import {
  ETA_STATE_INVITED,
  ETA_STATE_CLIENT_FLAGGED,
  ETA_STATE_PROVIDER_FLAGGED,
  sortStatusValue
} from "../../../helpers"

export const FILTER_ALL = 0
export const registration_states = [
  { text: "All Statuses", value: FILTER_ALL },
  { text: "Invite Sent", value: ETA_STATE_INVITED },
  { text: "Flagged by User", value: ETA_STATE_CLIENT_FLAGGED },
  { text: "Manual Verification", value: ETA_STATE_PROVIDER_FLAGGED }
]
export const SHOW_FILTERS_THRESHOLD = 5

export const filterRegistrationInProgressRows = (rows, filters) => {
  const { state_filter, user_search } = filters
  const filtered_rows = [...rows]
    .filter(
      ({ state }) => state_filter === FILTER_ALL || state_filter === state
    )
    .filter(
      ({ user }) =>
        user_search.trim().length === 0 ||
        user.name
          .toLowerCase()
          .trim()
          .indexOf(user_search.toLowerCase().trim()) > -1
    )
    .sort((a, b) =>
      sortStatusValue(a.state) > sortStatusValue(b.state) ? -1 : 1
    )
  return filtered_rows
}
