import { useMemo } from "react"
import Styled from "styled-components"

import Modal from "../../../Modal"
import Message from "../../../Message"
import EmployeePayoutsSummary from "../../EmployeePayouts/Summary"
import EmployeePayoutsTable from "../../EmployeePayouts/Table/"

import { formatFromUtc } from "../../../../helpers/datetime"
import { payoutInitiatedBy, payPeriodDateSpan } from "../../helpers"
import { useEtaConfigSetting } from "../../../../hooks"

export default ({ payout, onClose }) => {
  const show_fees = useEtaConfigSetting("hausdirect_enabled", false)
  const payouts = payout?.employeePayouts ?? []

  const getSummaryColumns = payout => {
    const {
      total_amount,
      total_employer_fees,
      user,
      payPeriod,
      completed_at
    } = payout
    return [
      {
        label: "Amount Paid",
        value: total_amount,
        type: "number"
      },
      show_fees
        ? {
            label: "Employer Fee",
            value: total_employer_fees,
            type: "number"
          }
        : null,
      { label: "Initiated By", value: payoutInitiatedBy(user) },
      { label: "For Pay Period", value: payPeriodDateSpan(payPeriod) },
      {
        label: "Completed At",
        value: completed_at ? formatFromUtc(completed_at) : "Payout in Progress"
      }
    ].filter(Boolean)
  }

  const summary_columns = useMemo(() => getSummaryColumns(payout), [payout])

  return (
    <Modal onClose={onClose}>
      <Modal.Header onClose={onClose}>Payout Detail</Modal.Header>
      <Modal.Content scrolling>
        <EmployeePayoutsSummary columns={summary_columns} />
        {payout.failed_message && (
          <PayoutFailedMessage message={payout.failed_message} />
        )}
        {!!payouts.length && (
          <EmployeePayoutsTable
            payouts={[...payouts].sort((a, b) => {
              return a.failed === b.failed ? 0 : a.failed ? -1 : 1
            })}
            show_completed_at={false}
            show_dates_included={false}
            show_fees={show_fees}
            show_take_home={false}
          />
        )}
      </Modal.Content>
    </Modal>
  )
}

const PayoutFailedMessage = Styled(({ className, message }) => (
  <div className={className}>
    <Message title="Payout Failed" type="danger" message={message} />
  </div>
))`
  margin-bottom: 1.5rem;
`
