import Link from "./../Link"

import { project_info, paths } from "./../../constants"

export default {
  onClose: () => {
    requestAnimationFrame(() => {
      try {
        document.getElementById("tab-navigation-sales").click()
      } catch {}
    })
    requestAnimationFrame(() => {
      try {
        document.getElementById("quick-filter-today").click()
      } catch {}
    })
  },
  steps: [
    {
      target: "body",
      placement: "center",
      title: `${project_info.name} Reporting`,
      content: (
        <>
          <p>
            Reporting pages provide a detailed view of your organization's data.
          </p>
          <p>Follow along for a summary of each tab.</p>
        </>
      ),
      onMount: () => {
        requestAnimationFrame(() => {
          try {
            document.getElementById("tab-navigation-sales").click()
          } catch {}
        })
        requestAnimationFrame(() => {
          try {
            document.getElementById("quick-filter-yesterday").click()
          } catch {}
        })
      }
    },
    {
      target: "#tab-navigation-sales",
      name: "sales-tab",
      title: "Let's start with sales.",
      content: (
        <>
          <p>
            This page lists the sales we've captured from your point-of-sale
            provider.
          </p>
        </>
      ),
      onMount: () => {
        requestAnimationFrame(() => {
          try {
            document.getElementById("tab-navigation-sales").click()
          } catch {}
        })
      }
    },
    {
      target: "#report-filter-bar",
      title: "Drill Down",
      content: (
        <>
          <p>Use these filters to specify a date range or specific employee.</p>
        </>
      )
    },
    {
      target: ".sales-table-checks-badge",
      title: "Check it out.",
      content: (
        <>
          <p>Click this badge to view individual checks for a sale.</p>
        </>
      ),
      placement: "left",
      onMount: () => {
        requestAnimationFrame(() => {
          try {
            let table = document.getElementById("report-table-sales").parentNode
            table.scrollLeft = table.scrollWidth
          } catch {}
        })
      }
    },
    {
      target: "#report-table-distributions-header",
      title: "Tip Sharing",
      content: (
        <>
          <p>
            A badge will appear in this column when one or more tip sharing
            rules applied to a sale.
          </p>
          <p>Click the badge to review individual distributions.</p>
        </>
      ),
      placement: "left",
      onMount: () => {
        requestAnimationFrame(() => {
          try {
            let table = document.getElementById("report-table-sales").parentNode
            table.scrollLeft = table.scrollWidth
          } catch {}
        })
      }
    },
    {
      target: "#tab-navigation-worked-shifts",
      name: "worked-shifts-tab",
      title: "Who's clocked in?",
      content: (
        <>
          <p>The Worked Shifts tab works just like Sales.</p>
          <p>
            You'll find all the shifts we've captured from your point-of-sale.
          </p>
        </>
      ),
      onMount: () => {
        requestAnimationFrame(() => {
          try {
            document.getElementById("tab-navigation-worked-shifts").click()
          } catch {}
        })
      },
      back: {
        onClick: ({ goToStep }) => {
          goToStep("sales-tab")
        }
      }
    },
    {
      target: "#missed-clockouts-notification",
      title: "Missed Clockouts",
      content: (
        <>
          <p>Keep your eye out for this message.</p>
          <p>
            Neither tip sharing rules nor tip pools will apply to shifts missing
            a clockout.
          </p>
          <p>You can click this message to see more info.</p>
        </>
      )
    },
    {
      target: "#tab-navigation-breakdown-snapshots",
      name: "breakdown-snapshots-tab",
      title: "Breakdown Snapshots",
      content: (
        <>
          <p>
            {project_info.name} automatically saves a snapshot of your Breakdown
            at the end of each pay period.
          </p>
          <p>Here you can easily export snapshots from any pay period.</p>
        </>
      ),
      onMount: () =>
        requestAnimationFrame(() => breakdownSnapshotsTab()?.click())
    },
    {
      target: "#tab-navigation-report-exports",
      name: "report-exports-tab",
      title: "Report Exports",
      content: (
        <>
          <p>
            If your organization uses automated report exports, which can be
            useful for automating payroll.
          </p>
          <p>You will find reports for each export run here.</p>
        </>
      ),
      onMount: () => requestAnimationFrame(() => reportExportsTab()?.click()),
      back: {
        onClick: ({ goToStep }) => {
          if (breakdownSnapshotsTab()) {
            return goToStep("breakdown-snapshots-tab")
          }
          return goToStep("worked-shifts-tab")
        }
      }
    },
    {
      target: "#tab-navigation-peer-to-peer",
      title: "Peer-to-Peer Tip Sharing",
      name: "peer-to-peer-tab",
      content: (
        <>
          <p>
            If your team uses Peer-to-Peer tip sharing, you'll find a log of
            transactions here.
          </p>
          <p>
            You can learn more about this feature and enable it (it's free!) in
            the Settings page.
          </p>
        </>
      ),
      onMount: () => requestAnimationFrame(() => peerToPeerTab()?.click()),
      back: {
        onClick: ({ goToStep }) => {
          if (reportExportsTab()) {
            return goToStep("report-exports-tab")
          } else if (breakdownSnapshotsTab()) {
            return goToStep("breakdown-snapshots-tab")
          }
          return goToStep("worked-shifts-tab")
        }
      }
    },
    {
      target: `#settings-resync-control`,
      title: "Request a Resync",
      content: () => (
        <>
          <p>We automatically sync with your point of sale.</p>
          <p>
            In some scenarios, it may be useful to resync data for a specific
            time window.
          </p>
          <p>You can use this button to request a resync.</p>
        </>
      ),
      back: {
        onClick: ({ goToStep }) => {
          if (peerToPeerTab()) {
            return goToStep("peer-to-peer-tab")
          } else if (reportExportsTab()) {
            return goToStep("report-exports-tab")
          } else if (breakdownSnapshotsTab()) {
            return goToStep("breakdown-snapshots-tab")
          }
          return goToStep("worked-shifts-tab")
        }
      }
    },
    {
      target: "#help-button",
      title: "Farewell for now.",
      content: (
        <>
          <p>That's it for the reporting tour.</p>
          <p>
            <Link href={paths.contact} target="_blank">
              Drop us a note
            </Link>{" "}
            if anything comes up.
          </p>
          <p>As always, I'm here if you need me.</p>
        </>
      ),
      disable_next: true
    }
  ]
}

const peerToPeerTab = () =>
  document.getElementById("tab-navigation-peer-to-peer")
const reportExportsTab = () =>
  document.getElementById("tab-navigation-report-exports")
const breakdownSnapshotsTab = () =>
  document.getElementById("tab-navigation-breakdown-snapshots")
