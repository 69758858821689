import Styled from "styled-components"

import Modal from "./"
import Icon from "../../Icon"
import Button from "../../Button"

const Thin = Styled.div`
  font-weight: 300;
`

const AreYouSure = ({
  size = "mini",
  basic = false,
  header = null,
  sticky = false,
  submitted = false,
  confirmText = "Yes",
  confirmIcon = "check",
  cancelText = "Cancel",
  body = "Are you sure?",
  align_actions = "right",
  onClose = () => {},
  onConfirm = () => {}
}) => (
  <Modal size={size} basic={basic} sticky={sticky} onClose={onClose}>
    {!!header && <Modal.Header onClose={onClose} content={header} />}
    <Modal.Content>
      <Thin>{body}</Thin>
    </Modal.Content>
    <Modal.Actions textAlign={align_actions}>
      <Button onClick={onClose} disabled={!!submitted}>
        {cancelText}
      </Button>
      {!!confirmIcon && (
        <Button
          primary
          onClick={onConfirm}
          disabled={!!submitted}
          loading={!!submitted}
          icon
          labelPosition="right"
        >
          {confirmText}
          <Icon name={confirmIcon} />
        </Button>
      )}
      {!confirmIcon && (
        <Button
          primary
          onClick={onConfirm}
          disabled={!!submitted}
          loading={!!submitted}
        >
          {confirmText}
        </Button>
      )}
    </Modal.Actions>
  </Modal>
)

export default AreYouSure
