import { FilterRow, FilterCheckbox } from "../Styled"
import {
  DISTRO_SOURCE_CASH,
  DISTRO_SOURCE_SALE,
  DISTRO_SOURCE_GC_TIP,
  DISTRO_SOURCE_CC_TIP,
  DISTRO_SOURCE_SERVICE_CHARGE,
  OBJECT_NAME_TIP_SHARE
} from "../../helpers"
import { useCashTipDistributionEnabled } from "../../../../hooks"

export default ({ object_name, distribution_source, onChange }) => {
  // cash tips enabled on organization
  const cash_rules_enabled = useCashTipDistributionEnabled()

  return (
    <FilterRow name="Distribution Sources">
      <FilterCheckbox
        label={<>Credit Card Tip</>}
        checked={!!distribution_source[DISTRO_SOURCE_CC_TIP]}
        onChange={() =>
          onChange(
            distribution_source[DISTRO_SOURCE_CC_TIP] === false ? true : false,
            DISTRO_SOURCE_CC_TIP
          )
        }
      />
      <FilterCheckbox
        label={<>Gift Card Tip</>}
        checked={!!distribution_source[DISTRO_SOURCE_GC_TIP]}
        onChange={() =>
          onChange(
            distribution_source[DISTRO_SOURCE_GC_TIP] === false ? true : false,
            DISTRO_SOURCE_GC_TIP
          )
        }
      />
      <FilterCheckbox
        label={<>Gratuity Service Charge</>}
        checked={!!distribution_source[DISTRO_SOURCE_SERVICE_CHARGE]}
        onChange={() =>
          onChange(
            distribution_source[DISTRO_SOURCE_SERVICE_CHARGE] === false
              ? true
              : false,
            DISTRO_SOURCE_SERVICE_CHARGE
          )
        }
      />
      {!!cash_rules_enabled && (
        <FilterCheckbox
          label={<>Declared Cash Tip</>}
          checked={!!distribution_source[DISTRO_SOURCE_CASH]}
          onChange={() =>
            onChange(
              distribution_source[DISTRO_SOURCE_CASH] === false ? true : false,
              DISTRO_SOURCE_CASH
            )
          }
        />
      )}
      {object_name === OBJECT_NAME_TIP_SHARE && (
        <FilterCheckbox
          label={<>Sale Item Price</>}
          checked={!!distribution_source[DISTRO_SOURCE_SALE]}
          onChange={() =>
            onChange(
              distribution_source[DISTRO_SOURCE_SALE] === false ? true : false,
              DISTRO_SOURCE_SALE
            )
          }
        />
      )}
    </FilterRow>
  )
}
