import Styled from "styled-components"

import { Menu } from "semantic-ui-react"

import { colors } from "./../constants"
import {
  CONTENT_PADDING_LEFT,
  CONTENT_PADDING_RIGHT
} from "./RouteContent/Styled"
import { useNavigateTo } from "../hooks"

const StyledTabs = Styled.div`${({}) => `
  & > div {
    padding-left: ${CONTENT_PADDING_LEFT};
    padding-right: ${CONTENT_PADDING_RIGHT};

    &:last-child {
      padding-top: 2rem;
      background-color: ${colors.white};
      border-top: 1px solid ${colors.table_border};

      & > div.alert-btn-group > div {
        margin-top: 0;
      }
    }
    &:first-child {
      background-color: ${colors.white};
    }
  }
`}`

const Tab = Styled.div`
  margin-right: 0.5rem;
  margin-bottom: -0.5px;
  position: relative;
  display: inline-block;
  padding: 0.7rem 0.8rem 0.6rem;
  font-size: 1.2rem;
  line-height: 1.25rem;
  vertical-align: bottom;
  transition: all 0.1s;
  border-bottom: 2px solid transparent;

  ${({ selected }) =>
    !!selected
      ? `
    /* selected */
    cursor: default;
    color: ${colors.dark};
    border-color: ${colors.dark4};
    `
      : `
    /* not selected */
    cursor: pointer;
    color: ${colors.dark2};
    &:hover {
      color: ${colors.dark};
      border-color: ${colors.light4};
    }
    `}
`

const Tabs = ({
  tabs = [],
  onChange = () => {},
  navigation_path = false,
  children
}) => {
  const navigateTo = useNavigateTo()
  return (
    <StyledTabs>
      <div>
        {tabs
          .filter(({ visible = true }) => !!visible)
          .map(tab => (
            <Tab
              key={tab.name}
              id={!!tab.id ? `tab-navigation-${tab.id}` : undefined}
              onClick={() => {
                if (navigation_path) {
                  navigateTo(`${navigation_path}/${tab.id}`)
                }
                onChange(tab)
              }}
              onAuxClick={() => {
                if (navigation_path) {
                  window.open(`${navigation_path}/${tab.id}`)
                }
              }}
              selected={tab.selected}
            >
              {tab.name}
            </Tab>
          ))}
      </div>
      <div>{children}</div>
    </StyledTabs>
  )
}

const SubTabsWrapper = Styled(props => <Menu {...props} />)`
  margin-bottom: 1.5rem !important;
`
const SubTab = Styled(({ active, ...props }) => {
  return <Menu.Item active={active} {...props} />
})``

export const SubTabs = ({
  tabs,
  onChange,
  navigation_path = false,
  navigateTo
}) => (
  <SubTabsWrapper compact pointing>
    {tabs
      .filter(({ visible = true }) => !!visible)
      .map(tab => (
        <SubTab
          key={tab.name}
          id={!!tab.id ? tab.id : tab.name}
          active={!!tab.selected}
          onClick={() => {
            if (navigation_path) {
              navigateTo(`${navigation_path}/${tab.id}`)
            }
            onChange(tab)
          }}
        >
          {tab.name}
        </SubTab>
      ))}
  </SubTabsWrapper>
)

export const RouteTabs = ({ className = "", ...props }) => (
  <div className={`route-content-full-width ${className}`}>
    <Tabs {...props} />
  </div>
)

export default Tabs
