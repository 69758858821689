import { createContext } from "react"

import Styled from "styled-components"
import { Modal as SemanticModal } from "semantic-ui-react"

import Menu from "./Menu"
import Table from "../Table"
import Button from "../Button"
import Sequence from "./Sequence"
import AreYouSure from "./AreYouSure"

import { style, colors } from "../../../constants"

export { Menu as MenuModal, Sequence as SequenceModal, AreYouSure }

const ModalContext = createContext({})

const ModalWithDefaults = ({
  open = true,
  centered = false,
  sticky = false,
  ...props
}) => {
  if (sticky) {
    props = { ...props, closeOnEscape: false, closeOnDimmerClick: false }
  }
  return <SemanticModal open={open} centered={centered} {...props} />
}

const StyledModal = Styled(ModalWithDefaults)`${({ basic = false }) => `
  && *:not(i) {
    font-family: ${style.font} !important;
  }
  && .ui.active.loader {
    &::before {
      border-color: ${colors.light4};
    }
    &::after {
      border-color: ${colors.success} transparent transparent;
    }
  }

  &.ui.fullscreen.modal {
    left: auto !important;
  }

  &.ui.modal .actions > .button {
    margin-left: 0.33em;
  }

  &.ui.modal .header {
    ${!basic ? `` : `border-bottom: none !important;`}
  }
`}`

const RemoveButton = Styled(
  ({
    dark = false,
    centered = false,
    inline_actions = false,
    className,
    ...props
  }) => (
    <Button
      basic
      fitted
      circular
      dark={dark}
      size="small"
      icon="remove"
      className={`close-modal-btn ${className}`}
      {...props}
    />
  )
)`${({ centered = false, inline_actions = false }) => `
  ${
    !centered && !inline_actions
      ? `
    position: absolute;
    right: 1rem;
    top: 0.9rem;
  `
      : `
    margin-left: 1.33rem !important;
  `
  }
`}`

const StyledHeader = Styled(
  ({
    dark,
    onClose,
    centered = false,
    content = null,
    inline_actions = false,
    children,
    ...props
  }) => (
    <ModalContext.Consumer>
      {({ basic: context_dark, onClose: contextOnClose }) => (
        <SemanticModal.Header {...props}>
          {!!content && content}
          {!content && children}
          {!!(onClose ?? contextOnClose) && (
            <RemoveButton
              centered={centered}
              dark={dark ?? context_dark}
              inline_actions={inline_actions}
              onClick={onClose ?? contextOnClose}
            />
          )}
        </SemanticModal.Header>
      )}
    </ModalContext.Consumer>
  )
)`
  &&&& {
    /* disable responsive padding */
    padding: 1.25rem 1.5rem !important;
    ${({ centered = false }) => (!centered ? "" : `text-align: center;`)};
  }
`

const StyledContent = Styled(({ fitted, ...props }) => (
  <SemanticModal.Content {...props} />
))`
  ${({ fitted = false }) => (!fitted ? "" : "&&& {padding: 0;}")}
`

const StyledActions = Styled(({ textAlign, ...props }) => (
  <SemanticModal.Actions {...props} />
))`
  &&&& {
    text-align: ${({ textAlign = "right" }) => textAlign};
  }
`

const StyledDescription = Styled(SemanticModal.Description)`
  font-size: 1.15em;
  font-weight: 300;
  color: ${colors.dark2};
  margin-bottom: 0.67em;
  &:last-child {
    margin-bottom: 0;
  }
`

const ModalContent = props => <StyledContent {...props} />

ModalContent.Table = Styled(Table)`
  margin-left: -1.5rem;
  margin-right: -1.5rem;
`

const Modal = ({ basic, onClose, ...props }) => {
  return (
    <ModalContext.Provider value={{ basic, onClose }}>
      <StyledModal basic={basic} onClose={onClose} {...props} />
    </ModalContext.Provider>
  )
}

Modal.Header = StyledHeader
Modal.Content = ModalContent
Modal.Actions = StyledActions
Modal.Dimmer = SemanticModal.Dimmer
Modal.Description = StyledDescription

export default Modal
