import { Table } from "../../../../../Shared"
import { ServiceLabel } from "../../../../Styled"
import RegistrationStatusCell from "./RegistrationStatusCell"

const Row = ({ payout_method, user, state, invite_expires, onInviteSent }) => (
  <Table.Row>
    <Table.Cell>
      <ServiceLabel payout_method={payout_method}>{payout_method}</ServiceLabel>
    </Table.Cell>
    <Table.Cell>{user.name}</Table.Cell>
    <RegistrationStatusCell
      user={user}
      state={state}
      invite_expires={invite_expires}
      onInviteSent={onInviteSent}
    />
  </Table.Row>
)

export default Row
