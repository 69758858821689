import moment from "moment"

export const filterAndSortNotificationQueryResults = (
  results = [],
  min_time = null,
  max_time = null
) => {
  let job_codes = []

  try {
    job_codes = [...results]
      .sort((a, b) =>
        moment(a.window_start).isAfter(moment(b.window_start)) ? 1 : -1
      )
      // filter with optional min and max time
      .filter(
        ({ window_start }) =>
          (!min_time ||
            moment(min_time).isSameOrBefore(moment(window_start))) &&
          (!max_time || moment(max_time).isSameOrAfter(moment(window_start)))
      )
  } catch {}
  return job_codes
}
