import {
  SET_APPLICATION_FILTERS,
  MERGE_APPLICATION_FILTERS,
  SET_FILTERS_FOR_NAMESPACE,
  MERGE_FILTERS_FOR_NAMESPACE,
  CLEAR_REPORT_FILTERS,
  CHANGE_SELECTED_LOCATION
} from "../actions"

// filters are stored as dictionaries isolated by namespace
const DEFAULT_NAMESPACE = "default"

// all filters these names should be unset when selected location is updated.
const CLEAR_ON_CHANGE_LOCATION = [
  "page",
  "employee",
  "job_code",
  "distributions",
  "revenue_center",
  "distribute_to",
  "distribute_from",
  "sale"
  // "sort_field",
  // "sort_direction"
]

export default (state = {}, action) => {
  const { type, payload } = action
  const namespace = payload?.namespace ?? DEFAULT_NAMESPACE
  const filters = payload?.filters ?? {}

  switch (type) {
    case SET_APPLICATION_FILTERS:
      return { ...state, ...filters }
    case MERGE_APPLICATION_FILTERS:
      return {
        ...state,
        ...Object.keys(filters).reduce(
          (acc, namespace) => ({
            ...acc,
            [namespace]: { ...(state[namespace] ?? {}), ...filters[namespace] }
          }),
          {}
        )
      }
    case SET_FILTERS_FOR_NAMESPACE:
      return {
        ...state,
        [namespace]: {
          ...filters
        }
      }
    case MERGE_FILTERS_FOR_NAMESPACE:
      return {
        ...state,
        [namespace]: {
          ...(state[namespace] ?? {}),
          ...filters
        }
      }
    case CLEAR_REPORT_FILTERS:
      if (!payload?.namespace) {
        // clear all namespaces
        return {}
      }
      // clear all filters in specified namespace
      return {
        ...state,
        [namespace]: {}
      }
    case CHANGE_SELECTED_LOCATION:
      if (payload === state?.global?.selected_location_id) {
        // selected location value didn't change
        return { ...state }
      }
      // all location dependent filters should be unset
      // when selected location is updated.
      return Object.keys(state).reduce(
        (filters, namespace) => ({
          ...filters,
          [namespace]: Object.keys(state[namespace])
            // remove filters that are whitelisted to be dependent on location
            .filter(
              filter_name => !CLEAR_ON_CHANGE_LOCATION.includes(filter_name)
            )
            // collapse filter keys back into key=>value object
            .reduce(
              (acc, filter_name) => ({
                ...acc,
                [filter_name]: state[namespace][filter_name]
              }),
              {}
            )
        }),
        {
          global: { selected_location_id: payload }
        }
      )
    default:
      return { ...state }
  }
}
