import {
  MAX_DISTRO_WEIGHT,
  TIP_DISTRO_SOURCES_ALL,
  DEFAULT_DISTRO_WEIGHT,
  DISTRO_SOURCE_CC_TIP,
  DISTRO_SOURCE_GC_TIP,
  DISTRO_SOURCE_SERVICE_CHARGE,
  RULE_TYPE_SALE_ASSIGNED_POOL
} from "../helpers"
import { round, isNumber } from "../../../helpers"

export {
  MAX_DISTRO_WEIGHT,
  DEFAULT_DISTRO_WEIGHT,
  RULE_TYPE_SALE_ASSIGNED_POOL
}

export const isValidDistributionWeight = (weight, allow_zero_weight = true) => {
  if (!isNumber(weight)) return false

  weight = round(weight, 2)

  if (weight > MAX_DISTRO_WEIGHT) return false
  if (weight < 0) return false
  if (!allow_zero_weight && weight === 0) return false

  return true
}

export const saleGratuityForSources = (
  sale,
  sources = TIP_DISTRO_SOURCES_ALL
) => {
  let total = 0
  if (sources.includes(DISTRO_SOURCE_CC_TIP)) {
    total += sale.total_cc_tips - sale.total_tips_refund
  }
  if (sources.includes(DISTRO_SOURCE_GC_TIP)) {
    total += sale.total_gc_tips
  }
  if (sources.includes(DISTRO_SOURCE_SERVICE_CHARGE)) {
    total += sale.total_service_charges
  }
  return total
}

export const salesGratuityForSources = (sales, sources) =>
  sales.reduce((acc, sale) => acc + saleGratuityForSources(sale, sources), 0)
