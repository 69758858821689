import {
  entity_type_enterprise,
  entity_type_organization,
  entity_type_store
} from "../../constants/role"
import { toInt } from "../../helpers"
import { useSelectedLocationId, useUser } from "../selectors"

/**
 * Authorization hook
 *
 * @returns
 */
const useAuthorization = () => {
  const user = useUser()
  const locationId = useSelectedLocationId()

  /**
   *  Check if user has permission for given entity_type and entity_id
   *
   * @param {*} entity_type
   * @param {*} entity_id
   * @param {*} permission
   * @returns
   */
  const hasPermissionForEntity = (entity_type, entity_id, permission) =>
    user.roleAssignments
      .filter(roleAssignment => roleAssignment.entity_type === entity_type)
      .filter(
        roleAssignment => toInt(roleAssignment.entity_id) === toInt(entity_id)
      )
      .some(roleAssignment =>
        roleAssignment.role.permissions
          .flatMap(permission => permission.name)
          .includes(permission)
      )

  /**
   *  Check if user has permission for given enterprise id
   *
   * @param {*} enterpriseId
   * @param {*} permission
   * @returns
   */
  const hasPermissionForEnterprise = (enterpriseId, permission) => {
    if (!user || !enterpriseId) return false

    return hasPermissionForEntity(
      entity_type_enterprise,
      enterpriseId,
      permission
    )
  }

  /**
   *  Check if user has permission for given org id
   *
   * @param {*} orgId
   * @param {*} permission
   * @returns
   */
  const hasPermissionForOrg = (orgId, permission) => {
    if (!user || !orgId) return false

    if (hasPermissionForEntity(entity_type_organization, orgId, permission)) {
      return true
    }

    // if not authorized for org, check if user has enterprise permission
    if (
      user?.enterprise?.id &&
      hasPermissionForEnterprise(user.enterprise.id, permission)
    ) {
      return true
    }

    return false
  }

  /**
   * Check if user has permission for given store id
   *
   * @param {*} storeId
   * @param {*} permission
   * @returns
   */
  const hasPermissionForStore = (storeId, permission) => {
    if (!user || !storeId) return false

    if (hasPermissionForEntity(entity_type_store, storeId, permission)) {
      return true
    }

    // check permission on org if user did not have store permission
    return hasPermissionForOrg(user.organization.id, permission)
  }

  /**
   * Check if user has permission for all stores
   *
   * @param {*} stores
   * @param {*} permission
   * @returns
   */
  const hasPermissionForStores = (stores, permission) => {
    const store_ids = stores.map(s => s.id)
    // every store has to have the permission
    const auth = store_ids.every(store_id =>
      hasPermissionForStore(store_id, permission)
    )
    return auth
  }

  /**
   * Checks permission for current user and selected location
   * @param {*} permission
   * @returns
   */
  const hasPermission = permission => {
    if (!user) return false

    if (locationId) {
      return hasPermissionForStore(locationId, permission)
    }

    if (hasPermissionForStores(user.stores, permission)) {
      return true
    }

    return hasPermissionForOrg(user.organization.id, permission)
  }

  return {
    hasPermission
  }
}

export default useAuthorization
