import { useQuery } from "@apollo/client"
import {
  CLIENT_SETTINGS_USER_SEARCH,
  CLIENT_DASH_ROLES
} from "../../../../graphql/queries"
import {
  useSelectedLocationId,
  useStateObject,
  useToast
} from "../../../../hooks"
import UsersTable from "./components/Table"
import FilterBar from "./components/FilterBar"
import UserFormModal from "./components/Modal/UserForm/"
import ResendInviteModal from "./components/Modal/ResendInvite"
import DeleteUserModal from "./components/Modal/DeleteUser"
import ExportUsersModal from "./components/Modal/ExportUsers"
import { createSearchQuery } from "../../../../helpers"
import { paths } from "../../../../constants"
import EmployeeInvites from "../../Locations/Modal/EmployeeInvites"
import { PageIntro, NoResultsMessage } from "./Styled"
import { useEffect } from "react"
import { entity_type_employee } from "../../../../constants/role"
import { userSearchActiveFilter } from "./helpers"
import { UserModalsProvider, useUserModals } from "./context/userModals"
import ExportButton from "./components/Table/Actions/Export"

const DEFAULT_PAGE = 1
const PER_PAGE = 100

export default () => (
  <UserModalsProvider>
    <UsersTab />
  </UserModalsProvider>
)

const UsersTab = () => {
  const { modal, setModal, hideModal } = useUserModals()
  const {
    user_modal,
    edit_user,
    delete_user,
    resend_invite,
    employee_invite,
    export_users
  } = modal
  const [filters, setFilters] = useStateObject({
    roles_loaded: false,
    status_filter: "all",
    sort_by: "name",
    sort_dir: "asc",
    roles: [],
    search: "",
    page: DEFAULT_PAGE,
    per_page: PER_PAGE
  })

  const {
    roles_loaded,
    status_filter,
    sort_by,
    sort_dir,
    roles,
    search,
    page,
    per_page
  } = filters

  const selected_location_id = useSelectedLocationId()
  const toast = useToast()

  const { data: roles_data, loading: roles_loading } = useQuery(
    CLIENT_DASH_ROLES,
    {
      fetchPolicy: "network-only"
    }
  )
  const { data, loading: users_loading, refetch } = useQuery(
    CLIENT_SETTINGS_USER_SEARCH,
    {
      fetchPolicy: "network-only",
      // don't query users until roles have loaded
      skip: !roles_loaded,
      variables: {
        store_id: selected_location_id ?? null,
        active: userSearchActiveFilter(status_filter),
        role_ids: roles,
        search,
        page,
        first: per_page,
        sort_by,
        sort_dir
      }
    }
  )

  const client_roles = roles_data?.clientDashRoles ?? []
  const users = data?.clientSettingsUserSearch?.data ?? []
  const { total = 0 } = data?.clientSettingsUserSearch?.paginatorInfo ?? {}

  const requestEditEmployee = employee => {
    window.location.href = createSearchQuery(paths.settingsLocations, {
      location_id: employee?.store?.id ?? "",
      employee_name: employee?.name ?? "",
      view: "employees"
    })
  }

  const onStatusChangeCompleted = data => {
    toast({
      type: "success",
      message: `User ${data.active ? "activated" : "deactivated"} successfully.`
    })
    refetch()
  }

  useEffect(() => {
    if (!!client_roles.length) {
      // pre-populate roles filter
      setFilters({
        roles_loaded: true,
        roles: client_roles
          .filter(r => r.assigned && r.entity_type !== entity_type_employee)
          .map(({ id }) => id)
      })
    }
  }, [client_roles])

  return (
    <>
      <PageIntro />
      <FilterBar
        filters={filters}
        setFilters={setFilters}
        client_roles={client_roles}
        loading={users_loading || roles_loading}
      />
      {!users_loading && !roles_loading && (
        <>
          {!users.length && <NoResultsMessage />}
          {!!users.length && (
            <UsersTable
              users={users}
              controls={{
                position: users.length > per_page ? "both" : "top",
                custom_controls_position: "top",
                pagination: {
                  current_page: page,
                  per_page,
                  total,
                  include_total: true,
                  position: "top",
                  changePage: page => setFilters({ page }),
                  changePerPage: per_page => setFilters({ per_page, page: 1 }),
                  options: {
                    per_page: [10, 25, 50, 100]
                  }
                },
                custom: [<ExportButton />]
              }}
              onStatusChangeCompleted={onStatusChangeCompleted}
            />
          )}
        </>
      )}
      {user_modal && (
        <UserFormModal
          onClose={() =>
            setModal({
              user_modal: false,
              edit_user: null
            })
          }
          edit_user={edit_user}
          client_roles={client_roles}
          onCompleted={() => {
            toast({
              type: "success",
              message: `User ${
                !!edit_user ? "updated" : "created"
              } successfully.`
            })
            setModal({ user_modal: false, edit_user: null })
            refetch()
          }}
        />
      )}
      {employee_invite && (
        <EmployeeInvites
          store_id={selected_location_id}
          onEditRequest={requestEditEmployee}
          onClose={() => hideModal("employee_invite")}
        />
      )}
      {resend_invite && (
        <ResendInviteModal
          user={resend_invite}
          onClose={() => hideModal("resend_invite")}
          onCompleted={() => {
            hideModal("resend_invite")
            toast({
              type: "success",
              message: `Invite sent successfully.`
            })
          }}
        />
      )}
      {!!delete_user && (
        <DeleteUserModal
          user={delete_user}
          onClose={() => hideModal("delete_user")}
          onCompleted={() => {
            hideModal("delete_user")
            toast({
              type: "success",
              message: `User deleted successfully.`
            })
            refetch()
          }}
        />
      )}
      {!!export_users && (
        <ExportUsersModal onClose={() => hideModal("export_users")} />
      )}
    </>
  )
}
