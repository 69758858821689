import { forwardRef, useRef } from "react"

import Styled from "styled-components"
import { Label } from "semantic-ui-react"

import ExclamationIcon from "../../Icon/Exclamation"
import { Button, Popup, Table, Link } from "../../Shared"

import { isNoneJobCode } from "../../../helpers"
import { displayNumber } from "../../../helpers/number"
import { JOB_CODE_NAMES_IDX, aggregateBreakdownRows } from "./helpers"

const StyledLabel = Styled(
  forwardRef(({ clickable, ...props }, ref) => <Label {...props} ref={ref} />)
)`
  position: relative;
  font-weight: 400 !important;
  vertical-align: top;
  ${({ clickable }) =>
    !clickable
      ? ""
      : `
    cursor: pointer;
    &:hover {
      border: 1px solid transparent !important;
      box-shadow:
        0px 0px 0px 1px rgba(34, 36, 38, 0.35) inset,
        0px 0px 0px 0px rgba(34, 36, 38, 0.15) inset !important;
    }
  `};
`
const TableLabel = props => {
  let ref = useRef()
  return <StyledLabel {...props} ref={ref} />
}
const StyledButton = Styled(Button)`
  vertical-align: top !important;
  margin: 1px !important;
`

export default ({ expanded, ...props }) => (
  <>
    <TableRow
      {...props}
      expanded={expanded}
      employee={{ ...props.breakdown.employee }}
      breakdown={aggregateBreakdownRows(props.breakdown.rows, props.columns)}
    />
    {!!expanded &&
      props.breakdown.rows.map((row, idx) => (
        <TableRow
          {...props}
          breakdown={row}
          secondary={true}
          expanded={false}
          toggleExpanded={null}
          employee={{ ...props.breakdown.employee }}
          key={`${props.breakdown.employee.id}.${idx}`}
        />
      ))}
  </>
)

const TableRow = ({
  links,
  columns,
  employee,
  breakdown,
  toggleExpanded,
  expanded = false,
  secondary = false,
  ...props
}) => {
  return (
    <Table.Row secondary={!!secondary} selected={!!expanded}>
      {columns.map((column, idx) => {
        if (!secondary && links[column.name] !== undefined) {
          let value = "-"
          let callback = links[column.name]
          if (breakdown[column.name] !== 0) {
            value = (
              <Link onClick={() => callback(employee)}>
                {displayNumber(breakdown[column.name], 2)}
              </Link>
            )
          }
          return (
            <TableCell
              key={idx}
              value={value}
              column={column}
              textAlign="right"
            />
          )
        } else if (column.name === JOB_CODE_NAMES_IDX) {
          return (
            <Table.Cell key={idx} column={column}>
              {!secondary &&
                breakdown[JOB_CODE_NAMES_IDX].split(", ").map((name, idx) =>
                  !isNoneJobCode(name) ? (
                    <TableLabel key={idx} circular basic>
                      {name}
                    </TableLabel>
                  ) : (
                    <Popup
                      key={idx}
                      delay
                      trigger={
                        <TableLabel
                          className="none-jobcode-badge"
                          circular
                          clickable
                          key={idx}
                          basic
                          onClick={() =>
                            props.showResolveNoneJobCodeModal({ ...employee })
                          }
                        >
                          {name} <ExclamationIcon fitted />
                        </TableLabel>
                      }
                      content={
                        <>
                          <p>
                            Some of {employee.first_name}'s sales were not
                            associated with a job code.
                          </p>
                          <p>Click to review and manage those sales.</p>
                        </>
                      }
                      inverted
                    />
                  )
                )}
              {!secondary &&
                breakdown.job_code_names.split(", ").length > 1 && (
                  <StyledButton
                    size="mini"
                    compact
                    circular
                    icon={!expanded ? "chevron down" : "chevron up"}
                    basic
                    onClick={() => toggleExpanded(employee.id)}
                  />
                )}
              {!!secondary && breakdown.job_code_names}
            </Table.Cell>
          )
        } else if (column.type === "number") {
          return (
            <TableCell
              column={column}
              key={column.name}
              textAlign="right"
              value={displayNumber(breakdown[column.name], 2)}
            />
          )
        }
        return (
          <TableCell
            column={column}
            key={column.name}
            value={breakdown[column.name]}
          />
        )
      })}
    </Table.Row>
  )
}

const TableCell = Styled(({ value, column, ...props }) => (
  <Table.Cell {...props} singleLine>
    {value === "0.00" ? "-" : value}
  </Table.Cell>
))``
