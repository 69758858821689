import { FilterRow, FilterValue, FilterCheckbox } from "../Styled"
import DistributionRuleSearch from "../../../../Search/DistributionRule"

export default ({
  distributions,
  location,
  onChange,
  onSelectRule,
  onRemoveRule
}) => (
  <>
    <FilterRow name="Distributions">
      <FilterCheckbox
        label={
          <>
            Sales <strong>with</strong> distributions
          </>
        }
        checked={!!distributions}
        onChange={() => onChange(distributions ? null : true)}
      />
      <FilterCheckbox
        label={
          <>
            Sales <strong>without</strong> distributions
          </>
        }
        checked={distributions === false}
        onChange={() => onChange(distributions === false ? null : false)}
      />
    </FilterRow>
    {!!distributions && (
      <FilterRow name="Distribution Rule">
        <div>
          {!distributions?.id && (
            <DistributionRuleSearch
              aligned="right"
              store_id={location?.id}
              onChange={({ rule }) => onSelectRule({ ...rule })}
            />
          )}
          {distributions?.id && (
            <FilterValue value={distributions.name} onRemove={onRemoveRule} />
          )}
        </div>
      </FilterRow>
    )}
  </>
)
