import { useMutation } from "@apollo/client"
import moment from "moment"
import { EDIT_USER } from "../../../../../../graphql/mutations"
import { toInt } from "../../../../../../helpers"
import { useNavigateTo, useStateObject, useUser } from "../../../../../../hooks"
import { Table } from "../../../../../Shared"
import RegisteredCell from "./RegisteredCell"
import StatusCell from "./StatusCell"
import LocationCell from "./LocationCell"
import ActionsPopupMenu from "./ActionsPopupMenu"
import useAuthorization from "../../../../../../hooks/authorization"
import { settings_manage_users } from "../../../../../../constants/permissions"
import { month_day_year_format, paths } from "../../../../../../constants"
import { hasMobileAppLevelRole } from "../../../../../../helpers/user"
import { canModifyUser } from "../../helpers"
import { useUserModals } from "../../context/userModals"

const UserRow = ({ user, onStatusChangeCompleted }) => {
  const current_user = useUser()
  const [state, setState] = useStateObject({ actions_open: false })
  const { hasPermission } = useAuthorization()
  const { setModal, showModal } = useUserModals()
  const navigateTo = useNavigateTo()

  const [editUser, { loading: edit_loading }] = useMutation(EDIT_USER)
  const closeActions = () => setState({ actions_open: false })

  const handleStatusChange = user => {
    const variables = {
      id: user.id,
      active: !user.active
    }
    editUser({
      variables,
      onCompleted: () => onStatusChangeCompleted(variables)
    })
  }

  const requestEditUser = user => {
    if (toInt(user.id) !== current_user.id) {
      setModal({ user_modal: true, edit_user: user })
    } else {
      navigateTo(paths.profile)
    }
  }

  const canChangeUserStatus = target_user =>
    hasPermission(settings_manage_users) &&
    canModifyUser(current_user, target_user) &&
    !hasMobileAppLevelRole(target_user) &&
    toInt(target_user.id) !== current_user.id

  const getActions = () => {
    let actions = []

    // check if user has settings:manage-users permission, and if user can modify target user based on roles hierarchy
    if (
      !hasPermission(settings_manage_users) ||
      !canModifyUser(current_user, user)
    ) {
      return actions
    }

    actions.push({
      label: "Edit",
      action: () => {
        requestEditUser(user)
        closeActions()
      }
    })

    if (!user.registered_at && !hasMobileAppLevelRole(user)) {
      actions.push({
        label: "Resend Invite",
        action: () => {
          showModal("resend_invite", user)
          closeActions()
        }
      })
    }

    if (toInt(user.id) !== current_user.id && !hasMobileAppLevelRole(user)) {
      actions.push({
        label: "Delete",
        danger: true,
        action: () => {
          showModal("delete_user", user)
          closeActions()
        }
      })
    }

    return actions
  }

  const role_name = user.roleAssignments[0]?.role?.name
  const { actions_open } = state

  const actions = getActions()
  return (
    <Table.Row>
      <Table.Cell>{user.name}</Table.Cell>
      <Table.Cell>{user.email}</Table.Cell>
      <LocationCell user={user} />
      <Table.Cell>{role_name || ""}</Table.Cell>
      <Table.Cell>
        {moment.utc(user.created_at).local().format(month_day_year_format)}
      </Table.Cell>
      <RegisteredCell
        is_registered={
          !!user.registered_at || toInt(user.id) === current_user.id
        }
      />
      <StatusCell
        active={user.active}
        disabled={!canChangeUserStatus(user)}
        loading={edit_loading}
        handleStatusChange={() => handleStatusChange(user)}
      />
      <Table.Cell>
        {!!actions.length && (
          <ActionsPopupMenu
            open={actions_open}
            actions={actions}
            onOpen={() => setState({ actions_open: true })}
            onClose={closeActions}
          />
        )}
      </Table.Cell>
    </Table.Row>
  )
}

export default UserRow
