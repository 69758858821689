import Styled from "styled-components"
import { List as SemanticList } from "semantic-ui-react"

import Icon from "../Icon"

const List = Styled(({ margin, ...props }) => <SemanticList {...props} />)`
  ${({ margin }) => (!margin ? "" : `margin: ${margin} !important;`)}
`

List.Item = props => <SemanticList.Item {...props} />
List.Icon = props => <Icon {...props} />
List.Content = props => <SemanticList.Content {...props} />
List.Description = props => <SemanticList.Description {...props} />

export default List
