import { useEffect, useState } from "react"
import moment from "moment"

import { round } from "../../helpers/number"
import { ExportToCsv } from "export-to-csv"
import { date_format } from "../../constants"
import { useOrganization } from "../../hooks"

export const LANDING_ROUTE_NAME = "landing"

// Title strings for metric export
const CC_TIP_FEE_STORE = "CC Tip Fee by Store"
const CC_TIP_FEE_EMPLOYEE = "CC Tip Fee by Employee"
const SALES_REVENUE_BY_EMPLOYEE = "Sales Revenue by Employee"
const SALES_REVENUE_BY_STORE = "Sales Revenue by Store"
const TIP_REVENUE_BY_EMPLOYEE = "Tip Revenue by Employee"
const TIP_REVENUE_BY_STORE = "Tip Revenue by Store"
const TOTAL_SALES_VOLUME = "Sale Count"
const TOTAL_SERVICE_CHARGES_BY_STORE = "Service Charges Revenue by Store"
const TOTAL_SERVICE_CHARGES_BY_EMPLOYEE = "Service Charges Revenue by Employee"
const TAKE_HOME_PAY = "Take Home Pay"
const TAKE_HOME_PER_HOUR_NAME = "Take Home per Hour"
const TAKE_HOME_JOB_CODE = "Take Home by Job Code"
const TAKE_HOME_JOB_CODE_PER_HOUR = "Take Home per Hour by Job Code"
const TIP_REVENUE_PER_HOUR = "Tip Revenue per Hour"
const TIP_PERCENTAGE_NAME = "Tip Percentage"
const HOURS_WORKED_BY_EMPLOYEE_NAME = "Hours Worked by Employee"
const HOURS_WORKED_BY_STORE_NAME = "Hours Worked by Store"
const SALES_REVENUE_PER_HOUR = "Sales Revenue per Hour"
const SALES_MADE_PER_HOUR_NAME = "Sales Made per Hour"
const TOTAL_TAKE_HOME_BY_STORE = "Take Home by Store"
const TOTAL_HOURS_WORKED_BY_JOB_CODE = "Hours Worked by Job Code"

// metric definitions
export const TIPS_BY_EMPLOYEE = "tip-revenue"
export const TIPS_PER_HOUR = "tips-per-hour"
export const TIP_PERCENTAGE = "tip-percentage"
export const TIPS_BY_STORE = "tip-revenue-by-store"
export const SALES_BY_EMPLOYEE = "sales-revenue"
export const SALES_VOLUME = "sale-count"
export const SALES_PER_HOUR = "sales-revenue-per-hour"
export const SALES_BY_STORE = "sales-revenue-by-store"
export const SALES_MADE_PER_HOUR = "sales-made-per-hour"
export const HOURS_WORKED_BY_EMPLOYEE = "hours-worked"
export const HOURS_WORKED_BY_STORE = "hours-worked-by-store"
export const HOURS_WORKED_BY_JOB_CODE = "hours-worked-by-job-code"
export const TAKE_HOME = "total-take-home"
export const TAKE_HOME_PER_HOUR = "take-home-per-hour"
export const TAKE_HOME_BY_JOB_CODE = "take-home-by-job-code"
export const TAKE_HOME_BY_STORE = "take-home-by-store"
export const TAKE_HOME_PER_HOUR_BY_JOB_CODE = "take-home-per-hour-by-job-code"
export const SERVICE_CHARGES_BY_STORE = "total-service-charges-revenue-by-store"
export const SERVICE_CHARGES_BY_EMPLOYEE =
  "total-service-charges-revenue-by-employee"
export const CC_TIP_FEE_BY_STORE = "total-cc-tip-fee-revenue-by-store"
export const CC_TIP_FEE_BY_EMPLOYEE = "total-cc-tip-fee-revenue-by-employee"
const METRIC_PANELS = [
  TIPS_BY_EMPLOYEE,
  TIPS_PER_HOUR,
  TIP_PERCENTAGE,
  SALES_BY_EMPLOYEE,
  SALES_VOLUME,
  SALES_PER_HOUR,
  SALES_MADE_PER_HOUR,
  HOURS_WORKED_BY_EMPLOYEE,
  TAKE_HOME,
  TAKE_HOME_PER_HOUR,
  TAKE_HOME_BY_JOB_CODE,
  TAKE_HOME_BY_STORE,
  TAKE_HOME_PER_HOUR_BY_JOB_CODE,
  CC_TIP_FEE_BY_EMPLOYEE,
  SERVICE_CHARGES_BY_EMPLOYEE,
  HOURS_WORKED_BY_JOB_CODE
]
const STORE_METRIC_PANELS = [
  TIPS_BY_STORE,
  SALES_BY_STORE,
  HOURS_WORKED_BY_STORE,
  CC_TIP_FEE_BY_STORE,
  SERVICE_CHARGES_BY_STORE
]

const ALL_METRICS = [...METRIC_PANELS, ...STORE_METRIC_PANELS]

const sumSales = totals =>
  totals.reduce((acc, total) => acc + total.total_sales_revenue, 0)
const salesPerHour = totals =>
  totals.reduce((acc, total) => acc + total.total_sales_revenue, 0) /
  (totals.reduce((acc, total) => acc + total.total_minutes_worked, 0) / 60)
const salesMadePerHour = totals =>
  totals.reduce((acc, total) => acc + total.total_sales_volume, 0) /
  (totals.reduce((acc, total) => acc + total.total_minutes_worked, 0) / 60)
const countSales = totals =>
  totals.reduce((acc, total) => acc + total.total_sales_volume, 0)
const sumTips = totals =>
  totals.reduce((acc, total) => acc + total.total_tips_revenue, 0)
const sumTakeHome = totals =>
  totals.reduce((acc, total) => acc + total.total_take_home, 0)
const tipsPerHour = totals =>
  totals.reduce((acc, total) => acc + total.total_tips_revenue, 0) /
  (totals.reduce((acc, total) => acc + total.total_minutes_worked, 0) / 60)
const takeHomePerHour = totals =>
  totals.reduce((acc, total) => acc + total.total_take_home, 0) /
  (totals.reduce((acc, total) => acc + total.total_minutes_worked, 0) / 60)
const tipsPercentage = totals =>
  (100 * totals.reduce((acc, total) => acc + total.total_tips_revenue, 0)) /
  totals.reduce((acc, total) => acc + total.total_sales_revenue, 0)
const hoursWorked = totals =>
  totals.reduce((acc, total) => acc + total.total_minutes_worked, 0) / 60
const sumServiceCharges = totals =>
  totals.reduce((acc, total) => acc + total.total_service_charges_revenue, 0)
const sumCcTipFees = totals =>
  totals.reduce((acc, total) => acc + total.total_cc_tip_fee_revenue, 0)

const groupTotalsByKey = (totals, resultKey, resultName) =>
  Object.values(
    totals.reduce((acc, result) => {
      const key = resultKey(result)
      if (!acc[key]) {
        acc[key] = {
          ...result,
          name: resultName(result),
          result_key: key,
          qualifier: result.store_name,
          total_minutes_worked: 0,
          total_sales_volume: 0,
          total_tips_revenue: 0,
          total_sales_revenue: 0,
          total_take_home: 0,
          total_cc_tip_fee_revenue: 0,
          total_service_charges_revenue: 0
        }
      }
      acc[key].total_minutes_worked += result.total_minutes_worked
      acc[key].total_sales_volume += result.total_sales_volume
      acc[key].total_tips_revenue += result.total_tips_revenue
      acc[key].total_sales_revenue += result.total_sales_revenue
      acc[key].total_take_home += result.total_take_home
      acc[key].total_cc_tip_fee_revenue += result.total_cc_tip_fee_revenue
      acc[key].total_service_charges_revenue +=
        result.total_service_charges_revenue
      return acc
    }, {})
  )

const groupedByEmployee = totals =>
  groupTotalsByKey(
    totals,
    result => result.employee_id,
    result =>
      `${result.first_name ? result.first_name : ""} ${
        result.last_name ? result.last_name : ""
      }`.trim()
  )

const groupedByStore = totals =>
  groupTotalsByKey(
    totals,
    result => result.store_id,
    result => `${result.store_name ? result.store_name : ""}`.trim()
  )

const groupedByJobCode = totals =>
  groupTotalsByKey(
    totals,
    result => result.job_code_id,
    result => result.job_code_name
  )

export const metric_definitions = {
  [SALES_BY_EMPLOYEE]: {
    name: "Sales Revenue by Employee",
    description: [
      "Total sales revenue",
      "Grouped by employee",
      "Excluding tips"
    ],
    type: "money",
    aggregator: sumSales,
    totals: totals =>
      groupedByEmployee(totals).map(total => ({
        ...total,
        total: total.total_sales_revenue
      }))
  },
  [SALES_BY_STORE]: {
    name: "Sales Revenue by Store",
    description: ["Total sales revenue", "Grouped by store", "Excluding tips"],
    type: "money",
    aggregator: sumSales,
    totals: totals =>
      groupedByStore(totals).map(total => ({
        ...total,
        total: total.total_sales_revenue
      }))
  },
  [SALES_VOLUME]: {
    name: "Sale Count",
    description: ["Count of completed sales", "Grouped by employee"],
    type: "number",
    aggregator: countSales,
    totals: totals =>
      groupedByEmployee(totals).map(total => ({
        ...total,
        total: total.total_sales_volume
      }))
  },
  [TIPS_BY_EMPLOYEE]: {
    name: "Tip Revenue by Employee",
    description: [
      "Total CC tip revenue",
      "Grouped by employee",
      "Prior to tip-distribution"
    ],
    type: "money",
    aggregator: sumTips,
    totals: totals =>
      groupedByEmployee(totals).map(total => ({
        ...total,
        total: total.total_tips_revenue
      }))
  },
  [TIPS_BY_STORE]: {
    name: "Tip Revenue by Store",
    description: [
      "Total CC tip revenue",
      "Grouped by store",
      "Prior to tip-distribution"
    ],
    type: "money",
    aggregator: sumTips,
    totals: totals =>
      groupedByStore(totals).map(total => ({
        ...total,
        total: total.total_tips_revenue
      }))
  },
  [CC_TIP_FEE_BY_STORE]: {
    name: "CC Tip Fee by Store",
    description: ["Total CC tip fees", "Grouped by store"],
    type: "money",
    aggregator: sumCcTipFees,
    totals: totals =>
      groupedByStore(totals).map(total => ({
        ...total,
        total: total.total_cc_tip_fee_revenue
      }))
  },
  [CC_TIP_FEE_BY_EMPLOYEE]: {
    name: "CC Tip Fee by Employee",
    description: ["Total CC tip fees", "Grouped by employee"],
    type: "money",
    aggregator: sumCcTipFees,
    totals: totals =>
      groupedByEmployee(totals).map(total => ({
        ...total,
        total: total.total_cc_tip_fee_revenue
      }))
  },
  [SERVICE_CHARGES_BY_STORE]: {
    name: "Service Charges Revenue by Store",
    description: [
      "Total service charges revenue",
      "Grouped by store",
      "Prior to tip-distribution"
    ],
    type: "money",
    aggregator: sumServiceCharges,
    totals: totals =>
      groupedByStore(totals).map(total => ({
        ...total,
        total: total.total_service_charges_revenue
      }))
  },
  [SERVICE_CHARGES_BY_EMPLOYEE]: {
    name: "Service Charges Revenue by Employee",
    description: [
      "Total service charges revenue",
      "Grouped by employee",
      "Prior to tip-distribution"
    ],
    type: "money",
    aggregator: sumServiceCharges,
    totals: totals =>
      groupedByEmployee(totals).map(total => ({
        ...total,
        total: total.total_service_charges_revenue
      }))
  },
  [TAKE_HOME]: {
    name: "Take Home Pay",
    description: [
      "Total take-home tips",
      "Grouped by employee",
      "Following tip-distribution",
      "Excluding Peer-to-Peer transactions"
    ],
    type: "money",
    supports_current_day: false,
    aggregator: sumTakeHome,
    totals: totals =>
      groupedByEmployee(totals).map(total => ({
        ...total,
        total: total.total_take_home
      }))
  },
  [TAKE_HOME_BY_JOB_CODE]: {
    name: "Take Home by Job Code",
    description: [
      "Total take-home tips",
      "Grouped by job-code",
      "Following tip-distribution",
      "Excluding Peer-to-Peer transactions"
    ],
    type: "money",
    supports_current_day: false,
    aggregator: sumTakeHome,
    totals: totals =>
      groupedByJobCode(totals).map(total => ({
        ...total,
        total: total.total_take_home
      }))
  },
  [TAKE_HOME_BY_STORE]: {
    name: "Take Home by Store",
    description: [
      "Total take-home tips",
      "Grouped by store",
      "Following tip-distribution",
      "Excluding Peer-to-Peer transactions"
    ],
    type: "money",
    supports_current_day: false,
    aggregator: sumTakeHome,
    totals: totals =>
      groupedByStore(totals).map(total => ({
        ...total,
        total: total.total_take_home
      }))
  },
  [TAKE_HOME_PER_HOUR_BY_JOB_CODE]: {
    name: "Take Home per Hour by Job Code",
    description: [
      "Total take-home tips per hour worked",
      "Grouped by job-code",
      "Following tip-distribution",
      "Excluding Peer-to-Peer transactions"
    ],
    type: "money",
    supports_current_day: false,
    aggregator: takeHomePerHour,
    totals: totals =>
      groupedByJobCode(totals)
        .filter(({ total_minutes_worked }) => total_minutes_worked > 0)
        .map(total => ({
          ...total,
          total: round(
            total.total_take_home / (total.total_minutes_worked / 60),
            2
          )
        }))
  },
  [TIPS_PER_HOUR]: {
    name: "Tip Revenue per Hour",
    description: [
      "CC tip revenue per hour worked",
      "Grouped by employee",
      "Prior to tip-distribution"
    ],
    type: "money",
    aggregator: tipsPerHour,
    totals: totals =>
      groupedByEmployee(totals)
        .filter(({ total_minutes_worked }) => total_minutes_worked > 0)
        .map(total => ({
          ...total,
          total: round(
            total.total_tips_revenue / (total.total_minutes_worked / 60),
            2
          )
        }))
  },
  [TAKE_HOME_PER_HOUR]: {
    name: "Take Home per Hour",
    description: [
      "Total take-home tips per hour worked",
      "Grouped by employee",
      "Following tip-distribution",
      "Excluding Peer-to-Peer transactions"
    ],
    type: "money",
    supports_current_day: false,
    aggregator: takeHomePerHour,
    totals: totals =>
      groupedByEmployee(totals)
        .filter(({ total_minutes_worked }) => total_minutes_worked > 0)
        .map(total => ({
          ...total,
          total: round(
            total.total_take_home / (total.total_minutes_worked / 60),
            2
          )
        }))
  },
  [TIP_PERCENTAGE]: {
    name: "Tip Percentage",
    description: [
      "Total CC tips earned divided by total sales revenue",
      "Grouped by employee",
      "Prior to tip-distribution"
    ],
    type: "percent",
    label: "AVG",
    aggregator: tipsPercentage,
    totals: totals =>
      groupedByEmployee(totals)
        .filter(({ total_sales_revenue }) => total_sales_revenue > 0)
        .map(total => ({
          ...total,
          total: round(
            (100 * total.total_tips_revenue) / total.total_sales_revenue,
            1
          )
        }))
  },
  [HOURS_WORKED_BY_EMPLOYEE]: {
    name: "Hours Worked by Employee",
    description: ["Total hours worked", "Grouped by employee"],
    type: "float",
    aggregator: hoursWorked,
    totals: totals =>
      groupedByEmployee(totals).map(total => ({
        ...total,
        total: round(total.total_minutes_worked / 60, 2)
      }))
  },
  [HOURS_WORKED_BY_STORE]: {
    name: "Hours Worked by Store",
    description: ["Total hours worked", "Grouped by store"],
    type: "float",
    aggregator: hoursWorked,
    totals: totals =>
      groupedByStore(totals).map(total => ({
        ...total,
        total: round(total.total_minutes_worked / 60, 2)
      }))
  },
  [HOURS_WORKED_BY_JOB_CODE]: {
    name: "Hours Worked by Job Code",
    description: ["Total hours worked", "Grouped by Job Code"],
    type: "float",
    aggregator: hoursWorked,
    totals: totals =>
      groupedByJobCode(totals).map(total => ({
        ...total,
        total: round(total.total_minutes_worked / 60, 2)
      }))
  },
  [SALES_PER_HOUR]: {
    name: "Sales Revenue per Hour",
    description: [
      "Total sales revenue per hour worked",
      "Grouped by employee",
      "Excluding tips"
    ],
    type: "money",
    label: "AVG",
    aggregator: salesPerHour,
    totals: totals =>
      groupedByEmployee(totals)
        .filter(({ total_minutes_worked }) => total_minutes_worked > 0)
        .map(total => ({
          ...total,
          total: round(
            total.total_sales_revenue / (total.total_minutes_worked / 60),
            2
          )
        }))
  },
  [SALES_MADE_PER_HOUR]: {
    name: "Sales Made per Hour",
    description: [
      "Count of completed sales per hour worked",
      "Grouped by employee",
      "Excluding tips"
    ],
    type: "float",
    label: "AVG",
    aggregator: salesMadePerHour,
    totals: totals =>
      groupedByEmployee(totals)
        .filter(({ total_minutes_worked }) => total_minutes_worked > 0)
        .map(total => ({
          ...total,
          total: round(
            total.total_sales_volume / (total.total_minutes_worked / 60),
            2
          )
        }))
  }
}

// metrics display settings
export const LANDING_FILTER_NAMESPACE = "landing"

export const metricPanelOptions = () =>
  [...METRIC_PANELS]
    .sort((a, b) =>
      metric_definitions[a].name.localeCompare(metric_definitions[b].name)
    )
    .map(panel => ({
      text: metric_definitions[panel].name,
      value: panel
    }))

export const storeMetricPanelOptions = () =>
  [...ALL_METRICS]
    .sort((a, b) =>
      metric_definitions[a].name.localeCompare(metric_definitions[b].name)
    )
    .map(panel => ({
      text: metric_definitions[panel].name,
      value: panel
    }))

const DATE_RANGE_OPT_YESTERDAY = "yesterday"
const DATE_RANGE_OPT_THIS_WEEK = "this-week"
const DATE_RANGE_OPT_LAST_WEEK = "last-week"
const DATE_RANGE_OPT_CURRENT_PAY_PERIOD = "current-pay-period"
const DATE_RANGE_OPT_LAST_PAY_PERIOD = "last-pay-period"
export const DATE_RANGE_OPT_CUSTOM = "custom"
export const DATE_RANGE_OPT_CUSTOM_SELECTED = "custom-selected"

const DATE_RANGE_PP_OPTIONS = [
  DATE_RANGE_OPT_CURRENT_PAY_PERIOD,
  DATE_RANGE_OPT_LAST_PAY_PERIOD
]

const DATE_RANGE_FILTER_OPTIONS = [
  { text: "Yesterday", value: DATE_RANGE_OPT_YESTERDAY },
  { text: "This Week", value: DATE_RANGE_OPT_THIS_WEEK },
  { text: "Last Week", value: DATE_RANGE_OPT_LAST_WEEK },
  { text: "Current Pay Period", value: DATE_RANGE_OPT_CURRENT_PAY_PERIOD },
  { text: "Last Pay Period", value: DATE_RANGE_OPT_LAST_PAY_PERIOD },
  { text: "Custom", value: DATE_RANGE_OPT_CUSTOM }
]

export const dateRangeFilterOptions = (user, custom_dates = {}) => {
  let options = [...DATE_RANGE_FILTER_OPTIONS]
    .filter(
      ({ value }) =>
        user?.organization?.current_pay_period ||
        !DATE_RANGE_PP_OPTIONS.includes(value)
    )
    .filter(
      ({ value }) =>
        // don't show "this week" option on monday
        !(value === DATE_RANGE_OPT_THIS_WEEK) || moment().isoWeekday() > 1
    )
  if (custom_dates?.start_date && custom_dates?.end_date) {
    options.push({
      text: `${moment(custom_dates.start_date).format(
        "ddd M/D/YY"
      )} through ${moment(custom_dates.end_date).format("ddd M/D/YY")}`,
      value: DATE_RANGE_OPT_CUSTOM_SELECTED
    })
  }
  return options
}

export const DEFAULT_LANDING_FILTERS = {
  selected_date_range: DATE_RANGE_OPT_YESTERDAY
}

export const dateRangeFilterValues = (
  applied_filters,
  current_pay_period = null,
  last_pay_period = null,
  date_today
) => {
  switch (applied_filters.selected_date_range) {
    case DATE_RANGE_OPT_CUSTOM_SELECTED:
      return {
        start_date: moment(applied_filters?.custom_date_range_start),
        end_date: moment(applied_filters?.custom_date_range_end)
      }
    case DATE_RANGE_OPT_CURRENT_PAY_PERIOD:
      return {
        start_date: moment(current_pay_period.start),
        end_date: moment(date_today).subtract(1, "d")
      }
    case DATE_RANGE_OPT_LAST_PAY_PERIOD:
      return {
        start_date: moment(last_pay_period.start),
        end_date: moment(last_pay_period.end)
      }
    case DATE_RANGE_OPT_THIS_WEEK:
      return {
        start_date: moment(date_today).startOf("isoWeek"),
        end_date: moment(date_today).subtract(1, "d")
      }
    case DATE_RANGE_OPT_LAST_WEEK:
      return {
        start_date: moment(date_today).startOf("isoWeek").subtract(1, "w"),
        end_date: moment(date_today).startOf("isoWeek").subtract(1, "d")
      }
    case DATE_RANGE_OPT_YESTERDAY:
    default:
      return {
        start_date: moment(date_today).subtract(1, "d"),
        end_date: moment(date_today).subtract(1, "d")
      }
  }
}

export const landingFiltersWithDefaults = app_filters => ({
  // inject defaults
  ...DEFAULT_LANDING_FILTERS,
  ...(app_filters[LANDING_FILTER_NAMESPACE] ?? {})
})

// persistent storage

const DISPLAY_SETTINGS_KEY_PREFIX = "dashboard_metrics_display"
const metricsStorageKey = ({ id }) => `${DISPLAY_SETTINGS_KEY_PREFIX}.${id}`
const DEFAULT_DISPLAY_METRICS = [
  TIPS_BY_EMPLOYEE,
  TIP_PERCENTAGE,
  TIPS_PER_HOUR
]

export const parseMetricsDisplaySettings = user => {
  const key = metricsStorageKey(user)
  let stored = localStorage.getItem(key)
  let settings = []
  if (!!stored) {
    try {
      settings = JSON.parse(stored).filter(
        panel => ALL_METRICS.indexOf(panel) > -1
      )
    } catch (e) {
      localStorage.setItem(key, JSON.stringify([...DEFAULT_DISPLAY_METRICS]))
      settings = [...DEFAULT_DISPLAY_METRICS]
    }
  } else {
    settings = [...DEFAULT_DISPLAY_METRICS]
  }
  return settings
}

export const saveMetricsDisplaySettings = (settings, user) => {
  localStorage.setItem(metricsStorageKey(user), JSON.stringify([...settings]))
}

const getMetricExportFileName = (type, start, end) => {
  return `${type.replace(/\s/g, "-").toLowerCase()}-${start}-${end}`
}

export const generateMetricExport = (
  leaders,
  metric_type,
  start_time,
  end_time
) => {
  // add timestamp to filename
  const start_date = start_time.format(date_format)
  const end_date = end_time.format(date_format)

  // define export-to-csv options
  const options = {
    filename: getMetricExportFileName(metric_type, start_date, end_date),
    title: "Metric Export Report",
    showLabels: true,
    headers: ["Store", "Employee", "Job Code", "Total"]
  }

  // get data values for each leader
  const data = leaders.reduce((acc, leader) => {
    // define each metric value
    const employee = `${leader.first_name} ${leader.last_name}`
    const job_code = leader.job_code_name
    const store = leader.store_name
    const total_cc_tip_fee = leader.total_cc_tip_fee_revenue
    const total_sales_revenue = leader.total_sales_revenue
    const total_sales_volume = leader.total_sales_volume
    const total_service_charges = leader.total_service_charges_revenue
    const total_take_home = leader.total_take_home
    const total_tips_revenue = leader.total_tips_revenue
    const total_hours_worked = round(leader.total_minutes_worked / 60, 2)

    // Build the export file for each metric type
    // omit employee and job code for store based metrics and employee for take home by job code
    switch (metric_type) {
      case CC_TIP_FEE_STORE:
        return [...acc, [store, "", "", total_cc_tip_fee]]
      case CC_TIP_FEE_EMPLOYEE:
        return [...acc, [store, employee, job_code, total_cc_tip_fee]]
      case SALES_REVENUE_BY_EMPLOYEE:
        return [...acc, [store, employee, job_code, total_sales_revenue]]
      case SALES_REVENUE_BY_STORE:
        return [...acc, [store, "", "", total_sales_revenue]]
      case TOTAL_TAKE_HOME_BY_STORE:
        return [...acc, [store, "", "", total_take_home]]
      case TOTAL_HOURS_WORKED_BY_JOB_CODE:
        return [...acc, [store, "", job_code, total_hours_worked]]
      case TIP_REVENUE_BY_EMPLOYEE:
        return [...acc, [store, employee, job_code, total_tips_revenue]]
      case TIP_REVENUE_BY_STORE:
        return [...acc, [store, "", "", total_tips_revenue]]
      case TOTAL_SALES_VOLUME:
        return [...acc, [store, employee, job_code, total_sales_volume]]
      case TOTAL_SERVICE_CHARGES_BY_STORE:
        return [...acc, [store, "", "", total_service_charges]]
      case TOTAL_SERVICE_CHARGES_BY_EMPLOYEE:
        return [...acc, [store, employee, job_code, total_service_charges]]
      case TAKE_HOME_PAY:
        return [...acc, [store, employee, job_code, total_take_home]]
      case TAKE_HOME_JOB_CODE:
        return [...acc, [store, "", job_code, total_take_home]]
      case HOURS_WORKED_BY_EMPLOYEE_NAME:
        return [...acc, [store, employee, job_code, total_hours_worked]]
      case HOURS_WORKED_BY_STORE_NAME:
        return [...acc, [store, "", "", total_hours_worked]]
      case TAKE_HOME_JOB_CODE_PER_HOUR:
        return [
          ...acc,
          [store, "", job_code, round(total_take_home / total_hours_worked, 2)]
        ]
      case TAKE_HOME_PER_HOUR_NAME:
        return [
          ...acc,
          [
            store,
            employee,
            job_code,
            round(total_take_home / total_hours_worked, 2)
          ]
        ]
      case TIP_REVENUE_PER_HOUR:
        return [
          ...acc,
          [
            store,
            employee,
            job_code,
            round(total_tips_revenue / total_hours_worked, 2)
          ]
        ]
      case TIP_PERCENTAGE_NAME:
        return [
          ...acc,
          [
            store,
            employee,
            job_code,
            round((100 * total_tips_revenue) / total_sales_revenue, 2)
          ]
        ]
      case SALES_REVENUE_PER_HOUR:
        return [
          ...acc,
          [
            store,
            employee,
            job_code,
            round(total_sales_revenue / total_hours_worked, 2)
          ]
        ]
      case SALES_MADE_PER_HOUR_NAME:
        return [
          ...acc,
          [
            store,
            employee,
            job_code,
            round(total_sales_volume / total_hours_worked, 2)
          ]
        ]
      default:
        return []
    }
  }, [])

  // generate CSV
  const exporter = new ExportToCsv(options)
  exporter.generateCsv(data)
}

const hideMarketingBannerKey = "hideOfficeHoursEmpAppBanner"

export const useShowMarketingBanner = () => {
  const [visible, setVisible] = useState(false)
  const { integration_sources } = useOrganization()

  const onDismiss = () => {
    localStorage.setItem(hideMarketingBannerKey, 1)
    setVisible(false)
  }

  useEffect(() => {
    const is_demo_org = integration_sources.includes("demo")
    const visible =
      !localStorage.getItem(hideMarketingBannerKey) &&
      moment().isBefore("2024-08-10 09:00:00") &&
      !is_demo_org
    setVisible(visible)
  }, [])

  return { visible, onDismiss }
}
