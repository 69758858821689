import React from "react"
import Styled from "styled-components"
import { colors } from "../../../constants"

const tippy_gif_path = `/assets/tippy_loading.gif`

export const Loader = Styled.div`
  position: fixed;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
  background-image: url('${tippy_gif_path}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @media (max-width: 768px) {
    width: 200px;
    height: 200px;
    top: 40%;
  }

  @media (max-width: 480px) {
    width: 150px;
    height: 150px;
    top: 35%;
  }
`

const LoadingText = Styled.div`
  position: fixed;
  top: calc(50% + 150px);
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 3rem;
  font-size: 2.33rem;
  font-weight: 300;
  color: ${colors.dark3};

  @media (max-width: 768px) {
    font-size: 1.8rem;
    top: calc(40% + 120px);
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
    top: calc(35% + 100px);
  }
`

const TippyLoader = () => {
  return (
    <>
      <Loader></Loader>
      <LoadingText>Loading your tips...</LoadingText>
    </>
  )
}

export default TippyLoader
