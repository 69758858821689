import { FilterRow, FilterValue } from "../Styled"
import SaleSearch from "../../../../Search/Sale"

export default ({ sale, location, onSelect, onRemove }) => (
  <FilterRow name="Sale ID">
    {!!sale && <FilterValue value={sale.external_id} onRemove={onRemove} />}
    {!sale && (
      <SaleSearch
        aligned="right"
        placeholder="Search Sale ID"
        store_id={location?.id}
        onChange={({ sale }) => onSelect({ ...sale })}
      />
    )}
  </FilterRow>
)
