import { Component, createRef } from "react"
import Styled from "styled-components"

const DragScrollWrapper = Styled.div`
  cursor: default;
  overflow: auto;
  &.dragging {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  }
`

export default class extends Component {
  state = { dragging: false }
  privateState = {
    isMouseDown: false,
    lastMousePositionX: null,
    lastMousePositionY: null,
    mouseUpCleared: true
  }
  clearListeners = []
  refElement = null

  constructor(props) {
    super(props)
    this.refElement = createRef()
  }

  componentDidMount() {
    const onMouseUp = () => {
      this.privateState = {
        ...this.privateState,
        isMouseDown: false,
        lastMousePositionX: null,
        lastMousePositionY: null
      }
      this.refElement.current.style.cursor = "default"
      if (this.state.dragging) {
        this.setState({ dragging: false })
      }
    }

    this.refElement.current.addEventListener(
      "mousedown",
      this.dragScrollOnMouseDown
    )
    document.documentElement?.addEventListener("mouseup", onMouseUp)
    const clearMouseDownListener = () =>
      document.documentElement?.removeEventListener("mousedown", onMouseUp)
    const clearMouseUpListener = () =>
      document.documentElement?.removeEventListener("mouseup", onMouseUp)
    this.clearListeners.push(clearMouseDownListener)
    this.clearListeners.push(clearMouseUpListener)

    const onMouseMove = event => {
      if (
        !this.privateState.isMouseDown ||
        !this.refElement ||
        !this.refElement.current
      ) {
        return
      }

      let element = this.refElement.current

      const { lastMousePositionX, lastMousePositionY } = this.privateState
      // The mousedown handler should have set the lastMousePositionX,
      // so this case should only happen if setPrivateState hasn't finished yet.
      if (lastMousePositionX === null || lastMousePositionY === null) {
        return
      }

      const scroll_x = lastMousePositionX - event.clientX
      const scroll_y = lastMousePositionY - event.clientY

      if (scroll_x !== 0 || scroll_y !== 0) {
        element.scrollLeft += scroll_x
        element.scrollTop += scroll_y

        this.privateState.mouseUpCleared = false
      }

      this.privateState.lastMousePositionX = event.clientX
      this.privateState.lastMousePositionY = event.clientY

      if (!this.state.dragging) {
        this.setState({ dragging: true })
      }

      event.preventDefault()
    }

    document.documentElement?.addEventListener("mousemove", onMouseMove)
    const clearMouseMoveListener = () =>
      document.documentElement?.removeEventListener("mousemove", onMouseMove)
    this.clearListeners.push(clearMouseMoveListener)

    const preventClickAfterMouseUp = e => {
      if (!this.privateState.mouseUpCleared) {
        e?.preventDefault()
        e?.stopPropagation()
        this.privateState.mouseUpCleared = true
        return false
      }
    }

    this.refElement.current.addEventListener("click", preventClickAfterMouseUp)
    this.clearListeners.push(preventClickAfterMouseUp)
  }

  componentWillUnmount() {
    this.clearListeners.forEach(clear => clear())
  }

  dragScrollOnMouseDown = e => {
    if (
      this.refElement.current.scrollWidth ===
      this.refElement.current.clientWidth
    ) {
      return
    }
    this.privateState = {
      ...this.privateState,
      isMouseDown: true,
      lastMousePositionX: e.clientX,
      lastMousePositionY: e.clientY
    }
  }

  render() {
    const { children, style } = this.props
    const { dragging } = this.state

    return (
      <DragScrollWrapper
        ref={this.refElement}
        style={style ?? {}}
        className={dragging ? "dragging" : ""}
      >
        {children}
      </DragScrollWrapper>
    )
  }
}
