import Styled from "styled-components"

import Icon from "../../Icon"

import { colors } from "../../../constants"

const StatusLabel = Styled(
  ({ strong, fitted, color, icon, label, ...props }) => (
    <span {...props}>
      <Icon name={icon} color={color} />
      {label}
    </span>
  )
)`
  ${({ fitted = false }) => (!fitted ? "margin: 0rem 1rem;" : "")}
  ${({ strong = false }) => (!!strong ? "font-weight: 600;" : "")}
`
export const ActiveLabel = ({ strong = false, fitted = false }) => (
  <StatusLabel
    label="Active"
    icon="check circle"
    color={colors.green}
    strong={strong}
    fitted={fitted}
  />
)
export const InactiveLabel = ({ strong = false, fitted = false }) => (
  <StatusLabel
    label="Inactive"
    icon="ban"
    color={colors.danger}
    strong={strong}
    fitted={fitted}
  />
)
export const FutureDatedLabel = ({ strong = false, fitted = false }) => (
  <StatusLabel
    label=" Future Dated"
    icon="calendar alternate outline"
    color={colors.success}
    strong={strong}
    fitted={fitted}
  />
)

export default StatusLabel
