import {
  useBusinessDates,
  useSelectedLocation,
  useCashTipDistributionEnabled
} from "../../../hooks"
import { compatibleDateObject } from "../../../helpers"
import {
  DEFAULT_DISTRO_WEIGHT,
  DISTRO_SOURCE_TYPE_TIPS,
  TIP_DISTRO_SOURCES_DEFAULT,
  useDefaultRuleSchedules,
  useFormatSchedulesForDisplay
} from "../helpers"

export const OBJECT_NAME = "tip pool"

export const POOL_EDITOR_MODE_EDIT = "edit"
export const POOL_EDITOR_MODE_COPY = "copy"
export const POOL_EDITOR_MODE_CREATE = "create"

// transform api result data structure state used by components
export const useFormatPoolForDisplay = (
  {
    id,
    name,
    store,
    schedules,
    effective_date,
    distributionMethod,
    jobCodes = [],
    revenueCenters = [],
    distributionSources = []
  },
  editor_mode
) => {
  const { businessDate } = useBusinessDates()
  const selected_location = useSelectedLocation()
  const cash_tips_enabled = useCashTipDistributionEnabled()

  let locations = []
  const default_source_type = !cash_tips_enabled ? DISTRO_SOURCE_TYPE_TIPS : ""

  if (store?.id) {
    locations.push(Number(store.id))
  } else if (selected_location?.id) {
    locations.push(selected_location.id)
  }

  return {
    id,
    name:
      editor_mode === POOL_EDITOR_MODE_COPY
        ? `${name ?? ""} (copy)`
        : name ?? "",
    locations,
    revenue_centers: revenueCenters.map(({ name }) => name),
    effective_date: compatibleDateObject(businessDate(effective_date)),
    schedules: schedules
      ? useFormatSchedulesForDisplay(schedules)
      : useDefaultRuleSchedules(),
    distribution_sources: distributionSources?.length
      ? distributionSources.map(({ name }) => name)
      : [...TIP_DISTRO_SOURCES_DEFAULT],
    distribution_source_type: distributionSources?.length
      ? distributionSources[0].source
      : default_source_type,
    distribution_method: distributionMethod?.name ?? "",
    apply_job_code_weights: jobCodes.reduce(
      (acc, { distribution_weight }) => acc || distribution_weight !== 1,
      false
    ),
    job_codes: jobCodes.reduce(
      (acc, { name, distribution_weight }) => ({
        ...acc,
        [name]: distribution_weight ?? DEFAULT_DISTRO_WEIGHT
      }),
      {}
    )
  }
}
