import { isValidEmail } from "../../../helpers"
import { Table } from "../../Shared"
import EmployeeEmailCell from "./EmployeeEmailCell"
import ExcludedToggleCell from "./ExcludedToggleCell"

const EmployeeRow = ({ employee, excluded, onExclude, onEditRequest }) => {
  const email = employee?.user?.email ?? employee.email ?? ""
  const valid_email = isValidEmail(email)
  return (
    <Table.Row
      // prevent selecting employee if no email is provided
      onClick={valid_email ? () => onExclude(employee, !excluded) : null}
      secondary={excluded}
    >
      <ExcludedToggleCell
        email={email}
        excluded={excluded}
        disabled={!valid_email}
        onChange={checked => onExclude(employee, !checked)}
      />
      <Table.Cell>{employee.name}</Table.Cell>
      <EmployeeEmailCell
        email={email}
        excluded={excluded}
        onEditRequest={() => onEditRequest(employee)}
      />
    </Table.Row>
  )
}

export default EmployeeRow
