import { useState } from "react"
import { useMutation } from "@apollo/client"
import { EDIT_ROLE } from "../../../../../../graphql/mutations"
import { getGraphQLValidationErrors } from "../../../../../../helpers"
import { useStateObject, useToast } from "../../../../../../hooks"
import {
  Button,
  Form,
  Icon,
  Modal,
  TextArea,
  ValidationErrorsMessage
} from "../../../../../Shared"

const EditRole = ({ role, onClose, refetch }) => {
  const [fields, setFields] = useStateObject({
    name: role?.name ?? "",
    description: role?.description ?? ""
  })
  const [errors, setErrors] = useState([])
  const [editRole, { loading }] = useMutation(EDIT_ROLE)
  const toast = useToast()
  const { name, description } = fields

  const handleSubmit = () => {
    const { id } = role
    editRole({
      variables: { id, name, description },
      onCompleted: () => {
        onClose()
        toast({
          type: "success",
          message: "Role updated successfully."
        })
        refetch()
      },
      onError: e => {
        setErrors(getGraphQLValidationErrors(e.graphQLErrors))
      }
    })
  }

  const validInput = () =>
    name.trim().length &&
    description.trim().length &&
    (name.trim() !== role.name || description.trim() !== role.description)

  return (
    <Modal size="tiny" onClose={onClose} closeOnDimmerClick={false}>
      <Modal.Header onClose={onClose}>Edit Role</Modal.Header>
      <Modal.Content>
        {!!errors.length && (
          <ValidationErrorsMessage
            header="Failed to edit role."
            errors={errors}
            onDismiss={() => setErrors([])}
            margin_bottom="1rem"
          />
        )}
        <Form autoComplete="off">
          <Form.Field>
            <Form.Input
              autoFocus={true}
              spellCheck="false"
              label="Name*"
              placeholder="Role Name"
              type="text"
              icon={name.trim().length ? "check" : null}
              value={name}
              onChange={(e, { value }) => setFields({ name: value })}
            />
          </Form.Field>
          <Form.Field>
            <label>Description*</label>
            <TextArea
              spellCheck="false"
              placeholder="Description"
              type="text"
              icon={description.trim().length ? "check" : null}
              value={description}
              onKeyDown={e => {
                // prevent new lines
                if (e.keyCode == 13 && !e.shiftKey) {
                  e.preventDefault()
                  return false
                }
              }}
              onChange={(e, { value }) => setFields({ description: value })}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} disabled={loading}>
          Close
        </Button>
        <Button
          icon
          primary
          loading={loading}
          labelPosition="right"
          disabled={!validInput()}
          onClick={() => handleSubmit(fields)}
        >
          Submit
          <Icon name="check" />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default EditRole
