import React, { Component } from "react"

import Styled from "styled-components"
import { Icon, Loader } from "semantic-ui-react"
import { Query } from "@apollo/client/react/components"

import Input from "../Shared/Input"
import Paginator from "../Paginator"

import {
  backdoor,
  CLIENT_BACKDOOR_LOGIN_USER_SEARCH,
  ORGANIZATION_SEARCH
} from "../../graphql/queries"
import { colors } from "../../constants"
import { isProduction } from "../../helpers"
import { org_admin_role_name } from "../../constants/role"
import { hasOrgLevelRole } from "../../helpers/user"

const ComponentWrapper = Styled.div`
  position: relative;
`
const StyledLoader = Styled.div`
  padding: 1.33rem;
  text-align: center;
`
const NoResults = Styled.div`
  padding: 1.33rem;
  font-size: 1rem;
  text-align: center;
`
const LoginLoader = Styled(Loader)`
  float: right;
  margin-top: 3px !important;
`
const SelectOrgWrapper = Styled.div`
  border: 1px solid ${colors.light5};
  border-radius: 3px;
  overflow: hidden;
`
const Organization = Styled.div`
  position: relative;
  font-size: 1.2rem;
  border-bottom: 1px solid ${colors.light4};
  line-height: 1.6rem;
  cursor: pointer;
  &>div {
    display: inline-block;
    padding: 0.67rem;
  }
  &:last-child {
    border-bottom: none;
  }
`
const ChevronWrapper = Styled.div`
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  color: ${colors.success};
`
const OrganizationHeader = Styled(Organization)`
  position: relative;
  background-color: ${colors.light2};
  font-weight: 400;
  cursor: default;
`
const OrgName = Styled.div`
  &&& {
    padding: 0.67rem 0.33rem 0.67rem 1rem;
    color: ${colors.dark};
  }
`
const OrganizationRow = Styled(Organization)`
  color: ${colors.dark2};
  background-color: ${colors.white};
  font-weight: 300;
  ${({ active }) => !active && `&:hover {`}
    & ${ChevronWrapper} {
      opacity: 1;
    }
    &::before {
      content: '';
      position: absolute;
      left: -1px;
      top: 0;
      bottom: 0px;
      background-color: ${colors.success};
      width: 2px;
    }
  ${({ active }) => !active && `}`}
`
const SelectUserList = Styled.div`
  &&& {
    display: block;
    margin-right: 0;
    padding: 0;
    background-color: ${colors.light2};
    max-height: 50vh;
    overflow-y: auto;
    border-top: 0.5px solid ${colors.light5};
  }
`
const SelectUserListWrapper = Styled.div`
  display: block !important;
  padding: 0 !important;
  font-size: 1rem;
`
const SelectUserLabel = Styled.div`
  font-size: 0.9rem;
`
const SelectUserListLoader = Styled.div`
  &&& {
    display: block;
    margin-right: 0;
    padding: 0.5rem 0;
    background-color: ${colors.light2};
    border-top: 0.5px solid ${colors.light5};
    text-align: center;
    font-size: 0.9rem;
  }
`
const UserRow = Styled(({ loading, disabled, ...props }) => <div {...props} />)`
  position: relative;
  padding: 0.33rem 0.67rem 0.33rem 1.67rem;
  transition: padding 0.12s linear;
  font-size: 1rem;
  border-top: 0.5px solid ${colors.light5};
  ${({ loading }) =>
    !loading
      ? ""
      : `
    background-color: ${colors.light3};
    padding-left: 2.165rem;
  `};
  & > .icon {
    float: right;
    visibility: hidden;
  }
  &:first-child {
    border-top: none;
    margin-top: 0.5px;
  }
  ${({ disabled }) =>
    !!disabled
      ? ""
      : `
    &:hover {
      background-color: ${colors.light3};
      color: ${colors.dark};
      padding-left: 2.165rem;
      & > .icon {
        visibility: visible;
      }
    }
  `}
  &::before {
    content: '';
    position: absolute;
    left: -1px;
    top: 0;
    bottom: 0px;
    background-color: ${colors.success};
    width: 3px;
  }
`
const AdminLabel = Styled.div`
  margin-left: 0.5rem;
  display: inline-block;
  background-color: ${colors.warning};
  border-radius: 4px;
  padding: 0 0.33rem;
  color: ${colors.white};
  font-size: 0.9rem;
  line-height: 1.33rem;
`
const OrgSearch = Styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 2px !important;
`

const PaginationWrapper = Styled.div`
  margin-top: 0.5rem;
  display: flex;
  justify-content: flex-end;
`
const UserHeaderWrapper = Styled.div`
  margin: 0.5rem;
  display: flex;
  justify-content: space-between;
`
const DEFAULT_PAGE = 1
const PER_PAGE_10 = 10
const PER_PAGE_25 = 25
const PER_PAGE_50 = 50
const PER_PAGE_100 = 100
const DEBOUNCE_MS = 500

export default class extends Component {
  state = {
    expanded: false,
    login_request: false,
    page: DEFAULT_PAGE,
    page_users: DEFAULT_PAGE,
    per_page: PER_PAGE_25,
    can_select_per_page: true,
    search: "",
    submitted_search: "",
    search_user: "",
    submitted_search_user: ""
  }
  render() {
    const {
      expanded,
      login_request,
      page,
      page_users,
      per_page,
      search,
      submitted_search,
      search_user,
      submitted_search_user
    } = this.state
    const { loginSuccess, navigateTo } = this.props
    const search_query =
      (submitted_search?.trim()?.length ?? 0) > 0 ? submitted_search : null
    const search_user_query =
      (submitted_search_user?.trim()?.length ?? 0) > 0
        ? submitted_search_user
        : null
    const filters = {
      active: true,
      search: search_query,
      not_demo: !search_query && isProduction() ? true : false
    }

    const filters_user_query = {
      search: search_user_query
    }

    return (
      <ComponentWrapper>
        <Query
          query={ORGANIZATION_SEARCH}
          variables={{ page, first: per_page, filters }}
          notifyOnNetworkStatusChange
          fetchPolicy="cache-and-network"
        >
          {({ loading, data }) => {
            let currentPage = 0,
              lastPage = 0,
              total = 0,
              organizations = []
            try {
              currentPage = data.organizationSearch.paginatorInfo.currentPage
              lastPage = data.organizationSearch.paginatorInfo.lastPage
              total = data.organizationSearch.paginatorInfo.total
              organizations = data.organizationSearch.data
            } catch (e) {}
            return (
              <>
                <SelectOrgWrapper>
                  <OrganizationHeader>
                    <OrgName>Name</OrgName>
                    <OrgSearch>
                      <Input
                        autoFocus={true}
                        size="mini"
                        action={{
                          icon:
                            submitted_search.length > 0 ? "remove" : "search",
                          basic: true,
                          onClick: () =>
                            this.setState({
                              submitted_search:
                                submitted_search.length > 0 ? "" : search,
                              search: submitted_search.length > 0 ? "" : search
                            })
                        }}
                        onChange={(e, { value }) => {
                          setTimeout(() => {
                            const { search } = this.state
                            if (search === value) {
                              this.setState({
                                submitted_search: value,
                                page: DEFAULT_PAGE
                              })
                            }
                          }, DEBOUNCE_MS)
                          this.setState({ search: value })
                        }}
                        value={search}
                        placeholder="Search..."
                      />
                    </OrgSearch>
                  </OrganizationHeader>
                  {!!loading && organizations.length === 0 && (
                    <StyledLoader>
                      <Loader active inline />
                    </StyledLoader>
                  )}
                  {!loading && organizations.length === 0 && (
                    <NoResults>No Results Found.</NoResults>
                  )}
                  {organizations.map(org => (
                    <OrganizationRow
                      key={org.id}
                      active={expanded === org.id}
                      onClick={() =>
                        this.setState({
                          expanded: org.id === expanded ? false : org.id,
                          submitted_search_user: "",
                          search_user: ""
                        })
                      }
                    >
                      <OrgName>{org.name}</OrgName>
                      <ChevronWrapper>
                        <Icon
                          name={
                            expanded === org.id
                              ? "chevron down"
                              : "chevron right"
                          }
                        />
                      </ChevronWrapper>
                      {expanded === org.id && (
                        <Query
                          query={CLIENT_BACKDOOR_LOGIN_USER_SEARCH}
                          variables={{
                            page: page_users,
                            first: PER_PAGE_25,
                            org_id: org.id,
                            filters: filters_user_query
                          }}
                          notifyOnNetworkStatusChange
                          fetchPolicy="cache-and-network"
                        >
                          {({ loading, data }) => {
                            let currentPageUsers = 0,
                              lastPageUsers = 0,
                              totalUsers = 0,
                              users = []
                            try {
                              currentPageUsers =
                                data.clientBackdoorLoginUserSearch.paginatorInfo
                                  .currentPage
                              lastPageUsers =
                                data.clientBackdoorLoginUserSearch.paginatorInfo
                                  .lastPage
                              totalUsers =
                                data.clientBackdoorLoginUserSearch.paginatorInfo
                                  .total
                            } catch (e) {}

                            users =
                              (Array.isArray(
                                data?.clientBackdoorLoginUserSearch?.data
                              )
                                ? [...data.clientBackdoorLoginUserSearch.data]
                                : []) ?? []

                            users.sort((a, b) => {
                              return a.name.localeCompare(b.name)
                            })
                            return (
                              <>
                                <SelectUserLabel>Select a User</SelectUserLabel>
                                <SelectUserListWrapper>
                                  <SelectUserList
                                    onClick={e => e.stopPropagation()}
                                  >
                                    <UserHeaderWrapper>
                                      <Input
                                        autoFocus={true}
                                        size="small"
                                        action={{
                                          icon:
                                            submitted_search_user.length > 0
                                              ? "remove"
                                              : "search",
                                          basic: true,
                                          onClick: () =>
                                            this.setState({
                                              submitted_search_user:
                                                submitted_search_user.length > 0
                                                  ? ""
                                                  : search_user,
                                              search_user:
                                                submitted_search_user.length > 0
                                                  ? ""
                                                  : search_user
                                            })
                                        }}
                                        onChange={(e, { value }) => {
                                          setTimeout(() => {
                                            const { search_user } = this.state
                                            if (search_user === value) {
                                              this.setState({
                                                submitted_search_user: value,
                                                page_users: DEFAULT_PAGE
                                              })
                                            }
                                          }, DEBOUNCE_MS)
                                          this.setState({ search_user: value })
                                        }}
                                        value={search_user}
                                        placeholder="Search..."
                                      />
                                      {!!currentPageUsers &&
                                        !!lastPageUsers &&
                                        lastPageUsers > 1 && (
                                          <Paginator
                                            current_page={currentPageUsers}
                                            per_page={PER_PAGE_25}
                                            total={totalUsers}
                                            changePage={page_users =>
                                              this.setState({
                                                page_users
                                              })
                                            }
                                          />
                                        )}
                                    </UserHeaderWrapper>
                                    {!!loading ? (
                                      <SelectUserListLoader>
                                        <Loader active inline />
                                      </SelectUserListLoader>
                                    ) : (
                                      users.map(user => (
                                        <UserRow
                                          key={user.name}
                                          loading={
                                            !!login_request &&
                                            login_request === user.id
                                          }
                                          disabled={
                                            !!login_request &&
                                            login_request !== user.id
                                          }
                                          onClick={e => {
                                            e.stopPropagation()
                                            e.nativeEvent.stopImmediatePropagation()
                                            if (!login_request) {
                                              this.setState({
                                                login_request: user.id
                                              })
                                            }
                                          }}
                                        >
                                          {user.name} - <i>{user.email}</i>{" "}
                                          {hasOrgLevelRole(user) && (
                                            <AdminLabel>
                                              {org_admin_role_name}
                                            </AdminLabel>
                                          )}
                                          {!login_request && (
                                            <Icon name="sign in" />
                                          )}
                                          {!!login_request &&
                                            login_request === user.id && (
                                              <Query
                                                query={backdoor(login_request)}
                                                onCompleted={({ backdoor }) => {
                                                  if (
                                                    !!backdoor &&
                                                    !!backdoor.token
                                                  ) {
                                                    this.props.syncUser(
                                                      loginSuccess()
                                                    )
                                                    navigateTo(
                                                      window.location.pathname
                                                    )
                                                  }
                                                }}
                                              >
                                                {() => {
                                                  return (
                                                    <LoginLoader
                                                      active
                                                      inline
                                                      size="tiny"
                                                    />
                                                  )
                                                }}
                                              </Query>
                                            )}
                                        </UserRow>
                                      ))
                                    )}
                                  </SelectUserList>
                                </SelectUserListWrapper>
                              </>
                            )
                          }}
                        </Query>
                      )}
                    </OrganizationRow>
                  ))}
                </SelectOrgWrapper>

                {!!currentPage && !!lastPage && (
                  <PaginationWrapper>
                    <Paginator
                      boundaryRange={0}
                      current_page={currentPage}
                      per_page={per_page}
                      total={total}
                      changePage={page => this.setState({ page })}
                      changePerPage={value =>
                        this.setState({ page: DEFAULT_PAGE, per_page: value })
                      }
                      options={{
                        per_page: [
                          PER_PAGE_10,
                          PER_PAGE_25,
                          PER_PAGE_50,
                          PER_PAGE_100
                        ]
                      }}
                    />
                  </PaginationWrapper>
                )}
              </>
            )
          }}
        </Query>
      </ComponentWrapper>
    )
  }
}
