import React, { Component } from "react"
import Styled from "styled-components"
import { Card } from "semantic-ui-react"

import ResetPasswordModal from "./../Modal/ResetPassword"
import EditUserModal from "./../Modal/EditUser"
import Button from "./../Button"
import Icon from "./../Icon"

const UserCard = Styled(props => <Card {...props} />)``
const UserIcon = Styled(props => <Icon {...props} />)`
  float: right;
`
const Thin = Styled.span`font-weight:300`

export default class extends Component {
  state = { request_edit_user: false, request_reset_pw: false }
  render() {
    const { request_edit_user, request_reset_pw } = this.state
    const { user, setUser } = this.props
    const role_name = user.roleAssignments[0]?.role?.name
    return (
      <UserCard>
        <Card.Content>
          <UserIcon name="user circle" fitted size="big" />
          <Card.Header>{user.name}</Card.Header>
          {!!role_name && <Card.Meta>{role_name}</Card.Meta>}
          <Card.Description>
            <Thin>Email:</Thin> {user.email}
          </Card.Description>
        </Card.Content>
        <Card.Content extra textAlign="right">
          <Button
            compact
            size="tiny"
            icon
            onClick={() => this.setState({ request_edit_user: true })}
          >
            Edit User <Icon name="cog" fitted />
          </Button>
          <Button
            compact
            size="tiny"
            icon
            onClick={() => this.setState({ request_reset_pw: true })}
          >
            Change Password <Icon name="lock" fitted />
          </Button>
        </Card.Content>
        {!!request_edit_user && (
          <EditUserModal
            user={user}
            toast={this.props.toast}
            onCompleted={({ name, email }) => setUser(name, email)}
            onClose={() => this.setState({ request_edit_user: false })}
          />
        )}
        {!!request_reset_pw && (
          <ResetPasswordModal
            user={user}
            navigateTo={this.props.navigateTo}
            onClose={() => this.setState({ request_reset_pw: false })}
          />
        )}
      </UserCard>
    )
  }
}
