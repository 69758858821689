import React from "react"
import Styled from "styled-components"
import Logo from "./LogoSvg"

import { colors } from "./../../constants"

const LogoWrapper = Styled.div`
  border-radius: 3px;
  display: inline-block;
  line-height: 0px;
  vertical-align: ${({ verticalAlign }) => verticalAlign};
  background-color: ${({ transparent, inverted }) =>
    !!transparent ? "transparent" : !!inverted ? colors.dark3 : colors.white};
  & > span {
    padding: 0.3em 0.4em;
    border-radius: 0.5em;
  }
`

const sizes = {
  mini: {
    height: "16px"
  },
  tiny: {
    height: "24px"
  },
  small: {
    height: "32px"
  },
  medium: {
    height: "40px"
  },
  large: {
    height: "50px"
  },
  big: {
    height: "55px"
  },
  huge: {
    height: "65px"
  },
  massive: {
    height: "100px"
  }
}

export default ({
  size = "tiny",
  inverted = true,
  transparent = true,
  verticalAlign = "middle",
  primaryColor,
  secondaryColor,
  style
}) => (
  <LogoWrapper
    inverted={inverted}
    transparent={transparent}
    verticalAlign={verticalAlign}
    style={{ ...style }}
  >
    <Logo
      inverted={inverted}
      style={{ ...sizes[size] }}
      primary={primaryColor}
      secondary={secondaryColor}
    />
  </LogoWrapper>
)
