import moment from "moment"
import {
  none_job_code,
  min_date_threshold_days,
  date_filters_threshold_months,
  epoch_date,
  epoch
} from "../constants"

export * from "./api"
export * from "./dom"
export * from "./number"
export * from "./string"
export * from "./datetime"
export * from "./intercom"
export * from "./fullview"
export * from "./settings"
export * from "./analytics"
export * from "./navigation"
export * from "./terms-of-service"

/* === environment helpers === */

export const isDevelopment = () =>
  process.env.REACT_APP_STAGE === "development" ||
  process.env.REACT_APP_STAGE === "local"

const isStaging = () => process.env.REACT_APP_STAGE === "staging"
const isDemo = () => process.env.REACT_APP_STAGE === "demo"
const isQa = () => process.env.REACT_APP_STAGE === "qa"

export const isProduction = () =>
  !isDevelopment() && !isStaging() && !isDemo() && !isQa()

/* === global business logic === */

/**
 * Check if is none job code
 *
 * @param {*} jc_name
 * @returns boolean
 */
export const isNoneJobCode = jc_name => jc_name === none_job_code

/**
 * App min date
 *
 * @param {*} reference
 * @returns moment
 */
export const appMinDate = (reference, user) => {
  const min_processing_time = user?.organization?.min_processing_time
  return moment(reference ?? moment().startOf("day")).subtract(
    min_processing_time
      ? moment().diff(moment(min_processing_time), "days")
      : min_date_threshold_days,
    "d"
  )
}

/**
 * Min filter date
 *
 * @returns moment
 */
export const dateFilterMinDate = yearsToSubstract => {
  if (yearsToSubstract === epoch) {
    return moment(epoch_date)
  }
  return moment().startOf("day").subtract(yearsToSubstract, "year")
}

/**
 * Start/end date filters exceed 1 month
 *
 * @param {*} start
 * @param {*} end
 * @returns boolean
 */
export const dateFiltersExceedThreshold = (start, end) =>
  moment(end).add(1, "day").diff(start, "month", true) >
  date_filters_threshold_months
