import React, { Component } from "react"
import Styled from "styled-components"
import { Form } from "semantic-ui-react"
import { Mutation } from "@apollo/client/react/components"

import Modal from "."
import Button from "../Button"
import Icon from "../Icon"
import Message from "../Message"

import { colors } from "../../constants"
import { isValidEmail } from "./../../helpers/string"
import { toInt } from "../../helpers/number"
import { EDIT_USER } from "../../graphql/mutations"

const FormModalWrapper = Styled.div``
const ErrorMessage = Styled(props => <Message type="error" {...props} />)`
  margin-bottom: 1rem;
`

export default class extends Component {
  state = {
    user_name: "",
    user_email: "",
    errors: []
  }

  componentDidMount() {
    const { user } = this.props
    this.setState({
      user_name: user.name,
      user_email: user.email
    })
  }

  formIsValid = () => {
    const { user_name, user_email } = this.state
    return (
      user_name.length > 0 && user_email.length > 0 && isValidEmail(user_email)
    )
  }

  render() {
    const { onClose, user, toast, onCompleted } = this.props
    const { user_name, user_email, errors } = this.state

    return (
      <FormModalWrapper>
        <Modal size="mini" onClose={onClose}>
          <Modal.Header>Update User Information</Modal.Header>
          <Modal.Content>
            {errors.length > 0 && (
              <ErrorMessage onDismiss={() => this.setState({ errors: [] })}>
                {errors.map((err, idx) => (
                  <p key={idx}>{err}</p>
                ))}
              </ErrorMessage>
            )}
            <Form autoComplete="off">
              <Form.Field>
                <Form.Input
                  autoFocus={true}
                  spellCheck="false"
                  label="Name"
                  type="text"
                  autoComplete="off"
                  icon={
                    user_name.length > 0 ? (
                      <Icon color={colors.green} name="check" fitted />
                    ) : null
                  }
                  value={user_name}
                  onChange={(e, { value }) =>
                    this.setState({
                      user_name: value
                    })
                  }
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  spellCheck="false"
                  label="Email"
                  type="text"
                  autoComplete="off"
                  icon={
                    user_email.length > 0 && isValidEmail(user_email) ? (
                      <Icon color={colors.green} name="check" fitted />
                    ) : null
                  }
                  value={user_email}
                  onChange={(e, { value }) =>
                    this.setState({
                      user_email: value
                    })
                  }
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Mutation
            mutation={EDIT_USER}
            onError={e =>
              this.setState({
                errors: [
                  "An error ocurred when attempting to update your user."
                ]
              })
            }
            onCompleted={() => {
              if (!!toast) {
                toast({
                  type: "success",
                  message: "User information updated successfully."
                })
              }
              onCompleted({ name: user_name, email: user_email })
              onClose()
            }}
          >
            {(editUser, { loading }) => {
              return (
                <Modal.Actions>
                  <Button onClick={onClose} disabled={!!loading}>
                    Close
                  </Button>
                  <Button
                    loading={!!loading}
                    disabled={!!loading || !this.formIsValid()}
                    onClick={() => {
                      this.setState({ errors: [] })
                      editUser({
                        variables: {
                          id: toInt(user.id),
                          name: user_name,
                          email: user_email
                        }
                      })
                    }}
                    primary
                    icon
                    labelPosition="right"
                  >
                    Submit <Icon name="check" />
                  </Button>
                </Modal.Actions>
              )
            }}
          </Mutation>
        </Modal>
      </FormModalWrapper>
    )
  }
}
