import React from "react"

import Styled from "styled-components"

import Message from "../../../../Message"
import { IconButton, SubmitButton } from "../../../../Button"

export const CountSelectedSales = Styled(({ selected_count, ...props }) => (
  <Message
    type="default"
    inline
    size="tiny"
    hidden={selected_count === 0}
    {...props}
  >
    <b>{selected_count}</b> {selected_count === 1 ? "sale" : "sales"} selected
  </Message>
))`
  & {
    min-width: 0;
    line-height: 0.72rem;
    display: inline-block;
    vertical-align: top;
    margin 0 0.33rem 0 1px;
  }
`

export const CancelMultiSelectBtn = ({ onClick }) => (
  <IconButton onClick={onClick} icon="remove">
    Cancel
  </IconButton>
)

export const SubmitMultiSelectBtn = ({ label, disabled, onClick }) => (
  <SubmitButton disabled={disabled} onClick={onClick} label={label} />
)
