import Styled from "styled-components"
import PayoutRow from "./PayoutRow"
import { Table } from "../../../Shared"

export default ({
  payouts = [],
  show_completed_at = true,
  show_employee = true,
  show_status = true,
  show_fees = true,
  show_take_home = true,
  show_dates_included = true
}) => {
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Service</Table.HeaderCell>
          {show_completed_at && (
            <Table.HeaderCell>Payout Date</Table.HeaderCell>
          )}
          {show_dates_included && (
            <Table.HeaderCell>Dates Included</Table.HeaderCell>
          )}
          {show_employee && <Table.HeaderCell>Employee</Table.HeaderCell>}
          {show_status && <Table.HeaderCell>Status</Table.HeaderCell>}
          <Table.HeaderCell textAlign="right">Gross Amount</Table.HeaderCell>
          {show_fees && (
            <Table.HeaderCell textAlign="right">Employee Fee</Table.HeaderCell>
          )}
          <Table.HeaderCell textAlign="right">Net Amount</Table.HeaderCell>
          {show_take_home && (
            <TakeHomeHeaderCell textAlign="right">Take Home</TakeHomeHeaderCell>
          )}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {payouts.map(payout => (
          <PayoutRow
            key={payout.id}
            {...payout}
            show_completed_at={show_completed_at}
            show_dates_included={show_dates_included}
            show_employee={show_employee}
            show_status={show_status}
            show_fees={show_fees}
            show_take_home={show_take_home}
          />
        ))}
      </Table.Body>
    </Table>
  )
}

const TakeHomeHeaderCell = Styled(Table.HeaderCell)`
  & {
    border-left: 1px solid rgba(34,36,38,.1) !important;
  }
`
