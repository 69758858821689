import Table from "../../../../Table"
import { Modal, IconButton, SubmitButton } from "../../../../Shared"

import SortByFilter from "./SortBy"
import JobCodeFilter from "./JobCode"
import EmployeeFilter from "./Employee"
import LocationFilter from "./Location"

import { toInt } from "../../../../../helpers/number"

export default ({
  user,
  filters,
  setFilters,
  resetFilters,
  onClose,
  onSubmit
}) => (
  <Modal size="tiny" onClose={onClose} sticky={!!resetFilters}>
    <Modal.Header>Worked Shifts Report Filters</Modal.Header>
    <Modal.Content fitted>
      <Table fitted basic="very">
        <Table.Body>
          {user.stores.length > 1 && (
            <LocationFilter
              location={filters.location}
              dependent_filters={{
                employee: filters.employee,
                job_code: filters.job_code
              }}
              available_locations={[...user.stores]}
              onChange={location =>
                setFilters({
                  ...filters,
                  location,
                  employee: null,
                  job_code: null
                })
              }
            />
          )}
          <EmployeeFilter
            location={filters.location}
            employee={filters.employee}
            onSelect={({ id, first_name, last_name, name, store }) =>
              setFilters({
                ...filters,
                employee: { id, first_name, last_name, name },
                location: { id: toInt(store.id), name: store.name }
              })
            }
            onRemove={() =>
              setFilters({
                ...filters,
                employee: null
              })
            }
          />
          <JobCodeFilter
            location={filters.location}
            job_code={filters.job_code}
            onSelect={({ id, name, store }) => {
              return setFilters({
                ...filters,
                job_code: { id, name },
                location: { id: toInt(store.id), name: store.name }
              })
            }}
            onRemove={() =>
              setFilters({
                ...filters,
                job_code: null
              })
            }
          />
          <SortByFilter
            field={filters.sort_field}
            direction={filters.sort_direction}
            onChange={(field, direction, is_default = false) => {
              return setFilters({
                ...filters,
                sort_field: is_default ? null : field,
                sort_direction: is_default ? null : direction
              })
            }}
          />
        </Table.Body>
      </Table>
    </Modal.Content>
    <Modal.Actions>
      {!!resetFilters && (
        <IconButton icon="refresh" basic onClick={resetFilters}>
          Reset Filters
        </IconButton>
      )}
      <SubmitButton onClick={onSubmit} />
    </Modal.Actions>
  </Modal>
)
