import { useState } from "react"

import moment from "moment"
import Styled from "styled-components"

import RevenueCentersInput from "./RevenueCentersInput"
import { Link, Button, DatePicker } from "../../../../Shared"
import { StepHeader, ItemLabel, Field, TextInput } from "../../../Styled"
import ScheduleTable, { ScheduleTableModal } from "../../../ScheduleTable"
import {
  DataLockedModal,
  EffectiveDateWarning
} from "../../../../Shared/ReportLockedIndicator"

import { toInt } from "../../../../../helpers"
import { useIsCustomRuleSchedule } from "../../../helpers"
import { month_day_format } from "../../../../../constants"
import { compatibleDateObject } from "../../../../../helpers/datetime"
import {
  useLocations,
  useReportLockThreshold,
  useRevenueCentersEnabled
} from "../../../../../hooks"

export default ({
  name,
  onUpdate,
  schedules,
  store_ids = [],
  effective_date,
  revenue_centers,
  rule_distributes_cash = false
}) => {
  const reportLockThreshold = useReportLockThreshold()
  const locations = useLocations()

  const [customize_schedule, setCustomizeSchedule] = useState(false)
  const [lock_threshold_detail, setLockThresholdDetail] = useState(false)

  const rev_centers_enabled = useRevenueCentersEnabled()

  const has_custom_schedule = useIsCustomRuleSchedule(schedules)

  const lock_threshold = reportLockThreshold(
    store_ids.length == 1 ? store_ids[0] : null
  )

  return (
    <>
      <StepHeader
        content="Basic Rule Details"
        subheader="Specify rule name, effective date, and schedule."
      />
      <Field id="rule-editor-name-input" name="Rule Name">
        <TextInput
          type="text"
          value={name}
          placeholder="my new rule"
          onChange={e =>
            onUpdate({
              name: e.target.value,
              store_ids,
              effective_date,
              schedules
            })
          }
        />
      </Field>
      {(locations.length > 1 || rev_centers_enabled) && (
        <Field name={`Location${store_ids.length > 1 ? "s" : ""}`}>
          {store_ids.map(store_id => (
            <ItemLabel key={store_id} basic>
              {
                locations.find(store => toInt(store.id) === toInt(store_id))
                  .name
              }
            </ItemLabel>
          ))}
        </Field>
      )}
      {!!rev_centers_enabled && !rule_distributes_cash && (
        <Field name="Revenue Centers">
          <RevenueCentersInput
            store_ids={store_ids}
            selected={revenue_centers}
            onUpdate={revenue_centers => onUpdate({ revenue_centers })}
          />
        </Field>
      )}
      <Field id="rule-editor-effective-input" name="Effective Date">
        <DatePicker
          button
          position="top right"
          force_close={!!lock_threshold_detail}
          description={
            !lock_threshold ? null : (
              <>
                Days preceding {lock_threshold.format(month_day_format)} are
                locked.{" "}
                <Link onClick={() => setLockThresholdDetail(true)}>
                  Learn more.
                </Link>
              </>
            )
          }
          onChange={e =>
            onUpdate({
              name,
              store_ids,
              effective_date: moment(e),
              schedules
            })
          }
          value={compatibleDateObject(effective_date)}
          minDate={compatibleDateObject(lock_threshold)}
        />
        <EffectiveDateWarning
          lock_threshold={lock_threshold}
          effective_date={effective_date}
        />
      </Field>
      <Field
        id="rule-editor-custom-schedule-input"
        name="Schedule"
        description={
          <>
            <p>
              Optionally specify which days and times this rule should be
              active.
            </p>
            <p>Rules are always active by default</p>
          </>
        }
      >
        {has_custom_schedule && (
          <StyledScheduleTable compact={true} schedules={schedules} />
        )}
        <CustomizeScheduleBtn
          always_active={!has_custom_schedule}
          onClick={() => setCustomizeSchedule(!customize_schedule)}
        />
      </Field>
      {!!customize_schedule && (
        <ScheduleTableModal
          onClose={() => setCustomizeSchedule(false)}
          schedules={schedules}
          onSubmit={submitted => {
            setCustomizeSchedule(false)
            onUpdate({
              name,
              store_ids,
              effective_date,
              schedules: [...submitted]
            })
          }}
        />
      )}
      {!!lock_threshold_detail && (
        <DataLockedModal
          lock_threshold={lock_threshold}
          onClose={() => setLockThresholdDetail(false)}
        />
      )}
    </>
  )
}

const StyledScheduleTable = Styled(({ className, ...props }) => (
  <div className={className}>
    <ScheduleTable {...props} compact={true} />
  </div>
))`
  margin-bottom: 0.5rem;
`

const CustomizeScheduleBtn = Styled(
  ({ className, always_active, ...props }) => (
    <div className={className}>
      <Button {...props}>
        {always_active && "Always Active"}
        {!always_active && "Customize"}
      </Button>
    </div>
  )
)``
