import { useState } from "react"
import { useQuery } from "@apollo/client"

import Search, { ResultItem, DEBOUNCE_MS, PAGE } from "."

import { toInt } from "../../helpers/number"
import { SALE_SEARCH } from "./../../graphql/queries"
import moment from "moment"

let debounce_ctr = null
const QUERY_LIMIT = 100
const datetime_format_compact = "MMM Do [at] h:mm a"

export default ({
  active = true,
  fluid = false,
  displayed = true,
  skip_empty = true,
  store_id = undefined,
  exclude_none = false,
  placeholder = "Search Sales",
  ...props
}) => {
  const [{ search, search_request }, setSearch] = useState({
    search: "",
    search_request: null
  })
  const { data, loading } = useQuery(SALE_SEARCH, {
    skip: !!skip_empty && search.trim() === "",
    variables: {
      page: PAGE,
      first: QUERY_LIMIT,
      filters: {
        search,
        store_id
      }
    }
  })

  const is_loading = loading || !!search_request
  const results = (data?.saleSearch?.data ?? [])
    .map(({ id, ...rest }) => ({ id: toInt(id), ...rest }))
    .map(sale => ({
      key: sale.id,
      value: sale.id,
      title: `Sale ID: ${sale.external_id}`,
      sale: { ...sale }
    }))

  return (
    <Search
      input={{ icon: "search", iconPosition: "left" }}
      placeholder={placeholder}
      loading={is_loading}
      results={results}
      onSearchChange={search => {
        let now = Date.now()
        debounce_ctr = now
        setSearch({ search: "", search_request: search })
        setTimeout(() => {
          if (debounce_ctr === now) {
            debounce_ctr = null
            setSearch({ search, search_request: null })
          }
        }, DEBOUNCE_MS)
      }}
      noResultsMessage={!!is_loading ? "Searching..." : "No results found."}
      resultRenderer={({ title, sale }) => (
        <ResultItem
          title={!!title ? title : <i>No Name</i>}
          subtitle={!store_id ? sale.employee.store.name : null}
          id_field={
            !!sale
              ? `Sale Closed: ${moment(sale.sale_time).format(
                  datetime_format_compact
                )}`
              : null
          }
        />
      )}
      {...props}
    />
  )
}
