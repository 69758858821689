import ReactGA from "react-ga4"

import { isProduction } from "./"
import { GOOGLE_ANALYTICS_MEASUREMENT_ID, project_info } from "../constants"

export const initializeAnalytics = user => {
  if (!isProduction()) {
    return
  }

  if (!user) {
    console.error("error initializing analytics")
    return
  }

  const user_id = user?.id?.toString()
  const org_id = user?.organization_id?.toString()
  const backdoor = user?.is_backdoor ? "1" : "0"

  const options = {
    gaOptions: {
      userId: user_id,
      th_org_id: org_id,
      th_internal: backdoor,
      th_app: project_info.title
    }
  }

  ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID, {
    ...options
  })
}

export const resetAnalytics = () => {
  ReactGA.reset()
}
