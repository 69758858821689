export const bootFullview = user => {
  if (process.env.REACT_APP_FULLVIEW_ORG_ID) {
    window.$fvIdentity = {
      id: user.id,
      name: user.name,
      email: user.email,
      env: process.env.REACT_APP_STAGE,
      roles: serializeUserRoles(user)
    }
  }
}

const serializeUserRoles = user => {
  if (!user || !user.roleAssignments) return []

  return user.roleAssignments.map(roleAssignment => {
    return roleAssignment.role.name + " " + roleAssignment.entity_id
  })
}
