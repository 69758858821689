import {
  entity_type_employee,
  entity_type_enterprise,
  entity_type_organization,
  entity_type_store
} from "../../../../constants/role"
import { toInt } from "../../../../helpers"
import {
  hasEnterpriseLevelRole,
  hasMobileAppLevelRole,
  hasOrgLevelRole,
  hasStoreLevelRole
} from "../../../../helpers/user"

// get location names to display for the location access column
export const getLocationLabelsForDisplay = (user, locations) => {
  const { roleAssignments, employees } = user
  const location_names = hasMobileAppLevelRole(user)
    ? getLocationNamesFromEmployees(employees)
    : getLocationNamesFromStoreRoles(roleAssignments, locations)
  return location_names
}

// if store level user, get assigned locations
const getLocationNamesFromStoreRoles = (roleAssignments, locations) =>
  roleAssignments
    .map(({ entity_type, entity_id }) =>
      entity_type === entity_type_store
        ? locations.find(l => l.id === toInt(entity_id))?.name
        : null
    )
    .filter(Boolean)

// if employee app user, get store name from employee object
const getLocationNamesFromEmployees = employees =>
  employees
    .map(employee => employee?.store?.name)
    .reduce(
      // unique based on name
      (acc, name) => (acc.includes(name) ? acc : [...acc, name]),
      []
    )

export const userSearchActiveFilter = status =>
  status === "active" ? true : status === "inactive" ? false : null

const entity_type_auth_mappings = {
  [entity_type_enterprise]: [
    entity_type_enterprise,
    entity_type_organization,
    entity_type_store,
    entity_type_employee
  ],
  [entity_type_organization]: [
    entity_type_organization,
    entity_type_store,
    entity_type_employee
  ],
  [entity_type_store]: [entity_type_store, entity_type_employee]
}

// checks if user can modify (create, edit, etc) a specified entity_type
export const userCanModifyEntityType = (user, entity_type) => {
  const user_entity_type = user.roleAssignments[0]?.entity_type ?? null
  const allowed_entity_types = entity_type_auth_mappings[user_entity_type] ?? []
  return allowed_entity_types.includes(entity_type)
}

// check if the logged in user can create/edit/delete the target user based on roles hierarachy
export const canModifyUser = (user, target_user) => {
  if (hasEnterpriseLevelRole(target_user)) {
    return userCanModifyEntityType(user, entity_type_enterprise)
  }

  if (hasOrgLevelRole(target_user)) {
    return userCanModifyEntityType(user, entity_type_organization)
  }

  if (hasStoreLevelRole(target_user)) {
    return userCanModifyEntityType(user, entity_type_store)
  }

  if (hasMobileAppLevelRole(target_user)) {
    return userCanModifyEntityType(user, entity_type_employee)
  }

  return false
}
