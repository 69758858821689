import { Form } from "semantic-ui-react"
import Styled from "styled-components"
import {
  Dropdown,
  HelpIcon,
  MultiSelect,
  Popup,
  TextArea,
  Toggle
} from "../../../../../../Shared"
import {
  displayEntityType,
  useCreateRoleEntityTypeOptions
} from "../../../helpers"
import { colors } from "../../../../../../../constants"
import {
  useLocations,
  useOrganization,
  useUser
} from "../../../../../../../hooks"
import { entity_type_store } from "../../../../../../../constants/role"
import { displayLocationNames } from "../helpers"
import {
  hasEnterpriseLevelRole,
  hasOrgLevelRole
} from "../../../../../../../helpers/user"

export const basicsStep = (
  { name, description, entity_type, store_ids, apply_all_stores, edit_mode },
  onChange
) => ({
  name: "Basics",
  complete: name.trim().length && description.trim().length && entity_type,
  render: () => (
    <Basics
      name={name}
      description={description}
      entity_type={entity_type}
      store_ids={store_ids}
      apply_all_stores={apply_all_stores}
      edit_mode={edit_mode}
      onChange={onChange}
    />
  )
})

const Basics = ({
  name,
  description,
  entity_type,
  store_ids,
  apply_all_stores,
  edit_mode,
  onChange
}) => {
  const user = useUser()
  const organization = useOrganization()
  const entity_type_options = useCreateRoleEntityTypeOptions()
  const locations = useLocations()
  return (
    <Form>
      {!edit_mode && <ConstantField label="Name" value={name} />}
      {edit_mode && (
        <Form.Field>
          <Form.Input
            autoFocus={true}
            spellCheck="false"
            label="Name*"
            placeholder="Role Name"
            type="text"
            icon={name.trim().length ? "check" : null}
            value={name}
            disabled={!edit_mode}
            onChange={(e, { value }) => onChange({ name: value })}
          />
        </Form.Field>
      )}
      {!edit_mode && <ConstantField label="Description" value={description} />}
      {edit_mode && (
        <Form.Field>
          <label>Description*</label>
          <TextArea
            spellCheck="false"
            placeholder="Description"
            type="text"
            icon={description.trim().length ? "check" : null}
            value={description}
            disabled={!edit_mode}
            onKeyDown={e => {
              // prevent new lines
              if (e.keyCode == 13 && !e.shiftKey) {
                e.preventDefault()
                return false
              }
            }}
            onChange={(e, { value }) => onChange({ description: value })}
          />
        </Form.Field>
      )}
      {!edit_mode && (
        <ConstantField
          label="Access Level"
          value={displayEntityType(entity_type)}
        />
      )}
      {edit_mode && (
        <Form.Field>
          <Popup
            hoverable
            delay
            content="Users with store-level access cannot manage any organization-level settings, including Pay Periods, Peer-to-Peer, and CC tip fees for the organization."
          >
            <label>
              Access Level* &nbsp;
              <HelpIcon fitted cursor="pointer" />
            </label>
          </Popup>
          <Dropdown
            selectOnBlur={false}
            placeholder="Select One"
            fluid
            selection
            options={entity_type_options}
            value={entity_type}
            disabled={!edit_mode}
            onChange={(e, { value }) =>
              onChange({
                entity_type: value
              })
            }
          />
        </Form.Field>
      )}
      {!edit_mode && entity_type === entity_type_store && (
        <ConstantField
          label="Location"
          value={
            !!store_ids.length
              ? displayLocationNames(store_ids, locations)
              : "All Locations"
          }
        />
      )}
      {edit_mode && entity_type === entity_type_store && (
        <>
          {(hasOrgLevelRole(user) || hasEnterpriseLevelRole(user)) && (
            <Form.Field>
              <Toggle
                label="Apply to All Locations (and future locations)"
                checked={apply_all_stores}
                onChange={() => {
                  // clear store_ids when toggling this option
                  onChange({
                    apply_all_stores: !apply_all_stores,
                    store_ids: []
                  })
                }}
              />
            </Form.Field>
          )}
          {(!apply_all_stores ||
            (!hasOrgLevelRole(user) && !hasEnterpriseLevelRole(user))) && (
            <Form.Field>
              <label>Location</label>
              <MultiSelect
                fluid
                search
                max_labels={5}
                selectOnBlur={false}
                closeOnChange={true}
                can_select_all={true}
                selectOnNavigation={false}
                placeholder="Select one or more locations"
                options={organization.stores.map(store => ({
                  key: store.name,
                  value: store.id,
                  text: store.name
                }))}
                value={store_ids}
                onChange={(e, { value }) =>
                  onChange({
                    store_ids: [...value]
                  })
                }
              />
            </Form.Field>
          )}
        </>
      )}
    </Form>
  )
}

const ConstantField = Styled(({ label, value, ...props }) => (
  <div {...props}>
    <div className="field">
      <label>{label}</label>
      <div className="constant-value">{value.trim().length > 0 && value}</div>
    </div>
  </div>
))`
  margin-bottom: 1em;

  & .constant-value {
    cursor: not-allowed;
    padding: .67857143em 1em;
    border-radius: .28571429rem;
    border: 1px solid ${colors.light4};
    background-color: ${colors.light};
    color: ${colors.dark6};
  }
`
