import { Button, Modal } from "../Shared"
import { useEmployeeInvites } from "./helpers"
import EmployeeInvitesTable from "./Table/index"

const EmployeeInvitesModal = ({
  employees = [],
  header = null,
  loading = false,
  onClose,
  onSubmit,
  onEditRequest
}) => {
  const { valid_invites, included, onExclude } = useEmployeeInvites(employees)
  return (
    <Modal sticky size="small" onClose={onClose}>
      {!!header && <Modal.Header>{header}</Modal.Header>}
      <Modal.Content fitted scrolling>
        <EmployeeInvitesTable
          employees={employees}
          included={included}
          onExclude={onExclude}
          onEditRequest={onEditRequest}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          onClick={() => onSubmit(valid_invites)}
          loading={loading}
          disabled={valid_invites.length === 0 || loading}
        >
          Send{" "}
          {employees.length > 1 && valid_invites.length > 0 && (
            <>{valid_invites.length} </>
          )}
          Invite{valid_invites.length !== 1 && "s"}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default EmployeeInvitesModal
