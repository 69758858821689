import React from "react"

import Table from "../../Table"
import Popup from "../../Popup"
import HelpIcon from "../../Icon/Help"

export const createColumnHeaderId = name => {
  const cleansed = name.replace(/\./g, "-").replace(/\_/g, "-").trim()
  return `breakdown-${cleansed}-col-header`
}

export default ({ columns, sort_column, sort_direction, handleSort }) => (
  <Table.Row>
    {columns.map(column => {
      return (
        <Table.HeaderCell
          id={createColumnHeaderId(column.name)}
          textAlign={column.type === "number" ? "right" : "left"}
          sorted={sort_column === column.name ? sort_direction : null}
          onClick={() => handleSort(column)}
          key={column.name}
        >
          {!column.description && column.display_name}
          {!!column.description && (
            <Popup content={column.description}>
              <span>
                {column.display_name}&nbsp;&nbsp;
                <HelpIcon fitted className="column-description-tooltip" />
              </span>
            </Popup>
          )}
        </Table.HeaderCell>
      )
    })}
  </Table.Row>
)
