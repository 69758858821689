import Table from "../../../Table"
import { SendInvitesCell } from "./Styled"

import {
  totalEtaSent,
  totalEtaAvailable,
  totalTakeHomePayable,
  totalTakeHomeEligible,
  totalTakeHomeReserved
} from "../../helpers"
import { displayNumber } from "../../../../helpers/number"

export default ({
  values,
  filters,
  setFilters,
  show_location = false,
  onNavigateToSettings
}) => (
  <Table.TotalsRow>
    {/* Status */}
    <SendInvitesCell onClick={onNavigateToSettings} />
    {/* Last Name */}
    <Table.InputCell
      value={filters?.search_last_name}
      onChange={search_last_name => setFilters({ search_last_name })}
    />
    {/* First Name */}
    <Table.InputCell
      value={filters?.search_first_name}
      onChange={search_first_name => setFilters({ search_first_name })}
    />
    {!!show_location && (
      <Table.InputCell
        value={filters?.search_location}
        onChange={search_location => setFilters({ search_location })}
      />
    )}
    {/* Take Home */}
    <Table.TotalCell
      textAlign="right"
      value={displayNumber(totalTakeHomeEligible(values), 2)}
    />
    {/* Take Home Reserved */}
    <Table.TotalCell
      textAlign="right"
      value={displayNumber(totalTakeHomeReserved(values), 2)}
    />
    {/* Take Home Payable */}
    <Table.TotalCell
      textAlign="right"
      value={displayNumber(totalTakeHomePayable(values), 2)}
    />
    {/* ETA Sent */}
    <Table.TotalCell
      textAlign="right"
      value={displayNumber(totalEtaSent(values), 2)}
    />
    {/* ETA Available */}
    <Table.TotalCell
      textAlign="right"
      value={displayNumber(totalEtaAvailable(values), 2)}
    />
  </Table.TotalsRow>
)
