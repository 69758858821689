import React, { Component } from "react"
import Styled from "styled-components"
import moment from "moment"

import Table from "./../../Table"

import { datetime_format_verbose } from "../../../constants"
import { displayMoney } from "../../../helpers/number"

const TableWrapper = Styled.div`
  overflow-x: auto;
  padding-bottom: 0.5rem;
`

export default class extends Component {
  validChecks = checks => checks.filter(check => check.voided == 0)
  render() {
    const { transactions, selected_location_id, ...props } = this.props
    return (
      <TableWrapper>
        <Table {...props}>
          <Table.Header>
            <Table.Row>
              {!selected_location_id && (
                <Table.HeaderCell>Location</Table.HeaderCell>
              )}
              <Table.HeaderCell>From Employee</Table.HeaderCell>
              <Table.HeaderCell>To Employee</Table.HeaderCell>
              <Table.HeaderCell>Amount ($)</Table.HeaderCell>
              <Table.HeaderCell>Time</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {transactions.map((tx, idx) => (
              <Table.Row key={idx}>
                {!selected_location_id && (
                  <Table.Cell>{tx.fromEmployee.store.name}</Table.Cell>
                )}
                <Table.Cell>{`${
                  tx.fromEmployee.first_name ? tx.fromEmployee.first_name : ""
                } ${
                  tx.fromEmployee.last_name ? tx.fromEmployee.last_name : ""
                }`}</Table.Cell>
                <Table.Cell>{`${
                  tx.toEmployee.first_name ? tx.toEmployee.first_name : ""
                } ${
                  tx.toEmployee.last_name ? tx.toEmployee.last_name : ""
                }`}</Table.Cell>
                <Table.Cell>{displayMoney(tx.amount)}</Table.Cell>
                <Table.Cell>
                  {moment(tx.shared_at).format(datetime_format_verbose)}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </TableWrapper>
    )
  }
}
