import { useState } from "react"

import moment from "moment"
import Styled from "styled-components"

import Popup from "../../../Popup"
import { Badge, Table } from "../../../Shared"
import ExclamationIcon from "../../../Icon/Exclamation"
import InvalidClockOutModal from "../Modal/InvalidClockOut"
import { EditShiftButton, ViewCustomShiftButton } from "./Actions"

import { colors } from "../../../../constants"
import { useBusinessDates } from "../../../../hooks"
import { displayNumber } from "../../../../helpers/number"
import useAuthorization from "../../../../hooks/authorization"
import { reporting_manage_shift } from "../../../../constants/permissions"

export default ({
  toast,
  refetch,
  controls,
  compact_view,
  worked_shifts,
  lock_threshold,
  reportLockThreshold,
  selected_location_id
}) => {
  const { hasPermission } = useAuthorization()
  const { businessDate, businessStart } = useBusinessDates()

  const locationLockThreshold = store_id =>
    reportLockThreshold(store_id, lock_threshold)

  return (
    <TableWrapper>
      <Table
        id="report-table-worked-shifts"
        compact
        singleLine
        unstackable
        draggable={true}
        controls={controls}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Employee</Table.HeaderCell>
            {!compact_view && <Table.HeaderCell>Payroll ID</Table.HeaderCell>}
            <Table.HeaderCell>Job Code</Table.HeaderCell>
            {!selected_location_id && (
              <Table.HeaderCell>Location</Table.HeaderCell>
            )}
            <Table.HeaderCell>Day</Table.HeaderCell>
            <Table.HeaderCell>Clock In</Table.HeaderCell>
            <Table.HeaderCell>Clock Out</Table.HeaderCell>
            <Table.HeaderCell>Time Worked</Table.HeaderCell>
            <Table.HeaderCell>Cash Tips ($)</Table.HeaderCell>
            <Table.HeaderCell collapsing />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {worked_shifts.map((worked_shift, idx) => {
            const lock_threshold = locationLockThreshold(
              worked_shift.employee.store.id
            )
            const shift_locked = lock_threshold.isAfter(worked_shift.start)

            return (
              <Table.Row key={idx}>
                <Table.Cell>
                  {`${
                    worked_shift.employee.first_name
                      ? worked_shift.employee.first_name
                      : ""
                  } ${
                    worked_shift.employee.last_name
                      ? worked_shift.employee.last_name
                      : ""
                  }`}
                </Table.Cell>
                {!compact_view && (
                  <Table.CopyToClipboardCell
                    enable_copy={!!worked_shift.employee.payroll_id}
                  >
                    {worked_shift.employee.payroll_id
                      ? worked_shift.employee.payroll_id
                      : "-"}
                  </Table.CopyToClipboardCell>
                )}
                <Table.Cell>
                  {!worked_shift.employee.assignedJobCode && (
                    <>{worked_shift.jobCode.name}</>
                  )}
                  {!!worked_shift.employee.assignedJobCode && (
                    <>
                      {worked_shift.employee.assignedJobCode.name}&nbsp;&nbsp;
                      <Badge compact size="small">
                        Employee Assignment
                      </Badge>
                    </>
                  )}
                </Table.Cell>
                {!selected_location_id && (
                  <Table.Cell>{worked_shift.employee.store.name}</Table.Cell>
                )}
                <Table.Cell>
                  {moment(worked_shift.start).format("ddd, MMM Do")}
                </Table.Cell>
                <Table.Cell>
                  {moment(worked_shift.start).format("h:mm a")}
                </Table.Cell>
                <Table.Cell>
                  <ClockOutTime
                    worked_shift={worked_shift}
                    can_edit={hasPermission(reporting_manage_shift)}
                    businessDate={businessDate}
                  />
                </Table.Cell>
                <Table.Cell>{formatTimeWorked(worked_shift)}</Table.Cell>
                <Table.Cell>
                  {worked_shift.cash_tips > 0
                    ? displayNumber(worked_shift.cash_tips, 2)
                    : "-"}
                </Table.Cell>
                <Table.Cell textAlign="right" collapsing>
                  {worked_shift.is_custom && (
                    <>
                      <ViewCustomShiftButton
                        worked_shift={worked_shift}
                        onShiftDeleted={shift_locked ? false : () => refetch()}
                      />
                    </>
                  )}
                  {hasPermission(reporting_manage_shift) && (
                    <EditShiftButton
                      worked_shift={worked_shift}
                      locked={shift_locked}
                      onShiftEdited={({ updateWorkedShift }) => {
                        const before_current_day = moment(
                          updateWorkedShift.start
                        ).isBefore(businessStart())
                        toast({
                          type: "success",
                          message: (
                            <>
                              <p>Worked shift updated successfully.</p>
                              {before_current_day && (
                                <p>
                                  The breakdown is currently reprocessing to
                                  reflect your changes.
                                </p>
                              )}
                            </>
                          )
                        })
                        refetch()
                      }}
                    />
                  )}
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </TableWrapper>
  )
}

// helper functions
const formatTimeWorked = shift => {
  const shift_end = !!shift.end ? shift.end : moment()
  const minutes_worked = moment(shift_end)
    .startOf("minute")
    .diff(moment(shift.start).startOf("minute"), "m")
  const display_hrs = Math.floor(minutes_worked / 60)
  const display_mns = minutes_worked % 60
  let display_time = "-"
  if (minutes_worked > 0) {
    display_time = `${
      display_hrs > 0 ? `${display_hrs}hr ` : ``
    }${display_mns}m`
  }

  return display_time
}

// styled components
const TableWrapper = Styled.div`
  overflow-x: auto;
  padding-bottom: 0.5rem;
`

const ClockOutTime = Styled(
  ({ className, worked_shift, can_edit, businessDate }) => {
    const [detail_modal, setDetailModal] = useState(false)
    return (
      <>
        {!worked_shift.end &&
          businessDate(worked_shift.start) === businessDate() &&
          "-"}
        {!!worked_shift.end &&
          businessDate(worked_shift.start) === businessDate(worked_shift.end) &&
          moment(worked_shift.end).format("h:mm a")}
        {businessDate(worked_shift.start) !==
          businessDate(worked_shift.end) && (
          <>
            <Popup
              delay
              hoverable
              content={
                <>
                  <p>This shift has an invalid end time.</p>
                  <p>Click to learn more.</p>
                </>
              }
            >
              <div className={className} onClick={() => setDetailModal(true)}>
                {!worked_shift.end && "None"}
                {!!worked_shift.end &&
                  moment(worked_shift.end).format("MMM Do h:mm a")}
                &nbsp;&nbsp;
                <ExclamationIcon />
              </div>
            </Popup>
            {!!detail_modal && (
              <InvalidClockOutModal
                can_edit={can_edit}
                onClose={() => setDetailModal(false)}
              />
            )}
          </>
        )}
      </>
    )
  }
)`
  display: inline-block;
  line-height: 1.9rem;
  cursor: pointer;
  border-radius: 2px;
  padding: 0 0.5rem;
  margin-left: -0.5rem;
  border: 0.5px solid transparent;
  transition: background 0.1s linear, border 0.1s linear;
  &:hover {
    background-color: ${colors.light3};
    border: 0.5px solid ${colors.light4};
  }
`
