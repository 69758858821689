import { Component } from "react"

import ContentWrapper from "./ContentWrapper"
import { FullPage, FULL_PAGE_FADE_MS } from "./Styled"

import { initializeAnalytics, resetAnalytics } from "../../helpers"
import TippyLoader from "../Shared/TippyLoader"

const INTRO_VISIBLE = 0
const INTRO_FADE = 1
const INTRO_HIDDEN = 2
let mounted = false

export default class extends Component {
  state = { intro_status: INTRO_VISIBLE }

  componentDidMount() {
    mounted = true
    initializeAnalytics(this.props.user)
    this.props.initNightMode()
  }

  componentWillUnmount() {
    mounted = false
    resetAnalytics()
  }

  render() {
    const { user } = this.props
    const { intro_status } = this.state

    return (
      <>
        {!!user && (
          <FullPage
            fading={intro_status !== INTRO_FADE ? true : false}
            night_mode={this.props.night_mode}
          >
            <TippyLoader />
          </FullPage>
        )}
        {!!user && !!user.stores && (
          <ContentWrapper
            {...this.props}
            onMount={() => {
              this.setState({ intro_status: INTRO_FADE })
              setTimeout(() => {
                if (mounted) {
                  this.setState({ intro_status: INTRO_HIDDEN })
                }
              }, FULL_PAGE_FADE_MS)
            }}
          />
        )}
      </>
    )
  }
}
