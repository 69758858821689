import moment from "moment"
import Styled from "styled-components"

import Dropdown from "./Dropdown"

import { hour_noon } from "../../../constants"
import { toInt } from "../../../helpers/number"
import { displayHour, displayMinute } from "../../../helpers/datetime"

export const MERIDIEM_AM = "AM"
export const MERIDIEM_PM = "PM"

const HOUR_DEFAULT = 12
const MINUTE_DEFAULT = 0
const SECOND_DEFAULT = 0
const MERIDIEM_DEFAULT = MERIDIEM_PM

const INPUT_VALUE_DEFAULT = {
  hour: HOUR_DEFAULT,
  minute: MINUTE_DEFAULT,
  second: SECOND_DEFAULT,
  meridiem: MERIDIEM_DEFAULT
}

// helper that makes it easier to convert selected inputs to a float.
export const timeValueToFloat = ({ hour, minute, meridiem }) =>
  (hour === hour_noon ? 0 : hour) +
  minute / 60 +
  (meridiem?.toLowerCase() === MERIDIEM_PM.toLowerCase() ? hour_noon : 0)

// helper that makes it easier to convert selected inputs into a datetime object.
// time_reference is a datetime, not a date.
export const timeValueToDateTime = (time_value, time_reference, businessDate) =>
  toBusinessTime(
    moment().set({
      hour: Math.floor(timeValueToFloat(time_value)),
      minute: time_value.minute,
      second: time_value.second
    }),
    businessDate(time_reference, false),
    businessDate
  )

// helper that makes it easier to convert a moment object to a Time input value.
export const momentToTimeValue = datetime => ({
  hour: toInt(datetime.format("h")),
  minute: datetime.minute(),
  meridiem: datetime.format("a")
})

const toBusinessTime = (time_reference, date_reference, businessDate) => {
  // create a new datetime using date from date_reference and time from time_reference
  const datetime = moment(date_reference).set({
    hour: time_reference.get("hour"),
    minute: time_reference.get("minute"),
    second: time_reference.get("second")
  })
  // is resulting biz dates aren't the same, time must be after
  // midnight and before biz-start-time. therefore, add a day to datetime.
  if (!date_reference.isSame(businessDate(datetime))) {
    datetime.add(1, "d")
  }
  return datetime
}

const prepareValue = (value = {}) => ({ ...INPUT_VALUE_DEFAULT, ...value })

export default ({ value = {}, onChange = () => {} }) => {
  const prepared_value = prepareValue(
    moment.isMoment(value) ? momentToTimeValue(value) : value
  )
  const { hour, minute, meridiem } = prepared_value

  return (
    <TimeInput
      hour={hour}
      minute={minute}
      meridiem={meridiem}
      onChangeHour={hour => onChange({ ...prepared_value, hour })}
      onChangeMinute={minute => onChange({ ...prepared_value, minute })}
      onChangeMeridiem={meridiem => onChange({ ...prepared_value, meridiem })}
    />
  )
}

const TimeInput = Styled(
  ({
    className,
    hour,
    minute,
    meridiem,
    onChangeHour,
    onChangeMinute,
    onChangeMeridiem
  }) => (
    <div className={className}>
      <TimeDropdown
        value={hour}
        placeholder={!!hour ? displayHour(hour) : displayHour(HOUR_DEFAULT)}
        onChange={onChangeHour}
        options={hourOptions()}
      />{" "}
      <strong>:</strong>{" "}
      <TimeDropdown
        value={minute}
        placeholder={
          !!minute ? displayMinute(minute) : displayMinute(MINUTE_DEFAULT)
        }
        onChange={onChangeMinute}
        options={minuteOptions()}
      />{" "}
      <TimeDropdown
        value={meridiem.toLowerCase()}
        placeholder={!!meridiem ? meridiem.toUpperCase() : MERIDIEM_DEFAULT}
        onChange={onChangeMeridiem}
        options={[
          { text: MERIDIEM_AM, value: MERIDIEM_AM.toLowerCase() },
          { text: MERIDIEM_PM, value: MERIDIEM_PM.toLowerCase() }
        ]}
      />
    </div>
  )
)`
  line-height: 2.6rem;
`

const TimeDropdown = Styled(({ onChange = () => {}, ...props }) => (
  <Dropdown
    icon={false}
    search
    selection
    onChange={(e, { value }) => onChange(value)}
    min_width_expanded="3.6rem"
    min_width_collapsed="3.6rem"
    {...props}
  />
))`
  & div.visible.menu {
    max-height: 13rem !important;
  }
`

const minuteOptions = () =>
  [...Array(60).keys()].map(min => ({
    key: min,
    text: displayMinute(min),
    value: min
  }))
const hourOptions = () =>
  [...Array(12).keys()].map(hour => ({
    key: hour,
    text: displayHour(hour + 1),
    value: hour + 1
  }))
