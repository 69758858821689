import Info from "./Info"
import Warning from "./Warning"
import Question from "./Question"
import Exclamation from "./Exclamation"

export default ({ type = "default", ...props }) => {
  switch (type) {
    case "danger":
    case "error":
      return <Exclamation {...props} />
    case "warning":
    case "caution":
      return <Warning {...props} />
    case "question":
    case "help":
      return <Question {...props} />
    case "default":
    case "info":
    default:
      return <Info {...props} />
  }
}
