import React from "react"
import moment from "moment"
import Styled from "styled-components"
import { Accordion, Loader, Table, List } from "semantic-ui-react"

import Modal from "./"
import Link from "../Link"
import Icon from "../Icon"
import ErrorModal from "./ErrorModal"
import ExclamationIcon from "../Icon/Exclamation"
import { SALE_SEARCH } from "../../graphql/queries"

import {
  paths,
  colors,
  project_info,
  month_day_format,
  datetime_format,
  time_format_verbose
} from "../../constants"
import { toInt, displayMoney } from "../../helpers/number"
import { useBusinessDates } from "../../hooks/selectors"
import { useQuery } from "@apollo/client"

const QUERY_PAGE = 1
const QUERY_LIMIT = 100

export default ({ employee, start_time, end_time, onClose }) => {
  const { businessStart } = useBusinessDates()
  const { data, error } = useQuery(SALE_SEARCH, {
    variables: {
      page: QUERY_PAGE,
      first: QUERY_LIMIT,
      filters: {
        has_worked_shift: false,
        employee_id: toInt(employee.id),
        start_time: moment(start_time).format(datetime_format),
        end_time: moment(end_time).format(datetime_format)
      }
    }
  })
  const sales = data?.saleSearch?.data ?? null

  if (!!error) {
    return <ErrorModal />
  }

  return (
    <Modal closeOnEscape size="small" onClose={onClose}>
      <Modal.Header onClose={onClose}>Sales Missing a Job Code</Modal.Header>
      {sales === null && (
        <CenteredContent>
          <Loader active inline />
        </CenteredContent>
      )}
      {sales !== null && (
        <Modal.Content>
          <ReviewSales>
            <Line>
              <ExclamationIcon /> {sales.length} of {employee.first_name}
              's sales {sales.length > 1 ? "were" : "was"} not associated with a
              job code{" "}
              {businessStart(start_time) === businessStart(end_time)
                ? `on ${businessStart(start_time, false).format(
                    month_day_format
                  )}`
                : `between ${businessStart(start_time, false).format(
                    month_day_format
                  )} and ${businessStart(end_time, false).format(
                    month_day_format
                  )}`}
              .
            </Line>
          </ReviewSales>
          <ResolveNoneJobCodeAccordion employee={employee} sales={sales} />
        </Modal.Content>
      )}
    </Modal>
  )
}

const ReviewSales = Styled.div``
const Line = Styled.div`
  margin-bottom: ${({ relaxed }) => (!!relaxed ? `0.5rem` : `1rem`)};
  font-weight: 300;
  &:last-child {
    margin-bottom: 0;
  }
`

const StyledAccordion = Styled(props => <Accordion {...props} />)`
  position: relative;
  margin-top: 1.33rem !important;
  &:first-child {
    margin-top: 0 !important;
  }
`
const BasicAccordion = Styled(props => <Accordion {...props} />)`
  position: relative;
  margin-top: 0.67rem !important;
  box-shadow: none !important;
  & > .title {
    border-top: none !important;
  }
`
const AccordionContent = Styled(({ ...props }) => (
  <Accordion.Content {...props} />
))`
  font-weight: 300;
  padding-left: 2.5em !important;
`
const CenteredContent = Styled(props => <Modal.Content {...props} />)`
  text-align: center;
`
const TableWrapper = Styled.div`
  position: relative;
  display: block;
  max-height: 40vh;
  overflow-y: auto;
`

const SituationStep = props => (
  <AccordionContent {...props}>
    <Line>
      A sale's "job code" depends on the employee shift being worked at the time
      the sale is made.
    </Line>
    <Line>
      For example, if an employee clocks in as a "Server" and completes a sale,
      the sale will be matched with the "Server" job code.
    </Line>
    <Line>
      <strong>
        If a sale is made by an employee that did not work a shift, the "None"
        job code is assigned.
      </strong>
    </Line>
  </AccordionContent>
)

const TipDistributionImpactStep = props => (
  <AccordionContent {...props}>
    <Line>
      Yes, if "None" job code is applied unintentionally, it may impact
      tip-distribution calculations.
    </Line>
    <Line>
      <strong>
        Rules and pools will not apply to sales missing a job code.
      </strong>
    </Line>
  </AccordionContent>
)

const ViewSalesStep = ({ sales, ...props }) => (
  <AccordionContent {...props}>
    <Line>
      {sales.length === 1
        ? "Here it is:"
        : `Here are the ${sales.length} sales we couldn't match with a job code:`}
    </Line>
    <Line>
      <TableWrapper>
        <Table compact basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Amount</Table.HeaderCell>
              <Table.HeaderCell>Tip</Table.HeaderCell>
              <Table.HeaderCell>External ID</Table.HeaderCell>
              <Table.HeaderCell>Time of Sale</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {sales
              .slice()
              .sort((a, b) =>
                moment(a.sale_time).isAfter(moment(b.sale_time)) ? 1 : -1
              )
              .map(({ total_sales, total_cc_tips, sale_time, external_id }) => (
                <Table.Row key={external_id}>
                  <Table.Cell>{displayMoney(total_sales)}</Table.Cell>
                  <Table.Cell>
                    {!total_cc_tips ? "-" : displayMoney(total_cc_tips)}
                  </Table.Cell>
                  <Table.Cell>{external_id}</Table.Cell>
                  <Table.Cell>
                    {moment(sale_time).format(time_format_verbose)}
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </TableWrapper>
    </Line>
    {!!sales.find(({ total_cc_tips }) => !total_cc_tips) && (
      <Line>
        <strong>Note:</strong>
        &nbsp;&nbsp;Sales without tips do not effect tip distribution unless
        sale-based rules are active.
      </Line>
    )}
  </AccordionContent>
)

const ResolutionStep = ({ employee = undefined, ...props }) => (
  <AccordionContent {...props}>
    <Line relaxed>
      There are a couple of ways to address sales needing a job code assigned.
    </Line>
    <ResolutionSolutionsAccordion employee={employee} />
  </AccordionContent>
)

const ResolveNoneJobCodeAccordion = ({ employee, sales }) => (
  <StyledAccordion
    styled
    fluid
    panels={[
      {
        key: "situation",
        title: "Why are some sales missing a job code?",
        content: <SituationStep />
      },
      {
        key: "impact",
        title: 'Does "None" job code impact tip distribution?',
        content: <TipDistributionImpactStep />
      },
      {
        key: "data",
        title:
          sales.length === 1
            ? "Show me which sale is missing a job code."
            : "Show me the sales missing a job code.",
        content: <ViewSalesStep sales={sales} />
      },
      {
        key: "resolution",
        title:
          sales.length === 1
            ? `How can I update this sale for ${employee.first_name}?`
            : `How can I update these ${sales.length} sales for ${employee.first_name}?`,
        content: <ResolutionStep />
      }
    ]}
  />
)

const ResolutionSolutionsAccordion = ({ employee = null }) => (
  <BasicAccordion
    fluid
    panels={[
      {
        key: "resolve.update-shifts",
        title: {
          icon: <span>Solution 1. </span>,
          content: (
            <>
              Ensure all {!!employee && <>{employee.first_name}'s</>} sales are
              covered by a shift.&nbsp;&nbsp;
              <i style={{ fontWeight: 300 }}>recommended</i>
            </>
          )
        },
        content: (
          <AccordionContent>
            <Line>
              Review {!!employee && <>{employee.first_name}'s</>} sales and make
              sure they were all completed during a worked shift. If you find
              any errors, address them through your point-of-sale provider.
            </Line>
            <Line>
              When {project_info.name} finds an updated sale or shift, we
              automatically recalculate the breakdown.
            </Line>
            <Line>
              <strong>Note:</strong>&nbsp;&nbsp;It may take 15 minutes or so for
              updates appear after updating your point-of-sale.
            </Line>
          </AccordionContent>
        )
      },
      {
        key: "resolve.contact-support",
        title: {
          icon: <span>Solution 2. </span>,
          content: "Assign a job code to individual sales."
        },
        content: (
          <AccordionContent>
            <Line>
              In some cases you may want to assign a specific job code to a
              handful of sales.
            </Line>
            <Line>You can do this from the Sales reporting page.</Line>
            <List ordered>
              <List.Item>
                Navigate to the{" "}
                <Link target="_blank" href={paths.reportSales}>
                  Sales Report
                </Link>
                .
              </List.Item>
              <List.Item>
                Select "Batch Job Code Assignment" under the "Actions" dropdown.
              </List.Item>
              <List.Item>Select a job code.</List.Item>
              <List.Item>
                Select one or more sales for job code assignment.
              </List.Item>
            </List>
            <Line>The breakdown will reprocess after changes are saved.</Line>
          </AccordionContent>
        )
      },
      {
        key: "resolve.setup-override",
        title: {
          icon: <span>Solution 3. </span>,
          content: `Assign a job code override to an employee.`
        },
        content: (
          <AccordionContent>
            <Line>
              In some cases you may want the same job code to always apply to an
              employee.
            </Line>
            <Line>You can do this from the Location settings page.</Line>
            <List ordered>
              <List.Item>
                Navigate to{" "}
                <Link target="_blank" href={paths.settingsLocations}>
                  Location Settings
                </Link>
                .
              </List.Item>
              <List.Item>
                Find{" "}
                {!!employee ? employee.store.name : "the appropriate location"}{" "}
                and click the Employees tab.
              </List.Item>
              <List.Item>
                Find{" "}
                {!!employee ? employee.first_name : "the appropriate employee"}{" "}
                and click the{" "}
                <Icon name="plus circle" color={colors.tween} fitted />
                icon to assign a job code override.
              </List.Item>
            </List>
            <Line>
              The breakdown will reprocess after changes are submitted.
            </Line>
          </AccordionContent>
        )
      }
    ]}
  />
)

export const SaleMissingJobCodeModal = ({ onClose }) => (
  <Modal onClose={onClose} size="tiny">
    <Modal.Header onClose={onClose}>Sale Missing Job Code</Modal.Header>
    <Modal.Content>
      <DescribeNoneJobCodeAccordion />
    </Modal.Content>
  </Modal>
)

const DescribeNoneJobCodeAccordion = () => (
  <StyledAccordion
    styled
    fluid
    panels={[
      {
        key: "situation",
        title: "Why are some sales missing a job code?",
        content: <SituationStep />
      },
      {
        key: "impact",
        title: 'Does "None" job code impact tip distribution?',
        content: <TipDistributionImpactStep />
      },
      {
        key: "resolution",
        title: "How can I apply the correct job code?",
        content: <ResolutionStep />
      }
    ]}
  />
)
