import { combineReducers } from "redux"
import { connectRouter } from "connected-react-router"

import appReducer from "./app-reducer"
import modalReducer from "./modal-reducer"
import appFiltersReducer from "./app-filters-reducer"

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    app: appReducer,
    modal: modalReducer,
    app_filters: appFiltersReducer
  })

export default rootReducer
