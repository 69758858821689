import { useIntercom } from "react-use-intercom"

import Section from "../Section"
import { RouteTabs } from "../Tabs"
import UsersManager from "./Users/"
import TipFeeManager from "./TipFee"
import P2PManager from "./PeerToPeer"
import PayPeriodManager from "./PayPeriod"
import LocationsManager from "./Locations"
import JobCodeManager from "./Locations/JobCodes"
import RouteContent from "../RouteContent"
import { Link, PageIntroduction } from "../Shared"
import tour from "./tour"
import { paths, project_info } from "../../constants"
import { useBusinessDates, useEtaEnabled, useUser } from "../../hooks"
import {
  USERS_TAB,
  TIP_FEE_TAB,
  P2P_TAB,
  LOCATIONS_TAB,
  JOB_CODES_TAB,
  PAY_PERIOD_TAB,
  useSelectedTab,
  useCanViewSettingsTab
} from "./helpers"
import useAuthorization from "../../hooks/authorization"
import {
  settings_manage_pay_period,
  settings_manage_recent_pay_periods
} from "../../constants/permissions"

export default props => {
  const { setRoutesVisited, location, history } = props
  const user = useUser()
  const { hasPermission } = useAuthorization()
  const { canViewTab } = useCanViewSettingsTab()
  const [selected_tab, setSelectedTab] = useSelectedTab({ location, history })
  const bizDates = useBusinessDates()
  const { show: showIntercom } = useIntercom()
  const eta_enabled = useEtaEnabled()

  return (
    <>
      <RouteContent
        tour={tour}
        header="Settings"
        route_name="settings"
        setRoutesVisited={setRoutesVisited}
      >
        <RouteTabs
          navigation_path={paths.settings}
          tabs={[
            {
              name: <>Location{user.stores.length > 1 && "s"}</>,
              id: LOCATIONS_TAB,
              selected: selected_tab === LOCATIONS_TAB
            },
            {
              name: "Users",
              id: USERS_TAB,
              selected: selected_tab === USERS_TAB
            },
            {
              name: "Credit Card Tip Fee",
              id: TIP_FEE_TAB,
              selected: selected_tab === TIP_FEE_TAB,
              visible: canViewTab(TIP_FEE_TAB)
            },
            {
              name: "Pay Period",
              id: PAY_PERIOD_TAB,
              selected: selected_tab === PAY_PERIOD_TAB,
              visible: canViewTab(PAY_PERIOD_TAB)
            },
            {
              name: "Peer-to-Peer",
              id: P2P_TAB,
              selected: selected_tab === P2P_TAB,
              visible: canViewTab(P2P_TAB)
            },
            {
              name: "Job Codes",
              id: JOB_CODES_TAB,
              selected: selected_tab === JOB_CODES_TAB,
              visible: selected_tab === JOB_CODES_TAB
            }
          ]}
          onChange={tab => setSelectedTab(tab.id)}
        >
          {selected_tab === LOCATIONS_TAB && (
            <Section>
              <Section.Content>
                <SettingInfo>
                  Manage employees, job codes, and general settings at your
                  location
                  {user.stores.length > 1 && "s"}.
                </SettingInfo>
                <LocationsManager {...props} />
              </Section.Content>
            </Section>
          )}
          {selected_tab === USERS_TAB && (
            <Section>
              <Section.Content>
                <UsersManager {...props} user={{ ...user }} />
              </Section.Content>
            </Section>
          )}
          {selected_tab === TIP_FEE_TAB && (
            <Section>
              <Section.Content>
                <SettingInfo>
                  CC tip fees are deducted from take home pay after tip
                  distribution applies.{" "}
                  {user.stores.length > 1 && (
                    <>
                      You can customize the tip fee for individual locations
                      from the{" "}
                      <Link href={paths.settingsLocations} target="_blank">
                        Locations tab
                      </Link>
                      .
                    </>
                  )}
                </SettingInfo>
                <TipFeeManager {...props} />
              </Section.Content>
            </Section>
          )}
          {selected_tab === PAY_PERIOD_TAB && (
            <Section>
              <Section.Content>
                {eta_enabled && (
                  <SettingInfo>
                    To ensure accurate ETA distributions, your Pay Period
                    settings are locked. Please{" "}
                    <Link onClick={showIntercom}>contact</Link> the support team
                    if you need to make any updates.
                  </SettingInfo>
                )}
                {!eta_enabled && (
                  <SettingInfo>
                    Simplify payroll by setting up your organization's pay
                    period.
                  </SettingInfo>
                )}
                <PayPeriodManager
                  {...props}
                  {...bizDates}
                  // pass prop because PayPeriodManager is a class component
                  can_edit_pay_period={hasPermission(
                    settings_manage_pay_period
                  )}
                  can_edit_recent_pay_periods={hasPermission(
                    settings_manage_recent_pay_periods
                  )}
                  showIntercom={showIntercom}
                />
              </Section.Content>
            </Section>
          )}
          {selected_tab === P2P_TAB && (
            <Section>
              <Section.Content>
                <SettingInfo>
                  Enable or disable peer-to-peer tip sharing for{" "}
                  {project_info.employee_app_name} mobile app users.
                </SettingInfo>
                <P2PManager {...props} />
              </Section.Content>
            </Section>
          )}
          {/* Deprecated Tabs Start - Still referenced at Alert/InvalidTakeHome/SummaryModal.js */}
          {selected_tab === JOB_CODES_TAB && (
            <Section>
              <Section.Content>
                <SettingInfo>
                  Review job codes, manage employee assignments, and disable
                  irrelevant job codes.
                </SettingInfo>
                <JobCodeManager {...props} />
              </Section.Content>
            </Section>
          )}
        </RouteTabs>
      </RouteContent>
    </>
  )
}

const SettingInfo = props => <PageIntroduction right_pad={false} {...props} />
