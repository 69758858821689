import { Icon } from "../../Shared"
import {
  FooterPanel,
  FooterIconWrapper,
  RotatingArrowIcon,
  FooterItemWrapper,
  FooterNameWrapper
} from "./Styled"

import { colors } from "../../../constants"

export default ({ expanded, onClick, isMobile }) => (
  <FooterPanel>
    {!isMobile && (
      <FooterItemWrapper onClick={e => onClick(e)}>
        <FooterIconWrapper>
          <RotatingArrowIcon expanded={expanded}>
            <Icon name="arrow right" />
          </RotatingArrowIcon>
        </FooterIconWrapper>
      </FooterItemWrapper>
    )}

    {!!isMobile && !expanded && (
      <FooterItemWrapper onClick={onClick}>
        <FooterNameWrapper id="sidebar-item-menu" name="Menu" />
        <FooterIconWrapper>
          <RotatingArrowIcon expanded={false}>
            {(!expanded || !isMobile) && (
              <Icon fitted name="bars" color={colors.white} />
            )}
            {!!expanded && !!isMobile && (
              <Icon fitted name="remove" color={colors.white} />
            )}
          </RotatingArrowIcon>
        </FooterIconWrapper>
      </FooterItemWrapper>
    )}
  </FooterPanel>
)
