import { useEffect, useState } from "react"
import Styled from "styled-components"
import { useQuery } from "@apollo/client"
import { Menu } from "semantic-ui-react"

import {
  Icon,
  Link,
  Button,
  Loader,
  Dropdown,
  HelpIcon,
  InfoIcon,
  MultiSelectList
} from "../../../../Shared"
import { TextInput, Field, FieldName } from "../../../Styled"

import DistributionMethodsModal from "../../../../Modal/DistributionMethods"
import { SHARED_SALES_CATEGORY_NAMES } from "../../../../../graphql/queries"
import {
  BASE_DISTRIBUTION_METHODS,
  WEEKLY_ENABLED_DISTRIBUTION_METHODS,
  FLAT_RATE_BASE_DISTRIBUTION_METHODS,
  FLAT_RATE_WEEKLY_ENABLED_DISTRIBUTION_METHODS,
  DISTRO_METHOD_WEEKLY,
  TIP_DISTRO_SOURCES_ALL,
  TIP_DISTRO_SOURCES_DEFAULT,
  CASH_WEEKLY_ENABLED_DISTRIBUTION_METHODS,
  DISTRO_SOURCE_CASH,
  DISTRO_METHOD_DAILY,
  DISTRO_METHOD_SALE_TIME,
  DISTRO_METHOD_SHIFT_TIME,
  RULE_TYPE_SHARE,
  RULE_TYPE_FLAT_RATE
} from "../../../helpers"
import {
  useCashTipDistributionEnabled,
  useFlatRateDistributionEnabled,
  useWeeklyDistributionEnabled
} from "../../../../../hooks"

const NONE_CATEGORY = "None"

export default props => {
  const {
    type,
    source,
    percent,
    is_edit,
    onUpdate,
    store_ids,
    sale_categories,
    distribution_method,
    distribution_sources,
    rule_has_revenue_centers = false
  } = props
  const [distribution_methods_modal, setDistributionMethodsModal] = useState(
    false
  )

  const cash_tip_rules_enabled =
    (useCashTipDistributionEnabled() || !!source.cash) &&
    !rule_has_revenue_centers
  const weekly_distributions_enabled = useWeeklyDistributionEnabled()
  const flat_rate_rules_enabled = useFlatRateDistributionEnabled()

  const handleRuleTypeChange = (e, { value }) => {
    let payload = {
      type: value,
      // if distribution method is time of shift, reset to daily
      distribution_method:
        distribution_method === DISTRO_METHOD_SHIFT_TIME
          ? DISTRO_METHOD_DAILY
          : distribution_method
    }
    // if selecting flat rate, auto select the tips source option
    if (value === RULE_TYPE_FLAT_RATE) {
      payload = {
        type: value,
        source: { sales: false, tips: true, cash: false },
        // reset distribution sources if tips wasn't already selected
        distribution_sources: !source.tips
          ? [...TIP_DISTRO_SOURCES_DEFAULT]
          : distribution_sources,
        // if distribution method is time of sale, reset to daily
        distribution_method:
          distribution_method === DISTRO_METHOD_SALE_TIME
            ? DISTRO_METHOD_DAILY
            : distribution_method
      }
    }
    onUpdate(payload)
  }

  const mapDistributionMethodOptions = options =>
    Object.keys(options).map(name => ({
      key: name,
      value: name,
      text: name
    }))

  return (
    <>
      <Field name="Distribute">
        <CollectMenu>
          <CollectMenuItem>
            <RateInput
              value={percent}
              onChange={percent => onUpdate({ percent })}
              type={
                flat_rate_rules_enabled && type === RULE_TYPE_FLAT_RATE
                  ? RATE_TYPE_MONEY
                  : RATE_TYPE_PERCENT
              }
            />
            {flat_rate_rules_enabled && !is_edit ? (
              <RuleTypeDropdown value={type} onChange={handleRuleTypeChange} />
            ) : type === RULE_TYPE_FLAT_RATE ? (
              <FlatRateIcon style={{ fontSize: "1.6rem" }} />
            ) : (
              <TipShareIcon style={{ fontSize: "1.6rem" }} />
            )}
          </CollectMenuItem>
          <CollectMenuItem>
            <FieldName>of</FieldName>
          </CollectMenuItem>
          <CollectMenuItem>
            <ButtonsWrapper>
              <Button.Group>
                <Button
                  id="rule-editor-source-sales-btn"
                  primary={!!source.sales}
                  size="huge"
                  disabled={type === RULE_TYPE_FLAT_RATE}
                  onClick={e =>
                    onUpdate({
                      source: { sales: true, tips: false, cash: false }
                    })
                  }
                >
                  Sales
                </Button>
                <Button.Or
                  className={type === RULE_TYPE_FLAT_RATE ? "disabled" : ""}
                />
                <Button
                  id="rule-editor-source-tips-btn"
                  primary={!!source.tips}
                  size="huge"
                  onClick={e =>
                    onUpdate({
                      source: { sales: false, tips: true, cash: false },
                      distribution_sources: [...TIP_DISTRO_SOURCES_DEFAULT]
                    })
                  }
                >
                  Tips
                </Button>
                {!!cash_tip_rules_enabled && (
                  <>
                    <Button.Or
                      className={type === RULE_TYPE_FLAT_RATE ? "disabled" : ""}
                    />
                    <Button
                      id="rule-editor-source-tips-btn"
                      primary={!!source.cash}
                      size="huge"
                      disabled={type === RULE_TYPE_FLAT_RATE}
                      onClick={e =>
                        onUpdate({
                          source: { sales: false, tips: false, cash: true },
                          distribution_method: DISTRO_METHOD_DAILY,
                          distribution_sources: [DISTRO_SOURCE_CASH]
                        })
                      }
                    >
                      Cash
                    </Button>
                  </>
                )}
              </Button.Group>
            </ButtonsWrapper>
          </CollectMenuItem>
          <CollectMenuItem>
            <FieldName
              description={
                <>
                  <p>
                    Tips are distributed to employees based on minutes worked
                    during daily, hourly, and other incremental frequencies.
                  </p>
                  {type === RULE_TYPE_FLAT_RATE ? (
                    <p>
                      The <strong>Time of Shift</strong> option distributes
                      based on minutes worked between each employee shifts start
                      and end time.
                    </p>
                  ) : (
                    <p>
                      The <strong>Time of Sale</strong> option distributes based
                      on minutes worked between each sale's open and close time.
                    </p>
                  )}
                  <Link
                    font_weight={600}
                    onClick={() => setDistributionMethodsModal(true)}
                  >
                    Learn More
                  </Link>
                </>
              }
            >
              frequency
            </FieldName>
          </CollectMenuItem>
          <CollectMenuItem>
            <FieldName>
              {/** Base level distribution methods set on organization settings */}
              {!weekly_distributions_enabled && (
                <DistroMethodsDropdown
                  disabled={!!source.cash}
                  onChange={(e, { value }) => {
                    onUpdate({
                      distribution_method: value
                    })
                  }}
                  options={mapDistributionMethodOptions(
                    type === RULE_TYPE_FLAT_RATE
                      ? FLAT_RATE_BASE_DISTRIBUTION_METHODS
                      : BASE_DISTRIBUTION_METHODS
                  )}
                  value={distribution_method}
                />
              )}
              {/** Weekly distribution methods setting enabled on organization and pay period setting is valid */}
              {weekly_distributions_enabled && !source.cash && (
                <DistroMethodsDropdown
                  disabled={!!source.cash}
                  onChange={(e, { value }) => {
                    onUpdate({
                      distribution_method: value
                    })
                  }}
                  options={mapDistributionMethodOptions(
                    type === RULE_TYPE_FLAT_RATE
                      ? FLAT_RATE_WEEKLY_ENABLED_DISTRIBUTION_METHODS
                      : WEEKLY_ENABLED_DISTRIBUTION_METHODS
                  )}
                  value={distribution_method}
                />
              )}{" "}
              {/** CASH RULE DAILY OR WEEKLY ONLY - Weekly distribution methods setting enabled on organization and pay period setting is valid */}
              {weekly_distributions_enabled && !!source.cash && (
                <DistroMethodsDropdown
                  onChange={(e, { value }) => {
                    onUpdate({
                      distribution_method: value
                    })
                  }}
                  options={mapDistributionMethodOptions(
                    CASH_WEEKLY_ENABLED_DISTRIBUTION_METHODS
                  )}
                  value={distribution_method}
                />
              )}{" "}
              {/** Notify user that custom schedules will not apply when weekly is selected */}
              {distribution_method === DISTRO_METHOD_WEEKLY && (
                <InfoIcon
                  name="info circle"
                  popup={{
                    content: (
                      <>
                        <p>
                          Custom schedules will not apply when using{" "}
                          <strong>Weekly</strong> distribution.
                        </p>
                      </>
                    )
                  }}
                />
              )}
            </FieldName>
          </CollectMenuItem>
        </CollectMenu>
        {!!source.cash && (
          <>
            <HelpIcon fitted />
            &nbsp;&nbsp;Distribute a percentage of declared cash tips.
          </>
        )}
      </Field>
      {!!source.sales && (
        <Field
          name="Apply To"
          description={
            <>
              <p>Select one or more menu item categories below.</p>
              <p>
                The amount shared is based on the sale price of items belonging
                to selected categories.
              </p>
            </>
          }
        >
          <div id="rule-editor-sale-categories-input">
            <SaleCategoriesInput
              store_ids={store_ids}
              sale_categories={sale_categories}
              onUpdate={selected =>
                onUpdate({
                  sale_categories: selected.map(({ id }) => id),
                  percent,
                  source
                })
              }
            />
          </div>
        </Field>
      )}
      {!!source.tips && (
        <Field
          name="Apply To"
          description={
            <>
              <p>Select one or more tip types below.</p>
              <p>This rule will only apply to the types you select.</p>
            </>
          }
        >
          <div id="rule-editor-tip-sources-input">
            <MultiSelectList
              available={TIP_DISTRO_SOURCES_ALL.map(source => ({
                name: source,
                id: source
              }))}
              selected={distribution_sources.map(source => ({
                id: source,
                name: source
              }))}
              onUpdate={selected =>
                onUpdate({
                  distribution_sources: selected.map(option => option.name)
                })
              }
            />
          </div>
        </Field>
      )}
      {!!distribution_methods_modal && (
        <DistributionMethodsModal
          onClose={() => setDistributionMethodsModal(false)}
        />
      )}
    </>
  )
}

const Loading = () => (
  <div>
    <Loader inline active />
  </div>
)

const CollectMenu = Styled(props => (
  <Menu borderless {...props} id="rule-editor-collect-input-group" />
))`
  box-shadow: none !important;
`
const CollectMenuItem = Styled(({ ...props }) => <Menu.Item {...props} />)``

const ButtonsWrapper = Styled.div`
  display: inline-block;
  & div.or {
    margin-top: auto;
    margin-bottom: auto;
  }
  & div.or.disabled {
    opacity: 0;
  }
  & .ui.disabled.button {
    opacity: 0.15 !important;
  }
`

const DistroMethodsDropdown = Styled(props => (
  <Dropdown id="rule-editor-distro-frequency" button inline {...props} />
))``

const TipShareIcon = Styled(props => <Icon name="percent" {...props} />)``

const FlatRateIcon = Styled(props => <Icon name="dollar sign" {...props} />)``

const RuleTypeDropdown = Styled(props => {
  return (
    <Dropdown
      {...props}
      inline
      data-testid="rule-type-dropdown"
      options={[
        {
          key: RULE_TYPE_SHARE,
          text: <TipShareIcon />,
          value: RULE_TYPE_SHARE,
          content: <TipShareIcon />
        },
        {
          key: RULE_TYPE_FLAT_RATE,
          text: <FlatRateIcon />,
          value: RULE_TYPE_FLAT_RATE,
          content: (
            <>
              <FlatRateIcon />
              <span className="option-text">/ hr</span>
            </>
          )
        }
      ]}
    />
  )
})`
  &.ui.dropdown {
    > .text {
      /* prevents menu from jumping left and right when changing icons */
      width: 24px;
      line-height: 1;
      vertical-align: middle;
      font-size: 1.6em;
    }
    > .dropdown.icon {
      margin-left: 0;
    }
    .menu > .item > .option-text {
      margin-left: -11px;
    }
  }
`

const SaleCategoriesInput = ({ store_ids, sale_categories, onUpdate }) => {
  const { data, loading } = useQuery(SHARED_SALES_CATEGORY_NAMES, {
    variables: { store_ids }
  })

  const categoryName = category =>
    category === NONE_CATEGORY ? "No Category" : category

  let available_categories
  if (!!loading) {
    return <Loading />
  }

  available_categories = data.sharedSalesCategoryNames
    .map(sales_category => ({
      name: categoryName(sales_category),
      id: sales_category
    }))
    .sort((a, b) => a.name.localeCompare(b.name))
  return (
    <MultiSelectList
      available={available_categories}
      selected={sale_categories.map(category => ({
        id: category,
        name: categoryName(category)
      }))}
      onUpdate={onUpdate}
    />
  )
}

const RATE_TYPE_PERCENT = "percent"
const RATE_TYPE_MONEY = "money"

const rateInputIsValid = (value, rate_type) => {
  // pecent fields can only have 2 digits of precision
  const cleaned = value?.trim ? value.trim() : ""
  const rate = Number(cleaned)
  return (
    cleaned.length === 0 ||
    (!isNaN(rate) && rate > 0 && (rate_type === RATE_TYPE_MONEY || rate <= 100))
  )
}

const formatInput = (value, rate_type) => {
  let formatted = value
  if ((value.length ?? 0) > 0 && rateInputIsValid(value, rate_type)) {
    // rate field can only have 2 digits of precision
    formatted = Number(value).toFixed(2)
    if (rate_type === RATE_TYPE_PERCENT) {
      // remove trailing zeros when type is percent
      formatted = formatted.replace(/\.?0+$/, "")
    }
  }
  return formatted
}

const RateInput = ({ type, value = "", onChange }) => {
  const [error, setError] = useState(false)
  useEffect(() => {
    if (rateInputIsValid(value, type)) {
      setError(false)
      onChange(formatInput(value, type))
    } else {
      setError(true)
    }
  }, [type])
  return (
    <TextInput
      error={error && value.toString().trim().length > 0}
      style={{ width: "4em" }}
      type="text"
      placeholder="0"
      value={value}
      onFocus={() => setError(false)}
      onBlur={e => {
        const value = e.target.value
        if (rateInputIsValid(value, type)) {
          onChange(formatInput(value, type))
        } else {
          setError(true)
        }
      }}
      onChange={e => {
        const value = e.target.value === "." ? "0." : e.target.value
        onChange(value)
        if (error && rateInputIsValid(value, type)) setError(false)
      }}
    />
  )
}
