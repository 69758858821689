import Styled from "styled-components"
import { Table } from "../../../../../Shared"
import Row from "./Row"

const VerifyUsersTable = Styled(({ className, users, onReviewCompleted }) => (
  <Table fitted compact basic="very" className={className}>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Service</Table.HeaderCell>
        <Table.HeaderCell>Employee</Table.HeaderCell>
        <Table.HeaderCell>Account Detail</Table.HeaderCell>
        <Table.HeaderCell>Eligibility Start</Table.HeaderCell>
        <Table.HeaderCell textAlign="center">Approval</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {[...users].map(({ user, person, transfer, payout_method }, idx) => (
        <Row
          key={user.id}
          row={idx + 1}
          user={user}
          person={person}
          transfer={transfer}
          payout_method={payout_method}
          onReviewCompleted={onReviewCompleted}
        />
      ))}
    </Table.Body>
  </Table>
))`padding-bottom: 7rem;`

export default VerifyUsersTable
