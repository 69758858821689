import { useState } from "react"

import Styled from "styled-components"
import { useQuery, useMutation } from "@apollo/client"

import Button, { SubmitButton } from "../../../../Button"
import { Modal, Loader, AreYouSure, Radio } from "../../../../Shared"

import { isSingleCustomShift } from "./helpers"
import { ToastConsumer } from "../../../../../context"
import { useBusinessDates } from "../../../../../hooks"
import { RECURRING_SHIFT } from "../../../../../graphql/queries"
import { DELETE_RECURRING_SHIFT } from "../../../../../graphql/mutations"

const DeleteRecurringShift = ({ worked_shift, onClose, onSuccess }) => {
  const { data } = useQuery(RECURRING_SHIFT, {
    variables: { id: worked_shift.recurring_shift_id }
  })

  const recurring_shift = data?.recurringShift
  const is_single_shift = recurring_shift
    ? isSingleCustomShift(recurring_shift)
    : undefined

  return (
    <ToastConsumer>
      {toast => (
        <>
          {!recurring_shift && (
            <Modal size="mini" basic onClose={onClose}>
              <Loader inline size="large" />
            </Modal>
          )}
          {!!recurring_shift && (
            <>
              {!!is_single_shift && (
                <DeleteSingleShiftModal
                  onClose={onClose}
                  worked_shift={worked_shift}
                  onSuccess={result => {
                    toast({
                      type: "success",
                      title: "Success",
                      message: <>Your custom shift was deleted.</>
                    })
                    return onSuccess(result)
                  }}
                />
              )}
              {!is_single_shift && (
                <DeleteRecurringModal
                  toast={toast}
                  onClose={onClose}
                  onSuccess={onSuccess}
                  worked_shift={worked_shift}
                />
              )}
            </>
          )}
        </>
      )}
    </ToastConsumer>
  )
}

export default DeleteRecurringShift

const DeleteSingleShiftModal = ({ worked_shift, onClose, onSuccess }) => {
  const [deleteShift, { loading }] = useMutation(DELETE_RECURRING_SHIFT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: result => {
      onSuccess(result)
    }
  })

  return (
    <AreYouSure
      onClose={onClose}
      header="Delete Custom Shift"
      submitted={!!loading}
      onConfirm={() =>
        deleteShift({
          variables: { worked_shift_id: worked_shift.id }
        })
      }
      body={<>Are you sure you want to delete this shift?</>}
    />
  )
}

const OPTION_ALL_SHFTS = "All shifts"
const OPTION_THIS_SHIFT = "This shift"
const OPTION_THIS_AND_FOLLOWING = "This and following shifts"

const DeleteRecurringModal = ({ toast, onClose, onSuccess, worked_shift }) => {
  const { businessDate } = useBusinessDates()
  const [selected, selectOption] = useState(OPTION_THIS_SHIFT)

  const [deleteShift, { loading }] = useMutation(DELETE_RECURRING_SHIFT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: result => {
      switch (selected) {
        case OPTION_THIS_SHIFT:
          toast({
            type: "success",
            title: "Success",
            message: <>Your custom shift was deleted.</>
          })
          break
        case OPTION_THIS_AND_FOLLOWING:
        case OPTION_ALL_SHFTS:
        default:
          toast({
            type: "success",
            title: "Success",
            message: <>Shift(s) deleted and recurring schedule deactivated.</>
          })
          break
      }
      return onSuccess(result)
    }
  })

  return (
    <Modal size="mini" onClose={onClose}>
      <Modal.Header>Remove Recurring Shift?</Modal.Header>
      <Modal.Content>
        <RadioInput
          label={`${OPTION_THIS_SHIFT} (${businessDate(
            worked_shift.start,
            false
          ).format("MMM Do")})`}
          checked={selected === OPTION_THIS_SHIFT}
          onChange={() => selectOption(OPTION_THIS_SHIFT)}
        />
        <RadioInput
          label={OPTION_THIS_AND_FOLLOWING}
          checked={selected === OPTION_THIS_AND_FOLLOWING}
          onChange={() => selectOption(OPTION_THIS_AND_FOLLOWING)}
        />
        <RadioInput
          label={OPTION_ALL_SHFTS}
          checked={selected === OPTION_ALL_SHFTS}
          onChange={() => selectOption(OPTION_ALL_SHFTS)}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Cancel</Button>
        <SubmitButton
          loading={loading}
          onClick={() => {
            deleteShift({
              variables: {
                worked_shift_id: worked_shift.id,
                delete_all: selected === OPTION_ALL_SHFTS,
                delete_future: selected === OPTION_THIS_AND_FOLLOWING
              }
            })
          }}
        />
      </Modal.Actions>
    </Modal>
  )
}

const RadioInput = Styled(({ className, ...props }) => (
  <div className={className}>
    <Radio {...props} />
  </div>
))`
  line-height: 2rem;
`
