import React from "react"

import {
  FooterWrapper,
  FooterLinks,
  FooterLink,
  LinkSeparator,
  FooterLogo
} from "./Styled"

import { paths, urls } from "../../../constants"

export default ({ night_mode = false, user, ...props }) => (
  <FooterWrapper night_mode={!!night_mode}>
    <FooterLinks>
      <FooterLink onClick={e => window.open(urls().marketing, true)}>
        About
      </FooterLink>
      <LinkSeparator />
      <FooterLink
        onClick={() => {
          if (!!user) {
            window.open(paths.contact, true)
          } else {
            props.showModal("contact_support_modal")
          }
        }}
      >
        Contact
      </FooterLink>
    </FooterLinks>
    <LinkSeparator />
    <FooterLogo />
  </FooterWrapper>
)
