import { Component } from "react"

import { rgba } from "polished"
import Styled from "styled-components"

import { Doughnut } from "../Charts/Pie"
import { Button, Table, Modal, Input, Message } from "../Shared"

import { colors } from "../../constants"
import { colorByIndex } from "../Charts/helpers"
import { round, isNumber } from "../../helpers/number"

const MAX_WEIGHT = 10000

export default class extends Component {
  state = { request_set_weight: false }

  totalDistributionWeight = () =>
    this.props.selected_job_codes.reduce((acc, { weight }) => acc + weight, 0)

  isValidWeight = weight => {
    const { allow_zero_weight = true } = this.props

    if (!isNumber(weight)) return false

    weight = round(weight, 2)

    if (weight > MAX_WEIGHT) return false
    if (weight < 0) return false
    if (!allow_zero_weight && weight === 0) return false

    return true
  }

  render() {
    const {
      allow_input = true,
      selected_job_codes,
      incrementDistributionWeight,
      decrementDistributionWeight,
      setDistributionWeight,
      allow_zero_weight = true,
      compact = true,
      show_header = true,
      show_percent = false
    } = this.props

    const { request_set_weight } = this.state

    const total_weight = this.totalDistributionWeight()

    return (
      <div>
        <Table compact={compact}>
          {!!show_header && (
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Job Code</Table.HeaderCell>
                <Table.HeaderCell collapsing textAlign="center">
                  Points
                </Table.HeaderCell>
                {!!show_percent && (
                  <Table.HeaderCell collapsing textAlign="right">
                    Percent
                  </Table.HeaderCell>
                )}
              </Table.Row>
            </Table.Header>
          )}
          <Table.Body>
            {selected_job_codes.map((job_code, idx) => {
              return (
                <DistributionWeightRow
                  key={idx}
                  percent={(100 * job_code.weight) / total_weight}
                >
                  <JobNameCell name={job_code.name} />
                  <DistributionWeightsCell
                    allow_input={allow_input}
                    show_label={!show_header}
                    align={show_header ? "center" : "right"}
                    weight={job_code.weight}
                    total_weight={total_weight}
                    allow_zero_weight={allow_zero_weight}
                    onDecrementWeight={() =>
                      decrementDistributionWeight(job_code.id)
                    }
                    onIncrementWeight={() =>
                      incrementDistributionWeight(job_code.id)
                    }
                    requestSetWeight={() =>
                      this.setState({
                        request_set_weight: {
                          ...job_code,
                          weight: round(job_code.weight, 2)
                        }
                      })
                    }
                  />
                  {!!show_percent && (
                    <DistributionPercentCell
                      percent={(100 * job_code.weight) / total_weight}
                    />
                  )}
                </DistributionWeightRow>
              )
            })}
          </Table.Body>
        </Table>
        {!!request_set_weight && (
          <Modal size="mini">
            <Modal.Header
              onClose={() => this.setState({ request_set_weight: false })}
            >
              Set Distribution Points
            </Modal.Header>
            <Modal.Content>
              <Input
                autoFocus={true}
                fluid
                label="Points"
                placeholder={`Enter a number >${
                  allow_zero_weight ? "=" : ""
                } 0`}
                value={request_set_weight.weight}
                onChange={(e, { value }) =>
                  this.setState({
                    request_set_weight: { ...request_set_weight, weight: value }
                  })
                }
              />
              {!isNumber(request_set_weight.weight) && (
                <>
                  <br />
                  <Message
                    type="info"
                    title="Invalid input."
                    message="Point value must be a number."
                  />
                </>
              )}
              {!!isNumber(request_set_weight.weight) && (
                <>
                  {round(request_set_weight.weight, 2) !=
                    request_set_weight.weight && (
                    <>
                      <br />
                      <Message
                        type="info"
                        title="No more than two decimal places supported."
                        message={`Point value will be rounded to ${round(
                          request_set_weight.weight,
                          2
                        )}.`}
                      />
                    </>
                  )}
                  {round(request_set_weight.weight, 2) > MAX_WEIGHT && (
                    <>
                      <br />
                      <Message
                        type="info"
                        title="Value too high!"
                        message="Point value must be less than 10,000."
                      />
                    </>
                  )}
                  {!allow_zero_weight &&
                    round(request_set_weight.weight, 2) == 0 && (
                      <>
                        <br />
                        <Message
                          type="info"
                          title="Invalid input."
                          message="Point value must be greater than zero."
                        />
                      </>
                    )}
                </>
              )}
            </Modal.Content>
            <Modal.Actions>
              <Button
                onClick={() => this.setState({ request_set_weight: false })}
              >
                Cancel
              </Button>
              <Button
                primary
                disabled={!this.isValidWeight(request_set_weight.weight)}
                onClick={() => {
                  setDistributionWeight(
                    request_set_weight.id,
                    round(request_set_weight.weight, 2)
                  )
                  this.setState({ request_set_weight: false })
                }}
              >
                Submit
              </Button>
            </Modal.Actions>
          </Modal>
        )}
      </div>
    )
  }
}

const DistributionWeightRow = Styled(({ percent, className, ...props }) => (
  <Table.Row className={className} hover={false} {...props} />
))`${({ percent }) => {
  const pct = round(percent, 2)
  return `
    position: relative;

    & > .weighted-distros-job-name > div.weighted-distros-percent-fill {
      width: ${pct}%;
    }
  `
}}
`

const JobNameCell = Styled(({ name, className }) => (
  <Table.Cell className={`weighted-distros-job-name ${className}`}>
    <span>{name}</span>
    <div className="weighted-distros-percent-fill" />
  </Table.Cell>
))`
  & > span {
    position: relative;
    z-index: 1010;
  }
  & > div.weighted-distros-percent-fill {
    z-index: 1001;
    transition: width 0.2s ease-out;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0px;
    bottom: -1px;
    background-color: ${rgba(colors.info, 0.3)};
    border-right: 1px solid ${rgba(colors.info, 0.7)};
  }
`

const DistributionWeightsCell = Styled(
  ({
    className,
    weight,
    allow_input = true,
    show_label = false,
    align = "center",
    ...props
  }) => (
    <Table.Cell collapsing textAlign={align}>
      {!allow_input && (
        <span className={className}>
          {show_label && <span className="weight-label">points:</span>}
          {weight}
        </span>
      )}
      {!!allow_input && <DistributionWeightInputs weight={weight} {...props} />}
    </Table.Cell>
  )
)`
  font-weight: bold;
  position: relative;
  z-index: 1010;
  & > .weight-label {
    margin-right: 0.33rem;
    font-size: 0.93rem;
    font-weight: 300;
  }
`

const DistributionPercentCell = Styled(({ percent, className }) => (
  <Table.Cell collapsing textAlign="right">
    <span className={className}>{round(percent, 2)}%</span>
  </Table.Cell>
))`
  position: relative;
  z-index: 1010;
`

const DistributionWeightInputs = Styled(
  ({
    weight,
    total_weight,
    onDecrementWeight,
    onIncrementWeight,
    requestSetWeight,
    allow_zero_weight = true,
    ...props
  }) => (
    <div {...props}>
      <Button.Group size="tiny" compact>
        <Button
          disabled={
            (allow_zero_weight ? weight < 1 : weight <= 1) || total_weight === 1
          }
          icon="minus"
          onClick={onDecrementWeight}
        />
        <Button basic onClick={requestSetWeight}>
          {round(weight, 2)}
        </Button>
        <Button icon="plus" onClick={onIncrementWeight} />
      </Button.Group>
    </div>
  )
)`
  position: relative;
  z-index: 1010;
  &&& .button.basic {
    background-color: ${colors.white} !important;
  }
`

// pie chart
export const WeightedDistrosPieChart = Styled(
  ({
    className,
    selected_job_codes,
    show_legend = true,
    show_percent = true,
    options = {}
  }) => {
    const selected = [...selected_job_codes].sort((a, b) =>
      b.weight > a.weight ? 1 : -1
    )

    const total_weight = selected.reduce((acc, { weight }) => acc + weight, 0)

    const chart_data = selected.map(({ name, weight }, idx) => ({
      value: round(weight, 3),
      percent: round((100 * weight) / total_weight, 1),
      label: name,
      color: rgba(colorByIndex(idx), 1 - 0.03 * (idx + 1))
    }))

    return (
      <div className={className}>
        <div>
          <Doughnut
            legend={false}
            options={{ aspectRatio: 1.75, animation: false, ...options }}
            data={chart_data}
            redraw={true}
          />
        </div>
        {!!show_legend && (
          <PieLegend data={chart_data} show_percent={show_percent} />
        )}
      </div>
    )
  }
)`
  display: grid;
  grid-template-columns: ${({ chart_width = "auto" }) => chart_width} ${({
  show_legend = true
}) => (!show_legend ? "" : "auto")};
  grid-column-gap: 1.33rem;
  white-space: nowrap;
`

const PieLegend = Styled(
  ({ className, data, show_percent = true, max_items = 10 }) => {
    let visible_items = data.slice(0, max_items)
    let hidden_items = data.slice(max_items)
    if (hidden_items.length === 1) {
      // don't hide just one item
      visible_items = [...visible_items, ...hidden_items]
      hidden_items = []
    }
    return (
      <div className={className}>
        <LegendTitle>Distribution Points</LegendTitle>
        <LegendItems items={visible_items} show_percent={show_percent} />
        {hidden_items.length > 0 && <HiddenItems items={hidden_items} />}
      </div>
    )
  }
)`
  cursor: default;
`
const LegendTitle = Styled.div`
  font-size: 1.3rem;
  font-weight: 300;
`
const LegendItems = Styled(({ items, show_percent, className }) => (
  <div className={className}>
    <div>
      {items.map((item, idx) => (
        <LegendItem key={idx} item={item} show_percent={show_percent} />
      ))}
    </div>
  </div>
))`
  margin: 0.67rem 0 0.5rem 0;

  & > div {
    display: inline-grid;
    grid-template-columns: auto auto auto ${({ show_percent }) =>
      !show_percent ? "" : "auto"};
    grid-column-gap: 0.67rem;
    grid-row-gap: 0.17rem;
    align-items: center;
  }
`
const LegendItem = ({ item, show_percent }) => (
  <>
    <ItemColor color={item.color} />
    <ItemName name={item.label} />
    <ItemWeight weight={item.value} />
    {!!show_percent && <ItemPercent percent={item.percent} />}
  </>
)

const ItemColor = Styled.div`
  width: 1.33rem;
  background-color: ${({ color }) => color};
  height: 0.67rem;
  border-radius: 2px;
`
const ItemName = Styled(({ name, className }) => (
  <div className={className}>{name}</div>
))``
const ItemWeight = Styled(({ weight, className }) => (
  <div className={className}>{weight}</div>
))`
  font-weight: 300;
`
const ItemPercent = Styled(({ percent, className }) => (
  <div className={className}>({percent}%)</div>
))`
  font-weight: 300;
`

const HiddenItems = Styled(({ items, className }) => (
  <div className={className}>
    {items.length} item{items.length !== 1 && "s"} hidden
  </div>
))`
  font-weight: 300;
`
