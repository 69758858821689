import React, { useState } from "react"

import moment from "moment"
import Styled from "styled-components"
import { Loader } from "semantic-ui-react"
import { useQuery } from "@apollo/client"

import Icon from "../../Icon"
import Table from "../../Table"
import Button from "../../Button"
import UnlockModal from "./UnlockModal"
import FinalizeNowModal from "./FinalizeNowModal"
import { StoresFinalizingIndicator } from "./Shared"

import { toInt } from "../../../helpers/number"
import { time_format_verbose } from "../../../constants"
import { RECENT_PAY_PERIODS } from "../../../graphql/queries"
import useAuthorization from "../../../hooks/authorization"
import { settings_manage_recent_pay_periods } from "../../../constants/permissions"

const POLL_INTERVAL = 30000
const PP_DATE_FORMAT = "dddd, MMM D"

const Header = Styled.div`
  font-weight: 300;
  font-size: 1.2rem;
  margin-top: 1.5rem;
`

export default Styled(
  ({
    user,
    toast,
    refresh,
    className,
    last_pay_period,
    current_pay_period,
    pay_period_before_last
  }) => {
    const { hasPermission } = useAuthorization()
    const [unlock_modal, requestUnlock] = useState(null)
    const [finalize_modal, requestFinalize] = useState(null)

    const { data } = useQuery(RECENT_PAY_PERIODS, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      pollInterval: POLL_INTERVAL
    })

    const recent_pay_periods = data?.self?.organization?.recent_pay_periods

    const last_pp_state = payPeriodState(
      user.stores,
      recent_pay_periods,
      last_pay_period.id
    )
    const pp_before_last_state = payPeriodState(
      user.stores,
      recent_pay_periods,
      pay_period_before_last.id
    )
    return (
      <div className={className}>
        <Header>Recent Pay Periods</Header>
        {!recent_pay_periods && <LoadingPayPeriods />}
        {!!recent_pay_periods && (
          <Table collapsing unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>First Day</Table.HeaderCell>
                <Table.HeaderCell>Last Day</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {!!current_pay_period && (
                <Table.Row>
                  <Table.Cell>
                    {moment(current_pay_period.start).format(PP_DATE_FORMAT)}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(current_pay_period.end).format(PP_DATE_FORMAT)}
                  </Table.Cell>
                  <Table.Cell>Open</Table.Cell>
                  <Table.Cell>-</Table.Cell>
                </Table.Row>
              )}
              {!!last_pay_period && (
                <Table.Row>
                  <Table.Cell>
                    {moment(last_pay_period.start).format(PP_DATE_FORMAT)}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(last_pay_period.end).format(PP_DATE_FORMAT)}
                  </Table.Cell>
                  <Table.Cell className="finalize-cell">
                    <ClosedPayPeriodState
                      exported_at={last_pay_period.exported_at}
                      {...last_pp_state}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    {hasPermission(settings_manage_recent_pay_periods) &&
                      last_pp_state.available_to_finalize.length > 0 && (
                        <RequestFinalizeBtn
                          onClick={() =>
                            requestFinalize({
                              pay_period: last_pay_period,
                              currently_finalizing:
                                last_pp_state.currently_finalizing,
                              available_to_finalize:
                                last_pp_state.available_to_finalize,
                              show_finalize_disclaimer:
                                pp_before_last_state.available_to_finalize
                                  .length > 0
                            })
                          }
                        />
                      )}
                    {hasPermission(settings_manage_recent_pay_periods) &&
                      last_pp_state.finalized.length > 0 && (
                        <RequestUnlockBtn
                          onClick={() =>
                            requestUnlock({
                              pay_period: last_pay_period,
                              currently_finalizing:
                                last_pp_state.currently_finalizing,
                              available_to_unlock: last_pp_state.finalized
                            })
                          }
                        />
                      )}
                    {last_pp_state.available_to_finalize.length === 0 &&
                      last_pp_state.finalized.length === 0 &&
                      "-"}
                  </Table.Cell>
                </Table.Row>
              )}
              {!!pay_period_before_last && (
                <Table.Row>
                  <Table.Cell>
                    {moment(pay_period_before_last.start).format(
                      PP_DATE_FORMAT
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(pay_period_before_last.end).format(PP_DATE_FORMAT)}
                  </Table.Cell>
                  <Table.Cell>
                    <ClosedPayPeriodState
                      exported_at={pay_period_before_last.exported_at}
                      {...pp_before_last_state}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    {hasPermission(settings_manage_recent_pay_periods) &&
                      pp_before_last_state.available_to_finalize.length > 0 && (
                        <>
                          <RequestFinalizeBtn
                            onClick={() =>
                              requestFinalize({
                                pay_period: pay_period_before_last,
                                currently_finalizing:
                                  pp_before_last_state.currently_finalizing,
                                available_to_finalize:
                                  pp_before_last_state.available_to_finalize
                              })
                            }
                          />
                        </>
                      )}
                    {hasPermission(settings_manage_recent_pay_periods) &&
                      pp_before_last_state.finalized.length > 0 && (
                        <>
                          <RequestUnlockBtn
                            onClick={() =>
                              requestUnlock({
                                pay_period: pay_period_before_last,
                                currently_finalizing:
                                  pp_before_last_state.currently_finalizing,
                                available_to_unlock:
                                  pp_before_last_state.finalized,
                                show_unlock_disclaimer:
                                  last_pp_state.finalized.length > 0
                              })
                            }
                          />
                        </>
                      )}
                    {pp_before_last_state.available_to_finalize.length === 0 &&
                      pp_before_last_state.finalized.length === 0 &&
                      "-"}
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        )}
        {!!unlock_modal && (
          <UnlockModal
            user={user}
            {...unlock_modal}
            onSuccess={() => {
              requestUnlock(null)
              toast({
                type: "success",
                message: "Pay period unlocked successfully."
              })
              refresh()
            }}
            onClose={() => requestUnlock(null)}
          />
        )}
        {!!finalize_modal && (
          <FinalizeNowModal
            user={user}
            {...finalize_modal}
            onSuccess={() => {
              requestFinalize(null)
              toast({
                type: "success",
                message: "Finalize request submitted successfully."
              })
              refresh()
            }}
            onClose={() => requestFinalize(null)}
          />
        )}
      </div>
    )
  }
)`
  margin-top: 2rem;
  & > div:last-child {
    margin-top: 0.5rem;
  }
  &&& td {
    line-height: 2.33rem;
    padding-top: 0.67rem;
    padding-bottom 0.5rem;
  }
`

const payPeriodState = (stores, recent_pay_periods, pay_period_id) => {
  let closed_pay_periods = [],
    finalized = [],
    currently_finalizing = [],
    available_to_finalize = []
  try {
    closed_pay_periods = recent_pay_periods
      .find(({ id }) => toInt(id) === pay_period_id)
      .closedPayPeriods.filter(({ store }) =>
        stores.find(({ id }) => toInt(store.id) === id)
      )
    finalized = closed_pay_periods
      .filter(({ exported_at }) => !!exported_at)
      .map(({ store }) => ({
        id: toInt(store.id),
        name: store.name
      }))
    currently_finalizing = closed_pay_periods
      .filter(({ exported_at }) => exported_at === null)
      .map(({ id, name }) => ({ id: toInt(id), name }))
    available_to_finalize = stores
      .filter(
        ({ id }) =>
          !closed_pay_periods.find(({ store }) => toInt(store.id) === id)
      )
      .map(({ id, name }) => ({ id, name }))
  } catch {
    return null
  }

  return {
    closed_pay_periods,
    finalized,
    currently_finalizing,
    available_to_finalize
  }
}

const RequestFinalizeBtn = props => (
  <TableActionButton icon="lock" label="Finalize Now" {...props} />
)

const RequestUnlockBtn = props => (
  <TableActionButton positive icon="unlock" label="Unlock" {...props} />
)

const TableActionButton = Styled(({ label, icon, ...props }) => (
  <Button compact size="small" {...props}>
    <Icon name={icon} />
    &nbsp;
    {label}
  </Button>
))`
  &&& {
    vertical-align: top;
    margin-right: 0.2rem;
    &:last-child {
      margin-right: 0;
    }
  }
`

const ClosedPayPeriodState = ({
  exported_at,
  finalized = [],
  currently_finalizing = [],
  available_to_finalize = []
}) => (
  <>
    {finalized.length === 0 && currently_finalizing.length === 0 && (
      <>Closed, not finalized</>
    )}
    {currently_finalizing.length > 0 && (
      <StoresFinalizingIndicator total={currently_finalizing.length} />
    )}
    {finalized.length > 0 && currently_finalizing.length === 0 && (
      <>
        {available_to_finalize.length > 0 && (
          <>
            {finalized.length} of{" "}
            {finalized.length + available_to_finalize.length} locations
            finalized
          </>
        )}
        {available_to_finalize.length === 0 && (
          <>
            <Icon name="lock" /> Finalized{" "}
            {!!exported_at && (
              <>
                on {moment.utc(exported_at).local().format(time_format_verbose)}
              </>
            )}
          </>
        )}
      </>
    )}
  </>
)

const LoadingPayPeriods = Styled(props => (
  <div {...props}>
    <Loader inline active />
  </div>
))`
  margin-top: 0.5rem;
`
