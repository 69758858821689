import { useEffect, useState } from "react"

import { Link, Dropdown } from "../../../../../Shared"

import { useWeeklyDistributionEnabled } from "../../../../../../hooks"
import {
  DISTRO_SOURCE_TYPE_CASH,
  BASE_DISTRIBUTION_METHODS,
  WEEKLY_ENABLED_DISTRIBUTION_METHODS,
  CASH_WEEKLY_ENABLED_DISTRIBUTION_METHODS
} from "../../../../helpers"

export default ({
  distribution_source_type,
  distribution_method,
  onUpdate
}) => {
  const weekly_distribution_enabled = useWeeklyDistributionEnabled()
  const options = useDistributionFrequencyOptions(
    distribution_source_type,
    weekly_distribution_enabled
  )

  return (
    <Dropdown
      placeholder="Select a frequency"
      selection
      fluid
      disabled={
        distribution_source_type === DISTRO_SOURCE_TYPE_CASH &&
        !weekly_distribution_enabled
      }
      value={distribution_method}
      options={options}
      onChange={(e, { value }) => onUpdate({ distribution_method: value })}
    />
  )
}

const useDistributionFrequencyOptions = (
  distribution_source_type,
  weekly_distribution_enabled
) => {
  const [options, setOptions] = useState([])

  useEffect(() => {
    let methods = {}
    // Base level distribution methods set on organization
    if (!weekly_distribution_enabled) {
      methods = BASE_DISTRIBUTION_METHODS
    } else if (weekly_distribution_enabled) {
      // Weekly distribution methods setting enabled on organization and pay period setting is valid
      methods =
        // CASH POOL CREATION DAILY OR WEEKLY ONLY
        distribution_source_type === DISTRO_SOURCE_TYPE_CASH
          ? CASH_WEEKLY_ENABLED_DISTRIBUTION_METHODS
          : WEEKLY_ENABLED_DISTRIBUTION_METHODS
    }
    setOptions(
      Object.keys(methods).map(name => ({
        text: name,
        value: name
      }))
    )
  }, [distribution_source_type])

  return options
}

export const DistributionFrequencyDescription = ({ handleLinkClick }) => {
  const weekly_distribution_enabled = useWeeklyDistributionEnabled()
  return (
    <>
      <p>
        Tips are pooled and distributed to employees based on minutes worked
        during this interval.
      </p>
      <p>
        The <strong>Time of Sale</strong> option distributes based on minutes
        worked between each sale's open and close time.
      </p>
      {/** Notify user that any custom schedules will not apply */}
      {weekly_distribution_enabled && (
        <p>
          Custom schedules will not apply when frequency is set to{" "}
          <strong>Weekly.</strong>
        </p>
      )}
      <Link font_weight={600} onClick={handleLinkClick}>
        Learn More
      </Link>
    </>
  )
}
