import moment from "moment"

import { DatePicker, FutureDatedLabel } from "../../../../../Shared"
import { EffectiveDateWarning } from "../../../../../Shared/ReportLockedIndicator"

import { ruleIsFutureDated } from "../../../../helpers"
import { month_day_format } from "../../../../../../constants"
import { compatibleDateObject } from "../../../../../../helpers"

export default ({ effective_date, lock_threshold, onUpdate }) => {
  const future_dated = ruleIsFutureDated({ effective_date })

  return (
    <>
      <DatePicker
        position="bottom right"
        button
        compact
        value={effective_date}
        minDate={compatibleDateObject(lock_threshold)}
        description={
          !lock_threshold ? null : (
            <>
              Days preceding {lock_threshold.format(month_day_format)} are
              locked.
            </>
          )
        }
        onChange={e => onUpdate(compatibleDateObject(moment(e)))}
      />
      {!!future_dated && (
        <>
          &nbsp;&nbsp;
          <FutureDatedLabel fitted />
        </>
      )}
      <EffectiveDateWarning
        lock_threshold={lock_threshold}
        effective_date={effective_date}
      />
    </>
  )
}
