import { useEffect, useState } from "react"
import Styled from "styled-components"
import { colors } from "../../../../../../../constants"
import {
  breakdown_view,
  rules_manage,
  rules_view
} from "../../../../../../../constants/permissions"
import { entity_type_store } from "../../../../../../../constants/role"
import { useCanViewEtaModule, useLocations } from "../../../../../../../hooks"
import Icon from "../../../../../../Icon"
import {
  Message,
  Table,
  ValidationErrorsMessage
} from "../../../../../../Shared"
import { displayEntityType } from "../../../helpers"
import {
  displayLocationNames,
  useVisibleEtaPermissions,
  useVisibleReportingPermissions,
  useVisibleSettingsPermissions
} from "../helpers"

export const reviewStep = state => {
  const [visited, setVisited] = useState(false)
  // at least one permission has to be turned on
  const valid_permissions = !!Object.values(state.permissions).filter(
    permission => !!permission
  ).length
  return {
    name: "Review",
    complete: visited && valid_permissions,
    render: () => (
      <Review
        state={state}
        valid_permissions={valid_permissions}
        onMount={() => setVisited(true)}
      />
    )
  }
}

const Review = ({ state, valid_permissions, onMount }) => {
  const eta_visible = useCanViewEtaModule()
  const locations = useLocations()
  const { name, description, entity_type, store_ids, permissions } = state
  const eta_permissions = useVisibleEtaPermissions()
  const reporting_permissions = useVisibleReportingPermissions()
  const settings_permissions = useVisibleSettingsPermissions(entity_type)

  useEffect(() => onMount(), [])
  return (
    <>
      {!valid_permissions && (
        <Message type="danger" margin_bottom="1rem">
          Please select at least one permission.
        </Message>
      )}
      {!!state.errors.length && (
        <ValidationErrorsMessage
          header="Failed to create role."
          errors={state.errors}
          margin_bottom="1rem"
        />
      )}
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Create a new role</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Role Name:</Table.Cell>
            <Table.Cell>{name}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Description:</Table.Cell>
            <Table.Cell>{description}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Access Level:</Table.Cell>
            <Table.Cell>{displayEntityType(entity_type)}</Table.Cell>
          </Table.Row>
          {entity_type === entity_type_store && (
            <Table.Row>
              <Table.Cell>Locations:</Table.Cell>
              <Table.Cell>
                {!store_ids.length && "All Locations"}
                {!!store_ids.length &&
                  displayLocationNames(store_ids, locations)}
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <Table>
        <Table.Body>
          <ReviewHeader text="Tip Distribution Logic" />
          <ReviewRow
            text="Tip Distribution Logic Tab Visible"
            selected={permissions[rules_view]}
          />
          <ReviewRow
            text="Manage Tip Distribution Logic"
            selected={permissions[rules_manage]}
          />
          <ReviewHeader text="Breakdown" />
          <ReviewRow
            text="Breakdown Tab Visible"
            selected={permissions[breakdown_view]}
          />
          {eta_visible && (
            <>
              <ReviewHeader text="Earned Tip Access" />
              {eta_permissions.map(({ name, description }) => (
                <ReviewRow
                  key={name}
                  text={description}
                  selected={permissions[name]}
                />
              ))}
            </>
          )}
          <ReviewHeader text="Reporting" />
          {reporting_permissions.map(({ name, description }) => (
            <ReviewRow
              key={name}
              text={description}
              selected={permissions[name]}
            />
          ))}
          <ReviewHeader text="Settings" />
          {settings_permissions.map(({ name, description }) => (
            <ReviewRow
              key={name}
              text={description}
              selected={permissions[name]}
            />
          ))}
        </Table.Body>
      </Table>
    </>
  )
}

const ReviewHeader = ({ text }) => (
  <Table.Row>
    <Table.Cell>
      <strong>{text}</strong>
    </Table.Cell>
    <Table.Cell />
  </Table.Row>
)

const ReviewRow = Styled(({ className, text, selected }) => (
  <Table.Row className={className}>
    <Table.Cell classtext="text">{text}</Table.Cell>
    <Table.Cell textAlign="right">
      {selected && <Icon name="checkmark" color={colors.green} />}
    </Table.Cell>
  </Table.Row>
))`
  & td.text {
    padding-left: 2rem !important;
  }
`
