import moment from "moment"
import Styled from "styled-components"

import { LinkToReporting } from "../../Report"
import { Link, Modal, Table } from "../../Shared"

import { useSelectedLocation } from "../../../hooks"
import { paths, project_info } from "../../../constants"
import useAuthorization from "../../../hooks/authorization"
import { reporting_manage_shift } from "../../../constants/permissions"

const SummaryTable = Styled(({ className, ...props }) => (
  <div className={className}>
    <Table {...props} />
  </div>
))`
  max-height: 50vh;
  overflow-y: auto;
  margin: 1.5rem 0;
`

const SummaryModal = props => {
  const selected_location = useSelectedLocation()
  const { hasPermission } = useAuthorization()
  const { shifts, onClose } = props
  const first_day = moment(shifts[0].start).format("MMMM Do")
  const last_day = moment(shifts[shifts.length - 1].start).format("MMMM Do")

  return (
    <Modal size="small" onClose={onClose}>
      <Modal.Header onClose={onClose}>Missed Clockouts</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {shifts.length} shift{shifts.length > 1 && `s`}{" "}
          {shifts.length === 1 ? "was" : "were"} left open{" "}
          {first_day === last_day && <>on {first_day}</>}
          {first_day !== last_day && (
            <>
              between {first_day} and {last_day}
            </>
          )}
          .
        </Modal.Description>
        <SummaryTable compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Employee</Table.HeaderCell>
              <Table.HeaderCell>Job Code</Table.HeaderCell>
              {!selected_location && (
                <Table.HeaderCell>Location</Table.HeaderCell>
              )}
              <Table.HeaderCell>Shift Start</Table.HeaderCell>
              <Table.HeaderCell collapsing />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {[...shifts].reverse().map(shift => (
              <Table.Row key={shift.id}>
                <Table.Cell>
                  {shift.employee.first_name} {shift.employee.last_name}
                </Table.Cell>
                <Table.Cell>{shift.jobCode.name}</Table.Cell>
                {!selected_location && (
                  <Table.Cell>{shift.employee.store.name}</Table.Cell>
                )}
                <Table.Cell>
                  {moment(shift.start).format("ddd, M/D/YY [at] h:mma")}
                </Table.Cell>
                <Table.Cell collapsing>
                  <LinkToReporting shift={shift} onClick={onClose} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </SummaryTable>
        <Modal.Description>
          Missed clockouts can impact tip distribution calculations.
        </Modal.Description>
        <Modal.Description>
          The best way to resolve this issue is by closing these shifts through
          your point-of-sale provider. Updates will propagate to{" "}
          {project_info.name} shortly after.
        </Modal.Description>
        {hasPermission(reporting_manage_shift) && (
          <Modal.Description>
            You can also override your PoS and close shifts directly from the{" "}
            <Link target="_blank" href={paths.reportWorkedShifts}>
              worked shifts reporting
            </Link>{" "}
            page.
          </Modal.Description>
        )}
      </Modal.Content>
    </Modal>
  )
}

export default SummaryModal
