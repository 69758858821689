import Styled from "styled-components"
import { lighten } from "polished"
import Icon from "../../../../../Icon"
import { Table } from "../../../../../Shared"
import { colors } from "../../../../../../constants"

const RegisteredCell = ({ is_registered }) => (
  <Table.Cell textAlign="center">
    {is_registered ? (
      <RegisteredIcon size="large" name="check circle" />
    ) : (
      <NotRegisteredIcon size="large" name="x" />
    )}
  </Table.Cell>
)

const RegisteredIcon = Styled(Icon)`
  &&& {
    color: ${colors.green} !important;
    border-radius: 100%;
    background-color: ${colors.white};
  }
`

const NotRegisteredIcon = Styled(Icon)`
  &&& {
    color: ${lighten(0.08, colors.danger)} !important;
    border-radius: 100%;
    background-color: ${colors.white};
  }
`

export default RegisteredCell
