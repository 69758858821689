import { List } from "../../../../Shared"
import { ReviewRow, LabelCell, ValueCell } from "../Styled"

import {
  DISTRO_SOURCE_TYPE_CASH,
  DISTRO_SOURCE_TYPE_TIPS
} from "../../../helpers"

export default ({ source_type, sources }) => (
  <ReviewRow id="pool-summary-distro-sources">
    <LabelCell label="Tip Sources" />
    <ValueCell>
      {source_type === DISTRO_SOURCE_TYPE_TIPS && (
        <>
          <span>Credit card tips including:</span>
          <List margin="0.5rem 0 0 0.67rem">
            {sources.map(source => (
              <List.Item key={source}>
                <List.Icon name="check" size="small" />
                <List.Content>{source}</List.Content>
              </List.Item>
            ))}
          </List>
        </>
      )}
      {source_type === DISTRO_SOURCE_TYPE_CASH && (
        <span>Declared cash tips</span>
      )}
    </ValueCell>
  </ReviewRow>
)
