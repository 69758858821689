import { Component } from "react"
import { Query } from "@apollo/client/react/components"

import SalesTable from "./Table"
import SalesFilterBar from "./FilterBar"
import { MenuDropdown } from "../../Shared"
import NoResultsMessage from "./Table/NoResults"
import ExportButton from "./Table/Actions/Export"
import ToggleCompactViewBtn from "./Table/Actions/ToggleCompactView"
import {
  AlertGroup,
  NoneJobCodeSalesAlert,
  ExcessiveTipAlert
} from "../../Alert"
import {
  SelectSaleJobModal,
  ConfirmAssignJobModal
} from "./Modal/JobCodeAssignment"
import {
  CancelMultiSelectBtn,
  SubmitMultiSelectBtn,
  CountSelectedSales
} from "./Table/Actions/MultiSelect"
import {
  RequestCustomizeTeamModal,
  CustomizeTeamSequenceModal
} from "./Modal/CustomTeamAdjustment"

import { isNoneJobCode, hashify } from "../../../helpers"
import { FILTERED_SALES } from "../../../graphql/queries"
import { useSaleAssignedRulesEnabled } from "../../../hooks"
import useAuthorization from "../../../hooks/authorization"
import {
  reporting_assign_job_code_to_sales,
  reporting_custom_team_adjustment
} from "../../../constants/permissions"

const ACTION_ASSIGN_JOB = "Batch Job Code Assignment"
const ACTION_CUSTOMIZE_TEAM = "Custom Team Adjustment"
const LOCALSTORAGE_SETTINGS_KEY = "sales_report_settings"
const DEFAULT_COMPACT_VIEW_SETTING = 1

const compactViewSettingsKey = ({ name }) =>
  `${LOCALSTORAGE_SETTINGS_KEY}.${hashify(name)}`

export default class extends Component {
  state = {
    compact_view: false,
    multiselect_enabled: false,
    multiselect_selected: [],
    request_assign_team: false,
    confirm_assign_team: false,
    selected_assign_team: false,
    request_assign_job_code: false,
    confirm_assign_job_code: false,
    selected_assign_job_code: null
  }

  componentDidMount() {
    const compact_view = this.initializeCompactViewSetting()
    this.setState({ compact_view })
  }

  componentDidUpdate(prevProps) {
    // cancel out of job code assignment
    // if selected store changes
    const { multiselect_enabled } = this.state
    const { selected_location_id } = this.props
    if (
      multiselect_enabled &&
      selected_location_id !== prevProps.selected_location_id
    ) {
      this.setState({ multiselect_enabled: false, multiselect_selected: [] })
    }
  }

  initializeCompactViewSetting = () => {
    const { user } = this.props
    const setting_key = compactViewSettingsKey(user)
    const compact_view = localStorage.getItem(setting_key)
    if (compact_view === null) {
      localStorage.setItem(setting_key, DEFAULT_COMPACT_VIEW_SETTING)
    }
    return !!Number(compact_view) ? true : false
  }

  toggleCompactViewSetting = () => {
    const { user } = this.props
    const { compact_view } = this.state
    const toggled_state = !!compact_view ? 0 : 1
    localStorage.setItem(compactViewSettingsKey(user), toggled_state)
    this.setState({ compact_view: toggled_state === 1 ? true : false })
  }

  render() {
    const {
      presets,
      report_filters,
      setReportFilters,
      selected_location_id,
      selected_location_name,
      // biz dates
      businessEnd,
      businessStart,
      reportLockThreshold,
      none_job_codes_notifications,
      excessive_tip_notifications
    } = this.props

    const {
      compact_view,
      request_assign_team,
      confirm_assign_team,
      multiselect_enabled,
      multiselect_selected,
      selected_assign_team,
      request_assign_job_code,
      confirm_assign_job_code,
      selected_assign_job_code
    } = this.state

    const lock_threshold = reportLockThreshold(
      selected_location_id,
      report_filters.start_time
    )

    const filter_start_time = businessStart(report_filters.start_time)
    const filter_end_time = businessEnd(report_filters.end_time)

    const canSelectSale = sale =>
      multiselect_enabled &&
      reportLockThreshold(sale?.store_id).isSameOrBefore(sale?.opened_time)
    return (
      <>
        <AlertGroup>
          {!!none_job_codes_notifications && (
            <NoneJobCodeSalesAlert
              min_time={filter_start_time}
              max_time={filter_end_time}
            />
          )}

          <ExcessiveTipAlert
            min_time={filter_start_time}
            max_time={filter_end_time}
          />
        </AlertGroup>

        <Query
          fetchPolicy="network-only"
          notifyOnNetworkStatusChange
          query={FILTERED_SALES}
          variables={{
            start_time: filter_start_time,
            end_time: filter_end_time,
            store_id: selected_location_id,
            revenue_center_id: report_filters.revenue_center?.id ?? null,
            employee_id: report_filters.employee?.id ?? null,
            has_job_code:
              report_filters.job_code &&
              isNoneJobCode(report_filters.job_code.name)
                ? false
                : null,
            job_code_id: report_filters.job_code
              ? report_filters.job_code.id
              : null,
            has_distribution: !!report_filters.distributions
              ? true
              : report_filters.distributions ?? null,
            distribution_rule_id: report_filters.distributions?.id ?? null,
            external_id: report_filters?.sale?.external_id ?? null,
            has_refunds: report_filters.refunds,
            page: report_filters.page,
            page_size: report_filters.per_page,
            sort_by: report_filters.sort_field,
            sort_dir: report_filters.sort_direction
          }}
        >
          {({ loading, data, refetch }) => (
            <>
              <SalesFilterBar
                presets={presets}
                loading={!!loading}
                filters={report_filters}
                setFilters={({ location = undefined, ...filters }) => {
                  const filter_location_id = location?.id
                    ? Number(location.id)
                    : null
                  if (
                    location !== undefined &&
                    filter_location_id !== selected_location_id
                  ) {
                    this.props.changeSelectedLocation(filter_location_id)
                  }
                  setReportFilters({ ...filters, page: 1 })
                }}
              />
              {!loading && !!data && (
                <>
                  {data.filteredSales.total === 0 && <NoResultsMessage />}
                  {data.filteredSales.total > 0 && (
                    <SalesTable
                      refetch={refetch}
                      compact_view={compact_view}
                      results={data.filteredSales}
                      businessStart={businessStart}
                      canSelectSale={canSelectSale}
                      multiselect_enabled={multiselect_enabled}
                      multiselect_selected={multiselect_selected}
                      saleLockThreshold={sale =>
                        reportLockThreshold(sale?.store_id, lock_threshold)
                      }
                      assign_job_code={selected_assign_job_code}
                      draggable={!confirm_assign_job_code}
                      selected_location_id={selected_location_id}
                      controls={{
                        position:
                          data.filteredSales.total > report_filters.per_page
                            ? "both"
                            : "top",
                        custom_controls_position: "top",
                        pagination: {
                          current_page: report_filters.page,
                          per_page: report_filters.per_page,
                          total: data.filteredSales.total,
                          include_total: true,
                          changePage: page => setReportFilters({ page }),
                          changePerPage: per_page => {
                            setReportFilters({ page: 1, per_page })
                          },
                          options: {
                            per_page: [25, 50, 100, 500]
                          }
                        },
                        custom: [
                          <>
                            {!multiselect_enabled && (
                              <>
                                <ExportButton
                                  filters={{
                                    location: {
                                      id: selected_location_id,
                                      name: selected_location_name
                                    },
                                    ...report_filters
                                  }}
                                  onDownloadStart={() => {
                                    this.props.toast({
                                      type: "success",
                                      message:
                                        "Sales export started.  Check your email in a few minutes."
                                    })
                                  }}
                                />
                                <ToggleCompactViewBtn
                                  compact_view={compact_view}
                                  onToggle={this.toggleCompactViewSetting}
                                />
                                <ActionsMenu
                                  onChange={value => {
                                    switch (value) {
                                      case ACTION_ASSIGN_JOB:
                                        return this.setState({
                                          request_assign_job_code: true
                                        })
                                      case ACTION_CUSTOMIZE_TEAM:
                                        return this.setState({
                                          request_assign_team: true
                                        })
                                      default:
                                        return
                                    }
                                  }}
                                />
                              </>
                            )}
                            {!!multiselect_enabled && (
                              <>
                                <CancelMultiSelectBtn
                                  onClick={() =>
                                    this.setState({
                                      multiselect_enabled: false,
                                      multiselect_selected: [],
                                      selected_assign_team: null,
                                      selected_assign_job_code: null
                                    })
                                  }
                                />
                                {!!selected_assign_job_code && (
                                  <SubmitMultiSelectBtn
                                    disabled={multiselect_selected.length == 0}
                                    onClick={() =>
                                      this.setState({
                                        confirm_assign_job_code: true
                                      })
                                    }
                                    label={
                                      <>
                                        Assign {selected_assign_job_code.name}
                                      </>
                                    }
                                  />
                                )}
                                {!!selected_assign_team && (
                                  <SubmitMultiSelectBtn
                                    disabled={multiselect_selected.length == 0}
                                    onClick={() =>
                                      this.setState({
                                        confirm_assign_team: true
                                      })
                                    }
                                    label="Customize Team"
                                  />
                                )}
                              </>
                            )}
                            <CountSelectedSales
                              selected_count={multiselect_selected.length}
                            />
                          </>
                        ]
                      }}
                      onSaleEdited={(before_current_day = false) => {
                        refetch()
                        this.props.toastToSuccess(
                          <>
                            <p>Sale updated successfully.</p>
                            {before_current_day && (
                              <p>
                                The breakdown is currently reprocessing to
                                reflect your changes.
                              </p>
                            )}
                          </>
                        )
                      }}
                      onJobCodeAssignmentRemoved={(
                        before_current_day = false
                      ) =>
                        this.props.toastToSuccess(
                          <>
                            <p>Job code assignment removed.</p>
                            {before_current_day && (
                              <p>
                                The breakdown is currently reprocessing to
                                reflect your changes.
                              </p>
                            )}
                          </>
                        )
                      }
                      onRuleAssignmentRemoved={(before_current_day = false) =>
                        this.props.toastToSuccess(
                          <>
                            <p>Sale assignment removed.</p>
                            {before_current_day && (
                              <p>
                                The breakdown is currently reprocessing to
                                reflect your changes.
                              </p>
                            )}
                          </>
                        )
                      }
                      onToggleAllSales={selected => {
                        let new_selected = [...multiselect_selected]
                        const current_page_sale_ids = data.filteredSales.sales
                          .filter(sale => canSelectSale(sale))
                          .map(({ id }) => id)
                        if (selected) {
                          for (let id of current_page_sale_ids) {
                            if (!new_selected.includes(id)) {
                              new_selected.push(id)
                            }
                          }
                        } else {
                          new_selected = new_selected.filter(
                            id => !current_page_sale_ids.includes(id)
                          )
                        }
                        this.setState({
                          multiselect_selected: new_selected
                        })
                      }}
                      onToggleSale={({ id }) => {
                        let new_selected = [...multiselect_selected]
                        if (new_selected.includes(id)) {
                          new_selected.splice(new_selected.indexOf(id), 1)
                        } else {
                          new_selected.push(id)
                        }
                        this.setState({ multiselect_selected: new_selected })
                      }}
                      onToggleManySales={target_sale => {
                        let new_selected = [...multiselect_selected]
                        const last_selected =
                          new_selected[new_selected.length - 1] ?? null
                        const current_page_sale_ids = data.filteredSales.sales
                          .filter(sale => canSelectSale(sale))
                          .map(({ id }) => id)
                        if (
                          !last_selected ||
                          !current_page_sale_ids.includes(last_selected)
                        ) {
                          return this.setState({
                            multiselect_selected: [
                              ...new_selected,
                              target_sale.id
                            ]
                          })
                        }

                        const last_selected_idx = current_page_sale_ids.indexOf(
                          last_selected
                        )
                        const target_idx = current_page_sale_ids.indexOf(
                          target_sale.id
                        )

                        const target_slice = current_page_sale_ids
                          .slice(
                            Math.min(last_selected_idx, target_idx),
                            Math.max(last_selected_idx, target_idx)
                          )
                          .filter(id => !new_selected.includes(id))

                        // make sure the clicked id is always last on the selected array
                        this.setState({
                          multiselect_selected: [
                            ...[...new_selected, ...target_slice].filter(
                              id => id !== target_sale.id
                            ),
                            target_sale.id
                          ]
                        })
                      }}
                    />
                  )}
                </>
              )}
              {request_assign_job_code && (
                <SelectSaleJobModal
                  onSelect={job_code =>
                    this.setState({
                      multiselect_enabled: true,
                      selected_assign_job_code: job_code,
                      request_assign_job_code: false
                    })
                  }
                  onClose={() =>
                    this.setState({ request_assign_job_code: false })
                  }
                  {...this.props}
                />
              )}
              {confirm_assign_job_code && (
                <ConfirmAssignJobModal
                  {...this.props}
                  edit_sales={multiselect_selected}
                  assign_job_code={selected_assign_job_code}
                  onCompleted={() => {
                    this.setState({
                      multiselect_enabled: false,
                      multiselect_selected: [],
                      selected_assign_job_code: null,
                      confirm_assign_job_code: false
                    })
                    refetch()
                  }}
                  onClose={() =>
                    this.setState({
                      confirm_assign_job_code: false
                    })
                  }
                />
              )}
              {request_assign_team && (
                <RequestCustomizeTeamModal
                  {...this.props}
                  onSubmit={() =>
                    this.setState({
                      request_assign_team: false,
                      selected_assign_team: true,
                      multiselect_enabled: true
                    })
                  }
                  onClose={() => this.setState({ request_assign_team: false })}
                />
              )}
              {confirm_assign_team && (
                <CustomizeTeamSequenceModal
                  {...this.props}
                  edit_sales={multiselect_selected}
                  onCompleted={() => {
                    this.setState({
                      multiselect_enabled: false,
                      multiselect_selected: [],
                      selected_assign_team: null,
                      confirm_assign_team: false
                    })
                    refetch()
                    this.props.toast({
                      type: "success",
                      message: (
                        <>
                          <p>Custom team adjustment applied.</p>
                          <p>
                            It may take a few minutes for updates to appear in
                            the breakdown.
                          </p>
                        </>
                      )
                    })
                  }}
                  onClose={() =>
                    this.setState({
                      confirm_assign_team: false
                    })
                  }
                />
              )}
            </>
          )}
        </Query>
      </>
    )
  }
}

const ActionsMenu = ({ onChange }) => {
  const { hasPermission } = useAuthorization()

  let options = []

  if (hasPermission(reporting_assign_job_code_to_sales)) {
    options.push({
      text: ACTION_ASSIGN_JOB,
      value: ACTION_ASSIGN_JOB
    })
  }

  if (
    hasPermission(reporting_custom_team_adjustment) &&
    useSaleAssignedRulesEnabled()
  ) {
    options.push({
      text: ACTION_CUSTOMIZE_TEAM,
      value: ACTION_CUSTOMIZE_TEAM
    })
  }

  return !!options.length ? (
    <MenuDropdown text="Actions" onChange={onChange} options={options} />
  ) : null
}
