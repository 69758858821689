import { useState } from "react"

import { rgba } from "polished"
import Styled from "styled-components"
import { Label } from "semantic-ui-react"

import {
  Icon,
  Table,
  Popup,
  Button,
  Loader,
  HelpIcon,
  css_disable_text_selection
} from "../../../Shared"
import { SaleMissingJobCodeModal } from "../../../Modal/ResolveNoneJobCode"

import { colors } from "../../../../constants"
import { isNoneJobCode } from "../../../../helpers"

export const TableWrapper = Styled.div`
  overflow-x: auto;
  padding-bottom: 0.5rem;
  ${({ disable_text_selection = false }) =>
    !disable_text_selection ? `` : css_disable_text_selection}
`

export const SaleRow = Styled(
  ({ select_enabled = false, selectable = false, onSelect, ...props }) => (
    <Table.Row
      {...props}
      disabled={select_enabled && !selectable}
      onClick={select_enabled && selectable ? onSelect : undefined}
    />
  )
)`
  ${({ select_enabled = false, selectable = false }) => `
    ${select_enabled && selectable ? "cursor: pointer;" : ""}
  `}
`

export const MultiSelectRow = Styled(props => <Table.TotalsRow {...props} />)`
  cursor: pointer;
`

export const LabelButton = Styled(
  ({ popup = false, primary, className, ...props }) => (
    <>
      {!!popup && (
        <Popup {...popup}>
          <div className={className}>
            <Label circular as="a" {...props} />
          </div>
        </Popup>
      )}
      {!popup && (
        <div className={className}>
          <Label circular as="a" {...props} />
        </div>
      )}
    </>
  )
)`
  display: inline;
  margin-right: 0.2rem;
  & > .label {
    ${({ primary }) =>
      !primary
        ? ""
        : `
      background-color: ${colors.success} !important;
      color: ${colors.white} !important;
    `};
    cursor: pointer;
  }
`

const JobCodeLabel = Styled(
  ({
    onRemove = null,
    loading,
    selected = false,
    description = null,
    children,
    ...props
  }) => (
    <>
      <span>
        &nbsp;&nbsp;
        {!!loading && <Loader active inverted inline size="mini" />}
      </span>
      {!loading && (
        <RemovableJobAssignment onRemove={onRemove} {...props}>
          {children}
        </RemovableJobAssignment>
      )}
    </>
  )
)`
  padding: 0.4em !important;
`

const RemovableJobAssignment = Styled(
  ({ onRemove = undefined, children, ...props }) => (
    <Label size="small" {...props} onClick={onRemove}>
      {children}
      {!!onRemove && (
        <>
          &nbsp;&nbsp;
          <Icon
            fitted
            name="remove"
            popup={{ delay: true, content: "Remove Assignment" }}
          />
        </>
      )}
    </Label>
  )
)`
${({ onRemove }) =>
  !onRemove
    ? ""
    : `
    cursor: pointer;
    transition: box-shadow 0.1s ease-out !important;
    & .icon.remove {
      transition: color 0.1s ease-out;
    }
    &:hover {
      box-shadow: 0 1px 2px ${colors.light6};
      & .icon.remove {
        color: ${colors.red};
      }
    }
  `}
`

const PendingJobCode = Styled(
  ({ size = "small", text = "Pending", ...props }) => (
    <Label size={size} {...props}>
      {text}
    </Label>
  )
)`
  color: white !important;
  padding: 0.4em !important;
  background-color: ${rgba(colors.success, 0.8)} !important;
`

const NoneJobCodeDetail = Styled(({ className, label }) => {
  const [detail_modal, setDetailModal] = useState(false)
  return (
    <>
      <Popup
        delay
        hoverable
        position="right center"
        content={
          <>We could not find a job code for this sale. Click to learn more.</>
        }
      >
        <div className={className} onClick={() => setDetailModal(true)}>
          {label}&nbsp;
          <HelpIcon type="error" />
        </div>
      </Popup>
      {!!detail_modal && (
        <SaleMissingJobCodeModal onClose={() => setDetailModal(false)} />
      )}
    </>
  )
})`
  display: inline-block;
  line-height: 1.9rem;
  cursor: pointer;
  border-radius: 2px;
  padding: 0 0.5rem;
  margin-left: -0.5rem;
  border: 0.5px solid transparent;
  transition: background 0.1s linear, border 0.1s linear;
  &:hover {
    background-color: ${colors.light3};
    border: 0.5px solid ${colors.light4};
  }
`

export const JobCodeCell = ({
  sale,
  assign_job_code,
  removeJobCodeRequest,
  selected = false
}) => {
  let pending = false
  let sale_assigned = false
  let employee_assigned = false
  // default job code is None
  let job_code = "None"

  if (sale.assignedJobCode) {
    // Direct sale assignment is highest priority
    sale_assigned = true
    job_code = sale.assignedJobCode.name
  } else if (sale.employee && sale.employee.assignedJobCode) {
    // Employee assignment next
    employee_assigned = true
    job_code = sale.employee.assignedJobCode.name
  } else if (sale.workedShift && sale.workedShift.jobCode) {
    // last priority is shift job code
    job_code = sale.workedShift.jobCode.name
  }

  // Check if user is assigning job code
  if (selected && assign_job_code) {
    pending = true
    job_code = assign_job_code.name
  }

  if (
    !!isNoneJobCode(job_code) &&
    !sale_assigned &&
    !employee_assigned &&
    !pending
  ) {
    return <NoneJobCodeDetail label={job_code} />
  }

  return (
    <>
      {job_code}
      {sale_assigned && !pending && (
        <JobCodeLabel onRemove={removeJobCodeRequest}>
          Sale Assignment
        </JobCodeLabel>
      )}
      {employee_assigned && !pending && (
        <JobCodeLabel>Employee Assignment</JobCodeLabel>
      )}
      {pending && (
        <>
          &nbsp;&nbsp;
          <PendingJobCode />
        </>
      )}
    </>
  )
}

export const EditSaleButton = Styled(
  ({ className, locked = false, ...props }) => (
    <>
      {!locked && (
        <Button
          compact
          circular
          icon="edit"
          size="tiny"
          popup={{ delay: true, content: "Edit Sale" }}
          {...props}
        />
      )}
      {!!locked && (
        <Icon
          className={className}
          color={colors.tween}
          name="lock"
          popup={
            <>
              This sale can't be modified because it belongs to a finalized pay
              period.
            </>
          }
        />
      )}
    </>
  )
)`line-height: 2rem;`
