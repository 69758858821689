import { useState, useEffect } from "react"

import PayoutsTab from "./Payouts"
import { RouteTabs } from "../Tabs"
import OverviewTab from "./Overview"
import RouteContent from "../RouteContent"
import Introduction from "./Introduction/RouteModule"
import { createSearchQuery } from "../../helpers"

import {
  ETA_TABS,
  TAB_PAYOUTS,
  TAB_OVERVIEW,
  INTRO_PATH,
  PAYOUTS_FILTER_NAMESPACE,
  OVERVIEW_FILTER_NAMESPACE,
  payoutsFiltersWithDefaults,
  overviewFiltersWithDefaults
} from "./helpers"
import { paths } from "../../constants"
import {
  useNavigateTo,
  useEtaEnabled,
  usePayPeriods,
  useBusinessDates,
  useCanViewEtaModule
} from "../../hooks"

export default props => {
  const { app_filters, setFiltersForNamespace } = props
  const navigateTo = useNavigateTo()
  const pay_periods = usePayPeriods()
  const eta_enabled = useEtaEnabled()
  const eta_visible = useCanViewEtaModule()
  const { businessDate } = useBusinessDates()

  const [selected_tab, setSelectedTab] = useState(TAB_OVERVIEW)

  useEffect(() => {
    if (!eta_visible) {
      navigateTo(paths.index)
      return
    }

    const pathname = props.location.pathname
    const tab_key = pathname
      .split("/")
      .filter(token => token.length)
      .pop()
    let selected_tab = ETA_TABS.map(({ path }) => path).find(
      tab => tab === tab_key
    )

    if (!selected_tab) {
      selected_tab = eta_enabled ? TAB_OVERVIEW : INTRO_PATH
      window.history.pushState(
        { [selected_tab]: true },
        selected_tab,
        `${paths.earnedTipAccess}/${selected_tab}`
      )
    }

    setSelectedTab(selected_tab)
  }, [])

  return (
    <RouteContent
      header={!eta_enabled ? null : <>Earned Tip Access&trade;</>}
      route_name="earned-tip-access"
    >
      {eta_enabled && (
        <RouteTabs
          navigation_path={paths.earnedTipAccess}
          tabs={ETA_TABS.map(tab => ({
            ...tab,
            id: tab.path,
            selected: tab.path === selected_tab
          }))}
          onChange={tab => setSelectedTab(tab.id)}
        >
          {selected_tab === TAB_OVERVIEW && (
            <OverviewTab
              {...props}
              pay_periods={pay_periods}
              filters={overviewFiltersWithDefaults(
                app_filters,
                pay_periods,
                businessDate()
              )}
              setFilters={(filters, merge = true) =>
                setFiltersForNamespace(
                  OVERVIEW_FILTER_NAMESPACE,
                  { page: 1, ...filters },
                  merge
                )
              }
              requestFailedPayoutDetails={() => {
                setSelectedTab(TAB_PAYOUTS)
                let url = createSearchQuery(paths.earnedTipAccessPayouts, {
                  show_failed_payout_modal: 1
                })
                navigateTo(url)
              }}
            />
          )}
          {selected_tab === TAB_PAYOUTS && (
            <PayoutsTab
              {...props}
              filters={payoutsFiltersWithDefaults(app_filters, businessDate())}
              setFilters={(filters, merge = true) => {
                setFiltersForNamespace(
                  PAYOUTS_FILTER_NAMESPACE,
                  { page: 1, ...filters },
                  merge
                )

                // clear out get params when changing filters
                props.history.replace({
                  search: ""
                })
              }}
            />
          )}
        </RouteTabs>
      )}
      {!eta_enabled && <Introduction />}
    </RouteContent>
  )
}
