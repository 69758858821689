import Styled from "styled-components"
import { Table, Toggle, Loader } from "../../../../../Shared"

const StatusCell = ({ active, disabled, loading, handleStatusChange }) => (
  <Table.Cell textAlign="center">
    {loading ? (
      <Loader size="tiny" />
    ) : (
      <ToggleWrapper>
        <Toggle
          data-testid="status-toggle"
          disabled={disabled}
          checked={active}
          onClick={e => e.stopPropagation()}
          onChange={handleStatusChange}
        />
      </ToggleWrapper>
    )}
  </Table.Cell>
)

const ToggleWrapper = Styled.div`
  position: relative;
  display: inline-block;
  & > div {
    padding-top: 0.33rem;
  }
`

export default StatusCell
