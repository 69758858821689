import React from "react"
import Styled from "styled-components"
import withSizes from "react-sizes"
import { Icon, Step } from "semantic-ui-react"

import Basics from "./Basics"
import SaleFilters from "./SaleFilters"
import CollectFrom from "./CollectFrom"
import DistributeTo from "./DistributeTo"

import Button from "../../../Button"
import Responsive from "../../../Shared/Responsive"

import { colors, sizesToProps } from "../../../../constants"
import { RULE_TYPE_FLAT_RATE } from "../../helpers"

const BASICS = "basics"
const SALE_FILTERS = "sale_filters"
const COLLECT_FROM = "collect_from"
const DISTRIBUTE_TO = "distribute_to"

export default props => {
  const {
    pages,
    selected,
    selectPage,
    getPreviousPage,
    getNextPage,
    updatePage,
    allValid,
    isValid,
    onSubmit
  } = props

  return (
    <CreateWrapper>
      <StepGroupWrapper id="rule-editor-steps">
        <Step.Group style={{ width: "100%", overflowX: "auto" }}>
          {/* basics step */}
          <StepWrapper
            id="rule-editor-step-basics"
            onClick={() => selectPage(BASICS)}
            active={selected === BASICS}
          >
            <Responsive min_width={MIN_ICON_WIDTH}>
              <IconWrapper>
                <Icon name="edit" />
                {isValid(BASICS) && (
                  <SuccessIcon size="tiny" name="check circle" />
                )}
              </IconWrapper>
            </Responsive>
            <Step.Content>
              <StepTitle>
                Basics
                <Responsive max_width={MIN_ICON_WIDTH}>
                  {isValid(BASICS) && <ResponsiveSuccessIcon name="check" />}
                </Responsive>
              </StepTitle>
              <Step.Description>Basic Rule Details</Step.Description>
            </Step.Content>
          </StepWrapper>

          {/* sale filters step */}
          <StepWrapper
            id="rule-editor-step-sale-filters"
            onClick={() => selectPage(SALE_FILTERS)}
            active={selected === SALE_FILTERS}
          >
            <Responsive min_width={MIN_ICON_WIDTH}>
              <IconWrapper>
                <Icon name="percent" />
                {isValid(SALE_FILTERS) && (
                  <SuccessIcon size="tiny" name="check circle" />
                )}
              </IconWrapper>
            </Responsive>
            <Step.Content>
              <StepTitle>
                Sharing
                <Responsive max_width={MIN_ICON_WIDTH}>
                  {isValid(SALE_FILTERS) && (
                    <ResponsiveSuccessIcon name="check" />
                  )}
                </Responsive>
              </StepTitle>
              <Step.Description>From Sale or Tip Totals</Step.Description>
            </Step.Content>
          </StepWrapper>
          {/* collect from step */}
          <StepWrapper
            id="rule-editor-step-collect-from"
            onClick={() => selectPage(COLLECT_FROM)}
            active={selected === COLLECT_FROM}
          >
            <Responsive min_width={MIN_ICON_WIDTH}>
              <IconWrapper>
                <Icon name="user" />
                {isValid(COLLECT_FROM) && (
                  <SuccessIcon size="tiny" name="check circle" />
                )}
              </IconWrapper>
            </Responsive>
            <Step.Content>
              <StepTitle>
                Collect From
                <Responsive max_width={MIN_ICON_WIDTH}>
                  {isValid(COLLECT_FROM) && (
                    <ResponsiveSuccessIcon name="check" />
                  )}
                </Responsive>
              </StepTitle>
              <Step.Description>Job Codes Distributing</Step.Description>
            </Step.Content>
          </StepWrapper>
          {/* distribute to step */}
          <StepWrapper
            id="rule-editor-step-distribute-to"
            onClick={() => selectPage(DISTRIBUTE_TO)}
            active={selected === DISTRIBUTE_TO}
          >
            <Responsive min_width={MIN_ICON_WIDTH}>
              <IconWrapper>
                <Icon name="group" />
                {isValid(DISTRIBUTE_TO) && (
                  <SuccessIcon size="tiny" name="check circle" />
                )}
              </IconWrapper>
            </Responsive>
            <Step.Content>
              <StepTitle>
                Distribute To
                <Responsive max_width={MIN_ICON_WIDTH}>
                  {isValid(DISTRIBUTE_TO) && (
                    <ResponsiveSuccessIcon name="check" />
                  )}
                </Responsive>
              </StepTitle>
              <Step.Description>Job Codes Receiving</Step.Description>
            </Step.Content>
          </StepWrapper>
        </Step.Group>
      </StepGroupWrapper>
      <StepContentWrapper>
        <NavigationControls>
          {!!getPreviousPage() && (
            <Button
              basic
              onClick={e => selectPage(getPreviousPage())}
              icon
              size="large"
              labelPosition="left"
            >
              Previous
              <Icon name="left arrow" />
            </Button>
          )}
          {isValid(selected) && !!getNextPage() && (
            <Button
              basic
              onClick={() => selectPage(getNextPage())}
              icon
              size="large"
              labelPosition="right"
            >
              Next
              <Icon name="right arrow" />
            </Button>
          )}
          {!!allValid() && (
            <Button
              onClick={onSubmit}
              primary
              icon
              size="large"
              labelPosition="right"
            >
              Submit
              <Icon name="check" />
            </Button>
          )}
        </NavigationControls>
        {selected == BASICS && (
          <Basics
            {...props}
            {...pages[BASICS]}
            rule_distributes_cash={pages[SALE_FILTERS].source.cash ?? false}
            onUpdate={config => updatePage(BASICS, config)}
          />
        )}
        {selected == SALE_FILTERS && (
          <SaleFilters
            {...props}
            {...pages[SALE_FILTERS]}
            rule_has_revenue_centers={pages[BASICS].revenue_centers.length > 0}
            onUpdate={config => updatePage(SALE_FILTERS, config)}
          />
        )}
        {selected == COLLECT_FROM && (
          <CollectFrom
            {...props}
            {...pages[COLLECT_FROM]}
            exclude={pages[DISTRIBUTE_TO].selected}
            onUpdate={config => updatePage(COLLECT_FROM, config)}
          />
        )}
        {selected == DISTRIBUTE_TO && (
          <DistributeTo
            {...props}
            {...pages[DISTRIBUTE_TO]}
            exclude={pages[COLLECT_FROM].selected}
            allow_fallback={pages[SALE_FILTERS].type !== RULE_TYPE_FLAT_RATE}
            allow_weights={pages[SALE_FILTERS].type !== RULE_TYPE_FLAT_RATE}
            onUpdate={config => updatePage(DISTRIBUTE_TO, config)}
          />
        )}
      </StepContentWrapper>
    </CreateWrapper>
  )
}

const MIN_ICON_WIDTH = "1300px"

const CreateWrapper = Styled.div``
const StepContentWrapper = Styled.div`
  position: relative;
  margin-bottom: 2rem;
`
const IconWrapper = Styled.div`
  display:inline-block;
  font-size: 2.5em;
  position: relative;
  line-height: 1em;
  margin-right: 0.33em;
  color: ${colors.dark3};
`
const ResponsiveSuccessIcon = Styled(Icon)`
  &&& {
    position: absolute;
    left: 100%;
    top: -2px;
    color: ${colors.green} !important;
    border-radius: 100%;
    margin-left: 5px;
  }
`
const SuccessIcon = Styled(Icon)`
  &&& {
    position: absolute;
    right: 0px;
    top: 0px;
    color: ${colors.green} !important;
    border-radius: 100%;
    background-color: ${colors.white};
  }
`
const StepGroupWrapper = Styled(props => <div {...props} />)`
  position: relative;
  & > .ui.steps {
    margin-bottom: 0em;
    & .step.active .title {
      color: ${colors.success};
    }
  }
`
const StepWrapper = Styled(Step)`
  background-color: ${colors.white};
  &.step:hover, &.step:hover::after {
      cursor: pointer !important;
      background-color: ${colors.light2} !important;
  }
`
const StepTitle = Styled(Step.Title)`
  & {
    position: relative !important;
    font-weight: 300 !important;
    display: inline-block !important;
  }
`
const NavigationControls = withSizes(sizesToProps)(Styled.div`
  ${({ isDesktop, isLargeScreen }) =>
    isDesktop || isLargeScreen
      ? `
  position: absolute;
  right: 0;
  top: 0;
  `
      : `
  margin-top: 2rem;
  `}
  & > .button {
    margin-right: 0.5rem !important;
  }
  & > .button:last-child {
    margin-right: 0 !important;
  }
`)
