export const SET_FILTERS_FOR_NAMESPACE = "SET_FILTERS_FOR_NAMESPACE"
export const MERGE_FILTERS_FOR_NAMESPACE = "MERGE_FILTERS_FOR_NAMESPACE"
export const setFiltersForNamespace = (namespace, filters, merge = true) => ({
  type: !merge ? SET_FILTERS_FOR_NAMESPACE : MERGE_FILTERS_FOR_NAMESPACE,
  payload: { namespace, filters }
})

export const SET_APPLICATION_FILTERS = "SET_APPLICATION_FILTERS"
export const MERGE_APPLICATION_FILTERS = "MERGE_APPLICATION_FILTERS"
export const setApplicationFilters = (filters, merge = true) => ({
  type: !merge ? SET_APPLICATION_FILTERS : MERGE_APPLICATION_FILTERS,
  payload: { filters }
})

export const CLEAR_REPORT_FILTERS = "CLEAR_REPORT_FILTERS"
export const clearReportFilters = (namespace = null) => ({
  type: CLEAR_REPORT_FILTERS,
  payload: { namespace }
})
