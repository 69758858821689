import { ExportToCsv } from "export-to-csv"

import { round } from "../../../helpers/number"
import { readableRuleType } from "../../Rule/helpers"

export const generateDistrosExport = distros => {
  const options = {
    filename: `sale-distribution-detail-${distros[0].employee.name
      .replace(/\sg/, "-")
      .toLowerCase()}`,
    title: "Sale Distribution Detail",
    showLabels: true,
    headers: [
      "Business Date",
      "Employee",
      "Job Code",
      "Distribution Type",
      "Distribution Name",
      "Sale Time",
      "Sale Total",
      "Transaction Type",
      "Net Amount"
    ]
  }

  const data = distros.reduce((acc, date_distros) => {
    const biz_date = date_distros.date
    const employee = date_distros.employee.name
    const job_code = date_distros.jobCode.name
    return [
      ...acc,
      ...date_distros.distributions.map(distro => [
        biz_date,
        employee,
        job_code,
        readableRuleType(distro.rule.type),
        distro.rule.name,
        distro.distributable.sale_time,
        round(distro.distributable.total, 2),
        distro.is_deposit ? "deposited" : "received",
        distro.amount
      ])
    ]
  }, [])

  const exporter = new ExportToCsv(options)
  exporter.generateCsv(data)
}
