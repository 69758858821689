import Table from "../../../Table"
import { StatusCell } from "./Styled"

import {
  rowEtaAvailable,
  rowLocationName,
  REPORT_COLUMN_ETA_SENT,
  REPORT_COLUMN_LAST_NAME,
  REPORT_COLUMN_FIRST_NAME,
  REPORT_COLUMN_ETA_PAYABLE,
  REPORT_COLUMN_ETA_ELIGIBLE,
  REPORT_COLUMN_ETA_RESERVED
} from "../../helpers"
import { displayNumber } from "../../../../helpers/number"
import { Link } from "../../../Shared"

export default ({ row, show_location = false, requestEmployeePayouts }) => (
  <Table.Row>
    {/* status */}
    <StatusCell row={row} />
    {/* last name */}
    <Table.Cell>{displayName(row[REPORT_COLUMN_LAST_NAME])}</Table.Cell>
    {/* first name */}
    <Table.Cell>{displayName(row[REPORT_COLUMN_FIRST_NAME])}</Table.Cell>
    {/* location name */}
    {!!show_location && <Table.Cell>{rowLocationName(row)}</Table.Cell>}
    {/* eligible take home */}
    <NumberCell value={row[REPORT_COLUMN_ETA_ELIGIBLE]} />
    {/* take home reserved */}
    <NumberCell value={row[REPORT_COLUMN_ETA_RESERVED]} />
    {/* payout remaining */}
    <NumberCell value={row[REPORT_COLUMN_ETA_PAYABLE]} />
    {/* already paid out */}
    <LinkCell
      value={row[REPORT_COLUMN_ETA_SENT]}
      onClick={requestEmployeePayouts}
    />
    {/* available to pay out */}
    <NumberCell value={rowEtaAvailable(row)} />
  </Table.Row>
)

const NumberCell = ({ value }) => (
  <Table.Cell textAlign="right">
    {!!value && displayNumber(value, 2)}
    {!value && "-"}
  </Table.Cell>
)

const LinkCell = ({ value, onClick }) => (
  <Table.Cell textAlign="right">
    {!!value && <Link onClick={onClick}>{displayNumber(value, 2)}</Link>}
    {!value && "-"}
  </Table.Cell>
)

const displayName = value => {
  const name = value?.trim() ?? ""
  return name === "" ? "-" : name
}
