import Styled from "styled-components"
import { Table } from "../../../../../Shared"
import RoleRow from "./RoleRow"

const RolesTable = Styled(({ className, roles }) => (
  <Table className={className} unstackable compact>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.HeaderCell>Description</Table.HeaderCell>
        <Table.HeaderCell>Access Level</Table.HeaderCell>
        <Table.HeaderCell />
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {roles.map(role => (
        <RoleRow key={role.id} role={role} />
      ))}
    </Table.Body>
  </Table>
))`
  margin-top: 1rem;
`
export default RolesTable
