import Styled from "styled-components"

import Icon from "../../Icon"

import { colors, style } from "../../../constants"

const propsToColor = ({
  negative,
  danger,
  caution,
  warning,
  positive,
  success,
  primary,
  info
}) => {
  if (negative || danger) return colors.danger
  if (caution || warning) return colors.warning
  if (positive || success) return colors.green
  if (primary) return colors.success
  if (info) return colors.info
  return colors.primary
}

const AlertButton = Styled(
  ({ id, className, icon, label, onClick, ...props }) => {
    const color = propsToColor(props)
    return (
      <div
        id={id}
        className={`alert-btn ${className}`}
        onClick={onClick}
        tabIndex={0}
      >
        {!!icon && (
          <div className="alert-icon">
            <Icon fitted size="large" name={icon} color={color} />
          </div>
        )}{" "}
        <div className="alert-label">{label}</div>
      </div>
    )
  }
)`${({ count, basic }) => `
  display: inline-block;
  white-space: nowrap;

  cursor: pointer;
  padding: 0.9rem 1rem 0.8rem 1rem;

  border: 0.5px solid ${colors.light4};
  border-radius: ${style.border_radius};

  background-color: ${!!basic ? colors.white : colors.light4};
  transition: border 0.08s, background 0.08s;

  & > .alert-label {
    font-weight: 600;
    font-size: 1rem;
    white-space: nowrap;
    line-height: 1.5rem;
    display: inline-block;
    color: rgba(0, 0, 0, ${!!basic ? 0.7 : 0.6});
    transition: color 0.08s;
  }

  & > .alert-icon {
    position: relative;
    display: inline-block;
    margin-right: 0.5rem;

    &:after {
      content: '${count ?? 0}';
      position: absolute;
      bottom: -0.33rem;
      right: -0.33rem;
      line-height: 0.86rem;
      font-size: 0.74rem;
      padding: 0.12em 0.2rem;
      font-weight: bold;
      background-color: ${!!basic ? colors.light5 : colors.light6};
      color: ${colors.dark2};
      border-radius: 0.6rem;
      min-width: 1.2rem;
      height: 0.98rem;
      text-align: center;
    }
  }

  &:hover {
    background-color: ${!!basic ? colors.white : colors.light6};
    border: 0.5px solid ${colors.light6};

    & > .alert-label {
      color: rgba(0, 0, 0, ${!!basic ? 0.9 : 0.8});
    }
  }
`}`

const AlertButtonGroup = Styled(({ className, ...props }) => (
  <div className={`alert-btn-group ${className}`} {...props} />
))`
  & > div {
    margin: 0 0.67rem 0.33rem 0;
  }
  & > div:last-child {
    margin-bottom: 1.33rem;
  }
`

AlertButton.Group = AlertButtonGroup

export default AlertButton
