import Table from "../../../Table"
import { displayNumber } from "../../../../helpers/number"
import { useEtaConfigSetting } from "../../../../hooks"

export default ({ total_amount_successful, total_fees, show_locations }) => {
  const show_fees = useEtaConfigSetting("hausdirect_enabled", false)
  return (
    <Table.TotalsRow>
      {/* initiated by */}
      <Table.TotalCell />
      {/* locations */}
      {show_locations && <Table.TotalCell />}
      {/* total amount sent */}
      <Table.TotalCell value={displayNumber(total_amount_successful, 2)} />
      {/* total fees */}
      {show_fees && <Table.TotalCell value={displayNumber(total_fees, 2)} />}
      {/* pay period */}
      <Table.TotalCell />
      {/* created_at */}
      <Table.TotalCell />
      {/* completed_at */}
      <Table.TotalCell />
      {/* status */}
      <Table.TotalCell />
    </Table.TotalsRow>
  )
}
