import React, { useState } from "react"

import Table from "../../../../Table"
import { IconButton } from "../../../../Button"
import AreYouSure from "../../../../Modal/AreYouSure"

import { downloadFile } from "../../../../../helpers/api"
import { useBusinessDates } from "../../../../../hooks/selectors"
import { weekday_month_day_format } from "../../../../../constants"

const downloadExport = async (filters, setDownloading) => {
  setDownloading(true)
  await downloadFile(`reports/worked-shifts`, filters)
  setDownloading(false)
}

export default ({ filters, onDownloadStart }) => {
  const [request_download, setRequestDownload] = useState(false)
  const [downloading, setDownloading] = useState(false)

  const { businessStart, businessEnd } = useBusinessDates()

  const {
    page,
    per_page,
    location,
    start_time,
    end_time,
    employee,
    job_code
  } = filters

  return (
    <>
      <IconButton
        secondary
        icon="cloud download"
        loading={!!downloading}
        disabled={!!downloading}
        onClick={() => setRequestDownload(true)}
      >
        Export
      </IconButton>
      {!!request_download && (
        <AreYouSure
          header="Export Worked Shifts Report?"
          body={
            <>
              <p>
                Would you like to download the worked shifts report with the
                following filters applied?
              </p>
              {
                <Table basic="very" compact celled={false}>
                  <Table.Body>
                    {!!page && (
                      <Table.Row>
                        <Table.Cell>
                          <strong>Page:</strong>
                        </Table.Cell>
                        <Table.Cell>{page}</Table.Cell>
                      </Table.Row>
                    )}
                    {!!per_page && (
                      <Table.Row>
                        <Table.Cell>
                          <strong>Limit:</strong>
                        </Table.Cell>
                        <Table.Cell>{per_page}</Table.Cell>
                      </Table.Row>
                    )}
                    {!!location?.name && (
                      <Table.Row>
                        <Table.Cell>
                          <strong>Location:</strong>
                        </Table.Cell>
                        <Table.Cell>{location.name}</Table.Cell>
                      </Table.Row>
                    )}
                    {!!start_time && (
                      <Table.Row>
                        <Table.Cell>
                          <strong>Start Date:</strong>
                        </Table.Cell>
                        <Table.Cell>
                          {businessStart(start_time, false).format(
                            weekday_month_day_format
                          )}
                        </Table.Cell>
                      </Table.Row>
                    )}
                    {!!end_time && (
                      <Table.Row>
                        <Table.Cell>
                          <strong>End Date:</strong>
                        </Table.Cell>
                        <Table.Cell>
                          {businessStart(end_time, false).format(
                            weekday_month_day_format
                          )}
                        </Table.Cell>
                      </Table.Row>
                    )}
                    {!!employee?.name && (
                      <Table.Row>
                        <Table.Cell>
                          <strong>Employee:</strong>
                        </Table.Cell>
                        <Table.Cell>{employee.name}</Table.Cell>
                      </Table.Row>
                    )}
                    {!!job_code?.name && (
                      <Table.Row>
                        <Table.Cell>
                          <strong>Job Code:</strong>
                        </Table.Cell>
                        <Table.Cell>{job_code.name}</Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              }
            </>
          }
          onConfirm={() => {
            onDownloadStart()
            downloadExport(
              {
                page,
                limit: per_page,
                store_id: location?.id,
                employee_id: employee?.id,
                job_code_id: job_code?.id,
                start: businessStart(start_time),
                end: businessEnd(end_time)
              },
              setDownloading
            )
            setRequestDownload(false)
          }}
          onClose={() => setRequestDownload(false)}
        />
      )}
    </>
  )
}
