// Number Helpers

export const toInt = integer => parseInt(integer, 10)

export const round = (number, precision = 0) => {
  precision = Math.pow(10, precision - 1)
  return Math.round(number * 10 * precision) / (10 * precision)
}

export const displayNumber = (x, precision = false) => {
  if (!isNumber(x)) {
    return "-"
  }
  if (precision !== false) {
    x = x.toFixed(precision)
  }
  var parts = x.toString().split(".")
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  return parts.join(".")
}

export const isNumber = input => {
  return (
    input.toString().trim().length > 0 &&
    input.toString().replace(/\-?[0-9\.]/g, "") === "" &&
    !isNaN(input)
  )
}

export const displayMoney = val => {
  if (!isNumber(val)) {
    return "-"
  }
  return `${val < 0 ? `-` : ``}$${displayNumber(Math.abs(val), 2)}`
}

export const displayDistributionAmount = (amount, max_precision = 5) =>
  Intl.NumberFormat(navigator.language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: max_precision
  }).format(amount)
