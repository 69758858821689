import { useState } from "react"
import Styled from "styled-components"

import { Modal, Button } from "../../../../Shared"
import WeightedDistribution from "../../../WeightedDistribution"

import { DEFAULT_DISTRO_WEIGHT } from "../../../helpers"
import { layoutContainerNode } from "../../../../../helpers"

export default ({ selected = [], onClose, onSave }) => {
  const [job_codes, setJobCodes] = useState([...selected])

  const has_weights_configured = !job_codes.reduce(
    (acc, { weight }) => acc && weight === DEFAULT_DISTRO_WEIGHT,
    true
  )

  return (
    <Modal
      size="tiny"
      onClose={onClose}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      mountNode={layoutContainerNode()}
    >
      <Modal.Header onClose={onClose}>
        Configure Distribution Points
      </Modal.Header>
      <Modal.Content scrolling>
        <Line font_weight={300}>
          Distribution points specify how much one job is tipped relative to
          another.
        </Line>
        <Line font_weight={300}>
          For example, a job with 2 points will be tipped twice as much per
          minute worked as a job with 1 point.
        </Line>
        <br />
        <WeightedDistribution
          selected_job_codes={job_codes}
          allow_zero_weight={false}
          incrementDistributionWeight={id =>
            setJobCodes(
              job_codes.map(job_code => ({
                ...job_code,
                weight:
                  job_code.id === id ? job_code.weight + 1 : job_code.weight
              }))
            )
          }
          decrementDistributionWeight={id =>
            setJobCodes(
              job_codes.map(job_code => ({
                ...job_code,
                weight:
                  job_code.id === id ? job_code.weight - 1 : job_code.weight
              }))
            )
          }
          setDistributionWeight={(id, weight) =>
            setJobCodes(
              job_codes.map(job_code => ({
                ...job_code,
                weight: job_code.id === id ? weight : job_code.weight
              }))
            )
          }
        />
      </Modal.Content>
      <Modal.Actions>
        {!!has_weights_configured && (
          <Button
            style={{ float: "left", marginLeft: 0 }}
            basic
            negative
            onClick={() =>
              setJobCodes(job_codes.map(job => ({ ...job, weight: 1 })))
            }
          >
            Reset Points
          </Button>
        )}
        <Button onClick={onClose}>Cancel</Button>
        <Button primary onClick={() => onSave(job_codes)}>
          Apply
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

const Line = Styled.div`
  ${({ fitted }) => (!!fitted ? "" : "margin: 0.67rem 0;")}
  ${({ inline }) => (!inline ? "" : "display: inline-block;")}
  ${({ font_weight }) => (!font_weight ? "" : `font-weight: ${font_weight};`)}
  &:first-child {
    margin-top: 0;
  }
`
