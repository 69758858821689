import { Component } from "react"
import moment from "moment"
import { Query } from "@apollo/client/react/components"

import {
  AlertGroup,
  MissedClockoutsAlert,
  InvalidTakeHomeAlert
} from "../Alert"
import BreakdownTable from "./Table"
import BreakdownFilterBar from "./FilterBar"
import ExportButton from "./Table/Actions/Export"
import DistributionDetailModal from "./DistributionDetail"
import RouteContent, { ResyncControl } from "../RouteContent"
import DisplaySettings from "./Table/Actions/DisplaySettings"
import ToggleCompactBtn from "./Table/Actions/ToggleCompactView"
import ResolveNoneJobCodeModal from "../Modal/ResolveNoneJobCode"
import ProcessingInProgress from "./Table/Actions/ProcessingInProgress"

import tour from "./tour"
import { paths } from "../../constants"
import { toInt, hashify } from "../../helpers"
import { FETCH_REPORT } from "../../graphql/queries"
import { p2pEnabled } from "../../helpers/settings/organization"
import { RULE_POOLING_TYPES, RULE_SHARING_TYPES } from "../Rule/helpers"
import {
  useUser,
  useBusinessDates,
  useReportLockThreshold
} from "../../hooks/selectors"
import {
  BREAKDOWN_REPORT_NAME,
  FILTER_TIPPED_EMPLOYEES,
  DEFUALT_BREAKDOWN_FILTERS,
  BREAKDOWN_FILTER_NAMESPACE,
  FILTER_EMPLOYEES_WITH_TAKE_HOME,
  prepareSalesReportFilters,
  preparePeerToPeerReportFilters,
  prepareWorkedShiftsReportFilters
} from "./helpers"
import {
  P2P_NET_IDX,
  RULE_NET_IDX,
  POOL_NET_IDX,
  STORE_NAME_IDX,
  TOTAL_SALES_IDX,
  REQUIRED_METRICS,
  HOURS_WORKED_IDX,
  JOB_CODE_NAMES_IDX,
  COMPACT_VIEW_COLUMNS,
  DEFAULT_HIDDEN_COLUMNS,
  LOCALSTORAGE_SETTINGS_KEY,
  DEFAULT_COMPACT_VIEW_SETTING
} from "./Table/helpers"

export { default as LinkToBreakdown } from "./Link"

// QUICK FIX for migrating away from global helpers for
// business dates and report lock threshold
export default props => {
  const user = useUser()
  const bizDates = useBusinessDates()
  const reportLockThreshold = useReportLockThreshold()
  return (
    <BreakdownIndex
      {...props}
      {...bizDates}
      user={user}
      reportLockThreshold={reportLockThreshold}
    />
  )
}

// breakdown index component
class BreakdownIndex extends Component {
  state = {
    compact_view: false,
    distro_detail_modal: false,
    resolve_none_job_code_modal: false,
    table_settings: DEFAULT_HIDDEN_COLUMNS.reduce(
      (acc, column) => ({ ...acc, [column]: false }),
      {}
    )
  }

  componentDidMount() {
    this.initializeBreakdownSettings()
  }

  getBreakdownFilters = () => ({
    // inject defaults
    ...DEFUALT_BREAKDOWN_FILTERS,
    // default start_time and end_time to start of biz yesterday
    start_time: this.props.businessStart(moment().subtract(1, "d")),
    end_time: this.props.businessStart(moment().subtract(1, "d")),
    ...((this.props.app_filters ?? {})[BREAKDOWN_FILTER_NAMESPACE] ?? {})
  })

  setBreakdownFilters = (filters, merge = true) =>
    this.props.setFiltersForNamespace(
      BREAKDOWN_FILTER_NAMESPACE,
      { page: 1, ...filters },
      merge
    )

  initializeBreakdownSettings = () => {
    const compact_view = this.initializeCompactViewSetting()
    const table_settings = this.initializeDisplayColumnSettings()
    this.setState({ compact_view, table_settings })
  }

  userSettingsKey = ({ name }, suffix = null) =>
    `${LOCALSTORAGE_SETTINGS_KEY}.${hashify(name)}${
      !!suffix ? `.${suffix}` : ""
    }`

  initializeCompactViewSetting = () => {
    const { user } = this.props
    const setting_key = this.userSettingsKey(user, "compact_view")
    const compact_view = localStorage.getItem(setting_key)
    if (compact_view === null) {
      localStorage.setItem(setting_key, DEFAULT_COMPACT_VIEW_SETTING)
    }
    return !!toInt(compact_view) ? true : false
  }

  initializeDisplayColumnSettings = () => {
    const { table_settings } = this.state
    const { user } = this.props
    let settings = localStorage.getItem(this.userSettingsKey(user))
    try {
      settings = JSON.parse(settings)
      settings = Object.keys(settings).reduce(
        (acc, key) => ({ [key]: settings[key], ...acc }),
        {}
      )
      settings = { ...table_settings, ...settings }
    } catch (e) {
      // reset table settings
      settings = DEFAULT_HIDDEN_COLUMNS.reduce(
        (acc, column) => ({ ...acc, [column]: false }),
        {}
      )
      localStorage.setItem(this.userSettingsKey(user), JSON.stringify(settings))
    }
    return settings
  }

  shouldDisplayColumn = column => {
    const { name, appended = false } = column
    const { table_settings, compact_view } = this.state
    const { selected_location_id } = this.props
    if (appended) {
      return false
    }
    if (!!selected_location_id && name === STORE_NAME_IDX) {
      return false
    }
    if (compact_view) {
      return COMPACT_VIEW_COLUMNS.includes(name)
    }
    return table_settings[name] !== false
  }

  groupByEmployee = breakdowns => {
    return Object.values(
      breakdowns.reduce((acc, row) => {
        let { __meta, ...breakdown } = row
        const employee_key = __meta.employee_id
        if (!acc[employee_key]) {
          acc[employee_key] = {
            employee: {
              id: __meta.employee_id,
              first_name: __meta.employee_first_name,
              last_name: __meta.employee_last_name,
              payroll_id: __meta.employee_payroll_id,
              store: {
                id: __meta.store_id,
                name: __meta.store_name
              },
              job_code_names: []
            },
            rows: []
          }
        }
        acc[employee_key].rows.push(breakdown)
        acc[employee_key].employee.job_code_names.push(
          breakdown[JOB_CODE_NAMES_IDX]
        )
        return acc
      }, {})
    )
  }

  parseColumnSettings = (columns, p2p_enabled) =>
    columns
      .filter(({ appended = false }) => !appended)
      .filter(({ name }) =>
        name === P2P_NET_IDX && !p2p_enabled ? false : true
      )
      .filter(({ name }) => name !== STORE_NAME_IDX)
      .reduce(
        (acc, column) => ({
          ...acc,
          [column.name]: this.shouldDisplayColumn(column)
        }),
        {}
      )

  toggleCompactViewSetting = () => {
    const { user } = this.props
    const { compact_view } = this.state
    const toggled_state = !!compact_view ? 0 : 1
    localStorage.setItem(
      this.userSettingsKey(user, "compact_view"),
      toggled_state
    )
    this.setState({ compact_view: toggled_state === 1 ? true : false })
  }

  updateTableDisplaySettings = (settings, user) => {
    localStorage.setItem(this.userSettingsKey(user), JSON.stringify(settings))
    this.setState({
      table_settings: { ...settings }
    })
  }

  prepareLinks = () => {
    const { navigateTo, setApplicationFilters } = this.props
    const filters = this.getBreakdownFilters()
    return {
      [TOTAL_SALES_IDX]: employee => {
        setApplicationFilters(
          prepareSalesReportFilters({
            start_time: filters.start_time,
            end_time: filters.end_time,
            employee
          }),
          false
        )
        this.props.navigateTo(paths.reportSales)
      },
      [P2P_NET_IDX]: employee => {
        setApplicationFilters(
          preparePeerToPeerReportFilters({
            start_time: filters.start_time,
            end_time: filters.end_time,
            employee
          }),
          false
        )
        this.props.navigateTo(paths.reportPeerToPeer)
      },
      [HOURS_WORKED_IDX]: employee => {
        setApplicationFilters(
          prepareWorkedShiftsReportFilters({
            start_time: filters.start_time,
            end_time: filters.end_time,
            employee
          }),
          false
        )
        navigateTo(paths.reportWorkedShifts)
      },
      [RULE_NET_IDX]: employee => {
        this.setState({
          distro_detail_modal: { rule_types: RULE_SHARING_TYPES, employee }
        })
      },
      [POOL_NET_IDX]: employee => {
        this.setState({
          distro_detail_modal: { rule_types: RULE_POOLING_TYPES, employee }
        })
      }
    }
  }

  employeeFilter = value => {
    const filters = this.getBreakdownFilters()
    let filter_val
    if (filters.showing === FILTER_EMPLOYEES_WITH_TAKE_HOME) {
      // filter out employees without take home
      filter_val = value.rows.reduce(
        (acc, { total_take_home }) => acc + total_take_home,
        0
      )
      if (filter_val === 0) {
        return false
      }
    } else if (filters.showing === FILTER_TIPPED_EMPLOYEES) {
      // filter out employees not participating in tip distribution
      filter_val = value.rows.reduce(
        (
          acc,
          {
            net_distro = 0,
            tip_pool_net = 0,
            total_tips = 0,
            total_cash_tips = 0,
            cash_tip_withdraw = 0,
            total_service_charges = 0
          }
        ) =>
          acc +
          (net_distro !== 0 ||
            tip_pool_net !== 0 ||
            total_tips !== 0 ||
            total_cash_tips !== 0 ||
            cash_tip_withdraw !== 0 ||
            total_service_charges !== 0)
            ? 1
            : 0,
        0
      )
      if (filter_val === 0) {
        return false
      }
    }
    return true
  }

  render() {
    const {
      compact_view,
      distro_detail_modal,
      resolve_none_job_code_modal
    } = this.state
    const {
      user,
      setRoutesVisited,
      selected_location_id,
      // biz dates
      businessEnd,
      businessDate
    } = this.props

    const filters = this.getBreakdownFilters()

    const start_date = businessDate(filters.start_time)
    const end_date = businessDate(filters.end_time)

    const filter_start_time = filters.start_time
    const filter_end_time = businessEnd(filters.end_time)

    const p2p_enabled = p2pEnabled(user.organization.settings)

    return (
      <RouteContent
        tour={tour}
        header="Breakdown"
        route_name="breakdown"
        setRoutesVisited={setRoutesVisited}
        controls={[<ResyncControl key="resync" toast={this.props.toast} />]}
      >
        <AlertGroup>
          <MissedClockoutsAlert
            min_time={filter_start_time}
            max_time={filter_end_time}
          />
          <InvalidTakeHomeAlert
            min_time={filter_start_time}
            max_time={filter_end_time}
          />
        </AlertGroup>
        <Query
          fetchPolicy="network-only"
          notifyOnNetworkStatusChange
          query={FETCH_REPORT}
          variables={{
            input: {
              report_name: BREAKDOWN_REPORT_NAME,
              start_date,
              end_date,
              store_id: selected_location_id,
              append_metrics: REQUIRED_METRICS
            }
          }}
        >
          {({ loading, data, refetch }) => {
            let all_columns = [],
              display_columns = [],
              column_settings = {}
            try {
              all_columns = [...data.fetchReport.columns].filter(({ name }) =>
                name === P2P_NET_IDX && !p2p_enabled ? false : true
              )
              display_columns = all_columns.filter(column =>
                this.shouldDisplayColumn(column)
              )
              column_settings = this.parseColumnSettings(
                all_columns,
                p2p_enabled
              )
            } catch (e) {}

            // get the breakdown values
            let values = []
            if (!!data && !!data.fetchReport) {
              values = this.groupByEmployee(
                data.fetchReport.values.map(breakdown =>
                  all_columns
                    .map(({ name }) => name)
                    .reduce(
                      (acc, column_name) => ({
                        ...acc,
                        [column_name]: breakdown[column_name]
                      }),
                      {
                        __meta: {
                          ...breakdown.__meta
                        }
                      }
                    )
                )
              ).filter(this.employeeFilter)
            }
            return (
              <>
                <BreakdownFilterBar
                  loading={!!loading}
                  filters={filters}
                  setFilters={this.setBreakdownFilters}
                />
                {!loading && !!data && !!data.fetchReport && (
                  <BreakdownTable
                    filters={filters}
                    setFilters={this.setBreakdownFilters}
                    links={this.prepareLinks()}
                    showResolveNoneJobCodeModal={employee => {
                      this.setState({
                        resolve_none_job_code_modal: {
                          ...employee
                        }
                      })
                    }}
                    columns={display_columns}
                    values={values}
                    selected_location_id={selected_location_id}
                    actions={
                      <>
                        <ExportButton
                          end_date={end_date}
                          start_date={start_date}
                        />
                        <ToggleCompactBtn
                          compact_view={compact_view}
                          onToggle={this.toggleCompactViewSetting}
                        />
                        {!compact_view && (
                          <DisplaySettings
                            column_settings={column_settings}
                            default_hidden={DEFAULT_HIDDEN_COLUMNS}
                            column_definitions={all_columns.reduce(
                              (acc, column) => ({
                                ...acc,
                                [column.name]: { ...column }
                              }),
                              {}
                            )}
                            onSaveSettings={settings => {
                              this.updateTableDisplaySettings(settings, user)
                              this.props.toast({
                                type: "success",
                                message: "Display settings saved."
                              })
                            }}
                          />
                        )}
                        <ProcessingInProgress
                          onRefresh={() => refetch()}
                          end_time={filter_end_time}
                        />
                      </>
                    }
                    page={filters.page}
                    per_page={filters.per_page}
                    onPageChange={(page, per_page = filters.per_page) =>
                      (page !== filters.page ||
                        per_page !== filters.per_page) &&
                      this.setBreakdownFilters({
                        page,
                        per_page
                      })
                    }
                  />
                )}
                {!!resolve_none_job_code_modal && (
                  <ResolveNoneJobCodeModal
                    end_time={filter_end_time}
                    start_time={filter_start_time}
                    employee={resolve_none_job_code_modal}
                    onClose={() =>
                      this.setState({
                        resolve_none_job_code_modal: false
                      })
                    }
                    toast={this.props.toast}
                  />
                )}
                {!!distro_detail_modal && (
                  <DistributionDetailModal
                    {...distro_detail_modal}
                    values={values}
                    end_date={end_date}
                    start_date={start_date}
                    onClose={() =>
                      this.setState({ distro_detail_modal: false })
                    }
                  />
                )}
              </>
            )
          }}
        </Query>
      </RouteContent>
    )
  }
}
