import { TextArea as SemanticTextArea } from "semantic-ui-react"
import Icon from "../Icon"
import Styled from "styled-components"

const TextArea = Styled(({ className, icon = null, ...props }) => (
  <div className={className}>
    <SemanticTextArea {...props} />
    {!!icon && <Icon name={icon} />}
  </div>
))`
  & {
    position: relative;
  }
  & textarea:focus~i.icon {
    opacity: 1;
  }
  & .icon {
    position: absolute;
    top: 0.55rem;
    right: 0.5rem;
    height: 100%;
    opacity: .5;
    transition: opacity .3s ease;
  }
`

export default TextArea
