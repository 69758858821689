import React, { Component } from "react"

import moment from "moment"
import { Loader } from "semantic-ui-react"
import { Query } from "@apollo/client/react/components"

import Table from "./Table"
import Dropdown from "./../../Dropdown"
import FilterBar from "../../Shared/FilterBar"
import { NoExportsFound, NoResultsMessage } from "./Styled"

import {
  report_export_runs,
  fetchReportExports
} from "../../../graphql/queries"

import { date_format } from "../../../constants"
import { toInt } from "../../../helpers/number"
import useAuthorization from "../../../hooks/authorization"
import { reporting_reprocess_report_export_run } from "../../../constants/permissions"

const POLL_INTERVAL = 10000
const ALL_EXPORTS_VALUE = 0

export const ReportExportsFilter = ({ value, onChange, exports }) => (
  <Dropdown
    compact
    selection
    value={value ?? ALL_EXPORTS_VALUE}
    options={[
      { value: ALL_EXPORTS_VALUE, text: "All" },
      ...exports.map(({ id, name }) => ({
        value: id,
        text: name
      }))
    ]}
    placeholder="Select One"
    onChange={(e, { value }) => onChange(value)}
  />
)

export default props => {
  const {
    user,
    toast,
    presets,
    report_filters,
    setReportFilters,
    selected_location_id,
    // biz dates
    businessEnd,
    businessStart,
    businessStartFromDate
  } = props
  const { hasPermission } = useAuthorization()
  const can_manage = hasPermission(reporting_reprocess_report_export_run)
  const export_id = report_filters.export_id ?? null

  return (
    <Query
      query={fetchReportExports(selected_location_id)}
      fetchPolicy="cache-and-network"
    >
      {({ loading, data }) => {
        let report_exports = []

        if (loading) {
          return <Loader inline active />
        }

        try {
          report_exports = data.fetchReportExports.map(({ id, name }) => ({
            id: toInt(id),
            name
          }))
        } catch {}

        if (report_exports.length === 0) {
          return <NoExportsFound />
        }

        return (
          <Query
            fetchPolicy="network-only"
            query={report_export_runs}
            variables={{
              page: report_filters.page,
              first: report_filters.per_page,
              filters: {
                export_id,
                start_date: report_filters.start_time,
                end_date: businessEnd(report_filters.end_time)
              }
            }}
          >
            {({ loading, data, startPolling }) => {
              return (
                <>
                  <FilterBar
                    id="report-exports-filter-bar"
                    presets={presets}
                    loading={!!loading}
                    location_id={selected_location_id}
                    start_date={report_filters.start_time}
                    end_date={report_filters.end_time}
                    onChange={({ start_date, end_date }) => {
                      setReportFilters({
                        start_time: businessStartFromDate(start_date),
                        end_time: businessStartFromDate(end_date)
                      })
                    }}
                    loading_msg={
                      <div>
                        Loading Export Runs for{" "}
                        <strong>
                          {moment(report_filters.start_time).format(
                            "dddd, MMMM Do"
                          )}
                        </strong>{" "}
                        {moment(report_filters.start_time).format(
                          date_format
                        ) !==
                          moment(report_filters.end_time).format(
                            date_format
                          ) && (
                          <>
                            through{" "}
                            <strong>
                              {moment(report_filters.end_time).format(
                                "dddd, MMMM Do"
                              )}
                            </strong>
                          </>
                        )}
                      </div>
                    }
                    custom_filters={[
                      {
                        label: "for Export",
                        id: "report-exports-filters-showing",
                        filter: (
                          <ReportExportsFilter
                            value={export_id}
                            exports={report_exports}
                            onChange={export_id =>
                              setReportFilters({
                                page: report_filters.page,
                                export_id:
                                  export_id === ALL_EXPORTS_VALUE
                                    ? null
                                    : export_id
                              })
                            }
                          />
                        )
                      }
                    ]}
                  />
                  {!loading && !!data && !!data.reportExportRuns && (
                    <>
                      {data.reportExportRuns.data.length === 0 && (
                        <NoResultsMessage />
                      )}
                      {data.reportExportRuns.data.length > 0 && (
                        <Table
                          id="report-export-runs"
                          compact
                          draggable
                          singleLine
                          unstackable
                          user={user}
                          toast={toast}
                          results={data.reportExportRuns.data}
                          can_manage={can_manage}
                          onReprocessSubmitted={() => {
                            // reset date filters bc new export will appear
                            // under current biz day.
                            setReportFilters({
                              start_time: businessStart(),
                              end_time: businessStart(),
                              page: 1
                            })

                            // start polling
                            startPolling(POLL_INTERVAL)
                          }}
                          controls={{
                            position:
                              data.reportExportRuns.total >
                              report_filters.per_page
                                ? "both"
                                : "top",
                            custom_controls_position: "top",
                            pagination: {
                              per_page: report_filters.per_page,
                              current_page: report_filters.page,
                              total: data.reportExportRuns.paginatorInfo.total,
                              include_total: true,
                              changePage: page => {
                                setReportFilters({ page })
                              },
                              changePerPage: per_page =>
                                setReportFilters({ per_page, page: 1 }),
                              options: {
                                per_page: [25, 50, 100, 500]
                              }
                            }
                          }}
                        />
                      )}
                    </>
                  )}
                </>
              )
            }}
          </Query>
        )
      }}
    </Query>
  )
}
