import Styled from "styled-components"
import { Dropdown } from "semantic-ui-react"

import Icon from "../../../Icon"
import { Link, Table, Popup } from "../../../Shared"

import { colors } from "../../../../constants"

export const SearchLink = Styled(Link)``

export const FilterRow = Styled(({ name, children, ...props }) => (
  <Table.Row {...props}>
    <Table.Cell collapsing>{name}</Table.Cell>
    <Table.Cell textAlign="right">
      <div>{children}</div>
    </Table.Cell>
  </Table.Row>
))`
  line-height: 2.5rem;
  & > td:first-child {
    padding-left: 1.5rem !important;
  }
  & > td:last-child {
    padding-right: 1.5rem !important;
    & > div {
      line-height: 1.715rem;
    }
  }
`

export const FilterValue = Styled(
  ({ className, value, disabled_message = null, onRemove }) => (
    <Popup
      delay
      disabled={!disabled_message}
      content={disabled_message ?? ""}
      position="bottom right"
    >
      <div
        className={className}
        onClick={() => (!disabled_message ? onRemove() : null)}
      >
        {value}
        {!!onRemove && (
          <Icon fitted name={!disabled_message ? "remove" : "lock"} />
        )}
      </div>
    </Popup>
  )
)`
  ${({ disabled_message = null }) => `
    margin-right: -0.5rem;
    display: inline-block;
    font-weight: bold;
    color: ${colors.dark2};
    background-color: transparent;
    border-radius: 4px;
    fonts-size: 1.2rem;
    padding: 0.5rem 0 0.5rem 0.67rem;
    ${!disabled_message ? "cursor: pointer;" : ""}
    ${!!disabled_message ? "cursor: not-allowed;" : ""}

    && > .icon {
      padding: 0.5rem;
      ${!disabled_message ? `color: ${colors.dark6};` : ""}
      ${!!disabled_message ? `color: ${colors.light5};` : ""}

    }

    &:hover {
      && > .icon {
        ${!disabled_message ? `color: ${colors.red};` : ""}
      }
    }
  `}
`

export const FilterSelect = Styled(({ className, ...props }) => (
  <div className={className}>
    <Dropdown {...props} />
  </div>
))`
  text-align: right;
`
