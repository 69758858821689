import { useState } from "react"
import {
  useCanViewEtaModule,
  useStateObject,
  useToast
} from "../../../../../../hooks"
import { SequenceModal } from "../../../../../Shared"
import { basicsStep } from "./Sequence/Basics"
import { rulesStep } from "./Sequence/Rules"
import { breakdownStep } from "./Sequence/Breakdown"
import { earnedTipAccessStep } from "./Sequence/EarnedTipAccess"
import { reportingStep } from "./Sequence/Reporting"
import { settingsStep } from "./Sequence/Settings"
import { reviewStep } from "./Sequence/Review"
import { useMutation } from "@apollo/client"
import { CREATE_ROLE } from "../../../../../../graphql/mutations"
import { entity_type_store } from "../../../../../../constants/role"
import { useFormatRoleForDisplay } from "./helpers"
import {
  EDITOR_MODE_COPY,
  EDITOR_MODE_CREATE,
  EDITOR_MODE_VIEW
} from "../../helpers"
import { getGraphQLValidationErrors } from "../../../../../../helpers"

// sequence steps
const getSteps = (props, updateSequenceState) => {
  const { edit_mode, eta_visible } = props
  const steps = [
    basicsStep,
    rulesStep,
    breakdownStep,
    ...(eta_visible ? [earnedTipAccessStep] : []),
    reportingStep,
    settingsStep,
    // only show reviewStep in edit mode
    ...(edit_mode ? [reviewStep] : [])
  ]
  return steps.map(step => step(props, updateSequenceState))
}

const Editor = ({
  role = null,
  mode = EDITOR_MODE_CREATE,
  onClose,
  refetch
}) => {
  const [state, setState] = useStateObject(useFormatRoleForDisplay(role, mode))
  const eta_visible = useCanViewEtaModule()
  const edit_mode = mode === EDITOR_MODE_CREATE || mode === EDITOR_MODE_COPY
  const [errors, setErrors] = useState([])

  const [createRole, { loading }] = useMutation(CREATE_ROLE)
  const toast = useToast()

  const handleCreateRole = role => {
    const { name, description, entity_type, store_ids, permissions } = role
    let payload = {
      name,
      description,
      entity_type,
      // format selected permissions
      permissions: Object.keys(permissions).filter(p => permissions[p])
    }
    // only include store_ids for when store entity type is selected
    if (entity_type === entity_type_store && !!store_ids.length) {
      payload.store_ids = store_ids
    }
    createRole({
      variables: payload,
      onCompleted: () => {
        onClose()
        toast({
          type: "success",
          message: "Role created successfully."
        })
        refetch()
      },
      onError: e => {
        setErrors(getGraphQLValidationErrors(e.graphQLErrors))
      }
    })
  }

  return (
    <SequenceModal
      closeOnDimmerClick={mode === EDITOR_MODE_VIEW}
      size="small"
      header={
        edit_mode
          ? "Manage Access and Permissions"
          : `Role Summary: ${role?.name}`
      }
      steps={getSteps({ ...state, edit_mode, eta_visible, errors }, setState)}
      show_step_icon={edit_mode}
      onClose={onClose}
      loading={loading}
      onSubmit={edit_mode ? () => handleCreateRole(state) : null}
    />
  )
}

export default Editor
