import { useEffect, useState } from "react"
import { useMutation } from "@apollo/client"
import { Form } from "semantic-ui-react"
import Styled from "styled-components"
import Icon from "../../../../../../Icon"
import {
  Modal,
  Button,
  Dropdown,
  Message,
  ValidationErrorsMessage,
  Link
} from "../../../../../../Shared"
import { getGraphQLValidationErrors, toInt } from "../../../../../../../helpers"
import { colors } from "../../../../../../../constants"
import {
  useOrganization,
  useStateObject,
  useUser
} from "../../../../../../../hooks"
import { entity_type_store } from "../../../../../../../constants/role"
import { CREATE_USER, EDIT_USER } from "../../../../../../../graphql/mutations"
import {
  useRoleOptions,
  validEmail,
  getAssignableLocationsForRole,
  useSelectedRole,
  useShowEmployeeEtaMessage,
  hasUserRoleChanged,
  useNavigateToEditUser,
  useCurrentUsers
} from "./helpers"
import { canModifyUser } from "../../../helpers"

const DEFAULT_USER = {
  name: "",
  email: "",
  active: true,
  role_id: null,
  store_ids: []
}

const UserForm = ({ onClose, edit_user, client_roles, onCompleted }) => {
  const current_user = useUser()
  const organization = useOrganization()
  const { stores } = organization
  const [state, setState] = useStateObject({
    user: { ...DEFAULT_USER }
  })
  const current_users = useCurrentUsers()
  const [errors, setErrors] = useState([])
  const { user } = state
  const [createUser, { loading: create_loading }] = useMutation(CREATE_USER, {
    onError: e => setErrors(getGraphQLValidationErrors(e.graphQLErrors))
  })
  const [editUser, { loading: edit_loading }] = useMutation(EDIT_USER, {
    onError: e => setErrors(getGraphQLValidationErrors(e.graphQLErrors))
  })
  const loading = create_loading || edit_loading
  const selected_role = useSelectedRole(user.role_id, client_roles)
  const show_employee_eta_warning = useShowEmployeeEtaMessage(edit_user)
  const navigateToEditUser = useNavigateToEditUser()
  // error when a user with the provided email already exists when creating a new user, ignore in edit mode
  const existing_user =
    !user.id && current_users.find(u => u.email === user.email)

  const handleSubmit = user => {
    const { id, name, email, role_id, store_ids } = user
    let payload = {
      name,
      email,
      role_id
    }
    if (id) {
      payload.id = id
    }
    // only include store_ids for store level role
    if (storeLevelRoleSelected(selected_role)) {
      payload.store_ids = store_ids
    }

    if (id) {
      editUser({
        variables: payload,
        onCompleted
      })
    } else {
      createUser({
        variables: payload,
        onCompleted
      })
    }
  }

  const validInput = () => {
    const { name, store_ids, role_id } = user
    if (
      !name.trim().length ||
      !validEmail(user, current_users) ||
      !role_id ||
      (storeLevelRoleSelected(selected_role) && !store_ids.length)
    ) {
      return false
    }
    return true
  }

  // check if the selected role is a store level role
  const storeLevelRoleSelected = role => role?.entity_type === entity_type_store

  useEffect(() => {
    if (!!edit_user) {
      const { id, name, email, active, roleAssignments } = edit_user
      const role_id = roleAssignments[0]?.role?.id ?? null
      let store_ids = []
      const store_entity_roles = roleAssignments.filter(
        ({ entity_type }) => entity_type === entity_type_store
      )
      // populate locations assigned to the user
      if (!!store_entity_roles.length) {
        store_ids = store_entity_roles.map(({ entity_id }) => toInt(entity_id))
      }
      setState({
        user: { id, name, email, active, role_id, store_ids }
      })
    }
  }, [edit_user])

  const role_options = useRoleOptions(client_roles, edit_user)
  const edit_mode = !!edit_user

  return (
    <Modal size="tiny" onClose={onClose} closeOnDimmerClick={false}>
      <Modal.Header onClose={onClose}>
        {!!edit_user
          ? `Edit ${edit_user.name.trim().length > 0 ? edit_user.name : "User"}`
          : "Create a New User"}
      </Modal.Header>
      <Modal.Content>
        {!!errors.length && (
          <ValidationErrorsMessage
            errors={errors}
            onDismiss={() => setErrors([])}
            margin_bottom="1rem"
          />
        )}
        <Form autoComplete="off">
          {!edit_mode && (
            <Form.Field>
              <Form.Input
                autoFocus={true}
                spellCheck="false"
                onClick={e => e !== document.activeElement && e.target.focus()}
                label="Name"
                placeholder="User Name"
                type="text"
                icon={user.name.trim().length ? "check" : null}
                value={user.name}
                onChange={(e, { value }) =>
                  setState({
                    user: {
                      ...user,
                      name: value
                    }
                  })
                }
              />
            </Form.Field>
          )}
          {edit_mode && <ConstantField label="Name" value={user.name} />}
          {!edit_mode && (
            <Form.Field>
              <Form.Input
                spellCheck="false"
                onClick={e => e !== document.activeElement && e.target.focus()}
                label="Email"
                placeholder="User Email"
                type="email"
                error={
                  !!existing_user
                    ? {
                        content: (
                          <ExistingUserErrorMessage
                            onEditRequest={
                              // check if user can modify existing user based on roles hierarchy
                              canModifyUser(current_user, existing_user)
                                ? () => navigateToEditUser(existing_user)
                                : null
                            }
                          />
                        )
                      }
                    : null
                }
                icon={validEmail(user, current_users) ? "check" : null}
                value={user.email}
                onChange={(e, { value }) =>
                  setState({
                    user: {
                      ...user,
                      email: value
                    }
                  })
                }
              />
            </Form.Field>
          )}
          {edit_mode && <ConstantField label="Email" value={user.email} />}
          <Form.Field>
            <label>Assign Role</label>
            <Dropdown
              selectOnBlur={false}
              placeholder="Select One"
              fluid
              selection
              options={role_options}
              value={user.role_id}
              onChange={(e, { value }) => {
                setState({
                  user: {
                    ...user,
                    role_id: value,
                    store_ids: []
                  }
                })
              }}
            />
          </Form.Field>
          {storeLevelRoleSelected(selected_role) && (
            <Form.Field>
              <label>Location</label>
              <Dropdown
                placeholder="Select One"
                fluid
                multiple
                selection
                options={getAssignableLocationsForRole(
                  user,
                  stores,
                  client_roles
                )}
                value={user.store_ids}
                onChange={(e, { value }) =>
                  setState({
                    user: { ...user, store_ids: [...value] }
                  })
                }
              />
            </Form.Field>
          )}
        </Form>
        {show_employee_eta_warning && <EmployeeEtaWarningMessage />}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} disabled={loading}>
          Close
        </Button>
        <Button
          icon
          primary
          loading={loading}
          labelPosition="right"
          disabled={!validInput() || !hasUserRoleChanged(edit_user, user)}
          onClick={() => handleSubmit(user)}
        >
          Submit
          <Icon name="check" />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

const NoValue = Styled(props => <span {...props}>Not Specified</span>)`
  font-weight: 300;
  font-style: italic;
`
const ConstantField = Styled(({ label, value, ...props }) => (
  <div {...props}>
    <div className="field">
      <label>{label}</label>
      <div className="constant-value">
        {value.trim().length > 0 && value}
        {value.trim().length === 0 && <NoValue />}
      </div>
    </div>
  </div>
))`
  margin-bottom: 1em;

  & .constant-value {
    cursor: not-allowed;
    padding: .67857143em 1em;
    border-radius: .28571429rem;
    border: 1px solid ${colors.light4};
    background-color: ${colors.light};
    color: ${colors.dark6};
  }
`

const EmployeeEtaWarningMessage = Styled(({ className }) => (
  <Message className={className} type="info">
    <Message.Content>
      This employee user will lose ETA access once their role is changed.
    </Message.Content>
  </Message>
))`
  margin-top: 1rem;
`

const ExistingUserErrorMessage = ({ onEditRequest }) => (
  <span>
    A user with this email already exists.
    {!!onEditRequest && (
      <>
        {" "}
        You can view and adjust their role{" "}
        <Link onClick={onEditRequest}>here</Link>.
      </>
    )}
  </span>
)

export default UserForm
