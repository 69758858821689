import history from "./history"
import thunkMiddleware from "redux-thunk"
import { routerMiddleware } from "connected-react-router"
import { applyMiddleware, compose, createStore } from "redux"

import createRootReducer from "../reducers"

import { isProduction } from "../helpers"

let composeEnhancers = compose
if (!isProduction()) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
}

const middleware = [routerMiddleware(history), thunkMiddleware]

export default function configureStore(initialState) {
  return createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  )
}
