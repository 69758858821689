import { project_info, paths } from "../../../../constants"
import Link from "../../../Link"

export default {
  steps: [
    {
      target: "body",
      title: `${project_info.name} Rule Editor`,
      content: (
        <>
          <p>
            The Rule Editor allows you to define exactly how tips should flow
            from one job code to another.
          </p>
          <p>I'll give you a few pointers help you get started.</p>
        </>
      ),
      placement: "center",
      onMount: () => {
        // navigate to basics
        requestAnimationFrame(() => {
          try {
            document.getElementById("rule-editor-step-basics").click()
          } catch (e) {}
        })
      }
    },
    {
      target: "#rule-editor-steps",
      title: "Let's break it down.",
      content: (
        <>
          <p>We've split the process into four steps.</p>
        </>
      )
    },
    {
      target: "#rule-editor-step-basics",
      title: "First Things First",
      content: (
        <>
          <p>Let's start with the basics.</p>
          <p>
            I'll point out a couple things here before we get to the fun stuff.
          </p>
        </>
      ),
      placement: "right"
    },
    {
      target: "#rule-editor-effective-input",
      title: "Backdate, if necessary.",
      content: (
        <>
          <p>
            By default, new rules take effect at the start of the current
            business day.
          </p>
        </>
      )
    },
    {
      target: "#rule-editor-custom-schedule-input",
      title: "For those complicated scenarios...",
      content: (
        <>
          <p>
            Custom schedules allow you to control exactly when a rule should
            apply.
          </p>
          <p>Click the button to customize.</p>
        </>
      ),
      next: {
        onClick: (step, onNext) => {
          // navigate to sale filters
          requestAnimationFrame(() => {
            try {
              document.getElementById("rule-editor-step-sale-filters").click()
            } catch (e) {}
          })
          onNext()
        }
      }
    },
    {
      target: "#rule-editor-step-sale-filters",
      title: "How much is shared?",
      content: (
        <>
          <p>
            In the next step, you'll describe how to calculate the amount shared
            for any given sale.
          </p>
        </>
      ),
      back: {
        onClick: (step, onBack) => {
          // navigate to sale filters
          requestAnimationFrame(() => {
            try {
              document.getElementById("rule-editor-step-basics").click()
            } catch (e) {}
          })
          // allow render time
          setTimeout(onBack, 200)
        }
      }
    },
    {
      target: "#rule-editor-collect-input-group",
      title: "Percent, Source & Frequency",
      content: (
        <>
          <p>
            The amount shared can be calculated as a percentage of sale price or
            tip.
          </p>
          <p>
            The last input allows you to control this rule's distribution
            frequency.
          </p>
        </>
      )
    },
    {
      target: "#rule-editor-distro-frequency",
      title: "Distribution Frequency",
      content: (
        <>
          <p>
            Distribution Frequency describes the time interval in which tips are
            distributed.
          </p>
          <p>
            For example, "Daily" rules distribute tips based on time worked by
            employees that day.
          </p>
          <p>
            The "Time of Sale" option distributes each sale's tips based on time
            worked between sale open and close.
          </p>
        </>
      ),
      next: {
        onClick: (step, onNext) => {
          // navigate to sale filters
          requestAnimationFrame(() => {
            try {
              document.getElementById("rule-editor-source-tips-btn").click()
            } catch (e) {}
          })
          // allow render time
          requestAnimationFrame(() => {
            try {
              onNext()
            } catch {}
          })
        }
      }
    },
    {
      target: "#rule-editor-tip-sources-input",
      title: "When sharing a percentage of tips:",
      content: (
        <>
          <p>Select the tip types your rule should apply to.</p>
        </>
      ),
      next: {
        onClick: (step, onNext) => {
          // navigate to sale filters
          requestAnimationFrame(() => {
            try {
              document.getElementById("rule-editor-source-sales-btn").click()
            } catch (e) {}
          })
          // allow render time
          requestAnimationFrame(() => {
            try {
              onNext()
            } catch {}
          })
        }
      }
    },
    {
      target: "#rule-editor-sale-categories-input",
      title: "When sharing a percentage of sale price:",
      content: (
        <>
          <p>Select which sale item categories your rule applies to.</p>
          <p>
            Tipping out a bartender? Consider sharing only a portion of Beer,
            Wine, or Liquor sales.
          </p>
          <p>
            <i>Note:</i> We pull categories from your POS daily. If you need to
            adjust them, simply update your POS.
          </p>
        </>
      ),
      delay_mount_ms: 750,
      back: {
        onClick: (step, onBack) => {
          // click tips button
          requestAnimationFrame(() => {
            try {
              document.getElementById("rule-editor-source-tips-btn").click()
            } catch (e) {}
          })
          // allow render time
          requestAnimationFrame(() => {
            try {
              onBack()
            } catch {}
          })
        }
      },
      next: {
        onClick: (step, onNext) => {
          // navigate to collect from
          requestAnimationFrame(() => {
            try {
              document.getElementById("rule-editor-source-tips-btn").click()
            } catch (e) {}
          })
          requestAnimationFrame(() => {
            try {
              document.getElementById("rule-editor-step-collect-from").click()
            } catch (e) {}
          })
          onNext()
        }
      }
    },
    {
      target: "#rule-editor-step-collect-from",
      title: "Who's tipping out?",
      content: (
        <>
          <p>Select which job codes will share their tips.</p>
        </>
      ),
      back: {
        onClick: (step, onBack) => {
          // navigate to sale filters
          requestAnimationFrame(() => {
            try {
              document.getElementById("rule-editor-step-sale-filters").click()
            } catch (e) {}
          })
          // select sale based
          requestAnimationFrame(() => {
            try {
              document.getElementById("rule-editor-source-sales-btn").click()
            } catch (e) {}
          })
          // allow render time
          requestAnimationFrame(() => {
            try {
              onBack()
            } catch {}
          })
        }
      }
    },
    {
      target: "#rule-editor-collect-from-input",
      title: "Pick one or many.",
      content: (
        <>
          <p>
            Your rule will only apply to sales made by employees working jobs
            selected here.
          </p>
        </>
      ),
      next: {
        onClick: (step, onNext) => {
          // navigate to distribute to
          requestAnimationFrame(() => {
            try {
              document.getElementById("rule-editor-step-distribute-to").click()
            } catch (e) {}
          })
          requestAnimationFrame(() => {
            try {
              onNext()
            } catch {}
          })
        }
      }
    },
    {
      target: "#rule-editor-step-distribute-to",
      title: "Who gets a cut?",
      content: (
        <>
          <p>This is the last step! It will look familiar.</p>
          <p>Here, you'll choose whom tips are shared with.</p>
        </>
      ),
      back: {
        onClick: (step, onBack) => {
          // navigate to distribute to
          requestAnimationFrame(() => {
            try {
              document.getElementById("rule-editor-step-collect-from").click()
            } catch (e) {}
          })
          requestAnimationFrame(() => {
            try {
              onBack()
            } catch {}
          })
        }
      }
    },
    {
      target: "#rule-editor-distribute-to-input",
      title: "Pick one or many.",
      content: (
        <>
          <p>Tips will be shared with employees working the jobs you select.</p>
          <p>
            The amount received by each employee is based on time worked during
            the appropriate distribution window.
          </p>
        </>
      )
    },
    {
      target: "body",
      placement: "center",
      title: "That's it!",
      content: (
        <>
          <p>Now it's your turn to take it for a spin.</p>
          <p>
            Keep in mind, you can always edit or delete a rule down the road.
          </p>
        </>
      )
    },
    {
      target: "#help-button",
      title: "If you need me again...",
      content: (
        <>
          <p>...you know where to find me.</p>
          <p>
            Feel free to{" "}
            <Link href={paths.contact} target="_blank">
              drop us a note
            </Link>{" "}
            if I left any questions unanswered.
          </p>
        </>
      ),
      disable_next: true
    }
  ]
}
