import { Icon, Message } from "../../../Shared"

import { colors } from "../../../../constants"
import useAuthorization from "../../../../hooks/authorization"
import { rules_manage } from "../../../../constants/permissions"

export default ({}) => {
  const { hasPermission } = useAuthorization()
  const can_edit = hasPermission(rules_manage)
  return (
    <Message
      inline
      type="info"
      title="No results found."
      message={
        <>
          <div>No Tip Sharing Rules were found with the specified filters.</div>
          {can_edit && (
            <div>
              To create a rule, click the&nbsp;&nbsp;
              <Icon name="plus circle" color={colors.success} />
              &nbsp;button.
            </div>
          )}
        </>
      }
    />
  )
}
