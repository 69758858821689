import { Component } from "react"

import Message from "./../Message"
import Styled from "styled-components"

export const TOAST_DEFAULT_TTL_MS = 6000
export const TOAST_LONG_TTL_MS = 9000
export const DELETE_TOAST_DELAY_MS = 1000
const FADE_SECONDS = 0.3

const ToastWrapper = Styled.div`
  text-align: right;
  margin-top: 0.5rem;
  pointer-events: none;
  opacity: ${({ mounted }) => (!!mounted ? 1 : 0)};
  transition: opacity ${FADE_SECONDS}s ease-out;
  & > div {
    text-align: left;
    pointer-events: all;
  }
`

export default class extends Component {
  componentDidMount() {
    this.props.onMount()
  }
  render() {
    const { clear, mounted, onMount, ...props } = this.props
    return (
      <ToastWrapper mounted={mounted}>
        <Message inline onDismiss={clear} {...props} big={true} />
      </ToastWrapper>
    )
  }
}
