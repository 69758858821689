import React, { Component, createRef } from "react"
import { v4 as uuidv4 } from "uuid"
import {
  Chart,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Legend,
  Title,
  Tooltip
} from "chart.js"
import { rgba } from "polished"

import { style, colors } from "../../constants"
import { colorByIndex } from "./helpers"

Chart.register(
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Legend,
  Title,
  Tooltip
)

export default class extends Component {
  chart = null
  ref = createRef()

  componentDidMount() {
    this.renderChart()
  }

  componentDidUpdate() {
    if (!!this.chart) {
      if (!!this.props.redraw) {
        this.chart.destroy()
        this.renderChart()
      } else {
        this.chart.update()
      }
    }
  }

  componentWillUnmount() {
    !!this.chart && this.chart.destroy()
  }

  legendOptions = () => {
    const { legend } = this.props

    if (!legend) {
      return false
    }

    const options = legend === true ? {} : { ...legend }

    return {
      position: "right",
      labels: {
        color: !!this.props.inverted ? colors.light3 : colors.dark3
      },
      ...options
    }
  }

  titleOptions = () => {
    const { title } = this.props

    if (!title) {
      return false
    }

    let options = {}

    if (typeof title === "object") {
      options = { ...title }
    } else {
      options = { text: title }
    }

    return {
      display: true,
      font: {
        size: 16,
        color: !!this.props.inverted ? colors.light : colors.dark3,
        family: style.font
      },
      position: "top",
      ...options
    }
  }

  buildOptions = () => {
    const { options = {} } = this.props
    return {
      aspectRatio: 2,
      plugins: {
        legend: this.legendOptions(),
        title: this.titleOptions()
      },
      scales: {
        x: {
          ticks: {
            color: !!this.props.inverted ? colors.light2 : colors.dark3
          }
        },
        y: {
          ticks: {
            color: !!this.props.inverted ? colors.light2 : colors.dark3
          }
        }
      },
      ...options
    }
  }

  renderChart = () => {
    this.chart = new Chart(this.ref.current.getContext("2d"), {
      type: this.props.type ? this.props.type : "bar",
      data: {
        datasets: [
          {
            borderWidth: 0.5,
            borderColor: rgba(colors.white, 0.5),
            backgroundColor: this.props.data.map(({ color }, idx) =>
              !!color ? color : rgba(colorByIndex(idx), 0.7 - 0.025 * (idx + 1))
            ),
            data: [...this.props.data.map(({ value }) => value)]
          }
        ],
        labels: [...this.props.data.map(({ label }) => label)]
      },
      options: this.buildOptions()
    })
  }

  render() {
    return <canvas id={this.props.id || uuidv4()} ref={this.ref} />
  }
}
