import { Input, Table } from "../../Shared"
import Icon from "../../Icon"
import { colors } from "../../../constants"
import Styled from "styled-components"

const SearchRow = ({ value, onChange }) => (
  <Table.Row as={StyledRow} className="search-row" secondary>
    <Table.Cell collapsing>
      {!value?.length && (
        <Icon fitted name="search" size="large" color={colors.dark2} />
      )}
      {!!value?.length && (
        <Icon
          fitted
          size="large"
          name="remove"
          cursor="pointer"
          color={colors.dark2}
          onClick={() => onChange("")}
        />
      )}
    </Table.Cell>
    <Table.Cell colSpan={2}>
      <Input
        fluid
        debounce
        transparent
        value={value}
        placeholder="search name or email"
        onChange={(d, { value }) => onChange(value)}
      />
    </Table.Cell>
  </Table.Row>
)

const StyledRow = Styled(Table.Row)`
  &.search-row > td {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    & div.input > input {
      padding-top: 1.33rem !important;
      padding-bottom: 1.33rem !important;
    }
  }  
`

export default SearchRow
