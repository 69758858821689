import Styled from "styled-components"

import { colors, style } from "../../constants"

export const CONTENT_MIN_HEIGHT = `calc(
  100vh
  - ${style.footer.height}
  - ${style.navbar.height}
);`

export const CONTENT_PADDING_LEFT = "2rem"
export const CONTENT_PADDING_RIGHT = "1.67rem"

const DEFAULT_HEADER_COLOR = colors.dark
const DEFAULT_HEADER_BACKGROUND = colors.white

const DEFAULT_CONTENT_COLOR = colors.dark2
const DEFAULT_CONTENT_BACKGROUND = colors.white

export const RouteWrapper = Styled(({ className, children }) => (
  <div className={className}>{children}</div>
))`${({
  header_color = DEFAULT_HEADER_COLOR,
  header_background = DEFAULT_HEADER_BACKGROUND,
  content_color = DEFAULT_CONTENT_COLOR,
  content_background = DEFAULT_CONTENT_BACKGROUND
}) => `
  padding-bottom: 4rem;
  min-height: ${CONTENT_MIN_HEIGHT};
  background-color: ${content_background};

  & > ${ContentWrapper} {
    color: ${content_color};
    background-color: ${content_background};
    padding: 0 ${CONTENT_PADDING_RIGHT} 0 ${CONTENT_PADDING_LEFT};
    & .route-content-full-width {
      margin-left: -${CONTENT_PADDING_LEFT};
      margin-right: -${CONTENT_PADDING_RIGHT};
    }
  }

  & > ${HeaderWrapper} {
    position: relative;
    color: ${header_color};
    background-color: ${header_background};

    @media (max-width: ${style.mobile_breakpoint}px) {
      padding: 0 ${CONTENT_PADDING_RIGHT} 0 ${CONTENT_PADDING_LEFT};
    }
    @media (min-width: ${style.mobile_breakpoint}px) {
      padding: 0 calc(13rem + ${CONTENT_PADDING_RIGHT})
        0 ${CONTENT_PADDING_LEFT};
    }

    & > ${Header} {
      display: inline-block;
      font-size: 2.4rem;
      line-height: 6rem;
      font-weight: 300;
      color: ${header_color};
      padding-left: 2px;
      margin-right: 1rem;
    }
  }
`}`

export const ContentWrapper = Styled.div``
export const HeaderWrapper = Styled.div``
export const Header = Styled.div``

export const ControlsWrapper = Styled.div`
  @media (max-width: ${style.mobile_breakpoint}px) {
    display: inline-block;
    vertical-align: middle;
    & > * {
      margin: 0 0.2rem 1rem 0 !important;
    }
  }
  @media (min-width: ${style.mobile_breakpoint}px) {
    position: absolute;
    right: 1.5rem;
    top: 1.67rem;
  }
  & button {
    white-space: nowrap;
  }
`
