import Icon, {
  HelpIcon,
  InfoIcon,
  RemoveIcon,
  SuccessIcon,
  WarningIcon,
  QuestionIcon,
  PeerToPeerIcon,
  ExclamationIcon
} from "../Shared/Icon"

export {
  HelpIcon,
  InfoIcon,
  RemoveIcon,
  SuccessIcon,
  WarningIcon,
  QuestionIcon,
  PeerToPeerIcon,
  ExclamationIcon
}

export default Icon
