import { useRef, useState } from "react"

import Styled from "styled-components"
import { useQuery } from "@apollo/client"
import { Loader } from "semantic-ui-react"

import { Field } from "../../../Styled"
import { Icon, Table, Modal, Button, Message, Input } from "../../../../Shared"

import {
  MAX_DISTRO_WEIGHT,
  DEFAULT_DISTRO_WEIGHT,
  salesGratuityForSources,
  isValidDistributionWeight
} from "../../helpers"
import { colors } from "../../../../../constants"
import { EMPLOYEE_SEARCH } from "../../../../../graphql/queries"
import { isNumber, round, displayMoney } from "../../../../../helpers/number"

// step entrypoint
export const selectEmployeesStep = ({ rule, ...props }, updateRule) => {
  const { loading, data } = useQuery(EMPLOYEE_SEARCH, {
    variables: {
      page: 1,
      first: 4000,
      filters: {
        store_id: props.selected_location_id,
        include_none: false,
        active: true
      }
    },
    fetchPolicy: "no-cache"
  })
  const employees = data?.employeeSearch?.data

  return {
    name: "Select Employees",
    complete:
      rule.employees.reduce(
        (acc, employee) => acc + employee.distribution_weight,
        0
      ) > 0,
    render: () => (
      <SelectEmployees
        {...props}
        loading={loading}
        store_employees={employees}
        rule_employees={rule.employees}
        setRuleEmployees={employees => updateRule({ ...rule, employees })}
        amount_shared={salesGratuityForSources(
          rule.sales,
          rule.distribution_sources
        )}
      />
    )
  }
}

const border_color = colors.light5
const border_radius = "4px"

const SelectEmployees = Styled(({ loading, className, ...props }) => {
  return (
    <>
      {!!loading && <Loader inline active />}
      {!loading && (
        <Field fluid name="Select Employees">
          <div className={className}>
            <div>
              <EmployeeSearch {...props} />
            </div>
            <div>
              <SelectedEmployees {...props} />
            </div>
          </div>
        </Field>
      )}
    </>
  )
})`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 1rem;
`

const EmployeeSearch = Styled(
  ({ store_employees, rule_employees, setRuleEmployees, className }) => {
    const [search, setSearch] = useState("")
    const inputRef = useRef()
    const focusInput = () => inputRef.current.focus()

    let available_employees = []
    try {
      available_employees = store_employees
        .filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()))
        .filter(
          ({ id }) =>
            !rule_employees.map(rule_employee => rule_employee.id).includes(id)
        )
    } catch {}

    return (
      <div className={className}>
        {!!store_employees && (
          <>
            <Input
              fluid
              ref={inputRef}
              value={search}
              autoFocus={true}
              placeholder="Search..."
              action={
                search.trim().length === 0
                  ? undefined
                  : {
                      icon: "remove",
                      onClick: () => {
                        setSearch("")
                        focusInput()
                      }
                    }
              }
              onChange={(e, d) => setSearch(d.value)}
            />
            <div id="employee-search-results">
              {available_employees.map(employee => (
                <EmployeeSearchResult
                  onSelect={() => {
                    setRuleEmployees([
                      ...rule_employees,
                      {
                        ...employee,
                        distribution_weight: DEFAULT_DISTRO_WEIGHT
                      }
                    ])
                    setSearch("")
                    focusInput()
                  }}
                  employee={employee}
                  key={employee.id}
                />
              ))}
              {available_employees.length === 0 && <NoEmployeeSearchResults />}
            </div>
          </>
        )}
      </div>
    )
  }
)`
  position: relative;
  width: 19rem;
  border-radius: ${border_radius};
  & > #employee-search-results {
    max-height: 28rem;
    border-bottom-left-radius: ${border_radius};
    border-bottom-right-radius: ${border_radius};
    overflow-y: auto;
    border-bottom: 0.5px solid ${border_color};
    & > div {
      border-left: 0.5px solid ${border_color};
      border-right: 0.5px solid ${border_color};
      border-bottom: 0.5px solid ${border_color};

      &:last-child {
        border-bottom: none;
      }
    }
  }
  & > .input {
    & > input {
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
    }
    & > input, & > button {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
`

const EmployeeSearchResult = Styled(
  ({ employee, className, onSelect, ...props }) => (
    <div className={className} onClick={onSelect}>
      <div className="employee-name">{employee.name}</div>
      <div className="arrow-icon">
        <Icon fitted name="arrow right" color={colors.light6} />
      </div>
    </div>
  )
)`
  cursor: pointer;
  position: relative;
  background-color: transparent;
  padding: 0.67rem 3rem 0.67rem 1rem;

  & > div.employee-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & > .arrow-icon {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 2rem;
    padding: 0.5rem 0;
    opacity: 0;
  }

  &:hover {
    background-color: ${colors.light2};
    & > .arrow-icon {
      opacity: 1;
    }
  }
`

const NoEmployeeSearchResults = Styled(props => (
  <div {...props}>No Results</div>
))`
  padding: 1rem 1rem;
  font-weight: 300;
`

const SelectedEmployees = ({
  amount_shared,
  rule_employees,
  setRuleEmployees
}) => {
  const total_weight = rule_employees.reduce(
    (acc, { distribution_weight }) => acc + distribution_weight,
    0
  )

  return (
    <>
      {rule_employees.length === 0 && <NoEmployeesSelected />}
      {rule_employees.length > 0 && (
        <>
          <Table fitted>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Employee</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  Distribution Points
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Percent</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                  Amount Received
                </Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {rule_employees.map((employee, idx) => (
                <SelectedEmployee
                  key={employee.id}
                  row={idx + 1}
                  employee={employee}
                  total_weight={total_weight}
                  amount_received={
                    total_weight === 0
                      ? 0
                      : amount_shared *
                        (employee.distribution_weight / total_weight)
                  }
                  setWeight={weight =>
                    setRuleEmployees(
                      rule_employees.map(rule_employee => ({
                        ...rule_employee,
                        distribution_weight:
                          rule_employee.id === employee.id
                            ? weight
                            : rule_employee.distribution_weight
                      }))
                    )
                  }
                  onRemove={() =>
                    setRuleEmployees(
                      rule_employees.filter(({ id }) => id !== employee.id)
                    )
                  }
                />
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell textAlign="center">
                  <strong>Total Points:</strong>&nbsp;&nbsp;{total_weight}
                </Table.HeaderCell>
                <Table.HeaderCell />
                <Table.HeaderCell textAlign="right">
                  {displayMoney(amount_shared)}
                </Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Footer>
          </Table>
        </>
      )}
    </>
  )
}

const SelectedEmployee = Styled(
  ({
    employee,
    amount_received,
    total_weight,
    onRemove,
    setWeight,
    className
  }) => (
    <Table.Row className={className} hover={false}>
      <Table.Cell>
        <div className="selected-employee-name">{employee.name}</div>
      </Table.Cell>
      <Table.Cell collapsing textAlign="center">
        <DistributionWeightControl
          weight={employee.distribution_weight}
          setWeight={setWeight}
        />
      </Table.Cell>
      <Table.Cell collapsing textAlign="right">
        {total_weight === 0
          ? 0
          : round((100 * employee.distribution_weight) / total_weight, 2)}
        %
      </Table.Cell>
      <Table.Cell collapsing textAlign="right" positive>
        {displayMoney(amount_received)}
      </Table.Cell>
      <Table.Cell collapsing textAlign="right">
        <Button
          fitted
          circular
          basic
          icon="remove"
          onClick={() => onRemove()}
        />
      </Table.Cell>
    </Table.Row>
  )
)`
  & > td {
    padding: 1rem;
    vertical-align: baseline;
    & > .selected-employee-name {
      max-width: 10rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`

const DistributionWeightControl = ({ weight, setWeight, className }) => {
  const [request_set_weight, requestSetWeight] = useState(false)
  return (
    <div className={className}>
      <Button.Group size="small">
        <Button
          icon="minus"
          onClick={() => setWeight(round(weight - 1, 2))}
          disabled={weight - 1 <= 0}
        />
        <Button basic onClick={() => requestSetWeight(true)}>
          {weight}
        </Button>
        <Button
          onClick={() => setWeight(round(weight + 1, 2))}
          disabled={weight + 1 > MAX_DISTRO_WEIGHT}
          icon="plus"
        />
      </Button.Group>
      {!!request_set_weight && (
        <SetCustomWeightModal
          weight={weight}
          allow_zero_weight={false}
          onClose={() => requestSetWeight(false)}
          onSubmit={weight => {
            setWeight(weight)
            requestSetWeight(false)
          }}
        />
      )}
    </div>
  )
}

const SetCustomWeightModal = ({
  weight,
  allow_zero_weight = true,
  onClose,
  onSubmit
}) => {
  const [pending_weight, setPendingWeight] = useState(weight)

  return (
    <Modal size="mini">
      <Modal.Header onClose={onClose}>Set Distribution Points</Modal.Header>
      <Modal.Content>
        <Input
          autoFocus={true}
          fluid
          label="Points"
          placeholder="Enter a number."
          value={pending_weight}
          onChange={(e, { value }) => setPendingWeight(value)}
        />
        {!isNumber(pending_weight) && (
          <>
            <br />
            <Message
              type="info"
              title="Invalid input."
              message="Point value must be a number."
            />
          </>
        )}
        {!!isNumber(pending_weight) && (
          <>
            {round(pending_weight, 2) != pending_weight && (
              <>
                <br />
                <Message
                  type="info"
                  title="No more than two decimal places supported."
                  message={`Point value will be rounded to ${round(
                    pending_weight,
                    2
                  )}.`}
                />
              </>
            )}
            {round(pending_weight, 2) > MAX_DISTRO_WEIGHT && (
              <>
                <br />
                <Message
                  type="info"
                  title="Value too high!"
                  message="Point value must be less than 10,000."
                />
              </>
            )}
            {!allow_zero_weight && parseFloat(pending_weight) === 0 && (
              <>
                <br />
                <Message
                  type="info"
                  title="Invalid Value"
                  message="Point value must be greater than 0."
                />
              </>
            )}
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          primary
          disabled={
            !isValidDistributionWeight(pending_weight, allow_zero_weight)
          }
          onClick={() => onSubmit(round(pending_weight, 2))}
        >
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

const NoEmployeesSelected = () => (
  <Message type="clean" centered>
    Select an employee to begin.
  </Message>
)
