import { setting_scheduled_jobs_enabled } from "../../constants"

export const scheduledJobsEnabled = store => {
  if (store.settings !== null && typeof store.settings === "object") {
    for (let key in store.settings) {
      if (
        key === setting_scheduled_jobs_enabled &&
        store.settings[key] === "1"
      ) {
        return true
      }
    }
  }

  return false
}
