import { reviewStep } from "./Sequence/Review"
import { howItWorksStep } from "./Sequence/HowItWorks"
import { selectEmployeesStep } from "./Sequence/SelectEmployees"
import { distributionSourcesStep } from "./Sequence/DistributionSources"

export default (props, updateSequenceState) =>
  [
    howItWorksStep,
    distributionSourcesStep,
    selectEmployeesStep,
    reviewStep
  ].map(step => step(props, updateSequenceState))
