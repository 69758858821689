import { createContext, useContext } from "react"
import { settings_manage_employee_app_users } from "../../../../../constants/permissions"
import { parseSearchQuery } from "../../../../../helpers"
import { useStateObject } from "../../../../../hooks"
import useAuthorization from "../../../../../hooks/authorization"

const UserModalsContext = createContext({})

export const UserModalsProvider = ({ children }) => {
  const { hasPermission } = useAuthorization()
  const { show_invite_modal } = parseSearchQuery(window.location.search)
  const [modal, setModal] = useStateObject({
    delete_user: null,
    edit_user: null,
    user_modal: false,
    // default modal to open if show_invite_modal get param is provided
    employee_invite:
      Number(show_invite_modal) === 1 &&
      hasPermission(settings_manage_employee_app_users),
    resend_invite: null,
    export_users: false
  })

  const showModal = (name, data = true) => setModal({ [name]: data })

  const hideModal = name => setModal({ [name]: null })

  return (
    <UserModalsContext.Provider
      value={{ modal, setModal, showModal, hideModal }}
    >
      {children}
    </UserModalsContext.Provider>
  )
}

export const useUserModals = () => useContext(UserModalsContext)
