import { Component } from "react"
import Styled from "styled-components"

import { colors } from "./../constants"

const SectionWrapper = Styled.div`
  position: relative;
  margin-bottom: 1.5rem;
  font-size: 1rem;
`
const SectionHeader = Styled.div`
  color: ${colors.dark4};
  font-weight: 300;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
`
const SectionContent = Styled.div`
  color: ${colors.dark2};
`

export const Header = props => <SectionHeader {...props} />

export const Content = props => <SectionContent {...props} />

const Section = class extends Component {
  render() {
    return (
      <SectionWrapper {...this.props}>{this.props.children}</SectionWrapper>
    )
  }
}

Section.Header = Header
Section.Content = Content

export default Section
