import Styled from "styled-components"

import Icon from "../../Icon"
import Logo from "../../Logo"
import { Loader, cssTextSelectionDisabled } from "../../Shared"

import { colors, style } from "../../../constants"
import { CONTENT_PADDING_LEFT } from "../../RouteContent/Styled"

// theme
const primary_background = colors.dark3
const active_menu_item_background = colors.dark4
const primary_font_color = colors.white
const secondary_font_color = colors.light2

const logo_primary = colors.info
const logo_secondary = colors.white
const location_icon_color = colors.dark6

// light theme example:
// const primary_background = colors.white
// const active_menu_item_background = colors.light3
// const primary_font_color = colors.dark2
// const secondary_font_color = colors.dark5

// const logo_primary = colors.success
// const logo_secondary = colors.dark4
// const location_icon_color = colors.light6

export const NavbarWrapper = Styled.div`
  padding 0 0 0 0;
  font-size: 1.1rem;
  letter-spacing: 0.03em;
  height: ${style.navbar.height};
  background-color: ${primary_background};
`

export const SingleLocationLabel = Styled(({ name, ...props }) => (
  <div {...props}>
    <Icon name="location arrow" color={location_icon_color} />
    <span>{name}</span>
  </div>
))`
  & > span {
    color: ${primary_font_color};
    margin-left: 0.67rem;
  }
`

export const SingleOrganizationLabel = Styled(({ name, ...props }) => (
  <div {...props}>
    <Icon name="globe" color={location_icon_color} />
    <span>{name}</span>
  </div>
))`
  & > span {
    color: ${primary_font_color};
    margin-left: 0.67rem;
  }
`

export const UserLabel = Styled(({ is_mobile, name, ...props }) => (
  <div {...props}>
    <Icon name="user circle" />
    <span>{name}</span>
  </div>
))`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${({ is_mobile = false }) => (!is_mobile ? "" : "max-width: 10rem;")}
  & > span {
    margin-left: 0.67rem;
    margin-right: 0.33rem;
  }
`

export const MenuItem = Styled(
  ({ active, button, right, children, ...props }) => (
    <div {...props}>
      <div>{children}</div>
    </div>
  )
)`
  height: 100%;
  padding: 0 1em;
  cursor: default;
  font-weight: 300;
  position: relative;
  text-align: center;
  vertical-align: top;
  display: inline-block;
  line-height: ${style.navbar.height};

  ${({ active = false }) =>
    !active
      ? "background-color: transparent;"
      : `background-color: ${active_menu_item_background};`}

  float: ${({ right = false }) => (!!right ? `right` : `left`)};
  ${({ button = true }) =>
    !!button &&
    `cursor: pointer;
    &:hover {
      color: ${primary_font_color};
      background-color: ${active_menu_item_background};
    }`}

  & > div {
    height: 100%;
  }
`

export const LogoButton = cssTextSelectionDisabled(Styled(
  ({ active, ...props }) => (
    <MenuItem {...props} button active={active}>
      <Logo
        size="tiny"
        primaryColor={logo_primary}
        secondaryColor={logo_secondary}
      />
    </MenuItem>
  )
)`
  padding: 0 ${CONTENT_PADDING_LEFT};
  @media (max-width: ${style.mobile_breakpoint}px) {
    padding: 0 1rem;
  }
`)

export const DropdownWrapper = cssTextSelectionDisabled(Styled(
  ({
    active,
    right = false,
    no_caret = false,
    search = false,
    onOpen,
    onClose,
    children,
    ...props
  }) => (
    <MenuItem right={right} active={active}>
      <div
        {...props}
        tabIndex={0}
        onFocus={() => !active && onOpen()}
        onBlur={e => {
          // currentTarget is the parent element,
          // relatedTarget is the clicked element
          if (!e.currentTarget.contains(e.relatedTarget)) {
            onClose()
          }
        }}
        onMouseDown={e => {
          // don't steal focus from search input that autofocused
          if (search || active) {
            e.preventDefault()
          }
          if (!active) {
            onOpen()
          } else if (e.target === e.currentTarget) {
            onClose()
          }
        }}
      >
        <div>
          {children}
          {!no_caret && (
            <div className="drop-caret">
              <Icon fitted name={active && search ? "search" : "caret down"} />
            </div>
          )}
        </div>
      </div>
    </MenuItem>
  )
)`
  z-index: 10;
  position: relative;
  margin: 0 -1em;
  padding: 0;
  cursor: pointer;
  height: 100%;
  text-align: left;
  transition: min-width 0.2s ease-out;
  min-width: ${({ active }) => (active ? `15` : `6`)}rem;

  &:focus {
    outline-width: 0;
  }

  & > div {
    pointer-events: none;
    color: ${primary_font_color};
    padding: 0 2.33rem 0 1rem;

    & > div.drop-caret {
      width: 1.2em;
      & > i {
        position: absolute;
        top: 0em;
        right: 0.8em;
      }
    }
  }
`)

export const DropdownSearchInput = Styled(({ onChange, ...props }) => (
  <input
    autoFocus={true}
    placeholder="Search..."
    type="text"
    size="5"
    onChange={e => onChange(e.target.value)}
    {...props}
  />
))`
  pointer-events: none;
  background-color: transparent;
  border: none;
  font-size: 1.1rem;
  font-family: ${style.font};
  outline: none;
  cursor: pointer;
  font-weight: 300;
  color: ${primary_font_color};
  min-width: 12rem;
  height: 100%;
  &::placeholder {
    /* colors.light6 */
    color: ${secondary_font_color};
  }
`

export const DropdownMenu = Styled(
  ({ right, collapsed, children, ...props }) => (
    <div {...props} tabIndex="-1">
      <div>{children}</div>
    </div>
  )
)`
  letter-spacing: 0;
  pointer-events: none;
  overflow:hidden;
  position: absolute;
  top: 100%;
  left: -0.5px;
  right ${({ right = false }) => (!right ? "-0.5px" : "0")};
  background: transparent;

  & > div {
    position: relative;
    text-overflow: ellipsis;
    overflow-y: auto;
    max-height: 14.67em;
    overflow-y: auto;
    border-top: 0.5px solid ${colors.light3};
    border-right: 1px solid ${colors.light5};
    border-bottom: 1px solid ${colors.light5};
    border-left: 1px solid ${colors.light5};
    border-radius: 0 0 3px 3px;

    transition: transform 0.2s ease-in-out;
    pointer-events: all;

    ${({ collapsed = true }) =>
      !!collapsed
        ? `transform: translateY(-100%);`
        : `transform: translateY(0%);`}

    & > div:last-child {
      border-radius: 0 0 3px 3px;
    }
  }
`

export const DropdownMenuItem = Styled(
  ({ selected, active, loading = false, content, ...props }) => (
    <div {...props}>
      <span>{content}</span>
      {!!loading && <DropdownMenuLoader />}
    </div>
  )
)`${({ loading = false, selected = false, active = false }) => {
  let background = colors.white
  if (active) {
    background = colors.light2
  }
  if (loading) background = colors.light
  return `
  font-size: 1rem;
  font-weight: ${selected ? 400 : 300};
  position: relative;
  line-height: 2rem;
  text-align: left;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${colors.dark};
  background-color: ${background};
  border-top: 0.5px solid rgba(0,0,0,0.1);
  padding: 0.5rem 2.33rem 0.5rem 1.33rem;
  & > div {
    display: inline-block;
  }
  &&& .icon {
    color: ${colors.dark3};
  }
  &:hover {
    background-color: ${colors.light2};
    color: ${colors.dark};
    &&& .icon {
      color: ${colors.dark};
    }
    &::before {
      content: ' ';
      position: absolute;
      right: 1.2rem;
      top: 1.2rem;
      width: 8px;
      height: 8px;
      border-radius: 500rem;
      background-color: ${loading ? colors.transparent : colors.success};
    }
  }
  ${
    loading || selected || active
      ? `
  color: ${colors.dark};
  &&& > .icon {
    color: ${colors.dark};
  }`
      : ``
  }
  ${
    !loading && (selected || active)
      ? `
  &::before {
    content: ' ';
    position: absolute;
    right: 1.2rem;
    top: 1.2rem;
    width: 8px;
    height: 8px;
    border-radius: 500rem;
    background-color: ${active ? colors.success : colors.info};
  }
  `
      : ``
  }
  &:first-child {
    border-top: none;
  }
`
}}`

const DropdownMenuLoader = Styled(props => (
  <div {...props}>
    <Loader size="tiny" inline active />
  </div>
))`
  position: absolute !important;
  right: 0.95rem !important;
  top: 0.425rem !important;
`
