import SelectedFilter from "./Selected"
import { FilterWrapper } from "../Styled"
import EmployeeSearch from "../../../Search/Employee"

export default ({ employee, label, location_id, onSelect, onRemove }) => (
  <FilterWrapper label={label ?? "Employee"}>
    {!employee && (
      <EmployeeSearch
        store_id={location_id}
        onChange={({ employee }) => {
          onSelect(employee)
        }}
      />
    )}
    {!!employee && (
      <SelectedFilter
        value={`${employee.first_name} ${employee.last_name}`}
        onRemove={onRemove}
      />
    )}
  </FilterWrapper>
)
