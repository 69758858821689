import TableRow from "./Row"
import Table from "../../../Table"
import TotalsRow from "./TotalsRow"
import ExportButton from "./Actions/Export"
import { ToastConsumer } from "../../../../context"
import { TableWrapper, NoResultsMessage } from "./Styled"
import { useEtaConfigSetting, useSelectedLocation } from "../../../../hooks"

export default ({
  rows,
  total_rows,
  total_amount_successful,
  total_fees,
  filters,
  setFilters,
  show_failed_payout_modal
}) => {
  const { page, per_page } = filters
  const show_fees = useEtaConfigSetting("hausdirect_enabled", false)
  const selected_location = useSelectedLocation()
  // only show locations column when a location is not selected
  const show_locations = !selected_location

  let hasPassedFailedModal = false

  return (
    <TableWrapper>
      {rows.length === 0 && <NoResultsMessage />}
      {rows.length > 0 && (
        <ToastConsumer>
          {toast => (
            <Table
              compact
              singleLine
              unstackable
              overflow="auto"
              controls={{
                position: rows.length > per_page ? "both" : "top",
                pagination: {
                  current_page: page,
                  total: total_rows,
                  per_page: per_page,
                  include_total: true,
                  changePage: page => setFilters({ page }),
                  changePerPage: per_page => setFilters({ per_page }),
                  options: {
                    per_page: [25, 50, 100, 500]
                  }
                },
                custom: [
                  <ExportButton
                    filters={filters}
                    values={rows}
                    selected_location={selected_location}
                    onDownloadStart={() => {
                      toast({
                        type: "success",
                        message: <p>Payout export download started.</p>
                      })
                    }}
                  />
                ]
              }}
            >
              <Table.Header>
                <Table.Row>
                  {/* user */}
                  <Table.HeaderCell>Initiated By</Table.HeaderCell>
                  {/* locations */}
                  {show_locations && (
                    <Table.HeaderCell>Locations</Table.HeaderCell>
                  )}
                  {/* total amount */}
                  <Table.HeaderCell>Total Sent ($)</Table.HeaderCell>
                  {/* total fees */}
                  {show_fees && <Table.HeaderCell>Fees ($)</Table.HeaderCell>}
                  {/* pay period */}
                  <Table.HeaderCell>For Pay Period</Table.HeaderCell>
                  {/* created_at */}
                  <Table.HeaderCell>Initiated At</Table.HeaderCell>
                  {/* completed_at */}
                  <Table.HeaderCell>Completed At</Table.HeaderCell>
                  {/* status */}
                  <Table.HeaderCell>Status</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <TotalsRow
                  total_amount_successful={total_amount_successful}
                  total_fees={total_fees}
                  show_locations={show_locations}
                />
                {rows.map(row => {
                  // Only open the first failed/partially failed row
                  const shouldShowFailedPayoutModal =
                    !hasPassedFailedModal &&
                    !!show_failed_payout_modal &&
                    (row.failed ||
                      row?.employeePayouts?.some(
                        payout => payout.failed === true
                      ))
                  if (shouldShowFailedPayoutModal) {
                    hasPassedFailedModal = true
                  }

                  return (
                    <TableRow
                      key={row.id}
                      payout={row}
                      show_locations={show_locations}
                      show_failed_payout_modal={shouldShowFailedPayoutModal}
                    />
                  )
                })}
              </Table.Body>
            </Table>
          )}
        </ToastConsumer>
      )}
    </TableWrapper>
  )
}
