import TableRow from "./Row"
import Table from "../../../Table"
import HeaderRow from "./HeaderRow"
import TotalsRow from "./TotalsRow"
import { TableWrapper } from "./Styled"
import ExportButton from "./Actions/Export"
import SendAvailableFunds from "./Actions/SendAvailableFunds"

import { ToastConsumer } from "../../../../context"
import {
  filterAndSortOverviewRows,
  getToastFromPayoutStatus,
  payPeriodLabel
} from "../../helpers"
import { paths } from "../../../../constants"
import { createSearchQuery } from "../../../../helpers"
import { useNavigateTo } from "../../../../hooks"

export default ({
  report_id,
  pay_period,
  report_values,
  payout_buffer,
  selected_location,
  filters,
  setFilters,
  onPayoutCompleted,
  requestEmployeePayouts,
  requestFailedPayoutDetails
}) => {
  const navigateTo = useNavigateTo()
  const { page, per_page, selected_pay_period } = filters

  const filtered_values = filterAndSortOverviewRows(report_values, filters)
  const paginated_values = filtered_values.slice(
    (page - 1) * per_page,
    page * per_page
  )

  const show_location = !selected_location

  const handleNavigateToSettings = () => {
    // navigate to settings/users
    let url = createSearchQuery(paths.settingsUsers, {
      view: "employees",
      show_invite_modal: 1
    })
    navigateTo(url)
  }

  return (
    <TableWrapper>
      <ToastConsumer>
        {toast => (
          <Table
            unstackable
            draggable
            compact
            sortable
            singleLine
            overflow="auto"
            controls={{
              position: filtered_values.length > per_page ? "both" : "top",
              custom_controls_position: "top",
              custom: [
                <ExportButton
                  filters={filters}
                  values={report_values}
                  pay_period={pay_period}
                  selected_location={selected_location}
                  report_id={report_id}
                  onDownloadStart={() => {
                    toast({
                      type: "success",
                      message: (
                        <p>
                          Report download started for{" "}
                          {payPeriodLabel(selected_pay_period)}.
                        </p>
                      )
                    })
                  }}
                />,
                <SendAvailableFunds
                  filters={filters}
                  values={report_values}
                  pay_period={pay_period}
                  selected_location={selected_location}
                  onPayoutCompleted={status => {
                    const toast_config = getToastFromPayoutStatus(status)
                    if (toast_config) {
                      toast({
                        ...toast_config,
                        long: true
                      })
                    }
                    onPayoutCompleted()
                  }}
                  requestFailedPayoutDetails={requestFailedPayoutDetails}
                />
              ],
              pagination: {
                current_page: page,
                total: filtered_values.length,
                per_page: per_page,
                include_total: true,
                changePage: page => setFilters({ page }),
                changePerPage: per_page => setFilters({ per_page }),
                options: {
                  per_page: [25, 50, 100, 500]
                }
              }
            }}
          >
            <Table.Header>
              <HeaderRow
                show_location={show_location}
                sort_field={filters.sort_field}
                sort_direction={filters.sort_direction}
                setSort={(sort_field, sort_direction) =>
                  setFilters({ sort_field, sort_direction })
                }
              />
            </Table.Header>
            <Table.Body>
              <TotalsRow
                filters={filters}
                setFilters={setFilters}
                values={filtered_values}
                show_location={show_location}
                onNavigateToSettings={handleNavigateToSettings}
              />
              {paginated_values.map((row, idx) => (
                <TableRow
                  key={idx}
                  row={row}
                  show_location={show_location}
                  payout_buffer={payout_buffer}
                  requestEmployeePayouts={() => requestEmployeePayouts(row)}
                />
              ))}
            </Table.Body>
          </Table>
        )}
      </ToastConsumer>
    </TableWrapper>
  )
}
