import moment from "moment"
import { useState } from "react"

import Introduction from "./"
import Tour from "../../Tour"
import { Modal } from "../../Shared"

import {
  useUser,
  useEtaEnabled,
  useSetRouteVisited,
  useHasVisitedRoute
} from "../../../hooks"
import {
  expandSidebar,
  collapseSidebar,
  layoutContainerNode
} from "../../../helpers"
import { eta_intro_modal_route_key, project_info } from "../../../constants"
import { INTRO_DECLINED_FOLLOWUP, INTRO_REQUESTED_FOLLOWUP } from "../helpers"

export default () => {
  const { registered_at } = useUser()
  const eta_enabled = useEtaEnabled()
  const setRouteVisited = useSetRouteVisited()
  const has_visited_route = useHasVisitedRoute(eta_intro_modal_route_key)
  const [requested_followup, _requestFollowup] = useState(null)

  const visible =
    !eta_enabled && moment(registered_at).isBefore(moment().subtract(1, "week"))

  const requestFollowup = requested_followup => {
    setRouteVisited(
      eta_intro_modal_route_key,
      requested_followup ? INTRO_REQUESTED_FOLLOWUP : INTRO_DECLINED_FOLLOWUP
    )
    expandSidebar()
    _requestFollowup(requested_followup)
  }
  const tourCompleted = () => {
    _requestFollowup(null)
    collapseSidebar()
  }

  return (
    <>
      {!!visible && (
        <>
          {!has_visited_route && (
            <Modal basic size="large" mountNode={layoutContainerNode()}>
              <Modal.Content>
                <Introduction
                  onRequestFollowup={() => requestFollowup(true)}
                  onDeclineFollowup={() => requestFollowup(false)}
                />
              </Modal.Content>
            </Modal>
          )}
          {requested_followup !== null && (
            <Tour
              {...tour(requested_followup)}
              onClose={() => tourCompleted()}
            />
          )}
        </>
      )}
    </>
  )
}

const tour = requested_followup => ({
  steps: [
    {
      target: "#sidebar-item-eta",
      title: (
        <>
          {!!requested_followup && <>Thanks for your interest!</>}
          {!requested_followup && <>No problem!</>}
        </>
      ),
      placement: "right",
      delay_mount_ms: 300,
      content: (
        <>
          {!!requested_followup && (
            <>
              <p>
                A {project_info.name} representative will reach out soon with
                more information.
              </p>
              <p>
                In the meantime, you can visit this tab to view information
                about ETA&trade;.
              </p>
            </>
          )}
          {!requested_followup && (
            <>
              <p>Timing is everything.</p>
              <p>Check back in here if you change your mind.</p>
            </>
          )}
        </>
      )
    }
  ]
})
