import { useState } from "react"

import Styled from "styled-components"

import Icon from "../../../../Icon"
import Button from "../../../../Button"
import EditShiftModal from "../../Modal/EditShift"

import { colors } from "../../../../../constants"

export default ({ worked_shift, locked, onShiftEdited }) => {
  const [show_modal, setShowModal] = useState(false)
  return (
    <>
      <EditShiftButton locked={locked} onClick={() => setShowModal(true)} />
      {show_modal && (
        <EditShiftModal
          worked_shift={worked_shift}
          onClose={() => setShowModal(false)}
          onSuccess={payload => {
            onShiftEdited(payload)
            setShowModal(false)
          }}
        />
      )}
    </>
  )
}

const EditShiftButton = Styled(({ className, locked = false, ...props }) => (
  <>
    {!locked && (
      <Button
        {...props}
        compact
        circular
        icon="edit"
        size="tiny"
        popup={{ delay: true, content: "Edit Shift" }}
      />
    )}
    {!!locked && (
      <Icon
        name="lock"
        color={colors.tween}
        className={className}
        popup={{
          delay: true,
          content: (
            <>
              This shift can't be modified because it belongs to a finalized pay
              period.
            </>
          )
        }}
      />
    )}
  </>
))`line-height: 2rem;`
