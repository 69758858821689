import { useState } from "react"

import moment from "moment"
import Styled from "styled-components"

import Link from "./Link"
import Modal from "./Modal"
import Button from "./Button"
import Message from "./Message"
import Icon, { WarningIcon } from "./Icon"

import { useBusinessDates, useUser } from "../../hooks/selectors"
import { paths, project_info, weekday_month_day_format } from "../../constants"
import useAuthorization from "../../hooks/authorization"
import { settings_manage_pay_period } from "../../constants/permissions"

export default Styled(
  ({ className, query_start, query_end, lock_threshold }) => {
    const { businessStart, businessEnd } = useBusinessDates()
    query_start = businessStart(query_start)
    query_end = businessEnd(query_end)
    const [show_detail, showDetailModal] = useState(false)
    return (
      <>
        {lockApplies(lock_threshold, query_start) && (
          <div className={className}>
            <Icon name="lock" /> The date range you're viewing is{" "}
            {lock_threshold.isBefore(query_end) && "partially"} locked.{" "}
            <Link onClick={() => showDetailModal(true)}>Learn more.</Link>
          </div>
        )}
        {!!show_detail && (
          <DataLockedModal
            lock_threshold={lock_threshold}
            onClose={() => showDetailModal(false)}
          />
        )}
      </>
    )
  }
)`
  ${({ fitted = false }) => (!!fitted ? "" : "line-height: 2.6rem;")}
  font-weight: 300;
  font-size: 1rem;
`

export const DataLockedModal = ({ lock_threshold, onClose }) => {
  const user = useUser()
  const { hasPermission } = useAuthorization()
  const payroll_enabled = !!user.organization.current_pay_period

  return (
    <Modal onClose={onClose} size="tiny">
      <Modal.Header onClose={onClose}>
        <Icon name="lock" /> Reports Locked
      </Modal.Header>
      <Modal.Content>
        <Message type="info">
          {!!payroll_enabled && (
            <>
              <p>
                To ensure consistency between employee payroll and{" "}
                {project_info.name} reporting, pay periods preceding{" "}
                <strong>
                  {moment(lock_threshold).format(weekday_month_day_format)}
                </strong>{" "}
                have finalized.
              </p>
              <p>
                When a pay period is finalized, information is locked and a
                snapshot of your breakdown report is created.
              </p>
              <p>
                <strong>
                  If you need to apply changes to a locked date range
                </strong>
                , you can <strong>unlock</strong> pay periods from the settings
                page.
              </p>
            </>
          )}
          {!payroll_enabled && (
            <>
              <p>
                Data older than{" "}
                <strong>
                  {moment(lock_threshold).format(weekday_month_day_format)}
                </strong>{" "}
                is locked.
              </p>
              <p>
                Updates from your point of sale will not apply automatically.
              </p>
              <p>
                <Link onClick={() => window.open(paths.contact)}>
                  Contact support
                </Link>{" "}
                to learn more.
              </p>
            </>
          )}
        </Message>
      </Modal.Content>
      {!!payroll_enabled && (
        <Modal.Actions>
          <Button onClick={() => window.open(paths.reportBreakdown)}>
            Breakdown Snapshots
          </Button>
          {!!user && hasPermission(settings_manage_pay_period) && (
            <Button onClick={() => window.open(paths.settingsPayPeriod)}>
              Pay Period Settings
            </Button>
          )}
        </Modal.Actions>
      )}
    </Modal>
  )
}

export const EffectiveDateWarning = Styled(
  ({ lock_threshold, effective_date, className }) => {
    const user = useUser()
    const { businessDate } = useBusinessDates()
    const [show_detail, showDetailModal] = useState(false)
    if (!businessDate(lock_threshold, null).isAfter(effective_date)) {
      return <></>
    }
    return (
      <div className={className}>
        <WarningIcon /> Modifications will take effect starting{" "}
        <strong>{lock_threshold.format(weekday_month_day_format)}</strong>.{" "}
        <Link onClick={() => showDetailModal(true)}>Learn more</Link>.
        {!!show_detail && (
          <DataLockedModal
            user={user}
            lock_threshold={lock_threshold}
            onClose={() => showDetailModal(false)}
          />
        )}
      </div>
    )
  }
)`
  line-height: 3rem;
  font-weight: 300;
`

export const lockApplies = (lock_threshold, query_start) =>
  !!lock_threshold && lock_threshold.isAfter(query_start)
