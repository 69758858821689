import { Badge } from "../../../../Shared"
import { ReviewRow, LabelCell, ValueCell } from "../Styled"

import { useLocation, useLocations } from "../../../../../hooks"

export default ({ locations }) => {
  const all_locations = useLocations()
  const location_names = locations
    .map(location_id => useLocation(location_id)?.name)
    .filter(name => !!name)
    .sort((a, b) => a.localeCompare(b))
  return (
    <>
      {all_locations.length > 1 && location_names.length > 0 && (
        <ReviewRow>
          <LabelCell label={`Location${locations.length > 1 ? "s" : ""}`} />
          <ValueCell>
            {location_names.map(name => (
              <Badge key={name} basic>
                {name}
              </Badge>
            ))}
          </ValueCell>
        </ReviewRow>
      )}
    </>
  )
}
