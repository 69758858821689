import { useState } from "react"

import moment from "moment"
import { Checkbox } from "semantic-ui-react"
import { Mutation } from "@apollo/client/react/components"

import ChecksModal from "../Modal/ChecksDetail"

import {
  Icon,
  Table,
  HelpIcon,
  AreYouSure,
  ExclamationIcon
} from "../../../Shared"
import {
  SaleRow,
  JobCodeCell,
  LabelButton,
  TableWrapper,
  MultiSelectRow
} from "./Styled"
import EditSale from "./Actions/EditSale"

import SaleDistributions from "../Modal/SaleDistributions"
import { SaleAssignedRuleModal } from "../Modal/CustomTeamAdjustment"

import { ruleIsEffective } from "../../../Rule/helpers"
import { toInt, displayNumber } from "../../../../helpers"
import { useRevenueCentersEnabled } from "../../../../hooks"
import { colors, datetime_format_verbose } from "../../../../constants"
import { REMOVE_SALE_ASSIGNED_JOB_CODE } from "../../../../graphql/mutations"
import useAuthorization from "../../../../hooks/authorization"
import { reporting_assign_job_code_to_sales } from "../../../../constants/permissions"

const datetime_format_compact = "MMM Do [at] h:mm a"

export const parseSaleJobCode = sale => {
  // Direct sale assignment is highest priority
  if (sale.assignedJobCode) {
    return sale.assignedJobCode
  }
  // Employee assignment next
  if (sale.employee && sale.employee.assignedJobCode) {
    return sale.employee.assignedJobCode
  }
  // last priority is shift job code
  if (sale.workedShift && sale.workedShift.jobCode) {
    return sale.workedShift.jobCode
  }

  return null
}

export default ({
  results,
  refetch,
  controls,
  compact_view,
  onToggleSale,
  canSelectSale,
  businessStart,
  assign_job_code,
  onToggleAllSales,
  draggable = true,
  onToggleManySales,
  saleLockThreshold,
  multiselect_enabled,
  multiselect_selected,
  selected_location_id,
  onRuleAssignmentRemoved,
  onJobCodeAssignmentRemoved,
  onSaleEdited: onSaleEditedCallback
}) => {
  const { hasPermission } = useAuthorization()
  const rev_centers_enabled = useRevenueCentersEnabled()

  const [state, setState] = useState({
    checks_modal: false,
    distros_modal: false,
    assigned_rule_modal: false,
    remove_assignment_request: false
  })

  const {
    checks_modal,
    distros_modal,
    assigned_rule_modal,
    remove_assignment_request
  } = state

  const validChecks = checks =>
    checks.filter(check => check.voided === false && check.deleted === false)

  const onSaleEdited = ({ updateSale }) =>
    onSaleEditedCallback(moment(updateSale.sale_time).isBefore(businessStart()))

  const {
    sales,
    sum_tax,
    sum_price,
    sum_cc_tip,
    sum_gc_tip,
    sum_service_charge
  } = results

  const count_selected_on_page = !multiselect_enabled
    ? 0
    : sales.filter(({ id }) => multiselect_selected.includes(id)).length
  const count_selectable_on_page = !multiselect_enabled
    ? 0
    : sales.filter(sale => canSelectSale(sale)).length

  return (
    <TableWrapper disable_text_selection={!!multiselect_enabled}>
      <Table
        id="report-table-sales"
        compact
        singleLine
        unstackable
        controls={controls}
        draggable={draggable}
      >
        <Table.Header>
          <Table.Row>
            {!!multiselect_enabled && <Table.HeaderCell />}
            <Table.HeaderCell>Employee</Table.HeaderCell>
            {!compact_view && <Table.HeaderCell>Sale ID</Table.HeaderCell>}
            {!compact_view && <Table.HeaderCell>Payroll ID</Table.HeaderCell>}
            <Table.HeaderCell>Job Code</Table.HeaderCell>
            {!compact_view && !selected_location_id && (
              <Table.HeaderCell>Location</Table.HeaderCell>
            )}
            {rev_centers_enabled && !compact_view && (
              <Table.HeaderCell>Revenue Center</Table.HeaderCell>
            )}
            <Table.HeaderCell>Total Tender ($)</Table.HeaderCell>
            <Table.HeaderCell>Subtotal ($)</Table.HeaderCell>
            {!compact_view && <Table.HeaderCell>Tax ($)</Table.HeaderCell>}
            <Table.HeaderCell>CC Tip ($)</Table.HeaderCell>
            {!compact_view && <Table.HeaderCell>GC Tip ($)</Table.HeaderCell>}
            {!compact_view && (
              <Table.HeaderCell>Service Charge ($)</Table.HeaderCell>
            )}
            {!compact_view && <Table.HeaderCell>Opened</Table.HeaderCell>}
            <Table.HeaderCell>Closed</Table.HeaderCell>
            <Table.HeaderCell id="report-table-checks-header">
              Checks
            </Table.HeaderCell>
            <Table.HeaderCell id="report-table-distributions-header">
              Distributions
            </Table.HeaderCell>
            {!multiselect_enabled && <Table.HeaderCell />}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {!!multiselect_enabled && (
            <MultiSelectRow
              onClick={() =>
                onToggleAllSales(
                  count_selected_on_page !== count_selectable_on_page
                )
              }
            >
              <Table.Cell collapsing>
                <Checkbox
                  fitted
                  checked={count_selected_on_page === count_selectable_on_page}
                  indeterminate={
                    count_selected_on_page > 0 &&
                    count_selected_on_page < count_selectable_on_page
                  }
                  style={{ verticalAlign: "middle" }}
                  onChange={(e, { checked }) => {
                    e.stopPropagation()
                    e.preventDefault()
                    onToggleAllSales(checked)
                  }}
                />
              </Table.Cell>
              <Table.Cell colSpan={100}>
                Select All&nbsp;
                <HelpIcon popup="Select all sales on the current page." />
              </Table.Cell>
            </MultiSelectRow>
          )}
          {!multiselect_enabled && (
            <Table.TotalsRow>
              <Table.Cell>Total</Table.Cell>
              {!compact_view && <Table.Cell>-</Table.Cell>}
              {!compact_view && <Table.Cell>-</Table.Cell>}
              <Table.Cell>-</Table.Cell>
              {!compact_view && !selected_location_id && (
                <Table.Cell>-</Table.Cell>
              )}
              {!!rev_centers_enabled && !compact_view && (
                <Table.Cell>-</Table.Cell>
              )}
              {/* sum of total tender */}
              <Table.TotalCell
                value={displayNumber(
                  sum_price +
                    sum_tax +
                    sum_cc_tip +
                    sum_gc_tip +
                    sum_service_charge,
                  2
                )}
              />
              {/* sum of subtotal */}
              <Table.TotalCell value={displayNumber(sum_price, 2)} />
              {!compact_view && (
                <Table.TotalCell value={displayNumber(sum_tax, 2)} />
              )}
              <Table.TotalCell value={displayNumber(sum_cc_tip, 2)} />
              {!compact_view && (
                <Table.TotalCell value={displayNumber(sum_gc_tip, 2)} />
              )}
              {!compact_view && (
                <Table.TotalCell value={displayNumber(sum_service_charge, 2)} />
              )}
              {!compact_view && <Table.Cell>-</Table.Cell>}
              <Table.Cell>-</Table.Cell>
              <Table.Cell>-</Table.Cell>
              <Table.Cell>-</Table.Cell>
              {!multiselect_enabled && <Table.Cell />}
            </Table.TotalsRow>
          )}
          {sales.map((sale, idx) => {
            const sale_lock_threshold = saleLockThreshold(sale)
            const sale_locked = sale_lock_threshold.isAfter(sale.opened_time)
            const can_select_sale = canSelectSale(sale)
            const sale_checks = validChecks(sale.checks)
            return (
              <SaleRow
                key={idx}
                select_enabled={multiselect_enabled}
                selected={multiselect_selected.includes(sale.id)}
                selectable={can_select_sale}
                onSelect={e =>
                  e.shiftKey ?? false
                    ? onToggleManySales(sale)
                    : onToggleSale(sale)
                }
              >
                {!!multiselect_enabled && (
                  <Table.Cell collapsing>
                    {!!can_select_sale && (
                      <Checkbox
                        fitted
                        checked={multiselect_selected.includes(sale.id)}
                        style={{ verticalAlign: "middle" }}
                        onChange={e => {
                          e.stopPropagation()
                          e.preventDefault()
                          return e.shiftKey ?? false
                            ? onToggleManySales(sale)
                            : onToggleSale(sale)
                        }}
                      />
                    )}
                    {!can_select_sale && !!sale_locked && (
                      <Icon color={colors.tween} name="lock" />
                    )}
                  </Table.Cell>
                )}
                <Table.Cell>{`${
                  sale.employee.first_name ? sale.employee.first_name : ""
                } ${
                  sale.employee.last_name ? sale.employee.last_name : ""
                }`}</Table.Cell>
                {!compact_view && (
                  <Table.CopyToClipboardCell
                    max_width="18rem"
                    enable_copy={!multiselect_enabled && !!sale.external_id}
                    value={sale.external_id ? sale.external_id : "-"}
                  />
                )}
                {!compact_view && (
                  <Table.CopyToClipboardCell
                    max_width="18rem"
                    enable_copy={
                      !multiselect_enabled && !!sale.employee.payroll_id
                    }
                    value={
                      sale.employee.payroll_id ? sale.employee.payroll_id : "-"
                    }
                  />
                )}
                <Table.Cell>
                  <JobCodeCell
                    sale={sale}
                    selected={multiselect_selected.includes(sale.id)}
                    assign_job_code={assign_job_code}
                    removeJobCodeRequest={
                      !hasPermission(reporting_assign_job_code_to_sales) ||
                      multiselect_enabled ||
                      sale_locked
                        ? null
                        : () => {
                            setState({ remove_assignment_request: sale })
                          }
                    }
                  />
                </Table.Cell>
                {!compact_view && !selected_location_id && (
                  <Table.Cell>{sale.employee.store.name}</Table.Cell>
                )}
                {!!rev_centers_enabled && !compact_view && (
                  <Table.Cell>{sale.revenueCenter?.name ?? "-"}</Table.Cell>
                )}
                {/* total tender column (factors in refunds) */}
                <Table.Cell>
                  {sale.total === 0 ? "-" : displayNumber(sale.total, 2)}
                </Table.Cell>
                {/* subtotal (factors in refunds) */}
                <Table.Cell>
                  {sale.total_sales === 0 && sale.total_sales_refund === 0 ? (
                    "-"
                  ) : (
                    <div>
                      {displayNumber(
                        sale.total_sales - sale.total_sales_refund,
                        2
                      )}{" "}
                      {sale.total_sales_refund > 0 && (
                        <ExclamationIcon
                          popup={{
                            flowing: true,
                            content: `Refund Applied: $${displayNumber(
                              sale.total_sales_refund,
                              2
                            )}`
                          }}
                        />
                      )}
                    </div>
                  )}
                </Table.Cell>
                {!compact_view && (
                  <Table.Cell>
                    {sale.total_tax > 0
                      ? displayNumber(sale.total_tax, 2)
                      : "-"}
                  </Table.Cell>
                )}
                {/* cc tips column (doesn't factor in refunds) */}
                <Table.Cell>
                  {sale.total_cc_tips === 0 ? (
                    "-"
                  ) : (
                    <div>
                      {displayNumber(
                        sale.total_cc_tips - sale.total_cc_tips_refund,
                        2
                      )}{" "}
                      {sale.total_cc_tips_refund > 0 && (
                        <ExclamationIcon
                          popup={{
                            flowing: true,
                            content: `Refund Applied: $${displayNumber(
                              sale.total_cc_tips_refund,
                              2
                            )}`
                          }}
                        />
                      )}
                    </div>
                  )}
                </Table.Cell>
                {!compact_view && (
                  <Table.Cell>
                    {sale.total_gc_tips === 0
                      ? "-"
                      : displayNumber(
                          sale.total_gc_tips - sale.total_gc_tips_refund,
                          2
                        )}{" "}
                    {sale.total_gc_tips_refund > 0 && (
                      <ExclamationIcon
                        popup={{
                          flowing: true,
                          content: `Refund Applied: $${displayNumber(
                            sale.total_gc_tips_refund,
                            2
                          )}`
                        }}
                      />
                    )}
                  </Table.Cell>
                )}
                {!compact_view && (
                  <Table.Cell>
                    {sale.total_service_charges === 0 ? (
                      "-"
                    ) : (
                      <div>{displayNumber(sale.total_service_charges, 2)}</div>
                    )}
                  </Table.Cell>
                )}
                {!compact_view && (
                  <Table.Cell>
                    {moment(sale.opened_time).format(
                      !!compact_view
                        ? datetime_format_verbose
                        : datetime_format_compact
                    )}
                  </Table.Cell>
                )}
                <Table.Cell>
                  {moment(sale.sale_time).format(
                    !!compact_view
                      ? datetime_format_verbose
                      : datetime_format_compact
                  )}
                </Table.Cell>
                <Table.Cell>
                  {!sale_checks.length ? (
                    "-"
                  ) : (
                    <LabelButton
                      className="sales-table-checks-badge"
                      popup={{
                        delay: true,
                        content: `View Check${
                          sale_checks.length > 1 ? "s" : ""
                        }`
                      }}
                      onClick={() => setState({ checks_modal: sale })}
                    >
                      {sale_checks.length}
                    </LabelButton>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {sale.deposit_distributions_count === 0 &&
                    sale.assignedDistributionRules.length === 0 &&
                    "-"}
                  {sale.deposit_distributions_count > 0 && (
                    <LabelButton
                      popup={{ delay: true, content: "View Distributions" }}
                      onClick={() => setState({ distros_modal: sale })}
                    >
                      {sale.deposit_distributions_count}
                    </LabelButton>
                  )}
                  {sale.assignedDistributionRules.filter(rule =>
                    ruleIsEffective(sale.sale_time, rule)
                  ).length > 0 && (
                    <LabelButton
                      primary
                      onClick={() => setState({ assigned_rule_modal: sale })}
                      popup={{
                        delay: true,
                        content: "Custom team adjustment applied"
                      }}
                    >
                      <Icon name="group" fitted />
                    </LabelButton>
                  )}
                </Table.Cell>
                {!multiselect_enabled && (
                  <Table.Cell textAlign="center">
                    <EditSale
                      sale={sale}
                      locked={sale_locked}
                      min_sale_date={sale_lock_threshold}
                      job_code={parseSaleJobCode(sale)}
                      onSaleEdited={onSaleEdited}
                    />
                  </Table.Cell>
                )}
              </SaleRow>
            )
          })}
        </Table.Body>
      </Table>
      {!!checks_modal && (
        <ChecksModal
          sale={checks_modal}
          onClose={() => setState({ checks_modal: false })}
        />
      )}
      {!!distros_modal && (
        <SaleDistributions
          sale_id={distros_modal.id}
          onClose={() => setState({ distros_modal: false })}
        />
      )}
      {!!remove_assignment_request && (
        <Mutation
          mutation={REMOVE_SALE_ASSIGNED_JOB_CODE}
          onCompleted={() => {
            onJobCodeAssignmentRemoved(
              moment(remove_assignment_request.sale_time).isBefore(
                businessStart()
              )
            )
            setState({
              remove_assignment_request: false
            })
          }}
        >
          {(removeJobCode, { loading }) => {
            const sale = { ...remove_assignment_request }

            let default_job = "None"
            if (!!sale.employee && !!sale.employee.assignedJobCode) {
              default_job = sale.employee.assignedJobCode.name
            } else if (!!sale.workedShift && !!sale.workedShift.jobCode) {
              default_job = sale.workedShift.jobCode.name
            }
            return (
              <AreYouSure
                header="Are you sure?"
                submitted={!!loading}
                body={
                  <>
                    <p>
                      If you continue, this sale will fall back to the{" "}
                      <strong>{default_job}</strong> job code.
                    </p>
                    <p>Would you like to proceed?</p>
                  </>
                }
                onConfirm={() => {
                  const variables = {
                    id: toInt(sale.id)
                  }
                  removeJobCode({ variables })
                }}
                onClose={() =>
                  setState({
                    remove_assignment_request: false
                  })
                }
              />
            )
          }}
        </Mutation>
      )}
      {!!assigned_rule_modal && (
        <SaleAssignedRuleModal
          sale={{ ...assigned_rule_modal }}
          saleLockThreshold={saleLockThreshold}
          onClose={() => setState({ assigned_rule_modal: false })}
          onAssignmentRemoved={() => {
            setState({ assigned_rule_modal: false })
            onRuleAssignmentRemoved(
              moment(assigned_rule_modal.sale_time).isBefore(businessStart())
            )
            refetch()
          }}
        />
      )}
    </TableWrapper>
  )
}
