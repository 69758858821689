import { useMemo } from "react"

import { useObjectSelector } from "./"

export const useOrganization = () =>
  useObjectSelector(state => state.app?.user?.organization)

export const useOrgIsMultiUnit = () => useOrganization().multi_unit_org ?? false

export const usePayPeriods = () => {
  const {
    payroll_interval,
    payroll_reference_date,
    current_pay_period,
    last_pay_period,
    pay_period_before_last
  } = useOrganization() ?? {}
  return useMemo(
    () => ({
      interval: payroll_interval,
      reference_date: payroll_reference_date,
      current_pay_period,
      last_pay_period,
      pay_period_before_last
    }),
    [
      payroll_interval,
      payroll_reference_date,
      current_pay_period?.id,
      last_pay_period?.id,
      pay_period_before_last?.id
    ]
  )
}

export const useCurrentPayPeriod = () => usePayPeriods().current_pay_period

export const useEtaConfig = () => useOrganization()?.eta_config ?? {}

export const useEtaConfigSetting = (setting_key, default_value = undefined) =>
  useEtaConfig()[setting_key] ?? default_value
