import { useState } from "react"

import Icon from "../../Icon"
import { SearchLink } from "./Styled"
import AdvancedSearchModal from "./Filters/Modal"

import { FILTERS_TEMPLATE } from "../helpers"
import { useSelectedLocation } from "../../../hooks/selectors"

export default ({ applied_filters, object_name, onSubmit }) => {
  const [filters, setFilters] = useState(null)
  const selected_location = useSelectedLocation()

  const parseFilters = () => {
    const filters = Object.keys(FILTERS_TEMPLATE).reduce(
      (acc, field) => ({
        ...acc,
        [field]: applied_filters[field] ?? FILTERS_TEMPLATE[field]
      }),
      {}
    )
    return { ...filters, location: selected_location }
  }
  return (
    <>
      <SearchLink onClick={() => setFilters(parseFilters())}>
        <Icon name="search plus" /> More Filters
      </SearchLink>
      {!!filters && (
        <AdvancedSearchModal
          filters={filters}
          setFilters={setFilters}
          resetFilters={() => setFilters({ ...FILTERS_TEMPLATE })}
          object_name={object_name}
          onSubmit={() => {
            onSubmit(filters)
            setFilters(null)
          }}
          onClose={() => {
            setFilters(null)
          }}
        />
      )}
    </>
  )
}
