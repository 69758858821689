import { useEffect } from "react"
import { Redirect } from "react-router"
import { useMutation } from "@apollo/client"

import RuleEditor from "../Editor"
import { Link } from "../../../Shared"

import { paths } from "../../../../constants"
import { prepareRuleAsTemplate } from "../helpers"
import { CREATE_RULE } from "../../../../graphql/mutations"
import { useBusinessDates, useReportLockThreshold } from "../../../../hooks"
import useAuthorization from "../../../../hooks/authorization"
import { rules_manage } from "../../../../constants/permissions"

export default ({ create_rule_template, ...props }) => {
  const { hasPermission } = useAuthorization()
  const can_edit = hasPermission(rules_manage)
  const bizDates = useBusinessDates()
  const reportLockThreshold = useReportLockThreshold()
  const rule_template = !!create_rule_template
    ? prepareRuleAsTemplate(create_rule_template, reportLockThreshold)
    : null

  // component unmount behavior
  useEffect(() => {
    return () => {
      // unset the rule template when leaving the page
      props.setRuleTemplateForCreate(null)
    }
  }, [])

  const [createRule, { loading }] = useMutation(CREATE_RULE, {
    onCompleted: () => {
      props.toast(SUCCESS_TOAST_PAYLOAD)
      props.navigateTo(paths.distributionRules)
    },
    onError: () => props.toast(ERROR_TOAST_PAYLOAD)
  })

  return (
    <>
      {!can_edit && <Redirect to={paths.distributionRules} />}
      {!!can_edit && (
        <RuleEditor
          {...props}
          {...bizDates}
          rule={rule_template}
          onRuleSubmit={rule =>
            createRule({
              variables: {
                input: { ...rule }
              }
            })
          }
          loading={loading}
          title="Create Rule"
          is_edit={false}
        />
      )}
    </>
  )
}

// helpers
const SUCCESS_TOAST_PAYLOAD = {
  type: "success",
  message: (
    <>
      <p>Rule created successfully.</p>
      <p>
        It may take some time for changes to appear in your Breakdown report.
      </p>
    </>
  )
}

const ERROR_TOAST_PAYLOAD = {
  ttl_ms: 30000,
  type: "error",
  title: "Oh No!",
  message: (
    <>
      <div>An unexpected error ocurred while trying to create this rule.</div>
      <div>Try refreshing the page and creating the rule again.</div>
      <div>
        If that doesn't work,{" "}
        <Link href={paths.contact} target="_blank">
          let our support team know
        </Link>
        .
      </div>
    </>
  )
}
