import { useState } from "react"
import Styled from "styled-components"
import { Form } from "semantic-ui-react"
import { Mutation } from "@apollo/client/react/components"

import Modal from "."
import Icon from "../Icon"
import Button from "../Button"
import Message from "../Message"
import Dropdown from "../Dropdown"

import { colors } from "../../constants"
import { useLocations } from "../../hooks"
import { CREATE_CUSTOM_JOB_CODE } from "../../graphql/mutations"

export default ({ location_id = null, toast, onCompleted, onClose }) => {
  const locations = useLocations()

  const [state, _setState] = useState({
    name: "",
    selected_location: location_id,
    errors: []
  })

  const setState = new_state => _setState({ ...state, ...new_state })

  const { name, errors, selected_location } = state

  return (
    <ModalWrapper>
      <Modal size="mini" dimmer onClose={onClose}>
        <Modal.Header onClose={onClose}>Create a Custom Job Code</Modal.Header>
        <Modal.Content>
          <InfoMessage
            message={
              <>
                <p>
                  You can use a custom job code to impact tip distribution by
                  assigning the job code directly to employees or sales.
                </p>
                <p>
                  Employee assignments are managed from the employee settings
                  tab.
                </p>
                <p>
                  Sale assignments are managed from the sales reporting page.
                </p>
              </>
            }
          />
          {errors.length > 0 && (
            <ErrorMessage onDismiss={() => setState({ errors: [] })}>
              {errors.map((err, idx) => (
                <p key={idx}>{err}</p>
              ))}
            </ErrorMessage>
          )}
          <Form autoComplete="off">
            <Form.Field>
              <Form.Input
                label="Job Name"
                autoFocus={true}
                spellCheck="false"
                placeholder="My Custom Job Code"
                type="text"
                autoComplete="off"
                icon={
                  !!name && name.length > 0 ? (
                    <Icon color={colors.green} name="check" fitted />
                  ) : null
                }
                value={name}
                onChange={(e, { value }) =>
                  setState({
                    name: value
                  })
                }
              />
            </Form.Field>
            {!location_id && (
              <Form.Field>
                <label>Select a Location</label>
                <Dropdown
                  clearable
                  selection
                  options={locations.map(location => ({
                    key: location.id,
                    text: location.name,
                    value: location.id
                  }))}
                  placeholder="Select one"
                  value={selected_location}
                  onChange={(e, { value }) =>
                    setState({ selected_location: value })
                  }
                />
              </Form.Field>
            )}
          </Form>
        </Modal.Content>
        <Mutation
          mutation={CREATE_CUSTOM_JOB_CODE}
          onError={() =>
            setState({
              errors: [
                "An error ocurred when attempting to create your job custom code."
              ]
            })
          }
          onCompleted={({ createCustomJobCode }) => {
            if (!!toast) {
              toast({
                type: "success",
                message: (
                  <>
                    <p>Custom job code created successfully.</p>
                  </>
                )
              })
            }
            onCompleted(createCustomJobCode)
            onClose()
          }}
        >
          {(createCustomJobCode, { loading }) => {
            return (
              <Modal.Actions>
                <Button onClick={onClose} disabled={!!loading}>
                  Close
                </Button>
                <Button
                  loading={!!loading}
                  disabled={!!loading || !inputIsValid(state)}
                  onClick={() => {
                    createCustomJobCode({
                      variables: {
                        store_id: selected_location,
                        name
                      }
                    })
                    setState({ errors: [] })
                  }}
                  primary
                  icon
                  labelPosition="right"
                >
                  Submit <Icon name="check" />
                </Button>
              </Modal.Actions>
            )
          }}
        </Mutation>
      </Modal>
    </ModalWrapper>
  )
}

// helpers
const inputIsValid = ({ name, selected_location }) =>
  name.trim().length > 0 && !!selected_location

// Styled components
const ModalWrapper = Styled.div``
const InfoMessage = Styled(props => <Message type="info" {...props} />)`
  margin-bottom: 1rem;
`
const ErrorMessage = Styled(props => <Message type="error" {...props} />)`
  margin-bottom: 1rem;
`
