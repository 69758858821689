import { Component } from "react"
import { Redirect } from "react-router"
import { IntercomProvider } from "react-use-intercom"

import Main from "./Main"
import Admin from "../Admin"
import Routes from "./Routes"
import NoAccess from "./NoAccess"
import { Loader } from "../Shared"
import Enterprise from "../Enterprise"

import { LayoutWrapper, FullPage } from "./Styled"
import ContactSupport from "../Modal/ContactSupport"

import { paths } from "../../constants"
import { getIntercomAppId, parseSearchQuery } from "../../helpers"
import GlobalRoutes from "./GlobalRoutes"

export default class extends Component {
  logout_timeout = null

  clearLogoutTimeout() {
    clearTimeout(this.logout_timeout)
    this.logout_timeout = null
  }

  setLogoutTimeout(expiration_sec) {
    const { logout } = this.props
    const now_sec = Math.floor(Date.now() / 1000)

    this.clearLogoutTimeout()

    if (expiration_sec <= now_sec) {
      logout()
    } else {
      this.logout_timeout = setTimeout(
        () => logout(),
        (expiration_sec - now_sec) * 1000
      )
    }
  }

  componentDidUpdate() {
    if (this.props.logged_in) {
      if (
        this.logout_timeout === null &&
        this.props.user &&
        this.props.user.token_expiration
      ) {
        this.setLogoutTimeout(this.props.user.token_expiration)
      }
    } else if (this.logout_timeout !== null) {
      this.clearLogoutTimeout()
    }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    console.error(error)
    this.props.triggerAppError({
      error_msg: error.message,
      error_trace: info.componentStack
    })
  }

  // location object looks different after routing
  parseLocation = () =>
    this.props.location && this.props.location.location
      ? this.props.location.location
      : this.props.location

  render() {
    const {
      logout_pending,
      user,
      syncing_user,
      authenticated,
      ...props
    } = this.props

    const is_super = user?.admin ?? false
    const is_enterprise_admin = user?.enterprise ?? false
    const location = this.parseLocation()

    return (
      <LayoutWrapper>
        {/* display loading screen while attempting to fetch user */}
        {!!syncing_user && (
          <FullPage>
            <Loader indeterminate size="big"></Loader>
          </FullPage>
        )}
        {/* admin user */}
        {!!authenticated &&
          !!location &&
          location.pathname !== paths.login &&
          !user.organization?.id &&
          is_super && <Admin {...this.props} user={user} location={location} />}
        {/* enterprise user */}
        {!!authenticated &&
          !!location &&
          location.pathname !== paths.login &&
          !user.organization?.id &&
          is_enterprise_admin && (
            <Enterprise {...this.props} user={user} location={location} />
          )}
        {/* user logged in, not on login page; render dashboard content*/}
        {!!authenticated &&
          !!location &&
          !!user.organization?.id &&
          user.store_ids &&
          user.store_ids.length > 0 &&
          location.pathname !== paths.login && (
            <IntercomProvider appId={getIntercomAppId()}>
              <Main {...props} location={location} user={user}>
                <Routes {...this.props} />
              </Main>
            </IntercomProvider>
          )}
        {/* user logged in, with no access to any locations*/}
        {!!authenticated &&
          !!location &&
          !!user.organization?.id &&
          user.store_ids &&
          user.store_ids.length === 0 &&
          location.pathname !== paths.login && (
            <NoAccess
              user={user}
              logout={props.logout}
              loading={!!logout_pending}
            />
          )}
        {/* user logged in, on login page; redirect to dashboard */}
        {!!authenticated &&
          !!location &&
          location.pathname === paths.login &&
          (() => {
            const query = parseSearchQuery(window.location.search)
            return <Redirect to={!!query.path ? query.path : paths.index} />
          })()}
        {/* user not logged in, on login page; render login */}
        {!authenticated && !!location && location.pathname === paths.login && (
          <IntercomProvider appId={getIntercomAppId()}>
            <Routes {...this.props} />
          </IntercomProvider>
        )}
        {/* user not logged in, not on login page; redirect to login page */}
        {!syncing_user &&
          !authenticated &&
          !!location &&
          location.pathname !== paths.login &&
          location.pathname !== paths.maintenance &&
          !logout_pending && <LoginRedirect />}
        {/* global modals */}
        {props.contact_support_modal && (
          <ContactSupport {...this.props} location={location} />
        )}
        <GlobalRoutes {...this.props} />
      </LayoutWrapper>
    )
  }
}

class LoginRedirect extends Component {
  componentDidMount() {
    window.location.href = paths.login
  }
  render() {
    return (
      <FullPage>
        <Loader indeterminate size="big"></Loader>
      </FullPage>
    )
  }
}
