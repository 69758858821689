import moment from "moment"

export const validateRequestTimes = (
  min_time = null,
  max_time = null,
  time_threshold
) => {
  if (min_time && max_time) {
    return (
      moment(min_time).isBefore(time_threshold) &&
      moment(min_time).isSameOrBefore(moment(max_time))
    )
  } else if (min_time && !max_time) {
    return moment(min_time).isBefore(time_threshold)
  } else if (!min_time && max_time) {
    return false
  }
  return true
}

export const filterAndSortQueryResults = (
  results = [],
  min_time = null,
  max_time = null
) => {
  let missed_clockouts = []

  try {
    missed_clockouts = [...results]
      .sort((a, b) => (moment(a.start).isAfter(moment(b.start)) ? 1 : -1))
      // filter with optional min and max time
      .filter(
        ({ start }) =>
          (!min_time || moment(min_time).isSameOrBefore(moment(start))) &&
          (!max_time || moment(max_time).isSameOrAfter(moment(start)))
      )
  } catch {}

  return missed_clockouts
}
