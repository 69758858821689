import { AlertButton } from "../../../Shared"
import VerifyUsers from "./VerifyUsers"
import RegistrationInProgress from "./RegistrationInProgress"

import {
  ETA_STATE_PROVIDER_APPROVED,
  ETA_STATE_OPT_IN_REQUESTED
} from "../../helpers"
import { useState } from "react"

const EtaAlert = ({ users_in_registration, onReviewCompleted }) => {
  const [showVerifyUsers, setShowVerifyUsers] = useState(false)
  const [showRegistrationInProgress, setShowRegistrationInProgress] = useState(
    false
  )
  const [review_completed, setReviewCompleted] = useState(false)

  const users_pending_verification =
    users_in_registration?.filter(
      ({ state }) =>
        state === ETA_STATE_PROVIDER_APPROVED ||
        state === ETA_STATE_OPT_IN_REQUESTED
    ) ?? []
  const users_registration_in_progress =
    users_in_registration?.filter(
      ({ state }) =>
        state !== ETA_STATE_PROVIDER_APPROVED &&
        state !== ETA_STATE_OPT_IN_REQUESTED
    ) ?? []

  const handleVerifyUsersClose = () => {
    // if review was completed, refetch
    if (review_completed) {
      onReviewCompleted()
    }
    // reset state variables
    setShowVerifyUsers(false)
    setReviewCompleted(false)
  }

  return (
    <>
      <AlertButton.Group>
        {!!users_pending_verification?.length && (
          <AlertButton
            basic
            positive
            icon="check circle"
            count={users_pending_verification.length}
            label="Completed Sign-Up"
            onClick={() => setShowVerifyUsers(true)}
          />
        )}
        {!!users_registration_in_progress?.length && (
          <AlertButton
            basic
            caution
            icon="address card"
            label="Sign-Up in Progress"
            count={users_registration_in_progress.length}
            onClick={() => setShowRegistrationInProgress(true)}
          />
        )}
      </AlertButton.Group>
      {showVerifyUsers && (
        <VerifyUsers
          users={users_pending_verification}
          onClose={handleVerifyUsersClose}
          onReviewCompleted={() => setReviewCompleted(true)}
        />
      )}
      {showRegistrationInProgress && (
        <RegistrationInProgress
          users={users_registration_in_progress}
          onClose={() => setShowRegistrationInProgress(false)}
        />
      )}
    </>
  )
}

export default EtaAlert
