import { useState } from "react"
import { useQuery } from "@apollo/client"
import moment from "moment"

import { useOrganization, useSelectedLocation, useBusinessDates } from "./"
import { ORG_STORES_RULES_PROCESSING } from "../../graphql/queries"

const POLL_FREQUENCY_MS = 5000
const STATUS_NO_PROCESSING = 0
const STATUS_PROCESSING = 1
const default_state = {
  status: STATUS_NO_PROCESSING,
  in_progress: []
}

export const useProcessingInProgress = ({ end_time, onRefresh = null }) => {
  const organization = useOrganization()
  const selected_location = useSelectedLocation()
  const { businessDate, businessEnd } = useBusinessDates()
  const [stores_processing, setStoresProcessing] = useState(default_state)
  const { status, in_progress } = stores_processing

  // processing if status === 1 and no location is selected or if the selection location is processing rules
  const processing =
    status === STATUS_PROCESSING &&
    (!selected_location?.id || in_progress.includes(selected_location?.id))

  const resetState = () => setStoresProcessing(default_state)

  const onCompleted = ({ orgStoresRulesProcessing = [] }) => {
    const store_ids_in_progress = filterStoreIdsByProcessingTime(
      orgStoresRulesProcessing,
      end_time,
      businessDate,
      businessEnd
    )
    if (store_ids_in_progress.length > 0) {
      // stores were processing and now they are not, refresh automatically
      if (
        selected_location?.id &&
        in_progress.includes(selected_location.id) &&
        !store_ids_in_progress.includes(selected_location.id)
      ) {
        // refresh and bail.
        resetState()
        if (!!onRefresh) {
          onRefresh()
        }
        return
      }
      // when stores are processing, set status appropriately
      setStoresProcessing({
        status: STATUS_PROCESSING,
        in_progress: [...store_ids_in_progress]
      })
    } else {
      if (in_progress.length > 0) {
        // stores were processing and now they are not, refresh automatically
        if (
          !selected_location?.id ||
          in_progress.includes(selected_location.id)
        ) {
          // refresh and bail.
          resetState()
          if (!!onRefresh) {
            onRefresh()
          }
          return
        }
      }

      setStoresProcessing({
        status: STATUS_NO_PROCESSING,
        in_progress: []
      })
    }
  }

  useQuery(ORG_STORES_RULES_PROCESSING, {
    pollInterval: POLL_FREQUENCY_MS,
    variables: {
      org_id: organization.id
    },
    onCompleted
  })

  return processing
}

const filterStoreIdsByProcessingTime = (
  stores,
  end_time,
  businessDate,
  businessEnd
) => {
  const today = businessDate()
  const business_end_date = businessEnd()
  // if provided end_date is after today, use today
  if (moment(end_time).isAfter(business_end_date)) {
    end_time = today
  }
  return stores
    .filter(
      store => store.lowwater && moment(store.lowwater).isBefore(end_time)
    )
    .map(({ id }) => id)
}
