import { useState } from "react"
import Styled from "styled-components"
import { Label } from "semantic-ui-react"

import Link from "../../../Link"
import Table from "../../../Table"
import Icon from "../../../Icon"

import { displayMoney } from "../../../../helpers/number"
import { colors } from "../../../../constants"
import {
  rowEmployeeName,
  rowEmployeeId,
  rowEtaAvailable,
  rowHausdirectPayoutIneligible,
  rowEtaPayoutMethod,
  PAYOUT_METHOD_HAUSMONEY,
  PAYOUT_METHOD_HAUSDIRECT,
  rowEtaNetAvailable,
  rowLastEtaPayoutOccurredToday,
  rowIsHausdirectPayout,
  rowEtaEmployeeFees
} from "../../helpers"
import { SummaryValue } from "./Styled"
import { useBusinessDates } from "../../../../hooks"

export default Styled(
  ({
    detail = [],
    onExcludeEmployee,
    onIncludeEmployee,
    excluded_employees = [],
    eta_min_employee_payout,
    className
  }) => {
    const [show_detail, setShowDetail] = useState(false)
    return (
      <Table.Row className={className} secondary hover={false}>
        <SummaryValue colSpan={2}>
          {!show_detail && (
            <Link onClick={() => setShowDetail(true)}>
              Show Employee Detail
            </Link>
          )}
          {!!show_detail && (
            <>
              <div style={{ display: "flex" }}>
                {excluded_employees.length > 0 && (
                  <span style={{ color: colors.danger }}>
                    {excluded_employees.length} Excluded Employee
                    {excluded_employees.length > 1 && "s"}
                  </span>
                )}
                <Link
                  onClick={() => setShowDetail(false)}
                  style={{ marginLeft: "auto" }}
                >
                  Hide Employee Detail
                </Link>
              </div>
              <EmployeeDetailWrapper>
                <Table fitted compact basic>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Service</Table.HeaderCell>
                      <Table.HeaderCell>Employee</Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">Fee</Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        Amount
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        Net Amount
                      </Table.HeaderCell>
                      <Table.HeaderCell collapsing />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {[...detail].map((row, idx) => (
                      <EmployeeDetailRow
                        key={idx}
                        row={row}
                        excluded_employees={excluded_employees}
                        eta_min_employee_payout={eta_min_employee_payout}
                        onExcludeEmployee={onExcludeEmployee}
                        onIncludeEmployee={onIncludeEmployee}
                      />
                    ))}
                  </Table.Body>
                </Table>
              </EmployeeDetailWrapper>
            </>
          )}
        </SummaryValue>
      </Table.Row>
    )
  }
)`
  & div.employee-detail-wrapper {
    position: relative;
    margin: 0.33rem 0;

    & > div {
      max-height: 15rem;
      overflow-y: auto;

      & .table {
        background-color: ${colors.white};
      }
    }
  }
`

const EmployeeDetailWrapper = ({ children }) => (
  <div className="employee-detail-wrapper">
    <div>{children}</div>
  </div>
)

const EmployeeDetailRow = ({
  row,
  excluded_employees,
  eta_min_employee_payout,
  onExcludeEmployee,
  onIncludeEmployee
}) => {
  const employee_id = rowEmployeeId(row)
  const excluded = excluded_employees.includes(employee_id)
  const total_eta_available = rowEtaAvailable(row)
  const eta_net_available = rowEtaNetAvailable(row)
  const is_hausdirect_payout = rowIsHausdirectPayout(row)
  const hausdirectPayoutIneligible = rowHausdirectPayoutIneligible(
    row,
    eta_min_employee_payout
  )
  const payout_method = rowEtaPayoutMethod(row)
  const { businessStart, businessEnd } = useBusinessDates()
  const paid_today = rowLastEtaPayoutOccurredToday(
    row,
    businessStart(),
    businessEnd()
  )
  const employee_fee = rowEtaEmployeeFees(row)

  return (
    <Table.Row hover={false}>
      <Table.Cell>
        <ServiceLabel
          payout_method={payout_method}
          content={payout_method}
          disabled={excluded}
        />
      </Table.Cell>
      <Table.Cell>
        {!excluded && rowEmployeeName(row)}
        {!!excluded && (
          <span
            style={{
              color: hausdirectPayoutIneligible ? "#999" : "inherit"
            }}
          >
            <strike>{rowEmployeeName(row)}</strike>
          </span>
        )}
        {paid_today && is_hausdirect_payout && (
          <span style={{ color: "#999", paddingLeft: "0.5rem" }}>
            (paid today)
          </span>
        )}
        {!!excluded && hausdirectPayoutIneligible && (
          <span style={{ marginLeft: "0.5rem" }}>
            <Icon
              fitted
              name="info circle"
              color={colors.light6}
              popup={{
                delay: 0,
                eventsEnabled: false,
                position: "top right",
                content:
                  "This employee does not have enough take home with added fees"
              }}
            />
          </span>
        )}
      </Table.Cell>
      <Table.Cell textAlign="right">
        {!excluded && displayMoney(employee_fee)}
        {!!excluded && (
          <span
            style={{
              color: hausdirectPayoutIneligible ? "#999" : "inherit"
            }}
          >
            <strike>{displayMoney(employee_fee)}</strike>
          </span>
        )}
      </Table.Cell>
      <Table.Cell textAlign="right">
        {!excluded && displayMoney(total_eta_available)}
        {!!excluded && (
          <span
            style={{
              color: hausdirectPayoutIneligible ? "#999" : "inherit"
            }}
          >
            <strike>{displayMoney(total_eta_available)}</strike>
          </span>
        )}
      </Table.Cell>
      <Table.Cell textAlign="right">
        {!excluded && displayMoney(eta_net_available)}
        {!!excluded && (
          <span
            style={{
              color: hausdirectPayoutIneligible ? "#999" : "inherit"
            }}
          >
            <strike>{displayMoney(eta_net_available)}</strike>
          </span>
        )}
      </Table.Cell>
      <Table.Cell collapsing>
        {!hausdirectPayoutIneligible && (
          <Icon
            fitted
            cursor="pointer"
            name="minus circle"
            color={!excluded ? colors.light5 : colors.danger}
            hover_color={colors.danger}
            popup={
              !excluded
                ? {
                    delay: 1000,
                    eventsEnabled: false,
                    position: "top right",
                    content: "Exclude employee from payout"
                  }
                : null
            }
            onClick={() =>
              !excluded
                ? onExcludeEmployee(employee_id)
                : onIncludeEmployee(employee_id)
            }
          />
        )}
      </Table.Cell>
    </Table.Row>
  )
}

const ServiceLabel = Styled(props => <Label {...props} />)`
  &.ui.label {
    background-color: ${({ payout_method }) =>
      payout_method === PAYOUT_METHOD_HAUSMONEY
        ? colors.success
        : payout_method === PAYOUT_METHOD_HAUSDIRECT
        ? colors.green
        : colors.light4};
    color: ${colors.white};
    font-weight: 500;
    padding: 0.3rem 0.6rem;
    margin-left: -0.5rem;
    ${props =>
      props.disabled &&
      `
      opacity: 0.5;
    `};
  }
`
