import Button from "./"
import Icon from "../../Icon"

const SubmitButton = ({
  icon = "check",
  label = "Submit",
  secondary = false,
  ...props
}) => (
  <Button
    icon
    labelPosition="right"
    primary={!secondary ? true : undefined}
    secondary={!!secondary ? true : undefined}
    {...props}
  >
    {label} <Icon name={icon} />
  </Button>
)

export default SubmitButton
