import React from "react"

import Styled from "styled-components"
import { Form, Table } from "semantic-ui-react"

import IconLogo from "../../Logo/IconLogoSvg"
import Button, { IconButton } from "../../Button"
import ExclamationIcon from "../../Icon/Exclamation"

import { colors } from "../../../constants"

export const Logo = Styled(props => (
  <div {...props}>
    <IconLogo primary={colors.success} secondary={colors.dark3} size="medium" />
  </div>
))`
  position: absolute;
  right: 1rem;
  top: 0.8rem;
`

export const Paragraph = Styled.p`
  ${({ force_margin }) => !!force_margin && "margin-top: 1em;"}
  ${({ thin = false }) => (!!thin ? `font-weight: 300;` : "")}
`

export const Thin = Styled.span`
  font-weight: 300;
`

export const PercentInput = Styled(Form.Input)`
  width: 8rem;
`

export const EmailList = Styled(({ className, ...props }) => (
  <Table compact className={className}>
    <Table.Body {...props} />
  </Table>
))``

export const EmailItem = Styled(({ email, onRemove, ...props }) => (
  <Table.Row {...props}>
    <Table.Cell>{email}</Table.Cell>
    <Table.Cell textAlign="right">
      <Button
        popup={{ content: "Remove Invite", delay: true, position: "top right" }}
        basic
        fitted
        compact
        negative
        circular
        size="mini"
        icon="trash alternate"
        onClick={onRemove}
      />
    </Table.Cell>
  </Table.Row>
))`
  line-height: 2rem;
`

export const InvalidInput = Styled(props => (
  <div {...props}>
    <ExclamationIcon />
    &nbsp;Resolve Invalid Input to Continue.
  </div>
))`
  display: inline-block;
  color: ${colors.danger};
  float: left;
  line-height: 1em;
  padding: .8em 0;
`

export const NavButton = Styled(IconButton)`
  margin-left: 0 !important;
`

export const NavBack = props => (
  <NavButton labelPosition="left" icon="backward" {...props}>
    Back
  </NavButton>
)

export const NavNext = props => (
  <NavButton primary icon="forward" {...props}>
    Next
  </NavButton>
)

export const NavSubmit = props => (
  <NavButton primary icon="thumbs up" {...props}>
    Let's do this!
  </NavButton>
)
