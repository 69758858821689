import RouteContent from "../RouteContent"
import UserCard from "./UserCard"
import { Popup, Button } from "../Shared"

export default ({ toggleNightMode, night_mode, ...props }) => (
  <RouteContent
    header="Profile"
    controls={
      <>
        <Popup
          content={!!night_mode ? "Disable Night Mode" : "Night mode. Why not?"}
          position="left center"
          delay
        >
          <Button
            content={!night_mode ? "Night Mode" : "Day Mode"}
            secondary
            circular
            icon={!night_mode ? "moon" : "sun"}
            size="small"
            onClick={toggleNightMode}
          />
        </Popup>
      </>
    }
  >
    <div>
      <UserCard {...props} />
    </div>
  </RouteContent>
)
