import { forwardRef } from "react"
import Styled from "styled-components"
import { Icon } from "semantic-ui-react"
import StyledIcon from "./Icon"

import { colors } from "../../../constants"

const DollarIcon = Styled(({ ...props }) => <StyledIcon {...props} />)`
  font-size: 0.6em !important;
  right: 0 !important;
  bottom: -0.15em !important;
`

export default forwardRef(({ color = colors.info, ...props }, ref) => (
  <Icon.Group ref={ref}>
    <StyledIcon name="exchange" color={colors.green} />
    <DollarIcon corner name="dollar sign" color={colors.success} />
  </Icon.Group>
))
