import { useState } from "react"
import { Image } from "semantic-ui-react"

import Modal from "./"
import Tabs from "../Tabs"

const DAILY = "daily"
const HOURLY = "hourly"
const TIME_OF_SALE = "time-of-sale"

const imageUrl = name =>
  `https://tiphaus-public.s3.amazonaws.com/distributions/${name}.png`

export default ({ onClose }) => {
  const [selected_tab, setSelectedTab] = useState(DAILY)

  return (
    <Modal onClose={onClose}>
      <Modal.Header onClose={onClose}>Distribution Frequency</Modal.Header>
      <Modal.Content fitted>
        <br />
        <Tabs
          tabs={[
            {
              id: DAILY,
              name: "Daily",
              selected: selected_tab === DAILY
            },
            {
              id: HOURLY,
              name: "Hourly",
              selected: selected_tab === HOURLY
            },
            {
              id: TIME_OF_SALE,
              name: "Time of Sale",
              selected: selected_tab === TIME_OF_SALE
            }
          ]}
          onChange={tab => setSelectedTab(tab.id)}
        />

        {selected_tab == DAILY && <Image centered src={imageUrl(DAILY)} />}
        {selected_tab == HOURLY && <Image centered src={imageUrl(HOURLY)} />}
        {selected_tab == TIME_OF_SALE && (
          <Image centered src={imageUrl(TIME_OF_SALE)} />
        )}
      </Modal.Content>
    </Modal>
  )
}
