import { Component } from "react"
import { useIntercom } from "react-use-intercom"

import Tour from "../Tour"
import Popup from "../Popup"
import Button from "../Button"
import { MenuModal } from "../Shared"
import {
  Header,
  RouteWrapper,
  HeaderWrapper,
  ContentWrapper,
  ControlsWrapper
} from "./Styled"

import {
  intercomEnabled,
  hasVisitedRoute,
  markRouteVisited
} from "../../helpers"
import { useUser } from "../../hooks"
import { project_info } from "../../constants"

const HelpButton = ({ onClick }) => (
  <Popup
    content="Show help options for this page."
    position="left center"
    delay
  >
    <Button
      id="help-button"
      size="small"
      content="Help"
      circular
      icon="question"
      onClick={onClick}
    />
  </Popup>
)

class RouteContent extends Component {
  state = {
    show_tour: false,
    first_visit: false,
    requested_help: false
  }

  componentDidMount() {
    // if user and route_name are set check user settings to see
    // if this is the users first visit to this page.
    const {
      route_name = null,
      user = null,
      tour = null,
      setRoutesVisited = null
    } = this.props
    let routes_visited

    if (
      tour &&
      route_name &&
      user &&
      setRoutesVisited &&
      !hasVisitedRoute(route_name, user)
    ) {
      // apollo_client.mutate for user setting and update redux state
      this.setState({ show_tour: true })
      // mark this route as visited
      routes_visited = markRouteVisited(route_name, user)
      setRoutesVisited(routes_visited)
    }
  }

  getHelpOptions = () => {
    const { tour, user, showIntercom } = this.props
    let options = []

    // optional tour
    if (tour) {
      options.push({
        name: "Take the Tour",
        icon: "map signs",
        description:
          "The tour will guide you step-by-step through the features on this page.",
        onSelect: () => this.setState({ show_tour: true })
      })
    }

    // learning center
    options.push({
      name: "Learning Center",
      icon: "university",
      description:
        "Educational documents and resources to get the most out of TipHaus.",
      onSelect: () => window.open(project_info.learning_site_url, "_blank")
    })

    // contact support
    if (!!showIntercom && intercomEnabled(user)) {
      options.push({
        name: "Contact Support",
        icon: "talk",
        description:
          "Drop a note for our support team.  We're here for you when you need us.",
        onSelect: () => showIntercom()
      })
    }

    return options
  }

  render() {
    const {
      header = "",
      controls = null,
      user = null,
      tour = null,
      children,
      showIntercom,
      ...props
    } = this.props

    const { requested_help, show_tour } = this.state

    const help_options = this.getHelpOptions()

    let route_classname = "route"
    try {
      route_classname = `${header.toLowerCase().replace(/\s/g, "-")}-route`
    } catch (e) {}

    return (
      <RouteWrapper {...props} className={route_classname}>
        {!!header && (
          <HeaderWrapper>
            <Header id={`${route_classname}-header`} className="route-header">
              {header}
            </Header>
            {(!!controls || help_options.length > 0) && (
              <ControlsWrapper>
                {!!controls && controls}
                {help_options.length > 0 && (
                  <HelpButton
                    onClick={() => this.setState({ requested_help: true })}
                  />
                )}
              </ControlsWrapper>
            )}
          </HeaderWrapper>
        )}
        <ContentWrapper id={`${route_classname}-content`}>
          {children}
        </ContentWrapper>
        {!!requested_help && (
          <MenuModal
            onClose={() => this.setState({ requested_help: false })}
            options={[...help_options]}
          />
        )}
        {!!show_tour && !!tour && (
          <Tour
            {...tour}
            onClose={() => {
              this.setState({ show_tour: false })
              tour?.onClose && tour.onClose()
            }}
          />
        )}
      </RouteWrapper>
    )
  }
}

// export default RouteContent wrapper
export default props => {
  const user = useUser()
  const { show } = useIntercom()

  return <RouteContent showIntercom={show} user={user} {...props} />
}

export { ResyncControl } from "./Controls"
