import { useEffect, useState } from "react"
import { Form } from "semantic-ui-react"
import {
  rules_view,
  rules_manage
} from "../../../../../../../constants/permissions"
import useAuthorization from "../../../../../../../hooks/authorization"
import { PermissionsTable, ToggleField } from "../Styled"

export const rulesStep = ({ permissions, edit_mode }, onChange) => {
  const { [rules_view]: can_view, [rules_manage]: can_manage } = permissions
  const [visited, setVisited] = useState(false)
  return {
    name: "Tip Distribution Logic",
    complete: !edit_mode || visited,
    render: () => (
      <Rules
        can_view={can_view}
        can_manage={can_manage}
        edit_mode={edit_mode}
        onChange={new_state =>
          onChange({
            permissions: {
              ...permissions,
              ...new_state
            }
          })
        }
        onMount={() => setVisited(true)}
      />
    )
  }
}

const Rules = ({ can_view, can_manage, edit_mode, onChange, onMount }) => {
  const { hasPermission } = useAuthorization()

  useEffect(() => onMount(), [])
  return (
    <>
      <Form>
        <ToggleField
          checked={can_view}
          disabled={!edit_mode || !hasPermission(rules_view)}
          label="Tip Distribution Logic Tab Visible"
          onChange={checked => {
            let new_state = { [rules_view]: checked }
            // auto-uncheck the manage permission when de-selecting view
            if (!checked) {
              new_state = {
                ...new_state,
                [rules_manage]: checked
              }
            }
            onChange(new_state)
          }}
        />
        <ToggleField
          checked={can_manage}
          disabled={!edit_mode || !hasPermission(rules_manage)}
          label="Manage Tip Distribution Logic"
          onChange={checked => {
            let new_state = {
              [rules_manage]: checked
            }
            // auto-check the view permission when selecting manage
            if (checked) {
              new_state = {
                ...new_state,
                [rules_view]: checked
              }
            }
            onChange(new_state)
          }}
        />
      </Form>
      <PermissionsTable
        permissions={[
          {
            display_name: "Create, Edit, Delete Distribution Logic",
            selected: can_manage
          }
        ]}
      />
    </>
  )
}
