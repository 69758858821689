import Styled from "styled-components"
import { colors } from "../../../constants"
import { displayMoney } from "../../../helpers"
import { InfoIcon, Popup } from "../../Shared"

export default Styled(({ columns = [], className }) => (
  <div className={className}>
    {columns.map(({ label, description, value, type }) => (
      <SummaryItem
        key={label}
        label={label}
        description={description}
        value={value}
        type={type}
      />
    ))}
  </div>
))`
  display: grid;
  grid-template-columns: repeat(${({ columns }) =>
    columns.length}, minmax(150px, auto));
  grid-column-gap: 0.33rem;
  grid-row-gap: 0.67rem;
  margin-bottom: 1.67rem;
`

const SummaryItem = Styled(({ label, description, value, type, className }) => (
  <div className={className}>
    <div>
      {!description && <div className="label">{label}</div>}
      {!!description && (
        <Popup content={description}>
          <span className="label">
            {label}&nbsp;
            <InfoIcon size="small" fitted />
          </span>
        </Popup>
      )}
      <div>{type === "number" ? displayMoney(value) : value}</div>
    </div>
  </div>
))`
  border-right: 0.5px solid ${colors.table_border};
  &:last-child {
    border-right: none;
  }
  & > div {
    font-size: 1.1rem;
    padding: 0 1.33rem;

    & .label {
      font-weight: bold;
      margin-bottom: 0.25rem;      
    }
  }
`
