import { IconButton } from "../../../../../../Button"
import { useUserModals } from "../../../context/userModals"

const ExportButton = () => {
  const { showModal } = useUserModals()
  return (
    <IconButton
      secondary
      icon="cloud download"
      onClick={() => showModal("export_users")}
    >
      Export
    </IconButton>
  )
}

export default ExportButton
