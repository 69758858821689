import moment from "moment"

import { SendInviteLink } from "../../../Table/Actions/SendInvites"
import { InfoIcon, WarningIcon, ExclamationIcon } from "../../../../../Icon"
import { Table } from "../../../../../Shared"
import {
  ETA_STATE_INVITED,
  ETA_STATE_CLIENT_FLAGGED,
  ETA_STATE_PROVIDER_FLAGGED
} from "../../../../helpers"
import { colors } from "../../../../../../constants"

const RegistrationStatusCell = ({
  user,
  state,
  invite_expires,
  onInviteSent
}) => {
  return (
    <Table.Cell colSpan={100}>
      {state === ETA_STATE_INVITED && (
        <>
          <InfoIcon color={colors.success} name="send" />{" "}
          <span>
            Invite sent
            {!!invite_expires && (
              <>
                , {moment.utc(invite_expires).isAfter() ? "expires" : "expired"}{" "}
                {moment.utc(invite_expires).fromNow()}
              </>
            )}
            .{" "}
            <SendInviteLink
              content="Resend Invite"
              user_id={user.id}
              onInvitesSent={() => onInviteSent(user)}
            />
          </span>
        </>
      )}
      {state === ETA_STATE_PROVIDER_FLAGGED && (
        <>
          <WarningIcon name="flag" />{" "}
          <span>Registration submitted, under manual verification.</span>
        </>
      )}
      {state === ETA_STATE_CLIENT_FLAGGED && (
        <>
          <ExclamationIcon name="flag" />{" "}
          <span>Flagged by user, pending internal review.</span>
        </>
      )}
    </Table.Cell>
  )
}

export default RegistrationStatusCell
