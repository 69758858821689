import { useState } from "react"
import { useCashTipDistributionEnabled } from "../../../../../../hooks"

import { Field } from "../../../../Styled"
import DistributionSourcesInput from "./DistributionSourcesInput"
import DistributionSourceTypeInput from "./DistributionSourceTypeInput"
import DistributionMethodsModal from "../../../../../Modal/DistributionMethods"
import DistributionFrequencyInput, {
  DistributionFrequencyDescription
} from "./DistributionFrequencyInput"

import {
  DISTRO_SOURCE_TYPE_TIPS,
  TIP_DISTRO_SOURCES_ALL
} from "../../../../helpers"

// step entrypoint
export const sourcesStep = (
  {
    revenue_centers,
    distribution_sources,
    distribution_source_type,
    distribution_method
  },
  onUpdate
) => ({
  name: "Tip Sources",
  complete: distribution_sources.length > 0 && distribution_method.length > 0,
  render: () => (
    <Sources
      revenue_centers={revenue_centers}
      distribution_sources={distribution_sources}
      distribution_source_type={distribution_source_type}
      distribution_method={distribution_method}
      onUpdate={onUpdate}
    />
  )
})

const Sources = ({
  revenue_centers,
  distribution_sources,
  distribution_source_type,
  distribution_method,
  onUpdate
}) => {
  const cash_tips_enabled = useCashTipDistributionEnabled()
  const [distribution_methods_modal, setDistributionMethodsModal] = useState(
    false
  )
  return (
    <>
      {!!cash_tips_enabled && (
        <Field fluid name="Tip Source">
          <DistributionSourceTypeInput
            revenue_centers={revenue_centers}
            distribution_source_type={distribution_source_type}
            distribution_method={distribution_method}
            onUpdate={onUpdate}
          />
        </Field>
      )}
      {distribution_source_type === DISTRO_SOURCE_TYPE_TIPS && (
        <Field fluid name="Apply To">
          <DistributionSourcesInput
            selected={distribution_sources}
            options={TIP_DISTRO_SOURCES_ALL.map(name => ({
              text: name,
              value: name
            }))}
            onUpdate={selected =>
              onUpdate({ distribution_sources: [...selected] })
            }
          />
        </Field>
      )}
      <Field
        fluid
        name="Distribution Frequency"
        description={
          <DistributionFrequencyDescription
            handleLinkClick={() => setDistributionMethodsModal(true)}
          />
        }
      >
        <DistributionFrequencyInput
          distribution_method={distribution_method}
          distribution_source_type={distribution_source_type}
          onUpdate={onUpdate}
        />
      </Field>
      {!!distribution_methods_modal && (
        <DistributionMethodsModal
          onClose={() => setDistributionMethodsModal(false)}
        />
      )}
    </>
  )
}
