import Styled from "styled-components"

import SelectedLabel from "./SelectedLabel"
import StatusLabel, {
  ActiveLabel,
  InactiveLabel,
  FutureDatedLabel
} from "./StatusLabel"

export {
  SelectedLabel,
  StatusLabel,
  ActiveLabel,
  InactiveLabel,
  FutureDatedLabel
}

export default Styled.div`
  display: ${({ inline }) => (!!inline ? "inline-block" : "block")};
  margin: 0 0 0.28571429rem 0;
  color: rgba(0,0,0,.87);
  font-size: .92857143em;
  font-weight: 700;
  text-transform: none;
`
