const pay_period_intervals = [
  {
    key: "weekly",
    name: "Weekly",
    weekly_distribution_compatible: true
  },
  {
    key: "bi-weekly",
    name: "Bi-Weekly",
    weekly_distribution_compatible: true
  },
  {
    key: "semi-monthly",
    name: "Semi-Monthly",
    weekly_distribution_compatible: false
  }
]

export { pay_period_intervals }
