import Styled from "styled-components"

import Icon from "../Icon"
import Table from "../Table"
import { IconButton } from "../Button"

import { colors } from "../../constants"

export const GeneralSettings = Styled.div`
  position: relative;
`

export const SettingsTable = Styled(({ children, ...props }) => (
  <Table {...props} fitted>
    <Table.Body>{children}</Table.Body>
  </Table>
))`
  overflow: hidden;
`

export const SettingRow = Styled(({ name, value, onRequestEdit, ...props }) => (
  <Table.Row {...props} hover={false}>
    <SettingNameCell name={name} />
    <Table.Cell>{value}</Table.Cell>
    <Table.Cell textAlign="right">
      {onRequestEdit !== false && (
        <IconButton
          icon="edit"
          compact
          size="tiny"
          secondary
          onClick={onRequestEdit}
        >
          Edit
        </IconButton>
      )}
    </Table.Cell>
  </Table.Row>
))``

export const SettingNameCell = Styled(({ name, ...props }) => (
  <Table.Cell {...props}>{name}</Table.Cell>
))`
  &&&& {
    background-color: ${colors.light} !important;
    border-right: 1px solid ${colors.table_border};
    font-weight: bold;
    line-height: 2rem;
  }
`
