import moment from "moment"

import {
  date_format,
  month_day_format,
  month_day_short_format
} from "../../constants"
import { round } from "../../helpers/number"
import PAYOUT_STATUS from "../../constants/payout-status"

// check if ETA is enabled.
export const etaEnabled = user => {
  const { organization: org } = user
  return !!(
    org.eta_config?.enabled &&
    org.eta_config?.verified_at &&
    org.current_pay_period
  )
}

export const DEFUALT_ETA_FILTERS = {
  page: 1,
  per_page: 50
}

// filter namespace
const FILTER_NAMESPACE = "earned-tip-access"

// tab definitions
export const TAB_OVERVIEW = "overview"
export const TAB_PAYOUTS = "payouts"
export const ETA_TABS = [
  {
    path: TAB_OVERVIEW,
    name: "Overview"
  },
  {
    path: TAB_PAYOUTS,
    name: "Payouts"
  }
]

// ETA Introduction constants
export const INTRO_PATH = "introduction"
export const INTRO_REQUESTED_FOLLOWUP = "followup"
export const INTRO_DECLINED_FOLLOWUP = "no-followup"

// ETA Overview Helpers
export const OVERVIEW_FILTER_NAMESPACE = `${FILTER_NAMESPACE}.overview`
export const PAYOUTS_FILTER_NAMESPACE = `${FILTER_NAMESPACE}.payouts`

// report constants
export const ETA_REPORT_NAME = "earned_tip_access"
export const ETA_REPORT_GROUP_BY = "employee"

export const REPORT_COLUMN_ETA_SENT = "total_eta_sent"
export const REPORT_COLUMN_ETA_PAYABLE = "total_eta_payable"
export const REPORT_COLUMN_ETA_ELIGIBLE = "total_eta_eligible"
export const REPORT_COLUMN_ETA_RESERVED = "total_eta_withheld"
export const REPORT_COLUMN_ETA_AVAILABLE = "total_eta_available"
export const REPORT_COLUMN_ETA_NET_AVAILABLE = "net_eta_available"
export const REPORT_COLUMN_ETA_EMPLOYEE_FEE = "eta_employee_fee"

export const REPORT_COLUMN_LAST_NAME = "employee.last_name"
export const REPORT_COLUMN_FIRST_NAME = "employee.first_name"
export const REPORT_COLUMN_ETA_STATE = "employee.eta_state"
export const REPORT_COLUMN_ETA_OPTED_OUT = "employee.user.eta_opted_out"
export const REPORT_COLUMN_ETA_EFFECTIVE = "employee.user.eta_effective_date"
export const REPORT_COLUMN_ETA_PAYOUT_METHOD = "employee.user.eta_payout_method"
export const REPORT_COLUMN_ETA_DEACTIVATED =
  "employee.user.eta_deactivated_date"
export const REPORT_COLUMN_ETA_OPTED_OUT_AT = "employee.user.eta_opted_out_at"

export const ETA_STATE_ACTIVE = "ACTIVE"
export const ETA_STATE_INVITED = "INVITED"
export const ETA_STATE_OPTED_OUT = "OPTED_OUT"
export const ETA_STATE_OPT_IN_REQUESTED = "OPT_IN_REQUESTED"
export const ETA_STATE_DEACTIVATED = "DEACTIVATED"
export const ETA_STATE_NOT_ENROLLED = "NOT_ENROLLED"
export const ETA_STATE_FUTURE_DATED = "FUTURE_DATED"
export const ETA_STATE_CLIENT_FLAGGED = "CLIENT_FLAGGED"
export const ETA_STATE_PROVIDER_FLAGGED = "PROVIDER_FLAGGED"
export const ETA_STATE_PROVIDER_APPROVED = "PROVIDER_APPROVED"
export const ETA_STATES_SIGN_UP_IN_PROGRESS = [
  ETA_STATE_INVITED,
  ETA_STATE_CLIENT_FLAGGED,
  ETA_STATE_PROVIDER_FLAGGED
]
// table columns
export const TABLE_COLUMN_STATUS = "status"
export const TABLE_COLUMN_LOCATION = "location"
export const TABLE_COLUMN_AMT_SENT = REPORT_COLUMN_ETA_SENT
export const TABLE_COLUMN_LAST_NAME = REPORT_COLUMN_LAST_NAME
export const TABLE_COLUMN_FIRST_NAME = REPORT_COLUMN_FIRST_NAME
export const TABLE_COLUMN_AMT_PAYABLE = REPORT_COLUMN_ETA_PAYABLE
export const TABLE_COLUMN_AMT_ELIGIBLE = REPORT_COLUMN_ETA_ELIGIBLE
export const TABLE_COLUMN_AMT_RESERVED = REPORT_COLUMN_ETA_RESERVED
export const TABLE_COLUMN_AMT_AVAILABLE = REPORT_COLUMN_ETA_AVAILABLE

// column sorting
export const SORT_DIRECTION_ASC = "ascending"
export const SORT_DIRECTION_DESC = "descending"

export const FILTER_LAST_PAY_PERIOD = "last_pay_period"
export const FILTER_CURRENT_PAY_PERIOD = "current_pay_period"
export const FILTER_PAY_PERIOD_BEFORE_LAST = "pay_period_before_last"
const PAY_PERIOD_FILTERS = [
  { text: "Current Pay Period", value: FILTER_CURRENT_PAY_PERIOD },
  { text: "Last Pay Period", value: FILTER_LAST_PAY_PERIOD },
  { text: "Pay Period Before Last", value: FILTER_PAY_PERIOD_BEFORE_LAST }
]
export const payPeriodFilters = (pay_periods, today) =>
  [...PAY_PERIOD_FILTERS]
    // make sure pay period is populated
    .filter(({ value }) => !!pay_periods[value])
    // make sure pay period start is at least a day ago
    // (no breakdown for current biz day)
    .filter(({ value }) =>
      moment(today).subtract(1, "day").isSameOrAfter(pay_periods[value].start)
    )
    .map(filter => ({
      ...filter,
      description: filter.text,
      text: `${moment(pay_periods[filter.value].start).format(
        "MMMM D"
      )} to ${moment(pay_periods[filter.value].end).format("MMMM D")}`
    }))

export const payPeriodDateSpan = (pay_period, format = month_day_format) =>
  `${moment(pay_period.start).format(format)} - ${moment(pay_period.end).format(
    format
  )}`

export const payPeriodLabel = filter_value =>
  PAY_PERIOD_FILTERS.find(({ value }) => value === filter_value)?.text ?? null

const FILTER_EMPLOYEE_TYPE_ALL = 1
const FILTER_EMPLOYEE_TYPE_ENABLED = 2
const FILTER_EMPLOYEE_TYPE_SIGN_UP = 3
const FILTER_EMPLOYEE_TYPE_NOT_ENABLED = 4
const DEFAULT_FILTER_EMPLOYEE_TYPE = FILTER_EMPLOYEE_TYPE_ALL
export const employeeTypeFilters = () => [
  { text: "All Employees", value: FILTER_EMPLOYEE_TYPE_ALL },
  { text: "Participating", value: FILTER_EMPLOYEE_TYPE_ENABLED },
  { text: "In Sign-Up Process", value: FILTER_EMPLOYEE_TYPE_SIGN_UP },
  { text: "Not Participating", value: FILTER_EMPLOYEE_TYPE_NOT_ENABLED }
]

// overview filters with defaults populated
export const overviewFiltersWithDefaults = (
  app_filters,
  pay_periods,
  today_date
) => ({
  // inject defaults
  ...DEFUALT_ETA_FILTERS,
  // default pay period should be current pay period unless
  // today_date is the first day of a new period (no breakdown data).
  selected_pay_period: moment(today_date)
    .subtract(1, "day")
    .isSameOrAfter(pay_periods[FILTER_CURRENT_PAY_PERIOD]?.start)
    ? FILTER_CURRENT_PAY_PERIOD
    : FILTER_LAST_PAY_PERIOD,
  selected_employee_type: DEFAULT_FILTER_EMPLOYEE_TYPE,
  search_last_name: "",
  search_first_name: "",
  sort_field: TABLE_COLUMN_AMT_AVAILABLE,
  sort_direction: SORT_DIRECTION_DESC,
  ...(app_filters[OVERVIEW_FILTER_NAMESPACE] ?? {})
})

// apply user filters to fetchReport result
export const filterAndSortOverviewRows = (
  rows,
  {
    selected_employee_type = DEFAULT_FILTER_EMPLOYEE_TYPE,
    search_first_name = "",
    search_last_name = "",
    search_location = "",
    sort_field,
    sort_direction
  }
) =>
  [...rows]
    // selected_employee_type filter
    .filter(
      row =>
        selected_employee_type === FILTER_EMPLOYEE_TYPE_ALL ||
        (selected_employee_type === FILTER_EMPLOYEE_TYPE_ENABLED &&
          statusIsPositive(row)) ||
        (selected_employee_type === FILTER_EMPLOYEE_TYPE_SIGN_UP &&
          statusIsCaution(row)) ||
        (selected_employee_type === FILTER_EMPLOYEE_TYPE_NOT_ENABLED &&
          statusIsNegative(row))
    )
    // first name search
    .filter(
      row =>
        search_first_name.trim().length === 0 ||
        (row[REPORT_COLUMN_FIRST_NAME] ?? "")
          .trim()
          .toLowerCase()
          .indexOf(search_first_name.toLowerCase().trim()) > -1
    )
    .filter(
      row =>
        search_location.trim() === "" ||
        row?.__meta?.store_name
          .toLowerCase()
          .trim()
          .includes(search_location.toLowerCase().trim())
    )
    // last name search
    .filter(
      row =>
        search_last_name.trim().length === 0 ||
        (row[REPORT_COLUMN_LAST_NAME] ?? "")
          .trim()
          .toLowerCase()
          .indexOf(search_last_name.toLowerCase().trim()) > -1
    )
    // apply sort field and direction
    .sort((a, b) => {
      const sort_multiplier = sort_direction === SORT_DIRECTION_ASC ? 1 : -1
      switch (sort_field) {
        case TABLE_COLUMN_STATUS:
          return (
            sort_multiplier * (sortStatusRow(a) > sortStatusRow(b) ? 1 : -1)
          )
        case TABLE_COLUMN_LAST_NAME:
        case TABLE_COLUMN_FIRST_NAME:
          return sort_multiplier * a[sort_field].localeCompare(b[sort_field])
        case TABLE_COLUMN_LOCATION:
          return (
            sort_multiplier *
            rowLocationName(a).localeCompare(rowLocationName(b))
          )
        case TABLE_COLUMN_AMT_SENT:
        case TABLE_COLUMN_AMT_PAYABLE:
        case TABLE_COLUMN_AMT_ELIGIBLE:
        case TABLE_COLUMN_AMT_RESERVED:
        case TABLE_COLUMN_AMT_AVAILABLE:
          return sort_multiplier * etaSortNumeric(a, b, sort_field)
        default:
          return 1
      }
    })

const etaSortNumeric = (a, b, sort_field = "") => {
  const a_sort = a[sort_field] ?? 0
  const b_sort = b[sort_field] ?? 0

  return a_sort > b_sort ? 1 : -1
}

// eta constants
export const PAYOUT_METHOD_PENDING = "pending"
export const PAYOUT_METHOD_HAUSMONEY = "hausmoney"
export const PAYOUT_METHOD_HAUSDIRECT = "hausdirect"

// aggregation helpers
export const totalEtaSent = rows =>
  round(
    rows.reduce((acc, row) => (row[REPORT_COLUMN_ETA_SENT] ?? 0) + acc, 0),
    2
  )
export const totalEtaAvailable = rows =>
  round(
    rows
      .filter(row => row[REPORT_COLUMN_ETA_AVAILABLE] > 0)
      .reduce((acc, row) => (row[REPORT_COLUMN_ETA_AVAILABLE] ?? 0) + acc, 0),
    2
  )
export const totalEtaNetAvailable = rows =>
  round(
    rows
      .filter(row => row[REPORT_COLUMN_ETA_NET_AVAILABLE] > 0)
      .reduce(
        (acc, row) => (row[REPORT_COLUMN_ETA_NET_AVAILABLE] ?? 0) + acc,
        0
      ),
    2
  )
export const totalEtaEmployeeFees = rows =>
  round(
    rows
      .filter(row => row[REPORT_COLUMN_ETA_EMPLOYEE_FEE] > 0)
      .reduce(
        (acc, row) => (row[REPORT_COLUMN_ETA_EMPLOYEE_FEE] ?? 0) + acc,
        0
      ),
    2
  )
export const totalTakeHomeEligible = rows =>
  round(
    rows.reduce((acc, row) => (row[REPORT_COLUMN_ETA_ELIGIBLE] ?? 0) + acc, 0),
    2
  )
export const totalTakeHomeReserved = rows =>
  round(
    rows.reduce((acc, row) => (row[REPORT_COLUMN_ETA_RESERVED] ?? 0) + acc, 0),
    2
  )
export const totalTakeHomePayable = rows =>
  round(
    rows.reduce((acc, row) => (row[REPORT_COLUMN_ETA_PAYABLE] ?? 0) + acc, 0),
    2
  )
export const rowLocationName = row => row?.__meta?.store_name ?? ""
export const rowEtaAvailable = row => row[REPORT_COLUMN_ETA_AVAILABLE] ?? 0
export const rowEtaNetAvailable = row =>
  row[REPORT_COLUMN_ETA_NET_AVAILABLE] ?? 0
export const rowEmployeeId = row => row?.__meta?.employee_id ?? null
export const rowEmployeeName = row =>
  `${row[REPORT_COLUMN_FIRST_NAME]} ${row[REPORT_COLUMN_LAST_NAME]}`.trim()
export const rowIsHausdirectPayout = row =>
  row[REPORT_COLUMN_ETA_PAYOUT_METHOD] === PAYOUT_METHOD_HAUSDIRECT
export const rowHausdirectPayoutIneligible = (row, eta_min_employee_payout) => {
  const isHausdirectPayout = rowIsHausdirectPayout(row)
  const total_eta_available = rowEtaAvailable(row)
  return isHausdirectPayout && total_eta_available < eta_min_employee_payout
}
export const rowEtaPayoutMethod = row => row[REPORT_COLUMN_ETA_PAYOUT_METHOD]
export const rowLastEtaPayoutOccurredToday = (
  row,
  business_start_date,
  business_end_date
) =>
  // employee payout occurred today if between business start date and business end date
  moment(row.eta_last_payout_at).isSameOrAfter(business_start_date) &&
  moment(row.eta_last_payout_at).isBefore(business_end_date)
export const rowEtaEmployeeFees = row =>
  row[REPORT_COLUMN_ETA_EMPLOYEE_FEE] ?? 0

// row helpers
export const statusIsPositive = row =>
  [
    ETA_STATE_ACTIVE,
    ETA_STATE_FUTURE_DATED,
    ETA_STATE_PROVIDER_APPROVED
  ].indexOf(row[REPORT_COLUMN_ETA_STATE]) > -1
export const statusIsCaution = row =>
  [
    ETA_STATE_INVITED,
    ETA_STATE_CLIENT_FLAGGED,
    ETA_STATE_PROVIDER_FLAGGED
  ].indexOf(row[REPORT_COLUMN_ETA_STATE]) > -1
export const statusIsNegative = row =>
  !statusIsPositive(row) && !statusIsCaution(row)

const sortStatusRow = row => sortStatusValue(row[REPORT_COLUMN_ETA_STATE])

export const sortStatusValue = status => {
  switch (status) {
    case ETA_STATE_ACTIVE:
      return 9
    case ETA_STATE_FUTURE_DATED:
      return 8
    case ETA_STATE_PROVIDER_APPROVED:
      return 7
    case ETA_STATE_INVITED:
      return 6
    case ETA_STATE_PROVIDER_FLAGGED:
      return 5
    case ETA_STATE_CLIENT_FLAGGED:
      return 4
    case ETA_STATE_NOT_ENROLLED:
      return 3
    case ETA_STATE_DEACTIVATED:
      return 2
    case ETA_STATE_OPTED_OUT:
      return 1
    default:
      return 0
  }
}

// ETA Payouts Helpers
const DEFAULT_PAYOUT_USER = "Automatic"
export const payoutInitiatedBy = user => user?.name ?? DEFAULT_PAYOUT_USER
export const payoutLocationNames = payouts =>
  // create unique list of location names
  payouts.reduce((acc, payout) => {
    const store_name = payout?.employee?.store?.name
    return !!store_name && !acc.includes(store_name)
      ? [...acc, store_name]
      : acc
  }, [])

export const payoutLocationsDisplay = locations => {
  // excludes the first location name
  const remaining_locations = locations.slice(1, locations.length)
  // display "and <x> other(s)"
  return !!remaining_locations.length
    ? ` and ${remaining_locations.length} other${
        remaining_locations.length > 1 ? "s" : ""
      }`
    : ""
}

export const RECENT_FILTER_DAYS_BACK = 6
export const payoutsFiltersWithDefaults = (app_filters, today_date) => ({
  // inject defaults
  ...DEFUALT_ETA_FILTERS,
  // default start_date and end_date to today
  start_date: moment(today_date)
    .subtract(RECENT_FILTER_DAYS_BACK, "day")
    .format(date_format),
  end_date: today_date,
  sort_dir: "desc",
  ...(app_filters[PAYOUTS_FILTER_NAMESPACE] ?? {})
})

export const getToastFromPayoutStatus = function (payout_status) {
  switch (payout_status) {
    case PAYOUT_STATUS.SUCCESS:
      return {
        type: "success",
        message: <p>Payout Processed.</p>
      }
    case PAYOUT_STATUS.FAILURE:
      return {
        type: "error",
        message: <p>Payout Failed.</p>
      }
    case PAYOUT_STATUS.PARTIAL_FAILURE:
      return {
        type: "warning",
        message: <p>Payout Partially Failed.</p>
      }
    default:
      return null
  }
}

export const getPayoutStatusFromPayout = function (payout) {
  if (!payout) {
    return PAYOUT_STATUS.NONE
  }
  if (payout.completed_at) {
    if (payout.failed) {
      return PAYOUT_STATUS.FAILURE
    } else {
      if (
        payout.employeePayouts.some(employee_payout => employee_payout.failed)
      ) {
        return PAYOUT_STATUS.PARTIAL_FAILURE
      } else {
        return PAYOUT_STATUS.SUCCESS
      }
    }
  } else {
    return PAYOUT_STATUS.IN_PROGRESS
  }
}

export const datesIncludedDisplay = dates_included => {
  const dates = dates_included.map(d => moment(d))
  const window_start_date = moment(Math.min(...dates)).format(
    month_day_short_format
  )
  let result = window_start_date
  // if more than one date, display as range (ie. 12/6 - 12/9)
  if (dates_included.length > 1) {
    const window_end_date = moment(Math.max(...dates)).format(
      month_day_short_format
    )
    result = `${window_start_date} - ${window_end_date}`
  }
  return result
}
