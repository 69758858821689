import { Component } from "react"

import { Mutation } from "@apollo/client/react/components"

import Link from "../../Link"
import TipFeeModal from "../../Modal/TipFee"
import UpdateNameModal from "./Modal/UpdateName"
import UpdateTimezoneModal from "./Modal/UpdateTimezone"
import { SettingRow, SettingsTable, GeneralSettings } from "../Styled"

import { ccTipFeeSetting } from "../../../helpers/settings/organization"
import { paths } from "../../../constants"
import {
  UPDATE_STORE_INFO,
  UPDATE_STORE_TIP_FEE
} from "../../../graphql/mutations"

const TIP_FEE_DEFAULT = -1

const DEFAULT_MODAL_STATE = {
  update_name_modal: false,
  update_timezone_modal: false,
  update_tip_fee_modal: false
}

export default class extends Component {
  state = { ...DEFAULT_MODAL_STATE }

  onMutationError = () => {
    this.props.toast({
      type: "error",
      message: "An error occurred while attempting to update this location."
    })
    this.props.refetchStores()
  }

  onMutationSuccess = () => {
    this.setState({ ...DEFAULT_MODAL_STATE })
    this.props.toast({
      type: "success",
      message: "Location updated successfully."
    })
    this.props.refetchStores()
    this.props.syncUser()
  }

  parseTipFee = () => {
    const { user, store } = this.props

    let tip_fee = ccTipFeeSetting(store.settings)

    if (tip_fee === TIP_FEE_DEFAULT) {
      tip_fee = ccTipFeeSetting(user.organization.settings)
      return <>{tip_fee}% (Using Organization Default)</>
    }

    return `${tip_fee}%`
  }

  render() {
    const { store, user, can_edit_store, can_edit_cc_tip_fee } = this.props
    const {
      update_name_modal,
      update_timezone_modal,
      update_tip_fee_modal
    } = this.state

    return (
      <GeneralSettings>
        <SettingsTable>
          <SettingRow
            name="Name"
            value={store.name}
            onRequestEdit={
              can_edit_store
                ? () => this.setState({ update_name_modal: true })
                : false
            }
          />
          <SettingRow
            name="Timezone"
            value={store.timezone}
            onRequestEdit={
              can_edit_store
                ? () => this.setState({ update_timezone_modal: true })
                : false
            }
          />
          <SettingRow
            name="CC Tip Fee"
            value={this.parseTipFee()}
            onRequestEdit={
              !!can_edit_cc_tip_fee
                ? () => this.setState({ update_tip_fee_modal: true })
                : false
            }
          />
          <SettingRow
            name="Status"
            value={
              <>
                {!!store.active ? `Active` : "Inactive"}
                <>
                  &nbsp;-&nbsp;
                  <Link href={paths.contact} target="_blank">
                    Contact support
                  </Link>{" "}
                  to {!store.active ? "activate" : "deactivate"} this location.
                </>
              </>
            }
            onRequestEdit={false}
          />
        </SettingsTable>
        {can_edit_store && (
          <>
            <Mutation
              mutation={UPDATE_STORE_INFO}
              onCompleted={this.onMutationSuccess}
              onError={this.onMutationError}
            >
              {(updateStoreInfo, { loading }) => (
                <>
                  {!!update_name_modal && (
                    <UpdateNameModal
                      name={store.name}
                      onSubmit={name =>
                        updateStoreInfo({
                          variables: {
                            input: { id: store.id, name }
                          }
                        })
                      }
                      onClose={() =>
                        this.setState({ update_name_modal: false })
                      }
                      submitted={!!loading}
                    />
                  )}
                  {!!update_timezone_modal && (
                    <UpdateTimezoneModal
                      timezone={store.timezone}
                      onSubmit={timezone =>
                        updateStoreInfo({
                          variables: {
                            input: { id: store.id, timezone }
                          }
                        })
                      }
                      onClose={() =>
                        this.setState({ update_timezone_modal: false })
                      }
                      submitted={!!loading}
                    />
                  )}
                </>
              )}
            </Mutation>
          </>
        )}
        {can_edit_cc_tip_fee && (
          <Mutation
            mutation={UPDATE_STORE_TIP_FEE}
            onCompleted={this.onMutationSuccess}
            onError={this.onMutationError}
          >
            {(updateStoreTipFee, { loading }) => {
              return (
                <>
                  {!!update_tip_fee_modal && (
                    <TipFeeModal
                      store_id={store.id}
                      cc_tip_fee={ccTipFeeSetting(store.settings)}
                      organization_default={ccTipFeeSetting(
                        user.organization.settings
                      )}
                      onSubmit={tip_fee =>
                        updateStoreTipFee({
                          variables: {
                            store_id: store.id,
                            value: tip_fee.toString()
                          }
                        })
                      }
                      onClose={() =>
                        this.setState({ update_tip_fee_modal: false })
                      }
                      submitted={!!loading}
                    />
                  )}
                </>
              )
            }}
          </Mutation>
        )}
      </GeneralSettings>
    )
  }
}
