import { useState } from "react"

import VerifyModal from "./Modal/Verify"
import IntroModal from "./Modal/Intro"

const VerifyUsers = ({ users, onClose, onReviewCompleted }) => {
  const [intro_complete, setIntroComplete] = useState(false)

  if (!intro_complete) {
    return (
      <IntroModal
        total_pending={users.length}
        onConfirm={() => setIntroComplete(true)}
        onClose={onClose}
      />
    )
  }
  return (
    <VerifyModal
      users={users}
      onClose={onClose}
      onReviewCompleted={onReviewCompleted}
    />
  )
}

export default VerifyUsers
