import TableRow from "./TableRow"
import HeaderRow from "./HeaderRow"
import { Table } from "../../../Shared"
import { TableWrapper } from "../../Styled"
import NoResultsMessage from "./NoResultsMessage"

import { paths } from "../../../../constants"

export default ({ rules, refetch, navigateTo, duplicateRule, pagination }) => (
  <TableWrapper>
    {rules.length === 0 && <NoResultsMessage />}
    {rules.length > 0 && (
      <Table
        id="tip-distribution-rules-table"
        draggable
        singleLine
        unstackable
        overflow="auto"
        controls={{
          pagination,
          position: "bottom",
          custom_controls_position: "top"
        }}
      >
        <HeaderRow />
        <Table.Body>
          {rules.map(rule => (
            <TableRow
              key={rule.id}
              rule={rule}
              refetch={refetch}
              duplicateRule={duplicateRule}
              editRule={rule_id =>
                navigateTo(paths.editDistributionRule.replace(":id", rule_id))
              }
            />
          ))}
        </Table.Body>
      </Table>
    )}
  </TableWrapper>
)
