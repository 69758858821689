import { round } from "../../../helpers"

export const EMP_ID_IDX = "employee.id"
export const STORE_ID_IDX = "store.id"
export const RULE_NET_IDX = "net_distro"
export const POOL_NET_IDX = "tip_pool_net"
export const STORE_NAME_IDX = "store.name"
export const TOTAL_TIPS_IDX = "total_tips"
export const TOTAL_SALES_IDX = "total_sales"
export const P2P_NET_IDX = "net_peer_to_peer"
export const HOURS_WORKED_IDX = "hours_worked"
export const TAKE_HOME_IDX = "total_take_home"
export const JOB_CODE_NAMES_IDX = "job_code_names"
export const EMP_LAST_NAME_IDX = "employee.last_name"
export const EMP_EXTERNAL_IDX = "employee.external_id"
export const EMP_FIRST_NAME_IDX = "employee.first_name"
export const EMP_PAYROLL_ID_IDX = "employee.payroll_id"
export const TAKE_HOME_PER_HOUR_IDX = "take_home_per_hour"

const RULE_DEPOSIT_IDX = "total_deposit"
const RULE_WITHDRAW_IDX = "total_withdraw"
const TIP_POOL_GROSS_IDX = "tip_pool_gross"
const TOTAL_CASH_TIPS_IDX = "total_cash_tips"
const CASH_TIP_WITHDRAW_IDX = "cash_tip_withdraw"
const TOTAL_SERVICE_CHARGE_IDX = "total_service_charges"

const TYPE_STRING = "string"
const TYPE_CUSTOM = "custom"

export const DEFAULT_HIDDEN_COLUMNS = [
  EMP_EXTERNAL_IDX,
  TIP_POOL_GROSS_IDX,
  TOTAL_CASH_TIPS_IDX,
  RULE_DEPOSIT_IDX,
  RULE_WITHDRAW_IDX,
  EMP_PAYROLL_ID_IDX
]

export const COMPACT_VIEW_COLUMNS = [
  EMP_FIRST_NAME_IDX,
  EMP_LAST_NAME_IDX,
  JOB_CODE_NAMES_IDX,
  TOTAL_SALES_IDX,
  TOTAL_TIPS_IDX,
  HOURS_WORKED_IDX,
  TAKE_HOME_IDX
]

export const REQUIRED_METRICS = [
  RULE_NET_IDX,
  POOL_NET_IDX,
  TAKE_HOME_IDX,
  TOTAL_TIPS_IDX,
  HOURS_WORKED_IDX,
  JOB_CODE_NAMES_IDX,
  TOTAL_CASH_TIPS_IDX,
  CASH_TIP_WITHDRAW_IDX,
  TOTAL_SERVICE_CHARGE_IDX
]

export const DEFAULT_COMPACT_VIEW_SETTING = 1

export const LOCALSTORAGE_SETTINGS_KEY = "distribution_table_settings"

export const SORT_COLUMN_ASCENDING = "ascending"
export const SORT_COLUMN_DESCENDING = "descending"

export const DEFAULT_COLUMN_FILTERS = {
  employee_first_name_search: "",
  employee_last_name_search: "",
  employee_ext_id_search: "",
  job_name_search: "",
  store_name_search: "",
  payroll_id_search: "",
  sort_column: null,
  sort_direction: null
}

export const calculateAvgTakeHomePerHour = table_rows =>
  calculateTakeHomePerHour(
    table_rows.reduce(
      (th_acc, { rows }) =>
        th_acc + rows.reduce((row_acc, row) => row_acc + row[TAKE_HOME_IDX], 0),
      0
    ),
    table_rows.reduce(
      (hw_acc, { rows }) =>
        hw_acc +
        rows.reduce((row_acc, row) => row_acc + row[HOURS_WORKED_IDX], 0),
      0
    )
  )

export const calculateTakeHomePerHour = (take_home, hours_worked) => {
  if (take_home === 0 || hours_worked === 0) {
    return 0
  }

  return round(take_home / hours_worked, 2)
}

export const aggregateBreakdownRows = (rows, columns) => {
  let aggr_breakdowns = rows.reduce((acc, row) => {
    columns.forEach(({ name, type }) => {
      if (acc[name] === undefined) {
        acc[name] = type === TYPE_STRING ? "" : 0
      }
      switch (name) {
        case JOB_CODE_NAMES_IDX:
          acc[name] = `${acc[name]}${acc[name] !== "" ? ", " : ""}${row[name]}`
          break
        case TAKE_HOME_PER_HOUR_IDX:
          // custom handler at the bottom
          return 0
          break
        default:
          acc[name] =
            type === TYPE_STRING || type === TYPE_CUSTOM
              ? row[name]
              : acc[name] + row[name]
      }
    })
    return acc
  }, {})

  if (aggr_breakdowns[TAKE_HOME_PER_HOUR_IDX] !== undefined) {
    // take home per hour average depends on total take home and time worked.
    // the total cannot be achieved with a sum (unlike other "number" columns)
    let take_home = rows.reduce((acc, row) => acc + row[TAKE_HOME_IDX], 0),
      hours_worked = rows.reduce((acc, row) => acc + row[HOURS_WORKED_IDX], 0)
    if (hours_worked > 0) {
      aggr_breakdowns[TAKE_HOME_PER_HOUR_IDX] = take_home / hours_worked
    }
  }
  return aggr_breakdowns
}
