import { useState } from "react"
import Styled from "styled-components"
import { Dimmer, Label as SemanticLabel } from "semantic-ui-react"
import Icon from "../Icon"
import { PopupMenu } from "../../Shared"
import { isNumber } from "../../../helpers"
import { colors } from "../../../constants"

/**
 * A LabelList displays a list of labels with an optional popup with additional items.
 */
const LabelList = props => {
  const {
    items = [],
    max_labels = false,
    labelProps = {},
    popup_position = "bottom left",
    onRemove = undefined,
    onLabelClick = undefined
  } = props
  // items displayed initially
  const displayed_items = isNumber(max_labels)
    ? items.filter((e, idx) => idx < max_labels)
    : items
  // items displayed in the popup
  const popup_items = isNumber(max_labels)
    ? items.filter((e, idx) => idx >= max_labels)
    : []
  return (
    <>
      <List
        options={displayed_items}
        labelProps={labelProps}
        onRemove={onRemove}
        onLabelClick={onLabelClick}
      />
      {!!popup_items.length && (
        <LabelPopupMenu
          items={popup_items}
          trigger={
            <LabelButton {...labelProps}>
              +{popup_items.length} more
            </LabelButton>
          }
          popup_position={popup_position}
          onRemove={onRemove}
          onLabelClick={onLabelClick}
        />
      )}
    </>
  )
}

const List = ({ options, labelProps, onRemove, onLabelClick }) =>
  options.map(({ text, value }, idx) => (
    <Label
      key={`${value}-${idx}`}
      {...labelProps}
      onClick={onLabelClick ? () => onLabelClick(value) : undefined}
    >
      {text}
      {!!onRemove && (
        <>
          &nbsp;&nbsp;
          <RemoveIcon onClick={() => onRemove(value)} />
        </>
      )}
    </Label>
  ))

const LabelPopupMenu = props => {
  const { items, trigger, popup_position, onRemove, onLabelClick } = props
  const [open, setOpen] = useState(false)

  const onClose = () => setOpen(false)

  const onOpen = () => setOpen(true)

  const onPopupItemClick = option => {
    const { value } = option
    if (!!onLabelClick) {
      onLabelClick(value)
    }
    if (!!onRemove) {
      onRemove(value)
    }
    onClose()
  }

  return (
    <>
      <PopupMenu
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        position={popup_position}
        positionFixed
        trigger={trigger}
      >
        <PopupOverflow>
          {items.map((option, idx) => (
            <PopupMenu.Item
              key={`${idx}-${option.value}`}
              onClick={() => onPopupItemClick(option)}
            >
              {option.text}
              {!!onRemove && (
                <>
                  &nbsp;&nbsp;
                  <RemoveIcon float="right" color="grey" />
                </>
              )}
            </PopupMenu.Item>
          ))}
        </PopupOverflow>
      </PopupMenu>
      <PageDimmer active={open} onClickOutside={onClose} />
    </>
  )
}

// Dimmer is used to prevent page scrolling and focus on the popup menu
const PageDimmer = Styled(({ className, ...props }) => (
  <Dimmer className={className} page {...props} />
))`
	&.ui.active.dimmer { 
		opacity: 0 !important; 
	}
`

const StyledLabelButton = Styled(SemanticLabel)`
  &.ui.label {
    background-color: ${colors.white};
    box-shadow: 0 0 0 1px rgba(34, 36, 38, .15) inset;
    cursor: pointer;
  }
`

const LabelButton = props => <StyledLabelButton {...props} />

const Label = Styled(props => <SemanticLabel {...props} />)`
  margin-bottom: 0.14285714em !important;
  // margin-bottom: 6px !important;
	cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
  &:last-child {
    margin-bottom: 0px !important;
  }
`

const PopupOverflow = Styled.div`
  max-height: 20rem;
  overflow-y: auto;
`

const RemoveIcon = props => (
  <Icon name="remove" cursor="pointer" fitted {...props} />
)

export default LabelList
