import { isValidEmail } from "../../../helpers"
import { Checkbox, Table } from "../../Shared"

const ExcludedToggleCell = ({ email, excluded, disabled, onChange }) => (
  <Table.ActionCell collapsing>
    <Checkbox
      indeterminate={!excluded && !isValidEmail(email)}
      checked={!excluded}
      disabled={disabled}
      onChange={onChange}
    />
  </Table.ActionCell>
)

export default ExcludedToggleCell
