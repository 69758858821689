import React from "react"

import { Thin, Paragraph } from "../Styled"

import { project_info } from "../../../../constants"

export const STEP_INTRO = "intro"

export default () => (
  <>
    <Paragraph>
      We'd like to run through a couple of things to make sure{" "}
      {project_info.name} is optimized for your organization.
    </Paragraph>
    <Paragraph>
      After you complete these steps, we'll show you how to customize tip
      distribution for your employees.
    </Paragraph>
    <Paragraph>
      <Thin>
        <i>Note:</i> You can skip any of these steps by clicking "Next". All the
        information collected here can be updated from the Settings page.
      </Thin>
    </Paragraph>
  </>
)
