import Icon from "../../Icon"
import Dropdown from "./Dropdown"
import { Responsive } from "../../Shared"
import { useIntercom } from "react-use-intercom"
import {
  MenuItem,
  UserLabel,
  LogoButton,
  NavbarWrapper,
  SingleLocationLabel,
  SingleOrganizationLabel
} from "./Styled"

import { colors, paths } from "../../../constants"
import { LOGIN_ENTERPRISE_ADMIN_TO_ORG } from "../../../graphql/mutations"
import { useMutation } from "@apollo/client"

export default props => {
  const {
    user,
    syncUser,
    loginSuccess,
    logout,
    isMobile,
    navigateTo,
    logout_pending,
    selected_location_id,
    selected_location_name,
    changeSelectedLocation
  } = props

  const { show } = useIntercom()
  const { stores } = user

  const clearSearchParams = () => {
    const url = new URL(window.location)
    url.search = ""
    window.history.replaceState({}, "", url)
  }

  let store_options = false
  if (stores.length > 1) {
    store_options = [
      {
        label: "All Locations",
        content: <>All Locations</>,
        selected: !selected_location_id,
        onSelect: () => {
          changeSelectedLocation(null)
          // clear out get params when changing locations
          clearSearchParams()
        }
      }
    ]
    store_options = [
      ...store_options,
      ...stores
        // sort on store name
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(store => ({
          label: store.name,
          content: <>{store.name}</>,
          selected: selected_location_id === store.id,
          onSelect: () => {
            changeSelectedLocation(store.id)
            // clear out get params when changing locations
            clearSearchParams()
          }
        }))
    ]
  }

  const [loginToOrg] = useMutation(LOGIN_ENTERPRISE_ADMIN_TO_ORG, {
    onCompleted: result => {
      if (result && result.loginEnterpriseAdminToOrg) {
        changeSelectedLocation(null)
        syncUser(loginSuccess())
      }
    }
  })

  const organizations = user?.enterprise?.organizations
  let organization_options = false
  if (!!organizations && organizations.length > 1) {
    organization_options = [...organizations]
      .filter(
        organization =>
          organization.active && organization.active_stores.length > 0
      )
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(organization => ({
        label: organization.name,
        content: organization.name,
        selected: user.organization.id == organization.id,
        onSelect: () => {
          loginToOrg({ variables: { id: organization.id } })
        }
      }))
  }

  return (
    <NavbarWrapper>
      <LogoButton
        onClick={() => navigateTo("/")}
        onAuxClick={() => window.open("/")}
      />
      {!!user && !!user.enterprise_admin && (
        <Responsive inline min_width="500px" style={{ height: "100%" }}>
          {organizations.length === 1 && (
            <MenuItem button={false}>
              <SingleOrganizationLabel name={user.organization.name} />
            </MenuItem>
          )}
          {!!organization_options && (
            <Dropdown
              id="navbar-dropdown-organizations"
              search
              value={user?.organization.name}
              options={organization_options}
              icon={
                <Icon
                  name="globe"
                  color={colors.dark6}
                  style={{ marginRight: "0.67rem" }}
                />
              }
            />
          )}
        </Responsive>
      )}
      <Responsive inline min_width="500px" style={{ height: "100%" }}>
        {stores.length === 1 && (
          <MenuItem button={false}>
            <SingleLocationLabel name={selected_location_name} />
          </MenuItem>
        )}
        {!!store_options && (
          <Dropdown
            id="navbar-dropdown-stores"
            search
            value={
              <>
                {!!selected_location_name
                  ? selected_location_name
                  : `All Locations`}
              </>
            }
            options={store_options}
          />
        )}
      </Responsive>
      <Dropdown
        right
        value={
          <UserLabel
            is_mobile={isMobile}
            name={
              user.name.trim().length === 0
                ? user.email.trim()
                : user.name.trim()
            }
          />
        }
        options={[
          {
            content: (
              <>
                <Icon name="user circle" />
                &nbsp; Profile
              </>
            ),
            onSelect: () => navigateTo(paths.profile)
          },
          {
            content: (
              <>
                <Icon name="cog" />
                &nbsp; Settings
              </>
            ),
            visible: true,
            onSelect: () => navigateTo(paths.settings)
          },
          {
            content: (
              <>
                <Icon name="send" />
                &nbsp; Contact Us
              </>
            ),
            // show intercom on selecting Contact Us
            onSelect: () => show()
          },
          {
            loading: logout_pending,
            close_on_select: false,
            content: (
              <>
                <Icon name="log out" />
                &nbsp; Log Out
              </>
            ),
            onSelect: () => {
              if (!logout_pending) {
                logout()
              }
            }
          }
        ]}
      />
    </NavbarWrapper>
  )
}
