import { useMutation } from "@apollo/client"
import { DELETE_USER } from "../../../../../../graphql/mutations"
import { project_info } from "../../../../../../constants"
import { AreYouSure } from "../../../../../Shared"

const DeleteUserModal = ({ user, onClose, onCompleted }) => {
  const [deleteUser, { loading }] = useMutation(DELETE_USER)

  const handleDeleteUser = () => {
    const { id } = user
    deleteUser({
      variables: { id },
      onCompleted
    })
  }

  return (
    <AreYouSure
      header="Are you sure?"
      submitted={loading}
      body={
        <>
          <p>Are you sure you want to delete {user.name}?</p>
          <p>This user will no longer have access to {project_info.name}.</p>
        </>
      }
      onConfirm={handleDeleteUser}
      onClose={onClose}
    />
  )
}

export default DeleteUserModal
