import { PresetsWrapper, PresetsLabel, PresetLink } from "../Styled"

export default ({ presets = [] }) => (
  <PresetsWrapper>
    {!!presets.length && (
      <PresetsLabel>Filters{presets.length > 0 && ":"}</PresetsLabel>
    )}
    {presets.map(({ name, active = false, onClick }, idx) => (
      <PresetLink
        key={idx}
        active={active}
        onClick={onClick}
        id={`quick-filter-${name.toLowerCase().replace(/\s/g, "-")}`}
      >
        {name}
      </PresetLink>
    ))}
  </PresetsWrapper>
)
