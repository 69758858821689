import { Field } from "../../../../Styled"
import SchedulesInput from "./SchedulesInput"
import LocationsInput from "./LocationsInput"
import EffectiveDateInput from "./EffectiveDateInput"
import RevenueCentersInput from "./RevenueCentersInput"
import { Badge, Input } from "../../../../../Shared"

import {
  useLocations,
  useSelectedLocation,
  useRevenueCentersEnabled,
  useGlobalLockThreshold
} from "../../../../../../hooks"
import { POOL_EDITOR_MODE_CREATE } from "../../../helpers"
import { DISTRO_SOURCE_TYPE_CASH } from "../../../../helpers"

// step entrypoint
export const basicsStep = (
  {
    name,
    locations,
    revenue_centers,
    effective_date,
    schedules,
    distribution_source_type,
    editor_mode
  },
  onUpdate
) => ({
  name: "Basics",
  complete: (name ?? "").trim().length > 0 && locations.length > 0,
  render: () => (
    <Basics
      name={name}
      schedules={schedules}
      editor_mode={editor_mode}
      selected_locations={locations}
      effective_date={effective_date}
      revenue_centers={revenue_centers}
      distribution_source_type={distribution_source_type}
      onUpdate={onUpdate}
    />
  )
})

const Basics = ({
  name,
  selected_locations,
  revenue_centers,
  effective_date,
  schedules,
  distribution_source_type,
  onUpdate,
  editor_mode
}) => {
  const locations = useLocations()
  const selected_location = useSelectedLocation()
  const rev_centers_enabled = useRevenueCentersEnabled()
  const lock_threshold = useGlobalLockThreshold(selected_locations)
  return (
    <>
      <Field fluid name="Pool Name">
        <Input
          fluid
          value={name}
          placeholder="Choose something descriptive"
          onChange={(e, d) => onUpdate({ name: d.value })}
        />
      </Field>
      {/* locations are configurable when creating a new pool */}
      {editor_mode === POOL_EDITOR_MODE_CREATE && (
        <Field fluid name={selected_location ? "Location" : "Locations"}>
          {!!selected_location && (
            <LocationsList locations={[selected_location]} />
          )}
          {!selected_location && (
            <LocationsInput
              options={locations}
              onUpdate={selected => {
                let update = { locations: [...selected] }
                if (selected.length > selected_locations.length) {
                  // a location was added, clear values dependent on selected locations
                  update = { ...update, revenue_centers: [], job_codes: {} }
                }
                onUpdate({ ...update })
              }}
              value={selected_locations}
            />
          )}
        </Field>
      )}
      {/* locations are not configurable when editing */}
      {editor_mode !== POOL_EDITOR_MODE_CREATE && (
        <Field
          fluid
          name={selected_locations.length === 1 ? "Location" : "Locations"}
        >
          <LocationsList
            locations={selected_locations.map(location_id =>
              locations.find(({ id }) => id === location_id)
            )}
          />
        </Field>
      )}
      {selected_locations.length > 0 &&
        rev_centers_enabled &&
        distribution_source_type !== DISTRO_SOURCE_TYPE_CASH && (
          <Field fluid name="Revenue Centers">
            <RevenueCentersInput
              locations={selected_locations}
              selected={revenue_centers}
              onUpdate={onUpdate}
            />
          </Field>
        )}
      <Field fluid name="Effective Date">
        <EffectiveDateInput
          lock_threshold={lock_threshold}
          effective_date={effective_date}
          onUpdate={value =>
            onUpdate({
              effective_date: value
            })
          }
        />
      </Field>
      <Field
        fluid
        name="Schedule"
        description={
          <>
            <p>
              Optionally specify which days and times this pool should apply.
            </p>
            <p>Pools are always active by default</p>
          </>
        }
      >
        <SchedulesInput schedules={schedules} onUpdate={onUpdate} />
      </Field>
    </>
  )
}

const LocationsList = ({ locations }) =>
  locations.map(location => (
    <Badge key={location.id} size="large" basic>
      {location.name}
    </Badge>
  ))
