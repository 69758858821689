import React from "react"
import Styled from "styled-components"

import Message from "../../Message"
import Link from "../../Link"

import { colors, paths } from "../../../constants"

const Line = Styled.div`
  margin-top: 1rem;
`

export const NoExportsFound = Styled(({ className }) => (
  <div>
    <Message
      type="info"
      inline
      title="No Scheduled Report Exports Found"
      className={className}
    >
      <Line>
        You're seeing this message because there are no active report exports
        scheduled for your organization.
      </Line>
      <Line>
        Report exports can automatically export your breakdown to a remote
        destination.
      </Line>
      <Line>
        If you'd like to learn more about exports,{" "}
        <Link onClick={() => window.open(paths.contact)}>let us know!</Link>
      </Line>
    </Message>
  </div>
))`
  margin-bottom: 1.33rem;
  border: 1px solid ${colors.light5};
`

export const NoResultsMessage = Styled(props => (
  <Message
    inline
    type="info"
    title="No Export Runs Found"
    message={
      <>
        No export runs were found with the current filters. Try expanding the
        date range for a broader search.
      </>
    }
    {...props}
  />
))`
  margin: 0.33rem 0;
`
