import { useState } from "react"

import Styled from "styled-components"
import { useQuery, useMutation } from "@apollo/client"

import { Field } from "../../../Rule/Styled"
import saleAssignedPoolSequence from "../../../Rule/SaleAssignedPool/Editor"
import {
  Icon,
  Form,
  Modal,
  Table,
  Button,
  InfoIcon,
  Dropdown,
  Statistic,
  AreYouSure,
  SubmitButton,
  SequenceModal
} from "../../../Shared"

import {
  ruleIsEffective,
  DISTRO_METHOD_DAILY,
  TIP_DISTRO_SOURCES_ALL
} from "../../../Rule/helpers"
import {
  CREATE_RULE,
  REMOVE_RULE_ASSIGNED_SALE
} from "../../../../graphql/mutations"
import {
  saleGratuityForSources,
  RULE_TYPE_SALE_ASSIGNED_POOL
} from "../../../Rule/SaleAssignedPool/helpers"
import { FILTERED_SALES } from "../../../../graphql/queries"
import { toInt, displayMoney } from "../../../../helpers/number"
import { ucfirst, formatListForSentence } from "../../../../helpers/string"
import { useApplyRulesToSaleAssignedPools } from "../../../../hooks"
import moment from "moment"
import { time_format_verbose } from "../../../../constants"
import useAuthorization from "../../../../hooks/authorization"
import { reporting_custom_team_adjustment } from "../../../../constants/permissions"

const Intro = Styled.div`
  font-weight:300;
  margin-bottom: 1.33rem;
`

export const RequestCustomizeTeamModal = ({
  user,
  onClose,
  onSubmit,
  selected_location_id,
  changeSelectedLocation
}) => {
  const stores = user.stores.map(s => ({
    key: s.id,
    text: s.name,
    value: s.id
  }))

  return (
    <Modal size="mini" onClose={onClose} closeOnDimmerClick={false}>
      <Modal.Header onClose={onClose}>Custom Team Adjustment</Modal.Header>
      <Modal.Content>
        <Intro>
          <p>
            The Custom Team Adjustment action allows you to manually adjust tip
            rules and employee participants for specific sales.
          </p>
          {stores.length > 1 && (
            <p>
              Select a location, then click "Select Sales" to specify one or
              more sales to adjust.
            </p>
          )}
          {stores.length === 1 && (
            <p>Click "Select Sales" to specify one or more sales to adjust.</p>
          )}
        </Intro>
        {stores.length > 1 && (
          <Form>
            <Form.Field>
              <label>Select a Location</label>
              <Dropdown
                clearable
                selection
                options={stores}
                placeholder="Select one"
                value={selected_location_id}
                onChange={(e, { value }) => {
                  const store = user.stores.find(s => s.id == value)
                  if (!!store) {
                    changeSelectedLocation(store.id)
                  } else {
                    changeSelectedLocation(null)
                  }
                }}
              />
            </Form.Field>
          </Form>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Cancel</Button>
        <SubmitButton
          onClick={onSubmit}
          icon="arrow right"
          label="Select Sales"
          disabled={!selected_location_id}
        />
      </Modal.Actions>
    </Modal>
  )
}

export const CustomizeTeamSequenceModal = ({
  onClose,
  edit_sales,
  onCompleted,
  selected_location_id,
  ...props
}) => {
  const [rule, updateRule] = useState({
    loading: true,
    store_ids: [selected_location_id],
    type: RULE_TYPE_SALE_ASSIGNED_POOL,
    distribution_method: DISTRO_METHOD_DAILY,
    sales: [
      // edit_sales
      // {id: 123456, is_constrained: false},
      // ...
    ],
    employees: [
      // employees
      // { id: 1234, distribution_weight: 0.5 },
      // ...
    ],
    distribution_sources: TIP_DISTRO_SOURCES_ALL
  })

  const apply_rules_to_sales = useApplyRulesToSaleAssignedPools()

  useQuery(FILTERED_SALES, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: { id_in: edit_sales, page_size: 10000 },
    onCompleted: data =>
      updateRule({
        ...rule,
        loading: false,
        sales: data?.filteredSales?.sales.map(sale => ({
          ...sale,
          is_constrained: !apply_rules_to_sales
        }))
      })
  })

  const [createRule] = useMutation(CREATE_RULE, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      onCompleted()
    }
  })

  return (
    <SequenceModal
      header="Create a Custom Team Adjustment"
      steps={saleAssignedPoolSequence(
        { ...props, rule, selected_location_id },
        updateRule
      )}
      loading={rule.loading}
      size="large"
      onClose={onClose}
      onSubmit={() => {
        createRule({
          variables: {
            input: {
              rule: {
                type: rule.type,
                distribution_method: rule.distribution_method
              },
              distribution_sources: rule.distribution_sources,
              store_ids: rule.store_ids,
              sales: rule.sales.map(({ id, is_constrained }) => ({
                id: toInt(id),
                is_constrained
              })),
              employees: rule.employees.map(({ id, distribution_weight }) => ({
                id: toInt(id),
                distribution_weight
              }))
            }
          }
        })
        updateRule({ ...rule, loading: true })
      }}
    />
  )
}

export const SaleAssignedRuleModal = ({
  sale,
  onClose,
  saleLockThreshold,
  onAssignmentRemoved
}) => {
  const { hasPermission } = useAuthorization()
  const [remove_assignment, requestRemoveAssignment] = useState(false)

  const [removeRuleAssignedSale, { loading }] = useMutation(
    REMOVE_RULE_ASSIGNED_SALE,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        onAssignmentRemoved()
      }
    }
  )

  // assume only one active assigned rule for now
  const {
    id: rule_id,
    employees,
    pivot,
    distributionSources,
    createdBy,
    created_at
  } = sale.assignedDistributionRules.filter(rule =>
    ruleIsEffective(sale.sale_time, rule)
  )[0]
  const { is_constrained } = pivot
  const distribution_sources = distributionSources.map(({ name }) => name)
  const amount_shared = saleGratuityForSources(sale, distribution_sources)
  const total_weight = employees.reduce(
    (acc, employee) => acc + employee.distribution_weight,
    0
  )

  // determine if the sale is locked
  const sale_lock_threshold = saleLockThreshold(sale)
  const sale_locked = sale_lock_threshold.isAfter(sale.opened_time)

  return (
    <>
      <Modal onClose={onClose} size="tiny">
        <Modal.Header onClose={onClose}>
          Custom Team Adjustment Applied
        </Modal.Header>
        <Modal.Content>
          <Field name="Amount Shared">
            <Statistic value={displayMoney(amount_shared)} />
            <div>
              <strong>Source:</strong>{" "}
              {ucfirst(formatListForSentence(distribution_sources))}
            </div>
          </Field>
          <Field name="Recipients">
            <Table>
              <Table.Body>
                {[...employees]
                  .sort((a, b) =>
                    a.distribution_weight < b.distribution_weight ? 1 : -1
                  )
                  .map((employee, idx) => (
                    <Table.Row key={idx}>
                      <Table.Cell>{employee.name}</Table.Cell>
                      <Table.Cell positive textAlign="right">
                        {displayMoney(
                          amount_shared *
                            (employee.distribution_weight / total_weight)
                        )}
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
            {!is_constrained && <RulesAppliedMessage />}
            <div style={{ marginTop: "1rem" }}>
              Created by:{" "}
              {!!createdBy ? createdBy.name : "TipHaus Representative"} on{" "}
              {moment.utc(created_at).local().format(time_format_verbose)}{" "}
              (local time)
            </div>
          </Field>
        </Modal.Content>
        <Modal.Actions>
          {hasPermission(reporting_custom_team_adjustment) && (
            <Button
              negative
              disabled={!!sale_locked}
              onClick={() => requestRemoveAssignment(true)}
            >
              {!!sale_locked && <Icon name="lock" />}
              Remove Assignment
            </Button>
          )}
          <Button onClick={onClose}>Close</Button>
        </Modal.Actions>
      </Modal>
      {remove_assignment && (
        <AreYouSure
          submitted={!!loading}
          header="Are You Sure?"
          body={
            <>
              Are you sure you want to remove the custom team adjustment
              configured on this sale?
            </>
          }
          onConfirm={() =>
            removeRuleAssignedSale({
              variables: {
                rule_id,
                sale_id: sale.id
              }
            })
          }
          onClose={() => requestRemoveAssignment(false)}
        />
      )}
    </>
  )
}

const RulesAppliedMessage = Styled(({ className }) => (
  <div className={className}>
    <InfoIcon /> Tip sharing rules were applied on this Custom Team Adjustment.
  </div>
))`
  margin-top: 1.5rem;
`
