import { useQuery } from "@apollo/client"

import moment from "moment"
import Styled from "styled-components"

import { Table, Loader, Modal } from "../../../Shared"

import { colors, style } from "../../../../constants"
import { SALE_CHECKS } from "../../../../graphql/queries"

const CheckWrapper = Styled.div`
  background-color: ${colors.light};
  border: 1px solid ${colors.light4};
  color: ${colors.dark};
  border-radius: ${style.border_radius};
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
`
const CheckHeader = Styled.div`
  background-color: ${colors.light2};
  border-bottom: 1px solid ${colors.light4};
  padding: 1.33rem 2rem;
`
const Header = Styled.div`
  font-size: 1.3rem;
  font-weight: 400;
`
const Subheader = Styled.div`
  padding-top: 0.1rem;
  font-size: 1rem;
`
const CheckBody = Styled.div`
  background-color: ${colors.white};
  padding: 1.33rem 2rem;
  border-bottom: 1px solid ${colors.light4};
`
const TableHeader = Styled(Table.HeaderCell)`
  font-weight: 400 !important;
`
const SaleItem = Styled(props => <Table.Row hover={false} {...props} />)`
  font-weight: 300;
`
const TotalRow = Styled(props => <Table.Row hover={false} {...props} />)``
const CheckSummary = Styled.div`
  padding: 1.33rem 2rem;
  background-color: ${colors.light2};
`
const SummaryRow = Styled.div`
  text-align: right;
  font-size: 1.33 rem;
`
const Thin = Styled.span`font-weight:300;`

export default ({ sale, onClose }) => {
  const { loading, data } = useQuery(SALE_CHECKS, {
    variables: { id: sale.id }
  })

  const checks = data?.sale?.checks.filter(
    check => !check.voided && !check.deleted
  )

  return (
    <Modal closeOnEscape size="small" basic onClose={onClose}>
      <Modal.Header onClose={onClose}>Sale Checks</Modal.Header>
      <Modal.Content>
        {loading && <Loader size="large" />}
        {!loading &&
          checks.map((check, idx) => (
            <Check
              key={idx}
              employee_name={`${sale.employee.first_name} ${sale.employee.last_name}`}
              sale_time={sale.sale_time}
              check={check}
            />
          ))}
      </Modal.Content>
    </Modal>
  )
}

const Check = ({ employee_name, sale_time, check }) => (
  <CheckWrapper>
    <CheckHeader>
      <Header>Check ID: {check.external_id ? check.external_id : "-"}</Header>
      <Header>Cashier: {employee_name}</Header>
      <Subheader>Date: {moment(sale_time).format("LLLL")}</Subheader>
    </CheckHeader>
    <CheckBody>
      <Table basic="very" compact celled>
        <Table.Header>
          <Table.Row>
            <TableHeader>Name</TableHeader>
            <TableHeader>Category</TableHeader>
            <TableHeader textAlign="right">Price</TableHeader>
            <TableHeader textAlign="right">Tax</TableHeader>
            <TableHeader textAlign="right">Total</TableHeader>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {check.saleItems
            .filter(sale_item => !sale_item.voided)
            .map((sale_item, idx) => (
              <SaleItem key={idx}>
                <Table.Cell>{sale_item.name}</Table.Cell>
                <Table.Cell>
                  {!!sale_item.salesCategory
                    ? sale_item.salesCategory.name
                    : "None"}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  ${sale_item.price.toFixed(2)}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  ${sale_item.tax.toFixed(2)}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  ${(sale_item.price + sale_item.tax).toFixed(2)}
                </Table.Cell>
              </SaleItem>
            ))}
          <TotalRow>
            <Table.Cell>Subtotal:</Table.Cell>
            <Table.Cell />
            <Table.Cell textAlign="right">
              $
              {check.saleItems
                .filter(sale_item => !sale_item.voided)
                .reduce((acc, sale_item) => acc + sale_item.price, 0)
                .toFixed(2)}
            </Table.Cell>
            <Table.Cell textAlign="right">
              $
              {check.saleItems
                .filter(sale_item => !sale_item.voided)
                .reduce((acc, sale_item) => acc + sale_item.tax, 0)
                .toFixed(2)}
            </Table.Cell>
            <Table.Cell textAlign="right">
              $
              {check.saleItems
                .filter(sale_item => !sale_item.voided)
                .reduce(
                  (acc, sale_item) => acc + sale_item.price + sale_item.tax,
                  0
                )
                .toFixed(2)}
            </Table.Cell>
          </TotalRow>
        </Table.Body>
      </Table>
    </CheckBody>
    <CheckSummary>
      {check.service_charge > 0 && (
        <SummaryRow>
          <Thin>Service Charge:</Thin>&nbsp;&nbsp; $
          {check.service_charge.toFixed(2)}
        </SummaryRow>
      )}
      <SummaryRow>
        <Thin>Tip:</Thin>&nbsp;&nbsp;
        {!!check.cc_tips ? `$${check.cc_tips.toFixed(2)}` : `None`}
      </SummaryRow>
      {!!check.cash_tips && (
        <SummaryRow>
          <Thin>Cash Tip:</Thin>&nbsp;&nbsp;${check.cash_tips}
        </SummaryRow>
      )}

      <SummaryRow>
        <Thin>Tax:</Thin>&nbsp;&nbsp;
        {!!check.tax ? `$${check.tax.toFixed(2)}` : `None`}
      </SummaryRow>
      <SummaryRow>
        <Thin>Total:</Thin>&nbsp;&nbsp;$
        {(
          check.cc_tips +
          check.cash_tips +
          check.price +
          check.tax +
          check.service_charge
        ).toFixed(2)}
      </SummaryRow>
    </CheckSummary>
  </CheckWrapper>
)
