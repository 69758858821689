import Styled from "styled-components"
import { settings_manage_roles } from "../../../../../constants/permissions"
import useAuthorization from "../../../../../hooks/authorization"
import { Button, FilterBar, MultiSelect } from "../../../../Shared"
import { useRoleModals } from "../context/roleModals"
import {
  useEntityTypeFilterOptions,
  SORT_OPTIONS,
  EDITOR_MODE_CREATE
} from "../helpers"

const RolesFilterBar = ({ filters, setFilters, loading }) => {
  const { hasPermission } = useAuthorization()
  const { showModal } = useRoleModals()
  const entity_type_options = useEntityTypeFilterOptions()
  return (
    <FilterBar
      loading={loading}
      loading_msg="Loading Roles"
      sort={{
        field: filters.sort_by ?? null,
        direction: filters.sort_dir ?? null,
        options: SORT_OPTIONS,
        onChange: (sort_by, sort_dir) => setFilters({ sort_by, sort_dir })
      }}
      custom_filters={[
        {
          label: "Roles",
          filter: (
            <RolesDropdown
              placeholder="All"
              max_labels={3}
              value={filters.entity_types}
              options={entity_type_options}
              onChange={(e, { value }) => setFilters({ entity_types: value })}
            />
          )
        }
      ]}
      controls={
        hasPermission(settings_manage_roles) ? (
          <Button
            primary
            circular
            icon="plus"
            content="Create"
            size="small"
            popup={{
              delay: true,
              position: "left center",
              content: "Create a Role"
            }}
            onClick={() =>
              showModal("editor_modal", { mode: EDITOR_MODE_CREATE })
            }
          />
        ) : null
      }
    />
  )
}

const RolesDropdown = Styled(MultiSelect)`
  max-width: 30rem;
  min-width: 14rem;
  .ui.fluid.dropdown {
    display: flex;
    flex-wrap: wrap; 
  }
`

export default RolesFilterBar
