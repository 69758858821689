export const urls = () => {
  return {
    api: process.env.REACT_APP_API_URL,
    setup: process.env.REACT_APP_SETUP_URL,
    client: process.env.REACT_APP_CLIENT_URL,
    my: process.env.REACT_APP_MY_URL,
    marketing: process.env.REACT_APP_MARKETING_URL,
    blog: process.env.REACT_APP_BLOG_URL,
    faq: process.env.REACT_APP_FAQ_URL,
    learning: process.env.REACT_APP_LEARNING_URL,
    public_docs: process.env.REACT_APP_PUBLIC_DOCS_URL,
    webinar: process.env.REACT_APP_WEBINAR_URL
  }
}

export const api = {
  origin: `${urls().api}api`,
  host: urls().api,
  graphql_origin: `${urls().api}graphql`
}

export const paths = {
  index: "/",
  login: "/login",
  profile: "/profile",
  contact: "/contact",
  pageNotFound: "/page-not-found",
  maintenance: "/maintenance",
  // breakdown
  breakdown: "/breakdown",
  // reporting
  report: "/report",
  reportBreakdown: "/report/breakdown-snapshots",
  reportPeerToPeer: "/report/peer-to-peer",
  reportSales: "/report/sales",
  reportWorkedShifts: "/report/worked-shifts",
  // distribution rules
  distributionRules: "/tip-distribution",
  createDistributionRule: "/tip-distribution/tip-sharing/create",
  editDistributionRule: "/tip-distribution/tip-sharing/edit/:id",
  // settings
  settings: "/settings",
  settingsUsers: "/settings/users",
  settingsLocations: "/settings/locations",
  settingsTipFee: "/settings/tip-fee",
  settingsJobCodes: "/settings/job-codes",
  settingsEmployees: "/settings/employees",
  settingsSaleCategories: "/settings/sale-categories",
  settingsPayPeriod: "/settings/pay-period",
  settingsPeerToPeer: "/settings/peer-to-peer",
  settingsEmailSubscriptions: "/settings/email-subscriptions",
  // earned tip access
  earnedTipAccess: "/earned-tip-access",
  earnedTipAccessOverview: "/earned-tip-access/overview",
  earnedTipAccessPayouts: "/earned-tip-access/payouts"
}
