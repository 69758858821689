import Styled from "styled-components"
import { List } from "semantic-ui-react"

// step entrypoint
export const howItWorksStep = () => ({
  name: "How it Works",
  complete: true,
  render: () => <HowItWorks />
})

const HowItWorks = () => {
  return (
    <>
      <Line header>
        <strong>Control exactly how tips are split</strong> with a custom team
        adjustment.
      </Line>
      <Line>
        In the following steps, you'll specify:
        {/* which gratuity types to split,
        who is participating, and how much each person should receive. */}
        <List bulleted>
          <List.Item>Which gratuity types to split</List.Item>
          <List.Item>Which employees are participating</List.Item>
          <List.Item>How much each employee should receive</List.Item>
        </List>
      </Line>
      <Line>Custom team adjustments apply in place of existing tip pools.</Line>
      <Line>
        You'll have the option to enable or disable your standard tip sharing
        rules.
      </Line>
      <Line>Click the "Next" button in the bottom right to get started!</Line>
    </>
  )
}

const Line = Styled.div`
  font-size: 1.2rem;
  font-weight: 300;
  margin-bottom: 1.5rem;
  & .highlight {
    font-weight: 400;
  }

  & > .list {
    margin-top: 0.5rem !important;
    padding-left: 1rem;
  }
`
