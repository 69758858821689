import { useEffect, useRef } from "react"

import {
  FormTitle,
  FormHeader,
  InputGroup,
  FormContent,
  FormWrapper,
  LoginWrapper,
  LinksWrapper
} from "./Styled"
import IconLogoSvg from "../Logo"
import { Button, Message, Input, Form, List } from "../Shared"

import { useStateObject } from "../../hooks"
import { colors, project_info } from "../../constants"
import { isValidEmail, parseSearchQuery } from "../../helpers"

export default ({ request_error, login, loading, ...props }) => {
  const [{ invalid_email, invalid_password }, setState] = useStateObject({
    invalid_email: false,
    invalid_password: false
  })

  // use refs instead of state to track input values
  // to support password autofill from browser
  const email_input = useRef()
  const password_input = useRef()

  const onEnterPress = e =>
    e.key === "Enter" &&
    inputIsValid(inputValue(email_input), inputValue(password_input))
      ? login(inputValue(email_input), inputValue(password_input))
      : null

  // on mount behavior
  useEffect(() => {
    // auto populate email field
    const query = parseSearchQuery(props.location.search)
    if (query?.email) {
      email_input.current.inputRef.current.value = query.email
    }
    document.addEventListener("keydown", onEnterPress)
    // unmount behvaior
    return () => document.removeEventListener("keydown", onEnterPress)
  }, [])

  return (
    <LoginWrapper>
      <FormHeader>
        <IconLogoSvg
          style={{ verticalAlign: "bottom" }}
          size="large"
          primaryColor={colors.success}
          secondaryColor={colors.dark4}
        />
        <FormTitle>{project_info.title}</FormTitle>
      </FormHeader>
      <FormWrapper>
        {request_error && (
          <Message type="danger" margin_bottom="1rem">
            Incorrect email or password.
          </Message>
        )}
        <FormContent disabled={!!loading}>
          <Form autoComplete="on">
            <InputGroup>
              <Input
                fluid
                icon={!invalid_email ? "user" : "exclamation"}
                name="email"
                autoFocus={true}
                ref={email_input}
                placeholder="email"
                autoComplete="email"
                error={invalid_email}
                onChange={(e, { value }) => {
                  if (invalid_email && emailIsValid(value)) {
                    setState({ invalid_email: false })
                  }
                }}
              />
              <Input
                fluid
                icon={!invalid_password ? "lock" : "exclamation"}
                name="password"
                type="password"
                ref={password_input}
                placeholder="password"
                autoComplete="current-password"
                error={invalid_password}
                onChange={(e, { value }) => {
                  if (invalid_password && passwordIsValid(value)) {
                    setState({ invalid_password: false })
                  }
                }}
              />
            </InputGroup>
          </Form>
          <Button
            primary
            fluid
            type="submit"
            loading={!!loading}
            disabled={!!loading}
            onClick={() => {
              const email = inputValue(email_input)
              const password = inputValue(password_input)
              if (!inputIsValid(email, password)) {
                setState({
                  invalid_email: !emailIsValid(email),
                  invalid_password: !passwordIsValid(password)
                })
              } else {
                login(email, password)
              }
            }}
            size="large"
          >
            Login
          </Button>
          <LinksWrapper>
            <List horizontal divided>
              <List.Item>
                <a href={project_info.forgot_pw_link} target="_blank">
                  Forgot Password
                </a>
              </List.Item>
              <List.Item>
                <a href={project_info.marketing_site_url} target="_blank">
                  TipHaus.com
                </a>
              </List.Item>
              <List.Item>
                <a href={project_info.employee_app_url} target="_blank">
                  Employee App
                </a>
              </List.Item>
            </List>
          </LinksWrapper>
        </FormContent>
      </FormWrapper>
    </LoginWrapper>
  )
}

const inputValue = ref => ref?.current?.inputRef?.current?.value ?? ""

const emailIsValid = email => isValidEmail(email)
const passwordIsValid = password => password?.length !== 0

const inputIsValid = (email, password) =>
  emailIsValid(email) && passwordIsValid(password)
