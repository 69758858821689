import { useState, useEffect } from "react"
import { Query } from "@apollo/client/react/components"

import ShiftsTable from "./Table"
import ShiftsFilterBar from "./FilterBar"
import CustomShifts from "./Modal/CustomShifts"
import { ActionsDropdown } from "../../Dropdown"
import NoResultsMessage from "./Table/NoResults"
import ExportButton from "./Table/Actions/Export"
import { AlertGroup, MissedClockoutsAlert } from "../../Alert"

import { toInt, hashify } from "../../../helpers"
import { useBooleanState } from "../../../hooks"
import { FILTERED_WORKED_SHIFTS } from "../../../graphql/queries"
import { ACTION_CUSTOM_SHIFT, useWorkedShiftActions } from "./Table/Actions"
import ToggleCompactViewBtn from "../Sales/Table/Actions/ToggleCompactView"

const DEFAULT_COMPACT_VIEW_SETTING = 1
const LOCALSTORAGE_SETTINGS_KEY = "shifts_report_settings"

export default ({
  user,
  toast,
  presets,
  report_filters,
  setReportFilters,
  selected_location_id,
  selected_location_name,
  changeSelectedLocation,
  // biz date helpers
  businessEnd,
  businessStart,
  reportLockThreshold
}) => {
  const [compact_view, setCompactView] = useState(null)

  const compactViewSettingsKey = ({ name }) =>
    `${LOCALSTORAGE_SETTINGS_KEY}.${hashify(name)}`

  const toggleCompactViewSetting = () => {
    const toggled_state = !!compact_view ? 0 : 1
    localStorage.setItem(compactViewSettingsKey(user), toggled_state)
    setCompactView(toggled_state === 1 ? true : false)
  }

  useEffect(() => {
    const initializeCompactViewSetting = () => {
      const setting_key = compactViewSettingsKey(user)
      const stored_compact_view = localStorage.getItem(setting_key)
      // initial setup
      if (stored_compact_view === null) {
        localStorage.setItem(setting_key, DEFAULT_COMPACT_VIEW_SETTING)
        const stored_compact_view = localStorage.getItem(setting_key)
        return !!toInt(stored_compact_view) ? true : false
      }
      // fallback
      return !!toInt(stored_compact_view) ? true : false
    }

    const compact_view_setting = initializeCompactViewSetting()
    if (compact_view === null || compact_view !== compact_view_setting) {
      setCompactView(compact_view_setting)
    }
  }, [])
  const [request_custom_shifts, requestCustomShifts] = useBooleanState(false)

  const available_actions = useWorkedShiftActions()

  const filter_start_time = businessStart(report_filters.start_time)
  const filter_end_time = businessEnd(report_filters.end_time)

  const lock_threshold = reportLockThreshold(
    selected_location_id,
    report_filters.start_time
  )
  const onChaneActions = value => {
    switch (value) {
      case ACTION_CUSTOM_SHIFT:
        requestCustomShifts(true)
        break
      default:
        break
    }
  }

  return (
    <>
      <AlertGroup>
        <MissedClockoutsAlert
          min_time={filter_start_time}
          max_time={filter_end_time}
        />
      </AlertGroup>
      <Query
        fetchPolicy="network-only"
        query={FILTERED_WORKED_SHIFTS}
        variables={{
          start_time: filter_start_time,
          end_time: filter_end_time,
          store_id: selected_location_id,
          employee_id: report_filters.employee
            ? toInt(report_filters.employee.id)
            : null,
          job_code_id: report_filters.job_code
            ? toInt(report_filters.job_code.id)
            : null,
          page: report_filters.page,
          page_size: report_filters.per_page,
          sort_by: report_filters.sort_field,
          sort_dir: report_filters.sort_direction
        }}
      >
        {({ loading, data, refetch }) => (
          <>
            <ShiftsFilterBar
              presets={presets}
              loading={!!loading}
              filters={report_filters}
              setFilters={({ location = undefined, ...filters }) => {
                const filter_location_id = location?.id
                  ? Number(location.id)
                  : null
                if (
                  location !== undefined &&
                  filter_location_id !== selected_location_id
                ) {
                  changeSelectedLocation(filter_location_id)
                }
                return setReportFilters({ ...filters, page: 1 })
              }}
            />
            {!loading && !!data && (
              <>
                {data.filteredWorkedShifts.total === 0 && (
                  <>
                    <StandaloneActions
                      options={available_actions}
                      onChange={onChaneActions}
                    />
                    <NoResultsMessage />
                  </>
                )}
                {data.filteredWorkedShifts.total > 0 && (
                  <ShiftsTable
                    id="report-table-worked-shifts"
                    user={user}
                    refetch={refetch}
                    compact_view={compact_view}
                    toast={toast}
                    lock_threshold={lock_threshold}
                    reportLockThreshold={reportLockThreshold}
                    selected_location_id={selected_location_id}
                    worked_shifts={data.filteredWorkedShifts.workedShifts}
                    controls={{
                      position:
                        data.filteredWorkedShifts.total >
                        report_filters.per_page
                          ? "both"
                          : "top",
                      custom_controls_position: "top",
                      pagination: {
                        current_page: report_filters.page,
                        per_page: report_filters.per_page,
                        total: data.filteredWorkedShifts.total,
                        include_total: true,
                        changePage: page => setReportFilters({ page }),
                        changePerPage: per_page => {
                          setReportFilters({ page: 1, per_page })
                        },
                        options: {
                          per_page: [25, 50, 100, 500]
                        }
                      },
                      custom: [
                        <>
                          <ExportButton
                            filters={{
                              location: {
                                id: selected_location_id,
                                name: selected_location_name
                              },
                              ...report_filters
                            }}
                            onDownloadStart={() => {
                              toast({
                                type: "success",
                                message: "Report download started."
                              })
                            }}
                          />
                          <ToggleCompactViewBtn
                            compact_view={compact_view}
                            onToggle={toggleCompactViewSetting}
                          />
                          <Actions
                            options={available_actions}
                            onChange={onChaneActions}
                          />
                        </>
                      ]
                    }}
                  />
                )}
                {!!request_custom_shifts && (
                  <CustomShifts
                    onClose={() => requestCustomShifts(false)}
                    toast={toast}
                  />
                )}
              </>
            )}
          </>
        )}
      </Query>
    </>
  )
}

const Actions = ({ options, onChange }) => {
  return options.length === 0 ? (
    <></> // render nothing when there are no options
  ) : (
    <ActionsDropdown options={options} onChange={onChange} />
  )
}

const StandaloneActions = ({ options, ...props }) =>
  options.length === 0 ? (
    <></> // render nothing when there are no options
  ) : (
    <div>
      <Actions options={options} {...props} />
    </div>
  )
