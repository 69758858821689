import { Component } from "react"

import moment from "moment"

import Sales from "./Sales"
import { RouteTabs } from "../Tabs"
import PeerToPeer from "./PeerToPeer"
import WorkedShifts from "./WorkedShifts"
import ReportExports from "./ReportExports"
import BreakdownSnapshots from "./BreakdownSnapshots"
import RouteContent, { ResyncControl } from "../RouteContent"

import tour from "./tour"
import { paths } from "../../constants"
import { generateFilterPresets } from "./helpers"
import {
  useBusinessDates,
  useReportLockThreshold,
  useNoneJobCodeSalesNotificationsEnabled,
  useExcessiveTipNotificationsEnabled
} from "../../hooks/selectors"

import {
  SALES_TAB,
  BREAKDOWN_TAB,
  REPORTING_TABS,
  PEER_TO_PEER_TAB,
  WORKED_SHIFTS_TAB,
  REPORT_EXPORTS_TAB,
  DEFUALT_REPORT_FILTERS,
  REPORTING_FILTER_NAMESPACE,
  visibleTabsForUser,
  reportFiltersNamespace
} from "./helpers"

export { default as LinkToReporting } from "./Link"

// QUICK FIX for migrating away from global helpers for
// business dates and report lock threshold
export default props => {
  const bizDates = useBusinessDates()
  const none_job_code_notifications_enabled = useNoneJobCodeSalesNotificationsEnabled()
  const excessive_tip_notification_enabled = useExcessiveTipNotificationsEnabled()
  const reportLockThreshold = useReportLockThreshold()
  return (
    <ReportIndex
      {...props}
      {...bizDates}
      none_job_codes_notifications={none_job_code_notifications_enabled}
      excessive_tip_notifications={excessive_tip_notification_enabled}
      reportLockThreshold={reportLockThreshold}
    />
  )
}

// Reporting Index component
class ReportIndex extends Component {
  state = {
    selected_tab: null
  }

  componentDidMount() {
    this.initializeSelectedTab()
  }

  initializeSelectedTab = () => {
    const pathname = this.props.location.pathname
    const tab_key = pathname
      .split("/")
      .filter(token => token.length)
      .pop()
    let selected_tab = REPORTING_TABS.map(({ path }) => path).find(
      tab => tab === tab_key
    )

    if (!selected_tab) {
      selected_tab = SALES_TAB
      window.history.pushState(
        { [SALES_TAB]: true },
        SALES_TAB,
        `${paths.report}/${SALES_TAB}`
      )
    }

    this.setState({ selected_tab })
  }

  generateFilterPresets = tab => {
    const { user, businessDate } = this.props
    const today = businessDate()
    const yesterday = businessDate(moment().subtract(1, "d"))
    const { start_time, end_time } = this.getGlobalReportFilters()
    const { current_pay_period, last_pay_period } = user.organization
    const start_date = businessDate(start_time)
    const end_date = businessDate(end_time)

    return generateFilterPresets({
      filter_start_date: start_date,
      filter_end_date: end_date,
      today,
      yesterday,
      current_pay_period,
      last_pay_period,
      applyPreset: (start_time, end_time) =>
        this.setScopedReportFilters(tab, {
          page: 1,
          start_time: this.props.businessStartFromDate(start_time),
          end_time: this.props.businessStartFromDate(end_time)
        })
    })
  }

  getGlobalReportFilters = () => ({
    start_time: this.props.businessStart(),
    end_time: this.props.businessStart(),
    ...(this.props.app_filters[REPORTING_FILTER_NAMESPACE] ?? {})
  })

  getScopedReportFilters = report_tab => ({
    ...this.getGlobalReportFilters(),
    ...DEFUALT_REPORT_FILTERS,
    ...(this.props.app_filters[reportFiltersNamespace(report_tab)] ?? {})
  })

  setGlobalReportFilters = (filters, merge = true) => {
    const current = this.getGlobalReportFilters()
    if (
      filters.start_time !== current.start_time ||
      filters.end_time !== current.end_time
    ) {
      this.props.setFiltersForNamespace(
        REPORTING_FILTER_NAMESPACE,
        filters,
        merge
      )
    }
  }

  setScopedReportFilters = (report_tab, filters, merge = true) => {
    const { start_time, end_time, ...scoped_filters } = filters
    const current_filters = this.getGlobalReportFilters()
    // start_time and end_time are managed through the global reporting namespace
    this.setGlobalReportFilters({
      start_time: start_time ?? current_filters.start_time,
      end_time: end_time ?? current_filters.end_time
    })
    // remaining filters are isolated to their corresponding page
    if (Object.keys(scoped_filters).length > 0) {
      this.props.setFiltersForNamespace(
        reportFiltersNamespace(report_tab),
        { page: 1, ...scoped_filters },
        merge
      )
    }
  }

  clearScopedReportFilters = report_tab =>
    this.props.clearReportFilters(reportFiltersNamespace(report_tab))

  render() {
    const {
      user,
      setRoutesVisited,
      none_job_codes_notifications,
      excessive_tip_notifications
    } = this.props
    const { selected_tab } = this.state
    let visible_tabs = visibleTabsForUser(user, selected_tab)

    return (
      <>
        <RouteContent
          tour={tour}
          header="Reporting"
          route_name="reporting"
          setRoutesVisited={setRoutesVisited}
          controls={[<ResyncControl key="resync" toast={this.props.toast} />]}
        >
          <RouteTabs
            tabs={visible_tabs}
            navigation_path={paths.report}
            onChange={tab => this.setState({ selected_tab: tab.id })}
          >
            {selected_tab === SALES_TAB && (
              <Sales
                {...this.props}
                presets={this.generateFilterPresets(SALES_TAB)}
                none_job_codes_notifications={none_job_codes_notifications}
                excessive_tip_notifications={excessive_tip_notifications}
                report_filters={this.getScopedReportFilters(SALES_TAB)}
                setReportFilters={(filters, merge = true) =>
                  this.setScopedReportFilters(SALES_TAB, filters, merge)
                }
                clearReportFilters={() =>
                  this.clearScopedReportFilters(SALES_TAB)
                }
              />
            )}
            {selected_tab === WORKED_SHIFTS_TAB && (
              <WorkedShifts
                {...this.props}
                presets={this.generateFilterPresets(WORKED_SHIFTS_TAB)}
                report_filters={this.getScopedReportFilters(WORKED_SHIFTS_TAB)}
                setReportFilters={(filters, merge = true) =>
                  this.setScopedReportFilters(WORKED_SHIFTS_TAB, filters, merge)
                }
                clearReportFilters={() =>
                  this.clearScopedReportFilters(SALES_TAB)
                }
              />
            )}
            {selected_tab === BREAKDOWN_TAB && (
              <BreakdownSnapshots {...this.props} />
            )}
            {selected_tab === REPORT_EXPORTS_TAB && (
              <ReportExports
                {...this.props}
                presets={this.generateFilterPresets(REPORT_EXPORTS_TAB)}
                report_filters={this.getScopedReportFilters(REPORT_EXPORTS_TAB)}
                setReportFilters={(filters, merge = true) =>
                  this.setScopedReportFilters(
                    REPORT_EXPORTS_TAB,
                    filters,
                    merge
                  )
                }
                clearReportFilters={() =>
                  this.clearScopedReportFilters(SALES_TAB)
                }
              />
            )}
            {selected_tab === PEER_TO_PEER_TAB && (
              <PeerToPeer
                {...this.props}
                presets={this.generateFilterPresets(PEER_TO_PEER_TAB)}
                report_filters={this.getScopedReportFilters(PEER_TO_PEER_TAB)}
                setReportFilters={(filters, merge = true) =>
                  this.setScopedReportFilters(PEER_TO_PEER_TAB, filters, merge)
                }
                clearReportFilters={() =>
                  this.clearScopedReportFilters(SALES_TAB)
                }
              />
            )}
          </RouteTabs>
        </RouteContent>
      </>
    )
  }
}
