import moment from "moment"
import Styled from "styled-components"
import Table from "../../Shared/Table"

import { Link, Modal } from "../../Shared"
import { LinkToBreakdown } from "../../Breakdown"

import { round, displayMoney } from "../../../helpers/number"
import { colors, paths, month_day_format } from "../../../constants"
import useAuthorization from "../../../hooks/authorization"
import { settings_manage_job_codes } from "../../../constants/permissions"

const Description = Styled.div`
  font-size: 1.15rem;
  font-weight: 300;
  color: ${colors.dark2};
  margin-bottom: 0.67rem;
`
const SummaryTable = Styled(({ className, ...props }) => (
  <div className={className}>
    <Table {...props} />
  </div>
))`
  max-height: 50vh;
  overflow-y: auto;
  margin: 1.5rem 0;
`

export default ({ onClose, job_codes }) => {
  const { hasPermission } = useAuthorization()
  const first_day = moment(job_codes[0].window_start).format("MMMM Do")
  const last_day = moment(job_codes[job_codes.length - 1].window_start).format(
    "MMMM Do"
  )

  return (
    <Modal size="small" onClose={onClose}>
      <Modal.Header onClose={onClose}>Invalid Take Home Found</Modal.Header>
      <Modal.Content>
        <Description>
          {job_codes.length} Job Code{job_codes.length > 1 && `s`}{" "}
          {job_codes.length === 1 ? "was" : "were"} found with invalid take home{" "}
          {first_day === last_day && <>on {first_day}</>}
          {first_day !== last_day && (
            <>
              between {first_day} and {last_day}
            </>
          )}
          .
        </Description>
        <SummaryTable compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Employee</Table.HeaderCell>
              <Table.HeaderCell>Job Code</Table.HeaderCell>
              <Table.HeaderCell>Location</Table.HeaderCell>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Take Home</Table.HeaderCell>
              <Table.HeaderCell collapsing />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {[...job_codes].reverse().map(job_code => {
              const { window_start, employee } = job_code
              return (
                <Table.Row
                  key={`${job_code.employee.id}-${job_code.window_start}`}
                >
                  <Table.Cell>
                    {job_code.employee.first_name} {job_code.employee.last_name}
                  </Table.Cell>
                  <Table.Cell>{job_code.jobCode.name}</Table.Cell>
                  <Table.Cell>{job_code.store.name}</Table.Cell>
                  <Table.Cell>
                    {moment(job_code.window_start).format(
                      "ddd, M/D/YY [at] h:mma"
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {displayMoney(round(job_code.take_home, 2))}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    <LinkToBreakdown
                      filters={{ window_start, employee }}
                      onClick={onClose}
                    />
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </SummaryTable>
        <Description>
          Take home for the wrong job code will impact tip distribution
          calculations. To ensure calculations are correct, review job codes
          with invalid take home.
        </Description>
        {hasPermission(settings_manage_job_codes) && (
          <Description>
            You can visit the locations job code settings to review and update
            take home for job codes.{" "}
            <Link target="_blank" href={paths.settingsJobCodes}>
              Job Code Settings
            </Link>
          </Description>
        )}
      </Modal.Content>
    </Modal>
  )
}
