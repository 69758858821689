import DatePicker from "../../../DatePicker"

import { compatibleDateObject } from "../../../../helpers/datetime"
import { FilterWrapper, DatePickerWrapper } from "../Styled"

export default ({ label, value, min_date, max_date, onChange }) => (
  <FilterWrapper label={label}>
    <DatePickerWrapper>
      <DatePicker
        onChange={onChange}
        value={compatibleDateObject(value)}
        minDate={compatibleDateObject(min_date)}
        maxDate={compatibleDateObject(max_date)}
      />
    </DatePickerWrapper>
  </FilterWrapper>
)
