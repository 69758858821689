import React from "react"
import Styled from "styled-components"

import Message from "./../../Message"
import Link from "./../../Link"

import { colors, paths } from "./../../../constants"
import useAuthorization from "../../../hooks/authorization"
import { useNavigateTo } from "../../../hooks"
import { settings_manage_peer_to_peer } from "../../../constants/permissions"

const FeatureDisabledWrapper = Styled.div``
const StyledMessage = Styled(Message)`
  margin-bottom: 1.33rem;
  border: 1px solid ${colors.light5};
`
const Line = Styled.div`
  margin-top: 1rem;
`

export default () => {
  const { hasPermission } = useAuthorization()
  const can_edit = hasPermission(settings_manage_peer_to_peer)
  const navigateTo = useNavigateTo()
  return (
    <FeatureDisabledWrapper>
      <StyledMessage
        type="info"
        inline
        title="Peer-to-Peer Tip Sharing Inactive"
      >
        <Line>
          You're seeing this message because Peer-to-Peer Tip Sharing is not
          currently enabled for your organization.
        </Line>
        {can_edit && (
          <Line>
            You can activate tip sharing from the{" "}
            <Link onClick={() => navigateTo(paths.settingsPeerToPeer)}>
              settings page
            </Link>
            .
          </Line>
        )}
        {!can_edit && (
          <Line>
            Organization admin users can activate tip sharing by logging in and
            navigating to Settings > Peer-to-Peer.
          </Line>
        )}
        <Line>
          If you'd like to learn more about Peer-to-Peer Tip Sharing,{" "}
          <Link onClick={() => navigateTo(paths.contact)}>let us know!</Link>
        </Line>
      </StyledMessage>
    </FeatureDisabledWrapper>
  )
}
