import { colors } from "../../constants"

export const chart_colors = [
  colors.purple,
  colors.info,
  colors.green,
  colors.blue,
  colors.warning
]

export const colorByIndex = idx => {
  if (idx < chart_colors.length) {
    return chart_colors[idx]
  }
  return chart_colors[idx % chart_colors.length]
}
