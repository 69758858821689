import { useState } from "react"

import Icon from "../../../Icon"
import { SearchLink } from "./Styled"
import AdvancedSearchModal from "./Filters/Modal"

import { sortIsDefault } from "./Filters/SortBy"
import { useUser } from "../../../../hooks/selectors"

const FILTERS_TEMPLATE = {
  location: null,
  employee: null,
  job_code: null,
  sort_field: null,
  sort_direction: null
}

export default ({ applied_filters, onSubmit }) => {
  const user = useUser()
  const [filters, setFilters] = useState(null)

  return (
    <>
      <SearchLink
        onClick={() =>
          setFilters(
            // whitelist filter keys to those included in template
            Object.keys(FILTERS_TEMPLATE).reduce(
              (acc, field) => ({
                ...acc,
                [field]: applied_filters[field] ?? FILTERS_TEMPLATE[field]
              }),
              {}
            )
          )
        }
      >
        <Icon name="search plus" /> More Filters
      </SearchLink>
      {!!filters && (
        <AdvancedSearchModal
          user={user}
          filters={filters}
          setFilters={setFilters}
          resetFilters={
            hasDefaultFilters(user, filters)
              ? null
              : () => {
                  setFilters({
                    ...FILTERS_TEMPLATE,
                    location: null
                  })
                }
          }
          onSubmit={() => {
            // const { location, ...rest } = filters
            onSubmit(filters)
            setFilters(null)
          }}
          onClose={() => setFilters(null)}
        />
      )}
    </>
  )
}

const hasDefaultFilters = (user, filters) =>
  sortIsDefault(filters.sort_field, filters.sort_direction) &&
  Object.keys(FILTERS_TEMPLATE)
    // ignore location filter
    .filter(key => key !== "location")
    // sort fields are handled by the sortIsDefault function
    .filter(key => !key.startsWith("sort_"))
    .reduce((acc, key) => acc && filters[key] === FILTERS_TEMPLATE[key], true)
