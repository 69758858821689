import React from "react"

import moment from "moment"
import { Form } from "semantic-ui-react"

import { Paragraph } from "../Styled"
import Dropdown from "../../../Dropdown"
import DatePicker from "../../../DatePicker"

import { date_format } from "../../../../constants"
import { useBusinessDates } from "../../../../hooks"
import { ucwords } from "../../../../helpers/string"
import { compatibleDateObject } from "../../../../helpers/datetime"

const INTERVAL_WEEKLY = "weekly"
const INTERVAL_BI_WEEKLY = "bi-weekly"
const INTERVAL_SEMI_MONTHLY = "semi-monthly"
const PAY_PERIOD_INTERVALS = [
  INTERVAL_WEEKLY,
  INTERVAL_BI_WEEKLY,
  INTERVAL_SEMI_MONTHLY
]

const SEMI_MONTHLY_INTERVAL_DAYS = 15

export const STEP_PAY_PERIOD = "pay-period"

export default ({
  interval,
  reference_date,
  onChangeInterval,
  onChangeReferenceDate
}) => {
  const { businessDate } = useBusinessDates()

  return (
    <>
      <Paragraph>
        Alright, now let's set up your organization's pay period.
      </Paragraph>
      <Paragraph>
        We'll reference your pay period to enhance reporting.
      </Paragraph>
      <Form>
        <Form.Field>
          <label>Pay Period Interval</label>
          <Form.Dropdown
            inline
            selection
            value={!!interval ? interval : 0}
            options={[
              { text: "Select One", value: 0 },
              ...PAY_PERIOD_INTERVALS.map(interval => ({
                text: ucwords(interval),
                value: interval
              }))
            ]}
            onChange={(e, { value }) => {
              onChangeInterval(value)
              if (value === INTERVAL_SEMI_MONTHLY) {
                onChangeReferenceDate(
                  moment().startOf("month").format(date_format)
                )
              }
            }}
          />
        </Form.Field>
        {[INTERVAL_WEEKLY, INTERVAL_BI_WEEKLY].includes(interval) && (
          <Form.Field
            label="Start Date of your Current Pay Period"
            control={() => (
              <DatePicker
                onChange={e =>
                  onChangeReferenceDate(moment(e).format(date_format))
                }
                value={compatibleDateObject(
                  `${reference_date ?? businessDate()} 00:00:00`
                )}
                minDate={compatibleDateObject(
                  `${moment(businessDate())
                    .subtract(interval === INTERVAL_WEEKLY ? 6 : 13, "d")
                    .format(date_format)} 00:00:00`
                )}
                maxDate={compatibleDateObject(
                  `${moment(businessDate()).format(date_format)} 00:00:00`
                )}
              />
            )}
          />
        )}
      </Form>
      {interval === INTERVAL_SEMI_MONTHLY && (
        <Paragraph force_margin thin>
          Semi-monthly pay periods start on the{" "}
          <Dropdown
            inline
            scrolling
            value={moment(reference_date).date()}
            options={[...Array(13).keys()].map(idx => ({
              value: idx + 1,
              text: moment.localeData().ordinal(idx + 1)
            }))}
            onChange={(e, { value }) =>
              onChangeReferenceDate(moment().date(value).format(date_format))
            }
          />{" "}
          and{" "}
          {moment
            .localeData()
            .ordinal(
              moment(reference_date).date() + SEMI_MONTHLY_INTERVAL_DAYS
            )}{" "}
          of each month.
        </Paragraph>
      )}
    </>
  )
}
