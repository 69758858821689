import React, { Component } from "react"

import ChartModal from "./ChartModal"
import Leaderboard from "./Leaderboard"
import {
  StatWrapper,
  MetricHeader,
  MetricSegment,
  MetricDisabled,
  MetricStatistic,
  TertiaryStatistic
} from "./Styled"

import { displayNumber } from "../../../helpers/number"
import { hashify } from "../../../helpers"

const LOCALSTORAGE_KEY_PREFIX = "dash_chart_settings"

export default class extends Component {
  state = {
    show_all: false,
    show_chart: false,
    edit_mode: false,
    hidden_result_rows: [],
    mounted: false
  }

  componentDidMount() {
    this.initializeLeaderboardSettings()
    window.addEventListener("keydown", this.exitEditMode)
  }
  componentWillUnmount() {
    window.removeEventListener("keydown", this.exitEditMode)
  }

  exitEditMode = ({ key }) => {
    if (key === "Escape" && !!this.state.edit_mode) {
      this.setState({ edit_mode: false })
    }
  }

  initializeLeaderboardSettings = () => {
    let settings = localStorage.getItem(this.userSettingsKey())
    let hidden_result_rows = []
    if (!!settings) {
      try {
        settings = JSON.parse(settings)
        // hesitant to change the name of the setting as that
        // will cause users to lose data (without some auto-migrate code)
        hidden_result_rows = [...settings.hidden_employee_ids]
      } catch (e) {
        //reset table settings
        localStorage.setItem(
          this.userSettingsKey(),
          JSON.stringify({ hidden_result_rows: [] })
        )
      }
    } else {
      localStorage.setItem(
        this.userSettingsKey(),
        JSON.stringify({ hidden_result_rows: [] })
      )
    }
    requestAnimationFrame(() =>
      this.setState({
        hidden_result_rows,
        mounted: true
      })
    )
  }

  userSettingsKey = () =>
    `${LOCALSTORAGE_KEY_PREFIX}.${this.props.name
      .toLowerCase()
      .replace(/\s/g, "_")}.${hashify(this.props.user.name)}`

  shouldDisplayResult = result_key =>
    this.state.hidden_result_rows !== null &&
    (!this.state.hidden_result_rows.includes(result_key) ||
      !!this.state.edit_mode)

  toggleResultSetting = result_key => {
    const { hidden_result_rows } = this.state
    let updated_hidden_ids = []
    if (hidden_result_rows.includes(result_key)) {
      updated_hidden_ids = hidden_result_rows.filter(id => id !== result_key)
    } else {
      updated_hidden_ids = [...hidden_result_rows, result_key]
    }
    this.setState({ hidden_result_rows: [...updated_hidden_ids] })
    localStorage.setItem(
      this.userSettingsKey(),
      JSON.stringify({ hidden_employee_ids: updated_hidden_ids })
    )
  }

  aggregate = () =>
    this.props.aggregator(
      this.props.totals
        .filter(total => total.total > 0)
        .filter(
          ({ result_key }) =>
            !this.state.hidden_result_rows.includes(result_key)
        )
    )

  leaders = () =>
    this.props.totals
      .filter(total => total.total > 0)
      .filter(leader => this.shouldDisplayResult(leader.result_key))
      .sort((a, b) => b.total - a.total)

  render() {
    const {
      show_all,
      show_chart,
      edit_mode,
      hidden_result_rows,
      mounted
    } = this.state
    const {
      start,
      end,
      name,
      description,
      type,
      label,
      removeSetting,
      moveBack,
      user,
      disabled_msg = false
    } = this.props

    let value = this.aggregate()
    return (
      <MetricSegment className="landing-metric-panel">
        <MetricHeader
          name={name}
          moveBack={moveBack}
          remove={removeSetting}
          description={description}
          showChart={() => this.setState({ show_chart: true })}
        />
        {!!disabled_msg && <MetricDisabled msg={disabled_msg} />}
        {!disabled_msg && (
          <>
            <StatWrapper>
              {!isNaN(value) && (
                <MetricStatistic horizontal size="large">
                  <MetricStatistic.Value>
                    {type == "money" && <>${displayNumber(value, 2)}</>}
                    {type == "percent" && <>{displayNumber(value, 1)}%</>}
                    {type == "number" && <>{displayNumber(value)}</>}
                    {type == "float" && <>{displayNumber(value, 2)}</>}
                  </MetricStatistic.Value>
                  {!!label && (
                    <MetricStatistic.Label>{label}</MetricStatistic.Label>
                  )}
                </MetricStatistic>
              )}
              {!!isNaN(value) && <TertiaryStatistic>###</TertiaryStatistic>}
            </StatWrapper>
            {this.leaders().length > 0 && (
              <Leaderboard
                name={name}
                start={start}
                end={end}
                mounted={mounted}
                type={type}
                show={5}
                user_id={user.id}
                leaders={this.leaders()}
                show_all={show_all}
                edit_mode={edit_mode}
                hidden_result_rows={hidden_result_rows}
                toggleEditMode={() => this.setState({ edit_mode: !edit_mode })}
                toggleResultSetting={this.toggleResultSetting}
                toggleShowAll={() => this.setState({ show_all: !show_all })}
                hide_qualifier={user.stores.length === 1}
              />
            )}
            {!!show_chart && (
              <ChartModal
                onClose={() => this.setState({ show_chart: false })}
                title={name}
                leaders={this.leaders().map(leader => ({
                  ...leader,
                  hidden: hidden_result_rows.includes(leader.result_key)
                }))}
              />
            )}
          </>
        )}
      </MetricSegment>
    )
  }
}
