import Styled from "styled-components"
import { colors, style } from "../../../../../../constants"

const AccountDetailCard = ({ person, ...props }) => (
  <Card
    {...props}
    header={
      <>
        {person.first_name} {person.last_name}
      </>
    }
    content={
      <>
        <div>{person.address_line_1}</div>
        {!!person.address_line_2?.length && <div>{person.address_line_2}</div>}
        <div>
          {person.city}, {person.state} {person.postal_code}
        </div>
      </>
    }
  />
)

const Card = Styled(({ className, header, content }) => (
  <div className={className}>
    {!!header && <div className="verification-header">{header}</div>}
    {!!content && <div className="verification-content">{content}</div>}
  </div>
))`
  text-align: left;
  display: inline-block;
  border: 0.5px solid ${colors.light4};
  border-radius: ${style.border_radius};
  overflow: hidden;

  & > div.verification-header {
    font-weight: 600;
    font-size: 1.07em;
    padding: 0.4rem 1rem 0.33rem 1rem;
    background-color: ${colors.light};
    border-bottom: 0.5px solid ${colors.light3};
  }
  & > div.verification-content {
    background-color: ${colors.white};
    padding: 0.8em 1rem;
    font-weight: 300;
  }
`

export default AccountDetailCard
