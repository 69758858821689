import { useState } from "react"
import Styled from "styled-components"
import { Table } from "../../Shared"
import SearchRow from "./SearchRow"
import EmployeeRow from "./EmployeeRow"
import { SHOW_BATCH_CONTROLS_THRESHOLD, employeeSearch } from "../helpers"

const EmployeeInvitesTable = ({
  employees = [],
  included = [],
  onExclude,
  onEditRequest
}) => {
  const [search, setSearch] = useState("")
  const filtered_employees = employeeSearch(employees, search)
  return (
    <StyledTable>
      <Table fitted basic="very">
        <Table.Body>
          {employees.length > SHOW_BATCH_CONTROLS_THRESHOLD && (
            <SearchRow value={search} onChange={value => setSearch(value)} />
          )}
          {filtered_employees
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((employee, idx) => (
              <EmployeeRow
                key={employee.id ?? idx}
                employee={employee}
                excluded={!included.includes(employee.id)}
                onExclude={onExclude}
                onEditRequest={onEditRequest}
              />
            ))}
        </Table.Body>
      </Table>
    </StyledTable>
  )
}

const StyledTable = Styled.div`
  .table tr {
    > td:first-child {
      padding-left: 1.5rem !important;
    }
    > td:last-child {
      padding-right: 1.5rem !important;
    }  
  }
`

export default EmployeeInvitesTable
