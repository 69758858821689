import { useState } from "react"

import moment from "moment"
import Styled from "styled-components"
import { Mutation } from "@apollo/client/react/components"

import { DataLockedModal } from "../../Shared/ReportLockedIndicator"
import {
  Form,
  Link,
  Modal,
  Button,
  Message,
  DatePicker,
  SubmitButton
} from "../../Shared"

import { DEACTIVATE_RULE_MUTATION } from "../../../graphql/mutations"
import { month_day_format, weekday_month_day_format } from "../../../constants"
import {
  toInt,
  ucwords,
  appMinDate,
  compatibleDateObject
} from "../../../helpers"
import {
  useUser,
  useEtaEnabled,
  useBusinessDates,
  useReportLockThreshold
} from "../../../hooks"

const DeactivationDateInput = Styled(
  ({
    value,
    min_deactivated_date,
    min_is_lock_threshold,
    onChange,
    className,
    show_min_date_description = true
  }) => {
    const [show_lock_threshold_modal, setLockThresholdModal] = useState(false)
    return (
      <Form className={className}>
        <Form.Field>
          <label>Deactivation Date</label>
          <DatePicker
            fluid
            onChange={onChange}
            format={weekday_month_day_format}
            value={compatibleDateObject(value)}
            minDate={compatibleDateObject(min_deactivated_date)}
            force_close={show_lock_threshold_modal}
            description={
              // show an info message if date selection is limited by closed pp.
              show_min_date_description && min_is_lock_threshold ? (
                <>
                  Days preceding {min_deactivated_date.format(month_day_format)}{" "}
                  are locked.{" "}
                  <Link onClick={() => setLockThresholdModal(true)}>
                    Learn more
                  </Link>
                  .
                </>
              ) : null
            }
          />
        </Form.Field>
        {show_lock_threshold_modal && (
          <DataLockedModal
            lock_threshold={min_deactivated_date}
            onClose={() => setLockThresholdModal(false)}
          />
        )}
      </Form>
    )
  }
)`
  margin: 1rem 0;
`
const ButtonsWrapper = Styled.div`
  margin-bottom: 1rem;
`

export default ({
  rule_id,
  location_id,
  rule_type = "rule",
  min_deactivated = null,
  allow_deactivate = true,
  onSuccess = () => {},
  onError = () => {},
  onClose = () => {}
}) => {
  const user = useUser()
  const eta_enabled = useEtaEnabled()
  const reportLockThreshold = useReportLockThreshold()
  const { businessStartFromDate } = useBusinessDates()
  const [delete_rule, setDeleteRule] = useState(
    !!allow_deactivate ? false : true
  )
  const app_min_date = appMinDate(null, user)

  const min_deactivated_date = min_deactivated
    ? moment.max([moment(min_deactivated), app_min_date]).startOf("day")
    : app_min_date

  const min_is_lock_threshold = reportLockThreshold(location_id)
    .startOf("day")
    .isSame(min_deactivated_date)

  const [deactivated_date, setDeactivatedDate] = useState(min_deactivated_date)

  const current_pay_period = user.organization?.current_pay_period
  const show_finalize_warning =
    current_pay_period?.end &&
    businessStartFromDate(current_pay_period.end, false).isSameOrAfter(
      deactivated_date
    )
  return (
    <Mutation
      mutation={DEACTIVATE_RULE_MUTATION}
      onCompleted={({ deactivateRule }) =>
        !!deactivateRule ? onSuccess(delete_rule) : onError(delete_rule)
      }
      onError={() => onError()}
    >
      {(deactivateRule, { loading }) => {
        return (
          <Modal size="mini" onClose={onClose} closeOnDimmerClick={false}>
            <Modal.Header
              icon="trash"
              onClose={onClose}
              content={`${
                !!allow_deactivate
                  ? !!delete_rule
                    ? "Delete"
                    : "Deactivate"
                  : "Delete"
              } ${ucwords(rule_type)}`}
            />
            <Modal.Content>
              {!!allow_deactivate && (
                <ButtonsWrapper>
                  <Button.Group widths={8} fluid>
                    <Button
                      negative={!delete_rule}
                      onClick={() => setDeleteRule(false)}
                    >
                      Deactivate
                    </Button>
                    <Button.Or />
                    <Button
                      negative={!!delete_rule}
                      onClick={() => setDeleteRule(true)}
                    >
                      Delete
                    </Button>
                  </Button.Group>
                </ButtonsWrapper>
              )}
              {!delete_rule && (
                <DeactivationDateInput
                  onChange={e =>
                    setDeactivatedDate(moment(e).format("YYYY-MM-DD"))
                  }
                  value={deactivated_date}
                  min_deactivated_date={min_deactivated_date}
                  min_is_lock_threshold={min_is_lock_threshold}
                  show_min_date_description={
                    !!user.organization.current_pay_period
                  }
                />
              )}
              <Message type="info">
                {!delete_rule && (
                  <>
                    {show_finalize_warning && eta_enabled && (
                      <p>
                        Deactivating this {rule_type} will affect your ETA
                        distribution. We recommend finalizing your pay period
                        before making any changes to your Tip Distribution.
                      </p>
                    )}
                    <p>
                      The deactivation date is the first day this {rule_type}{" "}
                      will no longer apply.
                    </p>
                  </>
                )}
                {!!delete_rule && (
                  <>
                    {eta_enabled && (
                      <p>
                        Deleting this {rule_type} will affect your ETA
                        distribution. We recommend finalizing your pay period
                        before making any changes to your Tip Distribution.
                      </p>
                    )}
                    <p>
                      When a {rule_type} is deleted, distributions are deleted
                      as well.
                    </p>
                  </>
                )}
                <p>
                  It may take a few minutes for the Breakdown to update after
                  you confirm.
                </p>
              </Message>
            </Modal.Content>
            <Modal.Actions>
              <Button disabled={!!loading} onClick={onClose}>
                Cancel
              </Button>
              <SubmitButton
                disabled={!!loading}
                loading={!!loading}
                onClick={() => {
                  let variables = {
                    id: toInt(rule_id),
                    deactivated_date: null
                  }
                  if (!delete_rule) {
                    variables.deactivated_date = businessStartFromDate(
                      deactivated_date
                    )
                  }
                  deactivateRule({ variables })
                }}
              />
            </Modal.Actions>
          </Modal>
        )
      }}
    </Mutation>
  )
}
