import Styled from "styled-components"
import {
  Button,
  Icon,
  Input,
  Message,
  MultiSelect,
  PageIntroduction
} from "../../../Shared"

export const UsersTableWrapper = Styled.div`
  & .table {
    position: relative;
    overflow-x: auto;
  }
`

export const NoResultsMessage = () => (
  <Message type="info">
    <Message.Header>No users found</Message.Header>
    <Message.Content>To add a user, click the button above.</Message.Content>
  </Message>
)

export const CreateUserButton = Styled(props => (
  <Button
    primary
    circular
    icon="plus"
    content="Create"
    floated="right"
    size="small"
    {...props}
  />
))``

export const PageIntro = Styled(props => (
  <PageIntroduction right_pad={false} {...props}>
    Active Admin and GM users can access this dashboard. GM users receive
    limited permissions.
  </PageIntroduction>
))`
  margin-bottom: 0 !important;
`

export const LoaderWrapper = Styled.div`
  margin: 1rem 0;
`

export const ControlWrapper = Styled.div`
  top: 0;
  right: 0;
  position: absolute;  
`

export const UserSearch = Styled(({ className, onClear, ...props }) => (
  <Input
    className={`${className}${!!props?.value?.length ? " valid" : ""}`}
    icon={
      !!props?.value?.length ? (
        <Icon name="remove" link onClick={onClear} />
      ) : (
        <Icon name="search" />
      )
    }
    debounce
    placeholder="Search"
    {...props}
  />
))`
  &.ui.input input {
    width: 8.5rem;
    transition: width 0.2s ease-in-out 0s;
  }
  &.ui.input input:focus,
  &.ui.input.valid input {
    width: 12.5rem;
  }
`

export const RolesDropdown = Styled(MultiSelect)`
  max-width: 30rem;
  min-width: 14rem;
  .ui.fluid.dropdown {
    display: flex;
    flex-wrap: wrap; 
  }
`
