import Styled from "styled-components"

import { Table } from "../../../Shared"
import { colors } from "../../../../constants"

export const ReviewRow = Styled(props => (
  <Table.Row {...props} hover={false} />
))`
  background-color: ${colors.white};
`

export const LabelCell = Styled(({ className, label }) => (
  <Table.Cell collapsing className={className}>
    <span>{label}:</span>
  </Table.Cell>
))`
  & > span {
    font-size: 0.93rem;
  }
`

export const ValueCell = props => <Table.Cell {...props} />
