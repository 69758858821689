import React, { useState } from "react"

import Table from "../../../../Table"
import { IconButton } from "../../../../Button"
import AreYouSure from "../../../../Modal/AreYouSure"

import { getRequest } from "../../../../../helpers/api"
import { useBusinessDates } from "../../../../../hooks/selectors"
import { weekday_month_day_format } from "../../../../../constants"
import { sortFieldLabel } from "../../AdvancedSearch/Filters/SortBy"

const requestExport = async (filters, setDownloading) => {
  setDownloading(true)
  // todo - use graphql instead of rest
  await getRequest(`reports/sales/all`, filters)
  setDownloading(false)
}

export default ({ filters, onDownloadStart }) => {
  const [request_download, setRequestDownload] = useState(false)
  const [downloading, setDownloading] = useState(false)

  const { businessStart, businessEnd } = useBusinessDates()

  const {
    location,
    start_time,
    end_time,
    employee,
    job_code,
    sort_field,
    distributions,
    sort_direction
  } = filters

  const sort_label = sortFieldLabel(sort_field)

  return (
    <>
      <IconButton
        secondary
        icon="cloud download"
        loading={!!downloading}
        disabled={!!downloading}
        onClick={() => setRequestDownload(true)}
      >
        Export
      </IconButton>
      {!!request_download && (
        <AreYouSure
          header="Sales Report Export"
          confirmText="Request Export"
          confirmIcon="mail"
          body={
            <>
              <p>
                A sales export will be generated and sent to your email address.
              </p>
              <p>The export will use the following filters:</p>
              {
                <Table basic="very" compact celled={false}>
                  <Table.Body>
                    {!!sort_label && (
                      <Table.Row>
                        <Table.Cell>
                          <strong>Sort By:</strong>
                        </Table.Cell>
                        <Table.Cell>{sort_label}</Table.Cell>
                      </Table.Row>
                    )}
                    {!!location?.name && (
                      <Table.Row>
                        <Table.Cell>
                          <strong>Location:</strong>
                        </Table.Cell>
                        <Table.Cell>{location.name}</Table.Cell>
                      </Table.Row>
                    )}
                    {!!start_time && (
                      <Table.Row>
                        <Table.Cell>
                          <strong>Start Date:</strong>
                        </Table.Cell>
                        <Table.Cell>
                          {businessStart(start_time, false).format(
                            weekday_month_day_format
                          )}
                        </Table.Cell>
                      </Table.Row>
                    )}
                    {!!end_time && (
                      <Table.Row>
                        <Table.Cell>
                          <strong>End Date:</strong>
                        </Table.Cell>
                        <Table.Cell>
                          {businessStart(end_time, false).format(
                            weekday_month_day_format
                          )}
                        </Table.Cell>
                      </Table.Row>
                    )}
                    {!!employee?.name && (
                      <Table.Row>
                        <Table.Cell>
                          <strong>Employee:</strong>
                        </Table.Cell>
                        <Table.Cell>{employee.name}</Table.Cell>
                      </Table.Row>
                    )}
                    {!!job_code?.name && (
                      <Table.Row>
                        <Table.Cell>
                          <strong>Job Code:</strong>
                        </Table.Cell>
                        <Table.Cell>{job_code.name}</Table.Cell>
                      </Table.Row>
                    )}
                    {!!distributions && (
                      <Table.Row>
                        <Table.Cell>
                          <strong>Distributions:</strong>
                        </Table.Cell>
                        <Table.Cell>{distributions?.name ?? "Any"}</Table.Cell>
                      </Table.Row>
                    )}
                    {distributions === false && (
                      <Table.Row>
                        <Table.Cell>
                          <strong>Distributions:</strong>
                        </Table.Cell>
                        <Table.Cell>None</Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              }
            </>
          }
          onConfirm={() => {
            onDownloadStart()
            requestExport(
              {
                start: businessStart(start_time),
                end: businessEnd(end_time),
                sort_dir: sort_direction,
                sort_by: sort_field,
                store_id: location?.id,
                employee_id: employee?.id,
                job_code_id: job_code?.id,
                has_distribution: !!distributions
                  ? 1
                  : distributions === false
                  ? 0
                  : null,
                distribution_rule_id: distributions?.id
              },
              setDownloading
            )
            setRequestDownload(false)
          }}
          onClose={() => setRequestDownload(false)}
        />
      )}
    </>
  )
}
