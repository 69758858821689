import Styled from "styled-components"

import { colors, style } from "../../constants"

export const LayoutWrapper = Styled(({ className, children }) => (
  <div className={className}>
    <div>{children}</div>
  </div>
))`
  position: fixed;
  height: 100vh;
  width: 100vw;
  overflow: auto;

  & *:not(i) {
    font-family: ${style.font} !important;
  }

  &::-webkit-scrollbar-thumb, & ::-webkit-scrollbar-thumb {
    border-radius: 0;
  }

  & .ui.active.loader {
    &::before {
      border-color: ${colors.light4};
    }
    &::after {
      border-color: ${colors.success} transparent transparent;
    }
  }

  & > div {
    position: relative;
    color: ${colors.dark};
    background-color: ${colors.light};
  }
`

export const FULL_PAGE_FADE_MS = 667
export const FullPage = Styled(
  ({ night_mode, fading, fade_ms, children, ...props }) => (
    <div {...props}>
      <div>
        <div>
          <div>{children}</div>
        </div>
      </div>
    </div>
  )
)`
  ${({ night_mode }) => (!night_mode ? "" : "filter: invert(0.85);")}
  background-color: ${colors.white};
  color: ${colors.dark2};
  z-index: 100000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  ${({ fade_ms = FULL_PAGE_FADE_MS, fading = false }) =>
    !fade_ms
      ? ``
      : `
    opacity: ${!fading ? 1 : 0};
    transition: opacity ${fade_ms}ms ease-out;
    pointer-events: ${!fading ? "auto" : "none"};
  `}

  & > div {
    display:table;
    width:100%;
    height:100%;

    & > div {
      display:table-cell;
      vertical-align:
      middle;

      & > div {
        margin-left:auto;
        margin-right:auto;
        text-align: center;
      }
    }
  }
`

export const WelcomeMessage = Styled(props => <div {...props}>Welcome</div>)`
  line-height: 3rem;
  font-size: 2.33rem;
  font-weight: 300;
  color: ${colors.dark3};
`

export const ContentWrapper = Styled.div`
  min-height: 100vh;
  position: relative;
  transition-property: margin;
  transition-duration: ${style.sidebar.animation_duration};
  transition-timing-function: ease-in-out;
  min-width: 300px;

  @media (min-width: ${style.mobile_breakpoint}px) {
    margin-left: ${({ expanded }) =>
      !!expanded ? style.sidebar.expanded : style.sidebar.collapsed};
  }
`

export const NightMode = Styled.div`
  ${props => props.night_mode && `filter: invert(0.8);`}
`
export const ToastsWrapper = Styled(({ children, className }) => (
  <div className={className}>{children}</div>
))`
  z-index: 100000;
  position: fixed;
  bottom: 3rem;
  right: ${({ intercom_enabled = true }) =>
    !!intercom_enabled ? "8" : "2"}rem;
  pointer-events: none;
  display: inline-block;
  max-width: calc(100% - 5rem);
`
