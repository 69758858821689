import Styled from "styled-components"

import { colors, style } from "../../../constants"

const border_radius = style.border_radius

export const CardWrapper = Styled(({ className, children }) => (
  <div className={className}>
    <div className="card">{children}</div>
  </div>
))`
  margin-bottom: ${({ fitted }) => (!fitted ? "2rem" : "0")};
  display: ${({ inline }) => (!!inline ? "inline-block" : "block")};

  & > div {
    position: relative;
    border: 1px solid ${colors.table_border};
    border-radius: ${border_radius};
    display: ${({ fluid }) => (!fluid ? "inline-block" : "block")};
    box-shadow: 0 1px 2px ${colors.light6};

    & > div:first-child {
      border-radius: ${border_radius} ${border_radius} 0 0;
    }

    & > div:last-child {
      border-radius: 0 0 ${border_radius} ${border_radius};
    }
  }
`

export const CardHeader = Styled(({ className, ...props }) => (
  <div className={`${className} card-header`} {...props} />
))`
  padding: 1rem;

  background: ${colors.light};
  border-bottom: 0.5px solid ${colors.table_border};
  font-size: 1.33rem;
  font-weight: 400;
  line-height: 2rem;
`

export const CardContent = Styled(({ className, ...props }) => (
  <div className={`${className} card-content`} {...props} />
))`
  padding: 1rem;
  background: ${colors.white};
`
