import { useState } from "react"

import Table from "../../../Table"
import PayoutDetailModal from "../Modal/PayoutDetail"
import {
  StatusFailed,
  DateTimeLabel,
  PayPeriodLabel,
  StatusSuccessful,
  StatusInProgress,
  StatusPartialSuccess,
  EscrowAmount,
  PayoutLocationsPopup
} from "./Styled"

import { payoutInitiatedBy, payoutLocationNames } from "../../helpers"
import { displayNumber } from "../../../../helpers/number"
import { useEtaConfigSetting } from "../../../../hooks"

export default ({
  payout,
  show_locations,
  show_failed_payout_modal = null
}) => {
  const [show_modal, setShowModal] = useState(
    show_failed_payout_modal == 1 ? true : false
  )
  const {
    user,
    payPeriod,
    created_at,
    completed_at,
    total_amount,
    total_fees,
    total_amount_unsuccessful,
    employeePayouts: payouts
  } = payout
  const failed = payouts.reduce((acc, { failed }) => acc && failed, true)
  const show_fees = useEtaConfigSetting("hausdirect_enabled", false)
  const payout_locations = payoutLocationNames(payouts)
  return (
    <>
      <Table.Row onClick={() => setShowModal(true)}>
        <Table.Cell>{payoutInitiatedBy(user)}</Table.Cell>
        {show_locations && (
          <Table.Cell>
            {/* display first location name */}
            {payout_locations[0] ?? ""}
            {/* only show popup if more than one location since we always display the first */}
            {payout_locations.length > 1 && (
              <PayoutLocationsPopup payout_locations={payout_locations} />
            )}
          </Table.Cell>
        )}
        <Table.Cell>
          {total_amount === 0 && "0.00"}
          {total_amount > 0 && displayNumber(total_amount, 2)}
          {total_amount_unsuccessful > 0 && (
            <EscrowAmount
              total_amount_unsuccessful={total_amount_unsuccessful}
            />
          )}
        </Table.Cell>
        {show_fees && (
          <Table.Cell>
            {total_fees === 0 && "-"}
            {total_fees > 0 && displayNumber(total_fees, 2)}
          </Table.Cell>
        )}
        <Table.Cell>
          {!!payPeriod && <PayPeriodLabel pay_period={payPeriod} />}
        </Table.Cell>
        <Table.Cell>
          <DateTimeLabel value={created_at} />
        </Table.Cell>
        <Table.Cell>
          {!!completed_at && <DateTimeLabel value={completed_at} />}
          {!completed_at && "-"}
        </Table.Cell>
        <Table.Cell>
          {!!failed && <StatusFailed />}
          {!failed && (
            <>
              {!completed_at && <StatusInProgress />}
              {!!completed_at && (
                <>
                  {!payouts.find(({ failed }) => !!failed) && (
                    <StatusSuccessful />
                  )}
                  {!!payouts.find(({ failed }) => !!failed) && (
                    <StatusPartialSuccess />
                  )}
                </>
              )}
            </>
          )}
        </Table.Cell>
      </Table.Row>
      {!!show_modal && (
        <PayoutDetailModal
          payout={payout}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  )
}
