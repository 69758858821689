import { useQuery } from "@apollo/client"
import { useCallback, useMemo } from "react"
import { paths } from "../../../../../../../constants"
import {
  entity_type_employee,
  entity_type_store
} from "../../../../../../../constants/role"
import { CLIENT_SETTINGS_USER_SEARCH } from "../../../../../../../graphql/queries"
import { isValidEmail, toInt } from "../../../../../../../helpers"
import { hasMobileAppLevelRole } from "../../../../../../../helpers/user"
import {
  useEtaEnabled,
  useNavigateTo,
  useUser
} from "../../../../../../../hooks"
import { useUserModals } from "../../../context/userModals"
import { userCanModifyEntityType } from "../../../helpers"

export const validEmail = (user, current_users) =>
  !!user.email &&
  isValidEmail(user.email) &&
  !current_users
    // if editing a user, don't validate email because it is read-only
    .filter(({ id }) => (user.id && id === user.id ? false : true))
    .map(u => u.email)
    .includes(user.email)

export const useRoleOptions = (client_roles, edit_user) => {
  const user = useUser()

  let visible_roles = client_roles.filter(role =>
    userCanModifyEntityType(user, role.entity_type)
  )
  // don't show mobile app role option when creating a user or if editing a non-mobile app user
  if (!edit_user || !hasMobileAppLevelRole(edit_user)) {
    visible_roles = visible_roles.filter(
      role => role.entity_type !== entity_type_employee
    )
  }

  return visible_roles.map(({ name, id }) => ({
    text: name,
    value: id
  }))
}

const findRoleById = (role_id, client_roles) =>
  client_roles.find(role => toInt(role.id) === toInt(role_id))

// get a list of locations that are assignable to a store level role
export const getAssignableLocationsForRole = (user, stores, client_roles) => {
  const role = findRoleById(user.role_id, client_roles)
  // get store ids assigned to store level role
  const assigned_store_ids = role.entities
    .filter(({ entity_type }) => entity_type === entity_type_store)
    .map(({ entity_id }) => toInt(entity_id))

  let assignable_stores = stores
  // if a store level role has assigned stores, only use those
  if (!!assigned_store_ids.length) {
    assignable_stores = assignable_stores.filter(store =>
      assigned_store_ids.includes(store.id)
    )
  }

  return assignable_stores.map(store => ({
    key: store.name,
    value: store.id,
    text: store.name
  }))
}

export const useSelectedRole = (role_id, client_roles) => {
  const selected_role = useMemo(
    () => (role_id ? findRoleById(role_id, client_roles) : null),
    [role_id, client_roles]
  )
  return selected_role
}

export const useShowEmployeeEtaMessage = edit_user => {
  const eta_enabled = useEtaEnabled()
  return eta_enabled && !!edit_user && hasMobileAppLevelRole(edit_user)
}

const storeIdArraysEqual = (a, b) => {
  const arrA = [...a].sort()
  const arrB = [...b].sort()
  return (
    arrA.length === arrB.length &&
    arrA.every((element, index) => element === arrB[index])
  )
}

export const hasUserRoleChanged = (edit_user, user) => {
  if (!!edit_user) {
    const { roleAssignments } = edit_user
    const current_role_id = roleAssignments[0]?.role?.id ?? null
    const current_store_ids = roleAssignments
      .filter(({ entity_type }) => entity_type === entity_type_store)
      .map(({ entity_id }) => toInt(entity_id))

    if (toInt(current_role_id) === toInt(user.role_id)) {
      if (roleAssignments[0]?.entity_type !== entity_type_store) {
        return false
      }
      if (storeIdArraysEqual(current_store_ids, user.store_ids)) {
        return false
      }
    }
  }
  return true
}

export const useNavigateToEditUser = () => {
  const current_user = useUser()
  const { setModal } = useUserModals()
  const navigateTo = useNavigateTo()

  const navigateToEditUser = existing_user => {
    if (toInt(existing_user.id) !== current_user.id) {
      setModal({
        user_modal: true,
        edit_user: existing_user
      })
    } else {
      navigateTo(paths.profile)
    }
  }

  return useCallback(existing_user => navigateToEditUser(existing_user), [
    navigateToEditUser
  ])
}

export const useCurrentUsers = () => {
  const { data } = useQuery(CLIENT_SETTINGS_USER_SEARCH, {
    fetchPolicy: "network-only",
    variables: {
      page: 1,
      first: 100000
    }
  })
  return data?.clientSettingsUserSearch?.data ?? []
}
