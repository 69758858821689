import React, { useState } from "react"
import Styled from "styled-components"

import Link from "../Link"
import Modal from "../Modal"
import Popup from "../Popup"
import Message from "../Message"
import Input from "../Shared/Input"
import Button, { SubmitButton } from "../Button"
import { DataLockedModal } from "../Shared/ReportLockedIndicator"

import { isNumber } from "../../helpers/number"
import { weekday_month_day_format } from "../../constants"
import { useReportLockThreshold } from "../../hooks/selectors"

const STORE_DEFAULT_VALUE = -1

const StyledMessage = Styled(Message)`
  margin-bottom: 1rem;
`

const inputPlaceholder = (cc_tip_fee, organization_default = false) => {
  if (cc_tip_fee && cc_tip_fee !== STORE_DEFAULT_VALUE) {
    return cc_tip_fee
  } else if (organization_default) {
    return organization_default
  }
  return "0"
}

const isValidInput = (value, existing_value) => {
  value = value.trim()

  return (
    value.length > 0 &&
    isNumber(value) &&
    parseFloat(value) !== existing_value && // value changed
    value.length < 8 && // reasonable precision
    parseFloat(value) >= 0 &&
    parseFloat(value) < 10
  )
}

export default ({
  onClose,
  onSubmit,
  submitted,
  store_id,
  cc_tip_fee,
  organization_default = false
}) => {
  const reportLockThreshold = useReportLockThreshold()

  const [tip_fee, setTipFee] = useState("")
  const [show_lock_detail, showLockDetail] = useState(false)

  const lock_threshold = reportLockThreshold(store_id)

  return (
    <>
      <Modal sticky size="tiny" onClose={onClose}>
        <Modal.Header onClose={onClose}>Edit Credit Card Tip Fee</Modal.Header>
        <Modal.Content>
          <StyledMessage
            type="info"
            message={
              <>
                <p>
                  CC tip fees are witheld when calculating total take home pay
                  for each employee.
                </p>
                {!!lock_threshold && (
                  <p>
                    After submit, we'll automatically reprocess your breakdown
                    back to {lock_threshold.format(weekday_month_day_format)}.
                    Data older than that is locked.{" "}
                    <Link onClick={() => showLockDetail(true)}>Learn more</Link>
                    .
                  </p>
                )}
                <p>
                  It may take a few minutes for updated totals to appear on the
                  breakdown after submitting.
                </p>
              </>
            }
          />
          <Input
            autoFocus={true}
            disabled={!!submitted}
            label="Tip Fee Percentage"
            onClick={e => e !== document.activeElement && e.target.focus()}
            fluid
            placeholder={inputPlaceholder(cc_tip_fee, organization_default)}
            icon="percent"
            value={tip_fee}
            onChange={(e, d) => setTipFee(d.value)}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose} disabled={!!submitted}>
            Close
          </Button>
          {tip_fee.trim().length === 0 &&
            cc_tip_fee !== STORE_DEFAULT_VALUE &&
            !!organization_default && (
              <Popup
                delay
                position="top right"
                content={
                  <>
                    Your organization's default tip fee will apply (
                    {organization_default}%).
                  </>
                }
                trigger={
                  <Button
                    onClick={() => onSubmit(STORE_DEFAULT_VALUE)}
                    negative
                    basic
                    disabled={!!submitted}
                    loading={!!submitted}
                  >
                    Remove Custom Tip Fee
                  </Button>
                }
              />
            )}
          <SubmitButton
            primary
            loading={!!submitted}
            disabled={!!submitted || !isValidInput(tip_fee, cc_tip_fee)}
            onClick={() => onSubmit(tip_fee)}
          />
        </Modal.Actions>
      </Modal>
      {show_lock_detail && (
        <DataLockedModal
          lock_threshold={lock_threshold}
          onClose={() => showLockDetail(false)}
        />
      )}
    </>
  )
}
