import { toInt } from "./number"
import moment from "moment"

import {
  weekdays,
  date_format,
  datetime_format,
  time_format_verbose,
  compatible_datetime_format
} from "../constants"

// Datetime Helpers

// format to date
export const toDateFormat = datetime => moment(datetime).format(date_format)

// format to datetime
export const toDateTimeFormat = datetime =>
  moment(datetime).format(datetime_format)

// for browser compatibility, all references to the Date class
// must be formatted using `compatible_datetime_format`
export const compatibleDateObject = date_class_input => {
  return new Date(moment(date_class_input).format(compatible_datetime_format))
}

export const formatFromUtc = (datetime, format = time_format_verbose) =>
  moment.utc(datetime).local().format(format)

export const hourToTime = (hour, brief = false, with_meridiem = true) => {
  let time_hour = Math.floor(hour > 12 ? hour - 12 : hour)
  let time_minute = Math.floor((hour - Math.floor(hour)) * 60)
  let meridiem = hour >= 12 ? "pm" : "am"
  time_hour = hour > 24 ? Math.floor(hour - 24) : time_hour
  meridiem = hour >= 24 ? "am" : meridiem
  if (time_hour === 0) {
    time_hour = 12 //12am
  }
  if (time_minute < 10) {
    time_minute = `0${time_minute}`
  }
  if (!!brief) {
    if (toInt(time_minute) === 0) {
      time_minute = ""
    }
  }
  return `${time_hour}${!!time_minute.length ? "" : `:${time_minute}`}${
    !!with_meridiem ? meridiem : ""
  }`
}

export const getNextDay = day => {
  const idx = weekdays.indexOf(day)
  if (idx === -1) {
    throw "invalid input"
  }
  if (!!weekdays[idx + 1]) {
    return weekdays[idx + 1]
  } else {
    return weekdays[0]
  }
}
export const getLastDay = day => {
  const idx = weekdays.indexOf(day)
  if (idx === -1) {
    throw "invalid input"
  }
  if (!!weekdays[idx - 1]) {
    return weekdays[idx - 1]
  } else {
    return weekdays[weekdays.length - 1]
  }
}

export const displayHour = hour => hour.toString()
export const displayMinute = min =>
  `${min.toString().length === 1 ? "0" : ""}${min}`
