import React from "react"
import Styled from "styled-components"
import { Container } from "semantic-ui-react"

import Button from "../Button"
import Panel from "../Panel"
import Logo from "../Logo"
import Link from "../Link"

import { colors, project_info } from "../../constants"

const NoAccessWrapper = Styled(Container)`
  height: 100vh;
  padding: 3rem;
`
const LogoWrapper = Styled.div`
  text-align: center;
  margin-top: 3rem;
`
const Padded = Styled.div`
  ${({ right }) => (!!right ? `text-align: right` : ``)}
  padding-top: 1rem;
  &:first-child {
    padding-top: 0;
  }
`
const StyledPanel = Styled(Panel)`
  max-width: 33rem;
  margin: 0 auto;
`

export default ({ logout, loading, isMobile }) => (
  <NoAccessWrapper>
    <StyledPanel
      type="info"
      raised
      title="We didn't expect to see you here..."
      footer={
        <Container textAlign="right">
          <Button basic loading={!!loading} onClick={() => logout()}>
            Back to Login
          </Button>
        </Container>
      }
    >
      <Padded>
        Your user doesn't have permission to use the client dashboard.
      </Padded>
      {!!isMobile && (
        <Padded>
          Did you mean to log in to our{" "}
          <Link href={project_info.employee_app_url}>employee mobile app?</Link>
        </Padded>
      )}
      <Padded>
        If you're having trouble, shoot us an email at{" "}
        {project_info.support_email}.
      </Padded>
    </StyledPanel>
    <LogoWrapper>
      <Logo size="large" secondaryColor={colors.dark5} />
    </LogoWrapper>
  </NoAccessWrapper>
)

export const NoActiveLocations = ({ logout, loading }) => (
  <NoAccessWrapper>
    <StyledPanel
      type="info"
      raised
      title="No Active Locations"
      footer={
        <Container textAlign="right">
          <Button basic loading={!!loading} onClick={() => logout()}>
            Back to Login
          </Button>
        </Container>
      }
    >
      <Padded>
        We didn't find any active locations associated with your organization.
      </Padded>
      <Padded>
        If you're having trouble, shoot us an email at{" "}
        {project_info.support_email}.
      </Padded>
    </StyledPanel>
    <LogoWrapper>
      <Logo size="large" secondaryColor={colors.dark5} />
    </LogoWrapper>
  </NoAccessWrapper>
)
