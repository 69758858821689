import {
  entity_type_employee,
  entity_type_enterprise,
  entity_type_organization,
  entity_type_store
} from "../../../../constants/role"
import { useUser } from "../../../../hooks"
import { userCanModifyEntityType } from "../Users/helpers"

export const EDITOR_MODE_VIEW = "view"
export const EDITOR_MODE_COPY = "copy"
export const EDITOR_MODE_CREATE = "create"

export const useEntityTypes = () => {
  const user = useUser()
  let entity_types = [
    { name: "Enterprise", entity_type: entity_type_enterprise },
    { name: "Organization", entity_type: entity_type_organization },
    { name: "Store", entity_type: entity_type_store },
    { name: "Employee", entity_type: entity_type_employee }
  ]
  // remove enterprise option for non-enterprise users
  if (!user?.enterprise?.id) {
    entity_types = entity_types.filter(
      ({ entity_type }) => entity_type !== entity_type_enterprise
    )
  }
  return entity_types
}

// entity type options for creating a new role
export const useCreateRoleEntityTypeOptions = () => {
  const user = useUser()
  const entity_types = useEntityTypes()
  return entity_types
    .filter(({ entity_type }) => entity_type !== entity_type_employee)
    .filter(({ entity_type }) => userCanModifyEntityType(user, entity_type))
    .map(({ name, entity_type }) => ({
      text: name,
      value: entity_type
    }))
}

// filter bar role options
export const useEntityTypeFilterOptions = () => {
  const user = useUser()
  const entity_types = useEntityTypes()
  return entity_types.map(({ name, entity_type }) => ({
    text: name,
    value: entity_type
  }))
}

// filter by sort by options
export const SORT_OPTIONS = [
  {
    text: "Created",
    value: "id"
  },
  {
    text: "Name",
    value: "name"
  }
]

// convert "App\\Models\\Store" to "Store"
export const displayEntityType = entity_type =>
  entity_type.split("\\")?.[2] ?? entity_type
