import { Component, useState } from "react"

import Styled from "styled-components"
import { Loader } from "semantic-ui-react"
import { Query } from "@apollo/client/react/components"

import { StepHeader } from "../../../Styled"
import AreYouSure from "../../../../Modal/AreYouSure"
import WeightedDistributionModal from "./WeightedDistributionModal"
import { WeightedDistrosPieChart } from "../../../WeightedDistribution"
import { Icon, Popup, Button, MultiSelectList } from "../../../../Shared"

import {
  hasWeightsConfigured,
  groupJobsByPriority,
  DEFAULT_DISTRO_PRIORITY
} from "../../../helpers"
import { toInt } from "../../../../../helpers/number"
import { colors } from "../../../../../constants"
import { SHARED_JOB_CODE_NAMES } from "../../../../../graphql/queries"

export default class extends Component {
  state = {
    max_priority: DEFAULT_DISTRO_PRIORITY,
    active_priority: DEFAULT_DISTRO_PRIORITY
  }

  componentDidMount() {
    const { selected } = this.props
    let max_priority
    if (selected.length > 0) {
      max_priority = selected.reduce(
        (acc, { priority }) => Math.max(acc, priority),
        DEFAULT_DISTRO_PRIORITY
      )
      this.setState({
        max_priority,
        active_priority: max_priority
      })
    }
  }

  render() {
    const {
      onUpdate,
      selected,
      exclude,
      store_ids,
      allow_fallback,
      allow_weights
    } = this.props
    const { max_priority, active_priority } = this.state

    let selected_by_priority = groupJobsByPriority(selected, max_priority)

    return (
      <>
        <StepHeader
          content="Distribute To"
          subheader="Select the job codes that tips will be shared with."
        />
        <Query query={SHARED_JOB_CODE_NAMES} variables={{ store_ids }}>
          {({ loading, data }) => {
            let job_codes = []
            if (!!loading || !data) {
              return <Loader inline active />
            }

            job_codes = [...data.sharedJobCodeNames]
              .map(name => ({
                id: name,
                name: name,
                weight: 1
              }))
              .sort((a, b) => a.name.localeCompare(b.name))
              .filter(job_code => !exclude.find(({ id }) => id === job_code.id))

            return (
              <div>
                {Object.keys(selected_by_priority)
                  .map(priority => toInt(priority))
                  .sort((a, b) => a > b)
                  .map(priority => {
                    const onUpdatePriorityGroup = update => {
                      onUpdate({
                        selected: [
                          ...selected
                            .filter(job_code => job_code.priority !== priority)
                            .filter(
                              ({ name }) =>
                                !update.find(job_code => job_code.name === name)
                            ),
                          ...update.map(job_code => ({
                            ...job_code,
                            priority: priority
                          }))
                        ]
                      })
                    }
                    const available = job_codes.filter(
                      job_code =>
                        !selected.find(
                          match =>
                            match.name === job_code.name &&
                            match.priority < priority
                        )
                    )
                    return (
                      <DistributionPriority
                        key={priority}
                        // filter out job codes selected at a different priority level
                        available={available}
                        selected={selected_by_priority[priority]}
                        priority={priority}
                        max_priority={max_priority}
                        active={
                          priority === active_priority ||
                          max_priority === DEFAULT_DISTRO_PRIORITY
                        }
                        allow_fallback={allow_fallback}
                        allow_weights={allow_weights}
                        togglePriorityGroup={() =>
                          this.setState({
                            active_priority:
                              active_priority === priority ? null : priority
                          })
                        }
                        addPriorityGroup={
                          // is max priority
                          priority === max_priority &&
                          // and one or more jobs are selected
                          selected_by_priority[priority].length > 0 &&
                          // and there are remaining jobs codes available
                          available.length >
                            selected_by_priority[priority].length
                            ? () =>
                                this.setState({
                                  max_priority: max_priority + 1,
                                  active_priority: max_priority + 1
                                })
                            : false
                        }
                        removePriorityGroup={
                          priority > DEFAULT_DISTRO_PRIORITY
                            ? () => {
                                onUpdate({
                                  selected: selected
                                    .filter(
                                      job_code => job_code.priority !== priority
                                    )
                                    .map(job_code => ({
                                      ...job_code,
                                      priority:
                                        job_code.priority > priority
                                          ? job_code.priority - 1
                                          : job_code.priority
                                    }))
                                })
                                this.setState({
                                  max_priority: max_priority - 1
                                })
                              }
                            : false
                        }
                        onUpdate={onUpdatePriorityGroup}
                      />
                    )
                  })}
              </div>
            )
          }}
        </Query>
      </>
    )
  }
}

const DistributionPriority = ({
  available,
  selected,
  onUpdate,
  priority,
  max_priority,
  addPriorityGroup,
  removePriorityGroup,
  active,
  togglePriorityGroup,
  allow_fallback,
  allow_weights
}) => {
  const weights_configured = !!hasWeightsConfigured(selected)

  return (
    <>
      <PriorityGroup
        priority={priority}
        show_header={max_priority > DEFAULT_DISTRO_PRIORITY}
        active={active}
        total_selected={selected.length}
        togglePriorityGroup={togglePriorityGroup}
      >
        <DistributeToMultiSelect
          available={available}
          selected={selected}
          onUpdate={selected => onUpdate(selected)}
        />
        <div>
          {!!weights_configured && (
            <WeightedDistrosPieChart
              selected_job_codes={selected}
              show_legend={true}
              show_percent={false}
              options={{ aspectRatio: 1 }}
              chart_width="20rem"
            />
          )}
        </div>
        <PriorityControls
          priority={priority}
          selected={selected}
          weights_configured={weights_configured}
          allow_fallback={allow_fallback}
          allow_weights={allow_weights}
          onUpdate={onUpdate}
          addPriorityGroup={addPriorityGroup}
          removePriorityGroup={removePriorityGroup}
        />
      </PriorityGroup>
    </>
  )
}

const PriorityGroup = Styled(
  ({
    show_header,
    priority,
    total_selected,
    active,
    children,
    togglePriorityGroup,
    ...props
  }) => {
    if (!show_header) {
      return <PriorityGroupContentLayout>{children}</PriorityGroupContentLayout>
    }
    return (
      <div {...props}>
        <PriorityGroupHeader
          priority={priority}
          active={active}
          total_selected={total_selected}
          onClick={togglePriorityGroup}
        />
        {!!active && <PriorityGroupContent content={children} />}
      </div>
    )
  }
)`
  border: 1px solid ${colors.input_border};
  border-bottom: 0px;
  &:first-child {
    border-radius: 0.28571429rem 0.28571429rem 0 0;
  }
  &:last-child {
    border-bottom: 1px solid ${colors.input_border};
    border-radius: 0 0 0.28571429rem 0.28571429rem;
  }
`

const PriorityGroupHeader = Styled(
  ({ priority, active, total_selected, ...props }) => (
    <div {...props}>
      <span className={`priority-group-title${!!active ? " active" : ""}`}>
        {priority === DEFAULT_DISTRO_PRIORITY && "Primary Distribution Group"}
        {priority > DEFAULT_DISTRO_PRIORITY && "Fallback Distribution Group"}
      </span>
      <span className="priority-group-detail">
        <>&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;Priority {priority}</>
      </span>
      <div className="priority-group-justify-right">
        <span className="priority-group-jobs-selected">
          {total_selected === 0 && "No jobs selected."}
          {total_selected > 0 && (
            <>
              {total_selected} job
              {total_selected !== 1 && "s"} selected.
            </>
          )}
        </span>
        <Icon name={!!active ? "chevron down" : "chevron right"} fitted />
      </div>
    </div>
  )
)`
  padding: 1rem 1.5rem;
  cursor: pointer;
  line-height: 1.67rem;

  & > span.priority-group-title {
    font-size: 1.1rem;
    font-weight: 300;
    &.active {
      font-weight: bold;
    }
  }

  & > span.priority-group-detail {
    font-weight: 300;
    color: ${colors.dark4};
  }

  & > div.priority-group-justify-right {
    position: relative;
    float: right;
    margin-right: 0.33rem;
    padding-right: 2rem;

    & > span.priority-group-jobs-selected {
      font-weight: 300;
      color: ${colors.dark4};
    }

    & i.chevron {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 1.2rem;
    }
  }
`

const COLLAPSE_WIDTH = "85rem"
const PriorityGroupContentLayout = Styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 1.5rem;

  @media (max-width: ${COLLAPSE_WIDTH}) {
    & {
      grid-template-columns: 1fr;
    }
  }
`

const PriorityGroupContent = Styled(({ content, className }) => (
  <div className={className}>
    <PriorityGroupContentLayout>{content}</PriorityGroupContentLayout>
  </div>
))`
  padding:1rem;
  background-color: ${colors.light};
  border-top: 0.5px solid ${colors.light3};
`

const PriorityControls = ({
  priority,
  selected,
  weights_configured,
  onUpdate,
  addPriorityGroup,
  removePriorityGroup,
  className,
  allow_fallback,
  allow_weights
}) => {
  const [weighted_distribution_modal, setWeightedDistroModal] = useState(false)
  const [add_priority_modal, setAddPriorityModal] = useState(false)
  const [remove_priority_modal, setRemovePriorityModal] = useState(false)

  return (
    <div className={className}>
      {!!removePriorityGroup && (
        <>
          <Popup
            content={<>Click to remove this fallback distribution group.</>}
            flowing
            delay
          >
            <Button basic onClick={() => setRemovePriorityModal(true)}>
              Delete Fallback Group
            </Button>
          </Popup>
          {!!remove_priority_modal && (
            <AreYouSure
              header="Delete Fallback Group"
              body="Are you sure you want to delete this group?"
              onClose={() => setRemovePriorityModal(false)}
              onConfirm={() => {
                setRemovePriorityModal(false)
                removePriorityGroup()
              }}
            />
          )}
        </>
      )}
      {!!addPriorityGroup && (
        <>
          {allow_fallback && (
            <Popup content={<>Optional, click to learn more.</>} delay>
              <Button basic onClick={() => setAddPriorityModal(true)}>
                {priority === DEFAULT_DISTRO_PRIORITY &&
                  "Setup a Fallback Distribution"}
                {priority > DEFAULT_DISTRO_PRIORITY &&
                  "Add Another Fallback Group"}
              </Button>
            </Popup>
          )}
          {!!add_priority_modal && (
            <AreYouSure
              header="Add a Fallback Group"
              body={
                <>
                  {priority === DEFAULT_DISTRO_PRIORITY && (
                    <>
                      <p>
                        In some cases, a tip-sharing rule can't apply because
                        there's nobody to distribute to.
                      </p>
                      <p>
                        A fallback distribution group allows you to specify
                        backup job codes to use in this scenario.
                      </p>
                    </>
                  )}
                  {priority > DEFAULT_DISTRO_PRIORITY && (
                    <p>Sometimes your backup needs a backup.</p>
                  )}
                  <p>
                    Would you like to{" "}
                    {priority === DEFAULT_DISTRO_PRIORITY && "setup a"}
                    {priority > DEFAULT_DISTRO_PRIORITY && "add another"}{" "}
                    fallback group?
                  </p>
                </>
              }
              onClose={() => setAddPriorityModal(false)}
              onConfirm={() => {
                setAddPriorityModal(false)
                addPriorityGroup()
              }}
            />
          )}
        </>
      )}
      {selected.length > 1 && (
        <>
          {allow_weights && (
            <Popup content={<>Optional, click to learn more.</>} delay>
              <Button basic onClick={() => setWeightedDistroModal(true)}>
                {!!weights_configured ? "Edit" : "Configure"} Distribution
                Points
              </Button>
            </Popup>
          )}
          {!!weighted_distribution_modal && (
            <WeightedDistributionModal
              selected={[...selected]}
              onClose={() => setWeightedDistroModal(false)}
              onSave={payload => {
                setWeightedDistroModal(false)
                onUpdate([...payload])
              }}
            />
          )}
        </>
      )}
    </div>
  )
}

const DistributeToMultiSelect = ({
  available,
  selected,
  onUpdate,
  className
}) => (
  <div className={className} id="rule-editor-distribute-to-input">
    <MultiSelectList
      icon="user"
      available={available}
      selected={selected}
      onUpdate={onUpdate}
    />
  </div>
)
