import { useEffect } from "react"

import TipPool from "./TipPool"
import TipShare from "./TipShare"
import { RouteTabs } from "../Tabs"
import RouteContent from "../RouteContent"

import {
  TIP_SHARING_TAB,
  TIP_POOLING_TAB,
  FILTERS_TEMPLATE,
  DEFAULT_SORT_FIELD,
  SORT_DIRECTION_DESC,
  DEFUALT_RULE_STATUS,
  RULE_FILTER_NAMESPACE,
  moduleTabs,
  ruleFiltersNamespace
} from "./helpers"
import { paths } from "../../constants"
import tour, { mockRule, mockPool } from "./tour"
import { useStateObject, useBusinessDates } from "../../hooks"

export default ({
  location,
  navigateTo,
  app_filters,
  setRoutesVisited,
  setFiltersForNamespace,
  setRuleTemplateForCreate
}) => {
  const { businessStart } = useBusinessDates()

  const [state, setState] = useStateObject({
    tour_active: false,
    selected_view: null
  })

  const { selected_view, tour_active } = state

  // select tab based on pathname on mount
  useEffect(() => {
    setState({ selected_view: selectTabFromPath(location.pathname) })
  }, [])

  const getGlobalRuleFilters = () => ({
    page: 1,
    per_page: 25,
    ...(app_filters[RULE_FILTER_NAMESPACE] ?? {})
  })

  const getScopedRuleFilters = rule_tab => ({
    ...getGlobalRuleFilters(),
    ...FILTERS_TEMPLATE,
    ...(app_filters[ruleFiltersNamespace(rule_tab)] ?? {})
  })

  const setGlobalRuleFilters = (filters, merge = true) => {
    setFiltersForNamespace(RULE_FILTER_NAMESPACE, filters, merge)
  }

  const setScopedRuleFilters = (rule_tab, filters, merge = true) => {
    const { page, per_page, ...scoped_filters } = filters
    setGlobalRuleFilters({
      sort_field: DEFAULT_SORT_FIELD,
      sort_direction: SORT_DIRECTION_DESC,
      status: DEFUALT_RULE_STATUS
    })
    // remaining filters are isolated to their corresponding page
    if (Object.keys(scoped_filters).length > 0) {
      setFiltersForNamespace(
        ruleFiltersNamespace(rule_tab),
        { page: 1, ...scoped_filters },
        merge
      )
    }
  }

  return (
    <RouteContent
      header="Tip Distribution"
      route_name="tip-distribution"
      tour={{
        ...tour,
        onOpen: () => setState({ tour_active: true }),
        onClose: () => {
          // close any modals that may be left open
          requestAnimationFrame(() => {
            try {
              document.getElementsByClassName("dimmer")[0].click()
            } catch (e) {}
          })
          // open tip sharing tab
          requestAnimationFrame(() => {
            try {
              document.getElementById("tab-navigation-tip-sharing").click()
            } catch (e) {}
          })
          setState({ tour_active: false })
        }
      }}
      setRoutesVisited={setRoutesVisited}
    >
      <RouteTabs
        tabs={moduleTabs(selected_view)}
        navigation_path={paths.distributionRules}
        onChange={tab => setState({ selected_view: tab.id })}
      >
        {selected_view === TIP_SHARING_TAB && (
          <TipShare
            navigateTo={navigateTo}
            setRuleTemplateForCreate={setRuleTemplateForCreate}
            rule_filters={getScopedRuleFilters(TIP_SHARING_TAB)}
            setRuleFilters={(filters, merge = true) =>
              setScopedRuleFilters(TIP_SHARING_TAB, filters, merge)
            }
            changePage={page =>
              setFiltersForNamespace(
                ruleFiltersNamespace(TIP_SHARING_TAB),
                { page },
                true
              )
            }
            changePerPage={per_page =>
              setFiltersForNamespace(
                ruleFiltersNamespace(TIP_SHARING_TAB),
                { per_page, page: 1 },
                true
              )
            }
            mock_rule={!!tour_active ? mockRule(businessStart()) : false}
          />
        )}
        {selected_view === TIP_POOLING_TAB && (
          <TipPool
            pool_filters={getScopedRuleFilters(TIP_POOLING_TAB)}
            setPoolFilters={(filters, merge = true) =>
              setScopedRuleFilters(TIP_POOLING_TAB, filters, merge)
            }
            changePage={page =>
              setFiltersForNamespace(
                ruleFiltersNamespace(TIP_POOLING_TAB),
                { page },
                true
              )
            }
            changePerPage={per_page =>
              setFiltersForNamespace(
                ruleFiltersNamespace(TIP_POOLING_TAB),
                { per_page, page: 1 },
                true
              )
            }
            mock_pool={!!tour_active ? mockPool(businessStart()) : false}
          />
        )}
      </RouteTabs>
    </RouteContent>
  )
}

const selectTabFromPath = pathname => {
  const tab_key = pathname
    .split("/")
    .filter(token => token.length)
    .pop()
  switch (tab_key) {
    case TIP_POOLING_TAB:
      return TIP_POOLING_TAB
    case TIP_SHARING_TAB:
      return TIP_SHARING_TAB
    default:
      window.history.pushState(
        { [TIP_SHARING_TAB]: true },
        TIP_SHARING_TAB,
        `${paths.distributionRules}/${TIP_SHARING_TAB}`
      )
  }
  return TIP_SHARING_TAB
}
