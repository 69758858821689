import React from "react"

import TableLoader from "./Table/Loader"
import FilterBar from "../../Shared/FilterBar"
import AdvancedSearch from "./AdvancedSearch"
import SelectedFilter from "../../Shared/FilterBar/Filters/Selected"
import LockedIndicator, {
  lockApplies
} from "../../Shared/ReportLockedIndicator"

import { toInt } from "../../../helpers"
import {
  useBusinessDates,
  useOrgIsMultiUnit,
  useSelectedLocation,
  useReportLockThreshold
} from "../../../hooks/selectors"
import {
  shiftSortOptions,
  defaultShiftSort
} from "./AdvancedSearch/Filters/SortBy"

export default ({ filters, setFilters, ...props }) => {
  const selected_location = useSelectedLocation()
  const reportLockThreshold = useReportLockThreshold()
  const {
    businessStart,
    businessEnd,
    businessStartFromDate
  } = useBusinessDates()

  const filter_start_time = businessStart(filters.start_time)
  const filter_end_time = businessEnd(filters.end_time)
  const lock_threshold = reportLockThreshold(
    selected_location?.id,
    filters.start_time
  )

  const custom_filters = generateFilterBarCustomFilters(
    selected_location,
    filters,
    setFilters
  )

  return (
    <FilterBar
      {...props}
      id="report-filter-bar"
      location_id={selected_location?.id}
      start_date={filters.start_time}
      end_date={filters.end_time}
      employee={{
        employee: filters.employee ?? null,
        onSelect: employee =>
          setFilters({ employee, location: employee?.store }),
        onRemove: () => setFilters({ employee: null })
      }}
      onChange={({ start_date, end_date }) => {
        setFilters({
          start_time: businessStartFromDate(start_date),
          end_time: businessStartFromDate(end_date)
        })
      }}
      sort={{
        field: filters.sort_field ?? defaultShiftSort().field,
        direction: filters.sort_direction ?? defaultShiftSort().direction,
        options: shiftSortOptions(),
        onChange: (sort_field, sort_direction) =>
          setFilters({ sort_field, sort_direction })
      }}
      custom_filters={custom_filters}
      actions={
        lockApplies(lock_threshold, filter_start_time) && (
          <LockedIndicator
            query_start={filter_start_time}
            query_end={filter_end_time}
            lock_threshold={lock_threshold}
          />
        )
      }
      loading_msg={
        <TableLoader
          start_time={filters.start_time}
          end_time={filters.end_time}
        />
      }
    />
  )
}

const generateFilterBarCustomFilters = (
  selected_location,
  applied_filters,
  setFilters
) => {
  const is_multi_unit = useOrgIsMultiUnit()
  const { job_code } = applied_filters
  let filters = []

  if (selected_location && is_multi_unit) {
    filters.push({
      label: "Location",
      filter: (
        <SelectedFilter
          value={selected_location.name}
          onRemove={() =>
            setFilters({
              location: null,
              employee: null,
              job_code: null
            })
          }
        />
      )
    })
  }

  if (job_code) {
    filters.push({
      label: "Job Code",
      filter: (
        <SelectedFilter
          value={job_code.name}
          onRemove={() => setFilters({ job_code: null })}
        />
      )
    })
  }

  filters.push({
    filter: (
      <AdvancedSearch
        applied_filters={prepareFilterDefaults({
          location: !selected_location?.id
            ? null
            : {
                id: selected_location?.id,
                name: selected_location?.name
              },
          ...applied_filters
        })}
        onSubmit={setFilters}
      />
    )
  })

  return filters
}

const prepareFilterDefaults = (filters = {}) => {
  if (!filters.location) {
    if (filters.employee?.store?.id && filters.employee?.store?.name) {
      return {
        ...filters,
        location: {
          id: toInt(filters.employee.store.id),
          name: filters.employee.store.name
        }
      }
    }
  }
  return filters
}
