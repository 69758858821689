import SelectedFilter from "./Selected"
import { FilterWrapper } from "../Styled"
import JobCodeSearch from "../../../Search/JobCode"

export default ({ job_code, label, location_id, onSelect, onRemove }) => (
  <FilterWrapper label={label ?? "Job Code"}>
    {!job_code && (
      <JobCodeSearch
        store_id={location_id}
        onChange={({ job_code }) => {
          onSelect(job_code)
        }}
      />
    )}
    {!!job_code && <SelectedFilter value={job_code.name} onRemove={onRemove} />}
  </FilterWrapper>
)
