import { useMemo } from "react"
import { Modal, Loader } from "../../../Shared"
import { queryEmployeeEtaPayouts, useEtaConfigSetting } from "../../../../hooks"
import { payPeriodDateSpan } from "../../helpers"
import EmployeePayoutsSummary from "../../EmployeePayouts/Summary"
import EmployeePayoutsTable from "../../EmployeePayouts/Table/"
import { month_day_short_format } from "../../../../constants"

export default ({ employee, selected_pay_period, onClose }) => {
  const pay_period_id = selected_pay_period?.id ?? null
  const { data, loading } = queryEmployeeEtaPayouts({
    employee_id: employee.__meta.employee_id,
    pay_period_id
  })
  const payouts = data?.employeeEtaPayouts ?? []
  const show_fees = useEtaConfigSetting("hausdirect_enabled", false)

  const getSummaryColumns = rows =>
    [
      {
        label: "Employee",
        value: `${employee["employee.first_name"]} ${employee["employee.last_name"]}`
      },
      {
        label: "Pay Period",
        value: payPeriodDateSpan(selected_pay_period, month_day_short_format)
      },
      {
        label: "Gross Amount",
        description:
          "The amount the employee was paid, before any Hausdirect Fees. Reserved amounts are excluded.",
        value: rows.reduce((acc, row) => acc + row.amount, 0),
        type: "number"
      },
      show_fees
        ? {
            label: "Employee Fees",
            description: "All fees an employee with Hausdirect has paid.",
            value: rows.reduce((acc, row) => acc + row.employee_fee, 0),
            type: "number"
          }
        : null,
      {
        label: "Net Amount",
        description:
          "The amount the employee was paid, after any Hausdirect Fees. Reserved amounts are excluded.",
        value: rows.reduce((acc, row) => acc + row.net_amount, 0),
        type: "number"
      },
      {
        label: "Take Home",
        description:
          "This is the amount the employee has earned from tip calculations on the breakdown page on all days with an associated payout. Reserved amounts are excluded.",
        value: rows.reduce((acc, row) => acc + row.take_home, 0),
        type: "number"
      }
    ].filter(Boolean)

  const summary_columns = useMemo(
    () => (!!payouts.length ? getSummaryColumns(payouts) : []),
    [payouts]
  )

  return (
    <Modal open onClose={onClose} size="large">
      <Modal.Header onClose={onClose}>Amount Paid Out Detail</Modal.Header>
      <Modal.Content scrolling>
        {loading && <Loader inline="centered" />}
        {!loading && (
          <>
            <EmployeePayoutsSummary columns={summary_columns} />
            <EmployeePayoutsTable
              payouts={payouts}
              show_employee={false}
              show_status={false}
              show_fees={show_fees}
            />
          </>
        )}
      </Modal.Content>
    </Modal>
  )
}
