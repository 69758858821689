import moment from "moment"
import Styled from "styled-components"
import { Mutation } from "@apollo/client/react/components"

import {
  Table,
  Popup,
  Button,
  Toggle,
  Input,
  Loader,
  AreYouSure,
  MenuDropdown
} from "../../Shared"
import Icon, { InfoIcon } from "../../Icon"
import EditCategoryModal from "../../Modal/EditSaleCategory"

import {
  STORE_SALE_CATEGORIES,
  ORG_SALE_CATEGORIES
} from "../../../graphql/queries"
import {
  REQUEST_REPROCESS_STORES,
  UPDATE_SALES_CATEGORY
} from "../../../graphql/mutations"

import { date_format, project_info } from "../../../constants"
import { useStateObject } from "../../../hooks"
import { useQuery } from "@apollo/client"
import useAuthorization from "../../../hooks/authorization"
import { settings_manage_sales_category } from "../../../constants/permissions"

const PER_PAGE = 25
const DEFAULT_PAGE = 1
const COLSPAN_ALL_COLUMNS = 100

const STATUS_ALL = "All"
const STATUS_ENABLED = "Enabled"
const STATUS_DISABLED = "Disabled"

const ACTION_RESYNC = "Resync Sale Categories with Point-of-Sale"

const EditButton = props => (
  <Popup content="Edit Category" delay>
    <Button circular icon="edit" compact size="tiny" {...props} />
  </Popup>
)
const ToggleWrapper = Styled.div`
  position: relative;
  display: inline-block;
  & > div {
    padding-top: 0.33rem;
  }
`

export default props => {
  const { user, store } = props

  const [state, setState] = useStateObject({
    search: "",
    page: DEFAULT_PAGE,
    per_page: PER_PAGE,
    request_resync: false,
    store_id_filter: store?.id ?? false,
    enabled_filter: STATUS_ALL,
    edit_category: false
  })
  const { hasPermission } = useAuthorization()

  const {
    search,
    page,
    per_page,
    request_resync,
    store_id_filter,
    enabled_filter,
    edit_category
  } = state

  const clearSearch = () => setState({ search: "", page: DEFAULT_PAGE })

  const parseCategories = data => {
    let categories = []

    // parse
    try {
      if (store_id_filter) {
        categories = data.store.salesCategories.map(sc => ({
          ...sc,
          store: { id: data.store.id, name: data.store.name }
        }))
      } else {
        categories = data.self.authorizedStores.reduce(
          (acc, store) => [
            ...acc,
            ...store.salesCategories.map(sc => ({
              ...sc,
              store: { id: store.id, name: store.name }
            }))
          ],
          []
        )
      }
    } catch {}

    // filter and sort
    return (
      categories
        // name search
        .filter(
          ({ name }) =>
            search.trim().length === 0 ||
            name.toLowerCase().includes(search.trim().toLowerCase())
        )
        // status filter
        .filter(({ active }) => {
          if (enabled_filter === STATUS_ENABLED) {
            return active
          } else if (enabled_filter === STATUS_DISABLED) {
            return !active
          }
          return true
        })
        // sort alphabetically
        .sort((a, b) => a.name.localeCompare(b.name))
    )
  }

  const { data, loading, refetch } = !!store_id_filter
    ? useQuery(STORE_SALE_CATEGORIES, {
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true,
        variables: { id: store_id_filter }
      })
    : useQuery(ORG_SALE_CATEGORIES, {
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true
      })

  const categories = parseCategories(data)

  let pagination = {
    current_page: page,
    total: categories.length,
    per_page: per_page,
    options: { per_page: [25, 50, 100, 500] },
    changePage: page => setState({ page }),
    changePerPage: per_page => setState({ per_page, page: 1 })
  }
  let custom_controls = []

  // table controls: category search
  custom_controls = [
    <Input
      icon="search"
      iconPosition="left"
      action={
        search.length > 0 && !loading
          ? {
              icon: <RemoveIcon />,
              onClick: clearSearch
            }
          : undefined
      }
      placeholder="Search..."
      value={search}
      onChange={(e, { value }) => setState({ search: value })}
    />
  ]

  // custom table controls
  if (!store && user.stores.length > 1) {
    // store dropdown only visible from /sale-categories page
    custom_controls.push(
      <MenuDropdown
        value={store_id_filter}
        options={[
          { value: false, text: "All Locations" },
          ...user.stores.map(({ id, name }) => ({
            text: name,
            value: id
          }))
        ]}
        onChange={value => setState({ store_id_filter: value, page: 1 })}
      />
    )
  }

  // status filter
  custom_controls.push(
    <MenuDropdown
      value={enabled_filter}
      prefix="Status"
      options={[
        { value: STATUS_ALL, text: STATUS_ALL },
        { value: STATUS_ENABLED, text: STATUS_ENABLED },
        { value: STATUS_DISABLED, text: STATUS_DISABLED }
      ]}
      onChange={value => setState({ enabled_filter: value, page: 1 })}
    />
  )

  // resync button
  custom_controls.push(
    <MenuDropdown
      text="Actions"
      options={[{ value: ACTION_RESYNC, text: ACTION_RESYNC }]}
      onChange={value => {
        switch (value) {
          case ACTION_RESYNC:
            return setState({ request_resync: true })
          default:
            return
        }
      }}
    />
  )

  return (
    <CategoriesWrapper>
      <Table
        compact
        draggable
        unstackable
        controls={{
          position: categories.length > per_page ? "both" : "top",
          custom_controls_position: "top",
          pagination,
          custom: [...custom_controls]
        }}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>External ID</Table.HeaderCell>
            {!store_id_filter && user.stores.length > 1 && (
              <Table.HeaderCell>Location</Table.HeaderCell>
            )}
            <Table.HeaderCell>Date Created</Table.HeaderCell>
            <Table.HeaderCell>
              {" "}
              Tip Distribution Enabled{" "}
              <InfoIcon
                popup={
                  <>
                    When disabled, the category will not be available to use
                    with tip-sharing rules.
                  </>
                }
              />
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {!!loading && categories.length === 0 && (
            <Table.Row>
              <Table.Cell textAlign="center" colSpan={COLSPAN_ALL_COLUMNS}>
                <TableSpan>
                  <Loader inline active>
                    Loading...
                  </Loader>
                </TableSpan>
              </Table.Cell>
            </Table.Row>
          )}
          {!loading && categories.length === 0 && (
            <Table.Row>
              <Table.Cell colSpan={COLSPAN_ALL_COLUMNS}>
                <TableSpan>No sale item categories found.</TableSpan>
              </Table.Cell>
            </Table.Row>
          )}
          {(!loading || categories.length > 0) &&
            categories
              .slice((page - 1) * per_page, page * per_page)
              .map(category => {
                return (
                  <Table.Row key={category.id}>
                    <Table.Cell>{category.name}</Table.Cell>
                    <Table.Cell>{category.external_id}</Table.Cell>
                    {!store_id_filter && user.stores.length > 1 && (
                      <Table.Cell>{category.store.name}</Table.Cell>
                    )}
                    <Table.Cell>
                      {moment(category.created_at)
                        .local()
                        .format("dddd, MMMM Do, YYYY")}
                    </Table.Cell>
                    <Table.Cell>
                      <Mutation
                        mutation={UPDATE_SALES_CATEGORY}
                        onCompleted={({ updateSalesCategory }) => {
                          props.toast({
                            type: "Success",
                            message: (
                              <p>
                                "{category.name}" category will{" "}
                                {!category.active ? `now` : `no longer`} be
                                visible when creating or modifying tip
                                distribution logic.
                              </p>
                            )
                          })
                          refetch()
                        }}
                      >
                        {(updateSalesCategory, { loading }) => (
                          <>
                            {!loading && (
                              <ToggleWrapper>
                                <Popup
                                  content={
                                    <>
                                      Disabled categories are hidden when
                                      editing tip sharing rules.
                                    </>
                                  }
                                  trigger={
                                    <Toggle
                                      checked={category.active}
                                      disabled={
                                        !hasPermission(
                                          settings_manage_sales_category
                                        )
                                      }
                                      onChange={(e, { checked }) => {
                                        updateSalesCategory({
                                          variables: {
                                            input: {
                                              id: category.id,
                                              active: checked
                                            }
                                          }
                                        })
                                      }}
                                    />
                                  }
                                />
                              </ToggleWrapper>
                            )}
                            {!!loading && <Loader active inline size="small" />}
                          </>
                        )}
                      </Mutation>
                    </Table.Cell>
                    <Table.Cell width={1} textAlign="center">
                      {hasPermission(settings_manage_sales_category) && (
                        <EditButton
                          onClick={() => {
                            setState({ edit_category: category })
                          }}
                        />
                      )}
                    </Table.Cell>
                  </Table.Row>
                )
              })}
        </Table.Body>
      </Table>
      {!!edit_category && (
        <EditCategoryModal
          toast={props.toast}
          category={edit_category}
          onCompleted={() => refetch()}
          onClose={() => setState({ edit_category: false })}
        />
      )}
      {request_resync && (
        <Mutation
          mutation={REQUEST_REPROCESS_STORES}
          onCompleted={() => {
            setState({ request_resync: false })
            props.toast({
              type: "success",
              message: (
                <>
                  <p>
                    A resync is underway. This process may take a few minutes to
                    complete.
                  </p>
                </>
              )
            })
          }}
          onError={() => {
            setState({
              request_resync: null
            })
            props.toast({
              type: "error",
              message: (
                <>
                  <p>
                    An error occurred while requesting to resync sale item
                    categories.
                  </p>
                </>
              )
            })
          }}
        >
          {(reprocessStores, { loading }) => (
            <AreYouSure
              header="Resync Sale Item Categories"
              submitted={!!loading}
              body={
                <Thin>
                  <p>
                    By default, {project_info.name} syncs sale item categories
                    with your point-of-sale daily.
                  </p>
                  <p>
                    If you confirm, we'll kick-off an additional sync now. It
                    may take a few minutes for the process to complete.
                  </p>
                  <p>Do you want to resync categories now?</p>
                </Thin>
              }
              onClose={() => setState({ request_resync: false })}
              onConfirm={() => {
                reprocessStores({
                  variables: {
                    store_ids: !!store_id_filter
                      ? [store_id_filter]
                      : user.stores.map(({ id }) => id),
                    // fillter date arg
                    date: moment().format(date_format),
                    sync_static: true,
                    sync_dynamic: false
                  }
                })
              }}
            />
          )}
        </Mutation>
      )}
    </CategoriesWrapper>
  )
}

const CategoriesWrapper = Styled.div`
  position: relative;
  font-size: 1rem;
`
const TableSpan = Styled.div`
  padding: 0.67rem 0;
`
const RemoveIcon = Styled(({ clickable, ...props }) => (
  <Icon name="remove" {...props} />
))`
  ${({ clickable }) =>
    !clickable
      ? ``
      : `
    cursor: pointer !important;
  `}
`
const Thin = Styled.span`font-weight:300;`
