import { Button, PopupMenu } from "../../../../../Shared"

const ActionsPopupMenu = ({ open, actions = [], onOpen, onClose }) => (
  <PopupMenu
    open={open}
    onClose={onClose}
    hideOnScroll
    trigger={
      <Button
        data-testid="actions-button"
        icon="ellipsis vertical"
        size="mini"
        onClick={e => {
          e.stopPropagation()
          onOpen()
        }}
      />
    }
  >
    {actions.map(({ label, action, danger = false }, idx) => (
      <PopupMenu.Item
        key={idx}
        danger={danger}
        onClick={e => {
          e.stopPropagation()
          action()
        }}
      >
        {label}
      </PopupMenu.Item>
    ))}
  </PopupMenu>
)

export default ActionsPopupMenu
