import Styled from "styled-components"
import { lighten } from "polished"
import { Label } from "semantic-ui-react"
import { Table } from "../../../../../Shared"
import { colors } from "../../../../../../constants"
import { useLocations } from "../../../../../../hooks"
import { getLocationLabelsForDisplay } from "../../helpers"
import {
  hasEnterpriseLevelRole,
  hasOrgLevelRole
} from "../../../../../../helpers/user"

const LocationCell = ({ user }) => {
  const locations = useLocations()
  const labels = getLocationLabelsForDisplay(user, locations)
  return (
    <Table.Cell>
      {hasOrgLevelRole(user) ? (
        <CellLabel label="Organization" primary />
      ) : hasEnterpriseLevelRole(user) ? (
        <CellLabel label="Enterprise" primary />
      ) : (
        labels.map((label, idx) => <CellLabel key={idx} label={label} />)
      )}
    </Table.Cell>
  )
}

const CellLabel = Styled(({ className, label }) => (
  <div className={className}>
    <Label horizontal>{label}</Label>
  </div>
))`
  display: inline-block;
  > .label {
    margin: 0.1rem !important;
  }
  ${({ primary }) =>
    !!primary &&
    `
    > .label {
      background-color: ${lighten(0.1, colors.success)} !important;
      color: ${colors.white} !important;
    }
  `};
`

export default LocationCell
