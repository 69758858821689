// String Helpers

export const firstCharToUpper = value => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const ucfirst = val => firstCharToUpper(val)

export const ucwords = str =>
  str
    .split(" ")
    .map(token => firstCharToUpper(token))
    .join(" ")

// list of strings to string formatted for a sentence
// eg:
//  input: ["eh", "bee", "see"]
export const formatListForSentence = (list, to_lower = true) => {
  let filtered = list.filter(val => val.trim().length > 0)
  return filtered.reduce(
    (acc, src, idx) =>
      `${acc}${idx === 0 ? "" : idx === filtered.length - 1 ? " & " : ", "}${
        !!to_lower ? src.trim().toLowerCase() : src.trim()
      }`,
    ""
  )
}

export const isValidEmail = email => {
  if (!email) {
    return false
  }

  if (
    email.length > 0 &&
    /^\w+([\.\+-]?\w+)*(\+\w+)?@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(email)
  ) {
    return true
  }
  return false
}

// Function to generate a hash from a given string
// Mainly used to make more "incognito" user specific localStorage key names
// Example: input: "John Doe" => output: 1038540793
export const hashify = str => {
  let hash = 0

  // Loop through each character in the string
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i) // Get the character code of the current character
    hash = (hash << 5) - hash + char // Compute hash: shift hash 5 bits left and add current character code
    hash |= 0 // Convert hash to a 32-bit integer
  }

  return hash
}
