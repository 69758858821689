import { IconButton } from "../Shared"

import { paths } from "../../constants"
import { useBusinessDates } from "../../hooks"
import { BREAKDOWN_FILTER_NAMESPACE } from "./helpers"
import {
  NavigateToConsumer,
  SetApplicationFiltersConsumer
} from "../../context"

export default ({ filters, onClick = () => {}, ...props }) => {
  const { businessStart } = useBusinessDates()

  return (
    <NavigateToConsumer>
      {navigateTo => (
        <SetApplicationFiltersConsumer>
          {setApplicationFilters => (
            <IconButton
              fitted
              compact
              circular
              size="tiny"
              icon="clipboard outline"
              popup={{
                delay: true,
                content: "Link to Breakdown",
                position: "top right"
              }}
              onClick={() => {
                setApplicationFilters(
                  {
                    [BREAKDOWN_FILTER_NAMESPACE]: {
                      start_time: businessStart(filters.window_start),
                      end_time: businessStart(filters.window_start),
                      employee_first_name_search:
                        filters?.employee?.first_name ?? "",
                      employee_last_name_search:
                        filters?.employee?.last_name ?? "",
                      job_name_search: filters?.jobCode?.name ?? "",
                      page: 1
                    }
                  },
                  false
                )
                navigateTo(paths.breakdown)
                return onClick()
              }}
              {...props}
            />
          )}
        </SetApplicationFiltersConsumer>
      )}
    </NavigateToConsumer>
  )
}
