import { useEffect, useState } from "react"
import { paths } from "../../constants"
import {
  settings_view,
  settings_manage_org_cc_tip_fee,
  settings_manage_pay_period,
  settings_manage_peer_to_peer
} from "../../constants/permissions"
import { hasEnterpriseLevelRole, hasOrgLevelRole } from "../../helpers/user"
import { useUser } from "../../hooks"
import useAuthorization from "../../hooks/authorization"

export const USERS_TAB = "users"
export const TIP_FEE_TAB = "tip-fee"
export const P2P_TAB = "peer-to-peer"
export const LOCATIONS_TAB = "locations"
export const JOB_CODES_TAB = "job-codes"
export const PAY_PERIOD_TAB = "pay-period"

// Hook for checking if a user has access a settings tab based on permissions
export const useCanViewSettingsTab = () => {
  const user = useUser()
  const { hasPermission } = useAuthorization()

  // critieria for viewing each settings tab
  const settings_route_permissions = {
    [LOCATIONS_TAB]: () => hasPermission(settings_view),
    [USERS_TAB]: () => hasPermission(settings_view),
    [TIP_FEE_TAB]: () =>
      hasPermission(settings_manage_org_cc_tip_fee) &&
      (hasEnterpriseLevelRole(user) || hasOrgLevelRole(user)),
    [PAY_PERIOD_TAB]: () => hasPermission(settings_manage_pay_period),
    [P2P_TAB]: () =>
      hasPermission(settings_manage_peer_to_peer) &&
      (hasEnterpriseLevelRole(user) || hasOrgLevelRole(user)),
    [JOB_CODES_TAB]: () => hasPermission(settings_view)
  }

  const canViewTab = route => {
    const route_has_permission = settings_route_permissions[route]
    if (!route_has_permission) {
      return false
    }
    // validate route
    return route_has_permission()
  }

  return { canViewTab }
}

// Hook for managing the selecting settings tab
export const useSelectedTab = ({ location, history }) => {
  const [selected, setSelected] = useState(null)
  const { canViewTab } = useCanViewSettingsTab()

  useEffect(() => {
    const pathname = location.pathname
    const tab_key = pathname
      .split("/")
      .filter(token => token.length)
      .pop()
    let selected_tab
    switch (tab_key) {
      // is this deprecated? link is still referenced
      case JOB_CODES_TAB:
        selected_tab = JOB_CODES_TAB
        break
      case TIP_FEE_TAB:
        selected_tab = TIP_FEE_TAB
        break
      case P2P_TAB:
        selected_tab = P2P_TAB
        break
      case PAY_PERIOD_TAB:
        selected_tab = PAY_PERIOD_TAB
        break
      case USERS_TAB:
        selected_tab = USERS_TAB
        break
      case LOCATIONS_TAB:
      default:
        selected_tab = LOCATIONS_TAB
        history.push(
          { [LOCATIONS_TAB]: true, search: window.location.search },
          LOCATIONS_TAB,
          `${paths.settings}/${LOCATIONS_TAB}`
        )
    }

    // if the user doesn't have permissions to view this tab, default to locations tab
    if (!canViewTab(selected_tab)) {
      selected_tab = LOCATIONS_TAB
    }

    setSelected(selected_tab)
  }, [])
  return [selected, setSelected]
}
