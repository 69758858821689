import { CardWrapper, CardHeader, CardContent } from "./Styled"

const Card = ({
  header,
  content,
  children,
  fluid = false,
  inline = false,
  fitted = false
}) => (
  <CardWrapper inline={inline} fluid={fluid} fitted={fitted}>
    {!!header && <CardHeader>{header}</CardHeader>}
    {!!content && <CardContent>{content}</CardContent>}
    {children}
  </CardWrapper>
)

Card.Header = CardHeader
Card.Content = CardContent

export default Card
