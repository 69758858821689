import moment from "moment"

import TableLoader from "./Table/Loader"
import { Dropdown, FilterBar } from "../Shared"
import SelectedFilter from "../Shared/FilterBar/Filters/Selected"
import LockedIndicator, { lockApplies } from "../Shared/ReportLockedIndicator"

import { FILTER_TIPPED_EMPLOYEES, FILTER_ALL_EMPLOYEES } from "./helpers"
import { epoch } from "../../constants"
import {
  usePayPeriods,
  useBusinessDates,
  useOrgIsMultiUnit,
  useSelectedLocation,
  useReportLockThreshold
} from "../../hooks/selectors"
import { SelectLocationConsumer } from "../../context/selectLocation"

export default ({ filters, setFilters, ...props }) => {
  const selected_location = useSelectedLocation()
  const { current_pay_period, last_pay_period } = usePayPeriods()
  const { businessDate, businessStartFromDate } = useBusinessDates()
  const reportLockThreshold = useReportLockThreshold()

  const start_date = businessDate(filters.start_time)
  const end_date = businessDate(filters.end_time)

  const lock_threshold = reportLockThreshold(
    selected_location?.id,
    filters.start_time
  )

  return (
    <FilterBar
      {...props}
      id="breakdown-filters"
      location_id={selected_location?.id}
      start_date={filters.start_time}
      years_min_date={epoch}
      end_date={filters.end_time}
      onChange={({ start_date, end_date }) => {
        setFilters({
          start_time: businessStartFromDate(start_date),
          end_time: businessStartFromDate(end_date)
        })
      }}
      loading_msg={<TableLoader start_date={start_date} end_date={end_date} />}
      presets={generateFilterBarPresets({
        filter_start_date: start_date,
        filter_end_date: end_date,
        today: businessDate(),
        yesterday: businessDate(moment().subtract(1, "d")),
        current_pay_period,
        last_pay_period,
        applyPreset: (start_date, end_date) =>
          setFilters({
            start_time: businessStartFromDate(start_date),
            end_time: businessStartFromDate(end_date)
          })
      })}
      custom_filters={generateFilterBarFilters(
        selected_location,
        filters,
        setFilters
      )}
      actions={
        lockApplies(lock_threshold, filters.start_time) && (
          <LockedIndicator
            query_start={filters.start_time}
            query_end={filters.end_time}
            lock_threshold={lock_threshold}
          />
        )
      }
    />
  )
}

const generateFilterBarPresets = ({
  today,
  yesterday,
  applyPreset,
  filter_end_date,
  last_pay_period,
  filter_start_date,
  current_pay_period
}) => {
  // only show filters when pay periods are configured
  if (!current_pay_period) {
    return null
  }
  return [
    {
      name: "Yesterday",
      active: filter_start_date === yesterday && filter_end_date === yesterday,
      onClick: () => applyPreset(yesterday, yesterday)
    },
    {
      name: "Current Pay Period",
      visible: moment(today).isSameOrAfter(
        moment(current_pay_period.start).add(1, "day")
      ),
      active:
        filter_start_date === current_pay_period.start &&
        filter_end_date === yesterday,
      onClick: () => applyPreset(current_pay_period.start, yesterday)
    },
    {
      name: "Last Pay Period",
      visible: !!last_pay_period,
      active:
        filter_start_date === last_pay_period.start &&
        filter_end_date === last_pay_period.end,
      onClick: () => applyPreset(last_pay_period.start, last_pay_period.end)
    }
  ]
}

const generateFilterBarFilters = (
  selected_location,
  {
    showing,
    employee_first_name_search,
    employee_last_name_search,
    employee_ext_id_search,
    job_name_search,
    store_name_search,
    payroll_id_search
  },
  setFilters
) => {
  const is_multi_unit = useOrgIsMultiUnit()

  let filters = [
    {
      id: "breakdown-filters-showing",
      label: "Showing",
      filter: (
        <Dropdown
          compact
          selection
          value={showing ?? FILTER_TIPPED_EMPLOYEES}
          options={[
            {
              text: "All Employees",
              value: FILTER_ALL_EMPLOYEES
            },
            {
              text: "Tipped Employees",
              value: FILTER_TIPPED_EMPLOYEES
            }
          ]}
          onChange={(e, { value }) =>
            setFilters({
              showing: value
            })
          }
        />
      )
    }
  ]

  if (selected_location && is_multi_unit) {
    filters.push({
      label: "Location",
      filter: (
        <SelectLocationConsumer>
          {selectLocation => (
            <SelectedFilter
              value={selected_location.name}
              onRemove={() => selectLocation(null)}
            />
          )}
        </SelectLocationConsumer>
      )
    })
  }

  if (employee_last_name_search) {
    filters.push({
      label: "Last Name",
      filter: (
        <SelectedFilter
          value={employee_last_name_search}
          onRemove={() => setFilters({ employee_last_name_search: "" })}
        />
      )
    })
  }

  if (employee_first_name_search) {
    filters.push({
      label: "First Name",
      filter: (
        <SelectedFilter
          value={employee_first_name_search}
          onRemove={() => setFilters({ employee_first_name_search: "" })}
        />
      )
    })
  }

  if (store_name_search) {
    filters.push({
      label: "Location Name",
      filter: (
        <SelectedFilter
          value={store_name_search}
          onRemove={() => setFilters({ store_name_search: "" })}
        />
      )
    })
  }

  if (employee_ext_id_search) {
    filters.push({
      label: "Employee External ID",
      filter: (
        <SelectedFilter
          value={employee_ext_id_search}
          onRemove={() => setFilters({ employee_ext_id_search: "" })}
        />
      )
    })
  }

  if (job_name_search) {
    filters.push({
      label: "Job Code",
      filter: (
        <SelectedFilter
          value={job_name_search}
          onRemove={() => setFilters({ job_name_search: "" })}
        />
      )
    })
  }

  if (payroll_id_search) {
    filters.push({
      label: "Payroll ID",
      filter: (
        <SelectedFilter
          value={payroll_id_search}
          onRemove={() => setFilters({ payroll_id_search: "" })}
        />
      )
    })
  }

  return filters
}
