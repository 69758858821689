import { useMemo } from "react"

import { useSelector, useObjectSelector } from "./"

export const useLocations = () =>
  useObjectSelector(state => state.app?.user?.organization?.stores ?? [])

export const useLocation = location_id =>
  findLocation(useLocations(), location_id)

export const useLocationSettings = location_id =>
  useLocation(location_id)?.settings ?? {}

export const useLocationSetting = (
  location_id,
  setting_key,
  default_value = undefined
) => useLocationSettings(location_id)[setting_key] ?? default_value

export const useSelectedLocation = () => {
  const selected_location_id = useSelectedLocationId()
  const locations = useLocations()
  return useMemo(() => findLocation(locations, selected_location_id), [
    selected_location_id
  ])
}

export const useSelectedLocationId = () =>
  useSelector(state => state.app?.selected_location_id)

const findLocation = (locations, location_id) =>
  locations.find(({ id }) => id === location_id)
