import { useMutation } from "@apollo/client"
import { Table } from "../../../../../Shared"
import { ServiceLabel } from "../../../../Styled"
import { usePayPeriods, useStateObject } from "../../../../../../hooks"
import ServiceTransferLabel from "./ServiceTransferLabel"
import AccountDetailCard from "./AccountDetailCard"
import EffectiveDateDropdown from "./EffectiveDateDropdown"
import ReviewActions from "./ReviewActions"
import {
  FLAG_ETA_USER_REGISTRATION,
  EMPLOYEE_ETA_APPROVE
} from "../../../../../../graphql/mutations"
import { ETA_STATE_OPT_IN_REQUESTED } from "../../../../helpers"
import OptInRequestLabel from "./OptInRequestLabel"

const Row = ({
  className,
  user,
  person,
  transfer,
  payout_method,
  onReviewCompleted
}) => {
  const { eta_state, eta_opted_out_at } = user
  const { current_pay_period } = usePayPeriods()
  const [state, setState] = useStateObject({
    verified: false,
    flagged: false,
    effective_date: current_pay_period.start
  })
  const { verified, flagged, effective_date } = state
  // if from_effective_date is set, user is transferring services
  const { from_effective_date } = transfer ?? {}

  const [employeeEtaApprove, approve_mutation] = useMutation(
    EMPLOYEE_ETA_APPROVE,
    {
      onCompleted: () => {
        setState({ verified: true })
        onReviewCompleted()
      }
    }
  )

  const [flagEtaUserRegistration, flag_mutation] = useMutation(
    FLAG_ETA_USER_REGISTRATION,
    {
      onCompleted: () => {
        setState({ flagged: true })
        onReviewCompleted()
      }
    }
  )

  return (
    <Table.Row className={className}>
      <Table.Cell>
        <ServiceLabel payout_method={payout_method}>
          {payout_method}
        </ServiceLabel>
      </Table.Cell>
      <Table.Cell>{user.name}</Table.Cell>
      <Table.Cell>
        <AccountDetailCard person={person} />
      </Table.Cell>
      <Table.Cell>
        {!!from_effective_date ? (
          <ServiceTransferLabel
            effective_date={from_effective_date}
            payout_method={payout_method}
          />
        ) : (
          <>
            {eta_state === ETA_STATE_OPT_IN_REQUESTED && (
              <OptInRequestLabel eta_opted_out_at={eta_opted_out_at} />
            )}
            <EffectiveDateDropdown
              disabled={flagged || verified}
              effective_date={effective_date}
              onChange={effective_date => setState({ effective_date })}
            />
          </>
        )}
      </Table.Cell>

      <Table.Cell collapsing textAlign="center">
        <ReviewActions
          loading={approve_mutation?.loading || flag_mutation?.loading}
          verified={verified}
          flagged={flagged}
          show_flagged={eta_state !== ETA_STATE_OPT_IN_REQUESTED}
          onVerify={() =>
            employeeEtaApprove({
              variables: { user_id: user.id, effective_date }
            })
          }
          onFlag={() =>
            flagEtaUserRegistration({
              variables: { user_id: user.id }
            })
          }
        />
      </Table.Cell>
    </Table.Row>
  )
}

export default Row
