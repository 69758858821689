import { FilterRow, FilterValue } from "../Styled"
import JobCodeSearch from "../../../Search/JobCode"
import { OBJECT_NAME_TIP_SHARE } from "../../helpers"

export default ({ job_code, object_name, location, onSelect, onRemove }) => (
  <FilterRow
    name={object_name === OBJECT_NAME_TIP_SHARE ? "Distribute To" : "Job Code"}
  >
    {!!job_code && <FilterValue value={job_code.name} onRemove={onRemove} />}
    {!job_code && (
      <JobCodeSearch
        aligned="right"
        store_id={location?.id}
        onChange={({ job_code }) => onSelect({ ...job_code })}
      />
    )}
  </FilterRow>
)
