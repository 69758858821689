import {
  reporting_custom_team_adjustment,
  reporting_manage_custom_shifts,
  reporting_reprocess_report_export_run,
  settings_manage_pay_period,
  settings_manage_peer_to_peer,
  eta_permissions,
  reporting_permissions,
  settings_permissions,
  settings_manage_org_cc_tip_fee
} from "../../../../../../constants/permissions"
import { entity_type_store } from "../../../../../../constants/role"
import { toInt } from "../../../../../../helpers"
import {
  usePeerToPeerEnabled,
  useRecurringShiftsEnabled,
  useReportExportsEnabled,
  useSaleAssignedRulesEnabled
} from "../../../../../../hooks"
import useAuthorization from "../../../../../../hooks/authorization"

export const displayLocationNames = (selected_store_ids, locations) =>
  selected_store_ids
    .map(store_id => locations.find(l => l.id === store_id)?.name ?? "n/a")
    .toString()
    .replaceAll(",", ", ")

const getStoreIdsFromRoleEntities = role =>
  role.entities
    .filter(({ entity_type }) => entity_type === entity_type_store)
    .map(({ entity_id }) => toInt(entity_id))

export const useFormatRoleForDisplay = (role, mode) => {
  const store_ids = role ? getStoreIdsFromRoleEntities(role) : []
  return {
    name: mode === "copy" ? `${role?.name ?? ""} (copy)` : role?.name ?? "",
    description: mode === "copy" ? "" : role?.description ?? "",
    entity_type: role?.entity_type ?? "",
    store_ids,
    apply_all_stores: !store_ids.length,
    permissions:
      role?.permissions.reduce((acc, v) => ({ ...acc, [v.name]: true }), {}) ??
      {}
  }
}

// sets all manage permissions to either checked or unchecked
export const toggleManagePermissions = (manage_permissions, checked) =>
  manage_permissions.reduce(
    (acc, v) => ({
      ...acc,
      [v.name]: checked
    }),
    {}
  )

const usePermissionOrgSettingEnabled = () => {
  const report_exports_enabled = useReportExportsEnabled()
  const custom_team_adjustment_enabled = useSaleAssignedRulesEnabled()
  const recurring_shifts_enabled = useRecurringShiftsEnabled()
  const p2p_enabled = usePeerToPeerEnabled()

  const permission_org_setting_map = {
    [reporting_custom_team_adjustment]: custom_team_adjustment_enabled,
    [reporting_reprocess_report_export_run]: report_exports_enabled,
    [reporting_manage_custom_shifts]: recurring_shifts_enabled,
    [settings_manage_peer_to_peer]: p2p_enabled
  }

  const isPermissionOrgSettingEnabled = permission =>
    permission_org_setting_map[permission] ?? true

  return { isPermissionOrgSettingEnabled }
}

// generic hook for getting visible permissions per module
const useVisiblePermissions = (permissions, default_hidden = []) => {
  const { hasPermission } = useAuthorization()
  const { isPermissionOrgSettingEnabled } = usePermissionOrgSettingEnabled()

  // permissions that should be hidden if the corresponding org setting is disabled
  const hidden_permissions = default_hidden.filter(
    permission => !isPermissionOrgSettingEnabled(permission)
  )

  return (
    permissions
      .filter(({ name }) => !hidden_permissions.includes(name))
      // disable the permissions they don't have access to
      .map(permission => ({
        ...permission,
        disabled: !hasPermission(permission.name)
      }))
  )
}

// Permissions displayed under the Manage Earned Tip Access section
export const useVisibleEtaPermissions = () => {
  const visible_permissions = useVisiblePermissions(eta_permissions)
  return visible_permissions
}

// Permissions displayed under the Manage Reporting section
export const useVisibleReportingPermissions = () => {
  const hidden_permissions = [
    reporting_custom_team_adjustment,
    reporting_reprocess_report_export_run,
    reporting_manage_custom_shifts
  ]
  const visible_permissions = useVisiblePermissions(
    reporting_permissions,
    hidden_permissions
  )
  return visible_permissions
}

// settings permissions that are not allowed if a user is creating a store level role
export const store_role_excluded_settings_permissions = [
  settings_manage_pay_period,
  settings_manage_peer_to_peer,
  settings_manage_org_cc_tip_fee
]

// Permissions displayed under the Manage Settings section
export const useVisibleSettingsPermissions = entity_type => {
  const hidden_permissions = [
    settings_manage_peer_to_peer,
    settings_manage_pay_period
  ]
  let visible_permissions = useVisiblePermissions(
    settings_permissions,
    hidden_permissions
  )
  if (entity_type === entity_type_store) {
    // hide permissions not allowed for store roles
    visible_permissions = visible_permissions.filter(
      permission =>
        !store_role_excluded_settings_permissions.includes(permission.name)
    )
  }
  return visible_permissions
}
