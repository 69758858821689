import { useQuery } from "@apollo/client"

import PayoutsTable from "./Table"
import FilterBar from "./FilterBar"
import { parseSearchQuery } from "../../../helpers"

import { FETCH_PAYOUTS } from "../../../graphql/queries"
import { useSelectedLocation } from "../../../hooks/selectors"

export default ({ filters, setFilters }) => {
  const selected_location = useSelectedLocation()
  const { data, loading } = useQuery(FETCH_PAYOUTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      start_date: filters.start_date,
      end_date: filters.end_date,
      store_id: selected_location?.id,
      employee_id: filters.employee?.id,
      page: filters.page,
      per_page: filters.per_page,
      sort_dir: filters.sort_dir
    }
  })

  const values = data?.getPayouts?.data ?? []
  const total_rows = data?.getPayouts?.paginatorInfo?.total ?? 0
  const {
    total_amount_successful = 0,
    total_employee_fees = 0,
    total_employer_fees = 0
  } = data?.getPayouts?.aggregates ?? {}
  const total_fees = total_employee_fees + total_employer_fees

  const { show_failed_payout_modal } = parseSearchQuery(window.location.search)

  return (
    <>
      <FilterBar
        loading={loading}
        selected_location={selected_location}
        filters={filters}
        setFilters={filters => {
          setFilters({ ...filters, page: 1 })
        }}
      />
      {!loading && (
        <PayoutsTable
          rows={values}
          total_rows={total_rows}
          total_amount_successful={total_amount_successful}
          total_fees={total_fees}
          filters={filters}
          setFilters={setFilters}
          show_failed_payout_modal={show_failed_payout_modal}
        />
      )}
    </>
  )
}
