import React from "react"
import Styled from "styled-components"

import Modal from "./"
import Icon from "../../Icon"

import { colors } from "../../../constants"

const MenuOptions = Styled(({ children, ...props }) => (
  <div {...props}>
    <div
      onClick={e => {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
      }}
    >
      {children}
    </div>
  </div>
))`
  text-align: center;
  & > div {
    display: inline-block;
    color: ${colors.dark};
    background-color: ${colors.light};
    padding: 1rem;
    border-radius: 5px;
  }
`
const MenuOption = Styled(({ option, ...props }) => (
  <div {...props}>
    <div className="icon">
      <Icon name={option.icon} size="huge" />
    </div>
    <div className="title">{option.name}</div>
    {!!option.description && (
      <div className="description">{option.description}</div>
    )}
  </div>
))`
  display: inline-block;
  border: 1px solid transparent;
  border-radius: 5px;
  text-align: center;
  margin: 1rem;
  padding: 1.33rem;
  cursor: pointer;
  width: 17rem;
  vertical-align: top;

  &:hover {
    background-color: ${colors.white};
    border: 1px solid ${colors.light4};

    & > .icon {
      color: ${colors.info};
    }
  }

  & > .icon {
    color: ${colors.light5};
    transition: color 0.15s ease-in-out;
  }

  & > .title {
    font-size: 1.2rem;
    padding: 0.67rem 0.33rem 0.5rem 0.33rem;
  }

  & > .description {
    font-weight: 300;
    padding-top: 0.67rem;
    border-top: 0.5px solid ${colors.light5};
    min-height: 5rem;
  }
`

const Menu = ({
  open = true,
  options = [],
  centered = false,
  close_on_select = true,
  onClose
}) => (
  <Modal
    centered={centered}
    onClose={onClose}
    open={open}
    size="fullscreen"
    basic
  >
    <Modal.Content>
      <MenuOptions onClick={onClose}>
        {options.map(({ onSelect, ...option }) => (
          <MenuOption
            key={option.name}
            option={option}
            onClick={() => {
              if (close_on_select) onClose()
              onSelect()
            }}
          />
        ))}
      </MenuOptions>
    </Modal.Content>
  </Modal>
)

export default Menu
