import Styled from "styled-components"

export default Styled.div`
  ${({ inline }) => !!inline && `display: inline-block;`}
  ${({ min_width }) =>
    !!min_width &&
    `
    @media (max-width: ${min_width}) {
      display: none !important;
    }
  `}
  ${({ max_width }) =>
    !!max_width &&
    `
    @media (min-width: ${max_width}) {
      display: none !important;
    }
  `}
`
