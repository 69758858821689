import React, { Component } from "react"
import Styled from "styled-components"
import { Form, Label, Dropdown } from "semantic-ui-react"
import { Mutation } from "@apollo/client/react/components"

import Icon from "../../../Icon"
import Modal from "../../../Modal"
import Button from "../../../Button"
import SubmitButton from "../../../Button/Submit"
import AreYouSure from "../../../Modal/AreYouSure"
import JobCodeSearch from "../../../Search/JobCode"

import { toInt } from "../../../../helpers/number"
import { ASSIGN_JOB_CODE_TO_SALES } from "../../../../graphql/mutations"

const Intro = Styled.div`
  font-weight:300;
  margin-bottom: 1.33rem;
`

export class SelectSaleJobModal extends Component {
  state = {
    selected_job_code: null
  }

  render() {
    const { selected_job_code } = this.state
    const {
      user,
      onClose,
      onSelect,
      selected_location_id,
      changeSelectedLocation
    } = this.props

    const stores = user.stores.map(s => ({
      key: s.id,
      text: s.name,
      value: s.id
    }))

    return (
      <Modal size="mini" onClose={onClose} closeOnDimmerClick={false}>
        <Modal.Header onClose={onClose}>Batch Job Code Assignment</Modal.Header>
        <Modal.Content>
          <Intro>
            <p>
              Direct assignments take priority over employee assignments and
              worked shifts.
            </p>
            <p>
              Select a {stores.length > 1 && ` location and `} job code first.
              Then click "Select Sales" to specify which sales to adjust.
            </p>
          </Intro>
          <Form>
            {stores.length > 1 && (
              <Form.Field>
                <label>Select a Location</label>
                <Dropdown
                  clearable
                  selection
                  options={stores}
                  placeholder="Select one"
                  value={selected_location_id}
                  onChange={(e, { value }) => {
                    const store = user.stores.find(s => s.id == value)
                    if (!!store) {
                      changeSelectedLocation(store.id)
                    } else {
                      changeSelectedLocation(null)
                    }
                    this.setState({ selected_job_code: null })
                  }}
                />
              </Form.Field>
            )}

            <Form.Field>
              <label>Select a Job Code</label>
              {!selected_job_code && (
                <JobCodeSearch
                  fluid
                  disabled={!selected_location_id}
                  store_id={selected_location_id}
                  onChange={({ title, value }) =>
                    this.setState({
                      selected_job_code: { id: value, name: title }
                    })
                  }
                />
              )}
              {!!selected_job_code && (
                <Label as="a" size="large">
                  {selected_job_code.name}&nbsp;&nbsp;
                  <Icon
                    fitted
                    cursor="pointer"
                    name="remove"
                    onClick={() => this.setState({ selected_job_code: null })}
                  />
                </Label>
              )}
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>Close</Button>
          <SubmitButton
            label="Select Sales"
            icon="arrow right"
            disabled={!selected_job_code}
            onClick={() => onSelect(selected_job_code)}
            labelPosition="right"
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

export const ConfirmAssignJobModal = ({
  onClose,
  edit_sales,
  onCompleted,
  assign_job_code,
  selected_location_id,
  ...props
}) => (
  <Mutation
    mutation={ASSIGN_JOB_CODE_TO_SALES}
    onCompleted={data => {
      const success = !data.errors
      props.toast({
        type: !!success ? "success" : "danger",
        message: !!success ? (
          <>
            <p>
              Successfully assigned {assign_job_code.name} to{" "}
              {edit_sales.length} sales.
            </p>
            <p>
              It may take a few minutes for updates to appear in the breakdown.
            </p>
          </>
        ) : (
          `Failed to assign ${assign_job_code.name} to sales.`
        )
      })
      onCompleted()
    }}
  >
    {(assignJobCodeToSales, { loading: mutation_loading }) => {
      return (
        <AreYouSure
          submitted={!!mutation_loading}
          header="Are you sure?"
          body={
            <>
              <p>
                Are you sure you want to assign the{" "}
                <b>{assign_job_code.name}</b> job code to{" "}
                {edit_sales.length === 1
                  ? "this"
                  : `these ${edit_sales.length}`}{" "}
                sale
                {edit_sales.length > 1 && `s`}?
              </p>
              <p>
                It may take a few minutes for updates to appear in the
                breakdown.
              </p>
            </>
          }
          onConfirm={() => {
            const variables = {
              sale_ids: edit_sales.map(id => toInt(id)),
              store_id: toInt(selected_location_id),
              job_code_id: toInt(assign_job_code.id)
            }
            assignJobCodeToSales({ variables })
          }}
          onClose={onClose}
        />
      )
    }}
  </Mutation>
)
