import ScheduleRow from "./ScheduleRow"
import JobCodesRow from "./JobCodesRow"
import LocationsRow from "./LocationsRow"
import EffectiveDateRow from "./EffectiveDateRow"
import RevenueCentersRow from "./RevenueCentersRow"
import DistributionSourcesRow from "./DistributionSourcesRow"
import DistributionFrequencyRow from "./DistributionFrequencyRow"
import { Table } from "../../../../Shared"

import { colors } from "../../../../../constants"
import { DISTRO_SOURCE_TYPE_CASH } from "../../../helpers"

export default ({
  name,
  locations,
  revenue_centers,
  effective_date,
  schedules,
  distribution_sources,
  distribution_source_type,
  distribution_method,
  apply_job_code_weights,
  job_codes
}) => (
  <Table fitted>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell
          colSpan={2}
          color={colors.dark}
          background={colors.light}
        >
          {name}
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      <LocationsRow locations={locations} />
      {distribution_source_type !== DISTRO_SOURCE_TYPE_CASH && (
        <RevenueCentersRow revenue_centers={revenue_centers} />
      )}
      <EffectiveDateRow effective_date={effective_date} />
      <ScheduleRow schedules={schedules} />
      <DistributionSourcesRow
        source_type={distribution_source_type}
        sources={distribution_sources}
      />
      <DistributionFrequencyRow distribution_method={distribution_method} />
      <JobCodesRow
        weight_by_job_code={job_codes}
        apply_job_code_weights={apply_job_code_weights}
      />
    </Table.Body>
  </Table>
)
