import { useState } from "react"
import Styled from "styled-components"
import { useQuery } from "@apollo/client"

import {
  Link,
  Badge,
  Modal,
  Button,
  Loader,
  MultiSelect
} from "../../../../../Shared"

import { SHARED_REVENUE_CENTERS } from "../../../../../../graphql/queries"

export default Styled(({ className, selected, locations, onUpdate }) => {
  const [edit, setEdit] = useState(false)
  return (
    <div className={className}>
      {selected.length === 0 && <span>No filter applied</span>}
      {selected.length > 0 &&
        selected.map(rev_center => (
          <Badge key={rev_center} size="small">
            {rev_center}
          </Badge>
        ))}
      <EditRevCentersLink selected={selected} onClick={() => setEdit(true)} />
      {!!edit && (
        <SelectRevenueCentersModal
          selected={selected}
          store_ids={locations}
          onClose={() => setEdit(false)}
          onSubmit={selected => {
            setEdit(false)
            onUpdate({ revenue_centers: selected })
          }}
        />
      )}
    </div>
  )
})`
  margin-top: 0.5rem;
`

const EditRevCentersLink = Styled(({ selected, ...props }) => (
  <Link {...props}>edit</Link>
))`
  line-height: 2rem;
  margin-left: 0.5rem;
`

const SelectRevenueCentersModal = ({
  selected: initial_selection,
  store_ids,
  onSubmit,
  onClose
}) => {
  const [selected, setSelected] = useState(initial_selection)

  const { data } = useQuery(SHARED_REVENUE_CENTERS, {
    fetchPolicy: "cache-and-network",
    variables: { store_ids }
  })

  let shared_rev_centers = data?.sharedRevenueCenterNames

  return (
    <Modal size="tiny" onClose={onClose} closeOnDimmerClick={false}>
      <Modal.Header>Select Revenue Centers</Modal.Header>
      <Modal.Content>
        <ModalPrompt>
          Which Revenue Centers should this Tip Pool to apply to?
        </ModalPrompt>
        {!shared_rev_centers && <Loader />}
        {!!shared_rev_centers && (
          <MultiSelect
            fluid
            search
            max_labels={5}
            selectOnBlur={false}
            closeOnChange={true}
            can_select_all={true}
            selectOnNavigation={false}
            noResultsMessage="All Revenue Centers Selected"
            placeholder="No filter applied"
            value={selected}
            options={[...shared_rev_centers]
              // sort by store name in dropdown
              .sort((a, b) => a.localeCompare(b))
              .map(rc => ({
                value: rc,
                text: rc
              }))}
            onChange={(e, d) => setSelected([...d.value])}
          />
        )}
        {selected.length === 0 && (
          <ModalPrompt>
            <p>
              <i>Note:</i> When no filter is selected, your pool will apply to
              all sales regardless of Revenue Center.
            </p>
          </ModalPrompt>
        )}
        {selected.length > 0 && (
          <ModalPrompt>
            <p>
              <i>Note:</i> Your filter will prevent this pool from applying to
              sales without a Revenue Center.
            </p>
          </ModalPrompt>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Cancel</Button>
        <Button primary onClick={() => onSubmit(selected)}>
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

const ModalPrompt = Styled.div`
  font-weight: 300;
  margin: 1rem 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`
