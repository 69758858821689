import { useState } from "react"
import Styled from "styled-components"
import { useMutation, useQuery } from "@apollo/client"

import IconLogo from "../Logo/IconLogoSvg"
import { Modal, Link, Loader, Button, Checkbox } from "../Shared"

import { TERMS_OF_SERVICE_LATEST } from "../../graphql/queries"
import {
  UPDATE_USER_SETTING,
  UPDATE_USER_SERVICE_AGREEMENTS
} from "../../graphql/mutations"
import {
  colors,
  project_info,
  completed_user_onboarding_settings_key
} from "../../constants"
import { layoutContainerNode } from "../../helpers"

const Logo = Styled(({ className }) => (
  <div className={className}>
    <IconLogo primary={colors.success} secondary={colors.dark3} size="medium" />
  </div>
))`
  position: absolute;
  right: 1rem;
  top: 0.8rem;
`
const P = Styled.p``

export default ({ onCompleted }) => {
  const [accept_selected, setAccepted] = useState(false)

  const { loading: fetching_terms, data } = useQuery(TERMS_OF_SERVICE_LATEST)
  const [
    updateUserSetting,
    { loading: pending_update_setting }
  ] = useMutation(UPDATE_USER_SETTING, { onCompleted })
  const [
    updateUserServiceAgreement,
    { loading: pending_accept_terms }
  ] = useMutation(UPDATE_USER_SERVICE_AGREEMENTS, {
    onCompleted: () =>
      updateUserSetting({
        variables: {
          key: completed_user_onboarding_settings_key,
          value: "1"
        }
      })
  })

  const terms_id = data?.termsOfServiceActive?.id
  const accept_submitted = pending_accept_terms || pending_update_setting

  return (
    <Modal size="tiny" mountNode={layoutContainerNode()}>
      <Modal.Header>
        Thanks for Choosing {project_info.name}!<Logo />
      </Modal.Header>
      <Modal.Content>
        <P>We're glad you're here.</P>
        {!!fetching_terms && <Loader />}
        {!fetching_terms && (
          <>
            <P>
              Before we get started, please review and accept our{" "}
              <Link
                target="_blank"
                href={data.termsOfServiceActive.document_url}
              >
                Terms and Conditions
              </Link>
              .
            </P>
            <Checkbox
              label={`I have read and agree to the ${project_info.name} Service Agreement.`}
              checked={accept_selected}
              onChange={checked => setAccepted(checked)}
            />
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          disabled={!accept_selected || accept_submitted}
          loading={accept_submitted}
          onClick={() => {
            updateUserServiceAgreement({
              variables: {
                input: {
                  termsOfService: {
                    syncWithoutDetaching: [
                      { id: Number(terms_id), agreed: true }
                    ]
                  }
                }
              }
            })
          }}
        >
          Confirm
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
