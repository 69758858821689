import { useCallback } from "react"
import moment from "moment"

import { toInt } from "../../helpers"
import { useSelector, useOrganization, useBusinessDates } from "./"

export const useReportLockThreshold = () =>
  useSelector(() => {
    const organization = useOrganization()
    const { businessStartFromDate } = useBusinessDates()
    const min_processing_key = (organization?.stores ?? [])
      .sort((a, b) => (a.id > b.id ? -1 : 1))
      .map(({ min_processing_time }) => min_processing_time)
      .join(".")

    return useCallback(
      (location_id = null, default_threshold = null) => {
        return reportLockThreshold(
          organization,
          location_id,
          default_threshold,
          businessStartFromDate
        )
      },
      [min_processing_key]
    )
  })

export const useGlobalLockThreshold = (location_ids = []) => {
  const reportLockThreshold = useReportLockThreshold()
  if (location_ids.length > 0) {
    // return max threshold for location ids passed in
    return location_ids
      .map(id => reportLockThreshold(id))
      .reduce((acc, time) => (!acc ? time : moment.max(acc, time)), null)
  }
  // return global max threshold
  return reportLockThreshold()
}

// calculate threshold value
const reportLockThreshold = (
  organization,
  location_id = null,
  default_threshold = null,
  businessStartFromDate
) => {
  // threshold to be calculated
  let threshold = null

  // if location_id is given, find location, use location min_processing_time
  let location = !location_id
    ? null
    : (organization?.stores ?? []).find(({ id }) => id === toInt(location_id))

  if (location?.min_processing_time) {
    threshold = businessStartFromDate(location.min_processing_time, false)
  } else {
    threshold = [default_threshold, organization?.min_processing_time]
      .filter(time => !!time)
      .map(time => businessStartFromDate(time, false))
      // get the max of defaults or null if no defaults
      .reduce((acc, time) => (!acc ? time : moment.max(acc, time)), null)
  }

  return threshold
}
