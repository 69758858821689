import React from "react"
import { Icon, Loader } from "semantic-ui-react"

import Popup from "../../Popup"
import { ItemNameWrapper, IconWrapper, ItemWrapper } from "./Styled"

export default ({
  expanded,
  onClick,
  onAuxClick = () => {},
  label,
  active,
  data_tip,
  header = false,
  loading = false,
  visible = true,
  icon = ""
}) => (
  <>
    {!!visible && (
      <ItemWrapper
        active={active}
        onClick={onClick}
        header={header}
        onAuxClick={onAuxClick}
        id={`sidebar-item-${label.toLowerCase().replace(/\s/g, "-")}`}
      >
        <ItemNameWrapper name={label} />
        <Popup
          content={!expanded && !!data_tip && data_tip.length ? data_tip : null}
          position="right center"
          pointing
          disabled={!!expanded || !data_tip}
        >
          <IconWrapper has_icon={!!icon} expanded={expanded}>
            {!loading && <Icon name={icon} />}
            {!!loading && <Loader size="small" active inverted />}
          </IconWrapper>
        </Popup>
      </ItemWrapper>
    )}
  </>
)
