import Styled from "styled-components"

import InfoIcon from "../Icon/Info"

import { colors } from "../../constants"

// leave space for a floated navigation button
const DEFAULT_RIGHT_PAD = "10rem"

export default Styled(({ right_pad, children, ...props }) => (
  <div {...props}>
    <div>
      <InfoIcon />
      &nbsp;
      {children}
    </div>
  </div>
))`
  line-height: 2.33rem;
  position: relative;
  margin-bottom: 1.5rem;

  & > div {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.67rem;
    display: inline-block;
    color: ${colors.dark3};
    ${({ right_pad = DEFAULT_RIGHT_PAD }) =>
      !right_pad ? "" : `padding-right: ${right_pad};`}
    &&&& .icon {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
`
