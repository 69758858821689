import { Component } from "react"

import Styled from "styled-components"

import { Modal, Button, Message, MultiSelect } from "../Shared"

const SelectStore = ({ ...props }) => (
  <MultiSelect
    {...props}
    fluid
    search
    max_labels={5}
    selectOnBlur={false}
    closeOnChange={true}
    can_select_all={true}
    selectOnNavigation={false}
    noResultsMessage="All Locations Selected"
    placeholder="Select one or more locations"
  />
)

const Prompt = Styled.div`font-weight: 300;`
const ModalSection = Styled.div`
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
`

export default class extends Component {
  state = { selected_store_ids: [] }
  componentDidMount() {
    const { default_selected_store_ids } = this.props
    if (!!default_selected_store_ids) {
      this.setState({ selected_store_ids: default_selected_store_ids })
    }
  }

  render() {
    const {
      user,
      prompt,
      header_text = "Select Locations",
      confirm_text = "Confirm",
      message,
      onSubmit,
      onClose
    } = this.props
    const { selected_store_ids } = this.state
    return (
      <Modal size="tiny" onClose={onClose} closeOnDimmerClick={false}>
        <Modal.Header>{header_text}</Modal.Header>
        <Modal.Content>
          {!!message && (
            <ModalSection>
              <Message type="info" message={message} />
            </ModalSection>
          )}
          {!!prompt && (
            <ModalSection>
              <Prompt>{prompt}</Prompt>
            </ModalSection>
          )}
          <ModalSection>
            <SelectStore
              options={user.stores
                // sort by store name in dropdown
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(store => ({
                  value: store.id,
                  text: store.name
                }))}
              onChange={(e, d) =>
                this.setState({
                  selected_store_ids: [...d.value]
                })
              }
              value={selected_store_ids}
            />
          </ModalSection>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>Cancel</Button>
          {selected_store_ids.length > 0 && (
            <Button primary onClick={() => onSubmit(selected_store_ids)}>
              {confirm_text}
            </Button>
          )}
        </Modal.Actions>
      </Modal>
    )
  }
}
