import { reviewStep } from "./Sequence/Review"
import { basicsStep } from "./Sequence/Basics"
import { jobCodesStep } from "./Sequence/JobCodes"
import { sourcesStep } from "./Sequence/DistributionSources"
import { SequenceModal } from "../../../Shared"

import {
  POOL_EDITOR_MODE_EDIT,
  POOL_EDITOR_MODE_CREATE,
  useFormatPoolForDisplay
} from "../helpers"
import { RULE_TYPE_POOL, formatSchedulesForSubmit } from "../../helpers"
import { useStateObject, useBusinessDates } from "../../../../hooks"

// sequence steps
const steps = (props, updateSequenceState) =>
  [basicsStep, sourcesStep, jobCodesStep, reviewStep].map(step =>
    step(props, updateSequenceState)
  )

// editor sequence component
export default ({
  pool,
  submitPool,
  mode = POOL_EDITOR_MODE_CREATE,
  ...props
}) => {
  const { businessStartFromDate } = useBusinessDates()
  const [state, setState] = useStateObject(
    useFormatPoolForDisplay(pool ?? {}, mode)
  )

  return (
    <SequenceModal
      size="small"
      header={
        mode === POOL_EDITOR_MODE_EDIT ? "Edit Tip Pool" : "Create a Tip Pool"
      }
      steps={steps({ ...state, editor_mode: mode }, setState)}
      onSubmit={() =>
        submitPool(
          formatPoolForSubmit(mode, {
            ...state,
            effective_date: businessStartFromDate(state.effective_date)
          })
        )
      }
      {...props}
    />
  )
}

// transform editor state to gql input structure
const formatPoolForSubmit = (
  mode,
  {
    id,
    name,
    job_codes,
    locations,
    schedules,
    effective_date,
    revenue_centers,
    distribution_method,
    distribution_sources
  }
) => {
  return {
    variables: {
      id: mode === POOL_EDITOR_MODE_EDIT ? id : undefined,
      input: {
        rule: {
          name,
          effective_date,
          distribution_method,
          type: RULE_TYPE_POOL
        },
        schedules: formatSchedulesForSubmit(schedules),
        job_codes: Object.keys(job_codes).map(name => ({
          name,
          distribution_weight: job_codes[name]
        })),
        distribution_sources,
        store_ids: mode === POOL_EDITOR_MODE_EDIT ? undefined : [...locations],
        revenue_centers
      }
    }
  }
}
