import { useState } from "react"
import { useMutation } from "@apollo/client"

import Form from "./Form"
import { FormPanel, Background, Foreground, LoginPageWrapper } from "./Styled"

import { LOGIN } from "../../graphql/mutations"

const DEFAULT_ERROR = "An error has occurred, please contact support."
const INVALID_CREDENTIALS_ERROR = "Invalid login credentials."

export default props => {
  const [error, setError] = useState(null)
  const [login, { loading }] = useMutation(LOGIN, {
    onError: () => setError(DEFAULT_ERROR),
    onCompleted: ({ login }) => {
      if (login.token) {
        props.syncUser()
        props.loginSuccess()
      } else if (!!login.message) {
        setError(login.message)
      } else {
        setError(INVALID_CREDENTIALS_ERROR)
      }
    }
  })
  return (
    <LoginPageWrapper>
      <Background />
      <Foreground>
        <FormPanel>
          <Form
            {...props}
            loading={!!loading}
            request_error={error}
            login={(email, password) => {
              login({
                variables: {
                  email,
                  password
                }
              })
            }}
          />
        </FormPanel>
      </Foreground>
    </LoginPageWrapper>
  )
}
