import { Table } from "../../../Shared"

import { useSelectedLocation } from "../../../../hooks"
import useAuthorization from "../../../../hooks/authorization"
import { rules_manage } from "../../../../constants/permissions"

export default () => {
  const { hasPermission } = useAuthorization()
  const can_edit = hasPermission(rules_manage)
  const selected_location = useSelectedLocation()
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Name</Table.HeaderCell>
        {!selected_location?.id && (
          <Table.HeaderCell>Location</Table.HeaderCell>
        )}
        <Table.HeaderCell>Source</Table.HeaderCell>
        <Table.HeaderCell>Rate</Table.HeaderCell>
        <Table.HeaderCell>Frequency</Table.HeaderCell>
        <Table.HeaderCell>Effective Date</Table.HeaderCell>
        <Table.HeaderCell>Created Date</Table.HeaderCell>
        {!!can_edit && <Table.HeaderCell />}
      </Table.Row>
    </Table.Header>
  )
}
