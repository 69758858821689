import Button from "./"
import Icon from "../../Icon"

const IconButton = ({ icon, labelPosition = "right", children, ...props }) => (
  <Button icon labelPosition={!children ? undefined : labelPosition} {...props}>
    {children}
    <Icon name={icon} />
  </Button>
)

export default IconButton
