import EtaAlerts from "./Alert"
import FilterBar from "./FilterBar"
import OverviewTable from "./Table"

import {
  // hooks
  useOrganization,
  useSelectedLocation,
  // queries
  queryFetchReport,
  queryUsersInEtaRegistration
} from "../../../hooks"
import { ETA_REPORT_NAME, ETA_REPORT_GROUP_BY } from "../helpers"
import EmployeePayoutsModal from "./Modal/EmployeePayouts"
import { useState } from "react"
import useAuthorization from "../../../hooks/authorization"
import { eta_manage_enrollment } from "../../../constants/permissions"

export default ({
  pay_periods,
  filters,
  requestFailedPayoutDetails,
  setFilters
}) => {
  const { hasPermission } = useAuthorization()
  const {
    data: users_in_registration,
    refetch: refetchUsersInRegistration
  } = queryUsersInEtaRegistration()
  const [employee_payout_modal, setEmployeePayoutModal] = useState(null)

  const selected_location = useSelectedLocation()
  const selected_pay_period = pay_periods[filters.selected_pay_period]

  const {
    data: report_data,
    loading: report_loading,
    refetch: refetchReport
  } = queryFetchReport({
    report_name: ETA_REPORT_NAME,
    group_by: ETA_REPORT_GROUP_BY,
    pay_period_id: selected_pay_period?.id,
    store_id: selected_location?.id
  })

  const organization = useOrganization()
  const payout_buffer = organization.eta_config?.payout_buffer ?? 0

  const refetchReportAndRegistration = () => {
    refetchReport()
    refetchUsersInRegistration()
  }

  const requestEmployeePayouts = employee => {
    setEmployeePayoutModal(employee)
  }

  return (
    <>
      {hasPermission(eta_manage_enrollment) && (
        <EtaAlerts
          onReviewCompleted={refetchReportAndRegistration}
          users_in_registration={users_in_registration?.usersInEtaRegistration}
        />
      )}
      <FilterBar
        filters={filters}
        loading={report_loading}
        pay_periods={pay_periods}
        setFilters={setFilters}
        selected_location={selected_location}
      />
      {!report_loading && (
        <OverviewTable
          filters={filters}
          setFilters={setFilters}
          payout_buffer={payout_buffer}
          onPayoutCompleted={refetchReport}
          pay_period={selected_pay_period}
          selected_location={selected_location}
          report_id={report_data?.fetchReport?.id ?? null}
          report_values={report_data?.fetchReport?.values ?? []}
          requestEmployeePayouts={requestEmployeePayouts}
          requestFailedPayoutDetails={requestFailedPayoutDetails}
        />
      )}
      {!!employee_payout_modal && (
        <EmployeePayoutsModal
          employee={employee_payout_modal}
          selected_pay_period={selected_pay_period}
          onClose={() => setEmployeePayoutModal(null)}
        />
      )}
    </>
  )
}
