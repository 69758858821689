import Styled from "styled-components"
import { Redirect } from "react-router"
import { useMutation, useQuery } from "@apollo/client"

import RuleEditor from "../Editor"
import { Link, Loader } from "../../../Shared"

import { paths } from "../../../../constants"
import { formatRuleForDisplay } from "../helpers"
import { RULE_BY_ID } from "../../../../graphql/queries"
import { UPDATE_RULE } from "../../../../graphql/mutations"
import { CONTENT_MIN_HEIGHT } from "../../../RouteContent/Styled"
import useAuthorization from "../../../../hooks/authorization"
import { rules_manage } from "../../../../constants/permissions"

export default props => {
  const { hasPermission } = useAuthorization()
  const can_edit = hasPermission(rules_manage)

  const { data, loading: query_loading } = useQuery(RULE_BY_ID, {
    skip: !can_edit,
    fetchPolicy: "network-only",
    variables: { id: props.match.params.id }
  })

  const [updateRule, { loading: mutation_loading }] = useMutation(UPDATE_RULE, {
    onCompleted: () => {
      props.toast(SUCCESS_TOAST_PAYLOAD)
      props.navigateTo(paths.distributionRules)
    },
    onError: () => props.toast(ERROR_TOAST_PAYLOAD)
  })

  return (
    <>
      {/* redirect non org admins back to rules index */}
      {!can_edit && <Redirect to={paths.distributionRules} />}
      {!!can_edit && (
        <>
          {!!query_loading && (
            <LoaderWrapper>
              <Loader active inline />
            </LoaderWrapper>
          )}
          {!query_loading && (
            <RuleEditor
              {...props}
              is_edit={true}
              title="Edit Rule"
              rule={formatRuleForDisplay(data.rule)}
              loading={mutation_loading}
              onRuleSubmit={(rule, id) =>
                updateRule({
                  variables: {
                    id: id,
                    input: {
                      ...rule
                    }
                  }
                })
              }
            />
          )}
        </>
      )}
    </>
  )
}

// helpers
const SUCCESS_TOAST_PAYLOAD = {
  type: "success",
  message: (
    <>
      <p>Rule updated successfully.</p>
      <p>
        It may take some time for changes to appear in your Breakdown report.
      </p>
    </>
  )
}

const ERROR_TOAST_PAYLOAD = {
  ttl_ms: 30000,
  type: "error",
  title: "Oh No!",
  message: (
    <>
      <div>An unexpected error ocurred while trying to update this rule.</div>
      <div>Try refreshing the page and updating the rule again.</div>
      <div>
        If that doesn't work,{" "}
        <Link href={paths.contact} target="_blank">
          let our support team know
        </Link>
        .
      </div>
    </>
  )
}

// Styled Components
const LoaderWrapper = Styled.div`
  padding: 2.2rem 2.5rem 2rem 2.5rem;
  min-height: ${CONTENT_MIN_HEIGHT};
`
