import { useStateObject } from "../../../../../hooks"
import { Modal, Button, Link, Message } from "../../../../Shared"
import {
  SHOW_FILTERS_THRESHOLD,
  FILTER_ALL,
  filterRegistrationInProgressRows
} from "./helpers"
import RegistrationInProgressTable from "./Table"

const RegistrationInProgress = ({ users, onClose }) => {
  const [state, setState] = useStateObject({
    user_search: "",
    state_filter: FILTER_ALL,
    invite_sent: false
  })
  const { user_search, state_filter, invite_sent } = state

  const filtered_rows = filterRegistrationInProgressRows(users, {
    user_search,
    state_filter
  })
  return (
    <Modal open onClose={onClose}>
      <Modal.Header>Sign-Up in Progress</Modal.Header>
      <Modal.Content scrolling style={{ minHeight: "20rem" }}>
        {!!invite_sent && (
          <Message
            inline
            type="info"
            onDismiss={() => setState({ invite_sent: false })}
            message={`Invite sent to ${invite_sent}.`}
          />
        )}
        <RegistrationInProgressTable
          rows={filtered_rows}
          show_filters={users.length > SHOW_FILTERS_THRESHOLD}
          filters={state}
          onFilterChange={setState}
          onInviteSent={user =>
            setState({
              invite_sent: user.name
            })
          }
        />
        {filtered_rows.length === 0 && (
          <div>
            <br />
            <span>No users match your filters.</span>&nbsp;
            <Link
              onClick={() =>
                setState({
                  user_search: "",
                  state_filter: FILTER_ALL
                })
              }
            >
              Clear Filters
            </Link>
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default RegistrationInProgress
