import { useState, useEffect, useRef } from "react"

import Styled from "styled-components"
import { Table as SemanticTable } from "semantic-ui-react"

import Icon from "../Icon"
import Popup from "../Popup"

import { colors } from "../../../constants"

export const StyledSemanticTable = Styled(SemanticTable)`
  &&&&.ui.table {
    & tr td, & tr th {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    & tbody > tr {
      &:last-child {
        & > :first-child {
          border-bottom-left-radius: 0.285714rem;
        }
        & > :last-child {
          border-bottom-right-radius: 0.285714rem;
        }
      }
    }
    &[class*="very basic"] {
      & th {
        padding-top: 1rem;
      }
    }
  }
`

export const StyledActionCell = Styled(SemanticTable.Cell)`
  cursor: default;
`

export const Control = Styled.div`
  display: inline-block;
  margin-left: 1rem;
`

export const CustomControl = Styled(Control)`
  float: left;
  margin-left: 0;
  margin-bottom: 1px;
  & > * {
    margin-right: 0.5rem !important;
    margin-top: 1px !important;
  }
  & > .ui.input > input {
    padding: .755em 1em;
  }
  & .ui.button {
    padding-top: calc(.86em) !important;
    padding-bottom: calc(.86em) !important;
  }
`

export const LinkRow = Styled(SemanticTable.Row)`
  color: ${colors.link};
  &&& .icon {
    margin-right: 0;
  }
`

export const TotalsRow = Styled(SemanticTable.Row)`
  font-weight: bold;
  background-color: ${colors.light};
  &&&& > td {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
  }
`
export const CopyToClipboardCell = Styled(
  ({
    value,
    children,
    max_width,
    enable_copy = true,
    popup_on_overflow = true,
    popup_on_overflow_delay = 750,
    ...props
  }) => {
    const [expand_popup_enabled, setExpandPopupEnabled] = useState(false)
    const [value_copied, setValueCopied] = useState(false)
    const value_ref = useRef()
    const icon_ref = useRef()
    useEffect(() =>
      setExpandPopupEnabled(
        value_ref.current.scrollWidth > value_ref.current.clientWidth
      )
    )
    return (
      <Popup
        hoverable
        size="tiny"
        content="copied!"
        context={icon_ref}
        position="top center"
        open={value_copied}
        disabled={!value_copied}
        onClose={() => setValueCopied(false)}
      >
        <SemanticTable.Cell {...props}>
          <div>
            <Popup
              flowing
              content={value ?? children}
              delay={popup_on_overflow_delay}
              disabled={!expand_popup_enabled || value_copied}
            >
              <div ref={value_ref}>{children ?? value}</div>
            </Popup>
            {enable_copy && (
              <div ref={icon_ref}>
                &nbsp;
                <Icon
                  fitted
                  cursor="pointer"
                  color={value_copied ? colors.green : colors.success}
                  name={value_copied ? "check circle" : "copy outline"}
                  onClick={() => {
                    navigator.clipboard.writeText(value ?? children)
                    setValueCopied(true)
                  }}
                />
              </div>
            )}
          </div>
        </SemanticTable.Cell>
      </Popup>
    )
  }
)`
  & > div {
    line-height: 1em;
    white-space: nowrap;
    & > div {
      ${({ max_width }) => (!max_width ? "" : `max-width: ${max_width};`)};
      vertical-align: bottom;
      vertical-align: inheret;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  & > div > div {
    line-height: 1em;
    & > i.icon {
      width: 1rem;
      opacity: 0;
      display: inline-block !important;
      transition: opacity 80ms ease-out;
    }
  }
  &:hover div > div > i.icon {
    opacity: 1;
  }
`
