import Styled from "styled-components"

import { cssTextSelectionDisabled } from "../../Shared"

import { colors, style } from "../../../constants"

/* Theme Variables */

// primary theme
const primary_background = colors.dark3

// header items
const header_background = colors.dark3
const header_background_active = colors.dark3
const header_icon_color = colors.tween
const header_icon_color_active = colors.white
const header_color = colors.white
const header_color_active = colors.white

// default items
const item_background = colors.dark2
const item_background_active = colors.dark3
const item_icon_color = colors.dark5
const item_icon_color_active = colors.info
const item_color = colors.light4
const item_color_active = colors.white

// footer items
const footer_background = "transparent"
const footer_color_inactive = colors.tween
const footer_color_active = colors.white

// non mobile full page height for sidebar wrapper
const SIDEBAR_MIN_HEIGHT = `calc(100vh - ${style.navbar.height})`

export const SidebarWrapper = Styled(({ expanded, className, ...props }) => (
  <div className={className}>
    <div
      className={`sidebar-${!!expanded ? "expanded" : "collapsed"}`}
      {...props}
    />
  </div>
))`
  background-color: ${primary_background};

  @media (min-width: ${style.mobile_breakpoint}px) {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    min-height: ${SIDEBAR_MIN_HEIGHT};
    width: ${({ expanded }) =>
      !!expanded ? style.sidebar.expanded : style.sidebar.collapsed};
  }

  transition-property: width;
  transition-duration: ${style.sidebar.animation_duration};
  transition-timing-function: ease-in-out;

  &::-webkit-scrollbar {
      display: none;
  }
`

/* Menu Item Components */
export const ItemNameWrapper = Styled(({ name, ...props }) => (
  <div {...props}>
    <span>{name}</span>
  </div>
))`
  font-size: 1.1rem;
  line-height: ${style.navbar.height};
  width: 100%;
  padding: 0 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const IconWrapper = Styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: ${style.sidebar.collapsed};
`

export const ItemWrapper = cssTextSelectionDisabled(
  Styled.div`${({ active, header }) => {
    const hover_background = header
      ? header_background_active
      : item_background_active
    const hover_icon_color = !header
      ? item_icon_color_active
      : header_icon_color_active
    const hover_label_color = !header ? item_color_active : header_color_active

    let background_color = active ? item_background_active : item_background
    let icon_background = active ? item_background_active : item_background
    let icon_color = active ? item_icon_color_active : item_icon_color
    let label_color = active ? item_color_active : item_color

    if (header) {
      background_color = active ? header_background_active : header_background
      icon_background = active ? header_background_active : header_background
      icon_color = active ? header_icon_color_active : header_icon_color
      label_color = active ? header_color_active : header_color
    }

    return `
    cursor: pointer;
    overflow: hidden;
    line-height: 1rem;
    position: relative;
    letter-spacing: 0.03em;
    height: ${style.navbar.height};
    background-color: ${background_color};

    & > ${ItemNameWrapper} > span {
      font-weight: 300;
      color: ${label_color};
    }
    & > ${IconWrapper} {
      padding: 0;
      background-color: ${icon_background};

      & > .icon {
        margin: 0 auto;
        display: inherit;
        font-size: 1.4rem;
        color: ${icon_color};
        line-height: ${style.navbar.height};
      }
    }

    &&&:hover {
      background-color: ${hover_background};

      & > ${IconWrapper} {
        background-color: ${hover_background};

        & > .icon {
          color: ${hover_icon_color};
        }
      }
      & > ${ItemNameWrapper} > span {
        color: ${hover_label_color};
      }
    }
  `
  }}`
)

/* Footer Components */
export const FooterPanel = Styled.div`
  /*position: absolute;
  bottom: 0;*/
  width: 100%;
`

export const FooterIconWrapper = Styled.div`
  text-align: center;
  padding: 0.67rem 1rem;
  position: absolute;
  right: 0rem;
  top: 0;
  bottom: 0;
  width: ${style.sidebar.collapsed};
`

export const RotatingArrowIcon = Styled.div`
  cursor: pointer;
  padding: 0;
  width: 100%;
  height: 100%;
  line-height: 2.67rem;
  border-radius: 500rem;
  transition: transform 0.3s ease-out;
  transform: ${({ expanded }) =>
    !!expanded ? `rotate(-180deg)` : `rotate(0deg)`}};

  & .icon {
    margin: 0 auto !important;
    display: inherit;
    font-size: 1.4em;
  }
`

export const FooterItemWrapper = Styled.div`
  position: relative;
  overflow: hidden;
  height: ${style.navbar.height};
  cursor: pointer;
  color: ${footer_color_inactive};
  font-weight: 300;
  letter-spacing: 0.03em;
  background-color: ${footer_background};

  &:hover {
    & ${RotatingArrowIcon} {
      & .icon {
        color: ${footer_color_active};
      }
    }
  }
`

export const FooterNameWrapper = Styled(({ name = "", ...props }) => (
  <div {...props}>
    <span>{name}</span>
  </div>
))`
  font-size: 1.1rem;
  line-height: calc(${style.navbar.height} + 0.1rem);
  width: 100%;
  padding: 0 1rem;
  color: ${footer_color_active};
`

/* Submenu Components */
export const SubMenuWrapper = Styled.div`
  transition-property: height;
  transition-duration: 0.15s;
  transition-timing-function: ease-out;
  height: 0em;
  overflow: hidden;
  height: ${({ open, length }) => (open ? 2.6 * length : 0)}em;
`

export const SubMenuItem = Styled.div`
  height: 2.6em;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  line-height: 1em;
  font-size: 1em;
  background-color: ${colors.dark2};
  color: ${colors.light6};
  font-weight: 300;
  padding: 0.75em 3.5em 0.75em 1.5em;

  &&& .icon {
    visibility: hidden;
  }
  ${({ selected }) =>
    !!selected &&
    `color: ${colors.info};
    font-weight: 600;
    background-color: ${colors.dark4};
    &&& .icon {
    visibility: visible;
  }`};

  ${({ selected }) =>
    !selected &&
    `
    &:hover {
      color: ${colors.light2};
      background-color: ${colors.dark3};
    }
  `}
`
