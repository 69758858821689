import Table from "../../../Table"
import { StatusCellDescription } from "./Styled"

import {
  SORT_DIRECTION_ASC,
  SORT_DIRECTION_DESC,
  TABLE_COLUMN_STATUS,
  TABLE_COLUMN_LOCATION,
  TABLE_COLUMN_AMT_SENT,
  TABLE_COLUMN_LAST_NAME,
  TABLE_COLUMN_FIRST_NAME,
  TABLE_COLUMN_AMT_RESERVED,
  TABLE_COLUMN_AMT_ELIGIBLE,
  TABLE_COLUMN_AMT_AVAILABLE,
  TABLE_COLUMN_AMT_PAYABLE
} from "../../helpers"

export default ({
  sort_field,
  sort_direction,
  setSort,
  show_location = false
}) => (
  <Table.Row>
    <Table.HeaderCell
      collapsing
      description={<StatusCellDescription />}
      textAlign="center"
      sorted={sort_field === TABLE_COLUMN_STATUS ? sort_direction : undefined}
      onClick={() =>
        setSort(
          TABLE_COLUMN_STATUS,
          sort_field === TABLE_COLUMN_STATUS &&
            sort_direction === SORT_DIRECTION_DESC
            ? SORT_DIRECTION_ASC
            : SORT_DIRECTION_DESC
        )
      }
    >
      Status
    </Table.HeaderCell>
    <Table.HeaderCell
      sorted={
        sort_field === TABLE_COLUMN_LAST_NAME ? sort_direction : undefined
      }
      onClick={() =>
        setSort(
          TABLE_COLUMN_LAST_NAME,
          sort_field === TABLE_COLUMN_LAST_NAME &&
            sort_direction === SORT_DIRECTION_ASC
            ? SORT_DIRECTION_DESC
            : SORT_DIRECTION_ASC
        )
      }
    >
      Last Name
    </Table.HeaderCell>
    <Table.HeaderCell
      sorted={
        sort_field === TABLE_COLUMN_FIRST_NAME ? sort_direction : undefined
      }
      onClick={() =>
        setSort(
          TABLE_COLUMN_FIRST_NAME,
          sort_field === TABLE_COLUMN_FIRST_NAME &&
            sort_direction === SORT_DIRECTION_ASC
            ? SORT_DIRECTION_DESC
            : SORT_DIRECTION_ASC
        )
      }
    >
      First Name
    </Table.HeaderCell>
    {!!show_location && (
      <Table.HeaderCell
        sorted={
          sort_field === TABLE_COLUMN_LOCATION ? sort_direction : undefined
        }
        onClick={() =>
          setSort(
            TABLE_COLUMN_LOCATION,
            sort_field === TABLE_COLUMN_LOCATION &&
              sort_direction === SORT_DIRECTION_ASC
              ? SORT_DIRECTION_DESC
              : SORT_DIRECTION_ASC
          )
        }
      >
        Location Name
      </Table.HeaderCell>
    )}
    <Table.HeaderCell
      sorted={
        sort_field === TABLE_COLUMN_AMT_ELIGIBLE ? sort_direction : undefined
      }
      textAlign="right"
      onClick={() =>
        setSort(
          TABLE_COLUMN_AMT_ELIGIBLE,
          sort_field === TABLE_COLUMN_AMT_ELIGIBLE &&
            sort_direction === SORT_DIRECTION_DESC
            ? SORT_DIRECTION_ASC
            : SORT_DIRECTION_DESC
        )
      }
    >
      Eligible Take Home
    </Table.HeaderCell>
    <Table.HeaderCell
      sorted={
        sort_field === TABLE_COLUMN_AMT_RESERVED ? sort_direction : undefined
      }
      textAlign="right"
      onClick={() =>
        setSort(
          TABLE_COLUMN_AMT_RESERVED,
          sort_field === TABLE_COLUMN_AMT_RESERVED &&
            sort_direction === SORT_DIRECTION_DESC
            ? SORT_DIRECTION_ASC
            : SORT_DIRECTION_DESC
        )
      }
    >
      Amount Reserved
    </Table.HeaderCell>
    <Table.HeaderCell
      sorted={
        sort_field === TABLE_COLUMN_AMT_PAYABLE ? sort_direction : undefined
      }
      textAlign="right"
      onClick={() =>
        setSort(
          TABLE_COLUMN_AMT_PAYABLE,
          sort_field === TABLE_COLUMN_AMT_PAYABLE &&
            sort_direction === SORT_DIRECTION_DESC
            ? SORT_DIRECTION_ASC
            : SORT_DIRECTION_DESC
        )
      }
    >
      Amount Payable
    </Table.HeaderCell>
    <Table.HeaderCell
      sorted={sort_field === TABLE_COLUMN_AMT_SENT ? sort_direction : undefined}
      textAlign="right"
      onClick={() =>
        setSort(
          TABLE_COLUMN_AMT_SENT,
          sort_field === TABLE_COLUMN_AMT_SENT &&
            sort_direction === SORT_DIRECTION_DESC
            ? SORT_DIRECTION_ASC
            : SORT_DIRECTION_DESC
        )
      }
    >
      Amount Paid Out
    </Table.HeaderCell>
    <Table.HeaderCell
      sorted={
        sort_field === TABLE_COLUMN_AMT_AVAILABLE ? sort_direction : undefined
      }
      textAlign="right"
      onClick={() =>
        setSort(
          TABLE_COLUMN_AMT_AVAILABLE,
          sort_field === TABLE_COLUMN_AMT_AVAILABLE &&
            sort_direction === SORT_DIRECTION_DESC
            ? SORT_DIRECTION_ASC
            : SORT_DIRECTION_DESC
        )
      }
    >
      Available to Pay Out
    </Table.HeaderCell>
  </Table.Row>
)
