import { useState } from "react"
import { useQuery, useMutation } from "@apollo/client"

import { Link } from "../../../../Shared"
import EmployeeInvitesModal from "../../../../EmployeeInvites/Modal"

import { useOrganization, useSelectedLocation } from "../../../../../hooks"
import { createSearchQuery, toInt } from "../../../../../helpers"
import { GET_AVAILABLE_ETA_INVITES } from "../../../../../graphql/queries"
import { SEND_ETA_REGISTRATION_INVITES } from "../../../../../graphql/mutations"
import { paths } from "../../../../../constants"

const getAvailableInvites = () => {
  const organization = useOrganization()
  const selected_location = useSelectedLocation()

  const { data, loading } = useQuery(GET_AVAILABLE_ETA_INVITES, {
    fetchPolicy: "network-only",
    variables: {
      org_id: organization.id,
      store_id: selected_location?.id
    }
  })

  return { data, loading }
}

export const SendInviteLink = ({
  user_id,
  content = "Send Invite",
  onInvitesSent = () => {}
}) => {
  const [show_modal, setShowModal] = useState(false)
  const { data } = getAvailableInvites()

  const available_invites =
    data?.getAvailableEtaInvites?.filter(
      ({ user }) => user && user_id && toInt(user.id) === toInt(user_id)
    ) ?? []

  return (
    <>
      {available_invites.length > 0 && (
        <>
          <Link onClick={() => setShowModal(true)}>{content}</Link>
          {show_modal && (
            <SendInvitesModal
              onClose={() => setShowModal(false)}
              onInvitesSent={result => {
                setShowModal(false)
                onInvitesSent(result)
              }}
              available_invites={available_invites}
            />
          )}
        </>
      )}
    </>
  )
}

const SendInvitesModal = ({ onClose, available_invites, onInvitesSent }) => {
  const organization = useOrganization()
  const [sendEtaRegistrationInvites, { loading }] = useMutation(
    SEND_ETA_REGISTRATION_INVITES,
    {
      onCompleted: result => onInvitesSent(result)
    }
  )

  const onSubmit = valid_invites => {
    sendEtaRegistrationInvites({
      variables: {
        org_id: organization.id,
        invites: valid_invites.map(({ id, email }) => ({
          employee_id: id,
          email
        }))
      }
    })
  }

  const onEditRequest = employee => {
    window.location.href = createSearchQuery(paths.settingsLocations, {
      location_id: employee?.store?.id ?? "",
      employee_name: employee?.name ?? "",
      view: "employees"
    })
  }

  return (
    <EmployeeInvitesModal
      employees={available_invites}
      header={`Invite Employee${
        available_invites.length > 1 ? "s" : ""
      } to Sign Up for
      Earned Tip Access`}
      loading={loading}
      onClose={onClose}
      onSubmit={onSubmit}
      onEditRequest={onEditRequest}
    />
  )
}
