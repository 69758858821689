import React from "react"

import { IconButton } from "../../../Button"

export default ({ compact_view, onToggle }) => (
  <IconButton
    id={
      !!compact_view
        ? "show-detailed-breakdown-btn"
        : "show-compact-breakdown-btn"
    }
    onClick={onToggle}
    icon={!!compact_view ? "expand" : "compress"}
    popup={
      !!compact_view ? "Show Detailed Table View" : "Show Compact Table View"
    }
  />
)
