import { useOrganization, useObjectSelector, useIsBackdoorUser } from "."

import {
  pay_period_intervals,
  flat_rate_enabled_val,
  flat_rate_settings_key,
  rev_centers_enabled_val,
  rev_centers_settings_key,
  eta_intro_modal_route_key,
  weekly_distros_enabled_val,
  can_update_sale_details_key,
  can_update_sale_details_val,
  weekly_distros_settings_key,
  routes_visited_settings_key,
  recurring_shifts_enabled_val,
  recurring_shifts_settings_key,
  sale_assigned_pools_enabled_val,
  sale_assigned_pools_settings_key,
  cash_tip_distribution_enabled_val,
  cash_tip_distribution_settings_key,
  apply_rules_to_sale_assigned_pools_settings_key,
  apply_rules_to_sale_assigned_pools_enabled_val,
  none_job_code_sales_notifications_settings_key,
  none_job_code_sales_notifications_enabled_val,
  excessive_tip_notifications_settings_key,
  excessive_tip_notifications_enabled_val,
  report_exports_settings_key,
  report_exports_enabled_val,
  p2p_settings_key,
  p2p_enabled_val
} from "../../constants"

export const useUserSettings = () =>
  useObjectSelector(
    state => state.app?.user?.settings ?? {},
    (a, b) =>
      Object.keys(a).length === Object.keys(b).length &&
      Object.keys(a).reduce((acc, key) => acc && a[key] === b[key], true)
  )

export const useUserSetting = (setting_key, default_value = undefined) =>
  useUserSettings()[setting_key] ?? default_value

export const useOrganizationSettings = () => useOrganization()?.settings ?? {}

export const useOrganizationSetting = (
  setting_key,
  default_value = undefined
) => useOrganizationSettings()[setting_key] ?? default_value

export const useRevenueCentersEnabled = () =>
  useOrganizationSetting(rev_centers_settings_key) === rev_centers_enabled_val

export const useNoneJobCodeSalesNotificationsEnabled = () =>
  useOrganizationSetting(none_job_code_sales_notifications_settings_key) ===
  none_job_code_sales_notifications_enabled_val

export const useExcessiveTipNotificationsEnabled = () =>
  useOrganizationSetting(excessive_tip_notifications_settings_key) >
  excessive_tip_notifications_enabled_val

export const useUpdateSaleDetailsEnabled = () =>
  useOrganizationSetting(can_update_sale_details_key) ===
  can_update_sale_details_val

export const useCashTipDistributionEnabled = () =>
  useOrganizationSetting(cash_tip_distribution_settings_key) ===
  cash_tip_distribution_enabled_val

export const useWeeklyDistributionEnabled = () =>
  useOrganizationSetting(weekly_distros_settings_key) ===
    weekly_distros_enabled_val &&
  pay_period_intervals.find(
    ({ key, weekly_distribution_compatible }) =>
      key === useOrganization()?.payroll_interval &&
      weekly_distribution_compatible
  )

export const useFlatRateDistributionEnabled = () =>
  useOrganizationSetting(flat_rate_settings_key) === flat_rate_enabled_val

export const useRecurringShiftsEnabled = () =>
  useOrganizationSetting(recurring_shifts_settings_key) ===
  recurring_shifts_enabled_val

export const useSaleAssignedRulesEnabled = () =>
  useOrganizationSetting(sale_assigned_pools_settings_key) ===
  sale_assigned_pools_enabled_val

export const useApplyRulesToSaleAssignedPools = () =>
  useOrganizationSetting(apply_rules_to_sale_assigned_pools_settings_key) ===
  apply_rules_to_sale_assigned_pools_enabled_val

export const useReportExportsEnabled = () =>
  useOrganizationSetting(report_exports_settings_key) ===
  report_exports_enabled_val

export const usePeerToPeerEnabled = () =>
  useOrganizationSetting(p2p_settings_key) === p2p_enabled_val

export const useEtaEnabled = () => {
  const org = useOrganization()
  return !!(
    org.eta_config?.enabled &&
    org.eta_config?.verified_at &&
    org.current_pay_period
  )
}

export const useCanViewEtaModule = () => {
  const has_viewed_intro = useHasVisitedRoute(eta_intro_modal_route_key)
  const eta_enabled = useEtaEnabled()
  return has_viewed_intro || eta_enabled
}

export const useRoutesVisited = () => {
  const setting_value = useUserSetting(routes_visited_settings_key)

  // default setting value
  if (Array.isArray(setting_value)) {
    return { client: {} }
  }

  return { client: {}, ...JSON.parse(setting_value) }
}

export const useHasVisitedRoute = route_name => {
  const is_backdoor = useIsBackdoorUser()
  const routes_visited = useRoutesVisited().client ?? {}
  // assume route was visited for backdoor logins
  return is_backdoor || routes_visited[route_name] ? true : false
}
