import { Component } from "react"

import {
  DropdownWrapper,
  DropdownMenu,
  DropdownMenuItem,
  DropdownSearchInput
} from "./Styled"

export default class extends Component {
  state = {
    active: false,
    search_value: "",
    selected_option_idx: null
  }

  open = () => {
    const { active } = this.state
    if (!active) {
      this.setState({
        active: true,
        search_value: "",
        selected_option_idx: null
      })
    }
  }

  close = () => {
    const { active } = this.state
    if (active) {
      this.setState({
        active: false
      })
    }
  }

  toggleUp = () => {
    const { selected_option_idx } = this.state
    if (selected_option_idx === null) {
      return
    } else if (selected_option_idx === 0) {
      this.setState({ selected_option_idx: null })
    } else if (selected_option_idx > 0) {
      this.setState({
        selected_option_idx: selected_option_idx - 1
      })
    }
  }

  toggleDown = () => {
    const { selected_option_idx } = this.state
    if (selected_option_idx === null) {
      this.setState({ selected_option_idx: 0 })
    } else if (selected_option_idx + 1 < this.visibleOptions().length) {
      this.setState({
        selected_option_idx: selected_option_idx + 1
      })
    }
  }

  select = () => {
    const { selected_option_idx } = this.state
    const options = this.visibleOptions()
    if (selected_option_idx !== null && !!options[selected_option_idx]) {
      options[selected_option_idx].onSelect()
      if (options[selected_option_idx].close_on_select) {
        this.close()
      }
    } else if (options.length === 1) {
      options[0].onSelect()
      if (options[0].close_on_select) {
        this.close()
      }
    }
  }

  visibleOptions = () =>
    this.props.options
      .filter(
        ({ label }) =>
          !this.props.search ||
          !this.state.search_value.trim().length ||
          (!!label &&
            label
              .toLowerCase()
              .indexOf(this.state.search_value.trim().toLowerCase()) > -1)
      )
      .filter(({ visible }) => visible !== false)
      .map(({ close_on_select = true, ...option }) => ({
        close_on_select,
        ...option
      }))

  render() {
    const {
      value,
      icon,
      search,
      right = false,
      no_caret = false,
      options, // descructure so options aren't passed to DropdownWrapper
      ...props
    } = this.props
    const { active, search_value, selected_option_idx } = this.state

    return (
      <DropdownWrapper
        {...props}
        active={active}
        right={right}
        no_caret={no_caret}
        search={search}
        onOpen={this.open}
        onClose={this.close}
        onKeyDown={e => {
          const key = e.key
          if (key === "Escape") {
            this.close()
          } else if (key === "ArrowDown") {
            this.toggleDown()
            e.preventDefault()
          } else if (key === "ArrowUp") {
            this.toggleUp()
            e.preventDefault()
          } else if (key === "Enter") {
            this.select()
          }
        }}
      >
        <>
          {!!icon && icon}
          {(!search || (!active && !!value)) && value}
          {!!search && !!active && (
            <DropdownSearchInput
              value={search_value}
              onChange={search_value =>
                this.setState({
                  search_value,
                  selected_option_idx: null
                })
              }
            />
          )}
        </>
        <DropMenu
          right={right}
          collapsed={!active}
          active_idx={selected_option_idx}
          options={this.visibleOptions()}
          closeMenu={this.close}
        />
      </DropdownWrapper>
    )
  }
}

const DropMenu = ({
  options,
  collapsed,
  closeMenu,
  active_idx,
  right = false
}) => {
  return (
    <DropdownMenu right={right} collapsed={collapsed}>
      {options
        .sort((a, b) => {
          try {
            return a.content.localeCompare(b.content)
          } catch (e) {
            return 0
          }
        })
        .map(({ selected = false, close_on_select = true, ...item }, i) => (
          <DropdownMenuItem
            key={i}
            loading={!!item.loading ? true : undefined}
            active={active_idx === i}
            selected={selected}
            onMouseDown={e => {
              e.preventDefault()
              e.stopPropagation()
              e.nativeEvent.stopImmediatePropagation()
              item.onSelect()
              if (close_on_select) {
                closeMenu()
              }
            }}
            content={item.content}
          />
        ))}
      {options.length === 0 && <DropdownMenuItem content="No Results" />}
    </DropdownMenu>
  )
}
