import { useState, useEffect } from "react"
import { Form } from "semantic-ui-react"
import { eta_view } from "../../../../../../../constants/permissions"
import useAuthorization from "../../../../../../../hooks/authorization"
import { toggleManagePermissions, useVisibleEtaPermissions } from "../helpers"
import { ToggleField, TogglePermissionsTable } from "../Styled"

export const earnedTipAccessStep = ({ permissions, edit_mode }, onChange) => {
  const [visited, setVisited] = useState(false)
  return {
    name: "Earned Tip Access",
    complete: !edit_mode || visited,
    render: () => (
      <EarnedTipAccess
        permissions={permissions}
        edit_mode={edit_mode}
        onChange={new_state =>
          onChange({
            permissions: {
              ...permissions,
              ...new_state
            }
          })
        }
        onMount={() => setVisited(true)}
      />
    )
  }
}

export const EarnedTipAccess = ({
  permissions,
  edit_mode,
  onChange,
  onMount
}) => {
  const { hasPermission } = useAuthorization()
  const eta_permissions = useVisibleEtaPermissions()
  // exclude eta_view permission
  const eta_manage_permissions = eta_permissions.filter(
    ({ name }) => name !== eta_view
  )
  // eta manage permissions the user has access to
  const allowed_manage_permissions = eta_manage_permissions.filter(
    ({ disabled }) => !disabled
  )
  // determines the checked state for Manage Reporting toggle
  const all_manage_selected =
    !!allowed_manage_permissions.length &&
    allowed_manage_permissions.every(
      permission => !!permissions[permission.name]
    )

  useEffect(() => onMount(), [])
  return (
    <>
      <Form>
        <ToggleField
          checked={permissions[eta_view]}
          disabled={!edit_mode || !hasPermission(eta_view)}
          label="Earned Tip Access Tab Visible"
          onChange={checked => {
            let new_state = { [eta_view]: checked }
            // auto-uncheck the manage permission when de-selecting view
            if (!checked) {
              new_state = {
                ...new_state,
                // de-select all allowed eta manage permissions
                ...toggleManagePermissions(allowed_manage_permissions, checked)
              }
            }
            onChange(new_state)
          }}
        />
        <ToggleField
          checked={all_manage_selected}
          disabled={!edit_mode || !hasPermission(eta_view)}
          label="Manage Earned Tip Access"
          onChange={checked => {
            // toggle all allowed eta manage permissions
            let new_state = toggleManagePermissions(
              allowed_manage_permissions,
              checked
            )
            // auto-check the view permission when selecting manage
            if (checked) {
              new_state = {
                ...new_state,
                [eta_view]: checked
              }
            }
            onChange(new_state)
          }}
        />
      </Form>
      <TogglePermissionsTable
        rows={eta_manage_permissions}
        selected={permissions}
        onChange={(value, checked) => {
          let new_state = { [value]: checked }
          // auto-check the view permission when selecting a permission
          if (checked) {
            new_state = {
              ...new_state,
              [eta_view]: checked
            }
          }
          onChange(new_state)
        }}
        edit_mode={edit_mode}
      />
    </>
  )
}
