import { useState } from "react"

import { Query } from "@apollo/client/react/components"

import SummaryModal from "./SummaryModal"
import { AlertButton } from "../../Shared"
import { filterAndSortQueryResults } from "./helpers"

import { useUser, useSelectedLocation } from "../../../hooks/selectors"
import { SALES_WITH_EXCESSIVE_TIP } from "../../../graphql/queries"

const ExcessiveTipAlert = ({ min_time = null, max_time = null }) => {
  const user = useUser()
  const selected_location = useSelectedLocation()

  const [show_modal, showModal] = useState(false)

  let store_ids = [...user.stores.map(({ id }) => id)]
  if (selected_location) {
    store_ids = [selected_location?.id]
  }

  return (
    <Query
      fetchPolicy="no-cache"
      query={SALES_WITH_EXCESSIVE_TIP}
      variables={{ store_ids }}
    >
      {({ data }) => {
        const sales_with_excessive_tips = filterAndSortQueryResults(
          data?.salesWithExcessiveTip ?? [],
          min_time,
          max_time
        )

        return (
          <>
            {sales_with_excessive_tips?.length > 0 && (
              <AlertButton
                id="sales-with-excessive-tip-notification"
                basic
                success
                icon="money"
                label="Excessive Tip"
                count={sales_with_excessive_tips.length}
                onClick={() => showModal(true)}
              />
            )}
            {sales_with_excessive_tips?.length > 0 && !!show_modal && (
              <SummaryModal
                sales={sales_with_excessive_tips}
                onClose={() => showModal(false)}
              />
            )}
          </>
        )
      }}
    </Query>
  )
}

export default ExcessiveTipAlert
