import { useState } from "react"

import Search, { ResultItem } from "./"

export default ({
  active = true,
  fluid = false,
  displayed = true,
  locations = [],
  placeholder = "Search Locations",
  ...props
}) => {
  const [search, setSearch] = useState("")
  let results = []

  try {
    results = locations
      .filter(
        ({ name }) =>
          search.trim().length === 0 ||
          name.toLowerCase().includes(search.trim().toLowerCase())
      )
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(location => ({
        key: location.id,
        value: location.id,
        title: location.name,
        location: { ...location }
      }))
  } catch (e) {}

  return (
    <Search
      input={{ icon: "search", iconPosition: "left" }}
      placeholder={placeholder}
      results={results}
      fluid={fluid}
      onSearchChange={search => setSearch(search)}
      resultRenderer={({ title }) => (
        <ResultItem title={!!title ? title : <i>No Name</i>} />
      )}
      {...props}
    />
  )
}
