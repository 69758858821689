import React, { useState } from "react"

import Styled from "styled-components"
import { List, Loader } from "semantic-ui-react"
import { Mutation } from "@apollo/client/react/components"

import Link from "../Link"
import Card from "../Shared/Card"
import { IconButton } from "../Button"
import P2PIcon from "../Icon/PeerToPeer"

import { paths, p2p_enabled_val, p2p_disabled_val } from "../../constants"
import AreYouSure from "../Modal/AreYouSure"
import { p2pEnabled } from "../../helpers/settings/organization"
import { UPDATE_PEER_TO_PEER_ORGANIZATION_SETTING } from "../../graphql/mutations"
import useAuthorization from "../../hooks/authorization"
import { settings_manage_peer_to_peer } from "../../constants/permissions"

const ComponentWrapper = Styled.div`
  font-size: 1rem;
`
const Thin = Styled.span`
  font-weight: 300 !important;
`
const Line = Styled.div`
  margin-top: 1rem;
  &:first-child {
    margin-top: 0;
  }
`
const FeatureStatus = Styled.div`
  position: relative;
  line-height: 2rem;
  font-size: 1.2rem;
  margin-bottom: 1rem;
`
const StatusLabel = Styled(({ active, ...props }) => (
  <span {...props}>{!!active ? "Active" : "Disabled"}</span>
))`
  font-size: 1rem;
  font-weight: bold;
`
const Controlwrapper = Styled.div`
  font-size: 1rem;
  margin-top: 0.67rem;
`
const EnabledDescriptionList = Styled(({ ...props }) => <List {...props} />)`
  margin-top: 0.33rem !important;
`

export default ({ user, syncing_user = false, syncUser, toast }) => {
  const [show_toggle_modal, setShowModal] = useState(false)

  const p2p_enabled = p2pEnabled(user.organization.settings)
  const pay_period_configured = !!user.organization.current_pay_period
  const { hasPermission } = useAuthorization()
  const can_edit = hasPermission(settings_manage_peer_to_peer)

  return (
    <>
      {!!syncing_user && <Loader inline active />}
      {!syncing_user && (
        <ComponentWrapper>
          <Card>
            <Card.Header>
              Peer-to-Peer Tip Sharing&nbsp;&nbsp;
              <P2PIcon />
            </Card.Header>
            <Card.Content>
              <Line>
                Peer-to-Peer Tip Sharing allows employees to share tips directly
                with team members.
              </Line>
              <Line>
                When enabled:
                <EnabledDescriptionList ordered>
                  <List.Item>
                    Employee App users will receive access to a Tip Sharing
                    module.
                  </List.Item>
                  <List.Item>
                    Users will be prompted to set up a pincode.
                  </List.Item>
                  <List.Item>
                    Users will be able to share tips directly with co-workers.
                  </List.Item>
                </EnabledDescriptionList>
              </Line>
              <Line>
                You can review Peer-to-Peer transactions from the{" "}
                <Link href={paths.reportPeerToPeer} target="_blank">
                  Reporting tab
                </Link>
                .
              </Line>
              <Line>
                Your{" "}
                <Link href={paths.breakdown} target="_blank">
                  Breakdown
                </Link>{" "}
                will incorporate tips shared when calculating take home amounts.
              </Line>
              <Line>
                If you'd like to learn more about Peer-to-Peer Tip Sharing,{" "}
                <Link href={paths.contact} target="_blank">
                  let us know!
                </Link>
              </Line>
            </Card.Content>
          </Card>

          <Mutation
            mutation={UPDATE_PEER_TO_PEER_ORGANIZATION_SETTING}
            onCompleted={data => {
              const success = !data.errors
              toast({
                type: !!success ? "success" : "danger",
                message: !!success
                  ? `Peer-to-Peer Tip Sharing has been ${
                      !p2p_enabled ? "enabled" : "disabled"
                    }.`
                  : `Failed to ${
                      !p2p_enabled ? "activate" : "deactivate"
                    } Peer-To-Peer Tip Sharing.`
              })
              syncUser()
              setShowModal(false)
            }}
          >
            {(updatePeerToPeerOrganizationSetting, { loading }) => {
              return (
                <>
                  <FeatureStatus>
                    <Thin>Status:</Thin> <StatusLabel active={!!p2p_enabled} />
                  </FeatureStatus>
                  {can_edit && (
                    <Controlwrapper>
                      <IconButton
                        popup={
                          <>
                            Pay period settings must be configured prior to
                            activating Peer-to-Peer Tip Sharing.
                          </>
                        }
                        icon={!p2p_enabled ? "power" : "pause"}
                        secondary={!p2p_enabled}
                        disabled={!pay_period_configured}
                        loading={!!loading && !show_toggle_modal}
                        onClick={() => setShowModal(true)}
                      >
                        {!!p2p_enabled ? "Disable" : "Activate"} Peer-to-Peer
                      </IconButton>
                    </Controlwrapper>
                  )}
                  {!can_edit && (
                    <div>
                      You must be an admin user to enable or disable this
                      feature.
                    </div>
                  )}
                  {!!show_toggle_modal && (
                    <AreYouSure
                      header="Are you sure?"
                      body={
                        <>
                          <p>
                            If you confirm, Peer-to-Peer Tip Sharing will be{" "}
                            {!!p2p_enabled ? `disabled` : `enabled`} for all
                            employee app users.
                          </p>
                          <p>Would you like to proceed?</p>
                        </>
                      }
                      submitted={!!loading}
                      onConfirm={() => {
                        const variables = {
                          value: !!p2p_enabled
                            ? p2p_disabled_val
                            : p2p_enabled_val
                        }
                        updatePeerToPeerOrganizationSetting({ variables })
                      }}
                      onClose={() => setShowModal(false)}
                    />
                  )}
                </>
              )
            }}
          </Mutation>
        </ComponentWrapper>
      )}
    </>
  )
}
