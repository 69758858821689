import { FilterRow, FilterValue } from "../Styled"
import JobCodeSearch from "../../../../Search/JobCode"

export default ({ job_code, location, onSelect, onRemove }) => (
  <FilterRow name="Job Code">
    {!!job_code && <FilterValue value={job_code.name} onRemove={onRemove} />}
    {!job_code && (
      <JobCodeSearch
        aligned="right"
        store_id={location?.id}
        onChange={({ job_code }) => onSelect({ ...job_code })}
      />
    )}
  </FilterRow>
)
