import React, { Component } from "react"

import Styled from "styled-components"
import { Form } from "semantic-ui-react"
import { Mutation } from "@apollo/client/react/components"

import Icon from "./../Icon"
import {
  Modal,
  Toggle,
  Button,
  SubmitButton,
  ValidationErrorsMessage
} from "../Shared"

import { colors, paths } from "../../constants"
import { toInt } from "../../helpers/number"
import { CHANGE_PASSWORD } from "../../graphql/mutations"
import { getGraphQLValidationErrors } from "../../helpers"

const FormModalWrapper = Styled.div``

const PASSWORD_MIN_LENGTH = 12

export default class extends Component {
  state = {
    current_password: "",
    new_password: "",
    confirm_password: "",
    show_text: false,
    errors: []
  }

  formIsValid = () => {
    const { current_password, new_password, confirm_password } = this.state
    return current_password.length > 0 && new_password === confirm_password
  }

  render() {
    const { onClose, user, navigateTo } = this.props
    const {
      current_password,
      new_password,
      confirm_password,
      show_text,
      errors
    } = this.state

    return (
      <FormModalWrapper>
        <Modal size="mini" onClose={onClose}>
          <Modal.Header>Update your Password</Modal.Header>
          <Modal.Content>
            {errors.length > 0 && (
              <ValidationErrorsMessage
                header="An error ocurred when attempting to update password."
                errors={errors}
                onDismiss={() => this.setState({ errors: [] })}
                margin_bottom="1rem"
              />
            )}
            <Form autoComplete="off">
              <Form.Field>
                <Form.Input
                  autoFocus={true}
                  spellCheck="false"
                  label="Current Password"
                  type={!!show_text ? "text" : "password"}
                  placeholder="Required for authentication"
                  autoComplete="off"
                  icon={
                    current_password.length > 0 ? (
                      <Icon color={colors.green} name="check" fitted />
                    ) : null
                  }
                  value={current_password}
                  onChange={(e, { value }) =>
                    this.setState({
                      current_password: value
                    })
                  }
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  spellCheck="false"
                  label="New Password"
                  placeholder="12 or more characters"
                  type={!!show_text ? "text" : "password"}
                  tyle="text"
                  value={new_password}
                  icon={
                    new_password.length > PASSWORD_MIN_LENGTH && (
                      <Icon color={colors.green} name="check" fitted />
                    )
                  }
                  onChange={(e, { value }) =>
                    this.setState({ new_password: value })
                  }
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  spellCheck="false"
                  label="Confirm New Password"
                  placeholder="Retype new password to confirm"
                  type={!!show_text ? "text" : "password"}
                  autoComplete="off"
                  value={confirm_password}
                  icon={
                    confirm_password.length > PASSWORD_MIN_LENGTH &&
                    confirm_password === new_password ? (
                      <Icon color={colors.green} name="check" fitted />
                    ) : null
                  }
                  error={
                    confirm_password.length > 0 &&
                    confirm_password !== new_password &&
                    "Value must match field above."
                  }
                  onChange={(e, { value }) =>
                    this.setState({
                      confirm_password: value
                    })
                  }
                />
              </Form.Field>
              <Form.Field>
                <Toggle
                  label="Show Passwords"
                  checked={show_text}
                  onChange={() => this.setState({ show_text: !show_text })}
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Mutation
            mutation={CHANGE_PASSWORD}
            onError={e =>
              this.setState({
                errors: getGraphQLValidationErrors(e.graphQLErrors)
              })
            }
            onCompleted={e => navigateTo(paths.login)}
          >
            {(changePassword, { loading }) => {
              return (
                <Modal.Actions>
                  <Button onClick={onClose} disabled={!!loading}>
                    Cancel
                  </Button>
                  <SubmitButton
                    loading={!!loading}
                    disabled={!!loading || !this.formIsValid()}
                    onClick={() => {
                      this.setState({ errors: [] })
                      changePassword({
                        variables: {
                          new_password: new_password.trim(),
                          current_password: current_password.trim()
                        }
                      })
                    }}
                  />
                </Modal.Actions>
              )
            }}
          </Mutation>
        </Modal>
      </FormModalWrapper>
    )
  }
}
