import { Table } from "../../../Shared"

export default ({ include_location = false, include_actions = false }) => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Name</Table.HeaderCell>
        {!!include_location && <Table.HeaderCell>Location</Table.HeaderCell>}
        <Table.HeaderCell>Source</Table.HeaderCell>
        <Table.HeaderCell>Frequency</Table.HeaderCell>
        <Table.HeaderCell>Effective Date</Table.HeaderCell>
        <Table.HeaderCell>Created Date</Table.HeaderCell>
        {!!include_actions && <Table.HeaderCell />}
      </Table.Row>
    </Table.Header>
  )
}
