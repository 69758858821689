import { rgba } from "polished"
import { forwardRef } from "react"
import Styled from "styled-components"
import { Icon as SemanticIcon, Ref } from "semantic-ui-react"

import Popup from "../Popup"

const IconWithRef = Styled(
  forwardRef(
    ({ color, hover_color, hover, cursor, floated, ...props }, ref) => {
      return (
        <Ref innerRef={ref}>
          <SemanticIcon {...props} />
        </Ref>
      )
    }
  )
)`${({ color, hover_color, hover, cursor, float }) => `
  &&&.icon {
    display: inline;
    ${!!float ? `float: ${float};` : ""}
    ${!!cursor ? `cursor: ${cursor} !important;` : ""}
    ${!!color ? `color: ${!!hover ? rgba(color, 0.8) : color};` : ""}
    ${!!hover_color ? `&:hover { color: ${hover_color}; }` : ""}
    ${!hover_color && !!color && !!hover ? `&:hover { color: ${color}; }` : ""}
  }
`};`

const LabeledIcon = ({ label = null, label_position = "right", ...props }) => (
  <>
    {!!label && label_position === "left" && <>{label} &nbsp;</>}
    <IconWithRef {...props} />
    {!!label && label_position === "right" && <>&nbsp; {label}</>}
  </>
)

const Icon = ({ popup = false, ...props }) => {
  let popup_props = false
  if (popup) {
    popup_props = popup?.content ? { ...popup } : { content: popup }
  }
  return (
    <>
      {!popup_props && <LabeledIcon {...props} />}
      {!!popup_props && (
        <Popup {...popup_props}>
          <div style={{ display: "inline-block" }}>
            <LabeledIcon {...props} />
          </div>
        </Popup>
      )}
    </>
  )
}

Icon.Group = SemanticIcon.Group

export default Icon
