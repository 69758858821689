import { Component } from "react"

import { Form } from "semantic-ui-react"

import Modal from "../../../Modal"
import Button, { SubmitButton } from "../../../Button"

export default class extends Component {
  state = { name: "" }

  componentDidMount() {
    this.setState({ name: this.props.name })
  }

  isValidInput = () => {
    const name_input = this.state.name.trim()
    const name = this.props.name.trim()
    return name_input !== name && name_input.length > 0
  }

  render() {
    const { onClose, onSubmit, submitted } = this.props
    const { name } = this.state

    return (
      <Modal size="tiny" onClose={onClose} closeOnDimmerClick={false}>
        <Modal.Header onClose={onClose}>Edit Name</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>Location Name</label>
              <Form.Input
                value={name}
                autoFocus={true}
                spellCheck="false"
                onChange={(e, { value }) => this.setState({ name: value })}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose} disabled={!!submitted}>
            Cancel
          </Button>
          <SubmitButton
            loading={!!submitted}
            disabled={!!submitted || !this.isValidInput()}
            onClick={() => onSubmit(name)}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}
