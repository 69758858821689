import { Component } from "react"

import Styled from "styled-components"

import TableRow from "./Row"
import Table from "../../Table"
import HeaderRow from "./Header"
import TotalsRow from "./TotalsRow"
import Message from "../../Message"

import {
  TAKE_HOME_IDX,
  STORE_NAME_IDX,
  EMP_EXTERNAL_IDX,
  HOURS_WORKED_IDX,
  EMP_LAST_NAME_IDX,
  EMP_FIRST_NAME_IDX,
  JOB_CODE_NAMES_IDX,
  EMP_PAYROLL_ID_IDX,
  SORT_COLUMN_ASCENDING,
  TAKE_HOME_PER_HOUR_IDX,
  SORT_COLUMN_DESCENDING,
  DEFAULT_COLUMN_FILTERS,
  aggregateBreakdownRows,
  calculateTakeHomePerHour
} from "./helpers"

export default class extends Component {
  state = {
    expanded_rows: []
  }

  getFilters = () => ({ ...DEFAULT_COLUMN_FILTERS, ...this.props.filters })

  handleSort = column => {
    const { setFilters } = this.props
    const { sort_column, sort_direction } = this.getFilters()
    if (!!sort_column && sort_column == column) {
      setFilters({
        sort_column: { ...column },
        sort_direction:
          sort_direction === SORT_COLUMN_ASCENDING
            ? SORT_COLUMN_DESCENDING
            : SORT_COLUMN_ASCENDING
      })
    } else {
      setFilters({
        sort_column: column,
        sort_direction:
          column.type === "string"
            ? SORT_COLUMN_ASCENDING
            : SORT_COLUMN_DESCENDING
      })
    }
  }

  filteredBreakdowns = result => {
    const {
      employee_first_name_search,
      employee_last_name_search,
      employee_ext_id_search,
      job_name_search,
      store_name_search,
      payroll_id_search
    } = this.getFilters()

    const emp_first_name = !!result.employee.first_name
      ? result.employee.first_name.trim().toLowerCase()
      : ""
    const emp_last_name = !!result.employee.last_name
      ? result.employee.last_name.trim().toLowerCase()
      : ""
    const emp_ext_id = !!result.employee.external_id
      ? result.employee.external_id.trim().toLowerCase()
      : ""
    const job_codes = !!result.employee.job_code_names
      ? result.employee.job_code_names.join("::").toLowerCase()
      : ""
    const store_name = !!result.employee.store.name
      ? result.employee.store.name.trim().toLowerCase()
      : ""
    const payroll_id = !!result.employee.payroll_id
      ? result.employee.payroll_id.trim().toLowerCase()
      : ""

    const emp_fn_filter = employee_first_name_search.trim().toLowerCase()
    const emp_ln_filter = employee_last_name_search.trim().toLowerCase()
    const emp_ext_id_filter = employee_ext_id_search.trim().toLowerCase()
    const job_filter = job_name_search.trim().toLowerCase()
    const store_filter = store_name_search.trim().toLowerCase()
    const pr_filter = payroll_id_search.trim().toLowerCase()

    //employee name filter
    if (
      !!emp_fn_filter.length &&
      emp_first_name.indexOf(emp_fn_filter) === -1
    ) {
      return false
    }
    if (!!emp_ln_filter.length && emp_last_name.indexOf(emp_ln_filter) === -1) {
      return false
    }
    //employee external id
    if (
      !!emp_ext_id_filter.length &&
      emp_ext_id.indexOf(emp_ext_id_filter) === -1
    ) {
      return false
    }
    //job name filter
    if (!!job_filter.length && job_codes.indexOf(job_filter) === -1) {
      return false
    }
    //store name filter
    if (!!store_filter.length && store_name.indexOf(store_filter) === -1) {
      return false
    }
    //store name filter
    if (!!pr_filter.length && payroll_id.indexOf(pr_filter) === -1) {
      return false
    }
    return true
  }

  sortedBreakdowns = (first, second) => {
    const { sort_column, sort_direction } = this.getFilters()
    let val_first, val_second, sort_multiplier
    if (sort_column === null) {
      return 0
    }

    if (sort_column.name === TAKE_HOME_PER_HOUR_IDX) {
      // custom sorting for take home per hour
      val_first = calculateTakeHomePerHour(
        aggregateBreakdownRows(first.rows, [
          { name: TAKE_HOME_IDX, type: "number" }
        ])[TAKE_HOME_IDX],
        aggregateBreakdownRows(first.rows, [
          { name: HOURS_WORKED_IDX, type: "number" }
        ])[HOURS_WORKED_IDX]
      )
      val_second = calculateTakeHomePerHour(
        aggregateBreakdownRows(second.rows, [
          { name: TAKE_HOME_IDX, type: "number" }
        ])[TAKE_HOME_IDX],
        aggregateBreakdownRows(second.rows, [
          { name: HOURS_WORKED_IDX, type: "number" }
        ])[HOURS_WORKED_IDX]
      )
    } else {
      val_first = aggregateBreakdownRows(first.rows, [sort_column])[
        sort_column.name
      ]
      val_second = aggregateBreakdownRows(second.rows, [sort_column])[
        sort_column.name
      ]
    }

    sort_multiplier = sort_direction === SORT_COLUMN_ASCENDING ? 1 : -1

    if (sort_column.type === "string") {
      return val_first.localeCompare(val_second) * sort_multiplier
    }
    switch (sort_column.type) {
      case "string":
        return val_first.localeCompare(val_second) * sort_multiplier
      default:
        return (val_first > val_second ? 1 : -1) * sort_multiplier
    }
  }

  filterAndSort = () =>
    this.props.values
      /* -- apply filters -- */
      .filter(breakdown => this.filteredBreakdowns(breakdown))
      /* -- apply column sort -- */
      .sort((first, second) => this.sortedBreakdowns(first, second))

  toggleExpanded = employee_id =>
    this.setState({
      expanded_rows: this.state.expanded_rows.includes(employee_id)
        ? this.state.expanded_rows.filter(id => id !== employee_id)
        : [...this.state.expanded_rows, employee_id]
    })

  render() {
    const { expanded_rows } = this.state
    const {
      columns,
      values,
      actions,
      links,
      setFilters,
      page,
      per_page,
      onPageChange,
      selected_location_id,
      showResolveNoneJobCodeModal
    } = this.props
    const {
      employee_first_name_search,
      employee_last_name_search,
      employee_ext_id_search,
      job_name_search,
      store_name_search,
      payroll_id_search,
      sort_column,
      sort_direction
    } = this.getFilters()
    let table_rows = this.filterAndSort()

    //no results
    if (values.length == 0) {
      return <NoResultsMessage />
    }

    return (
      <BreakdownTable
        id="breakdown-table"
        data-testid="breakdown-table"
        controls={{
          position: table_rows.length > per_page ? "both" : "top",
          custom_controls_position: "top",
          custom: [actions],
          pagination: {
            current_page: page,
            per_page: per_page,
            total: table_rows.length,
            include_total: true,
            changePage: page => onPageChange(page, per_page),
            changePerPage: per_page => onPageChange(1, per_page),
            options: {
              per_page: [25, 50, 100, 500]
            }
          }
        }}
      >
        <Table.Header>
          <HeaderRow
            columns={columns}
            sort_column={sort_column === null ? null : sort_column.name}
            sort_direction={sort_direction}
            handleSort={this.handleSort}
          />
        </Table.Header>
        <Table.Body>
          <TotalsRow
            columns={columns}
            table_rows={table_rows}
            search_fields={{
              [EMP_FIRST_NAME_IDX]: employee_first_name_search,
              [EMP_LAST_NAME_IDX]: employee_last_name_search,
              [JOB_CODE_NAMES_IDX]: job_name_search,
              [STORE_NAME_IDX]: store_name_search,
              [EMP_PAYROLL_ID_IDX]: payroll_id_search,
              [EMP_EXTERNAL_IDX]: employee_ext_id_search
            }}
            search_callbacks={{
              [EMP_FIRST_NAME_IDX]: employee_first_name_search => {
                onPageChange(1)
                setFilters({ employee_first_name_search })
              },
              [EMP_LAST_NAME_IDX]: employee_last_name_search => {
                onPageChange(1)
                setFilters({ employee_last_name_search })
              },
              [JOB_CODE_NAMES_IDX]: job_name_search => {
                onPageChange(1)
                setFilters({ job_name_search })
              },
              [STORE_NAME_IDX]: store_name_search => {
                onPageChange(1)
                setFilters({ store_name_search })
              },
              [EMP_PAYROLL_ID_IDX]: payroll_id_search => {
                onPageChange(1)
                setFilters({ payroll_id_search })
              },
              [EMP_EXTERNAL_IDX]: employee_ext_id_search => {
                onPageChange(1)
                setFilters({ employee_ext_id_search })
              }
            }}
          />
          {table_rows
            .slice((page - 1) * per_page, page * per_page)
            .map((breakdown, idx) => (
              <TableRow
                columns={columns}
                links={links}
                showResolveNoneJobCodeModal={showResolveNoneJobCodeModal}
                key={idx}
                breakdown={breakdown}
                selected_location_id={selected_location_id}
                expanded={expanded_rows.includes(breakdown.employee.id)}
                toggleExpanded={id => this.toggleExpanded(id)}
              />
            ))}
          {table_rows.length == 0 && (
            <Table.Row>
              <Table.Cell colSpan={columns.length}>
                <NoMatchingResults>No matching results.</NoMatchingResults>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </BreakdownTable>
    )
  }
}

const BreakdownTable = Styled(({ className, ...props }) => (
  <div className={className}>
    <Table draggable={true} unstackable compact sortable {...props} />
  </div>
))`
  position: relative;
  overflow-x: auto;
  padding-bottom: 0.33rem;
`
const NoMatchingResults = Styled.div`
  display: inline-block;
  padding: 0.33em 0;
  font-weight: 300;
`
const NoResultsMessage = Styled(({ className }) => (
  <Message className={className} type="info" inline>
    <Message.Header>No Breakdown Data Found</Message.Header>
    <Message.Content>
      No results found with the current filters. Try expanding the date range
      for a broader search.
    </Message.Content>
  </Message>
))`
  margin: 0.33rem 0;
`
