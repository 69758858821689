import { useQuery } from "@apollo/client"

import { Field } from "../../../../Styled"
import { Loader } from "../../../../../Shared"
import SelectJobCodes from "./SelectJobCodes"
import ApplyDistributionWeights from "./ApplyDistributionWeights"

import { SHARED_JOB_CODE_NAMES } from "../../../../../../graphql/queries"

// step entrypoint
export const jobCodesStep = (
  { locations, job_codes, apply_job_code_weights },
  onUpdate
) => ({
  name: "Job Codes",
  complete:
    Object.keys(job_codes).length > 0 &&
    (!apply_job_code_weights ||
      Object.values(job_codes).reduce((acc, weight) => acc + weight, 0) > 0),
  render: () => (
    <JobCodes
      locations={locations}
      weight_by_job_code={job_codes}
      apply_job_code_weights={apply_job_code_weights}
      onUpdate={onUpdate}
    />
  )
})
const JobCodes = ({
  locations,
  weight_by_job_code,
  apply_job_code_weights,
  onUpdate
}) => {
  const { loading, data } = useQuery(SHARED_JOB_CODE_NAMES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: { store_ids: [...locations] }
  })

  return (
    <>
      {!!loading && <Loader />}
      {!loading && (
        <>
          <Field fluid name="Select Job Codes">
            <SelectJobCodes
              onUpdate={job_codes => onUpdate({ job_codes })}
              job_codes={data?.sharedJobCodeNames ?? []}
              weight_by_job_code={weight_by_job_code}
            />
          </Field>
          {Object.keys(weight_by_job_code).length > 1 && (
            <Field
              fluid
              name="Configure Distribution Points"
              description={
                <>
                  <p>
                    (<strong>Optional</strong>) Use distribution points to
                    specify how much one job code should be tipped relative to
                    another.
                  </p>
                  <p>
                    A job code with 2 points will receive twice as much as a job
                    code with 1 point.
                  </p>
                  <p>When left disabled, tips are distributed evenly.</p>
                </>
              }
            >
              <ApplyDistributionWeights
                weight_by_job_code={weight_by_job_code}
                apply_job_code_weights={apply_job_code_weights}
                onUpdate={job_codes => onUpdate({ job_codes })}
                onToggleWeights={() =>
                  onUpdate({
                    apply_job_code_weights: !apply_job_code_weights
                  })
                }
              />
            </Field>
          )}
        </>
      )}
    </>
  )
}
