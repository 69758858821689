import { useState } from "react"
import {
  settings_manage_employee_app_users,
  settings_manage_users
} from "../../../../../constants/permissions"
import useAuthorization from "../../../../../hooks/authorization"
import { Button, FilterBar, PopupMenu } from "../../../../Shared"
import { useUserModals } from "../context/userModals"
import { RolesDropdown, UserSearch } from "../Styled"

const UsersFilterBar = ({ filters, setFilters, client_roles, loading }) => {
  const { hasPermission } = useAuthorization()
  const { showModal } = useUserModals()
  const [actions_open, setActionsOpen] = useState(false)

  const getActions = () => {
    let actions = []

    if (hasPermission(settings_manage_users)) {
      actions.push({
        label: "Client",
        action: () => {
          showModal("user_modal")
          setActionsOpen(false)
        }
      })
    }

    if (hasPermission(settings_manage_employee_app_users)) {
      actions.push({
        label: "Employee",
        action: () => {
          showModal("employee_invite")
          setActionsOpen(false)
        }
      })
    }

    return actions
  }

  const actions = getActions()
  return (
    <FilterBar
      loading={loading}
      loading_msg="Loading Users"
      status_filter={filters.status_filter}
      onChange={({ status_filter }) =>
        setFilters({
          status_filter
        })
      }
      sort={{
        field: filters.sort_by ?? null,
        direction: filters.sort_dir ?? null,
        options: SORT_OPTIONS,
        onChange: (sort_by, sort_dir) => setFilters({ sort_by, sort_dir })
      }}
      custom_filters={[
        {
          label: "Roles",
          filter: (
            <RolesDropdown
              placeholder="All"
              max_labels={2}
              value={filters.roles}
              options={clientRoleOptions(client_roles)}
              onChange={(e, { value }) => setFilters({ roles: value })}
            />
          )
        },
        {
          filter: (
            <UserSearch
              value={filters.search}
              onClear={() => setFilters({ search: "" })}
              onChange={(e, { value }) => setFilters({ search: value })}
            />
          )
        }
      ]}
      controls={
        !!actions.length ? (
          <PopupMenu
            open={actions_open}
            onClose={() => setActionsOpen(false)}
            trigger={
              <Button
                primary
                circular
                icon="plus"
                content="Create"
                size="small"
                onClick={() => setActionsOpen(true)}
              />
            }
          >
            {actions.map(({ action, label }, idx) => (
              <PopupMenu.Item key={idx} onClick={action}>
                {label}
              </PopupMenu.Item>
            ))}
          </PopupMenu>
        ) : null
      }
    />
  )
}

const clientRoleOptions = client_roles =>
  client_roles.map(role => ({
    text: role.name,
    value: role.id
  }))

const SORT_OPTIONS = [
  {
    text: "Created",
    value: "id"
  },
  {
    text: "Name",
    value: "name"
  }
]

export default UsersFilterBar
