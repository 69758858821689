import Styled from "styled-components"

import { FilterRow } from "../Styled"
import { Dropdown } from "../../../Shared"
import { OBJECT_NAME as OBJECT_NAME_TIP_SHARE } from "../../TipShare/helpers"
import { SortDirectionButton } from "../../../Shared/FilterBar/Filters/SortBy"

const SORT_DIRECTION_ASC = "asc"
const SORT_DIRECTION_DESC = "desc"
const SORT_FIELD_LOCATION = "store_name"
const SORT_FIELD_RULE_NAME = "name"
const SORT_FIELD_RULE_PERCENT = "percent_in"
const SORT_FIELD_CREATED_DATE = "created_at"
const SORT_FIELD_EFFECTIVE_DATE = "effective_date"

const DEFAULT_SORT_DIRECTION = SORT_DIRECTION_DESC
const DEFAULT_SORT_FIELD = SORT_FIELD_LOCATION

const SORT_OPTIONS = [
  {
    text: "Location",
    value: SORT_FIELD_LOCATION
  },
  {
    text: "Name",
    value: SORT_FIELD_RULE_NAME
  },
  {
    text: "Rate",
    value: SORT_FIELD_RULE_PERCENT
  },
  {
    text: "Effective Date",
    value: SORT_FIELD_EFFECTIVE_DATE
  },
  {
    text: "Created Date",
    value: SORT_FIELD_CREATED_DATE
  }
]

const RULE_SORT_OPTIONS = [...SORT_OPTIONS]
const POOL_SORT_OPTIONS = [...SORT_OPTIONS].filter(
  ({ value }) => value !== SORT_FIELD_RULE_PERCENT
)

export default ({ field, direction, object_name, onChange }) => (
  <SortWithDefaults
    field={field ?? DEFAULT_SORT_FIELD}
    direction={direction ?? DEFAULT_SORT_DIRECTION}
    options={sortOptions(object_name)}
    onChange={(field, direction) =>
      onChange(field, direction, sortIsDefault(field, direction))
    }
  />
)

const SortWithDefaults = ({
  field,
  direction,
  options,
  onChange,
  ...props
}) => (
  <FilterRow name="Sort By">
    <Dropdown
      selection
      value={field}
      options={options}
      onChange={(e, { value }) => onChange(value, direction)}
    />
    &nbsp;
    <StyledDirectionButton
      direction={direction}
      onClick={() =>
        onChange(
          field,
          direction === SORT_DIRECTION_ASC
            ? SORT_DIRECTION_DESC
            : SORT_DIRECTION_ASC
        )
      }
    />
  </FilterRow>
)

const StyledDirectionButton = Styled(({ className, ...props }) => (
  <div className={className}>
    <SortDirectionButton {...props} />
  </div>
))`
  display: inline-block;
  line-height: 2.67rem;
`

const fieldIsDefault = field => !field || field === DEFAULT_SORT_FIELD
const directionIsDefault = direction =>
  !direction || direction === DEFAULT_SORT_DIRECTION

export const sortIsDefault = (field, direction) =>
  fieldIsDefault(field) && directionIsDefault(direction)
export const sortOptions = object_name =>
  object_name === OBJECT_NAME_TIP_SHARE ? ruleSortOptions() : poolSortOptions()
const ruleSortOptions = () => [...RULE_SORT_OPTIONS]
const poolSortOptions = () => [...POOL_SORT_OPTIONS]
export const sortFieldLabel = field =>
  SORT_OPTIONS.find(option => option.value === field)?.text
