import Styled from "styled-components"

import { InfoIcon, MultiSelect } from "../../../../../Shared"

export default ({ onUpdate, options, value, ...props }) => {
  return (
    <>
      <MultiSelect
        fluid
        search
        max_labels={5}
        selectOnBlur={false}
        closeOnChange={true}
        can_select_all={true}
        selectOnNavigation={false}
        noResultsMessage="All Locations Selected"
        placeholder="Create a pool for one or more locations"
        {...props}
        value={value}
        options={[...options]
          // sort by store name in dropdown
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(location => ({
            value: location.id,
            text: location.name
          }))}
        onChange={(e, d) => onUpdate([...d.value])}
      />
      {(value?.length ?? 0) > 1 && <MultipleLocationsInfo />}
    </>
  )
}

const MultipleLocationsInfo = Styled(({ className }) => (
  <div className={className}>
    <InfoIcon label="Separate pools will be created for each location." />
  </div>
))`margin-top: 0.67rem;`
