import { useState } from "react"

import { IconButton } from "../../../Button"
import BreakdownSettingsModal from "../../Modal/TableSettings"

export default props => {
  const [show_modal, showModal] = useState(false)
  return (
    <>
      <IconButton
        icon="columns"
        labelPosition="right"
        popup="Customize Table Columns"
        onClick={() => showModal(true)}
        id="breakdown-display-settings-btn"
      />
      {!!show_modal && (
        <SettingsModal onClose={() => showModal(false)} {...props} />
      )}
    </>
  )
}

const SettingsModal = ({ onSaveSettings, onClose, ...props }) => {
  return (
    <BreakdownSettingsModal
      onClose={onClose}
      onSave={settings => {
        onSaveSettings(settings)
        onClose()
      }}
      {...props}
    />
  )
}
