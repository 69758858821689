import { apollo_client } from "../helpers/api"
import { LOGOUT_USER } from "../graphql/mutations"

export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const LOGOUT_PENDING = "LOGOUT_PENDING"

export const loginSuccess = () => ({
  type: LOGIN_SUCCESS
})

export const logout = () => dispatch => {
  dispatch({ type: LOGOUT_PENDING })

  apollo_client
    .mutate({
      mutation: LOGOUT_USER,
      fetchPolicy: "network-only"
    })
    .then(() => {
      logoutSuccess()
    })
    .catch(error => {
      console.error(error)
      logoutSuccess()
    })
}

export const logoutSuccess = () => {
  window.location.href = "/login"
  return { type: LOGOUT_SUCCESS }
}
