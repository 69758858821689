import { useState } from "react"

import Styled from "styled-components"
import { useQuery } from "@apollo/client"

import Button from "../../../../Button"
import { Modal, Loader } from "../../../../Shared"
import DeleteCustomShiftButton from "./DeleteCustomShift"
import { RecurringShiftSummaryFromQuery } from "../../Modal/CustomShifts/Summary"

import { RECURRING_SHIFT } from "../../../../../graphql/queries"
import useAuthorization from "../../../../../hooks/authorization"
import { reporting_manage_custom_shifts } from "../../../../../constants/permissions"

const ViewCustomShift = ({ worked_shift, onShiftDeleted }) => {
  const [show_modal, setShowModal] = useState(false)

  return (
    <>
      <SummaryCustomShiftButton onClick={() => setShowModal(true)} />
      {show_modal && (
        <RecurringShiftSummaryModal
          worked_shift={worked_shift}
          onShiftDeleted={onShiftDeleted}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  )
}

export default ViewCustomShift

const SummaryCustomShiftButton = Styled(({ className, ...props }) => (
  <Button
    {...props}
    compact
    primary
    circular
    size="tiny"
    icon="calendar check"
    popup={{ content: "View Custom Shift Summary", delay: true }}
  />
))`line-height: 2rem;`

const RecurringShiftSummaryModal = ({
  onClose,
  worked_shift,
  onShiftDeleted
}) => {
  const { data } = useQuery(RECURRING_SHIFT, {
    variables: { id: worked_shift.recurring_shift_id }
  })
  const recurring_shift = data?.recurringShift
  const { hasPermission } = useAuthorization()

  return (
    <>
      {!recurring_shift && (
        <Modal size="mini" basic onClose={onClose}>
          <Loader inline size="large" />
        </Modal>
      )}
      {!!recurring_shift && (
        <RecurringShiftSummaryFromQuery
          onClose={onClose}
          worked_shift={worked_shift}
          recurring_shift={recurring_shift}
          actions={
            <>
              {hasPermission(reporting_manage_custom_shifts) &&
                !!onShiftDeleted && (
                  <DeleteCustomShiftButton
                    locked={false}
                    label="Remove Shift"
                    worked_shift={worked_shift}
                    onShiftDeleted={onShiftDeleted}
                    onClose={onClose}
                  />
                )}
              <Button onClick={onClose}>Close</Button>
            </>
          }
        />
      )}
    </>
  )
}
