import React from "react"

import moment from "moment"

import Presets from "./Filters/Presets"
import DateFilter from "./Filters/Date"
import SortByFilter from "./Filters/SortBy"
import JobCodeFilter from "./Filters/JobCode"
import { MenuDropdown } from "../../Dropdown"

import EmployeeFilter from "./Filters/Employee"
import { LoadingMessage } from "./../../Shared/Loader"

import {
  FiltersLoader,
  FilterWrapper,
  FilterActions,
  FiltersContent,
  FiltersWrapper,
  ControlsWrapper,
  FilterBarWrapper
} from "./Styled"

import { useBusinessDates } from "../../../hooks/selectors"
import { date_filters_threshold_months } from "../../../constants"
import { dateFilterMinDate, dateFiltersExceedThreshold } from "../../../helpers"

export default ({
  id = "filter-bar",
  location_id,
  start_date,
  end_date,
  employee = undefined,
  job_code = undefined,
  sort = undefined,
  loading,
  loading_msg,
  years_min_date = 1,
  min_date,
  max_date,
  presets,
  custom_filters = [],
  for_route = true,
  controls = null, // controls appear within the filter bar
  actions = null, // user actions appear underneath the filter bar
  status_filter = undefined,
  onChange
}) => {
  const { businessDate } = useBusinessDates()

  const STATUS_ALL = "all"
  const STATUS_ACTIVE = "active"
  const STATUS_INACTIVE = "inactive"

  const statusFilterOptions = () => [
    {
      text: "All",
      value: STATUS_ALL
    },
    {
      text: "Active",
      value: STATUS_ACTIVE
    },
    {
      text: "Inactive",
      value: STATUS_INACTIVE
    }
  ]

  return (
    <>
      <FilterBarWrapper id={id} for_route={for_route}>
        <Presets
          presets={(presets ?? []).filter(({ visible }) => visible !== false)}
        />
        <FiltersContent>
          <FiltersWrapper>
            {!!start_date && (
              <DateFilter
                label="Start of Day"
                value={start_date}
                min_date={min_date ?? dateFilterMinDate(years_min_date)}
                max_date={max_date ?? businessDate()}
                onChange={e => {
                  let end = moment(end_date)
                  let start = moment(e)

                  // start is after end
                  if (start.isAfter(end)) {
                    end = moment(start)
                  }

                  // diff is more than 1 month
                  if (dateFiltersExceedThreshold(start, end)) {
                    end = moment(start)
                      .add(date_filters_threshold_months, "month")
                      .subtract(1, "day")
                  }

                  onChange({
                    start_date: start.format("YYYY-MM-DD 00:00:00"),
                    end_date: end.format("YYYY-MM-DD 23:59:59")
                  })
                }}
              />
            )}
            {!!end_date && (
              <DateFilter
                label="to End of Day"
                value={end_date}
                min_date={min_date ?? dateFilterMinDate(years_min_date)}
                max_date={max_date ?? businessDate()}
                onChange={e => {
                  let end = moment(e)
                  let start = moment(start_date)

                  // end is before start
                  if (end.isBefore(start)) {
                    start = moment(end)
                  }

                  // diff is more than 1 month
                  if (dateFiltersExceedThreshold(start, end)) {
                    start = moment(end)
                      .subtract(date_filters_threshold_months, "month")
                      .add(1, "day")
                  }

                  onChange({
                    start_date: start.format("YYYY-MM-DD 00:00:00"),
                    end_date: end.format("YYYY-MM-DD 23:59:59")
                  })
                }}
              />
            )}
            {!!employee && (
              <EmployeeFilter {...employee} location_id={location_id} />
            )}
            {!!job_code && (
              <JobCodeFilter {...job_code} location_id={location_id} />
            )}
            {!!status_filter && (
              <FilterWrapper label="Status">
                <MenuDropdown
                  value={status_filter}
                  onChange={value => {
                    onChange({
                      status_filter: value
                    })
                  }}
                  options={statusFilterOptions()}
                />
              </FilterWrapper>
            )}
            {sort !== undefined && <SortByFilter {...sort} />}
            {!!custom_filters.length > 0 &&
              custom_filters.map(
                ({ label, filter, styled = true, id = undefined }, idx) => (
                  <FilterWrapper
                    key={idx}
                    id={id}
                    label={label}
                    styled={styled}
                  >
                    {filter}
                  </FilterWrapper>
                )
              )}
          </FiltersWrapper>
          {!!loading && <FiltersLoader />}
          {!!controls && <ControlsWrapper>{controls}</ControlsWrapper>}
        </FiltersContent>
        {!!actions && <FilterActions actions={actions} />}
      </FilterBarWrapper>
      {!!loading && !!loading_msg && (
        <LoadingMessage
          message={loading_msg}
          show_loader={false}
          margin={true}
        />
      )}
    </>
  )
}
