import RouteContent from "../RouteContent"

import { Button } from "../Shared"
import { RouteTabs } from "../Tabs"

export default ({ navigateTo }) => (
  <RouteContent header="Page Not Found">
    <div>
      <div>The requested page was not found.</div>
      <br />
      <Button onClick={() => navigateTo("/")}>Go Back Home</Button>
    </div>
  </RouteContent>
)
