import { useQuery } from "@apollo/client"

import { Form, Modal, Loader, SubmitButton } from "../../Shared"

import { ORGANIZATION_REPORTS } from "../../../graphql/queries"
import { downloadFile, formatListForSentence } from "../../../helpers"
import { useUser, useStateObject, useSelectedLocation } from "../../../hooks"

const DEFAULT_EXPORT = "breakdown"
const GROUP_BY_EMPLOYEE = "employee"
const GROUP_BY_PAYROLL_ID = "payroll_id"
const GROUP_BY_EMPLOYEE_JOB = "employee_job"
const GROUP_BY_EMPLOYEE_JOB_DATE = "employee_job_date"

const downloadUrl = (report_id, location_id = null) => {
  let url = `breakdown/export/${report_id}`
  if (location_id) {
    url += `/${location_id}`
  }
  return url
}

const groupByOptionText = (group_keys, location_id) =>
  formatListForSentence([!location_id ? "Location" : "", ...group_keys], false)

export default ({ start_date, end_date, onClose }) => {
  const user = useUser()
  const selected_location = useSelectedLocation()

  const [state, setState] = useStateObject({
    selected_report_id: null,
    downloading: false,
    group_by: GROUP_BY_EMPLOYEE
  })
  const { downloading, group_by } = state

  const downloadExport = async report_id => {
    await downloadFile(downloadUrl(report_id, selected_location?.id), {
      start: start_date,
      end: end_date,
      group_by
    })
    onClose()
  }

  const { data, loading } = useQuery(ORGANIZATION_REPORTS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      org_id: user.organization.id,
      active: true
    }
  })

  const reports = data?.organizationReports ?? []

  const selected_report_id =
    state.selected_report_id ??
    reports?.find(({ name }) => name === DEFAULT_EXPORT)?.id ??
    null

  return (
    <Modal size="tiny" onClose={onClose} closeOnDimmerClick={false}>
      <Modal.Header icon="trash" content="Export Report" onClose={onClose} />
      <Modal.Content>
        {!!loading && <Loader />}
        {!loading && (
          <Form>
            <Form.Dropdown
              fluid
              selection
              label="Report"
              disabled={downloading}
              value={selected_report_id}
              placeholder="Select a report"
              options={[...reports]
                .sort((a, b) => a.display_name.localeCompare(b.display_name))
                .map(({ id, display_name }) => ({
                  value: id,
                  text: display_name
                }))}
              onChange={(e, { value }) =>
                setState({ selected_report_id: value })
              }
            />
            <Form.Dropdown
              fluid
              selection
              value={group_by}
              disabled={downloading}
              label="Group Rows By"
              options={[
                {
                  value: GROUP_BY_EMPLOYEE,
                  text: groupByOptionText(["Employee"], selected_location?.id),
                  description: "default"
                },
                {
                  value: GROUP_BY_EMPLOYEE_JOB,
                  text: groupByOptionText(
                    ["Employee", "Job Code"],
                    selected_location?.id
                  )
                },
                {
                  value: GROUP_BY_EMPLOYEE_JOB_DATE,
                  text: groupByOptionText(
                    ["Employee", "Job Code", "Date"],
                    selected_location?.id
                  )
                },
                {
                  value: GROUP_BY_PAYROLL_ID,
                  text: "Employee Payroll ID",
                  description: "group across locations"
                }
              ].filter(
                // hide payroll id option if single location is selected
                ({ value }) =>
                  !selected_location?.id || value !== GROUP_BY_PAYROLL_ID
              )}
              onChange={(e, { value }) => setState({ group_by: value })}
            />
          </Form>
        )}
      </Modal.Content>
      <Modal.Actions>
        <SubmitButton
          label="Export"
          icon="cloud download"
          loading={downloading}
          disabled={!selected_report_id || downloading}
          onClick={() => {
            setState({ downloading: true })
            downloadExport(selected_report_id)
          }}
        />
      </Modal.Actions>
    </Modal>
  )
}
