import moment from "moment"
import { date_format } from "../../../../../constants"

export const CUSTOM_SHIFT_SINGLE = "single"
export const CUSTOM_SHIFT_RECURRING = "recurring"

const recurringShiftType = ({ effective_date, deactivated_date }) =>
  deactivated_date === moment(effective_date).add(1, "d").format(date_format)
    ? CUSTOM_SHIFT_SINGLE
    : CUSTOM_SHIFT_RECURRING

export const isSingleCustomShift = recurring_shift =>
  recurringShiftType(recurring_shift) === CUSTOM_SHIFT_SINGLE
