import React from "react"
import Styled from "styled-components"
import Message from "./"

const ErrorsList = Styled.ul`
  margin: 0;
  padding-left: 1rem;
`

export default ({ header = "An Error Occurred", errors = [], ...rest }) => {
  return (
    <Message type="error" {...rest}>
      <Message.Header>{header}</Message.Header>
      <Message.Content>
        <ErrorsList>
          {errors.map((err, idx) => (
            <li key={idx}>{err}</li>
          ))}
        </ErrorsList>
      </Message.Content>
    </Message>
  )
}
