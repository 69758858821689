import { useState, useEffect, forwardRef } from "react"
import { Input as SemanticInput } from "semantic-ui-react"

import { useDebounce } from "../../../hooks"

// default export is semantic input with conditional "debounce" wrapper
const Input = forwardRef(
  (
    {
      disabled = false,
      debounce = false,
      spellCheck = "false",
      highlightOnFocus = false,
      autoComplete = "disabled-by-default",
      ...rest
    },
    ref
  ) => {
    let props = { disabled, spellCheck, autoComplete, ...rest }
    if (!disabled && highlightOnFocus) {
      props.onFocus = e => e.target.select()
    }
    return !!debounce ? (
      <DebouncedInput {...props} ref={ref} />
    ) : (
      <SemanticInput {...props} ref={ref} />
    )
  }
)

const DEFAULT_DEBOUNCE_DELAY = 200
const DebouncedInput = forwardRef(
  (
    { value, onChange, debounce_delay_ms = DEFAULT_DEBOUNCE_DELAY, ...props },
    ref
  ) => {
    const [change_event, setChangeEvent] = useState({ d: { value } })
    const { e, d } = change_event
    const current_value = d?.value ?? ""
    const debounced_value = useDebounce(current_value, debounce_delay_ms)

    // invoke onchange callback when debounced_value changes
    useEffect(() => {
      if (e) {
        return onChange(e, d)
      }
    }, [debounced_value])
    // set current_
    useEffect(() => {
      if (value !== current_value) {
        setChangeEvent({ d: { value } })
      }
    }, [value])

    return (
      <SemanticInput
        {...props}
        ref={ref}
        value={current_value}
        onChange={(e, d) => setChangeEvent({ e, d })}
      />
    )
  }
)

export default Input
