import { useState } from "react"

import moment from "moment"
import Styled from "styled-components"
import { Form } from "semantic-ui-react"
import { Mutation } from "@apollo/client/react/components"

import DatePicker from "../../../DatePicker"
import { Modal, Message } from "../../../Shared"
import Button, { SubmitButton } from "../../../Button"
import TimeInput, { timeValueToDateTime } from "../../../Shared/Input/Time"
import { useUpdateSaleDetailsEnabled } from "../../../../hooks"

import { useBusinessDates } from "../../../../hooks"
import { UPDATE_SALE } from "../../../../graphql/mutations"
import { toInt, round, isNumber } from "../../../../helpers/number"
import { compatibleDateObject } from "../../../../helpers/datetime"
import { datetime_format, month_day_format } from "../../../../constants"

const time_format = "ddd, MMMM Do [at] h:mm a"

// default export
export default ({ sale, job_code, min_date, onSuccess, onClose }) => {
  const { businessDate } = useBusinessDates()
  const can_update_sale_details = useUpdateSaleDetailsEnabled()

  const [state, _setState] = useState(() => ({
    error: null,
    dirty: false,
    sale_date_input: businessDate(sale.opened_time, false),
    sale_time_input: !!sale.sale_time ? moment(sale.sale_time) : null,
    opened_time_input: moment(sale.opened_time),
    sale_refund_input: !!sale.total_sales_refund
      ? sale.total_sales_refund.toFixed(2)
      : "",
    tip_refund_input: !!sale.total_tips_refund
      ? sale.total_tips_refund.toFixed(2)
      : ""
  }))
  const setState = new_state => _setState({ ...state, ...new_state })

  const {
    error,
    sale_date_input,
    sale_time_input,
    opened_time_input,
    sale_refund_input,
    tip_refund_input
  } = state

  return (
    <Modal sticky size="tiny" onClose={onClose}>
      <Modal.Header onClose={onClose}>Edit Sale</Modal.Header>
      <Modal.Content>
        {!error && (
          <InfoMessage
            message={
              <>
                <strong>Note:</strong> Changes made here will override data
                reported by your point-of-sale provider.
              </>
            }
          />
        )}
        {!!error && (
          <ErrorMessage basic onDismiss={() => setState({ error: null })}>
            <p>{error}</p>
          </ErrorMessage>
        )}
        <StyledForm>
          <Form.Field>
            <label>Sale ID</label>
            <span>{sale.external_id ?? ""}</span>
          </Form.Field>
          <Form.Field>
            <label>Subtotal</label>
            <span>${sale.total_sales.toFixed(2) ?? "0.00"}</span>
          </Form.Field>
          <Form.Field>
            <label>Employee Name</label>
            <span>
              {sale.employee.first_name ?? ""} {sale.employee.last_name ?? ""}
            </span>
          </Form.Field>
          <Form.Field>
            <label>Job Code</label>
            <span>{job_code?.name ?? "-"}</span>
          </Form.Field>
          <Form.Field>
            <label>Business Date</label>
            <DatePicker
              button
              onChange={e => {
                const sale_date_input = moment(e)
                // set the date on both opened_time and sale_time inputs
                const opened_time = assignBusinessDate(
                  opened_time_input,
                  sale_date_input,
                  businessDate
                )
                const sale_time = assignBusinessDate(
                  sale_time_input,
                  sale_date_input,
                  businessDate
                )
                setState({
                  dirty: true,
                  sale_date_input,
                  sale_time_input: sale_time,
                  opened_time_input: opened_time
                })
              }}
              minDate={compatibleDateObject(min_date)}
              value={compatibleDateObject(sale_date_input)}
              maxDate={compatibleDateObject(businessDate())}
              description={
                <>Days before {min_date.format(month_day_format)} are locked.</>
              }
            />
          </Form.Field>
          <Form.Field>
            <label>Sale Opened</label>
            <TimeInput
              value={opened_time_input}
              onChange={time => {
                const opened_time = timeValueToDateTime(
                  time,
                  opened_time_input,
                  businessDate
                )
                setState({
                  dirty: true,
                  opened_time_input: opened_time,
                  error:
                    // validate sale time >= opened time
                    sale_time_input && opened_time.isAfter(sale_time_input)
                      ? "Sale open cannot be after sale closed."
                      : null
                })
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Sale Closed</label>
            <TimeInput
              value={sale_time_input}
              onChange={time => {
                const sale_time = timeValueToDateTime(
                  time,
                  opened_time_input, // sale_time_input can be null
                  businessDate
                )
                setState({
                  dirty: true,
                  sale_time_input: sale_time,
                  // validate sale time >= opened time
                  error: sale_time.isBefore(opened_time_input)
                    ? "Sale closed can't be before sale opened."
                    : null
                })
              }}
            />
          </Form.Field>
          {!!can_update_sale_details && (
            <Form.Field>
              <label>Sales Refund</label>
              <RefundInput
                refund_input={sale_refund_input}
                onChange={value => {
                  setState({
                    dirty: true,
                    sale_refund_input: value,
                    error:
                      value > sale.total_sales ||
                      (value.length > 0 && !validMoneyValue(value))
                        ? "Sale refund cannot be greater than sale subtotal."
                        : null
                  })
                }}
              />
            </Form.Field>
          )}
          {!!can_update_sale_details && (
            <Form.Field>
              <label>Tips Refund</label>
              <RefundInput
                refund_input={tip_refund_input}
                onChange={value => {
                  setState({
                    dirty: true,
                    tip_refund_input: value,
                    error:
                      value > sale.total_cc_tips + sale.total_gc_tips ||
                      (value.length > 0 && !validMoneyValue(value))
                        ? "Tip refund cannot be greater than tip total."
                        : null
                  })
                }}
              />
            </Form.Field>
          )}
        </StyledForm>
      </Modal.Content>
      <Mutation mutation={UPDATE_SALE} onCompleted={onSuccess}>
        {(updateSale, { loading }) => (
          <Modal.Actions>
            <Button disabled={loading} onClick={onClose}>
              Cancel
            </Button>
            <SubmitButton
              loading={loading}
              disabled={loading || !formIsValid(state, businessDate, sale)}
              onClick={() => {
                updateSale({
                  variables: {
                    input: {
                      id: toInt(sale.id),
                      opened_time: opened_time_input.format(datetime_format),
                      sale_time: sale_time_input.format(datetime_format),
                      total_sales_refund: parseFloat(sale_refund_input),
                      total_tips_refund: parseFloat(tip_refund_input)
                    }
                  }
                })
              }}
            />
          </Modal.Actions>
        )}
      </Mutation>
    </Modal>
  )
}

// helper methods

const validMoneyValue = value =>
  isNumber(value) &&
  parseFloat(value) >= 0 &&
  round(value, 2) === parseFloat(value)

const assignBusinessDate = (datetime, biz_date, businessDate) => {
  datetime.set("month", biz_date.month())
  datetime.set("date", biz_date.date())
  // is resulting biz dates aren't the same, hour/minute must be after midnight.
  // therefore, add a day to datetime.
  if (!biz_date.isSame(businessDate(datetime))) {
    datetime.add(1, "d")
  }
  return datetime
}

const validOpenedTime = (
  { opened_time_input, sale_time_input, sale_date_input },
  businessDate
) => {
  try {
    return (
      sale_date_input.isSame(businessDate(opened_time_input)) &&
      (sale_time_input === null ||
        opened_time_input.isSameOrBefore(sale_time_input))
    )
  } catch {}
  return false
}

const validSaleTime = (
  { sale_date_input, opened_time_input, sale_time_input },
  businessDate
) => {
  try {
    return (
      sale_time_input === null ||
      (sale_date_input.isSame(businessDate(sale_time_input)) &&
        sale_time_input.isSameOrAfter(opened_time_input))
    )
  } catch {}
  return false
}

const validSaleRefund = (state, sale) => {
  return state.sale_refund_input <= sale.total_sales
}

const validTipRefund = (state, sale) => {
  return state.tip_refund_input <= sale.total_cc_tips + sale.total_gc_tips
}

const formIsValid = (state, businessDate, sale) =>
  state.dirty &&
  validOpenedTime(state, businessDate) &&
  validSaleTime(state, businessDate) &&
  validSaleRefund(state, sale) &&
  validTipRefund(state, sale)

// Styled Components
const InfoMessage = Styled(props => <Message type="info" {...props} />)`
  margin-bottom: 1.5rem;
`
const ErrorMessage = props => <InfoMessage type="error" {...props} />
const StyledForm = Styled(Form)`
  & > div {
    margin-bottom: 1rem;
  }
`

const RefundInput = Styled(({ className, refund_input, onChange }) => (
  <div className={className}>
    <div>
      <Form.Input
        placeholder="0.00"
        icon="dollar"
        value={refund_input}
        onChange={(e, { value }) => onChange(value)}
      />
    </div>
  </div>
))`
  & > div {
    display: inline-block;
  }
`
