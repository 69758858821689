import { useState } from "react"
import Styled from "styled-components"
import StatusColumn from "./StatusColumn"
import { Message, Table } from "../../../Shared"
import { displayMoney, formatFromUtc } from "../../../../helpers"
import { ServiceLabel } from "../../Styled"
import { datesIncludedDisplay } from "../../helpers"

export default props => {
  const {
    payout_method,
    dates_included,
    completed_at,
    amount,
    employee_fee,
    net_amount,
    take_home,
    employee,
    failed,
    failed_message,
    show_completed_at,
    show_dates_included,
    show_employee,
    show_status,
    show_fees,
    show_take_home
  } = props
  const [show_detail, setShowDetail] = useState(true)
  const has_detail = failed && !!failed_message

  return (
    <>
      <Table.Row>
        <Table.Cell>
          <ServiceLabel payout_method={payout_method} content={payout_method} />
        </Table.Cell>
        {show_completed_at && (
          <Table.Cell>{formatFromUtc(completed_at)}</Table.Cell>
        )}
        {show_dates_included && (
          <Table.Cell>
            {!!dates_included.length
              ? datesIncludedDisplay(dates_included)
              : "n/a"}
          </Table.Cell>
        )}
        {show_employee && <Table.Cell>{employee.name}</Table.Cell>}
        {show_status && (
          <StatusColumn
            failed={failed}
            completed_at={completed_at}
            has_detail={has_detail}
            handleShowDetail={() => setShowDetail(!show_detail)}
          />
        )}
        <Table.Cell textAlign="right">{displayMoney(amount)}</Table.Cell>
        {show_fees && (
          <Table.Cell textAlign="right">
            {displayMoney(employee_fee)}
          </Table.Cell>
        )}
        <Table.Cell textAlign="right">{displayMoney(net_amount)}</Table.Cell>
        {show_take_home && (
          <TakeHomeCell textAlign="right">
            {displayMoney(take_home)}
          </TakeHomeCell>
        )}
      </Table.Row>
      {show_detail && has_detail && (
        <DetailRow failed_message={failed_message} />
      )}
    </>
  )
}

const DetailRow = ({ failed_message }) => (
  <Table.Row secondary>
    <Table.Cell colSpan={6}>
      <Message title="Error Detail" type="danger" message={failed_message} />
    </Table.Cell>
  </Table.Row>
)

const TakeHomeCell = Styled(Table.Cell)`
  & {
    border-left: 1px solid rgba(34,36,38,.1);
  }
`
