import Styled from "styled-components"
import { Loader } from "../../../Shared"

import { useProcessingInProgress } from "../../../../hooks"

export default ({ end_time, onRefresh }) => {
  const processing_in_progress = useProcessingInProgress({
    end_time,
    onRefresh
  })
  return processing_in_progress ? <CurrentlyProcessing /> : null
}

const CurrentlyProcessing = Styled(props => (
  <div {...props}>
    <Loader active inline indeterminate size="mini" />
    <ProcessingLabel>Rules Processing</ProcessingLabel>
  </div>
))`
  display: inline-block;
  line-height: 2.73rem;
  margin-left: 0.5rem;
`
const ProcessingLabel = Styled.span`
  padding: 0.33rem 0.5rem;
  font-weight: 300;
  font-size: 0.95rem;
`
