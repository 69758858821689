import React from "react"
import Styled from "styled-components"
import { colors } from "./../../constants"

const LogoWrapper = Styled.div`
  border-radius: 3px;
  display: inline-block;
  line-height: 0px;
  vertical-align: ${({ verticalAlign }) => verticalAlign};
  background-color: ${({ transparent, inverted }) =>
    !!transparent ? "transparent" : !!inverted ? colors.dark3 : colors.white};
  & > span {
    padding: 0.3em 0.4em;
    border-radius: 0.5em;
  }
`

const sizes = {
  mini: {
    height: "16px"
  },
  tiny: {
    height: "24px"
  },
  small: {
    height: "32px"
  },
  medium: {
    height: "40px"
  },
  large: {
    height: "50px"
  },
  big: {
    height: "55px"
  },
  huge: {
    height: "60px"
  },
  massive: {
    height: "65px"
  },
  insane: {
    height: "200px"
  }
}

export const IconLogoSvg = ({
  style,
  inverted,
  primary = colors.info,
  secondary = colors.light3
}) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 480 480"
      style={{ ...style }}
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        xmlns="http://www.w3.org/2000/svg"
        transform="matrix(1.3333333,0,0,-1.3333333,0,480)"
        id="g10"
      >
        <g id="g12">
          <g id="g14">
            <g transform="translate(86.0542,189.5728)" id="g20">
              <path
                id="path22"
                style={{ fill: "#9cdcf7" }}
                d="M 0,0 17.375,-0.147 V 26.245 L 39.823,36.699 V 0 h 25.42 V -19.663 H 39.728 c 0,0 0.131,-24.867 0.131,-30.177 0,-5.313 -0.954,-19.666 5.779,-23.315 7.36,-3.99 17.059,1.26 17.059,1.26 l 6.116,-19.32 c -3.864,-1.343 -21.411,-7.887 -36.582,-0.19 -16.473,8.357 -14.941,30.734 -14.941,30.734 v 41.036 H 0 Z M -22.053,-124.031 H 92.038 v 190.234 10e-4 25.597 0.001 L -22.053,38.268 Z"
              />
            </g>
            <g transform="translate(103.3442,128.9014)" id="g24">
              <path
                id="path26"
                style={{ fill: "#616163" }}
                d="m 0,0 c 0,0 -1.532,-22.377 14.941,-30.733 15.171,-7.698 32.718,-1.154 36.582,0.189 l -6.116,19.32 c 0,0 -9.699,-5.25 -17.059,-1.259 -6.733,3.648 -5.779,18.002 -5.779,23.314 0,5.311 -0.132,30.178 -0.132,30.178 h 25.516 v 19.662 h -25.42 v 36.7 L 0.085,86.916 V 60.524 L -17.29,60.671 V 41.036 H 0 Z"
              />
            </g>
            <g transform="translate(178.0918,281.3735)" id="g28">
              <path
                id="path30"
                style={{ fill: "#616163" }}
                d="m 0,0 v -25.597 -10e-4 -190.234 H -114.09 V -53.533 L 0,0.001 Z m 45.004,-52.086 72.664,-36.279 V -215.832 H 88.68 v 75.543 c 0,30.053 -14.277,41.205 -35.357,42.428 -21.079,1.224 -30.149,-14.088 -30.149,-14.088 L 23.239,35.568 -136.913,-39.031 V -238.656 H 23.239 v 92.87 c 0,0 -0.662,27.257 24.019,27.257 23.363,0 19.449,-29.191 19.449,-39.137 0,-1.169 0.094,-80.99 0.094,-80.99 h 73.691 v 164.623 l -95.488,47.286 z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ({
  size = "tiny",
  inverted = true,
  transparent = true,
  verticalAlign = "middle",
  primaryColor,
  secondaryColor,
  style
}) => (
  <LogoWrapper
    inverted={inverted}
    transparent={transparent}
    verticalAlign={verticalAlign}
    style={{ ...style }}
  >
    <IconLogoSvg
      inverted={inverted}
      style={{ ...sizes[size] }}
      primary={primaryColor}
      secondary={secondaryColor}
    />
  </LogoWrapper>
)
