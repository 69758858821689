import { useState, useEffect } from "react"
import { Form } from "semantic-ui-react"
import { settings_view } from "../../../../../../../constants/permissions"
import { entity_type_store } from "../../../../../../../constants/role"
import useAuthorization from "../../../../../../../hooks/authorization"
import {
  store_role_excluded_settings_permissions,
  toggleManagePermissions,
  useVisibleSettingsPermissions
} from "../helpers"
import { ToggleField, TogglePermissionsTable } from "../Styled"

export const settingsStep = (
  { permissions, entity_type, edit_mode },
  onChange
) => {
  const [visited, setVisited] = useState(false)
  return {
    name: "Settings",
    complete: !edit_mode || visited,
    render: () => (
      <Settings
        permissions={permissions}
        entity_type={entity_type}
        edit_mode={edit_mode}
        onChange={new_state =>
          onChange({
            permissions: {
              ...permissions,
              ...new_state
            }
          })
        }
        onMount={() => setVisited(true)}
      />
    )
  }
}

export const Settings = ({
  permissions,
  entity_type,
  edit_mode,
  onChange,
  onMount
}) => {
  const { hasPermission } = useAuthorization()
  const settings_permissions = useVisibleSettingsPermissions(entity_type)
  // exclude settings_view permission
  const settings_manage_permissions = settings_permissions.filter(
    ({ name }) => name !== settings_view
  )
  // settings manage permissions the user has access to
  const allowed_manage_permissions = settings_manage_permissions.filter(
    ({ disabled }) => !disabled
  )
  // determines the checked state for Manage Reporting toggle
  const all_manage_selected =
    !!allowed_manage_permissions.length &&
    allowed_manage_permissions.every(
      permission => !!permissions[permission.name]
    )

  // check if user is creating a store level role and has selected permissions not allowed at store level
  const isStoreRoleExcludePermissionsSelected = (entity_type, permissions) => {
    const excluded_permissions_selected = !!store_role_excluded_settings_permissions.find(
      name => !!permissions[name]
    )
    return entity_type === entity_type_store && excluded_permissions_selected
  }

  useEffect(() => onMount(), [])

  useEffect(() => {
    if (
      edit_mode &&
      isStoreRoleExcludePermissionsSelected(entity_type, permissions)
    ) {
      // auto-uncheck permissions not allowed for store level roles
      const new_state = store_role_excluded_settings_permissions.reduce(
        (acc, name) => ({ ...acc, [name]: false }),
        {}
      )
      onChange(new_state)
    }
  }, [entity_type])

  return (
    <>
      <Form>
        <ToggleField
          checked={permissions[settings_view]}
          disabled={!edit_mode || !hasPermission(settings_view)}
          label="Settings Tab Visible"
          onChange={checked => {
            let new_state = { [settings_view]: checked }
            // auto-uncheck the manage permission when de-selecting view
            if (!checked) {
              new_state = {
                ...new_state,
                // de-select all allowed settings manage permissions
                ...toggleManagePermissions(allowed_manage_permissions, checked)
              }
            }
            onChange(new_state)
          }}
        />
        <ToggleField
          checked={all_manage_selected}
          disabled={!edit_mode || !hasPermission(settings_view)}
          label="Manage Settings"
          onChange={checked => {
            // toggle all allowed settings manage permissions
            let new_state = toggleManagePermissions(
              allowed_manage_permissions,
              checked
            )
            // auto-check the view permission when selecting manage
            if (checked) {
              new_state = {
                ...new_state,
                [settings_view]: checked
              }
            }
            onChange(new_state)
          }}
        />
      </Form>
      <TogglePermissionsTable
        rows={settings_manage_permissions}
        selected={permissions}
        onChange={(value, checked) => {
          let new_state = { [value]: checked }
          // auto-check the view permission when selecting a permission
          if (checked) {
            new_state = {
              ...new_state,
              [settings_view]: checked
            }
          }
          onChange(new_state)
        }}
        edit_mode={edit_mode}
      />
    </>
  )
}
