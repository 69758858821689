import Styled from "styled-components"
import { Label } from "semantic-ui-react"
import { colors } from "../../constants"
import {
  PAYOUT_METHOD_HAUSMONEY,
  PAYOUT_METHOD_HAUSDIRECT,
  PAYOUT_METHOD_PENDING
} from "./helpers"

export const ServiceLabel = Styled(props => <Label {...props} />)`
  &.ui.label {
    background-color: ${({ payout_method }) =>
      payout_method === PAYOUT_METHOD_HAUSMONEY
        ? colors.success
        : payout_method === PAYOUT_METHOD_HAUSDIRECT
        ? colors.green
        : colors.light4};
    color: ${({ payout_method }) =>
      payout_method === PAYOUT_METHOD_PENDING ? colors.primary : colors.light};
    font-weight: 500;
  }
`
