import { useState } from "react"
import { lighten } from "polished"
import withSizes from "react-sizes"
import Styled from "styled-components"

import Input from "./Input"
import Popup from "../Popup"
import Icon from "../Icon"
import Button from "../Button"

import { colors, sizesToProps } from "../../../constants"

// add a search box if options.length > N
const MIN_SEARCHABLE_AMOUNT = 8

// large enough to fit 7 items without scroll
const PANEL_WIDTH = "25rem"
const MIN_PANEL_HEIGHT = "10rem"
const MAX_PANEL_HEIGHT = "19.355rem"

const MultiSelectList = Styled(
  ({ className, fluid, full_width, controls, children }) => (
    <div className={className}>
      <div>{controls}</div>
      <SelectPanel fluid={fluid} full_width={full_width}>
        {children}
      </SelectPanel>
    </div>
  )
)`
  background-color: ${colors.white};
  padding: 3px;
  border-radius: 3px;
  border: 0.5px solid ${colors.input_border};
  display: ${({ fluid, full_width }) =>
    full_width || fluid ? "block" : "inline-block"};
  ${({ fitted }) => (!!fitted ? "" : "margin-bottom: 0.67rem;")}
`
const SelectPanel = Styled(({ fluid, full_width, ...props }) => (
  <div {...props} />
))`
  position: relative;
  border-radius: 3px;
  border: 0.5px solid transparent;
  background-color: ${colors.input_border};
  overflow: hidden;

  display: grid;
  grid-template-columns: ${({ full_width, fluid }) =>
    !!full_width
      ? "100%"
      : `${fluid ? "50%" : PANEL_WIDTH} ${fluid ? "auto" : PANEL_WIDTH}`};
  grid-column-gap: 1px;
  grid-row-gap: 0.5px;

  & > div {
    max-height: ${MAX_PANEL_HEIGHT};
    min-height: ${MIN_PANEL_HEIGHT};
    position: relative;
    overflow: auto;
  }
`
const SelectFrom = Styled.div`
  background-color: ${colors.white};
`
const SelectFromItem = Styled.div`
  position: relative;
  padding: 0.67rem;
  cursor: pointer;
  background-color: ${colors.white};
  border-bottom: 0.5px solid ${colors.light3};
  color: ${colors.dark2};
  font-weight: 400;
  &:hover {
    background-color: ${colors.light3};
    color: ${colors.dark3};
  }
`
const CurrentlySelected = Styled.div`
  position: relative;
  padding: 0.67rem;
  background-color: ${colors.light2};
  white-space: normal;
`
const SelectedTag = Styled.div`
  margin: 0 0.33rem 0.5rem 0;
  cursor: default;
  background-color: ${lighten(0.05, colors.success)};
  color: ${colors.white};
  display: inline-block;
  border-radius: 3px;
  padding: 0.33rem 0.33rem 0.33rem 0.67rem;
  font-weight: bold;
`
const SelectedCount = Styled(({ total, className }) => (
  <span className={className}>{total} selected</span>
))`
  color: ${colors.dark4};
  font-weight: 300;
  padding: 0.5rem 0.67rem;
`
const RemoveIcon = Styled(Icon)`
  &&& {
    margin: 0 0 0 0.17rem;
    padding: 0.33rem;
    cursor: pointer;
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }
`
const UnselectAllButton = Styled(
  ({ isMobile, total_selected, onClick, ...props }) => (
    <div {...props}>
      {!isMobile && <SelectedCount total={total_selected} />}
      <Popup content="Clear selected items" delay position="top right">
        <Button basic circular size="tiny" icon="remove" onClick={onClick} />
      </Popup>
    </div>
  )
)`
  position: absolute;
  right: 0;
  top: 0.2em;
`
const CheckIcon = Styled.div`
  display:inline-block;
  position: absolute;
  right: 0;
  top: -0.2em;
  margin: 0.7em;
  font-size: 1.3em;
  color: ${colors.info};
`
const Controls = Styled(
  ({ isMobile, total_selected, onClear, children, ...props }) => (
    <div {...props}>
      <div>{children}</div>
      {total_selected > 0 && (
        <UnselectAllButton
          isMobile={isMobile}
          total_selected={total_selected}
          onClick={onClear}
        />
      )}
    </div>
  )
)`
  position: relative;
  ${({ total_selected }) => (!total_selected ? "" : `min-height: 2.96em;`)}

  & > div:first-child {
    padding-right: 9rem;

    & > * {
      vertical-align: top;
      margin-right: 0.33rem;
      margin-bottom: 3px;

      &.button {
        padding-top: 0.8em !important;
        padding-bottom: 0.8em !important;
      }
    }
  }
`
const Notification = Styled(({ msg, className }) => (
  <div className={className}>{msg}</div>
))`
  padding: ${({ padded = false }) => (!!padded ? "1.33" : "0.67")}rem;
  color: ${colors.dark2};
  font-weight: 300;
`
const SelectAllButton = Styled(props => (
  <Button basic {...props}>
    Select All
  </Button>
))``
const SearchInput = Styled(Input)``

const MultiSelectComponent = ({
  isMobile,
  onUpdate,
  available,
  selected,
  icon,
  selection_placeholder = null,
  fitted = true,
  fluid = false,
  full_width = false,
  searchable = undefined,
  controls = null
}) => {
  const [filter, setFilter] = useState("")

  const visible_items = available
    .filter(
      // unique based on item name
      (item, idx) => available.map(a => a.name).indexOf(item.name) === idx
    )
    .filter(
      item =>
        !filter.trim().length ||
        item.name.trim().toLowerCase().indexOf(filter.toLowerCase().trim()) > -1
    )
    .filter(item => selected.filter(s => s.id === item.id).length === 0)

  return (
    <MultiSelectList
      fitted={fitted}
      fluid={fluid}
      full_width={!!isMobile || !!full_width}
      controls={
        <Controls
          isMobile={isMobile}
          total_selected={selected.length}
          onClear={() => onUpdate([])}
        >
          {available.length > 1 && (
            <>
              {(!!searchable ||
                (searchable === undefined &&
                  available.length >= MIN_SEARCHABLE_AMOUNT)) && (
                <SearchInput
                  placeholder="Search..."
                  value={filter}
                  onChange={(e, { value }) => setFilter(value)}
                  action={
                    filter.length > 0
                      ? {
                          icon: <Icon name="remove" />,
                          onClick: () => setFilter("")
                        }
                      : undefined
                  }
                />
              )}
              <SelectAllButton
                onClick={() => {
                  let selected_by_name = selected.reduce(
                    (acc, item) => ({ ...acc, [item.name]: { ...item } }),
                    {}
                  )
                  let visible_by_name = visible_items.reduce(
                    (acc, item) => ({ ...acc, [item.name]: { ...item } }),
                    {}
                  )
                  onUpdate([
                    ...Object.values({
                      ...selected_by_name,
                      ...visible_by_name
                    })
                  ])
                }}
              />
            </>
          )}
          {!!controls && controls}
        </Controls>
      }
    >
      <SelectFrom>
        {visible_items
          // .filter(item => selected.filter(s => s.id === item.id).length === 0)
          .map(item => (
            <SelectFromItem
              key={item.name}
              onClick={e => {
                if (selected.filter(s => s.id === item.id).length > 0) {
                  onUpdate(selected.filter(s => s.name !== item.name))
                } else {
                  selected.push(item)
                  onUpdate(selected)
                }
              }}
              // selected={selected.filter(s => s.id === item.id).length > 0}
            >
              {!!icon && (
                <>
                  <Icon name={icon} />
                  &nbsp;
                </>
              )}
              {item.name}
              {selected.filter(s => s.id === item.id).length > 0 && (
                <CheckIcon>
                  <Icon name="check circle" />
                </CheckIcon>
              )}
            </SelectFromItem>
          ))}
        {available.length > 0 &&
          filter.trim().length === 0 &&
          visible_items.length === 0 && (
            <Notification msg={<>All items are selected.</>} padded />
          )}
        {available.length === 0 && (
          <Notification padded msg={`No results found.`} />
        )}
        {available.length > 0 &&
          filter.trim().length > 0 &&
          available.filter(
            item =>
              !filter.trim().length ||
              item.name
                .trim()
                .toLowerCase()
                .indexOf(filter.toLowerCase().trim()) > -1
          ).length === 0 && (
            <Notification padded msg={`No results match your filter.`} />
          )}
      </SelectFrom>
      <CurrentlySelected>
        {selected.length === 0 && (
          <Notification
            msg={
              selection_placeholder ?? (
                <>
                  Select one or more items listed{" "}
                  {!!isMobile ? `above` : `to the left`}.
                </>
              )
            }
          />
        )}
        {selected.length > 0 &&
          selected.map(item => (
            <SelectedTag key={item.id}>
              {item.name}
              <RemoveIcon
                name="remove"
                onClick={() =>
                  onUpdate(selected.filter(s => s.name !== item.name))
                }
              />
            </SelectedTag>
          ))}
      </CurrentlySelected>
    </MultiSelectList>
  )
}

export default withSizes(sizesToProps)(MultiSelectComponent)
