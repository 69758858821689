import Styled from "styled-components"

import Icon from "../../Icon"

import { colors } from "../../../constants"

export default ({ value, onRemove = null }) => (
  <SelectedLabel onClick={onRemove ? () => onRemove() : null}>
    {value}
    {!!onRemove && <Icon name="remove" fitted />}
  </SelectedLabel>
)

const SelectedLabel = Styled.div`
  line-height: 1.67rem;
  display: inline-block;
  font-weight: bold;
  background-color: ${colors.light3};
  color: ${colors.dark2};
  border-radius: 4px;
  fonts-size: 1.2rem;

  ${({ onClick }) =>
    !onClick
      ? `
    cursor: default;
    padding: 0.2rem 0.67rem;
  `
      : `
    cursor: pointer;
    padding: 0.2rem 0 0.2rem 0.67rem;
    & > .icon {
      padding: 0.5rem 0.67rem;
      color: ${colors.dark6};
    }
    &:hover {
      & {
        color: ${colors.dark};
        box-shadow: 0 1px 2px ${colors.light6};
      }
      & > .icon {
        color: ${colors.red};
      }
    }
  `};
`
