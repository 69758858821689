import React from "react"

import moment from "moment"

import { date_format } from "../../../constants"

export default ({ start_date, end_date }) => (
  <>
    Loading your Breakdown Report for&nbsp;
    <strong>{moment(start_date).format("dddd, MMMM Do")}</strong>
    {moment(start_date).format(date_format) !==
      moment(end_date).format(date_format) && (
      <>
        {" "}
        through <strong>{moment(end_date).format("dddd, MMMM Do")}</strong>
      </>
    )}
  </>
)
