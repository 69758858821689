import gql from "graphql-tag"

export const CREATE_RULE = gql`
  mutation createRule($input: CreateRuleInput!) {
    createRule(input: $input) {
      id
    }
  }
`

export const DEACTIVATE_RULE_MUTATION = gql`
  mutation deactivateRule($id: ID!, $deactivated_date: DateTime) {
    deactivateRule(id: $id, deactivated_date: $deactivated_date) {
      id
    }
  }
`

export const ACTIVATE_RULE_MUTATION = gql`
  mutation activateRule($id: ID!, $activated_date: DateTime!) {
    activateRule(id: $id, activated_date: $activated_date) {
      id
    }
  }
`

export const UPDATE_RULE = gql`
  mutation updateRule($id: ID!, $input: UpdateRuleInput!) {
    updateRule(id: $id, input: $input) {
      id
    }
  }
`

export const UPDATE_JOB_CODE = gql`
  mutation updateJobCode(
    $id: ID!
    $name: String
    $displayed: Boolean
    $label: String
    $deleted: Boolean
    $take_home_enabled: Boolean
  ) {
    updateJobCode(
      id: $id
      name: $name
      displayed: $displayed
      label: $label
      deleted: $deleted
      take_home_enabled: $take_home_enabled
    ) {
      id
      displayed
      take_home_enabled
    }
  }
`

export const MASS_UPDATE_JOB_CODE_SETTINGS = gql`
  mutation massUpdateJobCodeSettings(
    $ids: [ID]
    $displayed: Boolean
    $take_home_enabled: Boolean
  ) {
    massUpdateJobCodeSettings(
      ids: $ids
      displayed: $displayed
      take_home_enabled: $take_home_enabled
    ) {
      id
      displayed
      take_home_enabled
    }
  }
`

export const UPDATE_SALE = gql`
  mutation updateSale($input: SaleInput!) {
    updateSale(input: $input) {
      id
      sale_time
    }
  }
`

export const UPDATE_WORKED_SHIFT = gql`
  mutation updateWorkedShift($input: WorkedShiftInput!) {
    updateWorkedShift(input: $input) {
      id
      start
    }
  }
`

export const REMOVE_SALE_ASSIGNED_JOB_CODE = gql`
  mutation removeSaleAssignedJobCode($id: Int!) {
    removeSaleAssignedJobCode(id: $id) {
      id
      assignedJobCode {
        id
        name
      }
    }
  }
`

export const ASSIGN_JOB_CODE_TO_SALES = gql`
  mutation assignJobCodeToSales(
    $store_id: Int!
    $job_code_id: Int!
    $sale_ids: [Int!]!
  ) {
    assignJobCodeToSales(
      store_id: $store_id
      job_code_id: $job_code_id
      sale_ids: $sale_ids
    ) {
      id
      sale_time
      assignedJobCode {
        id
        name
      }
    }
  }
`

export const ASSIGN_JOB_CODE_TO_POOL = gql`
  mutation UpdateJobCode($id: ID!, $tip_pool_id: Int) {
    updateJobCode(id: $id, tip_pool_id: $tip_pool_id) {
      id
      tip_pool_id
    }
  }
`

export const UPDATE_ORGANIZATION_SETTING = gql`
  mutation updateOrganizationSetting(
    $organization_id: ID!
    $key: String!
    $value: String!
  ) {
    updateOrganizationSetting(
      organization_id: $organization_id
      key: $key
      value: $value
    ) {
      id
      settings {
        key
        value
      }
    }
  }
`

export const UPDATE_PEER_TO_PEER_ORGANIZATION_SETTING = gql`
  mutation updatePeerToPeerOrganizationSetting($value: String!) {
    updatePeerToPeerOrganizationSetting(value: $value) {
      id
      settings {
        key
        value
      }
    }
  }
`

export const UPDATE_TIP_FEE_ORGANIZATION_SETTING = gql`
  mutation updateTipFeeOrganizationSetting($value: String!) {
    updateTipFeeOrganizationSetting(value: $value) {
      id
      settings {
        key
        value
      }
    }
  }
`

export const UPDATE_PAY_PERIOD_AUTO_FINALIZE_SETTING = gql`
  mutation updatePayPeriodAutoFinalizeSetting($value: String!) {
    updatePayPeriodAutoFinalizeSetting(value: $value) {
      id
      settings {
        key
        value
      }
    }
  }
`

export const ONBOARD_ORGANIZATION = gql`
  mutation onboardOrganization($input: OnboardOrganizationInput!) {
    onboardOrganization(input: $input) {
      success
      message
      payload
    }
  }
`

export const UPDATE_STORE_SETTING = gql`
  mutation updateStoreSetting($store_id: ID!, $key: String!, $value: String!) {
    updateStoreSetting(store_id: $store_id, key: $key, value: $value) {
      id
      settings {
        key
        value
      }
    }
  }
`

export const UPDATE_STORE_TIP_FEE = gql`
  mutation updateStoreTipFee($store_id: ID!, $value: String!) {
    updateStoreTipFee(store_id: $store_id, value: $value) {
      id
      settings {
        key
        value
      }
    }
  }
`

export const CREATE_USER = gql`
  mutation createClientDashUser(
    $name: String!
    $email: String!
    $role_id: ID!
    $store_ids: [ID]
  ) {
    createClientDashUser(
      name: $name
      email: $email
      role_id: $role_id
      store_ids: $store_ids
    ) {
      id
      name
      email
    }
  }
`

export const EDIT_USER = gql`
  mutation editClientDashUser(
    $id: ID!
    $name: String
    $email: String
    $role_id: ID
    $store_ids: [ID]
    $active: Boolean
  ) {
    editClientDashUser(
      id: $id
      name: $name
      email: $email
      role_id: $role_id
      store_ids: $store_ids
      active: $active
    ) {
      id
      name
      email
    }
  }
`

export const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`

export const CHANGE_PASSWORD = gql`
  mutation changePassword($new_password: String!, $current_password: String!) {
    changePassword(
      new_password: $new_password
      current_password: $current_password
    ) {
      success
    }
  }
`

export const CREATE_USER_FEEDBACK = gql`
  mutation createUserFeedback(
    $category: String!
    $contact: String
    $feedback: String!
  ) {
    createUserFeedback(
      category: $category
      contact: $contact
      feedback: $feedback
    ) {
      id
      category
      contact
      feedback
    }
  }
`

export const SEND_EMPLOYEE_REGISTRATION = gql`
  mutation sendRegistrationEmail($id: Int!, $email: String) {
    sendRegistrationEmail(id: $id, email: $email) {
      sent
    }
  }
`

export const SEND_EMPLOYEE_REGISTRATIONS = gql`
  mutation sendRegistrationEmails($invites: [EmployeeInvite!]!) {
    sendRegistrationEmails(invites: $invites) {
      success
    }
  }
`

export const SEND_BATCH_REGISTRATION = gql`
  mutation sendRegistrationToEmployees($store_id: ID!) {
    sendRegistrationToEmployees(id: $store_id) {
      sent
    }
  }
`

export const RESEND_CLIENT_DASH_REGISTRATION = gql`
  mutation resendClientDashRegistration($id: ID!) {
    resendClientDashRegistration(id: $id) {
      success
    }
  }
`

export const UPSERT_ORGANIZATION_PAYROLL = gql`
  mutation upsertOrganizationPayroll(
    $payroll_interval: String!
    $payroll_reference_date: Date
  ) {
    upsertOrganizationPayroll(
      payroll_interval: $payroll_interval
      payroll_reference_date: $payroll_reference_date
    ) {
      success
    }
  }
`

export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      message
      token
      token_type
      expires
    }
  }
`

export const LOGIN_ENTERPRISE_ADMIN_TO_ORG = gql`
  mutation loginEnterpriseAdminToOrg($id: ID!) {
    loginEnterpriseAdminToOrg(id: $id) {
      message
      token
      token_type
      expires
    }
  }
`

export const LOGOUT_USER = gql`
  mutation logout {
    logout {
      success
    }
  }
`

export const ASSIGN_JOB_CODE_OVERRIDE = gql`
  mutation setEmployeeConstantJobCode(
    $employee_id: Int!
    $job_code_id: Int
    $reprocess_date: DateTime
  ) {
    setEmployeeConstantJobCode(
      employee_id: $employee_id
      job_code_id: $job_code_id
      reprocess_date: $reprocess_date
    ) {
      message
      success
      payload
    }
  }
`

export const CLONE_RULE = gql`
  mutation cloneRule($id: ID!, $store_ids: [Int!]) {
    cloneRule(id: $id, store_ids: $store_ids) {
      id
      name
      store {
        id
        name
      }
    }
  }
`

export const UPDATE_USER_SERVICE_AGREEMENTS = gql`
  mutation updateUserServiceAgreements($input: UserServiceAgreementInput!) {
    updateUserServiceAgreements(input: $input) {
      id
    }
  }
`

export const UPDATE_USER_SETTING = gql`
  mutation updateUserSetting($key: String!, $value: String!) {
    updateUserSetting(key: $key, value: $value) {
      id
      settings {
        key
        value
      }
    }
  }
`

export const REQUEST_REPROCESS_STORES = gql`
  mutation reprocessStores(
    $date: Date!
    $store_ids: [ID!]!
    $sync_static: Boolean
    $sync_dynamic: Boolean
  ) {
    reprocessStores(
      date: $date
      store_ids: $store_ids
      sync_static: $sync_static
      sync_dynamic: $sync_dynamic
    ) {
      success
      message
    }
  }
`

export const REQUEST_REPROCESS_STORES_WITH_DATES = gql`
  mutation reprocessStoresWithDates(
    $store_dates: [StoreWithDate!]!
    $sync_static: Boolean
    $sync_dynamic: Boolean
  ) {
    reprocessStoresWithDates(
      store_dates: $store_dates
      sync_static: $sync_static
      sync_dynamic: $sync_dynamic
    ) {
      success
      message
    }
  }
`

export const UPDATE_STORE_INFO = gql`
  mutation updateStoreInfo($input: StoreInput!) {
    updateStoreInfo(input: $input) {
      id
      name
      external_id
      timezone
      active
    }
  }
`

export const UPDATE_EMPLOYEE = gql`
  mutation updateEmployee($input: UpdateEmployeeInput!) {
    updateEmployee(input: $input) {
      id
    }
  }
`

export const UPDATE_SALES_CATEGORY = gql`
  mutation updateSalesCategory($input: UpdateSalesCategoryInput!) {
    updateSalesCategory(input: $input) {
      id
    }
  }
`

export const UNLOCK_PAY_PERIOD = gql`
  mutation unlockPayPeriod($store_ids: [ID!], $pay_period_id: ID!) {
    unlockPayPeriod(store_ids: $store_ids, pay_period_id: $pay_period_id) {
      success
    }
  }
`

export const FINALIZE_PAY_PERIOD = gql`
  mutation finalizePayPeriod(
    $store_ids: [ID!]
    $resync: Boolean
    $pay_period_id: ID!
  ) {
    finalizePayPeriod(
      store_ids: $store_ids
      resync: $resync
      pay_period_id: $pay_period_id
    ) {
      success
    }
  }
`

export const CREATE_CUSTOM_JOB_CODE = gql`
  mutation createCustomJobCode($name: String!, $store_id: Int) {
    createCustomJobCode(name: $name, store_id: $store_id) {
      id
      name
      is_custom
      displayed
      external_id
      deleted
    }
  }
`

export const REPROCESS_REPORT_EXPORT_RUN = gql`
  mutation reprocessReportExportRun($id: ID!) {
    reprocessReportExportRun(id: $id) {
      success
      message
      payload
    }
  }
`

export const REMOVE_RULE_ASSIGNED_SALE = gql`
  mutation removeRuleAssignedSale($rule_id: ID!, $sale_id: ID!) {
    removeRuleAssignedSale(rule_id: $rule_id, sale_id: $sale_id) {
      id
    }
  }
`

export const INITIATE_PAYOUT = gql`
  mutation initiatePayout(
    $pay_period_id: ID!
    $requested_payouts: [EmployeePayoutRequest!]!
  ) {
    initiatePayout(
      pay_period_id: $pay_period_id
      requested_payouts: $requested_payouts
    ) {
      success
      message
    }
  }
`

export const EMPLOYEE_ETA_APPROVE = gql`
  mutation employeeEtaApprove($user_id: ID!, $effective_date: Date) {
    employeeEtaApprove(user_id: $user_id, effective_date: $effective_date) {
      id
    }
  }
`

export const FLAG_ETA_USER_REGISTRATION = gql`
  mutation flagEtaUserRegistration($user_id: ID!) {
    flagEtaUserRegistration(user_id: $user_id) {
      id
    }
  }
`

export const SEND_ETA_REGISTRATION_INVITES = gql`
  mutation sendEtaRegistrationInvites(
    $org_id: ID!
    $invites: [EmployeeInvite!]!
  ) {
    sendEtaRegistrationInvites(org_id: $org_id, invites: $invites) {
      success
    }
  }
`

export const CREATE_RECURRING_SHIFT = gql`
  mutation createRecurringShift($input: RecurringShiftInput!) {
    createRecurringShift(input: $input) {
      id
    }
  }
`

export const DELETE_RECURRING_SHIFT = gql`
  mutation deleteRecurringShift(
    $worked_shift_id: ID!
    $delete_all: Boolean
    $delete_future: Boolean
  ) {
    deleteRecurringShift(
      worked_shift_id: $worked_shift_id
      delete_all: $delete_all
      delete_future: $delete_future
    ) {
      success
      message
    }
  }
`

export const CREATE_ROLE = gql`
  mutation createClientDashRole(
    $name: String!
    $description: String!
    $entity_type: String!
    $permissions: [String!]!
    $store_ids: [ID!]
  ) {
    createClientDashRole(
      name: $name
      description: $description
      entity_type: $entity_type
      permissions: $permissions
      store_ids: $store_ids
    ) {
      id
    }
  }
`

export const DELETE_ROLE = gql`
  mutation deleteClientDashRole($id: ID!) {
    deleteClientDashRole(id: $id) {
      id
    }
  }
`

export const EDIT_ROLE = gql`
  mutation editClientDashRole($id: ID!, $name: String, $description: String) {
    editClientDashRole(id: $id, name: $name, description: $description) {
      id
    }
  }
`
