import {
  PanelHeader,
  PanelWrapper,
  PanelContent,
  PanelActions,
  PanelSubheader,
  ProductDetails,
  ProductHeadline,
  ProductSummary,
  ProductServices,
  ProductIncentives,
  ProductDescription,
  HausmoneyLogo,
  HausmoneyImage,
  HausmoneyDescription,
  HausdirectLogo,
  HausdirectImage,
  HausdirectDescription
} from "./Styled"
import { Button, SubmitButton } from "../../Shared"

import { project_info } from "../../../constants"

export default ({ onRequestFollowup, onDeclineFollowup }) => {
  return (
    <PanelWrapper>
      <PanelHeader>
        <>Earned Tip Access&trade;</>
        <PanelSubheader>
          Now Available to All {project_info.name} Customers
        </PanelSubheader>
      </PanelHeader>
      <PanelContent>
        <ProductDescription>
          <ProductHeadline>
            Ready to offer daily tip earnings? {project_info.name} makes it
            easy.
          </ProductHeadline>
          <ProductDetails>
            When enrolled in the ETA program, {project_info.name} provides three
            ways to pay your employees.{" "}
            <strong>
              By default, employees receive their tips however you've
              historically paid them - whether by direct deposit, cash, or
              paycheck.
            </strong>{" "}
            Participating employees can choose which financial experience fits
            them best.
          </ProductDetails>
          <ProductServices>
            <HausmoneyLogo src="https://tiphaus-public.s3.amazonaws.com/eta/hm-logo-transparent.png" />
            <HausmoneyImage src="https://tiphaus-public.s3.amazonaws.com/eta/hm-bank-transparent.png" />
            <HausmoneyDescription>
              Free deposits into Hausmoney&trade;, the financial app for the
              hospitality industry.
            </HausmoneyDescription>
            <HausdirectLogo src="https://tiphaus-public.s3.amazonaws.com/eta/hd-logo-transparent.png" />
            <HausdirectImage src="https://tiphaus-public.s3.amazonaws.com/eta/hd-bank-transparent.png" />
            <HausdirectDescription>
              Convenient deposits to employees' existing debit cards for a small
              fee.
            </HausdirectDescription>
          </ProductServices>
          <ProductSummary>
            Free to sign-up, with no pre-fund or float amount required!
          </ProductSummary>
          <ProductIncentives
            incentives={[
              "Boost employee retention",
              "Simplify payroll",
              "Improve shift coverage"
            ]}
          />
        </ProductDescription>
        {(onDeclineFollowup || onRequestFollowup) && (
          <PanelActions>
            {onDeclineFollowup && (
              <Button onClick={onDeclineFollowup}>
                I'm not interested right now
              </Button>
            )}
            &nbsp;
            {onRequestFollowup && (
              <SubmitButton
                fitted
                icon="chevron right"
                label="Tell me more about ETA&trade;"
                onClick={onRequestFollowup}
              />
            )}
          </PanelActions>
        )}
      </PanelContent>
    </PanelWrapper>
  )
}
