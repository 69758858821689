import { Component } from "react"

import moment from "moment"
import Styled from "styled-components"
import { Query } from "@apollo/client/react/components"
import { Table, Checkbox, Icon } from "semantic-ui-react"

import Button from "./../../Button/"
import Message from "./../../Message"
import { LoadingMessage } from "./../../Shared/Loader"

import { colors, month_day_year_format } from "../../../constants"
import { downloadFile } from "../../../helpers/api"
import { breakdownSnapshots } from "../../../graphql/queries"

const ContentWrapper = Styled.div`
  margin-left: 1rem;
`

const TableWrapper = Styled.div`
  margin-left: 0.5rem;
`

const StyledCheckbox = Styled(Checkbox)`
  vertical-align:middle !important;
`

const TableBodyRow = Styled(Table.Row)`
  cursor: pointer;
  background-color: ${({ selected }) =>
    !!selected ? colors.light2 : colors.white}
`

const TableFooter = Styled(Table.Footer)``
const FooterRow = Styled(Table.Row)``

const NoResults = () => (
  <Message type="info" title="No Snapshots">
    No breakdown snapshots found for this pay period
  </Message>
)

const breakdown_datetime_format_verbose = "ddd, MMM Do YYYY [at] h:mm a"

export default class extends Component {
  state = {
    snapshots: [],
    downloading: false,
    hovered_row: null,
    selected_snapshots: []
  }

  toggleSelectedSnapshot(snapshot) {
    const { selected_snapshots } = this.state
    const index = selected_snapshots.indexOf(snapshot)
    if (index > -1) {
      selected_snapshots.splice(index, 1)
    } else {
      selected_snapshots.push(snapshot)
    }
    this.setState({ selected_snapshots })
  }

  downloadSnapshots = async () => {
    this.setState({ downloading: true })
    const { pay_period } = this.props
    const { selected_snapshots } = this.state
    const store_ids = selected_snapshots.map(snapshot => snapshot.store.id)

    await downloadFile(`breakdown/snapshots/${pay_period.id}`, { store_ids })

    this.setState({ downloading: false })
  }

  render() {
    const { active, pay_period, selected_location_id } = this.props

    const {
      hovered_row,
      snapshots,
      selected_snapshots,
      downloading
    } = this.state

    return (
      <ContentWrapper>
        <Query
          skip={!active}
          fetchPolicy="network-only"
          onCompleted={({ breakdownSnapshots }) =>
            this.setState({
              snapshots: breakdownSnapshots
            })
          }
          query={breakdownSnapshots(pay_period.id, selected_location_id)}
        >
          {({ loading }) => {
            if (!!loading || !active) {
              return (
                <LoadingMessage loader_size="tiny" margin={false}>
                  Loading Breakdown Snapshots from{" "}
                  {moment(pay_period.start).format(month_day_year_format)} to{" "}
                  {moment(pay_period.end).format(month_day_year_format)}
                </LoadingMessage>
              )
            }

            if (!loading && !snapshots.length) return <NoResults />

            return (
              <TableWrapper>
                <Table singleLine unstackable compact>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell
                        width="1"
                        style={{ textAlign: "center" }}
                      >
                        <StyledCheckbox
                          onChange={(e, { checked }) => {
                            if (!!checked) {
                              this.setState({
                                selected_snapshots: [...snapshots]
                              })
                            } else {
                              this.setState({ selected_snapshots: [] })
                            }
                          }}
                          checked={
                            selected_snapshots.length == snapshots.length
                          }
                          fitted
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell>Location</Table.HeaderCell>
                      <Table.HeaderCell>Size</Table.HeaderCell>
                      <Table.HeaderCell>Created</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {snapshots.map((snapshot, idx) => (
                      <TableBodyRow
                        key={idx}
                        onClick={() => this.toggleSelectedSnapshot(snapshot)}
                        selected={hovered_row === idx}
                        onMouseEnter={() => this.setState({ hovered_row: idx })}
                        onMouseLeave={() =>
                          this.setState({ hovered_row: null })
                        }
                      >
                        <Table.Cell width="1" textAlign="center">
                          <StyledCheckbox
                            checked={selected_snapshots.includes(snapshot)}
                            readOnly
                            fitted
                          />
                        </Table.Cell>
                        <Table.Cell>{snapshot.store.name}</Table.Cell>
                        <Table.Cell>{snapshot.size}</Table.Cell>
                        <Table.Cell>
                          {moment
                            .unix(snapshot.last_modified)
                            .format(breakdown_datetime_format_verbose)}
                        </Table.Cell>
                      </TableBodyRow>
                    ))}
                  </Table.Body>
                  <TableFooter fullWidth>
                    <FooterRow>
                      <Table.HeaderCell colSpan="5" textAlign="left">
                        <Button
                          basic
                          primary
                          size="mini"
                          loading={downloading}
                          disabled={!selected_snapshots.length}
                          onClick={this.downloadSnapshots}
                        >
                          Download&nbsp;&nbsp;
                          <Icon name="cloud download" fitted />
                        </Button>
                      </Table.HeaderCell>
                    </FooterRow>
                  </TableFooter>
                </Table>
              </TableWrapper>
            )
          }}
        </Query>
      </ContentWrapper>
    )
  }
}
