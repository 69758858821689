import moment from "moment"
import Styled from "styled-components"

import { StatusPositive, StatusNegative } from "../Table/Styled"
import { Table, Icon, HelpIcon, Loader, Message } from "../../../Shared"

import { displayMoney } from "../../../../helpers"
import { useBusinessDates } from "../../../../hooks"
import { colors, month_day_format } from "../../../../constants"

export const TableWrapper = Styled.div``

const SummaryTableCell = Styled(({ children, ...props }) => (
  <Table.Cell {...props}>{children}</Table.Cell>
))`line-height: 1.5em;`

export const SummaryLabel = Styled(
  ({ description = null, inset, children, ...props }) => (
    <SummaryTableCell {...props}>
      <>{children}</>
      {description && (
        <>
          {" "}
          &nbsp;
          <HelpIcon popup={description} />
        </>
      )}
    </SummaryTableCell>
  )
)`${({ inset = false }) =>
  !inset
    ? `
  font-weight: 600;
`
    : `
  font-weight: 400;
  padding-left: 2em !important;
`}`

export const SummaryValue = Styled(({ add = false, strong, ...props }) => (
  <SummaryTableCell collapsing textAlign="right" {...props} />
))`${({ strong = false }) => `
  font-weight: ${strong ? "600" : "400"};
`}`

const Modifier = Styled(({ value, ...props }) => (
  <span {...props}>{value}</span>
))`
  margin-right: 0.33rem;
`
const AddModifier = props => <Modifier value="+" />
const SubtractModifier = props => <Modifier value="-" />

export const ActionCell = Styled(props => (
  <Table.ActionCell colSpan={2} {...props} />
))`
  padding-top: 1.5em !important;
  padding-bottom: 1.5em !important;
`

export const PayPeriodLabel = ({ label }) => <div>{label}</div>

export const PayPeriodDates = Styled(({ className, start, end }) => (
  <div className={className}>
    {moment(start).format(month_day_format)} -{" "}
    {moment(end).format(month_day_format)}
  </div>
))`font-size: 0.95rem;`

export const PayPeriodStatus = Styled(({ className, pay_period }) => {
  const { businessDate } = useBusinessDates()
  const today = businessDate()

  const status_open =
    moment(today).isSameOrAfter(pay_period.start) &&
    moment(today).isSameOrBefore(pay_period.end)
  const status_closed = moment(today).isAfter(pay_period.end)
  const status_final = status_closed && pay_period.exported_at

  return (
    <div className={className}>
      {status_open && (
        <>
          <OpenIcon />
          <span>Open</span>
        </>
      )}
      {status_closed && !status_final && (
        <>
          <ClosedIcon />
          <span>Closed, Not Finalized</span>
        </>
      )}
      {status_final && (
        <>
          <FinalizedIcon />
          <span>Finalized</span>
        </>
      )}
    </div>
  )
})`
  font-weight: 300;
  font-size: 0.95rem;
  display: inline-block;
  & > span {
    margin-left: 0.33em;
  }
`

const OpenIcon = () => <StatusPositive />
const ClosedIcon = () => <StatusNegative />
const FinalizedIcon = () => <Icon fitted name="lock" color={colors.dark} />

export const NoEmployeesSelected = Styled(props => (
  <div {...props}>No employees selected.</div>
))`
  line-height: 2em;
  text-align: center;
`

export const FeeDescriptiveText = ({ transfer_fee, transfer_fee_owner }) => (
  <div style={{ margin: "1rem 1rem 0" }}>
    <p>
      All hausdirect payouts accrue a fee,{" "}
      {transfer_fee_owner === "split"
        ? "split between the employer and employee"
        : `covered by the ${transfer_fee_owner}`}{" "}
      at {displayMoney(transfer_fee)} per payout.
      {transfer_fee_owner !== "employer" &&
        " Employee payout fees are limited to once per day, any further fees the employer covers."}
    </p>
  </div>
)

export const ProcessingMessage = Styled(({ className }) => (
  <Message className={className} type="error">
    <Message.Header>
      <Loader active size="mini" /> Active Sync in Progress
    </Message.Header>
    <Message.Content>
      Some employees' payouts may change; we advise waiting until the current
      sync is finished to payout.
    </Message.Content>
  </Message>
))`
  & .ui.inline.loader {
    vertical-align: baseline;
  }
`
