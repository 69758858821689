import moment from "moment"
import Styled from "styled-components"

import { Dropdown, FilterBar } from "../../Shared"
import SelectedFilter from "../../Shared/FilterBar/Filters/Selected"

import { useBusinessDates, useOrgIsMultiUnit } from "../../../hooks"
import {
  payPeriodLabel,
  payPeriodFilters,
  employeeTypeFilters,
  FILTER_LAST_PAY_PERIOD,
  FILTER_CURRENT_PAY_PERIOD,
  FILTER_PAY_PERIOD_BEFORE_LAST
} from "../helpers"
import { colors } from "../../../constants"
import { SelectLocationConsumer } from "../../../context/selectLocation"

export default ({
  filters,
  setFilters,
  pay_periods,
  selected_location,
  ...props
}) => {
  const { businessDate } = useBusinessDates()
  return (
    <FilterBar
      {...props}
      id="eta-overview-filters"
      presets={generateFilterPresets({
        pay_periods,
        today_date: businessDate(),
        filters,
        setFilters
      })}
      custom_filters={customFilters(
        selected_location,
        pay_periods,
        filters,
        setFilters,
        businessDate()
      )}
      location_id={selected_location?.id}
      loading_msg={
        <>
          Loading your ETA Summary for the&nbsp;
          <strong>{payPeriodLabel(filters.selected_pay_period)}</strong>
        </>
      }
    />
  )
}

const generateFilterPresets = ({
  pay_periods,
  today_date,
  filters,
  setFilters
}) => [
  {
    name: payPeriodLabel(FILTER_CURRENT_PAY_PERIOD),
    visible:
      pay_periods[FILTER_CURRENT_PAY_PERIOD] &&
      moment(today_date)
        .subtract(1, "day")
        .isSameOrAfter(pay_periods[FILTER_CURRENT_PAY_PERIOD].start),
    active: filters.selected_pay_period === FILTER_CURRENT_PAY_PERIOD,
    onClick: () =>
      setFilters({ selected_pay_period: FILTER_CURRENT_PAY_PERIOD })
  },
  {
    name: payPeriodLabel(FILTER_LAST_PAY_PERIOD),
    visible: pay_periods[FILTER_LAST_PAY_PERIOD],
    active: filters.selected_pay_period === FILTER_LAST_PAY_PERIOD,
    onClick: () => setFilters({ selected_pay_period: FILTER_LAST_PAY_PERIOD })
  },
  {
    name: payPeriodLabel(FILTER_PAY_PERIOD_BEFORE_LAST),

    visible: pay_periods[FILTER_PAY_PERIOD_BEFORE_LAST],
    active: filters.selected_pay_period === FILTER_PAY_PERIOD_BEFORE_LAST,
    onClick: () =>
      setFilters({ selected_pay_period: FILTER_PAY_PERIOD_BEFORE_LAST })
  }
]

const customFilters = (
  selected_location,
  pay_periods,
  filters,
  setFilters,
  today
) => {
  const is_multi_unit = useOrgIsMultiUnit()
  const pay_period_filters = payPeriodFilters(pay_periods, today)

  let custom_filters = [
    {
      label: "Pay Period",
      filter: (
        <PayPeriodDropdown
          value={filters.selected_pay_period}
          pay_period_filters={pay_period_filters}
          onChange={(e, { value }) =>
            setFilters({
              selected_pay_period: value
            })
          }
        />
      )
    },
    {
      label: "Showing",
      filter: (
        <EmployeeTypeDropdown
          value={filters.selected_employee_type}
          onChange={(e, { value }) =>
            setFilters({
              selected_employee_type: value
            })
          }
        />
      )
    }
  ]

  if (selected_location && is_multi_unit) {
    custom_filters.push({
      label: "Location",
      filter: (
        <SelectLocationConsumer>
          {selectLocation => (
            <SelectedFilter
              value={selected_location.name}
              onRemove={() => selectLocation(null)}
            />
          )}
        </SelectLocationConsumer>
      )
    })
  }

  if (filters.search_last_name) {
    custom_filters.push({
      label: "Last Name",
      filter: (
        <SelectedFilter
          value={filters.search_last_name}
          onRemove={() => setFilters({ search_last_name: "" })}
        />
      )
    })
  }

  if (filters.search_first_name) {
    custom_filters.push({
      label: "First Name",
      filter: (
        <SelectedFilter
          value={filters.search_first_name}
          onRemove={() => setFilters({ search_first_name: "" })}
        />
      )
    })
  }

  if (filters.search_location) {
    custom_filters.push({
      label: "First Name",
      filter: (
        <SelectedFilter
          value={filters.search_location}
          onRemove={() => setFilters({ search_location: "" })}
        />
      )
    })
  }

  return custom_filters
}

const FilterDropdown = Styled(props => (
  <Dropdown
    compact
    selection
    min_width_expanded="15rem"
    min_width_collapsed="15rem"
    {...props}
  />
))``

const PayPeriodDropdown = ({ value, pay_period_filters, onChange }) => (
  <FilterDropdown
    value={value}
    options={pay_period_filters.map(({ text, value, description }) => ({
      key: value,
      value,
      text,
      content: <PayPeriodOption text={text} description={description} />
    }))}
    onChange={onChange}
  />
)

const PayPeriodOption = Styled(({ className, text, description }) => (
  <div className={className}>
    {text}
    <div>{description}</div>
  </div>
))`
  & > div:first-child {
    padding-top: 0.2rem;
    color: ${colors.dark6};
    font-size: 0.9rem;
    font-weight: 400;
  }
`

const EmployeeTypeDropdown = props => (
  <FilterDropdown {...props} options={employeeTypeFilters()} />
)
