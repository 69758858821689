import { useState } from "react"

import moment from "moment"
import Styled from "styled-components"
import { Loader, Label } from "semantic-ui-react"
import { Query, Mutation } from "@apollo/client/react/components"

import Table from "../../Table"
import Popup from "../../Popup"
import Modal from "../../Modal"
import Button from "../../Button"
import Message from "../../Message"
import Input from "../../Shared/Input"
import Icon, { InfoIcon } from "../../Icon"
import { MenuDropdown } from "../../Dropdown"
import AreYouSure from "../../Modal/AreYouSure"
import EditEmployeeModal from "../../Modal/EditEmployee"
import AssignEmployeeJobCode from "../../Modal/AssignEmployeeJobCode"

import {
  ASSIGN_JOB_CODE_OVERRIDE,
  REQUEST_REPROCESS_STORES
} from "../../../graphql/mutations"
import { useBusinessDates } from "../../../hooks"
import { appMinDate, parseSearchQuery } from "../../../helpers"
import { toInt } from "../../../helpers/number"
import { EMPLOYEE_SEARCH } from "../../../graphql/queries"
import { colors, date_format, project_info } from "../../../constants"
import useAuthorization from "../../../hooks/authorization"
import {
  settings_manage_employees,
  settings_assign_job_code_to_employee
} from "../../../constants/permissions"

const PER_PAGE = 100
const DEFAULT_PAGE = 1
const COLSPAN_ALL_COLUMNS = 100

const ACTION_RESYNC = "Resync Employees with Point-of-Sale"

const USER_ASSIGNED_ALL = "All"
const USER_ASSIGNED_YES = "Yes"
const USER_ASSIGNED_NO = "No"

export default ({ user, store, toast }) => {
  const { businessStartFromDate } = useBusinessDates()
  const { employee_name = null } = parseSearchQuery(window.location.search)
  const { hasPermission } = useAuthorization()

  const [state, _setState] = useState({
    search: !!employee_name ? employee_name : "",
    page: DEFAULT_PAGE,
    per_page: PER_PAGE,
    edit_employee: false,
    request_resync: false,
    store_id_filter: false,
    no_user_filter: USER_ASSIGNED_ALL,
    assign_job_code_modal: null,
    remove_assigned_job_code_modal: null
  })

  const setState = (new_state = {}) => _setState({ ...state, ...new_state })

  const {
    page,
    per_page,
    search,
    edit_employee,
    request_resync,
    no_user_filter,
    assign_job_code_modal,
    remove_assigned_job_code_modal
  } = state

  // look for fixed store filter
  const store_id_filter = store?.id ?? state.store_id_filter

  return (
    <Query
      fetchPolicy="cache-and-network"
      notifyOnNetworkStatusChange
      query={EMPLOYEE_SEARCH}
      variables={{
        page,
        first: per_page,
        filters: {
          active: true,
          include_none: true,
          store_id: store_id_filter,
          search,
          no_user: no_user_filter === USER_ASSIGNED_ALL ? null : no_user_filter
        }
      }}
    >
      {({ loading, data, refetch }) => {
        let employees = []
        let total_employees = 0
        let paginator = {}
        let custom_controls = []
        try {
          employees = data.employeeSearch.data
          total_employees = data.employeeSearch.paginatorInfo.total
          paginator = {
            ...data.employeeSearch.paginatorInfo,
            current_page: data.employeeSearch.paginatorInfo.currentPage,
            per_page: per_page,
            options: { per_page: [25, 50, 100, 500] }
          }
        } catch (e) {}

        // table controls: employee search
        custom_controls = [
          <Input
            debounce
            icon="search"
            iconPosition="left"
            action={
              search.length > 0 && !loading
                ? {
                    icon: <RemoveIcon />,
                    onClick: () => setState({ search: "", page: DEFAULT_PAGE })
                  }
                : undefined
            }
            placeholder="Search..."
            value={search}
            onChange={(e, { value }) => {
              setState({ search: value })
            }}
          />,
          <UserAssignedDropdown
            value={no_user_filter}
            onChange={value => setState({ no_user_filter: value })}
          />
        ]

        // custom table controls
        if (!store && user.stores.length > 1) {
          // store dropdown only visible from /employees page
          custom_controls.push(
            <MenuDropdown
              value={store_id_filter}
              options={[
                { value: false, text: "All Locations" },
                ...user.stores.map(({ id, name }) => ({
                  text: name,
                  value: id
                }))
              ]}
              onChange={value => setState({ store_id_filter: value, page: 1 })}
            />
          )
        }

        // resync button
        custom_controls.push(
          <MenuDropdown
            text="Actions"
            options={[{ value: ACTION_RESYNC, text: ACTION_RESYNC }]}
            onChange={value => {
              switch (value) {
                case ACTION_RESYNC:
                  return setState({ request_resync: true })
                default:
                  return
              }
            }}
          />
        )

        return (
          <EmployeesWrapper>
            <Table
              unstackable
              draggable
              controls={{
                position: total_employees > per_page ? "both" : "top",
                custom_controls_position: "top",
                pagination: {
                  ...paginator,
                  changePage: page => setState({ page }),
                  changePerPage: per_page => setState({ per_page, page: 1 })
                },
                custom: [...custom_controls]
              }}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  {!store_id_filter && user.stores.length > 1 && (
                    <Table.HeaderCell>Location</Table.HeaderCell>
                  )}
                  <Table.HeaderCell>Email</Table.HeaderCell>
                  <Table.HeaderCell>Payroll ID</Table.HeaderCell>
                  <Table.HeaderCell>Most Recent Shift</Table.HeaderCell>
                  {hasPermission(settings_assign_job_code_to_employee) && (
                    <Table.HeaderCell>
                      <JobCodeHeader id="employee-settings-assigned-job-code">
                        Job Code Assignment{" "}
                        <InfoIcon
                          popup={
                            <>
                              Job code assignments are used to override shift
                              information when associating an employee with a
                              job code.
                            </>
                          }
                        />
                      </JobCodeHeader>
                    </Table.HeaderCell>
                  )}
                  <Table.HeaderCell>User</Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {!!loading && employees.length === 0 && (
                  <Table.Row>
                    <Table.Cell
                      textAlign="center"
                      colSpan={COLSPAN_ALL_COLUMNS}
                    >
                      <TableSpan>
                        <Loader inline active>
                          Loading...
                        </Loader>
                      </TableSpan>
                    </Table.Cell>
                  </Table.Row>
                )}
                {!loading && employees.length === 0 && (
                  <Table.Row>
                    <Table.Cell colSpan={COLSPAN_ALL_COLUMNS}>
                      <TableSpan>No employees found.</TableSpan>
                    </Table.Cell>
                  </Table.Row>
                )}
                {(!loading || employees.length > 0) &&
                  employees.map(employee => {
                    return (
                      <Table.Row key={employee.id}>
                        <Table.Cell>
                          {employee.name}
                          {!!employee.is_custom && <CustomEmployeeLabel />}
                        </Table.Cell>
                        {!store_id_filter && user.stores.length > 1 && (
                          <Table.Cell>{employee.store.name}</Table.Cell>
                        )}
                        <Table.Cell>
                          {!!employee.email ? employee.email : "-"}
                        </Table.Cell>
                        <Table.Cell>
                          {!!employee.payroll_id ? employee.payroll_id : "-"}
                        </Table.Cell>
                        <Table.Cell>
                          {!employee.most_recent_worked_shift && (
                            <Thin>None</Thin>
                          )}
                          {!!employee.most_recent_worked_shift && (
                            <RecentShift>
                              {moment(
                                employee.most_recent_worked_shift.start
                              ).format("ddd, MMM Do ")}
                              <Thin> as </Thin>
                              {employee.most_recent_worked_shift.jobCode.name}
                            </RecentShift>
                          )}
                        </Table.Cell>
                        {hasPermission(
                          settings_assign_job_code_to_employee
                        ) && (
                          <Table.Cell>
                            {!employee.assignedJobCode && (
                              <AssignJobCodeBtn
                                onClick={() =>
                                  setState({
                                    assign_job_code_modal: { ...employee }
                                  })
                                }
                              />
                            )}
                            {!!employee.assignedJobCode && (
                              <Label>
                                {employee.assignedJobCode.name}&nbsp;&nbsp;
                                <Icon
                                  name="remove"
                                  fitted
                                  cursor="pointer"
                                  onClick={() =>
                                    setState({
                                      remove_assigned_job_code_modal: {
                                        ...employee
                                      }
                                    })
                                  }
                                />
                              </Label>
                            )}
                          </Table.Cell>
                        )}
                        <Table.Cell>
                          {!!employee.user ? (
                            employee.user.name
                          ) : (
                            <Thin>None</Thin>
                          )}
                        </Table.Cell>
                        <Table.Cell width={1} textAlign="center">
                          {hasPermission(settings_manage_employees) &&
                            !isNoneEmployee(employee) && (
                              <EditButton
                                onClick={e => {
                                  setState({ edit_employee: employee })
                                }}
                              />
                            )}
                          {!!isNoneEmployee(employee) && <NoneEmployeeButton />}
                        </Table.Cell>
                      </Table.Row>
                    )
                  })}
              </Table.Body>
            </Table>
            {!!assign_job_code_modal && (
              <AssignEmployeeJobCode
                user={user}
                employee={assign_job_code_modal}
                onClose={() => setState({ assign_job_code_modal: null })}
                onCompleted={() => {
                  const { first_name } = assign_job_code_modal
                  setState({ assign_job_code_modal: null })
                  toast({
                    type: "success",
                    message: (
                      <>
                        <p>
                          Job code override successfully configured for{" "}
                          {first_name}.
                        </p>
                        <p>
                          It may take a few minutes for updates to appear on the
                          breakdown page.
                        </p>
                      </>
                    )
                  })
                  refetch()
                }}
                onError={() => {
                  const { first_name } = assign_job_code_modal
                  setState({ assign_job_code_modal: null })
                  toast({
                    type: "error",
                    message: (
                      <>
                        <p>
                          An error occurred while trying to configure the job
                          code override for {first_name}.
                        </p>
                      </>
                    )
                  })
                }}
              />
            )}
            {!!remove_assigned_job_code_modal && (
              <Mutation
                mutation={ASSIGN_JOB_CODE_OVERRIDE}
                onCompleted={() => {
                  const {
                    first_name,
                    assignedJobCode
                  } = remove_assigned_job_code_modal
                  setState({
                    remove_assigned_job_code_modal: null
                  })
                  toast({
                    type: "success",
                    message: (
                      <>
                        <p>
                          The {assignedJobCode.name} override is no longer
                          configured for {first_name}.
                        </p>
                        <p>
                          It may take a few minutes for updates to appear on the
                          breakdown page.
                        </p>
                      </>
                    )
                  })
                  refetch()
                }}
                onError={() => {
                  const { first_name } = assign_job_code_modal
                  setState({
                    assign_job_code_modal: null
                  })
                  toast({
                    type: "error",
                    message: (
                      <>
                        <p>
                          An error occurred while trying to remove the job code
                          override for {first_name}.
                        </p>
                      </>
                    )
                  })
                }}
              >
                {(setEmployeeConstantJobCode, { loading }) => (
                  <AreYouSure
                    header="Remove Assigned Job Code"
                    submitted={!!loading}
                    body={
                      <Thin>
                        <p>
                          If you confirm, the "
                          {remove_assigned_job_code_modal.assignedJobCode.name}"
                          job code override will no longer apply to{" "}
                          {remove_assigned_job_code_modal.first_name}.
                        </p>
                        <p>
                          {project_info.name} will reference employee shifts to
                          determine the appropriate job code for{" "}
                          {remove_assigned_job_code_modal.first_name}'s sales.
                        </p>
                      </Thin>
                    }
                    onClose={() =>
                      setState({ remove_assigned_job_code_modal: null })
                    }
                    onConfirm={() => {
                      setEmployeeConstantJobCode({
                        variables: {
                          employee_id: toInt(remove_assigned_job_code_modal.id),
                          reprocess_date: businessStartFromDate(
                            appMinDate(null, user)
                          )
                        }
                      })
                    }}
                  />
                )}
              </Mutation>
            )}
            {!!edit_employee && (
              <EditEmployeeModal
                toast={toast}
                employee={edit_employee}
                onCompleted={() => refetch()}
                onClose={() => setState({ edit_employee: false })}
              />
            )}
            {request_resync && (
              <Mutation
                mutation={REQUEST_REPROCESS_STORES}
                onCompleted={() => {
                  setState({ request_resync: false })
                  toast({
                    type: "success",
                    message: (
                      <>
                        <p>
                          A resync is underway. This process may take a few
                          minutes to complete.
                        </p>
                      </>
                    )
                  })
                }}
                onError={() => {
                  setState({
                    request_resync: null
                  })
                  toast({
                    type: "error",
                    message: (
                      <>
                        <p>
                          An error occurred while requesting to resync
                          employees.
                        </p>
                      </>
                    )
                  })
                }}
              >
                {(reprocessStores, { loading }) => (
                  <AreYouSure
                    header="Resync Employees"
                    submitted={!!loading}
                    body={
                      <Thin>
                        <p>
                          By default, {project_info.name} syncs employee
                          information with your point-of-sale daily.
                        </p>
                        <p>
                          If you confirm, we'll kick-off an additional sync now.
                          It may take a few minutes for the process to complete.
                        </p>
                        <p>Do you want to resync employees now?</p>
                      </Thin>
                    }
                    onClose={() => setState({ request_resync: false })}
                    onConfirm={() => {
                      reprocessStores({
                        variables: {
                          store_ids: !!store_id_filter
                            ? [store_id_filter]
                            : user.stores.map(({ id }) => id),
                          // fillter date arg
                          date: moment().format(date_format),
                          sync_static: true,
                          sync_dynamic: false
                        }
                      })
                    }}
                  />
                )}
              </Mutation>
            )}
          </EmployeesWrapper>
        )
      }}
    </Query>
  )
}

const CustomEmployeeLabel = Styled(props => (
  <Label size="small" {...props}>
    Custom
  </Label>
))`
  margin-left: 0.5rem !important;
`
const EmployeesWrapper = Styled.div`
  position: relative;
  font-size: 1rem;
`
const TableSpan = Styled.div`
  padding: 0.67rem 0;
`
const RemoveIcon = Styled(({ clickable, ...props }) => (
  <Icon name="remove" {...props} />
))`
  ${({ clickable }) =>
    !clickable
      ? ``
      : `
    cursor: pointer !important;
  `}
`
const Thin = Styled.span`font-weight:300;`
const RecentShift = Styled.div``
const AssignJobCodeBtn = props => (
  <AssignJobCodeIcon
    popup={{ delay: true, content: <>Configure a job code assignment.</> }}
    {...props}
  />
)
const AssignJobCodeIcon = Styled(props => (
  <Icon
    name="plus circle"
    cursor="pointer"
    size="large"
    color={colors.light5}
    {...props}
  />
))`
  &:hover {
    color: ${colors.success} !important;
  }
`
const JobCodeHeader = Styled.div`
  display: inline-block;
`

const EditButton = props => (
  <Popup content="Edit Employee" delay>
    <Button circular icon="edit" compact size="tiny" {...props} />
  </Popup>
)

const isNoneEmployee = ({ external_id }) => external_id === "None"

const NoneEmployeeButton = () => {
  const [show_detail, setShowDetail] = useState(false)
  return (
    <>
      <Popup content="More detail about 'None' employee" delay>
        <Button
          basic
          circular
          icon="info"
          compact
          size="tiny"
          onClick={() => setShowDetail(true)}
        />
      </Popup>
      {!!show_detail && (
        <NoneEmployeeDetailModal onClose={() => setShowDetail(false)} />
      )}
    </>
  )
}

const NoneEmployeeDetailModal = ({ onClose }) => (
  <Modal onClose={onClose} size="tiny">
    <Modal.Header onClose={onClose}>"None" Dummy Employee</Modal.Header>
    <Modal.Content>
      <Message
        type="info"
        message={
          <>
            <p>
              This is a dummy employee created by default and assigned to sales
              when no other employee has been specified.
            </p>
            <p>
              You can assign a job code to this employee from the employee
              settings table. Assigned job codes will apply to any sale missing
              an employee.
            </p>
          </>
        }
      />
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={onClose}>Close</Button>
    </Modal.Actions>
  </Modal>
)

const UserAssignedDropdown = Styled(({ className, value, onChange }) => {
  const display_text =
    value === true
      ? USER_ASSIGNED_NO
      : value == false
      ? USER_ASSIGNED_YES
      : USER_ASSIGNED_ALL
  return (
    <MenuDropdown
      className={className}
      text={`User Assigned: ${display_text}`}
      value={value}
      options={[
        {
          value: USER_ASSIGNED_ALL,
          text: USER_ASSIGNED_ALL
        },
        {
          value: false,
          text: USER_ASSIGNED_YES
        },
        {
          value: true,
          text: USER_ASSIGNED_NO
        }
      ]}
      onChange={onChange}
    />
  )
})`
  &.dropdown,
  &.dropdown.active {
    min-width: 8rem !important;
  }
`
