import React, { useState } from "react"

import Table from "../../../../Table"
import { payPeriodDateSpan } from "../../../helpers"
import { IconButton } from "../../../../Button"
import AreYouSure from "../../../../Modal/AreYouSure"
import { downloadFile } from "../../../../../helpers/api"

const downloadUrl = (report_id, selected_location_id = null) => {
  let url = `breakdown/export/${report_id}`
  if (!!selected_location_id) {
    url += `/${selected_location_id}`
  }
  return url
}
const downloadExport = async (
  filters,
  report_id,
  setDownloading,
  selected_location
) => {
  setDownloading(true)
  await downloadFile(downloadUrl(report_id, selected_location), filters)
  setDownloading(false)
}

export default ({
  onDownloadStart,
  pay_period,
  selected_location,
  report_id
}) => {
  const [request_download, setRequestDownload] = useState(false)
  const [downloading, setDownloading] = useState(false)

  return (
    <>
      <IconButton
        secondary
        icon="cloud download"
        loading={!!downloading}
        disabled={!!downloading}
        onClick={() => setRequestDownload(true)}
      >
        Export
      </IconButton>
      {!!request_download && (
        <AreYouSure
          header="Export ETA Overview?"
          body={
            <>
              <p>
                Would you like to download the ETA Overview report for the
                following Pay Period?
              </p>
              {
                <Table basic="very" compact celled={false}>
                  <Table.Body>
                    {!!pay_period && (
                      <Table.Row>
                        <Table.Cell>
                          <strong>Pay Period:</strong>
                        </Table.Cell>
                        <Table.Cell>{payPeriodDateSpan(pay_period)}</Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              }
            </>
          }
          onConfirm={() => {
            onDownloadStart()
            downloadExport(
              {
                pay_period_id: pay_period.id,
                group_by: "employee"
              },
              report_id,
              setDownloading,
              selected_location?.id
            )
            setRequestDownload(false)
          }}
          onClose={() => setRequestDownload(false)}
        />
      )}
    </>
  )
}
