import Styled from "styled-components"
import { lighten } from "polished"

import Link from "../Link"
import RouteContent from "../RouteContent"

import { isProduction } from "../../helpers"
import { colors, paths } from "../../constants"

const MessageSpan = Styled.div`
  color: ${colors.dark2};
  font-size: 1.2em;
  padding: 0.5em 0;
`
const AdditionalDetail = Styled.div`
  color: ${colors.dark2};
  margin-top: 2em;
  font-size: 1.2em;
`
const ErrorMessage = Styled.div`
  &&& {
    border: 1px solid ${lighten(0.2, colors.danger)};
    background-color: ${lighten(0.4, colors.danger)};
    color: ${colors.dark3};
    margin-top: 0.33rem;
    border-radius: 3px;
    font-family: monospace !important;
    padding: 0.5rem 0;
  }
`
const ErrorDetail = Styled.div`
  &&& {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    font-family: monospace !important;
  }
`
const ErrorLine = Styled.div`
  font-size: 0.8rem;
`

export default ({ error_msg, error_trace }) => {
  return (
    <RouteContent header="Uh-Oh">
      <div>
        <MessageSpan>An error occurred.</MessageSpan>
        <MessageSpan>
          Try&nbsp;
          <Link onClick={() => (window.location.href = window.location.href)}>
            refreshing
          </Link>
          &nbsp;the page.
        </MessageSpan>
        <MessageSpan>
          If that doesn't fix the issue,&nbsp;
          <Link onClick={() => (window.location = paths.contact)}>
            let us know
          </Link>
          .
        </MessageSpan>
        {!isProduction() && (!!error_msg || !!error_trace) && (
          <AdditionalDetail>
            Additional Detail:
            <ErrorMessage>
              {!!error_msg && <ErrorDetail>{error_msg}</ErrorDetail>}
              {!!error_trace && (
                <ErrorDetail>
                  {error_trace.split(/\n/g).map((line, idx) => (
                    <ErrorLine key={idx}>{line}</ErrorLine>
                  ))}
                </ErrorDetail>
              )}
            </ErrorMessage>
          </AdditionalDetail>
        )}
      </div>
    </RouteContent>
  )
}
