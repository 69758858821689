import React, { Component } from "react"
import { Mutation } from "@apollo/client/react/components"

import Styled from "styled-components"

import { Label } from "semantic-ui-react"

import Icon from "../../Icon"
import Table from "../../Table"
import Popup from "../../Popup"
import Button from "../../Button"

import AreYouSure from "../../Modal/AreYouSure"

import { datetime_format_verbose } from "../../../constants"
import { REPROCESS_REPORT_EXPORT_RUN } from "../../../graphql/mutations"

import { downloadFile } from "../../../helpers/api"

import moment from "moment"

const TableWrapper = Styled.div`
  overflow-x: auto;
  padding-bottom: 0.5rem;
`

const ActionButton = ({ content, ...props }) => (
  <Popup content={content} delay>
    <Button icon compact size="tiny" {...props} />
  </Popup>
)

export default class extends Component {
  state = {
    downloading: false,
    requested_reprocess_run: false
  }

  canReprocessRun = run =>
    (!!run.finished_at || !!run.failed_at) &&
    !!run.arguments &&
    !!run.arguments.start_date &&
    !!run.arguments.end_date

  downloadReport = async run => {
    this.setState({ downloading: run.id })

    try {
      // attempt to download
      await downloadFile(`reports/exports/run/${run.id}`)
    } catch {
      // show error toast
      this.props.toast({
        type: "error",
        message: (
          <>
            <p>Unable to download report for run.</p>
          </>
        )
      })
    }
    this.setState({ downloading: false })
  }

  render() {
    const {
      user,
      toast,
      results,
      onReprocessSubmitted,
      selected_location_id,
      can_manage,
      ...props
    } = this.props

    const { downloading, requested_reprocess_run } = this.state
    return (
      <TableWrapper>
        <Table {...props}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Driver</Table.HeaderCell>
              <Table.HeaderCell>Parameters</Table.HeaderCell>
              <Table.HeaderCell>Finished At</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {results.map(run => (
              <Table.Row key={run.id} negative={!!run.failed_at ? true : false}>
                <Table.Cell>{run.id}</Table.Cell>
                <Table.Cell>{run.reportExport.name}</Table.Cell>
                <Table.Cell>{run.reportExport.driver}</Table.Cell>
                <Table.Cell>
                  {!!run.arguments
                    ? Object.keys(run.arguments).map(key => (
                        <Label key={key} size="tiny">
                          {key}: {run.arguments[key]}
                        </Label>
                      ))
                    : "-"}
                </Table.Cell>
                <Table.Cell>
                  {!!run.finished_at
                    ? moment
                        .utc(run.finished_at)
                        .local()
                        .format(datetime_format_verbose)
                    : "-"}
                </Table.Cell>

                <Table.Cell>
                  {can_manage && (
                    <ActionButton
                      primary
                      content="Reprocess run"
                      circular
                      basic
                      disabled={!this.canReprocessRun(run)}
                      onClick={e => {
                        this.setState({ requested_reprocess_run: run })
                      }}
                    >
                      <Icon name="refresh" />
                    </ActionButton>
                  )}

                  <ActionButton
                    basic
                    circular
                    content="Download Report"
                    disabled={!run.finished_at && !run.failed_at}
                    loading={downloading === run.id}
                    onClick={() => this.downloadReport(run)}
                  >
                    <Icon name="download" />
                  </ActionButton>

                  {!!run.output && !!run.output.error && (
                    <ActionButton
                      circular
                      negative
                      basic
                      content={run.output.error}
                    >
                      <Icon name="exclamation circle" />
                    </ActionButton>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        {!!requested_reprocess_run && (
          <Mutation
            mutation={REPROCESS_REPORT_EXPORT_RUN}
            onCompleted={() => {
              // close this modal
              this.setState({
                requested_reprocess_run: false
              })

              // call handler
              onReprocessSubmitted()

              // show success toast
              toast({
                type: "success",
                message: (
                  <>
                    <p>Export run is being reprocessed.</p>
                    <p>You will see the results in a few seconds.</p>
                  </>
                )
              })
            }}
          >
            {(reprocessReportExportRun, { loading }) => {
              return (
                <AreYouSure
                  header="Are you sure?"
                  submitted={!!loading}
                  body={
                    <>
                      <p>Do you want to reprocess this export run?</p>
                    </>
                  }
                  onConfirm={() => {
                    reprocessReportExportRun({
                      variables: { id: requested_reprocess_run.id }
                    })
                  }}
                  onClose={() =>
                    this.setState({
                      requested_reprocess_run: false
                    })
                  }
                />
              )
            }}
          </Mutation>
        )}
      </TableWrapper>
    )
  }
}
