import { Checkbox as SemanticCheckbox } from "semantic-ui-react"

const Checkbox = ({ onChange = () => {}, ...props }) => (
  <SemanticCheckbox
    {...props}
    onChange={(e, { checked }) => onChange(checked)}
  />
)

export const Radio = props => <Checkbox radio {...props} />

export default Checkbox
