import { useState } from "react"
import { useQuery } from "@apollo/client"

import Search, { ResultItem, DEBOUNCE_MS, PAGE, PER_PAGE } from "."

import { toInt } from "../../helpers/number"
import { EMPLOYEE_SEARCH } from "./../../graphql/queries"

let debounce_ctr = null

export default ({
  active = true,
  no_user = null,
  store_id = null,
  skip_empty = true,
  include_none = true,
  placeholder = "Search Employees",
  ...props
}) => {
  const [{ search, search_request }, setSearch] = useState({
    search: "",
    search_request: null
  })

  const { data, loading } = useQuery(EMPLOYEE_SEARCH, {
    skip: !!skip_empty && search.trim() === "",
    variables: {
      page: PAGE,
      first: PER_PAGE,
      filters: {
        active,
        store_id,
        search,
        include_none,
        no_user: no_user === true ? true : null
      }
    }
  })

  const is_loading = loading || !!search_request
  let results = []
  if (!is_loading) {
    try {
      results = data.employeeSearch.data
        .map(({ id, ...rest }) => ({ id: toInt(id), ...rest }))
        .map(employee => ({
          key: employee.id,
          value: employee.id,
          title: !!employee.name ? employee.name : employee.email,
          employee: { ...employee }
        }))
    } catch (e) {}
  }

  return (
    <Search
      input={{ icon: "search", iconPosition: "left" }}
      placeholder={placeholder}
      loading={is_loading}
      results={results}
      onSearchChange={search => {
        let now = Date.now()
        debounce_ctr = now
        setSearch({ search: "", search_request: search })
        setTimeout(() => {
          if (debounce_ctr === now) {
            debounce_ctr = null
            setSearch({ search, search_request: null })
          }
        }, DEBOUNCE_MS)
      }}
      noResultsMessage={!!is_loading ? "Searching..." : "No results found."}
      resultRenderer={({ title, employee }) => (
        <ResultItem
          title={!!title ? title : <i>No Name</i>}
          subtitle={!store_id ? employee.store.name : null}
          id_field={!!employee ? employee.payroll_id : null}
        />
      )}
      {...props}
    />
  )
}
