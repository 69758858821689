import { createContext } from "react"

const SelectLocation = createContext(undefined)

export const SelectLocationProvider = ({
  changeSelectedLocation,
  ...props
}) => {
  return <SelectLocation.Provider {...props} value={changeSelectedLocation} />
}

export const SelectLocationConsumer = props => (
  <SelectLocation.Consumer {...props} />
)
