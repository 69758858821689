import Styled from "styled-components"

import { colors, style } from "../../constants"

const bg_images = ["lounge-1", "lounge-2", "table-1"]

/*just pick a random one*/
const background_image_path = `images/${
  bg_images[Math.floor(Math.random() * bg_images.length)]
}.jpeg`

export const LoginPageWrapper = Styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
`
export const Background = Styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background-image: url('${background_image_path}');
  background-size: cover;
  background-position: bottom;
  opacity: 0.6;
`
export const Foreground = Styled.div`
  background-color: transparent;
  width: 100%;
  height: 100%;
`
export const FormPanel = Styled.div`
  position: relative;
  height: 100%;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100vw;
  vertical-align: top;
  padding-top: 25vh;
`
export const LoginWrapper = Styled(({ className, ...props }) => (
  <div className={className}>
    <div {...props} />
  </div>
))`
  position: relative;
  & > div {
    border: 3px solid rgba(0, 0, 0, 0.1);
    text-align: left;
    max-width: 400px;
    overflow: hidden;
    margin: 0 auto;
    background-color: ${colors.white};
    border-radius: ${style.border_radius};
    padding: 1rem 1.5rem 0;
  }
`
export const FormHeader = Styled.div`
  padding-bottom: 1rem;
  line-height: 2rem;
`
export const FormWrapper = Styled.div``
export const FormContent = Styled.div`
  border-radius: 3px;
`
export const InputGroup = Styled.div`
  padding-bottom: 0.67rem;
  & > div {
    margin-bottom: 0.67rem;
  }
  & > div:last-child {
    margin-bottom: 0;
  }
  & .ui.label > i.icon {
    margin: 0;
  }
`
export const FormTitle = Styled.div`
  padding: 0.1rem 0 0 0;
  line-height: 3.4rem;
  font-size: 1.6rem;
  font-weight: 300;
  color: ${colors.dark2};
  float: right;
`
export const LinksWrapper = Styled.div`
  padding: 1.5rem 0 1rem;
  text-align: center;
  font-weight: 300;
  & a {
    color: ${colors.dark3} !important;
    &:hover {
      color: ${colors.link} !important;
    }
  }
`
