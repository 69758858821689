import { useState } from "react"
import { Query } from "@apollo/client/react/components"

import { AlertButton } from "../../Shared"
import NoneJobCodeSalesModal from "./SummaryModal"

import {
  useUser,
  useBusinessDates,
  useSelectedLocation
} from "../../../hooks/selectors"
import { NONE_JOB_CODE_SALES } from "../../../graphql/queries"
import { validateRequestTimes, filterAndSortQueryResults } from "./helpers"

const NoneJobCodeSalesAlert = ({ min_time = null, max_time = null }) => {
  const user = useUser()
  const selected_location = useSelectedLocation()
  const { businessStart } = useBusinessDates()

  const [show_modal, showModal] = useState(false)

  const cutoff_time = businessStart()

  let store_ids = [...user.stores.map(({ id }) => id)]
  if (selected_location) {
    store_ids = [selected_location?.id]
  }

  return (
    <Query
      fetchPolicy="network-only"
      query={NONE_JOB_CODE_SALES}
      variables={{ store_ids }}
    >
      {({ data }) => {
        const none_job_code_sales = filterAndSortQueryResults(
          data?.noneJobCodeSales ?? [],
          min_time,
          max_time
        )

        return (
          <>
            {none_job_code_sales?.length > 0 && (
              <AlertButton
                id="none-job-code-sales-notification"
                basic
                primary
                icon="edit"
                count={none_job_code_sales.length}
                label={`Unassigned Sale${
                  none_job_code_sales.length > 1 ? "s" : ""
                }`}
                onClick={() => showModal(true)}
              />
            )}
            {none_job_code_sales?.length > 0 && !!show_modal && (
              <NoneJobCodeSalesModal
                sales={none_job_code_sales}
                onClose={() => showModal(false)}
              />
            )}
          </>
        )
      }}
    </Query>
  )
}

export default NoneJobCodeSalesAlert
