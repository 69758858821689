import moment from "moment"
import Icon from "../../../../../Icon"
import { colors, month_day_format } from "../../../../../../constants"

const ServiceTransferLabel = ({ effective_date, payout_method }) => {
  return (
    <>
      Service Transfer
      <Icon
        style={{ marginLeft: "0.25rem" }}
        name="info circle"
        color={colors.info}
        popup={{
          position: "top right",
          content: `User is transferring their ETA payout method to ${payout_method}.
          Their original eligibility date is ${moment(effective_date).format(
            month_day_format
          )}.`
        }}
      />
    </>
  )
}

export default ServiceTransferLabel
