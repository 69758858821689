import { formatListForSentence } from "./string"
import {
  hasEnterpriseLevelRole,
  hasOrgLevelRole,
  hasStoreLevelRole
} from "./user"

// Intercom Helpers

export const getIntercomAppId = () => process.env.REACT_APP_INTERCOM_APP_ID

export const intercomEnabled = user =>
  !!user && !!user.organization && !user.is_backdoor

export const intercomBootConfig = (user, organization) => ({
  userId: user.id,
  name: user.name,
  email: user.email,
  customLauncherSelector: "intercom-launcher",
  company: {
    companyId: organization.id,
    name: organization.name
  },
  customAttributes: {
    is_employee_user: false,
    is_org_admin: hasOrgLevelRole(user) || hasEnterpriseLevelRole(user),
    is_store_admin: hasStoreLevelRole(user),
    is_enterprise_admin: hasEnterpriseLevelRole(user),
    integration_name: getIntegrationSources(organization.integration_sources),
    org_settings_enabled: getOrgSettings(organization.settings),
    payroll_reference_date: getPayrollReferenceDate(organization),
    upcoming_payroll_date: getUpcomingPayrollDate(organization),
    payroll_interval: getPayrollInterval(organization),
    org_eta_enabled: getOrgEtaStatus(organization),
    org_created_at: getOrgCreationDate(organization)
  }
})

const getOrgSettings = settings => {
  function* values(setting) {
    for (let key of Object.keys(setting))
      if (setting[key] === "1") {
        yield key
      }
  }
  let enabled = Array.from(values(settings))
  return formatListForSentence(enabled)
}

const getIntegrationSources = integrations => {
  return formatListForSentence(integrations)
}

const getPayrollReferenceDate = organization => {
  return organization.payroll_reference_date
}

const getUpcomingPayrollDate = organization => {
  try {
    return organization.current_pay_period.end
  } catch {}
  return null
}

const getPayrollInterval = organization => {
  return organization.payroll_interval
}

const getOrgEtaStatus = organization => {
  return organization.eta_config.enabled
}

const getOrgCreationDate = organization => {
  return organization.created_at
}
