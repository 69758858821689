import Table from "../../../../Table"
import { Modal, IconButton, SubmitButton } from "../../../../Shared"

import SortByFilter from "./SortBy"
import JobCodeFilter from "./JobCode"
import EmployeeFilter from "./Employee"
import LocationFilter from "./Location"
import RevCenterFilter from "./RevenueCenter"
import DistributionsFilter from "./DistributionRule"
import SaleFilter from "./SaleFilter"
import RefundsFilter from "./Refund"

import { toInt } from "../../../../../helpers"
import { useRevenueCentersEnabled } from "../../../../../hooks"

export default ({
  user,
  filters,
  setFilters,
  resetFilters,
  onClose,
  onSubmit
}) => {
  const rev_centers_enabled = useRevenueCentersEnabled()
  return (
    <Modal size="tiny" onClose={onClose} sticky={!!resetFilters}>
      <Modal.Header>Sales Report Filters</Modal.Header>
      <Modal.Content fitted>
        <Table fitted basic="very">
          <Table.Body>
            {user.stores.length > 1 && (
              <LocationFilter
                location={filters.location}
                dependent_filters={{
                  revenue_center: filters.revenue_center,
                  employee: filters.employee,
                  job_code: filters.job_code,
                  distribution_rule: filters.distributions?.id,
                  sale: filters.sale
                }}
                available_locations={[...user.stores]}
                onChange={location =>
                  setFilters({
                    ...filters,
                    location,
                    employee: null,
                    job_code: null
                  })
                }
              />
            )}
            {!!rev_centers_enabled && (
              <RevCenterFilter
                location={filters.location}
                revenue_center={filters.revenue_center}
                onSelect={({ id, name, store }) =>
                  setFilters({
                    ...filters,
                    revenue_center: { id, name },
                    location: { id: toInt(store.id), name: store.name }
                  })
                }
                onRemove={() =>
                  setFilters({
                    ...filters,
                    revenue_center: null
                  })
                }
              />
            )}
            <EmployeeFilter
              location={filters.location}
              employee={filters.employee}
              onSelect={({ id, first_name, last_name, name, store }) =>
                setFilters({
                  ...filters,
                  employee: { id, first_name, last_name, name },
                  location: { id: toInt(store.id), name: store.name }
                })
              }
              onRemove={() =>
                setFilters({
                  ...filters,
                  employee: null
                })
              }
            />
            <JobCodeFilter
              location={filters.location}
              job_code={filters.job_code}
              onSelect={({ id, name, store }) =>
                setFilters({
                  ...filters,
                  job_code: { id, name },
                  location: { id: toInt(store.id), name: store.name }
                })
              }
              onRemove={() =>
                setFilters({
                  ...filters,
                  job_code: null
                })
              }
            />
            <SaleFilter
              location={filters.location}
              sale={filters.sale}
              onSelect={({ id, external_id, employee }) =>
                setFilters({
                  ...filters,
                  sale: { id, external_id },
                  location: {
                    id: toInt(employee.store.id),
                    name: employee.store.name
                  }
                })
              }
              onRemove={() =>
                setFilters({
                  ...filters,
                  sale: null
                })
              }
            />
            <DistributionsFilter
              location={filters.location}
              distributions={filters.distributions}
              onSelectRule={({ id, name, type, store }) => {
                return setFilters({
                  ...filters,
                  distributions: { id, name, type },
                  location: { id: toInt(store.id), name: store.name }
                })
              }}
              onRemoveRule={() =>
                setFilters({
                  ...filters,
                  distributions: true
                })
              }
              onChange={value =>
                setFilters({
                  ...filters,
                  distributions: value
                })
              }
            />
            <RefundsFilter
              refunds={filters.refunds}
              onChange={value => setFilters({ ...filters, refunds: value })}
            />
            <SortByFilter
              field={filters.sort_field}
              direction={filters.sort_direction}
              onChange={(field, direction, is_default = false) => {
                return setFilters({
                  ...filters,
                  sort_field: is_default ? null : field,
                  sort_direction: is_default ? null : direction
                })
              }}
            />
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        {!!resetFilters && (
          <IconButton icon="refresh" basic onClick={resetFilters}>
            Reset Filters
          </IconButton>
        )}
        <SubmitButton onClick={onSubmit} />
      </Modal.Actions>
    </Modal>
  )
}
