import Styled from "styled-components"
import { Checkbox } from "semantic-ui-react"
import { darken } from "polished"

import { colors } from "../../constants"

const Toggle = Styled(Checkbox)`
  /* semantic ui making it difficult to theme their checkbox */
  /* hey semantic, */
  /* thx bb */
  /* <3 */

  &.ui.toggle.checkbox input:checked ~ label:before {
    background: ${darken(0.2, colors.info)} !important;
  }
  &.ui.toggle.checkbox input:focus:checked ~ label:before {
    background: ${darken(0.35, colors.info)} !important;
  }
  &.ui.toggle.checkbox.disabled label {
    cursor: not-allowed !important;
  }
`

export default props => <Toggle name="toggle" toggle {...props} />
