import { useState } from "react"

import Styled from "styled-components"
import { Dropdown as SemanticDropdown } from "semantic-ui-react"

import Label from "../../Label"
import Button from "../../Button"

import { colors } from "../../../../constants"

// Multi Select Dropdown
const MultiSelect = ({
  fluid,
  value = [],
  label = null,
  max_labels = false,
  can_select_all = false,
  placeholder = "Select one or more.",
  ...props
}) => {
  const [open, _openDropdown] = useState(props.defaultOpen ?? false)
  const openDropdown = () => _openDropdown(true)
  const closeDropdown = () => _openDropdown(false)

  const show_select_all =
    can_select_all &&
    (props?.options?.length ?? 0) > 0 &&
    (value?.length ?? 0) < props.options.length

  const show_clear_all =
    can_select_all &&
    (value?.length ?? 0) > 0 &&
    (props?.options?.length ?? 0) > 0 &&
    value.length === props.options.length

  return (
    <>
      {!!label && <Label>{label}</Label>}
      <SelectionGrid fluid={fluid}>
        <StyledMultiSelect
          fluid
          multiple
          selection
          open={open}
          value={value}
          placeholder={placeholder}
          onOpen={() => openDropdown()}
          onClose={() => closeDropdown()}
          renderLabel={(item, idx) => {
            if (!open && max_labels) {
              if (idx === max_labels) {
                return {
                  content: `+${value.length - max_labels} more`,
                  onClick: () => openDropdown(),
                  className: "labels-hidden-indicator",
                  onRemove: undefined
                }
              } else if (idx > max_labels) {
                return false
              }
            }
            return {
              content: item.text
            }
          }}
          {...props}
        />
        {!!show_select_all && (
          <SelectAllBtn
            onClick={e =>
              props.onChange(e, {
                value: props.options.map(({ value }) => value)
              })
            }
          />
        )}
        {!!show_clear_all && (
          <ClearAllBtn
            onClick={e =>
              props.onChange(e, {
                value: []
              })
            }
          />
        )}
      </SelectionGrid>
    </>
  )
}

export default MultiSelect

const SelectionGrid = Styled(({ className, children }) => (
  <div className={className}>
    <div>{children}</div>
  </div>
))`${({ fluid = false }) => `
  display: ${!!fluid ? "block" : "inline-block"};
  & > div {
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-column-gap: 0.33rem;
  }
`}`

const StyledMultiSelect = Styled(({ className, ...props }) => (
  <div className={className}>
    <SemanticDropdown {...props} />
  </div>
))`
  & .labels-hidden-indicator {
    padding-top: 0.38em !important;
    padding-bottom: 0.38em !important;
    background-color: ${colors.white} !important;
    color: ${colors.dark} !important;
  }
  & > .dropdown > div.default.text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

const SelectAllBtn = ({ ...props }) => (
  <ControlManyBtn {...props} label="Select All" />
)

const ClearAllBtn = ({ ...props }) => (
  <ControlManyBtn {...props} label="Clear All" />
)

const ControlManyBtn = Styled(({ className, label, ...props }) => (
  <div className={className}>
    <Button basic fitted {...props}>
      {label}
    </Button>
  </div>
))`
  white-space: nowrap;
`
