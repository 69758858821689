import { memo } from "react"
import Styled from "styled-components"
import { Placeholder } from "semantic-ui-react"

import RouteContent from "../RouteContent"
import { style } from "../../constants"

const randomInt = max => {
  return Math.floor(Math.random() * Math.floor(max))
}

const RoutePlaceholder = Styled(({ className }) => (
  <div className={className}>
    <RouteContent>
      <div className="route-placeholder">
        <br />
        <br />
        <Paragraph />
        <Paragraph />
        <Paragraph />
        <Paragraph />
        <Paragraph />
        <Paragraph />
      </div>
    </RouteContent>
  </div>
))`
  &&& .route-placeholder {
    max-height: calc(100vh - ${style.navbar.height} - ${style.footer.height} - 5rem);
    overflow: hidden;
    & > .ui.placeholder {
      animation: placeholderShimmer 6s linear;
      animation-iteration-count: infinite;
    }
  } 
`

const Paragraph = () => {
  const count = randomInt(4) + 2
  let arr = []
  for (let i = 0; i < count; i++) {
    arr.push(true)
  }
  return (
    <>
      <Placeholder fluid>
        <Placeholder.Paragraph>
          {arr.map((i, idx) => (
            <Placeholder.Line key={idx} />
          ))}
        </Placeholder.Paragraph>
      </Placeholder>
      <br />
      <br />
    </>
  )
}

const PlaceHeader = Styled(props => (
  <Placeholder {...props}>
    <Placeholder.Line />
  </Placeholder>
))`
  min-width: 30rem;
`

export default memo(RoutePlaceholder)
