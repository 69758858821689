import timezones from "./timezones"

export { timezones }

export const weekdays = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday"
]

export const epoch_date = "1970-01-01"
export const epoch = "epoch"

export const hour_noon = 12
export const hour_midnight = 24
export const hours_per_day = 24
export const minutes_per_hour = 60

export const datetime_format = "YYYY-MM-DD HH:mm:ss"
export const date_format = "YYYY-MM-DD"
export const time_format = "h:mm a"
export const datetime_format_verbose = "ddd, MMM Do [at] h:mm a"
export const time_format_verbose = "MMM Do [at] h:mm a"
export const month_day_format = "MMMM Do"
export const month_day_year_format = "MMMM Do, YYYY"
export const weekday_month_day_format = "dddd, MMMM Do"
export const weekday_month_day_year_format = "dddd, MMMM Do, YYYY"
export const month_day_short_format = "M/D"

// used for browser compatiblity when interacting
// with the Date() class
export const compatible_datetime_format = "MM/DD/YYYY HH:mm:ss"

export const min_date_threshold_days = 31

export const date_filters_threshold_months = 1
