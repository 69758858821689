import Styled from "styled-components"
import moment from "moment"
import Icon from "../../../../../Icon"
import { colors, month_day_format } from "../../../../../../constants"

const OptInRequestLabel = Styled(({ className, eta_opted_out_at }) => (
  <div className={className}>
    Opt-In Request
    <Icon
      name="info circle"
      color={colors.info}
      popup={{
        position: "top right",
        content: (
          <>
            User is requesting to opt-in to ETA<>&trade;</>. Their deactivated
            date was on {moment(eta_opted_out_at).format(month_day_format)}
          </>
        )
      }}
    />
  </div>
))`
  margin-bottom: 0.25rem;
  .icon {
    margin-left: 0.25rem;
  }
`

export default OptInRequestLabel
