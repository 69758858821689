import { createContext } from "react"

const SetApplicationFiltersContext = createContext(undefined)

export const SetApplicationFiltersProvider = ({
  setApplicationFilters,
  ...props
}) => {
  return (
    <SetApplicationFiltersContext.Provider
      {...props}
      value={setApplicationFilters}
    />
  )
}

export const SetApplicationFiltersConsumer = props => (
  <SetApplicationFiltersContext.Consumer {...props} />
)
