import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { push } from "connected-react-router"

import { useRoutesVisited } from "../selectors"
import { setRoutesVisited, toast } from "../../actions"
import { updateUserSetting } from "../../helpers"
import { routes_visited_settings_key } from "../../constants"

export const useNavigateTo = () => {
  const dispatch = useDispatch()
  return useCallback(path => dispatch(push(path)), [dispatch])
}

export const useSetRouteVisited = () => {
  const dispatch = useDispatch()
  let routes_visited = useRoutesVisited()
  return useCallback(
    (route_name, value = 1) => {
      if (
        routes_visited.client[route_name] ||
        routes_visited.client[route_name] != value
      ) {
        routes_visited.client[route_name] = value
        updateUserSetting(
          routes_visited_settings_key,
          JSON.stringify(routes_visited)
        )
      }
      dispatch(setRoutesVisited(JSON.stringify(routes_visited)))
    },
    [dispatch, JSON.stringify(routes_visited)]
  )
}

export const useToast = () => {
  const dispatch = useDispatch()
  return useCallback(options => dispatch(toast(options)), [dispatch])
}
