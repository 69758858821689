import Styled from "styled-components"
import { Modal, Button } from "../../../../../Shared"

const IntroModal = Styled(
  ({ className, total_pending, onConfirm, onClose }) => (
    <Modal className={className} size="mini" onClose={onClose}>
      <Modal.Header>New Employees Signed Up</Modal.Header>
      <Modal.Content>
        <p>
          You have {total_pending} employee{total_pending > 1 && "s"} eligible
          to participate in ETA, pending account verification.
        </p>
        <p>
          Please take a moment to review the new linked account
          {total_pending > 1 && "s"}.
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>I'll do it later.</Button>
        <Button primary onClick={onConfirm}>
          Let's do it!
        </Button>
      </Modal.Actions>
    </Modal>
  )
)`
  &.modal > .content {
    font-size: 1em;
  }
`

export default IntroModal
