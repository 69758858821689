import { useState } from "react"
import Styled from "styled-components"

import { Button } from "../../../../../Shared"

import { useIsCustomRuleSchedule } from "../../../../helpers"
import ScheduleTable, { ScheduleTableModal } from "../../../../ScheduleTable"

export default ({ schedules, onUpdate }) => {
  const [edit, setEdit] = useState(false)

  const has_custom_schedule = useIsCustomRuleSchedule(schedules)

  return (
    <>
      <div>
        {!!has_custom_schedule && (
          <>
            <ScheduleTable
              basic
              compact
              fitted={false}
              include_header={false}
              schedules={schedules}
            />
          </>
        )}
        {!has_custom_schedule && (
          <Button compact onClick={() => setEdit(true)}>
            Always Active
          </Button>
        )}
        {!!has_custom_schedule && (
          <CustomizeButton onClick={() => setEdit(true)} />
        )}
      </div>
      {!!edit && (
        <ScheduleTableModal
          schedules={schedules}
          onClose={() => setEdit(false)}
          onSubmit={submitted => {
            setEdit(false)
            onUpdate({
              schedules: [...submitted]
            })
          }}
        />
      )}
    </>
  )
}

const CustomizeButton = Styled(props => (
  <Button compact {...props}>
    Customize
  </Button>
))`
  margin-top: 0.33rem !important;
`
