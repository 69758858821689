import Styled from "styled-components"
import { Loader as SemanticLoader } from "semantic-ui-react"

const Loader = ({ inline = true, active = true, ...props }) => (
  <SemanticLoader inline={inline} active={active} {...props} />
)

export const LoadingMessage = Styled(
  ({
    message,
    children,
    margin = true,
    show_loader = true,
    loader_size = "large",
    ...props
  }) => (
    <div {...props}>
      {!!show_loader && (
        <>
          <SemanticLoader inline active size={loader_size} />
          &nbsp;&nbsp;
        </>
      )}
      <div>{message ?? children}</div>
    </div>
  )
)`
  margin: ${({ margin }) => (!!margin ? `2rem 0` : `0`)};
  font-size: 1.2rem;
  font-weight: 300;
  & > div {
    & > div {
      display: inline-block;
    }
    display: inline-block;
    &::after {
      font-size: 1.15em;
      margin-left: 0.15rem;
      overflow: hidden;
      display: inline-block;
      vertical-align: bottom;
      animation: ellipsis steps(4,end) 1.5s infinite;
      letter-spacing: 0.125em;
      content: "...";
      width: 0px;
    }
  }

  @keyframes ellipsis {
    to {
      width: 1.375em;
    }
  }

  @-webkit-keyframes ellipsis {
    to {
      width: 1.375em;
    }
  }
`

export default Loader
