import { useState } from "react"

import Styled from "styled-components"

import Button from "../../../../Button"
import DeleteCustomShiftModal from "../../Modal/CustomShifts/Delete"

export default ({
  worked_shift,
  label = undefined,
  locked,
  onShiftDeleted,
  onClose
}) => {
  const [show_modal, setShowModal] = useState(false)
  return (
    <>
      <DeleteCustomShiftButton
        locked={locked}
        onClick={() => setShowModal(true)}
      >
        {!!label && label}
      </DeleteCustomShiftButton>
      {show_modal && (
        <DeleteCustomShiftModal
          worked_shift={worked_shift}
          onClose={() => setShowModal(false)}
          onSuccess={payload => {
            onShiftDeleted(payload)
            setShowModal(false)
            onClose()
          }}
        />
      )}
    </>
  )
}

const DeleteCustomShiftButton = Styled(
  ({ className, locked = false, ...props }) => (
    <Button {...props} negative disabled={!!locked} />
  )
)`line-height: 2rem;`
