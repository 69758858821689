import { FilterRow, FilterValue } from "../Styled"
import EmployeeSearch from "../../../../Search/Employee"

export default ({ employee, location, onSelect, onRemove }) => (
  <FilterRow name="Employee">
    {!!employee && (
      <FilterValue
        value={
          !!employee.name
            ? employee.name
            : `${employee.first_name} ${employee.last_name}`
        }
        onRemove={onRemove}
      />
    )}
    {!employee && (
      <EmployeeSearch
        aligned="right"
        store_id={location?.id}
        onChange={({ employee }) => onSelect({ ...employee })}
      />
    )}
  </FilterRow>
)
