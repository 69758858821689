import Icon from "../Icon"
import { colors, project_info } from "../../constants"
import { rules_manage } from "../../constants/permissions"

export default {
  steps: [
    {
      target: "body",
      title: `${project_info.name} Tip Distribution`,
      content: ({ hasPermission }) => {
        const can_edit = hasPermission(rules_manage)
        return (
          <>
            {can_edit && <p>This is where the magic happens.</p>}
            <p>
              Here, you can
              {can_edit && " customize "}
              {!can_edit && " review "}
              your organization's tip sharing logic.
            </p>
          </>
        )
      },
      placement: "center",
      onMount: () => {
        try {
          document.getElementById("tab-navigation-tip-sharing").click()
        } catch (e) {}
      }
    },
    {
      target: "body",
      title: "Let's dive in.",
      content: () => (
        <>
          <p>
            {project_info.name} provides two primary tools for configuring tip
            distribution:
          </p>
          <p>
            <strong>Tip Sharing Rules</strong> and <strong>Tip Pools</strong>.
          </p>
        </>
      ),
      placement: "center"
    },
    {
      target: "#tab-navigation-tip-sharing",
      title: `Tip Sharing`,
      content: () => (
        <>
          <p>
            Tip sharing rules allow you to distribute a percentage of tips from
            one job code to another.
          </p>
          <p>
            For example, a rule might distribute 10% of tips from servers to
            cooks.
          </p>
        </>
      )
    },
    {
      target: "#tab-navigation-tip-pools",
      title: `Tip Pools`,
      content: () => (
        <>
          <p>Tip pools allow you to split tips within a group of job codes.</p>
          <p>For example, a team of bartenders might pool their tips.</p>
          <p>
            More in this later. For now, let's take a closer look at tip sharing
            rules.
          </p>
        </>
      ),
      actions: [
        {
          title: "Skip to Pools",
          onClick: ({ goToStep }) => {
            goToStep("start-tip-pools")
          }
        }
      ]
    },
    {
      target: ".tip-distribution-rules-table-row",
      name: "closer-look-at-rules",
      title: `A closer look at rules:`,
      content: () => (
        <>
          <p>
            Here you see a sample rule. Once a rule is created, it'll appear in
            this table.
          </p>
          <p>You can click on the row to see more detail.</p>
        </>
      ),
      next: {
        onClick: (step, onNext) => {
          try {
            document
              .getElementsByClassName("tip-distribution-rules-table-row")[0]
              .click()
          } catch (e) {}
          // allow time for modal to render
          requestAnimationFrame(() => {
            try {
              onNext()
            } catch {}
          })
        }
      }
    },
    {
      target: "#rule-summary-header",
      title: "Review in Detail",
      placement: "right",
      content: (
        <>
          <p>Here's the detailed view of our sample rule.</p>
        </>
      ),
      back: {
        onClick: (step, onBack) => {
          // close the rule summary modal
          requestAnimationFrame(() => {
            try {
              document.getElementsByClassName("dimmer")[0].click()
            } catch {}
          })
          onBack()
        }
      }
    },
    {
      target: "#rule-summary-percent-from-to",
      title: "Which tips are shared?",
      content: (
        <>
          <p>
            This example distributes 10% of credit card tips from servers to
            cooks and dishwashers.
          </p>
        </>
      )
    },
    {
      target: "#rule-summary-distro-frequency",
      title: "Distribution Frequency",
      content: () => (
        <>
          <p>
            The distribution frequency specifies how often distribution will
            occur.
          </p>
          <p>
            For example, "Daily" distributes tips based on total minutes worked
            throughout the day.
          </p>
          <p>
            "Time of Sale" distributes tips from each sale based on minutes
            worked between sale open and close.
          </p>
        </>
      ),
      next: {
        onClick: ({ goToStep }, nextStep) => {
          // close the rule summary modal
          requestAnimationFrame(() =>
            document.getElementsByClassName("dimmer")[0].click()
          )
          requestAnimationFrame(() => {
            try {
              if (
                document.getElementsByClassName(
                  "distribution-rules-table-action-cell"
                )?.length
              ) {
                nextStep()
              } else {
                goToStep("start-tip-pools")
              }
            } catch {}
          })
        }
      }
    },
    {
      target: ".distribution-rules-table-action-cell",
      title: () => "Copy, Edit, & Delete",
      content: (
        <>
          <p>You have the power to make changes on your own schedule.</p>
          <p>Open this menu to copy, edit, or delete.</p>
        </>
      ),
      back: {
        onClick: (step, onBack) => {
          try {
            document
              .getElementsByClassName("tip-distribution-rules-table-row")[0]
              .click()
          } catch (e) {}
          // allow time for modal to render
          setTimeout(() => onBack(), 10)
        }
      }
    },
    {
      target: "#create-rule-btn",
      title: "Tip sharing starts here.",
      content: () => (
        <>
          <p>
            When the time is right, click the{" "}
            <Icon name="plus circle" fitted color={colors.success} /> button to
            create a new rule.
          </p>
        </>
      )
    },
    {
      target: "#tab-navigation-tip-pools",
      name: "start-tip-pools",
      title: "Next up, tip pools.",
      content: (
        <>
          <p>Tip pools allow you to share tips within a group of job codes.</p>
          <p>
            When done right, pools can be an effective tool for building
            cohesion within your team.
          </p>
        </>
      ),
      back: {
        onClick: ({ goToStep }, onBack) => {
          try {
            document.getElementById("tab-navigation-tip-sharing").click()
          } catch (e) {}
          setTimeout(goToStep("closer-look-at-rules"), 100)
        }
      },
      onMount: () => {
        try {
          document.getElementById("tab-navigation-tip-pools").click()
        } catch (e) {}
      }
    },
    {
      target: ".tip-pools-table-row",
      title: "Simple yet powerful.",
      content: (
        <>
          <p>
            Here's a sample pool that splits tips between bartenders based on
            minutes worked throughout the day.
          </p>
        </>
      ),
      next: {
        onClick: (step, onNext) => {
          try {
            document.getElementsByClassName("tip-pools-table-row")[0].click()
          } catch (e) {}
          // allow time for modal to render
          requestAnimationFrame(() => {
            try {
              onNext()
            } catch {}
          })
        }
      }
    },
    {
      target: "#pool-summary-header",
      title: "Review in Detail",
      placement: "right",
      content: (
        <>
          <p>Like before, clicking the row displays a detailed summary.</p>
        </>
      ),
      back: {
        onClick: (step, onBack) => {
          // close the rule summary modal
          requestAnimationFrame(() => {
            try {
              document.getElementsByClassName("dimmer")[0].click()
            } catch {}
          })
          onBack()
        }
      }
    },
    {
      target: "#pool-summary-distro-sources",
      title: "Distribution Sources",
      content: (
        <>
          <p>
            Pools can apply to a combination of credit card tips, gift card
            tips, and gratuity service charges.
          </p>
        </>
      )
    },
    {
      target: "#pool-summary-distro-frequency",
      title: "Distribution Frequency",
      content: (
        <>
          <p>Pools use distribution frequency just like rules.</p>
          <p>Tips are split based on total minutes worked.</p>
        </>
      )
    },
    {
      target: "#pool-summary-job-codes",
      title: "Who's included?",
      name: "pool-summary-job-codes",
      content: (
        <>
          <p>Our sample pool applies to bartenders.</p>
          <p>
            You can pool multiple job codes too, such as bartenders and servers.
          </p>
        </>
      ),
      next: {
        onClick: (step, nextStep) => {
          // close the rule summary modal
          requestAnimationFrame(() =>
            document.getElementsByClassName("dimmer")[0].click()
          )
          requestAnimationFrame(() => nextStep())
        }
      }
    },
    {
      target: ".pools-table-action-cell",
      title: () => "Copy, Edit, & Delete",
      content: (
        <>
          <p>You have the power to make changes on your own schedule.</p>
          <p>Open this menu to copy, edit, or delete.</p>
        </>
      ),
      back: {
        onClick: (step, onBack) => {
          requestAnimationFrame(() =>
            document.getElementsByClassName("tip-pools-table-row")[0].click()
          )
          // allow time for modal to render
          setTimeout(() => onBack(), 10)
        }
      }
    },
    {
      target: "#create-rule-btn",
      title: "Try it out.",
      content: () => (
        <>
          <p>
            Click the <Icon name="plus circle" fitted color={colors.success} />{" "}
            button to get started!
          </p>
        </>
      ),
      next: {
        title: "Got it!"
      }
    },
    {
      target: "#help-button",
      title: "That's All Folks!",
      content: (
        <>
          <p>Thanks for staying with me.</p>
          <p>If you ever need a refresh, you know where to find me.</p>
        </>
      ),
      disable_next: true,
      back: {
        onClick: ({ goToStep }, onBack) => {
          const can_edit = !!document.getElementById("create-rule-btn")
          if (can_edit) {
            return onBack()
          } else {
            requestAnimationFrame(() =>
              document.getElementsByClassName("tip-pools-table-row")[0].click()
            )
            // allow time for modal to render
            setTimeout(() => goToStep("pool-summary-job-codes"), 10)
          }
        }
      }
    }
  ]
}

export const mockRule = biz_start => ({
  id: "1",
  name: "Sample: Server to Back-of-House",
  store_name: "TipHaus Demo",
  store_id: "1",
  effective_date: biz_start,
  deactivated_date: null,
  distributionMethod: {
    id: 4,
    name: "Time of Sale"
  },
  distributionSources: [{ id: 1, name: "Credit Card Tip" }],
  active: true,
  percent_in: 10,
  created_at: biz_start,
  schedules: [
    {
      hour_end: 4,
      hour_start: 4,
      day_end: "tuesday",
      day_start: "monday"
    },
    {
      hour_end: 4,
      hour_start: 4,
      day_end: "wednesday",
      day_start: "tuesday"
    },
    {
      hour_end: 4,
      hour_start: 4,
      day_end: "thursday",
      day_start: "wednesday"
    },
    {
      hour_end: 4,
      hour_start: 4,
      day_end: "friday",
      day_start: "thursday"
    },
    {
      hour_end: 4,
      hour_start: 4,
      day_end: "saturday",
      day_start: "friday"
    },
    {
      hour_end: 4,
      hour_start: 4,
      day_end: "sunday",
      day_start: "saturday"
    },
    {
      hour_end: 4,
      hour_start: 4,
      day_end: "monday",
      day_start: "sunday"
    }
  ],
  output_job_code_names: ["Cook", "Dishwasher"],
  input_job_code_names: ["Server"],
  sales_category_names: [],
  inputJobCodes: [
    {
      id: "1",
      jobCode: { id: 1, name: "Server" }
    }
  ],
  outputJobCodes: [
    {
      id: "2",
      distribution_weight: 1,
      name: "Cook",
      priority: 1
    },
    {
      id: "3",
      distribution_weight: 1,
      name: "Dishwasher",
      priority: 1
    }
  ],
  salesCategories: []
})

export const mockPool = biz_start => ({
  id: "1",
  name: "Sample: Bartender Pool",
  store_name: "TipHaus Demo",
  store_id: "1",
  active: true,
  effective_date: biz_start,
  deactivated_date: null,
  distributionSources: [
    { id: 1, source: "sale_tip", name: "Credit Card Tip" },
    {
      id: 2,
      source: "sale_tip",
      name: "Gratuity Service Charge"
    }
  ],
  distributionMethod: {
    id: 1,
    name: "Daily"
  },
  jobCodes: [
    {
      id: 1,
      name: "Bartender",
      distribution_weight: 1
    }
  ],
  schedules: [
    {
      hour_end: 4,
      hour_start: 4,
      day_end: "tuesday",
      day_start: "monday"
    },
    {
      hour_end: 4,
      hour_start: 4,
      day_end: "wednesday",
      day_start: "tuesday"
    },
    {
      hour_end: 4,
      hour_start: 4,
      day_end: "thursday",
      day_start: "wednesday"
    },
    {
      hour_end: 4,
      hour_start: 4,
      day_end: "friday",
      day_start: "thursday"
    },
    {
      hour_end: 4,
      hour_start: 4,
      day_end: "saturday",
      day_start: "friday"
    },
    {
      hour_end: 4,
      hour_start: 4,
      day_end: "sunday",
      day_start: "saturday"
    },
    {
      hour_end: 4,
      hour_start: 4,
      day_end: "monday",
      day_start: "sunday"
    }
  ],
  store: {
    id: "1",
    name: "TipHaus Demo",
    active: true
  }
})
