import { Component, useState, useEffect } from "react"
import Styled from "styled-components"
import { Menu } from "semantic-ui-react"
import { useQuery } from "@apollo/client"

import GeneralSettings from "./General"
import JobCodesManager from "./JobCodes"
import EmployeesManager from "./Employees"
import CategoriesManager from "./SaleCategories"
import RevCentersManager from "./RevenueCenters"

import Icon from "../../Icon"
import {
  Input,
  Table,
  Loader,
  ActiveLabel,
  InactiveLabel,
  ErrorMessage
} from "../../Shared"

import { toInt, parseSearchQuery } from "../../../helpers"
import { colors } from "../../../constants"
import { ORGANIZATION_STORES } from "../../../graphql/queries"
import { useLocations, useRevenueCentersEnabled } from "../../../hooks"
import useAuthorization from "../../../hooks/authorization"
import {
  settings_manage_store,
  settings_manage_store_cc_tip_fee
} from "../../../constants/permissions"

export default props => {
  const locations = useLocations()
  const rev_centers_enabled = useRevenueCentersEnabled()

  const { data, loading, refetch } = useQuery(ORGANIZATION_STORES, {
    variables: { store_ids: locations.map(({ id }) => id) }
  })

  let stores = []

  if (loading || !data) {
    return <Loader />
  }

  try {
    stores = data.organizationStores.map(
      ({ id, name, timezone, active, settings }) => ({
        id: toInt(id),
        name,
        timezone,
        active,
        settings: settings.map(({ key, value }) => ({ key, value }))
      })
    )
  } catch {
    return <ErrorMessage />
  }

  return (
    <MultiStoreManager
      stores={stores}
      rev_centers_enabled={rev_centers_enabled}
      refetchStores={refetch}
      {...props}
    />
  )
}

// min number of stores to include pagination and search
const STORE_SEARCH_THRESHOLD = 5
const FILTER_STATUS_SELECTED = "Selected"

class MultiStoreManager extends Component {
  state = {
    page: 1,
    per_page: 10,
    search: "",
    status_filter: null,
    selected_store: null
  }

  componentDidMount() {
    const { location_id = null } = parseSearchQuery(window.location.search)
    const active_stores = this.activeStores()
    if (active_stores.length === 1) {
      this.setState({ selected_store: active_stores[0].id })
    } else if (this.props.selected_location_id) {
      this.setState({
        status_filter: FILTER_STATUS_SELECTED
      })
    } else if (!!location_id) {
      // location_id get param is provided
      this.setState({ selected_store: toInt(location_id) })
    }
  }

  activeStores = () => this.props.stores.filter(({ active }) => !!active)

  filteredStores = () => {
    const { stores } = this.props
    const { search } = this.state
    return [...stores]
      .filter(({ name }) => {
        if (search.trim().length === 0) {
          return true
        }
        return name.toLowerCase().includes(search.trim().toLowerCase())
      })
      .sort((a, b) => a.name.localeCompare(b.name))
  }

  render() {
    const { selected_location_id, rev_centers_enabled, ...props } = this.props
    const { page, per_page, search, selected_store } = this.state
    const filtered_stores = this.filteredStores()

    let { stores } = props

    let controls = {
      custom: []
    }

    if (
      filtered_stores.length >= STORE_SEARCH_THRESHOLD ||
      search.trim().length > 0
    ) {
      controls.custom.push(
        <Input
          icon="search"
          iconPosition="left"
          placeholder="Search..."
          value={search}
          onChange={(e, { value }) => this.setState({ search: value, page: 1 })}
          action={
            search.length > 0
              ? {
                  icon: <Icon name="remove" />,
                  onClick: () => this.setState({ search: "", page: 1 })
                }
              : undefined
          }
        />
      )
    }

    if (stores.length > 1) {
      if (filtered_stores.length > 1) {
        controls.position = filtered_stores.length > per_page ? "both" : "top"
        controls.custom_controls_position = "top"
        controls.pagination = {
          current_page: page,
          per_page: per_page,
          total: filtered_stores.length,
          include_total: true,
          changePage: page => this.setState({ page }),
          changePerPage: per_page => this.setState({ per_page }),
          options: {
            per_page: [10, 25, 50, 100]
          }
        }
      }
    }

    return (
      <Table
        unstackable
        overflow="auto"
        controls={controls}
        no_results={filtered_stores.length === 0}
      >
        <Table.Body>
          {filtered_stores
            .slice((page - 1) * per_page, page * per_page)
            .map(store => (
              <StoreRow
                className="location-row"
                key={store.id}
                store={store}
                selected={
                  selected_store === store.id || filtered_stores.length === 1
                }
                onToggle={
                  filtered_stores.length === 1
                    ? false
                    : () => {
                        // clear out get params when changing locations
                        this.props.history.replace({
                          search: ""
                        })
                        this.setState({
                          selected_store:
                            selected_store !== store.id ? store.id : null
                        })
                      }
                }
              >
                <StoreManager
                  store={store}
                  rev_centers_enabled={rev_centers_enabled}
                  {...props}
                />
              </StoreRow>
            ))}
        </Table.Body>
      </Table>
    )
  }
}

const StoreRow = Styled(({ store, selected, onToggle, children, ...props }) => (
  <Table.Row hover={false} {...props}>
    <Table.Cell>
      <StoreRowHeader
        onToggle={onToggle}
        store={store}
        selected={selected}
        className="location-row-header"
      />
      {!!selected && <StoreRowContent>{children}</StoreRowContent>}
    </Table.Cell>
  </Table.Row>
))`
  & > td {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    & > div {
      /*padding-left: 1rem;
      padding-right: 1rem;*/
    }

    & .ui.table tr td {
      border-top: 1px solid ${colors.table_border};
    }
    & .ui.table tr:first-child td {
      border-top: none;
    }
  }
  &:first-child > td > div:first-child {
    border-radius: .28571429rem .28571429rem 0 0;
  }
  &:last-child > td > div:last-child {
    border-radius: 0 0 .28571429rem .28571429rem;
  }
`

const StoreRowHeader = Styled(({ store, selected, onToggle, ...props }) => (
  <div {...props} onClick={() => !!onToggle && onToggle()}>
    <StoreName name={store.name} selected={selected} />
    <StoreControls>
      {!store.active && <InactiveLabel />}
      {!!store.active && <ActiveLabel />}
      {!!onToggle && (
        <Icon name={`chevron ${!selected ? "right" : "down"}`} fitted />
      )}
    </StoreControls>
  </div>
))`
  ${({ onToggle }) => (!onToggle ? "" : "cursor: pointer;")}
  padding: 0.8rem 1rem;
  font-size: 1.1rem;
  ${({ selected }) =>
    !selected
      ? ``
      : `
    background-color: ${colors.light2} !important;
  `};
  &:hover {
    background-color: ${colors.light};
  }
`

const StoreName = Styled(({ name, ...props }) => (
  <span {...props}>{name}</span>
))`
  ${({ selected }) => (!selected ? `` : `font-weight: 600;`)}
`

const StoreRowContent = Styled.div`
  background-color: ${colors.light2};
  & > div {
    margin-bottom: 0.33rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`
const StoreControls = Styled.div`
  float: right;
  & > .icon.chevron {
    width: 1rem !important;
    float: right;
    font-size: 1.1rem;
  }
`

const TAB_GENERAL = "General"
const TAB_EMPLOYEES = "Employees"
const TAB_JOB_CODES = "Job Codes"
const TAB_CATEGORIES = "Sale Item Categories"
const TAB_REVENUE_CENTERS = "Revenue Centers"

const StoreManager = props => {
  const [state, setState] = useState({ active_tab: TAB_GENERAL })
  const { hasPermission } = useAuthorization()

  useEffect(() => {
    const { view = null } = parseSearchQuery(window.location.search)
    // set tab to employees if view is passed as a get param
    if (view === TAB_EMPLOYEES.toLowerCase()) {
      setState({ active_tab: TAB_EMPLOYEES })
    }
  }, [])

  const handleClick = active_tab => {
    setState({ active_tab })
    // clear out get params when changing tabs
    props.history.replace()
  }

  const { store, refetchStores, rev_centers_enabled, ...rest } = props
  const { active_tab } = state

  return (
    <StoreManagerWrapper>
      <StoreSettingsTabs vertical tabular>
        <Menu.Item
          id="location-menu-general"
          name={TAB_GENERAL}
          active={active_tab === TAB_GENERAL}
          onClick={() => handleClick(TAB_GENERAL)}
        />
        <Menu.Item
          id="location-menu-employees"
          name={TAB_EMPLOYEES}
          active={active_tab === TAB_EMPLOYEES}
          disabled={!store.active}
          onClick={() => handleClick(TAB_EMPLOYEES)}
        />
        <Menu.Item
          id="location-menu-job-codes"
          name={TAB_JOB_CODES}
          active={active_tab === TAB_JOB_CODES}
          disabled={!store.active}
          onClick={() => handleClick(TAB_JOB_CODES)}
        />
        <Menu.Item
          id="location-menu-sale-categories"
          name={TAB_CATEGORIES}
          active={active_tab === TAB_CATEGORIES}
          disabled={!store.active}
          onClick={() => handleClick(TAB_CATEGORIES)}
        />
        {!!rev_centers_enabled && (
          <Menu.Item
            id="location-menu-revenue-centers"
            name={TAB_REVENUE_CENTERS}
            active={active_tab === TAB_REVENUE_CENTERS}
            disabled={!store.active}
            onClick={() => handleClick(TAB_REVENUE_CENTERS)}
          />
        )}
      </StoreSettingsTabs>
      <StoreSettingsContent>
        {active_tab === TAB_GENERAL && (
          <GeneralSettings
            {...props}
            can_edit_store={hasPermission(settings_manage_store)}
            can_edit_cc_tip_fee={hasPermission(
              settings_manage_store_cc_tip_fee
            )}
          />
        )}
        {active_tab === TAB_EMPLOYEES && (
          <ContentLift>
            <EmployeesManager {...rest} store={store} />
          </ContentLift>
        )}
        {active_tab === TAB_JOB_CODES && (
          <ContentLift>
            <JobCodesManager {...rest} store={store} />
          </ContentLift>
        )}
        {active_tab === TAB_CATEGORIES && (
          <ContentLift>
            <CategoriesManager {...rest} store={store} />
          </ContentLift>
        )}
        {active_tab === TAB_REVENUE_CENTERS && (
          <ContentLift>
            <RevCentersManager {...rest} store={store} />
          </ContentLift>
        )}
      </StoreSettingsContent>
    </StoreManagerWrapper>
  )
}

const StoreManagerWrapper = Styled.div`
  cursor: default;
  position: relative;
`
const StoreSettingsTabs = Styled(Menu)`
  background-color: ${colors.light2};
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  display: inline-block !important;
  width: 10rem !important;
  padding-left: 0.67rem;
  &&& .item {
    line-height: 1.4rem;
    padding: .93rem 1.33rem !important;
    &.disabled {
      cursor: not-allowed !important;
    }
    &.active {
      background-color: ${colors.white} !important;
    }
  }
`
const StoreSettingsContent = Styled.div`
  background-color: ${colors.white};
  display: inline-block;
  width: calc(100% - 10rem + 1px);
  vertical-align: top;
  padding: 0.93rem 1.33rem !important;
  border-left: 0.5px solid #d4d4d5;
  border-top: 0.5px solid #d4d4d5;
  margin-left: -1px;
  min-height: 22rem;
`
const ContentLift = Styled.div`
  margin-top: -0.33rem;
`
