import React, { useState } from "react"

import Styled from "styled-components"
import { useMutation } from "@apollo/client"

import Icon from "../../Icon"
import Modal from "../../Modal"
import Toggle from "../../Toggle"
import Button from "../../Button"
import Message from "../../Message"
import Label from "../../Shared/Label"
import {
  SelectStores,
  StoresFinalizingIndicator,
  PayPeriodDetail
} from "./Shared"

import { FINALIZE_PAY_PERIOD } from "../../../graphql/mutations"
import { useBusinessDates, useProcessingInProgress } from "../../../hooks"
import { Loader } from "../../Shared"

export default Styled(
  ({
    user,
    onClose,
    className,
    onSuccess,
    pay_period,
    currently_finalizing,
    available_to_finalize,
    show_finalize_disclaimer = false
  }) => {
    const [selected_stores, setSelectStores] = useState(
      user.stores.length === 1 ? [...user.stores.map(({ id }) => id)] : []
    )
    const { businessStartFromDate } = useBusinessDates()
    const processing_in_progress = useProcessingInProgress({
      end_time: businessStartFromDate(pay_period.end)
    })
    const [should_resync, setShouldResync] = useState(false)

    const [finalizePayPeriod, { loading }] = useMutation(FINALIZE_PAY_PERIOD, {
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        onSuccess()
      }
    })

    return (
      <Modal
        size="tiny"
        onClose={onClose}
        className={className}
        closeOnDimmerClick={false}
      >
        <Modal.Header onClose={onClose}>Finalize Pay Period</Modal.Header>
        <ModalContent>
          <PayPeriodDetail pay_period={pay_period} fitted />
          {processing_in_progress && (
            <Message type="error">
              <Message.Header>
                <Loader active size="mini" /> Active Sync in Progress
              </Message.Header>
              <Message.Content>
                We advise waiting until the current sync is finished to finalize
                a pay period.
              </Message.Content>
            </Message>
          )}
          <Message
            // type="info"
            message={
              <>
                <p>
                  {/* We'll resync data from your point of sale, recalculate tip
                  distribution, and generate a new breakdown snapshot. */}
                  We'll lock this pay period{" "}
                  {show_finalize_disclaimer && "and the previous pay period,"}{" "}
                  and generate a snapshot of your breakdown.
                </p>
                {/* <p>
                  It may take up to an hour to before reprocessing is complete.
                  You can monitor progress from the settings page.
                </p> */}
              </>
            }
          />
          {currently_finalizing.length > 0 && (
            <StoresFinalizingIndicator total={currently_finalizing.length} />
          )}
          {available_to_finalize.length > 0 && user.stores.length > 1 && (
            <SelectStores
              label="Select Locations to Finalize"
              options={available_to_finalize.map(store => ({
                value: store.id,
                text: store.name
              }))}
              onChange={(e, { value }) => setSelectStores([...value])}
              value={selected_stores}
            />
          )}
          <ResyncOnFinalizeToggle
            active={should_resync}
            onChange={value => setShouldResync(value)}
          />
        </ModalContent>
        <Modal.Actions>
          <Button onClick={onClose} disabled={!!loading}>
            Close
          </Button>
          {available_to_finalize.length > 0 && (
            <Button
              primary
              icon
              loading={!!loading}
              labelPosition="right"
              disabled={selected_stores.length === 0 || !!loading}
              onClick={() =>
                finalizePayPeriod({
                  variables: {
                    store_ids: [...selected_stores],
                    pay_period_id: pay_period.id,
                    resync: should_resync
                  }
                })
              }
            >
              Finalize <Icon name="check" />
            </Button>
          )}
        </Modal.Actions>
      </Modal>
    )
  }
)`
  & .content > div {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const ModalContent = Styled(Modal.Content)`font-weight: 300;`

const ResyncOnFinalizeToggle = Styled(({ active, onChange, className }) => (
  <div className={className}>
    <Label>Resync with Point of Sale</Label>
    <Toggle
      checked={active}
      label={
        !!active
          ? "Enabled - We'll resync the entire pay period and reprocess tip distribution before finalizing.  This may take up to an hour to complete."
          : "Disabled - We'll finalize the pay period as is.  This will take about a minute to complete."
      }
      onChange={() => onChange(!active)}
    />
  </div>
))`
  line-height: 1.67rem;
  margin-top: 1.5rem;
`
