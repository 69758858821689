import Styled from "styled-components"

import { ExclamationIcon, Toggle } from "../../../../../Shared"
import WeightedDistribution from "../../../../WeightedDistribution"

export default ({
  weight_by_job_code,
  apply_job_code_weights,
  onToggleWeights,
  onUpdate
}) => {
  const total_weight = Object.values(weight_by_job_code).reduce(
    (acc, weight) => acc + weight,
    0
  )
  return (
    <>
      <WeightedDistrosToggle
        checked={apply_job_code_weights}
        onChange={() => onToggleWeights()}
      />
      {!!apply_job_code_weights && (
        <>
          {total_weight === 0 && <ZeroWeightError />}
          <WeightedDistributionTable
            weight_by_job_code={weight_by_job_code}
            onUpdate={onUpdate}
          />
        </>
      )}
    </>
  )
}

const WeightedDistrosToggle = ({ checked, onChange }) => (
  <div>
    <Toggle
      style={{ verticalAlign: "top" }}
      checked={checked}
      onChange={(e, { checked }) => onChange(checked)}
      fitted={false}
    />
  </div>
)

const WeightedDistributionTable = Styled(
  ({ className, weight_by_job_code, onUpdate }) => (
    <div className={className}>
      <WeightedDistribution
        selected_job_codes={Object.keys(weight_by_job_code).map(
          job_code_name => ({
            id: job_code_name,
            name: job_code_name,
            weight: weight_by_job_code[job_code_name]
          })
        )}
        incrementDistributionWeight={name =>
          onUpdate({
            ...weight_by_job_code,
            [name]: weight_by_job_code[name] + 1
          })
        }
        decrementDistributionWeight={name =>
          onUpdate({
            ...weight_by_job_code,
            [name]: weight_by_job_code[name] - 1
          })
        }
        setDistributionWeight={(name, weight) =>
          onUpdate({
            ...weight_by_job_code,
            [name]: weight
          })
        }
      />
    </div>
  )
)`margin-top: 1rem;`

const ZeroWeightError = Styled(({ className }) => (
  <div className={className}>
    <ExclamationIcon label="Total points must be greater than zero" />
  </div>
))`margin-top: 1rem;`
