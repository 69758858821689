import SortByFilter from "./SortBy"
import LocationFilter from "./Location"
import RevenueCenterFilter from "./RevenueCenter"
import DistributionSource from "./DistributionSource"
import DistributeToJobCode from "./DistributeToJobCode"
import DistributeFromJobCode from "./DistributeFromJobCode"
import { Modal, Table, IconButton, SubmitButton } from "../../../Shared"

import { useOrgIsMultiUnit, useRevenueCentersEnabled } from "../../../../hooks"
import { toInt } from "../../../../helpers"
import { OBJECT_NAME_TIP_SHARE, handleSourceNames } from "../../helpers"

export default ({
  filters,
  setFilters,
  resetFilters,
  object_name,
  onClose,
  onSubmit
}) => {
  const multi_unit = useOrgIsMultiUnit()
  const rev_centers_enabled = useRevenueCentersEnabled()
  return (
    <Modal size="tiny" onClose={onClose} sticky={!!resetFilters}>
      <Modal.Header onClose={onClose}>Search Filters</Modal.Header>
      <Modal.Content fitted>
        <Table fitted basic="very">
          <Table.Body>
            {multi_unit && (
              <LocationFilter
                object_name={object_name}
                location={filters.location}
                dependent_filters={{
                  distribute_from: filters.distribute_from,
                  distribute_to: filters.distribute_to,
                  revenue_center: filters.revenue_center
                }}
                onChange={location =>
                  setFilters({
                    ...filters,
                    location,
                    distribute_from: null,
                    distribute_to: null
                  })
                }
              />
            )}
            {!!rev_centers_enabled && (
              <RevenueCenterFilter
                location={filters.location}
                revenue_center={filters.revenue_center}
                onSelect={({ id, name, store }) => {
                  setFilters({
                    ...filters,
                    revenue_center: { id, name },
                    location: { id: toInt(store.id), name: store.name }
                  })
                }}
                onRemove={() =>
                  setFilters({
                    ...filters,
                    revenue_center: null
                  })
                }
              />
            )}
            {object_name === OBJECT_NAME_TIP_SHARE && (
              <DistributeFromJobCode
                location={filters.location}
                job_code={filters.distribute_from}
                onSelect={({ id, name, store }) => {
                  setFilters({
                    ...filters,
                    distribute_from: { id, name },
                    location: { id: toInt(store.id), name: store.name }
                  })
                }}
                onRemove={() =>
                  setFilters({
                    ...filters,
                    distribute_from: null
                  })
                }
              />
            )}
            <DistributeToJobCode
              location={filters.location}
              job_code={filters.distribute_to}
              object_name={object_name}
              onSelect={({ id, name, store }) => {
                setFilters({
                  ...filters,
                  distribute_to: { id, name },
                  location: { id: toInt(store.id), name: store.name }
                })
              }}
              onRemove={() =>
                setFilters({
                  ...filters,
                  distribute_to: null
                })
              }
            />
            <DistributionSource
              location={filters.location}
              distribution_source={filters.distribution_source}
              object_name={object_name}
              onChange={(value, name) =>
                setFilters({
                  ...filters,
                  distribution_source: {
                    ...filters.distribution_source,
                    [name]: value
                  },
                  source_names: handleSourceNames(filters.source_names, name)
                })
              }
            />
            <SortByFilter
              object_name={object_name}
              field={filters.sort_field}
              direction={filters.sort_direction}
              onChange={(field, direction) => {
                return setFilters({
                  ...filters,
                  sort_field: field,
                  sort_direction: direction
                })
              }}
            />
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        {!!resetFilters && (
          <IconButton icon="refresh" basic onClick={resetFilters}>
            Reset Filters
          </IconButton>
        )}
        <SubmitButton onClick={onSubmit} />
      </Modal.Actions>
    </Modal>
  )
}
