import { SHOW_MODAL, CLEAR_ALL_MODALS } from "../actions"

const default_state = {
  contact_support_modal: false
}

export default (state = default_state, action) => {
  const { type, payload } = action
  switch (type) {
    case SHOW_MODAL:
      return { ...state, [payload]: true }
    case CLEAR_ALL_MODALS:
      return { ...default_state }
    default:
      return { ...state }
  }
}
