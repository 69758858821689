import moment from "moment"
import Styled from "styled-components"

import { LinkToReporting } from "../../Report"
import { Link, Modal, Table } from "../../Shared"

import { useSelectedLocation } from "../../../hooks"
import { paths } from "../../../constants"
import useAuthorization from "../../../hooks/authorization"
import { reporting_assign_job_code_to_sales } from "../../../constants/permissions"

const SummaryTable = Styled(({ className, ...props }) => (
  <div className={className}>
    <Table {...props} />
  </div>
))`
  max-height: 50vh;
  overflow-y: auto;
  margin: 1.5rem 0;
`

const SummaryModal = props => {
  const selected_location = useSelectedLocation()
  const { hasPermission } = useAuthorization()
  const { sales, onClose } = props

  const first_day = moment(sales[0].sale_time).format("MMMM Do")
  const last_day = moment(sales[sales.length - 1].sale_time).format("MMMM Do")

  return (
    <Modal size="small" onClose={onClose}>
      <Modal.Header onClose={onClose}>Unassigned Sales</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {sales.length} sale{sales.length > 1 && `s`}{" "}
          {sales.length === 1 ? "was" : "were"} assigned a <b>None</b> job code{" "}
          {first_day === last_day && <>on {first_day}</>}
          {first_day !== last_day && (
            <>
              between {first_day} and {last_day}
            </>
          )}
          .
        </Modal.Description>
        <SummaryTable compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Employee</Table.HeaderCell>
              {!selected_location && (
                <Table.HeaderCell>Location</Table.HeaderCell>
              )}
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Sale ID</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {[...sales].reverse().map(sale => {
              return (
                <Table.Row key={sale.id}>
                  <Table.Cell>
                    {sale.employee.first_name} {sale.employee.last_name}
                  </Table.Cell>
                  {!selected_location && (
                    <Table.Cell>{sale.employee.store.name}</Table.Cell>
                  )}
                  <Table.Cell>
                    {moment(sale.sale_time).format("ddd, M/D/YY [at] h:mma")}
                  </Table.Cell>
                  <Table.CopyToClipboardCell max_width="10rem">
                    {sale.external_id}
                  </Table.CopyToClipboardCell>
                  <Table.Cell collapsing>
                    <LinkToReporting unassigned_sale={sale} onClick={onClose} />
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </SummaryTable>
        <Modal.Description>
          Sales with no job code assigned can impact tip distribution
          calculations.
        </Modal.Description>
        <Modal.Description>
          The best way to resolve this issue is to ensure all sales are covered
          by a shift.
        </Modal.Description>
        {hasPermission(reporting_assign_job_code_to_sales) && (
          <Modal.Description>
            You can also assign a job code to individual sales, or assign a job
            code override to an individual employee on the{" "}
            <Link target="_blank" href={paths.reportSales}>
              Sales Reporting
            </Link>{" "}
            page.
          </Modal.Description>
        )}
      </Modal.Content>
    </Modal>
  )
}

export default SummaryModal
