import { Popup } from "semantic-ui-react"
import Styled from "styled-components"
import { colors, style } from "../../../constants"

const default_style = { padding: "0" }

const PopupMenu = ({
  children,
  on = "click",
  position = "bottom right",
  hideOnScroll = true,
  ...props
}) => {
  return (
    <Popup
      on={on}
      position={position}
      hideOnScroll={hideOnScroll}
      style={default_style}
      inverted={false}
      basic
      {...props}
    >
      {children}
    </Popup>
  )
}

PopupMenu.Item = Styled.div`
  position: relative;
  cursor: pointer;
  display: block;
  line-height: 1em;
  padding: 0.78571429rem 1.14285714rem!important;
  font-size: 1rem;    
  text-transform: none;
  font-weight: 400;
  font-family: ${style.font};
  color: rgba(0, 0, 0, .87);
  &:hover {
    background: rgba(0, 0, 0, .05);
    color: rgba(0, 0, 0, .95);
    z-index: 13;
  }
  ${({ danger }) =>
    danger &&
    `
    color: ${colors.danger};
    &:hover {
      color: ${colors.danger};
    }
  `};
`

export default PopupMenu
