import { Component } from "react"
import moment from "moment"
import Styled from "styled-components"

import { Modal, DatePicker, SubmitButton } from "../Shared"

import { date_format, date_filters_threshold_months } from "../../constants"
import { dateFilterMinDate, dateFiltersExceedThreshold } from "../../helpers"
import { compatibleDateObject } from "../../helpers/datetime"

const Through = Styled(props => <div {...props}>through</div>)`
  padding: 1rem 0;
  font-weight: 300;
  text-align: center;
`
const years_min_date = 1

export default class extends Component {
  state = { start_date: null, end_date: null }
  componentDidMount() {
    this.setState({
      start_date: moment(this.props.start_time)
        .startOf("day")
        .format(date_format),
      end_date: moment(this.props.end_time).startOf("day").format(date_format)
    })
  }
  render() {
    const { start_date, end_date } = this.state
    const { onClose, onSave } = this.props
    const max_date = compatibleDateObject(
      `${moment()
        .subtract(1, "d")
        .subtract(4, "h")
        .format(date_format)} 00:00:00`
    )
    const min_date = compatibleDateObject(dateFilterMinDate(years_min_date))
    return (
      <Modal onClose={onClose} size="mini">
        <Modal.Header onClose={onClose}>Select a Date Range</Modal.Header>
        <Modal.Content>
          <DatePicker
            label="Start Date"
            fluid
            value={compatibleDateObject(
              `${moment(start_date).format(date_format)} 00:00:00`
            )}
            format="dddd, MMM Do, YYYY"
            onChange={e => {
              let end = moment(end_date)
              let start = moment(e)
              // start is after end
              if (start.isAfter(end)) {
                end = moment(start)
              }

              // diff is more than 1 month
              if (dateFiltersExceedThreshold(start, end)) {
                end = moment(start)
                  .add(date_filters_threshold_months, "month")
                  .subtract(1, "day")
              }

              this.setState({
                start_date: start.format("YYYY-MM-DD"),
                end_date: end.format("YYYY-MM-DD")
              })
            }}
            maxDate={max_date}
            minDate={min_date}
          />
          <Through />
          <DatePicker
            label="End Date&nbsp;&nbsp;"
            fluid
            value={compatibleDateObject(
              `${moment(end_date).format(date_format)} 00:00:00`
            )}
            format="dddd, MMM Do, YYYY"
            onChange={e => {
              let end = moment(e)
              let start = moment(start_date)
              // end is before start
              if (end.isBefore(start)) {
                start = moment(end)
              }

              // diff is more than 1 month
              if (dateFiltersExceedThreshold(start, end)) {
                start = moment(end)
                  .subtract(date_filters_threshold_months, "month")
                  .add(1, "day")
              }

              this.setState({
                start_date: start.format("YYYY-MM-DD"),
                end_date: end.format("YYYY-MM-DD")
              })
            }}
            maxDate={max_date}
            minDate={min_date}
          />
        </Modal.Content>
        <Modal.Actions>
          <SubmitButton
            label="Apply"
            onClick={() => onSave(start_date, end_date)}
            icon="check"
            labelPosition="right"
          />
        </Modal.Actions>
      </Modal>
    )
  }
}
