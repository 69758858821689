import { Component } from "react"
import Styled from "styled-components"
import { Progress } from "semantic-ui-react"

import Logo from "../Logo"
import { Modal } from "../Shared"

import { colors } from "../../constants"
import { layoutContainerNode } from "../../helpers"

const ModalContent = Styled(Modal.Content)`text-align: center;`
const LogoHeader = Styled(props => (
  <div {...props}>
    <Logo secondaryColor={colors.dark3} size="large" />
  </div>
))`
  margin-top: 1rem;
`
const Header = Styled.div`
  font-size: 1.5rem;
  margin: 1.67rem 2rem;
`
const Text = Styled.div`
  font-size: 1.33rem;
  margin: 1.67rem 2rem;
  font-weight: 300;
`
const Thanks = Styled(props => <div {...props}>Thanks for using TipHaus!</div>)`
  margin: 1.67rem 2rem 1rem;
  font-size: 1.5rem;
`
const ModalFooter = Styled(Modal.Actions)`
  &&& {
    border-top: none !important;
    background-color: ${colors.info} !important;
    color: ${colors.white};
    font-size: 1.33rem;
    text-align: center !important;
    padding: 1.67rem 1rem !important;
  }
`
const ProgressBar = Styled(props => (
  <div {...props}>
    <Progress percent={100} active size="tiny" />
  </div>
))`
  padding: 0 4rem;
  margin: 2rem 0;
  & .bar {
    background: ${colors.info} !important;
  }
`

const SYNC_INTERVAL = 20000

export default class extends Component {
  state = { interval_id: null }

  componentDidMount() {
    // sync user on an interval
    // when the organization has synced, this component will unmount
    // based on rendering login in Content.js
    const interval_id = setInterval(this.props.syncUser, SYNC_INTERVAL)
    this.setState({ interval_id })
  }

  componentWillUnmount() {
    clearInterval(this.state.interval_id)
  }

  render() {
    return (
      <Modal size="small" mountNode={layoutContainerNode()}>
        <ModalContent>
          <LogoHeader />
          <Header>
            Hang tight... We're populating your organization's unique data.
          </Header>
          <ProgressBar />
          <Text>
            It takes time to make time, or something like that. We're currently
            syncing with your point of sale. This can take 10-15 minutes to
            complete, so take a quick break. We'll load the dashboard as soon as
            it's ready.
          </Text>
          <Thanks />
        </ModalContent>
        <ModalFooter>
          Note: All future syncs will happen behind the scenes.
        </ModalFooter>
      </Modal>
    )
  }
}
