import { round } from "../../../helpers"

export const OBJECT_NAME = "rule"

// format api data structure to display friendly structure
export const formatRuleForDisplay = rule =>
  rule.is_formatted
    ? { ...rule }
    : {
        ...rule,
        is_formatted: true,
        percent_in: round(rule.percent_in, 2),
        revenue_centers: rule.revenueCenters.map(({ name }) => name),
        jobCodes: rule.jobCodes.filter(({ deleted }) => !deleted),
        inputJobCodes: rule.inputJobCodes.filter(({ deleted }) => !deleted),
        input_job_code_names: rule.inputJobCodes
          .filter(({ deleted }) => !deleted)
          .map(({ name }) => name)
          .filter((val, i, arr) => arr.indexOf(val) == i),
        outputJobCodes: rule.outputJobCodes.filter(({ deleted }) => !deleted),
        output_job_code_names: rule.outputJobCodes
          .filter(({ deleted }) => !deleted)
          .map(({ name }) => name)
          .filter((val, i, arr) => arr.indexOf(val) == i),
        salesCategories: rule.salesCategories.filter(({ active }) => !!active),
        sales_category_names: rule.salesCategories
          .filter(({ active }) => !!active)
          .map(({ name }) => name)
          .filter((val, i, arr) => arr.indexOf(val) == i),
        distribution_source_names: rule.distributionSources.map(
          ({ name }) => name
        )
      }

export const prepareRuleAsTemplate = (rule, reportLockThreshold) => {
  return formatRuleForDisplay({
    ...rule,
    id: null,
    name: `${rule.name} (copy)`,
    effective_date: reportLockThreshold(Number(rule.store.id))
  })
}
