import { useState } from "react"
import { useMutation } from "@apollo/client"

import { Link, Button, Modal, Checkbox } from "../Shared"

import { project_info } from "../../constants"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"

import { UPDATE_USER_SERVICE_AGREEMENTS } from "../../graphql/mutations"
import { layoutContainerNode } from "../../helpers"

export default ({ id, document_url, onCompleted }) => {
  const [accept_selected, setAccepted] = useState(false)

  const [
    updateUserServiceAgreement,
    { loading: pending_accept_terms }
  ] = useMutation(UPDATE_USER_SERVICE_AGREEMENTS, { onCompleted })

  return (
    <Modal size="tiny" mountNode={layoutContainerNode()}>
      <Modal.Header>We've updated our Terms of Service</Modal.Header>
      <Modal.Content>
        <p>
          Please review and accept our latest{" "}
          <Link href={document_url} target="_blank">
            Terms of Service Agreement
          </Link>{" "}
          to continue.
        </p>
        <Checkbox
          label={`I have read and agree to the ${project_info.name} Service Agreement.`}
          checked={accept_selected}
          onChange={checked => setAccepted(checked)}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          disabled={!accept_selected || pending_accept_terms}
          loading={pending_accept_terms}
          onClick={() => {
            updateUserServiceAgreement({
              variables: {
                input: {
                  termsOfService: {
                    syncWithoutDetaching: [
                      {
                        id: Number(id),
                        agreed: true
                      }
                    ]
                  }
                }
              }
            })
          }}
        >
          Continue
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
