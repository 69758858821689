export const super_admin_role_name = "Super Admin"
export const enterprise_admin_role_name = "Enterprise Admin"
export const org_admin_role_name = "Org Admin"
export const store_admin_role_name = "GM Admin"
export const mobile_app_role_name = "Mobile App"

export const entity_type_enterprise = "App\\Models\\Enterprise"
export const entity_type_organization = "App\\Models\\Organization"
export const entity_type_store = "App\\Models\\Store"
export const entity_type_employee = "App\\Models\\Employee"
