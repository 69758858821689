import { Component } from "react"
import Styled from "styled-components"
import { Form } from "semantic-ui-react"
import { Mutation } from "@apollo/client/react/components"

import Icon from "../Icon"
import { Modal, Button, Message, ValidationErrorsMessage } from "../Shared"

import { colors } from "../../constants"
import { isValidEmail } from "./../../helpers/string"
import { toInt } from "../../helpers/number"
import { getGraphQLValidationErrors } from "../../helpers/api"
import { UPDATE_EMPLOYEE } from "../../graphql/mutations"

const ModalWrapper = Styled.div``
const InfoMessage = Styled(props => <Message type="info" {...props} />)`
  margin-bottom: 1rem;
`

export default class extends Component {
  state = {
    email: "",
    first_name: "",
    last_name: "",
    payroll_id: "",
    errors: []
  }

  componentDidMount() {
    const { employee } = this.props
    this.setState({
      first_name: employee.first_name,
      last_name: employee.last_name,
      email: employee.email ?? "",
      payroll_id: employee.payroll_id ?? ""
    })
  }

  isValid = () => {
    const { employee } = this.props
    const { email, first_name, last_name, payroll_id } = this.state

    return (
      (employee.email !== email && isValidEmail(email)) ||
      employee.first_name !== first_name ||
      employee.last_name !== last_name ||
      employee.payroll_id !== payroll_id
    )
  }

  render() {
    const { onClose, employee, toast, onCompleted } = this.props
    const { email, first_name, last_name, payroll_id, errors } = this.state

    return (
      <ModalWrapper>
        <Modal size="mini" onClose={onClose} closeOnDimmerClick={false}>
          <Modal.Header onClose={onClose}>Edit Employee</Modal.Header>
          <Modal.Content>
            {errors.length === 0 && (
              <InfoMessage message="Changes made here will override data reported by your point-of-sale provider." />
            )}
            {errors.length > 0 && (
              <ValidationErrorsMessage
                header="An error ocurred when attempting to update the employee."
                errors={errors}
                onDismiss={() => this.setState({ errors: [] })}
                margin_bottom="1rem"
              />
            )}
            <Form autoComplete="off">
              <Form.Field>
                <Form.Input
                  autoFocus={true}
                  spellCheck="false"
                  label="First Name"
                  type="text"
                  autoComplete="off"
                  icon={
                    !!first_name && first_name.length > 0 ? (
                      <Icon color={colors.green} name="check" fitted />
                    ) : null
                  }
                  value={first_name}
                  onChange={(e, { value }) =>
                    this.setState({
                      first_name: value
                    })
                  }
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  spellCheck="false"
                  label="Last Name"
                  type="text"
                  autoComplete="off"
                  icon={
                    !!last_name && last_name.length > 0 ? (
                      <Icon color={colors.green} name="check" fitted />
                    ) : null
                  }
                  value={last_name}
                  onChange={(e, { value }) =>
                    this.setState({
                      last_name: value
                    })
                  }
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  spellCheck="false"
                  label="Email"
                  type="text"
                  autoComplete="off"
                  icon={
                    !!email && email.length > 0 && isValidEmail(email) ? (
                      <Icon color={colors.green} name="check" fitted />
                    ) : null
                  }
                  value={email}
                  onChange={(e, { value }) =>
                    this.setState({
                      email: value
                    })
                  }
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  spellCheck="false"
                  label="Payroll ID"
                  type="text"
                  autoComplete="off"
                  icon={
                    !!payroll_id && payroll_id.length > 0 ? (
                      <Icon color={colors.green} name="check" fitted />
                    ) : null
                  }
                  value={payroll_id}
                  onChange={(e, { value }) =>
                    this.setState({
                      payroll_id: value
                    })
                  }
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Mutation
            mutation={UPDATE_EMPLOYEE}
            onError={e =>
              this.setState({
                errors: getGraphQLValidationErrors(e.graphQLErrors)
              })
            }
            onCompleted={({ updateEmployee }) => {
              if (!!toast) {
                toast({
                  type: "success",
                  message: "Employee successfully updated."
                })
              }
              onCompleted(updateEmployee)
              onClose()
            }}
          >
            {(updateEmployee, { loading }) => {
              return (
                <Modal.Actions>
                  <Button onClick={onClose} disabled={!!loading}>
                    Close
                  </Button>
                  <Button
                    loading={!!loading}
                    disabled={!!loading || !this.isValid()}
                    onClick={() => {
                      this.setState({ errors: [] })
                      updateEmployee({
                        variables: {
                          input: {
                            id: toInt(employee.id),
                            email,
                            last_name,
                            first_name,
                            payroll_id
                          }
                        }
                      })
                    }}
                    primary
                    icon
                    labelPosition="right"
                  >
                    Update <Icon name="check" />
                  </Button>
                </Modal.Actions>
              )
            }}
          </Mutation>
        </Modal>
      </ModalWrapper>
    )
  }
}
