import Styled from "styled-components"
import Icon from "../../../../../Icon"
import { Loader, IconButton } from "../../../../../Shared"
import { colors } from "../../../../../../constants"

const ReviewActions = ({
  loading,
  verified,
  flagged,
  show_flagged = true,
  onVerify,
  onFlag
}) => {
  if (!!loading) {
    return <Loader inverted />
  }
  if (verified) {
    return <VerifiedLabel />
  }
  if (flagged) {
    return <FlaggedLabel />
  }
  return (
    <ReviewActionButtons
      show_flagged={show_flagged}
      onVerify={onVerify}
      onFlag={onFlag}
    />
  )
}

const ReviewActionButtons = ({ show_flagged, onVerify, onFlag }) => (
  <div style={{ whiteSpace: "nowrap" }}>
    <IconButton
      data-testid="verify-button"
      basic
      positive
      circular
      icon="thumbs up"
      popup={{
        delay: true,
        content: "Looks good!",
        position: "top right"
      }}
      onClick={onVerify}
    />
    {show_flagged && (
      <IconButton
        data-testid="flag-button"
        basic
        negative
        circular
        icon="flag"
        popup={{
          delay: true,
          content: "Flag for internal review.",
          position: "top right"
        }}
        onClick={onFlag}
      />
    )}
  </div>
)

const VerifiedLabel = () => (
  <div>
    <Icon size="big" name="check" color={colors.green} />
    <ActionResultLabel label="Approved" />
  </div>
)

const FlaggedLabel = () => (
  <div>
    <Icon size="big" name="flag" color={colors.red} />
    <ActionResultLabel label="Flagged" />
  </div>
)

const ActionResultLabel = Styled(({ className, label }) => (
  <div className={className}>{label}</div>
))`padding: 0.33rem 1rem;`

export default ReviewActions
