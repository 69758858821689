import Styled from "styled-components"

import { FilterRow } from "../Styled"
import Dropdown from "../../../../Dropdown"
import { SortDirectionButton } from "../../../../Shared/FilterBar/Filters/SortBy"

const SORT_FIELD_START = "start"
const SORT_FIELD_END = "end"
const SORT_FIELD_FIRST_NAME = "employees.first_name"
const SORT_FIELD_LAST_NAME = "employees.last_name"
const SORT_DIRECTION_ASC = "asc"
const SORT_DIRECTION_DESC = "desc"

const DEFAULT_SORT_FIELD = SORT_FIELD_START
const DEFAULT_SORT_DIRECTION = SORT_DIRECTION_DESC

const SORT_OPTIONS = [
  {
    text: "Clock In",
    value: SORT_FIELD_START
  },
  {
    text: "Clock Out",
    value: SORT_FIELD_END
  },
  {
    text: "First Name",
    value: SORT_FIELD_FIRST_NAME
  },
  {
    text: "Last Name",
    value: SORT_FIELD_LAST_NAME
  }
]

export default ({ field, direction, onChange }) => (
  <SortWithDefaults
    field={field ?? DEFAULT_SORT_FIELD}
    direction={direction ?? DEFAULT_SORT_DIRECTION}
    onChange={(field, direction) =>
      onChange(field, direction, sortIsDefault(field, direction))
    }
  />
)

const SortWithDefaults = ({ field, direction, onChange }) => (
  <FilterRow name="Sort By">
    <Dropdown
      selection
      value={field}
      options={SORT_OPTIONS}
      onChange={(e, { value }) => onChange(value, direction)}
    />
    &nbsp;
    <StyledDirectionButton
      direction={direction}
      onClick={() =>
        onChange(
          field,
          direction === SORT_DIRECTION_ASC
            ? SORT_DIRECTION_DESC
            : SORT_DIRECTION_ASC
        )
      }
    />
  </FilterRow>
)

const StyledDirectionButton = Styled(({ className, ...props }) => (
  <div className={className}>
    <SortDirectionButton {...props} />
  </div>
))`
  display: inline-block;
  line-height: 2.67rem;
`

const fieldIsDefault = field => !field || field === DEFAULT_SORT_FIELD
const directionIsDefault = direction =>
  !direction || direction === DEFAULT_SORT_DIRECTION

export const sortIsDefault = (field, direction) =>
  fieldIsDefault(field) && directionIsDefault(direction)
export const shiftSortOptions = () => [...SORT_OPTIONS]
export const defaultShiftSort = () => ({
  field: DEFAULT_SORT_FIELD,
  direction: DEFAULT_SORT_DIRECTION
})
export const sortFieldLabel = field =>
  SORT_OPTIONS.find(option => option.value === field)?.text
