import React, { Component } from "react"
import Styled from "styled-components"
import { Icon } from "semantic-ui-react"

import Logo from "./../Logo/"
import OrganizationTable from "./OrganizationTable"

import { colors } from "./../../constants"

const AdminWrapper = Styled.div`
  background-color: ${colors.white};
  height: 100vh;
`
const AdminHeader = Styled.div`
  position: relative;
  font-size: 1.67rem;
  font-weight: 300;
  color: ${colors.white};
  background-color: ${colors.dark3};
  padding: 2.5rem;
  margin-bottom: 4rem;
`
const AdminControl = Styled.div`
  position: absolute;
  right: 2rem;
  top: 1.9rem;
`
const LogoutIcon = Styled.div`
  margin-left: 0.33rem;
  padding: 0.67rem;
  display: inline-block;
  cursor: pointer;
  color: ${colors.white};
  &:hover {
    color: ${colors.info};
  }
`
const AdminContent = Styled.div`
  padding: 0.67rem 2.5rem;
  font-size: 1.3rem;
  font-weight: 300;
  margin-top: 2rem;
`
const TableWrapper = Styled.div`
  margin-top: 1rem;
`

export default class extends Component {
  render() {
    const { user, logout } = this.props
    return (
      <AdminWrapper>
        <AdminHeader>
          Admin Login
          <AdminControl>
            <Logo style={{ marginTop: "-3px" }} />
            <LogoutIcon
              onClick={() => {
                logout()
              }}
            >
              <Icon name="log out" />
            </LogoutIcon>
          </AdminControl>
        </AdminHeader>
        <AdminContent>Welcome, {user.name}.</AdminContent>
        <AdminContent>
          Select an Organization
          <TableWrapper>
            <OrganizationTable {...this.props} />
          </TableWrapper>
        </AdminContent>
      </AdminWrapper>
    )
  }
}
