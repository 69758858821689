import { useMutation } from "@apollo/client"
import { RESEND_CLIENT_DASH_REGISTRATION } from "../../../../../../graphql/mutations"
import { AreYouSure } from "../../../../../Shared"

const ResendInviteModal = ({ user, onClose, onCompleted }) => {
  const [resendClientDashRegistration, { loading }] = useMutation(
    RESEND_CLIENT_DASH_REGISTRATION
  )

  const handleResendInvite = () => {
    const { id } = user
    resendClientDashRegistration({
      variables: { id },
      onCompleted
    })
  }

  return (
    <AreYouSure
      header="Resend Invite"
      submitted={loading}
      body={
        <>
          <p>
            Please confirm you want to resend an email to signup for {user.name}{" "}
            using {user.email}
          </p>
        </>
      }
      onConfirm={handleResendInvite}
      onClose={onClose}
    />
  )
}

export default ResendInviteModal
