import { Table } from "../../../../../Shared"
import { registration_states } from "../helpers"
import TableRow from "./Row"

const RegistrationInProgressTable = ({
  rows,
  filters,
  show_filters,
  onFilterChange,
  onInviteSent
}) => {
  const { user_search, state_filter } = filters
  return (
    <Table fitted compact basic="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Service</Table.HeaderCell>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Registration Status</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {show_filters && (
          <Table.TotalsRow>
            <Table.Cell />
            <Table.InputCell
              value={user_search}
              onChange={user_search => onFilterChange({ user_search })}
            />
            <Table.SelectCell
              value={state_filter}
              options={[...registration_states]}
              onChange={(e, { value }) =>
                onFilterChange({ state_filter: value })
              }
            />
          </Table.TotalsRow>
        )}
        {rows.map(row => (
          <TableRow key={row.user.id} {...row} onInviteSent={onInviteSent} />
        ))}
      </Table.Body>
    </Table>
  )
}

export default RegistrationInProgressTable
