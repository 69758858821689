import { useState } from "react"

import Styled from "styled-components"
import { Form, Label } from "semantic-ui-react"
import { Mutation } from "@apollo/client/react/components"

import Modal from "."
import Icon from "../Icon"
import Link from "../Link"
import Message from "../Message"
import JobCodeSearch from "../Search/JobCode"
import Button, { SubmitButton } from "../Button"
import { DataLockedModal } from "../Shared/ReportLockedIndicator"

import { appMinDate } from "../../helpers"
import { toInt } from "../../helpers/number"
import { ASSIGN_JOB_CODE_OVERRIDE } from "../../graphql/mutations"
import { datetime_format, weekday_month_day_format } from "../../constants"
import { useBusinessDates, useReportLockThreshold } from "../../hooks/selectors"
import { useUser } from "../../hooks"

const StyledMessage = Styled(props => <Message {...props} type="info" />)`
  margin-bottom: 1rem;
`

export default ({ onClose, onError, employee, onCompleted }) => {
  const [selected_job_code, setSelectedJobCode] = useState(null)
  const [lock_detail_modal, showLockDetailModal] = useState(false)

  const { businessStart } = useBusinessDates()
  const reportLockThreshold = useReportLockThreshold()
  const lock_threshold = reportLockThreshold(employee.store.id)
  const user = useUser()

  return (
    <>
      <Modal size="tiny" dimmer>
        <Modal.Header onClose={onClose}>
          Configure Job Code Assignment
        </Modal.Header>
        <Modal.Content>
          <StyledMessage>
            <p>
              Apply a job code override to all of {employee.first_name}'s sales
              and shifts.
            </p>
            <p>
              <strong>Job code assignment is not recommended</strong> unless{" "}
              {employee.first_name}
              's job code is never expected to change.
            </p>
            {!!lock_threshold && (
              <p>
                After submit, we'll automatically reprocess your breakdown back
                to {lock_threshold.format(weekday_month_day_format)}. Data older
                than that is locked.{" "}
                <Link onClick={() => showLockDetailModal(true)}>
                  Learn more
                </Link>
                .
              </p>
            )}
          </StyledMessage>
          <Form>
            <Form.Field>
              <label>Select a Job Code</label>
              {!selected_job_code && (
                <JobCodeSearch
                  store_id={employee.store.id}
                  fluid
                  autoFocus={true}
                  onChange={({ title, value }) =>
                    setSelectedJobCode({ id: value, name: title })
                  }
                />
              )}
              {!!selected_job_code && (
                <Label
                  as="a"
                  size="large"
                  onClick={() => setSelectedJobCode(null)}
                >
                  {selected_job_code.name}&nbsp;&nbsp;
                  <Icon fitted cursor="pointer" name="remove" />
                </Label>
              )}
            </Form.Field>
          </Form>
        </Modal.Content>
        <Mutation
          mutation={ASSIGN_JOB_CODE_OVERRIDE}
          onError={onError}
          onCompleted={onCompleted}
        >
          {(setEmployeeConstantJobCode, { loading }) => {
            return (
              <Modal.Actions>
                <Button onClick={onClose} disabled={!!loading}>
                  Close
                </Button>
                <SubmitButton
                  loading={!!loading}
                  disabled={!selected_job_code || !!loading}
                  onClick={() => {
                    setEmployeeConstantJobCode({
                      variables: {
                        employee_id: toInt(employee.id),
                        job_code_id: toInt(selected_job_code.id),
                        reprocess_date: appMinDate(
                          businessStart(),
                          user
                        ).format(datetime_format)
                      }
                    })
                  }}
                />
              </Modal.Actions>
            )
          }}
        </Mutation>
      </Modal>
      {!!lock_detail_modal && (
        <DataLockedModal
          lock_threshold={lock_threshold}
          onClose={() => showLockDetailModal(false)}
        />
      )}
    </>
  )
}
