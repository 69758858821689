import { IconButton } from "../Shared"

import { paths } from "../../constants"
import { useBusinessDates } from "../../hooks"
import {
  prepareSalesReportFilters,
  prepareWorkedShiftsReportFilters
} from "./helpers"
import {
  NavigateToConsumer,
  SetApplicationFiltersConsumer,
  SelectLocationConsumer
} from "../../context"

import { toInt } from "../../helpers"

export default ({
  sale,
  shift,
  unassigned_sale,
  excessive_tip,
  onClick = () => {},
  ...props
}) => {
  const { businessStart } = useBusinessDates()

  return (
    <SelectLocationConsumer>
      {changeSelectedLocation => (
        <NavigateToConsumer>
          {navigateTo => (
            <SetApplicationFiltersConsumer>
              {setApplicationFilters => (
                <IconButton
                  fitted
                  compact
                  circular
                  size="tiny"
                  icon="clipboard outline"
                  popup={{
                    delay: true,
                    content: "Link to Reporting",
                    position: "top right"
                  }}
                  onClick={() => {
                    if (!!shift) {
                      setApplicationFilters(
                        prepareWorkedShiftsReportFilters({
                          start_time: businessStart(
                            shift.window_start ?? shift.start
                          ),
                          end_time: businessStart(
                            shift.window_start ?? shift.start
                          ),
                          employee: shift.employee,
                          job_code: shift.jobCode
                        }),
                        false
                      )
                      navigateTo(paths.reportWorkedShifts)
                      return onClick()
                    }
                    if (!!sale) {
                      setApplicationFilters(
                        prepareSalesReportFilters({
                          start_time: businessStart(
                            sale.window_start ?? sale.start
                          ),
                          end_time: businessStart(
                            sale.window_start ?? sale.start
                          ),
                          employee: sale.employee,
                          job_code: sale.jobCode
                        }),
                        false
                      )
                      navigateTo(paths.reportSales)
                      return onClick()
                    }
                    if (!!unassigned_sale) {
                      changeSelectedLocation(
                        toInt(unassigned_sale.employee?.store?.id)
                      )
                      setApplicationFilters(
                        prepareSalesReportFilters({
                          start_time: businessStart(
                            unassigned_sale.sale_time ??
                              unassigned_sale.opened_time
                          ),
                          end_time: businessStart(
                            unassigned_sale.sale_time ??
                              unassigned_sale.opened_time
                          ),
                          job_code: unassigned_sale.workedShift?.jobCode ?? {
                            id: null,
                            name: "None"
                          },
                          location: unassigned_sale.employee?.store
                        }),
                        false
                      )
                      navigateTo(paths.reportSales)
                      return onClick()
                    }
                    if (!!excessive_tip) {
                      setApplicationFilters(
                        prepareSalesReportFilters({
                          start_time: businessStart(
                            excessive_tip.sale_time ?? excessive_tip.opened_time
                          ),
                          end_time: businessStart(
                            excessive_tip.sale_time ?? excessive_tip.opened_time
                          ),
                          employee: excessive_tip.employee,
                          sale: excessive_tip
                        }),
                        false
                      )
                      navigateTo(paths.reportSales)
                      return onClick()
                    }
                  }}
                  {...props}
                />
              )}
            </SetApplicationFiltersConsumer>
          )}
        </NavigateToConsumer>
      )}
    </SelectLocationConsumer>
  )
}
