import React, { useState } from "react"

import Styled from "styled-components"
import { useMutation } from "@apollo/client"

import Icon from "../../Icon"
import Modal from "../../Modal"
import Button from "../../Button"
import Message from "../../Message"
import {
  SelectStores,
  PayPeriodDetail,
  StoresFinalizingIndicator
} from "./Shared"

import { UNLOCK_PAY_PERIOD } from "../../../graphql/mutations"

export default Styled(
  ({
    user,
    onClose,
    onSuccess,
    className,
    pay_period,
    available_to_unlock,
    currently_finalizing,
    show_unlock_disclaimer = false
  }) => {
    const [selected_stores, setSelectStores] = useState(
      user.stores.length === 1 ? [...user.stores.map(({ id }) => id)] : []
    )
    const [unlockPayPeriod, { loading }] = useMutation(UNLOCK_PAY_PERIOD, {
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        onSuccess()
      }
    })

    return (
      <Modal
        size="tiny"
        onClose={onClose}
        className={className}
        closeOnDimmerClick={false}
      >
        <Modal.Header onClose={onClose}>Unlock Pay Period</Modal.Header>
        <ModalContent>
          <PayPeriodDetail pay_period={pay_period} fitted />
          <Message
            message={
              <>
                <p>
                  You can unlock this pay period after it's finalized in order
                  to make changes to locked data. Data will remain unlocked and
                  a new shapshot will not be created until you finalize the pay
                  period.
                </p>
                {show_unlock_disclaimer && (
                  <p>
                    Unlocking this pay period will also unlock the last pay
                    period.
                  </p>
                )}
              </>
            }
          />
          {currently_finalizing.length > 0 && (
            <StoresFinalizingIndicator total={currently_finalizing.length} />
          )}
          {user.stores.length > 1 && (
            <SelectStores
              label="Select Locations to Unlock"
              options={available_to_unlock.map(store => ({
                value: store.id,
                text: store.name
              }))}
              onChange={(e, { value }) => setSelectStores([...value])}
              value={selected_stores}
            />
          )}
        </ModalContent>
        <Modal.Actions>
          <Button onClick={onClose} disabled={!!loading}>
            Close
          </Button>
          <Button
            primary
            icon
            loading={!!loading}
            labelPosition="right"
            disabled={selected_stores.length === 0 || !!loading}
            onClick={() =>
              unlockPayPeriod({
                variables: {
                  store_ids: [...selected_stores],
                  pay_period_id: pay_period.id
                }
              })
            }
          >
            Unlock <Icon name="unlock" />
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
)`
  & .content > div {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const ModalContent = Styled(Modal.Content)`font-weight: 300;`
