import React, { Component } from "react"
import Styled from "styled-components"
import { Form } from "semantic-ui-react"
import { Mutation } from "@apollo/client/react/components"

import Modal from "."
import Icon from "../Icon"
import Button from "../Button"
import Message from "../Message"

import { colors } from "../../constants"
import { toInt } from "../../helpers/number"
import { UPDATE_JOB_CODE } from "../../graphql/mutations"

const ModalWrapper = Styled.div``
const InfoMessage = Styled(props => <Message type="info" {...props} />)`
  margin-bottom: 1rem;
`
const ErrorMessage = Styled(props => <Message type="error" {...props} />)`
  margin-bottom: 1rem;
`

export default class extends Component {
  state = {
    name: "",
    errors: []
  }

  componentDidMount() {
    const { job_code } = this.props
    this.setState({
      name: job_code.name
    })
  }

  isValid = () => {
    const { job_code } = this.props
    const { name } = this.state

    return name.trim().length > 0 && name.trim() !== job_code.name
  }

  render() {
    const { onClose, job_code, toast, onCompleted } = this.props
    const { name, errors } = this.state

    return (
      <ModalWrapper>
        <Modal size="mini" onClose={onClose} closeOnDimmerClick={false}>
          <Modal.Header onClose={onClose}>Edit Job Code</Modal.Header>
          <Modal.Content>
            {errors.length === 0 && (
              <InfoMessage
                message={
                  <>
                    <p>
                      Changes made here will override data reported by your
                      point-of-sale provider.
                    </p>
                    <p>
                      Tip sharing rules that reference this job code will not be
                      affected.
                    </p>
                  </>
                }
              />
            )}
            {errors.length > 0 && (
              <ErrorMessage onDismiss={() => this.setState({ errors: [] })}>
                {errors.map((err, idx) => (
                  <p key={idx}>{err}</p>
                ))}
              </ErrorMessage>
            )}
            <Form autoComplete="off">
              <Form.Field>
                <Form.Input
                  autoFocus={true}
                  spellCheck="false"
                  label="Name"
                  placeholder="My Job Code"
                  type="text"
                  autoComplete="off"
                  icon={
                    !!name && name.length > 0 ? (
                      <Icon color={colors.green} name="check" fitted />
                    ) : null
                  }
                  value={name}
                  onChange={(e, { value }) =>
                    this.setState({
                      name: value
                    })
                  }
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Mutation
            mutation={UPDATE_JOB_CODE}
            onError={() =>
              this.setState({
                errors: [
                  "An error ocurred when attempting to update the job code."
                ]
              })
            }
            onCompleted={({ updateJobCode }) => {
              if (!!toast) {
                toast({
                  type: "success",
                  message: "Job code updated successfully."
                })
              }
              onCompleted(updateJobCode)
              onClose()
            }}
          >
            {(updateJobCode, { loading }) => {
              return (
                <Modal.Actions>
                  <Button onClick={onClose} disabled={!!loading}>
                    Close
                  </Button>
                  <Button
                    loading={!!loading}
                    disabled={!!loading || !this.isValid()}
                    onClick={() => {
                      this.setState({ errors: [] })
                      updateJobCode({
                        variables: {
                          id: toInt(job_code.id),
                          name
                        }
                      })
                    }}
                    primary
                    icon
                    labelPosition="right"
                  >
                    Submit <Icon name="check" />
                  </Button>
                </Modal.Actions>
              )
            }}
          </Mutation>
        </Modal>
      </ModalWrapper>
    )
  }
}
