import React from "react"

import moment from "moment"
import Styled from "styled-components"
import { Loader } from "semantic-ui-react"

import Table from "../../Table"
import { MultiSelect } from "../../Dropdown"

export const StoresFinalizingIndicator = Styled(({ className, total }) => (
  <div className={className}>
    <Loader size="mini" inline active indeterminate />
    <span>
      Finalize in progress at {total} location
      {total > 1 && "s"}
    </span>
  </div>
))`
  line-height: 2.33rem;
  display: inline-block;
  margin-right: 0.5rem;
  & > span {
    margin-left: 0.5rem;
  }
`

export const PayPeriodDetail = ({ pay_period, ...props }) => {
  const date_format = "dddd, MMMM Do"
  const time_format = "h:mm a"
  return (
    <Table basic="very" compact collapsing {...props}>
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            <strong>First Day:</strong>
          </Table.Cell>
          <Table.Cell>
            {moment(pay_period.start).format(date_format)}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <strong>Last Day:</strong>
          </Table.Cell>
          <Table.Cell>{moment(pay_period.end).format(date_format)}</Table.Cell>
        </Table.Row>
        {!!pay_period.exported_at && (
          <Table.Row>
            <Table.Cell>
              <strong>Finalized At:</strong>
            </Table.Cell>
            <Table.Cell>
              {moment
                .utc(pay_period.exported_at)
                .local()
                .format(`${date_format} [at] ${time_format}`)}
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  )
}

export const SelectStores = Styled(({ className, ...props }) => (
  <div className={className}>
    <MultiSelect
      {...props}
      fluid
      search
      max_labels={5}
      selectOnBlur={false}
      closeOnChange={true}
      can_select_all={true}
      selectOnNavigation={false}
      noResultsMessage="All Locations Selected"
      placeholder="Select one or more locations."
    />
  </div>
))``
