import { useMutation } from "@apollo/client"
import { useEffect } from "react"
import { Button } from "semantic-ui-react"
import Styled from "styled-components"
import { colors, paths } from "../../constants"
import { LOGIN } from "../../graphql/mutations"
import Logo from "../Logo"

// query every 60 seconds
const query_timeout_ms = 60000
const email = "test@test.com"
const password = "test"

const Maintenance = () => {
  const [login] = useMutation(LOGIN, {
    variables: {
      email,
      password
    },
    onCompleted: () => {
      // server is no longer returning a 503, redirect to login
      window.location.href = paths.login
    }
  })

  useEffect(() => {
    // attempt to login with fake creds
    setTimeout(() => {
      login()
    }, query_timeout_ms)
  }, [])

  return (
    <Container>
      <Logo size="large" secondaryColor={colors.dark6} />
      <h1>Sorry, we're down for maintenance.</h1>
      <h3>We'll be back shortly.</h3>
      <Button
        onClick={() => {
          window.location.href = paths.login
        }}
      >
        Back to login
      </Button>
    </Container>
  )
}

const Container = Styled.div`
	color: ${colors.dark2};
	text-align: center;
	padding-top: 2rem;
	height: 100vh;
	.button {
		margin-top: 1rem;
	}
`

export default Maintenance
