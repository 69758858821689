import Icon from "./Icon"

export { default as HelpIcon } from "./Help"
export { default as InfoIcon } from "./Info"
export { default as RemoveIcon } from "./Remove"
export { default as SuccessIcon } from "./Success"
export { default as WarningIcon } from "./Warning"
export { default as QuestionIcon } from "./Question"
export { default as PeerToPeerIcon } from "./PeerToPeer"
export { default as ExclamationIcon } from "./Exclamation"

export default Icon
