import moment from "moment"
import Styled from "styled-components"
import { useQuery } from "@apollo/client"
import { Mutation } from "@apollo/client/react/components"

import {
  Icon,
  Table,
  Input,
  Loader,
  MenuDropdown,
  AreYouSure
} from "../../Shared"

import { STORE_REVENUE_CENTERS } from "../../../graphql/queries"
import { REQUEST_REPROCESS_STORES } from "../../../graphql/mutations"

import { useStateObject } from "../../../hooks"
import { date_format, project_info } from "../../../constants"

const PER_PAGE = 25
const DEFAULT_PAGE = 1
const COLSPAN_ALL_COLUMNS = 100

const ACTION_RESYNC = "Resync Revenue Centers with Point-of-Sale"

export default props => {
  const { store } = props

  const [state, setState] = useStateObject({
    search: "",
    page: DEFAULT_PAGE,
    per_page: PER_PAGE,
    request_resync: false
  })

  const { search, page, per_page, request_resync } = state

  const clearSearch = () => setState({ search: "", page: DEFAULT_PAGE })

  const parseRevCenters = data => {
    let results = []

    // parse
    try {
      results = data.store.revenueCenters.map(rc => ({
        ...rc,
        store: { id: data.store.id, name: data.store.name }
      }))
    } catch {}

    // filter and sort
    return (
      results
        // name search
        .filter(
          ({ name }) =>
            search.trim().length === 0 ||
            name.toLowerCase().includes(search.trim().toLowerCase())
        )
        // sort alphabetically
        .sort((a, b) => a.name.localeCompare(b.name))
    )
  }

  const { data, loading } = useQuery(STORE_REVENUE_CENTERS, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: { id: store.id }
  })

  const rev_centers = parseRevCenters(data)

  let pagination = {
    current_page: page,
    total: rev_centers.length,
    per_page: per_page,
    options: { per_page: [25, 50, 100, 500] },
    changePage: page => setState({ page }),
    changePerPage: per_page => setState({ per_page, page: 1 })
  }
  let custom_controls = []

  // table controls: rev center search
  custom_controls = [
    <Input
      icon="search"
      iconPosition="left"
      action={
        search.length > 0 && !loading
          ? {
              icon: <RemoveIcon />,
              onClick: clearSearch
            }
          : undefined
      }
      placeholder="Search..."
      value={search}
      onChange={(e, { value }) => setState({ search: value })}
    />
  ]

  // resync button
  custom_controls.push(
    <MenuDropdown
      text="Actions"
      options={[{ value: ACTION_RESYNC, text: ACTION_RESYNC }]}
      onChange={value => {
        switch (value) {
          case ACTION_RESYNC:
            return setState({ request_resync: true })
          default:
            return
        }
      }}
    />
  )

  return (
    <RevCentersWrapper>
      <Table
        compact
        draggable
        unstackable
        controls={{
          position: rev_centers.length > per_page ? "both" : "top",
          custom_controls_position: "top",
          pagination,
          custom: [...custom_controls]
        }}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Date Created</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {!!loading && rev_centers.length === 0 && (
            <Table.Row>
              <Table.Cell textAlign="center" colSpan={COLSPAN_ALL_COLUMNS}>
                <TableSpan>
                  <Loader inline active>
                    Loading...
                  </Loader>
                </TableSpan>
              </Table.Cell>
            </Table.Row>
          )}
          {!loading && rev_centers.length === 0 && (
            <Table.Row>
              <Table.Cell colSpan={COLSPAN_ALL_COLUMNS}>
                <TableSpan>No revenue centers found.</TableSpan>
              </Table.Cell>
            </Table.Row>
          )}
          {(!loading || rev_centers.length > 0) &&
            rev_centers
              .slice((page - 1) * per_page, page * per_page)
              .map(rev_center => {
                return (
                  <Table.Row key={rev_center.id}>
                    <Table.Cell>{rev_center.name}</Table.Cell>
                    <Table.Cell>
                      {moment(rev_center.created_at)
                        .local()
                        .format("dddd, MMMM Do, YYYY")}
                    </Table.Cell>
                  </Table.Row>
                )
              })}
        </Table.Body>
      </Table>
      {request_resync && (
        <Mutation
          mutation={REQUEST_REPROCESS_STORES}
          onCompleted={() => {
            setState({ request_resync: false })
            props.toast({
              type: "success",
              message: (
                <>
                  <p>
                    A resync is underway. This process may take a few minutes to
                    complete.
                  </p>
                </>
              )
            })
          }}
          onError={() => {
            setState({
              request_resync: null
            })
            props.toast({
              type: "error",
              message: (
                <>
                  <p>
                    An error occurred while requesting to resync revenue
                    centers.
                  </p>
                </>
              )
            })
          }}
        >
          {(reprocessStores, { loading }) => (
            <AreYouSure
              header="Resync Revenue Centers"
              submitted={!!loading}
              body={
                <Thin>
                  <p>
                    By default, {project_info.name} syncs revenue centers with
                    your point-of-sale daily.
                  </p>
                  <p>
                    If you confirm, we'll kick-off an additional sync now. It
                    may take a few minutes for the process to complete.
                  </p>
                  <p>Do you want to resync revenue centers now?</p>
                </Thin>
              }
              onClose={() => setState({ request_resync: false })}
              onConfirm={() => {
                reprocessStores({
                  variables: {
                    store_ids: [store.id],
                    // fillter date arg
                    date: moment().format(date_format),
                    sync_static: true,
                    sync_dynamic: false
                  }
                })
              }}
            />
          )}
        </Mutation>
      )}
    </RevCentersWrapper>
  )
}

const RevCentersWrapper = Styled.div`
  position: relative;
  font-size: 1rem;
`
const TableSpan = Styled.div`
  padding: 0.67rem 0;
`
const RemoveIcon = Styled(({ clickable, ...props }) => (
  <Icon name="remove" {...props} />
))`
  ${({ clickable }) =>
    !clickable
      ? ``
      : `
    cursor: pointer !important;
  `}
`
const Thin = Styled.span`font-weight:300;`
