import React from "react"
import Styled from "styled-components"

import { colors } from "../constants"

const PanelWrapper = Styled.div`
  background-color: ${colors.white};
  ${({ type, raised }) =>
    type === "info" && !!raised
      ? `box-shadow: 0 1px 3px ${colors.light6};`
      : ``}
  color: ${colors.dark2};
  font-size: 1rem;
  font-weight: 300;
  border-radius: 3px;
  border: 1px solid ${colors.light3};
`
const HeaderWrapper = Styled.div`
  font-size: 1.25rem;
  font-weight: 400;
  padding: 1rem;
  background-color: ${colors.light2};
  border-bottom: 1px solid ${colors.light3};
`
const ContentWrapper = Styled.div`
  padding: 1.33rem 1rem;
  background-color: ${colors.white};
`
const FooterWrapper = Styled.div`
  padding: 1rem;
  background-color: ${colors.light2};
  border-top: 1px solid ${colors.light3};
`

const Panel = ({ title, footer, children, raised = false, ...props }) => (
  <PanelWrapper {...props} raised={raised}>
    {!!title && <HeaderWrapper>{title}</HeaderWrapper>}
    <ContentWrapper>{children}</ContentWrapper>
    {!!footer && <FooterWrapper>{footer}</FooterWrapper>}
  </PanelWrapper>
)
Panel.Header = props => <HeaderWrapper {...props} />
Panel.Content = props => <ContentWrapper {...props} />
Panel.Footer = props => <FooterWrapper {...props} />

export default Panel
