import moment from "moment"

import { FilterBar } from "../../Shared"
import SelectedFilter from "../../Shared/FilterBar/Filters/Selected"

import {
  usePayPeriods,
  useBusinessDates,
  useOrgIsMultiUnit
} from "../../../hooks"
import { date_format, epoch } from "../../../constants"
import { RECENT_FILTER_DAYS_BACK } from "../helpers"
import { SelectLocationConsumer } from "../../../context/selectLocation"

export default ({ filters, setFilters, selected_location, ...props }) => {
  const { businessDate } = useBusinessDates()
  const pay_periods = usePayPeriods()
  return (
    <FilterBar
      {...props}
      id="eta-payout-filters"
      start_date={filters.start_date}
      end_date={filters.end_date}
      years_min_date={epoch}
      user={{
        user: filters.user ?? null,
        onSelect: ({ id, name }) => setFilters({ user: { id, name } }),
        onRemove: () => setFilters({ user: null })
      }}
      employee={{
        employee: filters.employee ?? null,
        label: "Employee",
        onSelect: ({ id, name, first_name, last_name }) =>
          setFilters({ employee: { id, first_name, last_name, name } }),
        onRemove: () => setFilters({ employee: null })
      }}
      onChange={({ start_date, end_date }) =>
        setFilters({
          start_date: moment(start_date).format(date_format),
          end_date: moment(end_date).format(date_format)
        })
      }
      presets={generateFilterPresets({
        ...pay_periods,
        ...filters,
        today_date: businessDate(),
        applyPreset: (start_date, end_date) =>
          setFilters({ start_date, end_date })
      })}
      custom_filters={generateCustomFilters(selected_location)}
      location_id={selected_location?.id}
      loading_msg={<FilterBarLoader {...filters} />}
    />
  )
}

const generateFilterPresets = ({
  end_date,
  start_date,
  today_date,
  applyPreset
}) => {
  const yesterday_date = moment(today_date)
    .subtract(1, "day")
    .format(date_format)
  const recent_start_date = moment(today_date)
    .subtract(RECENT_FILTER_DAYS_BACK, "day")
    .format(date_format)
  return [
    {
      name: "Today",
      active: start_date === today_date && end_date === today_date,
      onClick: () => applyPreset(today_date, today_date)
    },
    {
      name: "Yesterday",
      active: start_date === yesterday_date && end_date === yesterday_date,
      onClick: () => applyPreset(yesterday_date, yesterday_date)
    },
    {
      name: "Recent",
      active: start_date === recent_start_date && end_date === today_date,
      onClick: () => applyPreset(recent_start_date, today_date)
    }
  ]
}

const generateCustomFilters = selected_location => {
  const is_multi_unit = useOrgIsMultiUnit()

  let filters = []

  if (selected_location && is_multi_unit) {
    filters.push({
      label: "Location",
      filter: (
        <SelectLocationConsumer>
          {selectLocation => (
            <SelectedFilter
              value={selected_location.name}
              onRemove={() => selectLocation(null)}
            />
          )}
        </SelectLocationConsumer>
      )
    })
  }

  return filters
}

const FilterBarLoader = ({ start_date, end_date }) => (
  <>
    Loading your ETA Payouts for&nbsp;
    <strong>{moment(start_date).format("dddd, MMMM Do")}</strong>
    {moment(start_date).format(date_format) !==
      moment(end_date).format(date_format) && (
      <>
        {" "}
        through <strong>{moment(end_date).format("dddd, MMMM Do")}</strong>
      </>
    )}
  </>
)
