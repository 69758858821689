import HelpIcon from "./../Icon/Help"
import { List } from "semantic-ui-react"

import Link from "./../Link"
import ExclamationIcon from "./../Icon/Exclamation"

import { project_info, paths } from "./../../constants"

export default {
  onClose: () => {
    // Scroll the table all the way left
    const table = document.getElementById("breakdown-table")
    try {
      const wrapper = table.parentNode
      wrapper.scrollLeft = 0
    } catch {}
  },
  steps: [
    {
      target: "body",
      placement: "center",
      title: `${project_info.name} Breakdown`,
      content: (
        <>
          <p>Fear payroll no more.</p>
          <p>
            The Breakdown sums up tips shared and take home pay for all your
            employees.
          </p>
          <p>Follow along for some help getting started.</p>
        </>
      ),
      onMount: () => {
        requestAnimationFrame(() => {
          try {
            document.getElementById("show-detailed-breakdown-btn").click()
          } catch {}
        })
      }
    },
    {
      target: "#breakdown-filters",
      title: "Make payroll a breeze.",
      content: (
        <>
          <p>
            Adjust filters to find the time range you'd like to view in the
            Breakdown table below.
          </p>
          <p>Keep in mind:</p>
          <List bulleted style={{ textAlign: "left" }}>
            <List.Item>
              Each business day begins at 4am and ends at 4am the following
              morning.
            </List.Item>
            <List.Item>
              Today's breakdown is not available until the business day closes.
            </List.Item>
          </List>
        </>
      )
    },
    {
      target: "#breakdown-filters-showing",
      title: "Can't find someone?",
      content: (
        <>
          <p>
            By default, the Breakdown only lists employees with take home pay.
          </p>
          <p>You can change this filter to see all employees.</p>
        </>
      )
    },
    {
      target: "#breakdown-export-btn",
      title: "Hook into payroll.",
      content: (
        <>
          <p>Once your filters are set, CSV exports are a click away.</p>
        </>
      ),
      onMount: () => {
        // Scroll the table all the way to the column
        requestAnimationFrame(() => {
          const table = document.getElementById("breakdown-table")
          try {
            const wrapper = table.parentNode
            wrapper.scrollLeft = 0
          } catch {}
        })
      }
    },
    {
      target: "#breakdown-display-settings-btn",
      title: "Customize to your liking.",
      content: (
        <>
          <p>
            We have a number of columns you might like to add to your report.
          </p>
          <p>Click here to change which columns you see.</p>
          <p>
            <i>Note: </i> The report export includes all columns.
          </p>
        </>
      ),
      onMount: () => {
        // Scroll the table all the way to the column
        requestAnimationFrame(() => {
          const table = document.getElementById("breakdown-table")
          try {
            const wrapper = table.parentNode
            wrapper.scrollLeft = 0
          } catch {}
        })
      }
    },
    {
      target: "#breakdown-total-take-home-col-header",
      title: "The Bottom Line",
      name: "bottom-line",
      placement: "left",
      onMount: () => {
        // Scroll the table all the way to the column
        requestAnimationFrame(() => {
          const table = document.getElementById("breakdown-table")
          try {
            const wrapper = table.parentNode
            wrapper.scrollLeft = wrapper.scrollWidth
          } catch {}
        })
      },
      content: (
        <>
          <p>This might be the most important column.</p>
          <p>
            "Take Home" describes the amount each employee receives after
            sharing and pooling has applied.
          </p>
        </>
      )
    },
    {
      target: "#breakdown-total-take-home-col-header",
      title: "By the way,",
      name: "column-description",
      placement: "left",
      onMount: () => {
        // Scroll the table all the way to the column
        requestAnimationFrame(() => {
          const table = document.getElementById("breakdown-table")
          try {
            const wrapper = table.parentNode
            wrapper.scrollLeft = wrapper.scrollWidth
          } catch {}
        })
      },
      content: (
        <>
          <p>
            You can hover over the <HelpIcon fitted /> icons to see column
            descriptions.
          </p>
        </>
      )
    },
    {
      target: ".none-jobcode-badge",
      name: "none-jobcode",
      title: "Sales with no Job Code",
      content: (
        <>
          <p>You may see "None" listed as an employee's job name.</p>
          <p>
            This appears when we're unable to find the appropriate job code for
            a sale. Tip sharing may be affected.
          </p>
          <p>
            After the tour, click the <ExclamationIcon fitted /> icon to learn
            how you can resolve this issue.
          </p>
        </>
      ),
      onMount: () => {
        // Scroll the table all the way to the column
        requestAnimationFrame(() => {
          const table = document.getElementById("breakdown-table")
          try {
            const wrapper = table.parentNode
            wrapper.scrollLeft = 0
          } catch {}
        })
      }
    },
    {
      target: "#missed-clockouts-notification",
      title: "One last thing.",
      name: "missed-clockouts",
      content: (
        <>
          <p>Keep your eye out for this message.</p>
          <p>
            Neither tip sharing rules nor tip pools will apply to shifts missing
            a clockout.
          </p>
          <p>You can click this message to see more info.</p>
        </>
      )
    },
    {
      target: "#help-button",
      title: "Thanks for your time!",
      content: (
        <>
          <p>
            Don't forget, you can{" "}
            <Link href={paths.contact} target="_blank">
              contact our team
            </Link>{" "}
            with any questions above my pay grade.
          </p>
          <p>For anything else, you know where to find me.</p>
        </>
      ),
      back: {
        onClick: ({ goToStep }) => {
          if (!!document.getElementById("missed-clockouts-notification")) {
            goToStep("missed-clockouts")
          } else if (
            document.getElementsByClassName("none-jobcode-badge").length > 0
          ) {
            goToStep("none-jobcode")
          }
          goToStep("bottom-line")
        }
      },
      disable_next: true
    }
  ]
}
