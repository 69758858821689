import { Badge } from "../../../../Shared"
import { ReviewRow, LabelCell, ValueCell } from "../Styled"
import WeightedDistribution from "../../../WeightedDistribution"

export default ({ weight_by_job_code, apply_job_code_weights }) => {
  const job_code_names = [...Object.keys(weight_by_job_code)]
  return (
    <ReviewRow id="pool-summary-job-codes">
      <LabelCell label="Job Codes" />
      <ValueCell>
        {!apply_job_code_weights &&
          [...job_code_names]
            .sort((a, b) => a.localeCompare(b))
            .map(job => (
              <Badge primary key={job}>
                {job}
              </Badge>
            ))}
        {!!apply_job_code_weights && (
          <WeightedDistribution
            allow_input={false}
            show_header={false}
            selected_job_codes={[...job_code_names]
              .sort((a, b) =>
                weight_by_job_code[b] === weight_by_job_code[a]
                  ? a.localeCompare(b)
                  : weight_by_job_code[b] - weight_by_job_code[a]
              )
              .map(name => ({
                id: name,
                name: name,
                weight: weight_by_job_code[name]
              }))}
          />
        )}
      </ValueCell>
    </ReviewRow>
  )
}
