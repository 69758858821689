import React, { Component, Fragment, createRef } from "react"

import Styled from "styled-components"
import { Grid } from "semantic-ui-react"

import Metric from "./Metric"

import { metric_definitions } from "../helpers"

const MSG_TODAY_NOT_SUPPORTED = (
  <>
    <div>
      This metric does not support queries for the current business day.
    </div>
    <div>Try adjusting your date range filter.</div>
  </>
)

const ComponentWrapper = Styled.div``

export default class extends Component {
  state = { columns: 3 }

  constructor(props) {
    super(props)
    this.wrapper_ref = createRef()
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize)
    this.handleResize()
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }

  handleResize = () => {
    let columns = 4
    if (this.wrapper_ref.current.clientWidth <= 720) {
      columns = 1
    } else if (this.wrapper_ref.current.clientWidth < 1080) {
      columns = 2
    } else if (this.wrapper_ref.current.clientWidth < 1900) {
      columns = 3
    }
    this.setState({ columns })
  }

  renderMetric = (name, idx) => {
    const {
      end,
      start,
      totals,
      displayed,
      removeSetting,
      updateSettings,
      request_includes_today,
      ...props
    } = this.props

    let moveBack = false
    if (idx > 0) {
      moveBack = () => {
        displayed[idx] = displayed[idx - 1]
        displayed[idx - 1] = name
        updateSettings(displayed)
      }
    }

    if (!metric_definitions[name]) {
      console.error("unrecognized metric: " + name)
      return <Fragment />
    }

    return (
      <Grid.Column>
        <Metric
          {...props}
          {...metric_definitions[name]}
          totals={metric_definitions[name].totals(totals)}
          moveBack={moveBack}
          removeSetting={() => removeSetting(name)}
          disabled_msg={
            !!request_includes_today &&
            metric_definitions[name].supports_current_day === false
              ? MSG_TODAY_NOT_SUPPORTED
              : false
          }
          start={start}
          end={end}
        />
      </Grid.Column>
    )
  }

  render() {
    const { columns } = this.state
    const { displayed } = this.props

    return (
      <ComponentWrapper ref={this.wrapper_ref}>
        <Grid columns={columns}>
          <Grid.Row>
            {displayed.map((panel, idx) => (
              <Fragment key={panel}>{this.renderMetric(panel, idx)}</Fragment>
            ))}
          </Grid.Row>
        </Grid>
      </ComponentWrapper>
    )
  }
}
