import { useState } from "react"
import { downloadFile } from "../../../../../../helpers"
import { useToast } from "../../../../../../hooks"
import { AreYouSure } from "../../../../../Shared"

const ExportUsersModal = ({ onClose }) => {
  const [downloading, setDownloading] = useState(false)
  const toast = useToast()

  const handleExportUsers = async () => {
    setDownloading(true)
    await downloadFile("users/export")
    setDownloading(false)
    onClose()
    toast({
      type: "success",
      message: <p>Users successfully downloaded.</p>
    })
  }

  return (
    <AreYouSure
      header="Export Users"
      submitted={downloading}
      body="Would you like to download a full list of users for this organization?"
      onConfirm={handleExportUsers}
      onClose={onClose}
    />
  )
}

export default ExportUsersModal
