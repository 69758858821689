import { Badge } from "../../../../Shared"
import { ReviewRow, LabelCell, ValueCell } from "../Styled"

import { useRevenueCentersEnabled } from "../../../../../hooks"

export default ({ revenue_centers }) => {
  const rev_centers_enabled = useRevenueCentersEnabled()
  return (
    <>
      {rev_centers_enabled && (
        <ReviewRow>
          <LabelCell
            label={`Revenue Center${revenue_centers.length !== 1 ? "s" : ""}`}
          />
          <ValueCell>
            {revenue_centers.length === 0 && "No Filter Applied"}
            {revenue_centers.length > 0 &&
              revenue_centers.map(name => (
                <Badge key={name} size="small">
                  {name}
                </Badge>
              ))}
          </ValueCell>
        </ReviewRow>
      )}
    </>
  )
}
