export const rules_view = "tip-distribution:view"
export const rules_manage = "tip-distribution:manage"
export const breakdown_view = "breakdown:view"
export const breakdown_manage = "breakdown:manage"
export const eta_view = "eta:view"
export const eta_manage_enrollment = "eta:manage-enrollment"
export const eta_manage_payout = "eta:manage-payout"
export const reporting_view = "reporting:view"
export const reporting_assign_job_code_to_sales =
  "reporting:assign-job-code-to-sales"
export const reporting_custom_team_adjustment =
  "reporting:custom-team-adjustment"
export const reporting_manage_sale = "reporting:manage-sale"
export const reporting_manage_custom_shifts = "reporting:manage-custom-shifts"
export const reporting_manage_shift = "reporting:manage-shift"
export const reporting_reprocess_report_export_run =
  "reporting:reprocess-report-export-run"
export const settings_view = "settings:view"
export const settings_manage_store = "settings:manage-store"
export const settings_manage_store_cc_tip_fee =
  "settings:manage-store-cc-tip-fee"
export const settings_manage_employees = "settings:manage-employees"
export const settings_assign_job_code_to_employee =
  "settings:assign-job-code-to-employee"
export const settings_manage_job_codes = "settings:manage-job-codes"
export const settings_manage_sales_category = "settings:manage-sales-category"
export const settings_manage_users = "settings:manage-users"
export const settings_manage_employee_app_users =
  "settings:manage-employee-app-users"
export const settings_manage_roles = "settings:manage-roles"
export const settings_manage_org_cc_tip_fee = "settings:manage-org-cc-tip-fee"
export const settings_manage_pay_period = "settings:manage-pay-period"
export const settings_manage_recent_pay_periods =
  "settings:manage-recent-pay-periods"
export const settings_manage_peer_to_peer = "settings:manage-peer-to-peer"

// All available eta permissions
export const eta_permissions = [
  {
    name: eta_view,
    description: "Earned Tip Access Tab Visible"
  },
  {
    name: eta_manage_enrollment,
    description: "ETA Enrollment Management"
  },
  {
    name: eta_manage_payout,
    description: "Initiate Payout"
  }
]

// All available reporting permissions
export const reporting_permissions = [
  {
    name: reporting_view,
    description: "Reporting Tab Visible"
  },
  {
    name: reporting_assign_job_code_to_sales,
    description: "Batch Job Code Assignment"
  },
  {
    name: reporting_custom_team_adjustment,
    description: "Custom Team Adjustment"
  },
  {
    name: reporting_manage_sale,
    description: "Edit Sale Data"
  },
  {
    name: reporting_manage_custom_shifts,
    description: "Manage Custom Shifts"
  },
  {
    name: reporting_manage_shift,
    description: "Edit and Delete Shift Data"
  },
  {
    name: reporting_reprocess_report_export_run,
    description: "Reprocess Report Export"
  }
]

// All available settings permissions
export const settings_permissions = [
  {
    name: settings_view,
    description: "Settings Tab Visible"
  },
  {
    name: settings_manage_store,
    description: "Manage General Store Data"
  },
  {
    name: settings_manage_store_cc_tip_fee,
    description: "Manage Store CC Tip Fee"
  },
  {
    name: settings_manage_employees,
    description: "Manage Employee Details"
  },
  {
    name: settings_assign_job_code_to_employee,
    description: "Assign Job Codes to Employees"
  },
  {
    name: settings_manage_job_codes,
    description: "Job Code Management"
  },
  {
    name: settings_manage_sales_category,
    description: "Manage Sale Item Categories"
  },
  {
    name: settings_manage_users,
    description: "Manage Users"
  },
  {
    name: settings_manage_employee_app_users,
    description: "Manage Employee App Users"
  },
  {
    name: settings_manage_roles,
    description: "Manage Roles"
  },
  {
    name: settings_manage_org_cc_tip_fee,
    description: "Manage Organization CC Tip Fee"
  },
  {
    name: settings_manage_pay_period,
    description: "Manage Pay Period Settings"
  },
  {
    name: settings_manage_recent_pay_periods,
    description: "Manage Recent Pay Periods"
  },
  {
    name: settings_manage_peer_to_peer,
    description: "Manage Peer to Peer"
  }
]
