import { render } from "react-dom"
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client"

import Index from "./containers/"
import configureStore from "./store"

import { api } from "./constants"
import * as serviceWorker from "./sw"
import { syncUser, loginSuccess } from "./actions"
import { apollo_client, appContainerNode } from "./helpers"

export const store = configureStore()

// Create a separate Apollo client for auto-authentication on entry to avoid conflicting requests
const custom_apollo_client = new ApolloClient({
  uri: api.graphql_origin,
  credentials: "include",
  cache: new InMemoryCache({ addTypename: false }),
  headers: {
    "client-name": "tiphaus-client"
  }
})

const App = () => (
  <ApolloProvider client={apollo_client}>
    <Index store={store} />
  </ApolloProvider>
)

initializeApplication()

// initialize application
function initializeApplication() {
  onBeforeRender()
  // render application
  render(<App />, appContainerNode())
  onAfterRender()
}

async function onBeforeRender() {
  // attempt to sync user on entry
  await store.dispatch(syncUser(loginSuccess(), null, custom_apollo_client))
}

function onAfterRender() {
  serviceWorker.register({
    // SW Updated event
    onUpdate: registration => {
      console.log("service worker updated, refreshing...")
      location.reload(true)
    },
    // SW installed event
    onSuccess: registration => {
      console.log("service worker registered")
    }
  })
}
