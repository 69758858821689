import { LAYOUT_CONTAINER_ID } from "../constants"

const app_container_id = process.env.REACT_APP_CONTAINER_ID
export const appContainerNode = () => document.getElementById(app_container_id)

export const layoutContainerNode = () =>
  document.getElementById(LAYOUT_CONTAINER_ID)

export const expandSidebar = () => {
  try {
    if (document.getElementsByClassName("sidebar-collapsed").length) {
      document.getElementById("sidebar-item-menu").click()
    }
  } catch {}
}

export const collapseSidebar = () => {
  try {
    if (document.getElementsByClassName("sidebar-expanded").length) {
      document.getElementById("sidebar-item-menu").click()
    }
  } catch {}
}
