import moment from "moment"
import Styled from "styled-components"

import { Header } from "semantic-ui-react"
import {
  Popup,
  Badge,
  Table,
  Button,
  Message,
  HelpIcon,
  ExclamationIcon
} from "../Shared"

import {
  DISTRO_SOURCE_TYPE_CASH,
  DISTRO_SOURCE_TYPE_TIPS,
  DISTRO_SOURCE_TYPE_SALES
} from "./helpers"
import { ucwords } from "../../helpers"
import { useBusinessDates } from "../../hooks"
import { style, colors, month_day_year_format } from "../../constants"

export const Field = Styled(
  ({ id, className, name, description, children }) => (
    <div className={className}>
      <div id={id}>
        {!!name && <FieldName description={description}>{name}</FieldName>}
        <FieldContent>{children}</FieldContent>
      </div>
    </div>
  )
)`
  position: relative;
  margin-bottom: 2rem;
  :last-child {
    margin-bottom: 0;
  }
  &>div {
    display: ${({ fluid }) => (!!fluid ? "block" : "inline-block")};
  }
`

export const FieldName = Styled(
  ({ description = null, children, ...props }) => (
    <Popup hoverable delay content={description} disabled={!description}>
      <div {...props}>
        {children}
        {!!description && (
          <>
            &nbsp;&nbsp;
            <HelpIcon fitted cursor="pointer" />
          </>
        )}
      </div>
    </Popup>
  )
)`
  cursor: default;
  position: relative;
  display: inline-block;
  color: ${colors.dark3};
  font-weight: bold;
  text-transform: uppercase;
`

export const FieldContent = Styled(({ children, id = undefined, ...props }) => (
  <div {...props}>
    <div id={id} children={children} />
  </div>
))`
  margin: 1rem 0;
`

export const TextInput = Styled(({ className, error, ...props }) => (
  <div className={className}>
    <input autoFocus={true} spellCheck="false" {...props} />
    {!!error && (
      <Popup content="invalid input">
        <ExclamationIcon />
      </Popup>
    )}
  </div>
))`
  position: relative;
  display: inline-block;
  & > input {

    font-family: ${style.font};
    font-size: 2rem;
    border: none;
    outline: none !important;
    outline-width: 0;
    font-weight: 300;
    margin: 0 0.1em;
    color: ${colors.success};
    padding: 0 0.33em;
    background-color: transparent;
    border-bottom: 2px solid  ${colors.light3};
  
    &:focus {
      border-bottom: 2px solid ${colors.dark6};
    }

    &::placeholder {
      font-weight: 300;
      color: ${colors.tween};
    }

  }
  & > i.exclamation {
    position: absolute;
    right: 0;
    top: 0;
  }

`

export const StepHeader = Styled(({ content, subheader, ...props }) => (
  <div {...props}>
    <Header as="h3">
      <Header.Content>{content}</Header.Content>
      <Description>{subheader}</Description>
    </Header>
  </div>
))`
  margin: 2rem 0 2rem 0;
  & > .ui.header {
    color: ${colors.dark3};
  }
`

export const Description = Styled(props => <Header.Subheader {...props} />)`
  color: ${colors.dark2} !important;
  font-weight: 300 !important;
  margin-top: 0.15rem !important;
`

export const ItemLabel = Styled(({ size = "large", ...props }) => (
  <Badge {...props} size={size} />
))``

export const TableWrapper = Styled.div`
  margin: 1rem 0 1rem 0;
`
export const TableRow = Styled(Table.Row)`
  cursor: pointer;
  background-color: ${colors.white};
  &:hover {
    background-color: ${colors.light2};
  }
`

export const CreateButton = Styled(({ className, ...props }) => (
  <div className={className}>
    <Button
      primary
      circular
      icon="plus"
      content="Create"
      floated="right"
      size="small"
      {...props}
      id="create-rule-btn"
    />
  </div>
))`
  position: absolute;
  top: 0rem;
  right: 0;
  & > .button {
    margin-bottom: 1rem !important;
  }
`

export const FilterBarControls = props => (
  <Button
    primary
    circular
    fitted
    icon="plus"
    content="Create"
    size="small"
    {...props}
    id="create-rule-btn"
  />
)

export const SourceBadge = Styled.div`
display: inline-block;
color: ${colors.white};
border-radius: 3px;
font-weight: 600;
font-size: 0.85rem;
text-align: center;
padding: 0.15rem 0.33rem 0.15rem 0.33rem;
min-width: 3rem;
margin-right: 3px;
background-color: ${({ type }) => {
  switch (type) {
    case DISTRO_SOURCE_TYPE_CASH:
      return colors.green
    case DISTRO_SOURCE_TYPE_SALES:
      return colors.warning
    case DISTRO_SOURCE_TYPE_TIPS:
    default:
      return colors.success
  }
}}
`

export const EffectiveTimeSpan = ({
  effective_date,
  deactivated_date = null
}) => {
  const { businessDate } = useBusinessDates()
  const effective = businessDate(effective_date)
  const deactivated = !deactivated_date ? null : businessDate(deactivated_date)

  return (
    <>
      {effective === deactivated && "-"}
      {effective !== deactivated && (
        <>
          {moment(effective).format(month_day_year_format)}
          {!!deactivated && moment(deactivated).isSameOrAfter(effective) && (
            <>
              &nbsp;to end of day&nbsp;
              {moment(deactivated)
                .subtract(1, "d")
                .format(month_day_year_format)}
            </>
          )}
        </>
      )}
    </>
  )
}

export const TableLoader = ({ object_name = "rule", location }) => (
  <div>
    Loading {ucwords(object_name)}s for{" "}
    <strong>{location?.name ?? "All Locations"}</strong>
  </div>
)

export const EtaFinalizeRuleWarning = Styled(
  ({ className, is_edit, type = "rule" }) => (
    <Message className={className} type="info">
      {is_edit ? "Modifying" : "Creating"} this {type} will affect your ETA
      distribution. We recommend finalizing your pay period before making
      changes to tip distribution logic.
    </Message>
  )
)`
  margin-bottom: 1rem;
`
