import { Component } from "react"

import Styled from "styled-components"

import Icon from "../../Icon"
import Modal from "../../Modal"
import Button from "../../Button"
import Bar from "../../Charts/Bar"
import { Doughnut } from "../../Charts/Pie"

import { round } from "../../../helpers/number"

const CHART_TYPE_PIE = "pie"
const CHART_TYPE_BAR = "bar"
const MAX_PIE_CHART_RESULT_SIZE = 50

export default class extends Component {
  state = { chart_type: CHART_TYPE_PIE }
  chartData = () => {
    let { leaders } = this.props
    return Object.values(
      leaders
        .map(({ total, name }) => ({
          value: round(total, 2),
          label: name
        }))
        .reduce((acc, leader) => {
          if (!acc[leader.label]) {
            acc[leader.label] = { ...leader }
          } else {
            acc[leader.label].value += round(leader.value)
          }
          return acc
        }, {})
    )
  }

  canViewPie = () => {
    return this.chartData().length <= MAX_PIE_CHART_RESULT_SIZE
  }

  render() {
    const { chart_type } = this.state
    const { title, onClose } = this.props
    const can_show_pie = this.canViewPie()

    return (
      <Modal basic onClose={onClose}>
        <Modal.Header centered>{title}</Modal.Header>
        <Modal.Content>
          {!!can_show_pie && (
            <Controls
              showing={chart_type}
              onShowPie={() => this.setState({ chart_type: CHART_TYPE_PIE })}
              onShowBar={() => this.setState({ chart_type: CHART_TYPE_BAR })}
            />
          )}
          <ChartWrapper>
            {chart_type === CHART_TYPE_PIE && can_show_pie && (
              <Doughnut
                inverted
                options={{ aspectRatio: 1.5 }}
                data={this.chartData()}
              />
            )}
            {(chart_type === CHART_TYPE_BAR || !can_show_pie) && (
              <Bar inverted data={this.chartData()} />
            )}
          </ChartWrapper>
        </Modal.Content>
      </Modal>
    )
  }
}

const ChartWrapper = Styled.div``

const Controls = Styled(({ showing, onShowPie, onShowBar, className }) => (
  <div className={className}>
    <NavButton onClick={onShowPie} active={showing === CHART_TYPE_PIE}>
      Show Pie Chart
      <Icon name="pie chart" />
    </NavButton>
    <NavButton onClick={onShowBar} active={showing === CHART_TYPE_BAR}>
      Show Bar Chart
      <Icon name="bar chart" />
    </NavButton>
  </div>
))`
  text-align: center;
  margin-bottom: 2.5rem;
`

const NavButton = props => (
  <Button dark basic icon labelPosition="right" compact {...props} />
)
