import { useState } from "react"

import Styled from "styled-components"
import { Loader } from "semantic-ui-react"
import { Query } from "@apollo/client/react/components"

import {
  AlertGroup,
  MissedClockoutsAlert,
  InvalidTakeHomeAlert,
  NoneJobCodeSalesAlert,
  ExcessiveTipAlert
} from "../Alert"
import MetricsGrid from "./Metrics"
import RouteContent from "../RouteContent"
import { MetricsControl } from "./Metrics/Styled"
import CustomDatesModal from "./CustomDatesModal"

import tour from "./tour"
import {
  useBusinessDates,
  useExcessiveTipNotificationsEnabled,
  useNoneJobCodeSalesNotificationsEnabled
} from "../../hooks"
import { datetime_format } from "../../constants"
import { TOTALS_BY_EMPLOYEE } from "../../graphql/queries"
import {
  metricPanelOptions,
  LANDING_ROUTE_NAME,
  dateRangeFilterValues,
  DATE_RANGE_OPT_CUSTOM,
  dateRangeFilterOptions,
  storeMetricPanelOptions,
  LANDING_FILTER_NAMESPACE,
  landingFiltersWithDefaults,
  saveMetricsDisplaySettings,
  parseMetricsDisplaySettings,
  DATE_RANGE_OPT_CUSTOM_SELECTED,
  useShowMarketingBanner
} from "./helpers"
import { collapseSidebar } from "../../helpers"
import MarketingBanner from "./MarketingBanner"

const Header = Styled.div`
  margin-bottom: 1.33rem;
  font-size: 1.2rem;
  font-weight: 300;
`

export default props => {
  const { setRoutesVisited } = props

  return (
    <RouteContent
      header="Home"
      route_name={LANDING_ROUTE_NAME}
      tour={{
        ...tour,
        // close the sidebar if open
        onClose: () => requestAnimationFrame(() => collapseSidebar())
      }}
      setRoutesVisited={setRoutesVisited}
    >
      <LandingContent
        {...props}
        setLandingFilters={(filters, merge = true) =>
          props.setFiltersForNamespace(LANDING_FILTER_NAMESPACE, filters, merge)
        }
      />
    </RouteContent>
  )
}

// landing index content component
const LandingContent = ({
  user,
  app_filters,
  setLandingFilters,
  selected_location_id
}) => {
  const {
    businessDate,
    businessStart,
    businessEndFromDate,
    businessStartFromDate
  } = useBusinessDates()

  const [show_custom_dates_modal, setShowCustomDatesModal] = useState(false)
  const [display_metrics, _updateDisplayMetrics] = useState(() =>
    parseMetricsDisplaySettings(user)
  )
  const updateDisplayMetrics = display_metrics => {
    saveMetricsDisplaySettings(display_metrics, user)
    _updateDisplayMetrics([...display_metrics])
  }

  const filters = landingFiltersWithDefaults(app_filters)
  const { selected_date_range } = filters
  const { start_date, end_date } = dateRangeFilterValues(
    filters,
    user.organization.current_pay_period,
    user.organization.last_pay_period,
    businessDate(null, false)
  )

  const start_time = businessStartFromDate(start_date, false)
  const end_time = businessEndFromDate(end_date, false)

  const custom_range_applied = !!filters.custom_date_range_start

  const stores = user.organization.stores
  const {
    visible: marketing_banner_visible,
    onDismiss
  } = useShowMarketingBanner()
  const none_job_code_sales_notifications_enabled = useNoneJobCodeSalesNotificationsEnabled()
  const excessive_tip_alert_enabled = useExcessiveTipNotificationsEnabled()

  return (
    <>
      <Query
        fetchPolicy="cache-first"
        query={TOTALS_BY_EMPLOYEE}
        variables={{
          start_time: start_time.format(datetime_format),
          end_time: end_time.format(datetime_format),
          store_id: selected_location_id
        }}
      >
        {({ data, loading }) => {
          return (
            <>
              {marketing_banner_visible && (
                <MarketingBanner onDismiss={onDismiss} />
              )}
              <AlertGroup>
                <MissedClockoutsAlert
                  min_time={start_time}
                  max_time={end_time}
                />
                <InvalidTakeHomeAlert
                  min_time={start_time}
                  max_time={end_time}
                />
                {!!none_job_code_sales_notifications_enabled && (
                  <NoneJobCodeSalesAlert
                    min_time={start_time}
                    max_time={end_time}
                  />
                )}
                <ExcessiveTipAlert min_time={start_time} max_time={end_time} />
              </AlertGroup>
              <MetricsControl
                selected_metrics={[...display_metrics]}
                metrics_options={
                  // include store metric options for orgs with more than one store
                  stores.length > 1
                    ? storeMetricPanelOptions()
                    : metricPanelOptions()
                }
                onChangeDisplayed={updateDisplayMetrics}
                date_range_options={dateRangeFilterOptions(
                  user,
                  custom_range_applied
                    ? {
                        start_date: businessDate(
                          filters.custom_date_range_start,
                          false
                        ),
                        end_date: businessDate(
                          filters.custom_date_range_end,
                          false
                        )
                      }
                    : null
                )}
                selected_date_range={selected_date_range}
                onSelectDateRange={value => {
                  if (value === DATE_RANGE_OPT_CUSTOM) {
                    setShowCustomDatesModal(true)
                  } else {
                    setLandingFilters({
                      selected_date_range: value,
                      custom_date_range_start: null,
                      custom_date_range_end: null
                    })
                  }
                }}
              />
              {loading && display_metrics.length > 0 && (
                <Loader inline active />
              )}
              {display_metrics.length === 0 && (
                <Header>
                  Choose your dashboard display metrics from the dropdown above.
                </Header>
              )}
              {display_metrics.length > 0 &&
                !!data &&
                !!data.totalsByEmployee && (
                  <MetricsGrid
                    start={start_time}
                    end={end_time}
                    selected_location_id={selected_location_id}
                    displayed={display_metrics}
                    totals={data.totalsByEmployee}
                    updateSettings={updateDisplayMetrics}
                    removeSetting={setting =>
                      updateDisplayMetrics(
                        display_metrics.filter(s => s != setting)
                      )
                    }
                    request_includes_today={end_time.isAfter(businessStart())}
                    user={user}
                  />
                )}
            </>
          )
        }}
      </Query>
      {!!show_custom_dates_modal && (
        <CustomDatesModal
          onClose={() => setShowCustomDatesModal(false)}
          onSave={(start, end) => {
            setLandingFilters({
              selected_date_range: DATE_RANGE_OPT_CUSTOM_SELECTED,
              custom_date_range_start: businessStartFromDate(start),
              custom_date_range_end: businessStartFromDate(end)
            })
            setShowCustomDatesModal(false)
          }}
          start_time={businessDate(start_time, false)}
          end_time={businessDate(end_time, false)}
        />
      )}
    </>
  )
}
