import Styled from "styled-components"

import { Button } from "../Shared"

import { style, colors } from "../../constants"

export const HiddenBeacon = Styled.div`display: none;`

export const TooltipBody = Styled.div`
  font-family: ${style.font};
  position: relative;
  background-color: ${colors.white};
  border-radius: 5px;
  padding: 1.5rem 1.33rem 1.33rem 1.33rem;
  font-size: 1.15rem;
  max-width: 28rem;
  text-align: center;
`
export const TooltipTitle = Styled.div`
  font-size: 1.67rem;
  padding: 0 3rem 1.5rem;
  line-height: 2rem;
`
export const TooltipContent = Styled.div`
  font-weight: 300;
  padding: 0 1rem;
`
export const TooltipFooter = Styled.div`
  padding-top: 1.33rem;
  text-align: right;
  & > .button:last-child {
    margin-right: 0 !important;
  }
`
export const CloseButton = Styled(props => (
  <Button {...props} basic circular icon="remove" size="small" />
))`
  position: absolute;
  right: 1.33rem;
  top: 1.2rem;
  margin-right: 0 !important;
`
export const NextButton = Styled(({ title, ...props }) => (
  <Button {...props} secondary>
    {title}
  </Button>
))``
