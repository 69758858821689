import Styled from "styled-components"

import Dropdown from "./"

// Menu Dropdown
const MenuDropdown = Styled(
  ({
    text,
    value,
    options,
    min_width,
    className,
    prefix = false,
    compact = true,
    onChange = () => {},
    ...props
  }) => (
    <Dropdown
      {...props}
      className={className}
      compact={compact}
      selection
      text={
        !!text
          ? text
          : `${!!prefix ? `${prefix}: ` : ""}${
              options.find(option => option.value === value).text ?? ""
            }`
      }
      value={null}
      selectOnNavigation={false}
      selectOnBlur={false}
      onChange={(e, { value }) => onChange(value)}
      options={options.map(({ text, value: item_value, ...option }) => ({
        key: text.replace(/\s/g, "-").toLowerCase(),
        text,
        value: item_value,
        active: item_value === value,
        ...option
      }))}
    />
  )
)`
  vertical-align: top !important;
  min-height: 2rem !important;
  padding-top: .86rem !important;
  padding-bottom: .86rem !important;
  ${({ min_width = null }) =>
    !min_width ? "" : `min-width: ${min_width} !important;`};
  & > .dropdown.icon {
    top: 0.725rem !important;
  }
  &&&& .item {
    padding-top: 0.67rem !important;
    padding-bottom: 0.67rem !important;
    line-height: 1.4rem !important;
  }
`

// Actions Dropdown
export const ActionsDropdown = props => (
  <MenuDropdown text="Actions" {...props} />
)

export default MenuDropdown
