import Styled from "styled-components"
import { colors } from "../../../constants"
import { isValidEmail } from "../../../helpers"
import { Input, Link, Popup, Table } from "../../Shared"

const EmployeeEmailCell = props => (
  <Table.ActionCell>
    <EmployeeEmailInput {...props} />
  </Table.ActionCell>
)

const EmployeeEmailInput = Styled(({ className, email, onEditRequest }) => (
  <Popup
    hoverable
    content={
      <span>
        To edit this employees email, please go to the location settings page{" "}
        <Link onClick={onEditRequest}>HERE</Link>
      </span>
    }
  >
    <div className={className}>
      <Input
        fluid
        debounce
        highlightOnFocus
        value={email}
        disabled
        placeholder="employee email address"
        icon={isValidEmail(email) ? false : "exclamation circle"}
      />
    </div>
  </Popup>
))`
  & > .input {
    & > input {
      min-width: 10rem;
      background: #f3f3f3;
      border-color: #f3f3f3;
    }
    & > i.icon.exclamation {
      color: ${colors.danger} !important;
      opacity: 1 !important;
    }
    ${({ excluded }) =>
      !excluded &&
      `
      opacity: 1 !important;
    `};    
  }
`

export default EmployeeEmailCell
