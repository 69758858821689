import { useState } from "react"
import Styled from "styled-components"
import { Label, Loader } from "semantic-ui-react"
import { Query, Mutation } from "@apollo/client/react/components"
import moment from "moment"

import Table from "../../Table"
import Popup from "../../Popup"
import Message from "../../Message"
import Toggle from "../../Toggle"
import Input from "../../Shared/Input"
import Icon, { InfoIcon } from "../../Icon"
import { MenuDropdown } from "../../Dropdown"
import AreYouSure from "../../Modal/AreYouSure"
import Button, { IconButton } from "../../Button"
import EditJobCodeModal from "../../Modal/EditJobCode"
import CustomJobCodeModal from "../../Modal/CreateCustomJobCode"

import {
  UPDATE_JOB_CODE,
  ASSIGN_JOB_CODE_OVERRIDE,
  REQUEST_REPROCESS_STORES,
  MASS_UPDATE_JOB_CODE_SETTINGS
} from "../../../graphql/mutations"
import { useBusinessDates } from "../../../hooks"
import { JOB_CODE_SEARCH } from "../../../graphql/queries"
import { appMinDate } from "../../../helpers"
import { toInt } from "../../../helpers/number"
import { ucwords } from "../../../helpers/string"
import { project_info, date_format } from "../../../constants"
import useAuthorization from "../../../hooks/authorization"
import { settings_manage_job_codes } from "../../../constants/permissions"
import LabelList from "../../Shared/Label/LabelList"

const DEFAULT_PAGE = 1
const PER_PAGE = 25

const STATUS_ALL = "All"
const STATUS_ENABLED = "Enabled"
const STATUS_DISABLED = "Disabled"

const ACTION_RESYNC = "Resync Job Codes with Point-of-Sale"
const ACTION_CREATE_CUSTOM = "Create a Custom Job Code"

const COLSPAN_ALL_COLUMNS = 100
const CUSTOM_TOAST_TTL_MS = 2000

const Strong = Styled.span`font-weight: bold !important;`

// default export
export default ({ user, store, toast }) => {
  const { businessStartFromDate } = useBusinessDates()
  const { hasPermission } = useAuthorization()

  const [state, _setState] = useState({
    search: "",
    page: DEFAULT_PAGE,
    per_page: PER_PAGE,
    edit_job_code: false,
    toggled_job_codes: {},
    request_resync: false,
    store_id_filter: false,
    enabled_filter: STATUS_ALL,
    request_create_custom: false,
    remove_employee_assignment: false,
    delete_job_code: null,
    take_home_setting: {}
  })

  const setState = new_state => _setState({ ...state, ...new_state })

  const {
    page,
    search,
    per_page,
    staged_search,
    edit_job_code,
    request_resync,
    enabled_filter,
    toggled_job_codes,
    request_create_custom,
    remove_employee_assignment,
    delete_job_code,
    take_home_setting
  } = state

  const store_id_filter = store?.id ?? state.store_id_filter

  const displayed_filter =
    enabled_filter === STATUS_ENABLED
      ? true
      : enabled_filter === STATUS_DISABLED
      ? false
      : null

  const resolveTakeHomeEnabledChecked = job_codes => {
    let take_home_enabled = Object.values(job_codes).map(
      job_code => job_code.take_home_enabled
    )

    return take_home_enabled.every(element => element === true)
  }

  const resolveDistributionEnabledChecked = job_codes => {
    let displayed = Object.values(job_codes).map(job_code => job_code.displayed)

    return displayed.every(element => element === true)
  }

  const actionOptions = () => {
    let actions = [{ value: ACTION_RESYNC, text: ACTION_RESYNC }]

    if (hasPermission(settings_manage_job_codes)) {
      actions.push({ value: ACTION_CREATE_CUSTOM, text: ACTION_CREATE_CUSTOM })
    }

    return actions
  }

  return (
    <Query
      fetchPolicy="cache-and-network"
      notifyOnNetworkStatusChange
      query={JOB_CODE_SEARCH}
      variables={{
        page,
        first: per_page,
        filters: {
          active: true,
          exclude_none: true,
          store_id: !!store_id_filter ? store_id_filter : null,
          displayed: displayed_filter,
          search
        }
      }}
    >
      {({ loading, data, refetch }) => {
        let job_codes = []
        let total_job_codes = 0
        let paginator = {}
        let custom_controls = []
        try {
          job_codes = data.jobCodeSearch.data
          total_job_codes = data.jobCodeSearch.paginatorInfo.total
          paginator = {
            ...data.jobCodeSearch.paginatorInfo,
            current_page: data.jobCodeSearch.paginatorInfo.currentPage,
            per_page: per_page,
            options: { per_page: [25, 50, 100, 500] }
          }
        } catch (e) {}

        // table controls: job code search
        custom_controls = [
          <Input
            icon="search"
            iconPosition="left"
            action={
              search.length > 0 && !loading
                ? {
                    icon: <RemoveIcon />,
                    onClick: () => setState({ search: "" })
                  }
                : undefined
            }
            placeholder="Search..."
            value={staged_search}
            onChange={(e, { value }) => setState({ search: value })}
          />
        ]

        // table controls: store dropdown
        if (!store && user.stores.length > 1) {
          custom_controls.push(
            <MenuDropdown
              value={store_id_filter}
              options={[
                { value: false, text: "All Locations" },
                ...user.stores.map(({ id, name }) => ({
                  text: name,
                  value: id
                }))
              ]}
              onChange={value => setState({ store_id_filter: value, page: 1 })}
            />
          )
        }

        custom_controls.push(
          <MenuDropdown
            value={enabled_filter}
            prefix="Status"
            options={[
              { value: STATUS_ALL, text: STATUS_ALL },
              { value: STATUS_ENABLED, text: STATUS_ENABLED },
              { value: STATUS_DISABLED, text: STATUS_DISABLED }
            ]}
            onChange={value => setState({ enabled_filter: value, page: 1 })}
          />
        )

        // resync button
        custom_controls.push(
          <MenuDropdown
            text="Actions"
            options={actionOptions()}
            onChange={value => {
              switch (value) {
                case ACTION_RESYNC:
                  return setState({ request_resync: true })
                case ACTION_CREATE_CUSTOM:
                  return setState({ request_create_custom: true })
                default:
                  return
              }
            }}
          />
        )

        return (
          <JobCodesWrapper>
            <JobCodesTable
              controls={{
                position: total_job_codes > per_page ? "both" : "top",
                custom_controls_position: "top",
                pagination: {
                  ...paginator,
                  changePage: page => setState({ page }),
                  changePerPage: per_page => setState({ per_page, page: 1 })
                },
                custom: [...custom_controls]
              }}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  {!store_id_filter && user.stores.length > 1 && (
                    <Table.HeaderCell>Location</Table.HeaderCell>
                  )}
                  <Table.HeaderCell>
                    Tip Sharing Rules{" "}
                    <InfoIcon
                      popup={
                        <>
                          This column displays the name of any tip sharing rules
                          associated with each job.
                        </>
                      }
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Tip Pools{" "}
                    <InfoIcon
                      popup={
                        <>
                          This column displays the name of any tip pools
                          associated with each job.
                        </>
                      }
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Employee Assignments{" "}
                    <InfoIcon
                      popup={
                        <>
                          <p>
                            Job code assignments are used to override shift
                            information when associating an employee with a job
                            code.
                          </p>
                          <p>
                            You can configure job code assignments from the
                            Employees tab.
                          </p>
                        </>
                      }
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell id="job-code-settings-status">
                    Tip Distribution Enabled{" "}
                    <InfoIcon
                      popup={
                        <>
                          When disabled, the job code will not be available to
                          use with tip sharing rules and tip pools.
                        </>
                      }
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Take Home Enabled{" "}
                    <InfoIcon
                      popup={
                        <>
                          <p>
                            When disabled, you will receive notifications when
                            this job code has take home greater than zero.
                          </p>
                          <p>
                            You will no longer receive missed clock-out
                            notifications for this job code.
                          </p>
                        </>
                      }
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {!!loading && job_codes.length === 0 && (
                  <Table.Row>
                    <Table.Cell
                      textAlign="center"
                      colSpan={COLSPAN_ALL_COLUMNS}
                    >
                      <TableSpan>
                        <Loader inline active>
                          Loading...
                        </Loader>
                      </TableSpan>
                    </Table.Cell>
                  </Table.Row>
                )}
                {!loading && job_codes.length === 0 && (
                  <Table.Row>
                    <Table.Cell colSpan={COLSPAN_ALL_COLUMNS}>
                      <TableSpan>No job codes found.</TableSpan>
                    </Table.Cell>
                  </Table.Row>
                )}
                {(!loading || job_codes.length > 0) && (
                  <Table.Row secondary>
                    <Table.Cell>
                      <Strong>Toggle All</Strong>{" "}
                      <InfoIcon popup="Toggle all job codes on current page" />
                    </Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell />
                    <Table.Cell />
                    <Table.Cell>
                      <Mutation
                        mutation={MASS_UPDATE_JOB_CODE_SETTINGS}
                        onCompleted={({ massUpdateJobCodeSettings }) => {
                          let displayed = massUpdateJobCodeSettings[0].displayed
                          setState({
                            toggled_job_codes: {
                              [job_codes.map(
                                job_code => job_code.id
                              )]: displayed
                            }
                          })
                          toast({
                            type: "Success",
                            ttl_ms: CUSTOM_TOAST_TTL_MS,
                            message: (
                              <p>
                                All job codes will{" "}
                                {!!displayed ? `now` : `no longer`} be visible
                                when creating or modifying tip distribution
                                logic.
                              </p>
                            )
                          })
                          refetch()
                        }}
                      >
                        {(massUpdateJobCodeSettings, { loading }) => {
                          return (
                            <ToggleWrapper>
                              {!loading && (
                                <Popup
                                  content={<>Toggle all</>}
                                  trigger={
                                    <Toggle
                                      checked={resolveDistributionEnabledChecked(
                                        job_codes
                                      )}
                                      disabled={
                                        !hasPermission(
                                          settings_manage_job_codes
                                        )
                                      }
                                      onChange={(e, { checked }) => {
                                        massUpdateJobCodeSettings({
                                          variables: {
                                            ids: [
                                              ...job_codes.map(job_code =>
                                                toInt(job_code.id)
                                              )
                                            ],
                                            displayed: checked
                                          }
                                        })
                                      }}
                                    />
                                  }
                                />
                              )}
                              {!!loading && (
                                <Loader active inline size="small" />
                              )}
                            </ToggleWrapper>
                          )
                        }}
                      </Mutation>
                    </Table.Cell>
                    <Table.Cell>
                      <Mutation
                        mutation={MASS_UPDATE_JOB_CODE_SETTINGS}
                        onCompleted={({ massUpdateJobCodeSettings }) => {
                          let take_home_enabled =
                            massUpdateJobCodeSettings[0].take_home_enabled
                          setState({
                            take_home_setting: {
                              [job_codes.map(
                                job_code => job_code.id
                              )]: take_home_enabled
                            }
                          })
                          toast({
                            type: "Success",
                            ttl_ms: CUSTOM_TOAST_TTL_MS,
                            message: (
                              <p>
                                All job codes will{" "}
                                {!take_home_enabled ? `now` : `no longer`} have
                                notifications when take home is greater than
                                zero, and will{" "}
                                {take_home_enabled ? `now` : `not`} have missed
                                clock-out notifications.
                              </p>
                            )
                          })
                          refetch()
                        }}
                      >
                        {(massUpdateJobCodeSettings, { loading }) => {
                          return (
                            <ToggleWrapper>
                              {!loading && (
                                <Popup
                                  content={<>Toggle all</>}
                                  trigger={
                                    <Toggle
                                      checked={resolveTakeHomeEnabledChecked(
                                        job_codes
                                      )}
                                      disabled={
                                        !hasPermission(
                                          settings_manage_job_codes
                                        )
                                      }
                                      onChange={(e, { checked }) => {
                                        massUpdateJobCodeSettings({
                                          variables: {
                                            ids: [
                                              ...job_codes.map(job_code =>
                                                toInt(job_code.id)
                                              )
                                            ],
                                            take_home_enabled: checked
                                          }
                                        })
                                      }}
                                    />
                                  }
                                />
                              )}
                              {!!loading && (
                                <Loader active inline size="small" />
                              )}
                            </ToggleWrapper>
                          )
                        }}
                      </Mutation>
                    </Table.Cell>
                    <Table.Cell></Table.Cell>
                  </Table.Row>
                )}
                {(!loading || job_codes.length > 0) &&
                  job_codes.map(job_code => {
                    // single array of rules referenced by this job code
                    return (
                      <Table.Row key={job_code.id}>
                        <Table.Cell>
                          {job_code.name}
                          {!!job_code.is_custom && <CustomJobLabel />}
                        </Table.Cell>
                        {!store_id_filter && user.stores.length > 1 && (
                          <Table.Cell>{job_code.store.name}</Table.Cell>
                        )}
                        <Table.Cell>
                          {job_code.tipShareRules.length === 0 ? (
                            "-"
                          ) : (
                            <LabelList
                              items={job_code.tipShareRules.map(rule => ({
                                text: rule.name,
                                value: rule
                              }))}
                              max_labels={2}
                            />
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {job_code.tipPoolRules.length === 0 ? (
                            "-"
                          ) : (
                            <LabelList
                              items={job_code.tipPoolRules.map(rule => ({
                                text: rule.name,
                                value: rule
                              }))}
                              max_labels={2}
                            />
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {job_code.employeeAssignments.length === 0 ? (
                            "-"
                          ) : (
                            <LabelList
                              items={job_code.employeeAssignments.map(e => ({
                                text: `${e.first_name} ${e.last_name}`,
                                value: e
                              }))}
                              max_labels={2}
                              onRemove={
                                hasPermission(settings_manage_job_codes)
                                  ? employee =>
                                      setState({
                                        remove_employee_assignment: {
                                          employee,
                                          job_code
                                        }
                                      })
                                  : undefined
                              }
                            />
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          <Mutation
                            mutation={UPDATE_JOB_CODE}
                            onCompleted={({ updateJobCode }) => {
                              const { displayed } = updateJobCode
                              setState({
                                toggled_job_codes: {
                                  ...toggled_job_codes,
                                  [job_code.id]: displayed
                                }
                              })
                              toast({
                                type: "Success",
                                ttl_ms: CUSTOM_TOAST_TTL_MS,
                                message: (
                                  <p>
                                    "{job_code.name}" will{" "}
                                    {!!displayed ? `now` : `no longer`} be
                                    visible when creating or modifying tip
                                    distribution logic.
                                  </p>
                                )
                              })
                              refetch()
                            }}
                          >
                            {(updateJobCode, { loading }) => {
                              return (
                                <ToggleWrapper>
                                  {!loading && (
                                    <Popup
                                      content={
                                        <>
                                          Disabled job codes hidden when editing
                                          tip-sharing logic.
                                        </>
                                      }
                                      trigger={
                                        <Toggle
                                          checked={
                                            toggled_job_codes[job_code.id] !==
                                            undefined
                                              ? toggled_job_codes[job_code.id]
                                              : !!job_code.displayed
                                          }
                                          disabled={
                                            !hasPermission(
                                              settings_manage_job_codes
                                            )
                                          }
                                          onChange={(e, { checked }) => {
                                            updateJobCode({
                                              variables: {
                                                id: toInt(job_code.id),
                                                displayed: checked
                                              }
                                            })
                                          }}
                                        />
                                      }
                                    />
                                  )}
                                  {!!loading && (
                                    <Loader active inline size="small" />
                                  )}
                                </ToggleWrapper>
                              )
                            }}
                          </Mutation>
                        </Table.Cell>
                        <Table.Cell>
                          <Mutation
                            mutation={UPDATE_JOB_CODE}
                            onCompleted={({ updateJobCode }) => {
                              const { take_home_enabled } = updateJobCode
                              setState({
                                take_home_setting: {
                                  ...take_home_setting,
                                  [job_code.id]: take_home_enabled
                                }
                              })
                              toast({
                                type: "Success",
                                ttl_ms: CUSTOM_TOAST_TTL_MS,
                                message: (
                                  <p>
                                    "{job_code.name}" will{" "}
                                    {!take_home_enabled ? `now` : `no longer`}{" "}
                                    have notifications when take home is greater
                                    than zero, and will{" "}
                                    {take_home_enabled ? `now` : `not`} have
                                    missed clock-out notifications.
                                  </p>
                                )
                              })
                              refetch()
                            }}
                          >
                            {(updateJobCode, { loading }) => {
                              return (
                                <ToggleWrapper>
                                  {!loading && (
                                    <Popup
                                      content={
                                        <>
                                          Notifications will appear when this
                                          job code has take home greater than
                                          zero, and missed clock-out
                                          notifications will no longer appear.
                                        </>
                                      }
                                      trigger={
                                        <Toggle
                                          checked={
                                            take_home_setting[job_code.id] !==
                                            undefined
                                              ? take_home_setting[job_code.id]
                                              : !!job_code.take_home_enabled
                                          }
                                          disabled={
                                            !hasPermission(
                                              settings_manage_job_codes
                                            )
                                          }
                                          onChange={(e, { checked }) => {
                                            updateJobCode({
                                              variables: {
                                                id: toInt(job_code.id),
                                                take_home_enabled: checked
                                              }
                                            })
                                          }}
                                        />
                                      }
                                    />
                                  )}
                                  {!!loading && (
                                    <Loader active inline size="small" />
                                  )}
                                </ToggleWrapper>
                              )
                            }}
                          </Mutation>
                        </Table.Cell>
                        <Table.Cell>
                          {hasPermission(settings_manage_job_codes) && (
                            <EditButton
                              onClick={() => {
                                setState({ edit_job_code: job_code })
                              }}
                            />
                          )}
                          {hasPermission(settings_manage_job_codes) &&
                            !!job_code.is_custom && (
                              <ActionButton
                                negative
                                icon="trash alternate outline"
                                popup={{
                                  content: "Delete Custom Job Code",
                                  delay: true
                                }}
                                onClick={() => {
                                  setState({
                                    delete_job_code: job_code
                                  })
                                }}
                              />
                            )}
                        </Table.Cell>
                      </Table.Row>
                    )
                  })}
              </Table.Body>
            </JobCodesTable>
            {!!delete_job_code && (
              <Mutation
                mutation={UPDATE_JOB_CODE}
                onCompleted={() => {
                  setState({ delete_job_code: null })
                  toast({
                    type: "success",
                    ttl_ms: CUSTOM_TOAST_TTL_MS,
                    message: `Custom job code deleted successfully.`
                  })
                  refetch()
                }}
                onError={() => {
                  setState({ delete_job_code: null })
                  toast({
                    type: "error",
                    ttl_ms: CUSTOM_TOAST_TTL_MS,
                    message: `An error ocurred while attempting to delete custom job code.`
                  })
                  refetch()
                }}
              >
                {(updateJobCode, { loading }) => (
                  <>
                    <AreYouSure
                      header="Are you sure?"
                      submitted={loading}
                      body={
                        <>
                          <p>Do you want to delete {delete_job_code.name}?</p>
                          <Message type="info">
                            Note: You may need to rerun your Breakdown reports
                          </Message>
                        </>
                      }
                      onConfirm={() => {
                        updateJobCode({
                          variables: {
                            id: toInt(delete_job_code.id),
                            deleted: true,
                            name:
                              delete_job_code.name +
                              ":deleted:" +
                              new Date().getTime()
                          }
                        })
                      }}
                      onClose={() =>
                        this.setState({
                          delete_job_code: null
                        })
                      }
                    />
                  </>
                )}
              </Mutation>
            )}
            {!!edit_job_code && (
              <EditJobCodeModal
                toast={toast}
                job_code={edit_job_code}
                onCompleted={() => refetch()}
                onClose={() => setState({ edit_job_code: false })}
              />
            )}
            {!!remove_employee_assignment && (
              <Mutation
                mutation={ASSIGN_JOB_CODE_OVERRIDE}
                onCompleted={() => {
                  const { employee, job_code } = remove_employee_assignment
                  setState({ remove_employee_assignment: null })
                  toast({
                    type: "success",
                    ttl_ms: CUSTOM_TOAST_TTL_MS,
                    message: (
                      <>
                        <p>
                          "{ucwords(job_code.name)}" job code override
                          successfully removed from {employee.first_name}.
                        </p>
                        <p>
                          It may take a few minutes for updates to appear on the
                          breakdown page.
                        </p>
                      </>
                    )
                  })
                  refetch()
                }}
                onError={() => {
                  const { employee, job_code } = remove_employee_assignment
                  setState({ remove_employee_assignment: null })
                  toast({
                    type: "error",
                    ttl_ms: CUSTOM_TOAST_TTL_MS,
                    message: (
                      <>
                        <p>
                          An error occurred while trying to remove "
                          {ucwords(job_code.name)}" job code override from{" "}
                          {employee.first_name}.
                        </p>
                      </>
                    )
                  })
                }}
              >
                {(setEmployeeConstantJobCode, { loading }) => (
                  <AreYouSure
                    header="Remove Assigned Job Code"
                    submitted={!!loading}
                    body={
                      <Thin>
                        <p>
                          If you confirm, the "
                          {remove_employee_assignment.job_code.name}" job code
                          override will be removed from{" "}
                          {remove_employee_assignment.employee.first_name}.
                        </p>
                        <p>
                          {project_info.name} will reference employee shifts to
                          determine the appropriate job code for{" "}
                          {remove_employee_assignment.employee.first_name}'s
                          sales.
                        </p>
                      </Thin>
                    }
                    onClose={() =>
                      setState({ remove_employee_assignment: null })
                    }
                    onConfirm={() => {
                      setEmployeeConstantJobCode({
                        variables: {
                          employee_id: toInt(
                            remove_employee_assignment.employee.id
                          ),
                          reprocess_date: businessStartFromDate(
                            appMinDate(null, user)
                          )
                        }
                      })
                    }}
                  />
                )}
              </Mutation>
            )}
            {request_resync && (
              <Mutation
                mutation={REQUEST_REPROCESS_STORES}
                onCompleted={() => {
                  setState({ request_resync: false })
                  toast({
                    type: "success",
                    ttl_ms: CUSTOM_TOAST_TTL_MS,
                    message: (
                      <>
                        <p>
                          A resync is underway. This process may take a few
                          minutes to complete.
                        </p>
                      </>
                    )
                  })
                }}
                onError={() => {
                  setState({
                    request_resync: null
                  })
                  toast({
                    type: "error",
                    ttl_ms: CUSTOM_TOAST_TTL_MS,
                    message: (
                      <>
                        <p>
                          An error occurred while requesting to resync job
                          codes.
                        </p>
                      </>
                    )
                  })
                }}
              >
                {(reprocessStores, { loading }) => (
                  <AreYouSure
                    header="Resync Job Codes"
                    submitted={!!loading}
                    body={
                      <Thin>
                        <p>
                          By default, {project_info.name} syncs job code
                          information with your point-of-sale daily.
                        </p>
                        <p>
                          If you confirm, we'll kick-off an additional sync now.
                          It may take a few minutes for the process to complete.
                        </p>
                        <p>Do you want to resync job codes now?</p>
                      </Thin>
                    }
                    onClose={() => setState({ request_resync: false })}
                    onConfirm={() => {
                      reprocessStores({
                        variables: {
                          store_ids: !!store_id_filter
                            ? [store_id_filter]
                            : user.stores.map(({ id }) => id),
                          // fillter date arg
                          date: moment().format(date_format),
                          sync_static: true,
                          sync_dynamic: false
                        }
                      })
                    }}
                  />
                )}
              </Mutation>
            )}
            {request_create_custom && (
              <CustomJobCodeModal
                toast={toast}
                location_id={store_id_filter}
                onCompleted={() => refetch()}
                onClose={() => setState({ request_create_custom: false })}
              />
            )}
          </JobCodesWrapper>
        )
      }}
    </Query>
  )
}

// Styled components
const JobCodesWrapper = Styled.div`
  position: relative;
  font-size: 1rem;
`
const JobCodesTable = Styled(props => (
  <Table draggable compact unstackable {...props} />
))``
const TableSpan = Styled.div`
  padding: 0.67rem 0;
`
const CustomJobLabel = Styled(props => (
  <Label size="small" {...props}>
    Custom
  </Label>
))`
  margin-left: 0.5rem !important;
`
const RemoveIcon = ({ clickable = false, ...props }) => (
  <Icon name="remove" cursor={!!clickable ? "pointer" : "default"} {...props} />
)
const Thin = Styled.div`font-weight: 300;`
const ToggleWrapper = Styled.div`
  position: relative;
  display: inline-block;
  & > div {
    padding-top: 0.33rem;
  }
`
const EditButton = props => (
  <Button
    popup={{ content: "Edit Job Code", delay: true }}
    circular
    icon="edit"
    compact
    size="tiny"
    {...props}
  />
)

const ActionButton = props => (
  <IconButton basic circular icon compact size="tiny" {...props} />
)
