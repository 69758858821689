import { FilterRow, FilterCheckbox } from "../Styled"

export default ({ refunds, onChange }) => (
  <>
    <FilterRow name="Refunds">
      <FilterCheckbox
        label={
          <>
            Sales <strong>with</strong> refunds
          </>
        }
        checked={refunds === true}
        onChange={() => onChange(refunds ? null : true)}
      />
      <FilterCheckbox
        label={
          <>
            Sales <strong>without</strong> refunds
          </>
        }
        checked={refunds === false}
        onChange={() => onChange(refunds === false ? null : false)}
      />
    </FilterRow>
  </>
)
