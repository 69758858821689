import { useState } from "react"

import Styled from "styled-components"
import { Mutation } from "@apollo/client/react/components"

import TipFeeModal from "../Modal/TipFee"
import { Label, IconButton, AreYouSure, Loader } from "../Shared"

import { appMinDate, ccTipFeeSetting } from "../../helpers"
import { UPDATE_TIP_FEE_ORGANIZATION_SETTING } from "../../graphql/mutations"
import { useBusinessDates, useToast, useUser } from "../../hooks"

const TipFeeResult = Styled.div``
const SettingHeader = Styled(({ className, label }) => (
  <div className={className}>
    <Label>{label}</Label>
  </div>
))``
const SettingValue = Styled.div`
  line-height: 2rem;
`

const TipFeeControls = Styled(
  ({ tip_fee, onRequestEdit, onRequestRemove, className }) => (
    <div className={className}>
      <IconButton
        icon="edit"
        secondary
        onClick={onRequestEdit}
        popup={{
          content: "Edit your organization's CC tip fee percentage.",
          delay: true
        }}
      >
        Edit
      </IconButton>
      {!!tip_fee && (
        <IconButton
          icon="remove"
          onClick={onRequestRemove}
          popup={{
            content:
              "Remove your organization's default CC tip fee percentage.",
            delay: true
          }}
        >
          Remove
        </IconButton>
      )}
    </div>
  )
)`
  padding: 1.5rem 0;
`

export default ({ syncUser, syncing_user }) => {
  const user = useUser()
  const toast = useToast()
  const [request_edit, setRequestEdit] = useState(false)
  const [request_remove, setRequestRemove] = useState(false)
  const { businessStartFromDate } = useBusinessDates()
  const cc_tip_fee = ccTipFeeSetting(user.organization.settings)

  return (
    <>
      {!!syncing_user && <Loader />}
      {!syncing_user && (
        <Mutation
          mutation={UPDATE_TIP_FEE_ORGANIZATION_SETTING}
          onCompleted={e => {
            const success = !!e.updateTipFeeOrganizationSetting
            toast({
              type: !!success ? "success" : "danger",
              message: !!success
                ? "CC Tip Fee setting updated successfully. " +
                  "It may take some time for changes to appear in your Breakdown report."
                : "Failed to update CC Tip Fee settings."
            })
            syncUser()
            setRequestEdit(false)
            setRequestRemove(false)
          }}
        >
          {(updateTipFeeOrganizationSetting, { loading }) => {
            return (
              <TipFeeResult>
                <SettingHeader label="Organization Default" />
                <SettingValue>
                  {!cc_tip_fee && "Don't apply a fee to credit card tips."}
                  {!!cc_tip_fee &&
                    `${cc_tip_fee}% fee applying to credit card tips.`}
                </SettingValue>
                <TipFeeControls
                  tip_fee={cc_tip_fee}
                  onRequestEdit={() => setRequestEdit(true)}
                  onRequestRemove={() => setRequestRemove(true)}
                />
                {request_edit && (
                  <TipFeeModal
                    submitted={!!loading}
                    onSubmit={value => {
                      let variables = {
                        value: parseFloat(value).toString()
                      }
                      updateTipFeeOrganizationSetting({ variables })
                    }}
                    onClose={() => setRequestEdit(false)}
                    cc_tip_fee={cc_tip_fee}
                  />
                )}
                {request_remove === true && (
                  <AreYouSure
                    header="Are you sure?"
                    confirmText="Submit"
                    body={
                      <>
                        <p>
                          If you confirm, the{" "}
                          {user.stores.length > 1 && `default `}credit card tip
                          fee will be removed.{" "}
                          {user.stores.length > 1 && (
                            <>
                              Customizations for individual locations will still
                              apply.
                            </>
                          )}
                        </p>
                        <p>
                          It may take a few minutes for updated totals to appear
                          in the breakdown.
                        </p>
                      </>
                    }
                    submitted={!!loading}
                    onClose={() => setRequestRemove(false)}
                    onConfirm={() => {
                      let variables = {
                        value: "0",
                        reprocess_date: businessStartFromDate(
                          appMinDate(null, user)
                        )
                      }
                      updateTipFeeOrganizationSetting({ variables })
                    }}
                  />
                )}
              </TipFeeResult>
            )
          }}
        </Mutation>
      )}
    </>
  )
}
