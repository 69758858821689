import { Component } from "react"

import Navbar from "./Navbar"
import Sidebar from "./Sidebar"
import Footer from "./Footer"
import RoutePlaceholder from "./RoutePlaceholder"
import { ContentWrapper, NightMode, ToastsWrapper } from "./Styled"
import Toast, {
  TOAST_LONG_TTL_MS,
  TOAST_DEFAULT_TTL_MS,
  DELETE_TOAST_DELAY_MS
} from "../Toast"

// modals
import FirstLoginModal from "../Modal/FirstLogin"
import UpdatedTermsModal from "../Modal/UpdatedTerms"
import OrgOnboardingModal from "../Modal/OrgOnboarding"
import PendingOrgSyncModal from "../Modal/PendingOrgSync"
import ETAIntroModal from "../EarnedTipAccess/Introduction/Modal"

import {
  intercomEnabled,
  completedOrgOnboarding,
  completedUserOnboarding,
  hasCompletedInitialSync,
  checkToShowTermsOfService
} from "../../helpers"
import ApplicationProvider from "../../context"
import { LAYOUT_CONTAINER_ID } from "../../constants"
import { hasEnterpriseLevelRole, hasOrgLevelRole } from "../../helpers/user"

class LayoutContent extends Component {
  state = {
    sidebar_active: false,
    show_updated_terms: false,
    should_onboard_user: null,
    should_onboard_org: null
  }

  componentDidMount() {
    const { user } = this.props

    const should_onboard_user = !completedUserOnboarding(user)
    // TODO: hasPermission("org-onboarding")
    const should_onboard_org =
      !completedOrgOnboarding(user) &&
      (hasOrgLevelRole(user) || hasEnterpriseLevelRole(user))

    this.setState({
      should_onboard_user,
      should_onboard_org
    })

    if (!user.is_backdoor && !should_onboard_user) {
      checkToShowTermsOfService(user)
    }
  }

  toggleSidebar = state => {
    if (state === true) {
      this.setState({ sidebar_active: true })
    } else if (state === false) {
      this.setState({ sidebar_active: false })
    } else {
      this.setState({ sidebar_active: !this.state.sidebar_active })
    }
  }

  render() {
    const {
      children,
      toasts,
      clearToast,
      mountToast,
      unmountToast,
      ...props
    } = this.props
    const {
      sidebar_active,
      show_updated_terms,
      should_onboard_org,
      should_onboard_user
    } = this.state

    const completed_initial_sync = hasCompletedInitialSync(props.user)

    return (
      <>
        <div id={LAYOUT_CONTAINER_ID}>
          <ApplicationProvider
            toast={this.props.toast}
            navigateTo={this.props.navigateTo}
            setApplicationFilters={this.props.setApplicationFilters}
            changeSelectedLocation={this.props.changeSelectedLocation}
          >
            <Sidebar
              navigateTo={this.props.navigateTo}
              isMobile={props.isMobile}
              expanded={sidebar_active}
              toggle={this.toggleSidebar}
              pathname={props.location.pathname}
            />
            <ContentWrapper expanded={sidebar_active}>
              <Navbar {...this.props} />
              <NightMode night_mode={props.night_mode}>
                {should_onboard_user === false &&
                should_onboard_org === false &&
                show_updated_terms === false &&
                completed_initial_sync === true ? (
                  <>{children}</>
                ) : (
                  <RoutePlaceholder />
                )}
              </NightMode>
              {/* application toasts */}
              {toasts.length > 0 && (
                <ToastsWrapper intercom_enabled={intercomEnabled(props.user)}>
                  {toasts
                    .slice(-2)
                    .map(({ id, ttl_ms, long = false, ...toast }) => (
                      <Toast
                        key={id}
                        {...toast}
                        clear={() => {
                          unmountToast(id)
                          setTimeout(
                            () => clearToast(id),
                            DELETE_TOAST_DELAY_MS
                          )
                        }}
                        onMount={() => {
                          mountToast(id)
                          setTimeout(() => {
                            unmountToast(id)
                            setTimeout(
                              () => clearToast(id),
                              DELETE_TOAST_DELAY_MS
                            )
                          }, ttl_ms ?? (long ? TOAST_LONG_TTL_MS : TOAST_DEFAULT_TTL_MS))
                        }}
                      />
                    ))}
                </ToastsWrapper>
              )}
              <Footer {...this.props} />
              <ETAIntroModal />
            </ContentWrapper>
          </ApplicationProvider>
          {should_onboard_user === true && (
            <FirstLoginModal
              {...this.props}
              onCompleted={() => this.setState({ should_onboard_user: false })}
            />
          )}
          {should_onboard_user === false && (
            <>
              {should_onboard_org === true && (
                <OrgOnboardingModal
                  {...this.props}
                  onCompleted={() => {
                    this.setState({ should_onboard_org: false })
                    props.syncUser()
                  }}
                />
              )}
              {should_onboard_org === false && (
                <>
                  {!!show_updated_terms && (
                    <UpdatedTermsModal
                      {...show_updated_terms}
                      onCompleted={() =>
                        this.setState({ show_updated_terms: false })
                      }
                    />
                  )}
                  {!show_updated_terms && completed_initial_sync === false && (
                    <PendingOrgSyncModal syncUser={props.syncUser} />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </>
    )
  }
}

export default LayoutContent
