import { FilterRow, FilterValue } from "../Styled"
import LocationSearch from "../../../Search/Location"

import { useLocations } from "../../../../hooks"
import { formatListForSentence } from "../../../../helpers"
import { OBJECT_NAME_TIP_POOL } from "../../helpers"

export default ({
  location,
  object_name,
  onChange,
  dependent_filters = {}
}) => {
  const available_locations = useLocations()
  const applied_dependencies = Object.keys(dependent_filters)
    .filter(key => !!dependent_filters[key])
    .map(filter => filter.replace(/_/g, " "))
    // translate "distribute to" filter to "job code" label for tip pools
    .map(filter =>
      object_name === OBJECT_NAME_TIP_POOL && filter === "distribute to"
        ? "job code"
        : filter
    )

  let disabled_msg = null
  if (applied_dependencies.length) {
    disabled_msg = (
      <>
        To modify this filter, first clear the{" "}
        {formatListForSentence(applied_dependencies)} filter
        {applied_dependencies.length > 1 && "s"}.
      </>
    )
  }

  return (
    <FilterRow name="Location">
      {!!location && (
        <FilterValue
          value={location.name}
          disabled_message={disabled_msg}
          onRemove={() => onChange(null)}
        />
      )}
      {!location && (
        <>
          <LocationSearch
            aligned="right"
            locations={available_locations}
            onChange={({ location }) => onChange({ ...location })}
          />
        </>
      )}
    </FilterRow>
  )
}
