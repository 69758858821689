import { useState, useEffect } from "react"
import { Form } from "semantic-ui-react"
import { reporting_view } from "../../../../../../../constants/permissions"
import useAuthorization from "../../../../../../../hooks/authorization"
import { toggleManagePermissions } from "../helpers"
import { useVisibleReportingPermissions } from "../helpers"
import { TogglePermissionsTable, ToggleField } from "../Styled"

export const reportingStep = ({ permissions, edit_mode }, onChange) => {
  const [visited, setVisited] = useState(false)
  return {
    name: "Reporting",
    complete: !edit_mode || visited,
    render: () => (
      <Reporting
        permissions={permissions}
        edit_mode={edit_mode}
        onChange={new_state =>
          onChange({
            permissions: {
              ...permissions,
              ...new_state
            }
          })
        }
        onMount={() => setVisited(true)}
      />
    )
  }
}

export const Reporting = ({ permissions, edit_mode, onChange, onMount }) => {
  const { hasPermission } = useAuthorization()
  const reporting_permissions = useVisibleReportingPermissions()
  const reporting_manage_permissions = reporting_permissions.filter(
    ({ name }) => name !== reporting_view
  )
  // reporting manage permissions the user has access to
  const allowed_manage_permissions = reporting_manage_permissions.filter(
    ({ disabled }) => !disabled
  )
  // determines the checked state for Manage Reporting toggle
  const all_manage_selected =
    !!allowed_manage_permissions.length &&
    allowed_manage_permissions.every(
      permission => !!permissions[permission.name]
    )

  useEffect(() => onMount(), [])
  return (
    <>
      <Form>
        <ToggleField
          checked={permissions[reporting_view]}
          disabled={!edit_mode || !hasPermission(reporting_view)}
          label="Reporting Tab Visible"
          onChange={checked => {
            let new_state = { [reporting_view]: checked }
            // auto-uncheck all reporting permissions when de-selecting view
            if (!checked) {
              new_state = {
                ...new_state,
                // de-select all allowed reporting manage permissions
                ...toggleManagePermissions(allowed_manage_permissions, checked)
              }
            }
            onChange(new_state)
          }}
        />
        <ToggleField
          checked={all_manage_selected}
          disabled={!edit_mode || !hasPermission(reporting_view)}
          label="Manage Reporting"
          onChange={checked => {
            // toggle all allowed reporting manage permissions
            let new_state = toggleManagePermissions(
              allowed_manage_permissions,
              checked
            )
            // auto-check the view permission when selecting manage
            if (checked) {
              new_state = {
                ...new_state,
                [reporting_view]: checked
              }
            }
            onChange(new_state)
          }}
        />
      </Form>
      <TogglePermissionsTable
        rows={reporting_manage_permissions}
        selected={permissions}
        onChange={(value, checked) => {
          let new_state = { [value]: checked }
          // auto-check the view permission when selecting a permission
          if (checked) {
            new_state = {
              ...new_state,
              [reporting_view]: checked
            }
          }
          onChange(new_state)
        }}
        edit_mode={edit_mode}
      />
    </>
  )
}
