import { FilterBar } from "../Shared"
import { TableLoader } from "./Styled"
import AdvancedSearch from "./AdvancedSearch"
import SelectedFilter from "../Shared/FilterBar/Filters/Selected"

import { sortOptions } from "./AdvancedSearch/Filters/SortBy"
import { FILTERS_TEMPLATE, OBJECT_NAME_TIP_SHARE } from "./helpers"
import { useOrgIsMultiUnit, useSelectedLocation } from "../../hooks"

export default ({
  loading,
  filters,
  setFilters,
  object_name = OBJECT_NAME_TIP_SHARE,
  ...props
}) => {
  const selected_location = useSelectedLocation()
  const custom_filters = generateFilterBarCustomFilters({
    selected_location,
    object_name,
    applied_filters: filters,
    setFilters
  })

  return (
    <FilterBar
      {...props}
      id="rule-filter-bar"
      location_id={selected_location?.id}
      status_filter={filters.status}
      sort={{
        field: filters.sort_field ?? null,
        direction: filters.sort_direction ?? null,
        options: sortOptions(object_name),
        onChange: (sort_field, sort_direction) =>
          setFilters({ sort_field, sort_direction })
      }}
      custom_filters={custom_filters}
      onChange={({ status_filter }) => {
        setFilters({
          status: status_filter
        })
      }}
      loading={loading}
      loading_msg={
        <TableLoader object_name={object_name} location={selected_location} />
      }
    />
  )
}

const generateFilterBarCustomFilters = ({
  selected_location,
  object_name,
  applied_filters,
  setFilters
}) => {
  const {
    revenue_center,
    distribute_from,
    distribute_to,
    distribution_source
  } = applied_filters
  let filters = []

  const is_multi_unit = useOrgIsMultiUnit()

  const source_filter_enabled = Object.values(distribution_source).some(
    val => val === true
  )

  if (selected_location && is_multi_unit) {
    filters.push({
      label: "Location",
      filter: (
        <SelectedFilter
          value={selected_location.name}
          onRemove={() =>
            setFilters({
              location: null,
              revenue_center: null,
              distribute_from: null,
              distribute_to: null
            })
          }
        />
      )
    })
  }
  if (revenue_center) {
    filters.push({
      label: "Revenue Center",
      filter: (
        <SelectedFilter
          value={revenue_center.name}
          onRemove={() => setFilters({ revenue_center: null })}
        />
      )
    })
  }
  if (object_name === OBJECT_NAME_TIP_SHARE && distribute_from) {
    filters.push({
      label: "Collect From",
      filter: (
        <SelectedFilter
          value={distribute_from.name}
          onRemove={() => setFilters({ distribute_from: null })}
        />
      )
    })
  }
  if (distribute_to) {
    filters.push({
      label:
        object_name === OBJECT_NAME_TIP_SHARE ? "Distribute To" : "Job Codes",
      filter: (
        <SelectedFilter
          value={distribute_to.name}
          onRemove={() => setFilters({ distribute_to: null })}
        />
      )
    })
  }
  if (source_filter_enabled) {
    filters.push({
      label: "Distribution Source",
      filter: (
        <SelectedFilter
          value={getSourceKeysApplied(distribution_source)}
          onRemove={() =>
            setFilters({
              distribution_source: FILTERS_TEMPLATE["distribution_source"],
              source_names: []
            })
          }
        />
      )
    })
  }

  filters.push({
    filter: (
      <AdvancedSearch
        applied_filters={prepareFilterDefaults({
          location: !selected_location?.id
            ? null
            : {
                id: selected_location?.id,
                name: selected_location?.name
              },
          ...applied_filters
        })}
        object_name={object_name}
        onSubmit={setFilters}
      />
    )
  })

  return filters
}

const prepareFilterDefaults = (filters = {}) => {
  if (!filters.location) {
    if (filters.employee?.store?.id && filters.employee?.store?.name) {
      return {
        ...filters,
        location: {
          id: Number(filters.employee.store.id),
          name: filters.employee.store.name
        }
      }
    }
  }
  return filters
}

const getSourceKeysApplied = obj => {
  let keys = Object.keys(obj).filter(k => obj[k] === true)
  if (keys.length > 1) {
    return keys[0].concat("...")
  }
  return keys[0]
}
