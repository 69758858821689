import moment from "moment"
import Styled from "styled-components"

import Table from "./Table"
import FilterBar from "../../Shared/FilterBar"
import FeatureDisabled from "./FeatureDisabled"
import { Loader, Message } from "../../Shared"
import SelectedFilter from "../../Shared/FilterBar/Filters/Selected"

import { PEER_TO_PEER_TRANSACTIONS } from "../../../graphql/queries"
import { date_format } from "../../../constants"
import { p2pEnabled } from "../../../helpers/settings/organization"
import { useOrgIsMultiUnit, useSelectedLocation } from "../../../hooks"
import { SelectLocationConsumer } from "../../../context/selectLocation"
import { useQuery } from "@apollo/client"

const NoResultsMessage = Styled(props => (
  <Message inline type="info" title="No Transactions Found" {...props}>
    No Peer-to-Peer Tip Sharing transactions found for the current filters. Try
    expanding the date range for a broader search.
  </Message>
))`
  margin: 0.3rem 0;
`

const FilterBarLoader = ({ start_time, end_time }) => (
  <div>
    Loading Shared Tips for{" "}
    <strong>{moment(start_time).format("dddd, MMMM Do")}</strong>{" "}
    {moment(start_time).format(date_format) !==
      moment(end_time).format(date_format) && (
      <>
        through <strong>{moment(end_time).format("dddd, MMMM Do")}</strong>
      </>
    )}
  </div>
)

const PAGE = 1
const PER_PAGE = 25

export default ({
  user,
  presets,
  report_filters,
  setReportFilters,
  selected_location_id,
  //biz dates
  businessDate,
  businessStartFromDate
}) => {
  const custom_filters = generateCustomFilters()
  const p2p_enabled = p2pEnabled(user.organization.settings)

  if (p2p_enabled === null) {
    return <Loader />
  } else if (!p2p_enabled) {
    return <FeatureDisabled />
  }

  const { data, loading } = useQuery(PEER_TO_PEER_TRANSACTIONS, {
    variables: {
      page: report_filters.page,
      first: report_filters.per_page,
      store_id: !!selected_location_id ? selected_location_id : null,
      start_date: businessDate(report_filters.start_time),
      end_date: businessDate(report_filters.end_time),
      employee_id: report_filters.employee?.id ?? null
    }
  })
  const result = data?.peerToPeerTransactions?.data ?? null
  const { total = 0 } = data?.peerToPeerTransactions?.paginatorInfo ?? {}
  return (
    <>
      <FilterBar
        location_id={selected_location_id}
        presets={presets}
        start_date={report_filters.start_time}
        end_date={report_filters.end_time}
        employee={{
          employee: report_filters.employee ?? null,
          onSelect: employee => setReportFilters({ employee }),
          onRemove: () => setReportFilters({ employee: null })
        }}
        onChange={({ start_date, end_date }) => {
          setReportFilters({
            start_time: businessStartFromDate(start_date),
            end_time: businessStartFromDate(end_date)
          })
        }}
        custom_filters={custom_filters}
        loading={!!loading}
        loading_msg={
          <FilterBarLoader
            start_time={report_filters.start_time}
            end_time={report_filters.end_time}
          />
        }
      />
      {!loading && !!result && result.length === 0 && <NoResultsMessage />}
      {!loading && !!result && result.length > 0 && (
        <Table
          selected_location_id={selected_location_id}
          transactions={result}
          draggable={true}
          singleLine
          unstackable
          compact
          controls={{
            position: result.length > report_filters.per_page ? "both" : "top",
            custom_controls_position: "top",
            pagination: {
              current_page: report_filters.page,
              per_page: report_filters.per_page,
              total,
              include_total: true,
              changePage: page => setReportFilters({ page }),
              changePerPage: per_page =>
                setReportFilters({ per_page, page: 1 }),
              options: {
                per_page: [25, 50, 100, 500]
              }
            }
          }}
        />
      )}
    </>
  )
}

const generateCustomFilters = () => {
  const is_multi_unit = useOrgIsMultiUnit()
  const selected_location = useSelectedLocation()
  let filters = []

  if (selected_location && is_multi_unit) {
    filters.push({
      label: "Location",
      filter: (
        <SelectLocationConsumer>
          {selectLocation => (
            <SelectedFilter
              value={selected_location.name}
              onRemove={() => selectLocation(null)}
            />
          )}
        </SelectLocationConsumer>
      )
    })
  }

  return filters
}
