import { useState, useEffect } from "react"

import Item from "./Item"
import Submenu from "./Submenu"
import FooterControl from "./FooterControl"
import { SidebarWrapper } from "./Styled"

import { paths } from "./../../../constants"
import { useLocations, useCanViewEtaModule } from "../../../hooks"
import useAuthorization from "../../../hooks/authorization"
import {
  breakdown_view,
  eta_view,
  reporting_view,
  rules_view,
  settings_view
} from "../../../constants/permissions"

const SIDEBAR_ID = "sidebar-wrapper"

export default ({ navigateTo, pathname, toggle, expanded, isMobile }) => {
  const locations = useLocations()
  const eta_visible = useCanViewEtaModule()
  const { hasPermission } = useAuthorization()

  const [select_store_active, setSelectStoreActive] = useState(false)

  const mobileClose = () => {
    if (isMobile) {
      setSelectStoreActive(false)
      toggle(false)
    }
  }

  const navigateAndMobileClose = path => {
    navigateTo(path)
    mobileClose()
  }

  // set expanded to false when changing layout
  useEffect(() => {
    setSelectStoreActive(false)
    toggle(false)
  }, [isMobile])

  return (
    <SidebarWrapper id={SIDEBAR_ID} expanded={expanded}>
      {(!!expanded || !isMobile) && (
        <>
          <Item
            header
            label="Menu"
            data_tip="Expand Menu"
            icon={!!expanded ? "bars" : "bars"}
            expanded={expanded}
            onClick={toggle}
          />
          {!!isMobile && locations.length > 1 && (
            <>
              <Item
                label="Select a Location"
                icon="location arrow"
                expanded={expanded}
                active={select_store_active}
                onClick={() => setSelectStoreActive(!select_store_active)}
              />
              <Submenu
                open={select_store_active}
                items={locations}
                toggle={mobileClose}
              />
            </>
          )}
          <Item
            label="Home"
            data_tip="Home"
            icon="home"
            expanded={expanded}
            active={pathname === paths.index}
            onClick={() => navigateAndMobileClose(paths.index)}
            onAuxClick={() => window.open(paths.index)}
          />
          <Item
            label="Tip Distribution"
            data_tip="Tip Distribution Logic"
            icon="pie chart"
            expanded={expanded}
            active={pathname.indexOf(paths.distributionRules) > -1}
            visible={hasPermission(rules_view)}
            onClick={() => navigateAndMobileClose(paths.distributionRules)}
            onAuxClick={() => window.open(paths.distributionRules)}
          />
          <Item
            label="Breakdown"
            data_tip="Breakdown"
            icon="table"
            expanded={expanded}
            active={pathname.indexOf(paths.breakdown) === 0}
            visible={hasPermission(breakdown_view)}
            onClick={() => navigateAndMobileClose(paths.breakdown)}
            onAuxClick={() => window.open(paths.breakdown)}
          />
          <Item
            label="ETA"
            data_tip="Earned Tip Access"
            icon="credit card"
            expanded={expanded}
            active={pathname.indexOf(paths.earnedTipAccess) > -1}
            visible={eta_visible && hasPermission(eta_view)}
            onClick={() => navigateAndMobileClose(paths.earnedTipAccess)}
            onAuxClick={() => window.open(paths.earnedTipAccess)}
          />
          <Item
            label="Reporting"
            data_tip="Reporting"
            icon="clipboard outline"
            expanded={expanded}
            active={pathname.indexOf(paths.report) > -1}
            visible={hasPermission(reporting_view)}
            onClick={() => navigateAndMobileClose(paths.report)}
            onAuxClick={() => window.open(paths.report)}
          />
          <Item
            label="Settings"
            data_tip="Settings"
            icon="cog"
            expanded={expanded}
            active={pathname.indexOf(paths.settings) > -1}
            visible={hasPermission(settings_view)}
            onClick={() => navigateAndMobileClose(paths.settings)}
            onAuxClick={() => window.open(paths.settings)}
          />
        </>
      )}
      <FooterControl isMobile={isMobile} expanded={expanded} onClick={toggle} />
    </SidebarWrapper>
  )
}
