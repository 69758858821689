import { useMemo, useState } from "react"
import { isValidEmail } from "../../helpers"

export const SHOW_BATCH_CONTROLS_THRESHOLD = 5

export const employeeSearch = (employees, search) =>
  employees.filter(invite => {
    const clean_search = search?.trim()?.toLowerCase() ?? ""
    return (
      clean_search === "" ||
      invite?.name?.toLowerCase()?.includes(clean_search) ||
      invite?.email?.toLowerCase()?.includes(clean_search)
    )
  })

export const useEmployeeInvites = employees => {
  const [included, setIncluded] = useState([])

  const valid_invites = useMemo(() => getValidInvites(employees, included), [
    employees,
    included
  ])

  const onExclude = (employee, excluded = true) => {
    if (excluded) {
      setIncluded(included.filter(e => e != employee.id))
    } else {
      setIncluded([...included, employee.id])
    }
  }

  return { valid_invites, included, onExclude }
}

export const getValidInvites = (employees, included) =>
  employees.filter(
    employee => included.includes(employee.id) && isValidEmail(employee.email)
  )
