import { useState, useEffect } from "react"
import { EtaFinalizeRuleWarning } from "../../../../Styled"
import { useShowEtaFinalizeRuleWarning } from "../../../../helpers"

import SummaryTable from "../../../Summary/Table"

// step entrypoint
export const reviewStep = pool => {
  const [completed, setCompleted] = useState(
    pool.editor_mode === "edit" ? true : false
  )

  return {
    name: "Review",
    complete: completed,
    render: () => <Review {...pool} onMount={() => setCompleted(true)} />
  }
}

const Review = ({ onMount, ...pool }) => {
  // set step completed on mount
  useEffect(() => onMount(), [])

  const show_finalize_warning = useShowEtaFinalizeRuleWarning(
    pool.effective_date
  )
  return (
    <>
      {show_finalize_warning && (
        <EtaFinalizeRuleWarning
          is_edit={pool.editor_mode === "edit"}
          type="pool"
        />
      )}
      <SummaryTable {...pool} />
    </>
  )
}
