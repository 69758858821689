import Styled from "styled-components"

export const cssTransition = (
  attribute = "all",
  duration = "0.2s",
  animation = "ease-out"
) => `
  transition: ${attribute} ${duration} ${animation};
`

export const css_disable_text_selection = `
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`
export const cssTextSelectionDisabled = component => Styled(component)`
  ${css_disable_text_selection}
`

export const Relative = Styled.div`position:relative;`
export const Thin = Styled.span`font-weight:300;`
export const Strong = Styled.span`font-weight:600;`
