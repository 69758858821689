import React, { useState } from "react"

import moment from "moment"
import { IconButton } from "../../../../Button"
import AreYouSure from "../../../../Modal/AreYouSure"
import { downloadFile } from "../../../../../helpers/api"
import { useBusinessDates } from "../../../../../hooks"
import { month_day_format, datetime_format } from "../../../../../constants"

const handleExportPayouts = async (filters, setDownloading) => {
  setDownloading(true)
  await downloadFile("payouts/export", filters)
  setDownloading(false)
}

export default ({ onDownloadStart, filters, selected_location = null }) => {
  const [request_download, setRequestDownload] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const { business_start_hour } = useBusinessDates()
  let updatedFilters = {
    ...filters,
    start: moment(filters.start_date)
      .hour(business_start_hour)
      .format(datetime_format),
    end: moment(filters.end_date)
      .add(1, "day")
      .hour(business_start_hour)
      .format(datetime_format),
    store_id: selected_location?.id
  }

  return (
    <>
      <IconButton
        secondary
        icon="cloud download"
        loading={!!downloading}
        disabled={!!downloading}
        onClick={() => setRequestDownload(true)}
      >
        Export
      </IconButton>
      {!!request_download && (
        <AreYouSure
          header="Export Payouts?"
          body={
            <>
              <p>
                Would you like to download the payouts for <br />
                {moment(filters.start_date).format(month_day_format)}
                {" - "}
                {moment(filters.end_date).format(month_day_format)}?
              </p>
            </>
          }
          onConfirm={() => {
            onDownloadStart()
            handleExportPayouts(updatedFilters, setDownloading)
            setRequestDownload(false)
          }}
          onClose={() => setRequestDownload(false)}
        />
      )}
    </>
  )
}
