import { useState } from "react"

import { IconButton } from "../../../Shared"
import ExportModal from "../../Modal/Export"

export default ({ start_date, end_date }) => {
  const [show_modal, showModal] = useState(false)
  return (
    <>
      <IconButton
        secondary
        icon="cloud download"
        id="breakdown-export-btn"
        onClick={() => showModal(true)}
      >
        Export
      </IconButton>
      {show_modal && (
        <ExportModal
          start_date={start_date}
          end_date={end_date}
          onClose={() => showModal(false)}
        />
      )}
    </>
  )
}
