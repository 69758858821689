export const colors = {
  warning: "#ff8b28",
  danger: "#E65F5C",
  success: "#0ba3e8",
  green: "#40B875",
  blue: "#35a7ff",
  orange: "#ff8b28",
  red: "#E65F5C",
  gold: "#f8ee0a",
  purple: "#504DB8",
  info: "#96dbfa",
  info_secondary: "#abe2fb",
  primary: "#4D6D8C",
  white: "#fff",
  light: "#f8f9fa",
  light2: "#F3F5F7",
  light3: "#E7EBEE",
  light4: "#DBE0E6",
  light5: "#CFD6DD",
  light6: "#C3CCD5",
  dark: "#1E1E24",
  dark2: "#383843",
  dark3: "#4A4A59",
  dark4: "#5D5D6F",
  dark5: "#6F6F85",
  dark6: "#858599",
  dark7: "#9B9BAB",
  tween: "#ABB8C4",
  link: "#0ba3e8",
  input_border: "rgba(34, 36, 38, 0.15)",
  input_border_hover: "rgba(34, 36, 38, 0.35)",
  input_border_focus: "#85B7D9",
  table_border: "rgba(34, 36, 38, 0.125)",
  transparent: "rgba(0, 0, 0, 0)"
}

export const sizesToProps = ({ width }) => ({
  isMobile: width < style.mobile_breakpoint,
  isTablet: width >= style.mobile_breakpoint && width < style.tablet_breakpoint,
  isDesktop:
    width >= style.tablet_breakpoint && width < style.desktop_breakpoint,
  isLargeScreen: width >= style.desktop_breakpoint,
  screen_width_px: width
})

export const style = {
  font: "Roboto, sans-serif",
  border_radius: "0.28571429rem",
  navbar: {
    height: "4rem"
  },
  footer: {
    height: "2rem"
  },
  sidebar: {
    expanded: "13.33rem",
    collapsed: "4.25rem",
    animation_duration: "0.15s"
  },
  semantic: {
    table: {
      border_color: "rgba(34, 36, 38, 0.15)",
      header_background: "#f9fafb"
    }
  },
  mobile_breakpoint: 768,
  tablet_breakpoint: 1024,
  desktop_breakpoint: 1280
}
