import { createContext } from "react"

import Styled from "styled-components"
import { darken, rgba } from "polished"

import Message from "../Message"
import Paginator from "../../Paginator"
import DragScroll from "../../DragScroll"
import Input, { Dropdown } from "../Input"
import Icon, { InfoIcon } from "../../Icon"
import {
  Control,
  LinkRow,
  TotalsRow,
  CustomControl,
  StyledActionCell,
  CopyToClipboardCell,
  StyledSemanticTable as SemanticTable
} from "./Styled"

import { colors } from "../../../constants"

const SECTION_BODY = "body"
const SECTION_HEADER = "header"
const SECTION_FOOTER = "footer"

const TableSectionContext = createContext({ section: null })

const TableWrapper = Styled(
  ({ controls, fitted, no_results, className, overflow, ...props }) => (
    <div>
      {!!controls && (
        <ControlsWrapper {...controls}>
          {!no_results && <div className={className} {...props} />}
        </ControlsWrapper>
      )}
      {!controls && !no_results && <div className={className} {...props} />}
      {!!no_results && (
        <div className={className}>
          {no_results === true && (
            <Message type="info" message="No results found." />
          )}
          {no_results !== true && <>{no_results}</>}
        </div>
      )}
    </div>
  )
)`${({ fitted, overflow }) => `
  position: relative;
  ${!fitted ? "margin: 0.33rem 0;" : ""}
  ${!!overflow ? `overflow: ${overflow};` : ""}
`}`

const ControlsWrapper = Styled(
  ({
    children,
    custom = null,
    pagination = null,
    position = "top", // "top", "bottom", or "both"
    custom_controls_position = null, // "top" or "bottom" (match position by default)
    className
  }) => {
    if ((!custom || custom.length === 0) && !pagination) {
      //nothing to do
      return <div>{children}</div>
    }
    return (
      <>
        {(position === "top" || position === "both") && (
          <div className={className}>
            {!!custom &&
              (!custom_controls_position ||
                custom_controls_position === "top") &&
              custom.length > 0 &&
              custom.map((el, i) => (
                <CustomControl key={i}>
                  <>{el}</>
                </CustomControl>
              ))}
            {!!pagination && (
              <Control>
                <Paginator {...pagination} />
              </Control>
            )}
            <div style={{ clear: "both" }} />
          </div>
        )}
        {children}
        {(position === "bottom" || position === "both") && (
          <div className={className}>
            {!!custom &&
              (!custom_controls_position ||
                custom_controls_position === "bottom") &&
              custom.length > 0 &&
              custom.map((el, i) => (
                <CustomControl key={i}>
                  <>{el}</>
                </CustomControl>
              ))}
            {!!pagination && (
              <Control>
                <Paginator {...pagination} />
              </Control>
            )}
          </div>
        )}
      </>
    )
  }
)`
  padding: 0.33rem 0;
  min-height: 3.65rem;
  text-align: ${({ align }) => (!!align ? align : "right")};
`

const TableRow = Styled(({ section, selected, secondary, hover, ...props }) => (
  <SemanticTable.Row {...props} />
))`${({
  onClick,
  section,
  hover = true,
  selected = false,
  secondary = false
}) => `
  &&& {
    ${onClick ? `cursor: pointer;` : ""}
    ${
      !hover || section === SECTION_HEADER
        ? `` // ignore hover on header row
        : `
      &:hover {
        background-color: ${
          !!secondary ? darken(0.015, colors.light2) : colors.light
        }
      }
    `
    }
    ${
      !selected
        ? !secondary
          ? ""
          : `
      font-weight: 300;
      font-size: 0.98rem;
      background-color: ${colors.light2};
    `
        : `background-color: ${colors.light};`
    }
  }
`}`

const TableCell = Styled(SemanticTable.Cell)`
${({ positive, negative, active }) => {
  if (positive) {
    return `&&&& {
      background-color: ${rgba(colors.green, 0.125)} !important;
      color: rgba(0, 0, 0, 0.87) !important;
    }`
  } else if (negative) {
    return `&&&& {
      background-color: transparent !important;
      color: rgba(0, 0, 0, 0.87) !important;
    }`
  } else if (active) {
    return `&&&& {
      background-color: ${colors.light2} !important;
      color: ${colors.dark} !important;
    }`
  }
  return ``
}}
`

const HeaderCell = Styled(
  ({
    className,
    description = null,
    color = null,
    background = null,
    children,
    ...props
  }) => (
    <SemanticTable.HeaderCell className={className} {...props}>
      {children}
      {!!description && (
        <>
          &nbsp;&nbsp;
          <InfoIcon popup={description} />
        </>
      )}
    </SemanticTable.HeaderCell>
  )
)`${({ color = null, background = null }) => `
  &&&& {
    font-weight: 600;
    font-size: 0.88rem;
    text-transform: uppercase;
    color: ${color ? color : colors.dark6};
    background-color: ${background ? background : colors.white};
  }
`}`

const TotalCell = Styled(({ value, ...props }) => (
  <TableCell {...props}>
    {value === "0.00" || value === "-0.00" ? `-` : value}
  </TableCell>
))``

const InputCell = Styled(({ value, onChange, className }) => (
  <SemanticTable.Cell
    className={className}
    onClick={e =>
      e !== document.activeElement &&
      e.target.getElementsByTagName("input")[0]?.focus()
    }
  >
    <div>
      <Input
        debounce
        transparent
        value={value}
        placeholder="search"
        onChange={(e, d) => onChange(d.value)}
      />
      {(value?.length ?? 0) > 0 && (
        <Icon
          name="remove"
          fitted
          cursor="pointer"
          onClick={() => onChange("")}
        />
      )}
    </div>
  </SemanticTable.Cell>
))`
  cursor: text;

  & > div {
    position: relative;

    & > .icon {
      position: absolute;
      right: 0;
      top: 0;

      color: ${colors.light5};

      &.remove:hover {
        color: ${colors.danger};
      }
    }
  }

`

const SelectCell = Styled(({ className, strong, ...props }) => (
  <SemanticTable.Cell className={className}>
    <Dropdown inline {...props} />
  </SemanticTable.Cell>
))`${({ strong = false }) => `
  & > .ui.dropdown.inline > .text {
    font-weight: ${!!strong ? 600 : 400};
  }
`}`

const Table = ({
  className,
  basic = true,
  fitted = false,
  controls = null,
  draggable = false,
  no_results = false,
  overflow = "visible",
  ...props
}) => (
  <TableWrapper
    fitted={fitted}
    controls={controls}
    overflow={overflow}
    className={className}
    no_results={no_results}
  >
    {!!draggable && (
      <DragScroll>
        <SemanticTable basic={basic} {...props} />
      </DragScroll>
    )}
    {!draggable && <SemanticTable basic={basic} {...props} />}
  </TableWrapper>
)

Table.Header = props => (
  <TableSectionContext.Provider value={{ section: SECTION_HEADER }}>
    <SemanticTable.Header {...props} />
  </TableSectionContext.Provider>
)
Table.Body = props => (
  <TableSectionContext.Provider value={{ section: SECTION_BODY }}>
    <SemanticTable.Body {...props} />
  </TableSectionContext.Provider>
)
Table.Footer = props => (
  <TableSectionContext.Provider value={{ section: SECTION_FOOTER }}>
    <SemanticTable.Footer {...props} />
  </TableSectionContext.Provider>
)
Table.Row = props => (
  <TableSectionContext.Consumer>
    {({ section }) => <TableRow section={section} {...props} />}
  </TableSectionContext.Consumer>
)
Table.HeaderCell = props => <HeaderCell {...props} />
Table.LinkRow = props => <LinkRow {...props} />
Table.Cell = props => <TableCell {...props} />
Table.TotalsRow = props => <TotalsRow {...props} />
Table.TotalCell = props => <TotalCell {...props} />
Table.InputCell = props => <InputCell {...props} />
Table.SelectCell = props => <SelectCell {...props} />
Table.ActionCell = props => (
  <StyledActionCell
    {...props}
    onClick={e => {
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()
      if (!!props.onClick) {
        props.onClick(e)
      }
    }}
  />
)
Table.CopyToClipboardCell = props => <CopyToClipboardCell {...props} />

export default Table
