import moment from "moment"

import {
  Table,
  Button,
  PopupMenu,
  InactiveLabel,
  FutureDatedLabel
} from "../../../Shared"
import SummaryModal from "../Summary/Modal"
import ActivatePoolModal from "../../Actions/ActivateRule"
import DeactivatePoolModal from "../../Actions/DeactivateRule"
import CopyToLocationsModal from "../../Actions/CopyToLocations"
import { TableRow, SourceBadge, EffectiveTimeSpan } from "../../Styled"

import {
  useLocations,
  useStateObject,
  useSelectedLocation
} from "../../../../hooks"
import { ruleIsFutureDated } from "../../helpers"
import { ToastConsumer } from "../../../../context"
import { month_day_year_format } from "../../../../constants"
import useAuthorization from "../../../../hooks/authorization"
import { rules_manage } from "../../../../constants/permissions"

export default ({ pool, refetch, editPool, copyPool }) => {
  const { hasPermission } = useAuthorization()
  const can_edit = hasPermission(rules_manage)
  const selected_location = useSelectedLocation()
  const future_dated = ruleIsFutureDated(pool)
  const locations = useLocations()

  const [state, setState] = useStateObject({
    view_pool: false,
    deactivate_pool: false,
    activate_pool: false,
    copy_request: false,
    actions_open: false
  })

  const {
    view_pool,
    actions_open,
    copy_request,
    activate_pool,
    deactivate_pool
  } = state
  const closeActions = () => setState({ actions_open: false })

  return (
    <ToastConsumer>
      {toast => (
        <>
          <TableRow
            className="tip-pools-table-row"
            onClick={() => setState({ view_pool: true })}
          >
            <Table.Cell>
              {pool.name}
              {!pool.active && !future_dated && <InactiveLabel />}
              {!!future_dated && <FutureDatedLabel />}
            </Table.Cell>
            {!selected_location && (
              <Table.Cell>{pool.store?.name ?? pool.store_name}</Table.Cell>
            )}
            <Table.Cell>
              {pool.distributionSources.map(source => (
                <SourceBadge key={source.id} type={source.source}>
                  {source.name}
                </SourceBadge>
              ))}
            </Table.Cell>
            <Table.Cell>{pool.distributionMethod.name}</Table.Cell>
            <Table.Cell>
              <EffectiveTimeSpan
                effective_date={pool.effective_date}
                deactivated_date={pool.deactivated_date}
              />
            </Table.Cell>
            <Table.Cell>
              {moment
                .utc(pool.created_at)
                .local()
                .format(month_day_year_format)}
            </Table.Cell>
            {!!can_edit && (
              <Table.Cell
                collapsing
                textAlign="right"
                className="pools-table-action-cell"
              >
                <PopupMenu
                  open={actions_open}
                  onClose={() => closeActions()}
                  trigger={
                    <Button
                      icon="ellipsis vertical"
                      size="mini"
                      onClick={e => {
                        e.stopPropagation()
                        setState({ actions_open: true })
                      }}
                    />
                  }
                >
                  {(!!pool.active || !!future_dated) && (
                    <>
                      <PopupMenu.Item
                        onClick={e => {
                          e.stopPropagation()
                          editPool(pool)
                          closeActions()
                        }}
                      >
                        Edit
                      </PopupMenu.Item>
                      <PopupMenu.Item
                        onClick={e => {
                          e.stopPropagation()
                          copyPool(pool)
                          closeActions()
                        }}
                      >
                        Duplicate
                      </PopupMenu.Item>
                      {locations.length > 1 && (
                        <PopupMenu.Item
                          onClick={e => {
                            e.stopPropagation()
                            setState({
                              copy_request: pool.id,
                              actions_open: false
                            })
                          }}
                        >
                          Copy to Locations
                        </PopupMenu.Item>
                      )}
                    </>
                  )}
                  {!pool.active && !future_dated && (
                    <PopupMenu.Item
                      onClick={e => {
                        e.stopPropagation()
                        setState({ activate_pool: pool, actions_open: false })
                      }}
                    >
                      Activate Pool
                    </PopupMenu.Item>
                  )}
                  <PopupMenu.Item
                    danger
                    onClick={e => {
                      e.stopPropagation()
                      setState({ deactivate_pool: pool, actions_open: false })
                    }}
                  >
                    {!pool.active ? "Delete Pool" : "Deactivate/Delete"}
                  </PopupMenu.Item>
                </PopupMenu>
              </Table.Cell>
            )}
          </TableRow>
          {!!view_pool && (
            <SummaryModal
              size="small"
              pool={pool}
              onClose={() => setState({ view_pool: false })}
              onEdit={
                can_edit
                  ? () => {
                      editPool(pool)
                      setState({ view_pool: false })
                    }
                  : null
              }
            />
          )}
          {!!copy_request && (
            <CopyToLocationsModal
              rule_type="pool"
              rule_id={copy_request}
              onSuccess={() => {
                refetch()
                setState({ copy_request: false })
                toast({
                  type: "success",
                  message: "Pool copied successfully."
                })
              }}
              onClose={() => setState({ copy_request: false })}
            />
          )}
          {!!activate_pool && (
            <ActivatePoolModal
              rule_id={activate_pool.id}
              rule_type="pool"
              location_id={activate_pool.store.id}
              min_effective={activate_pool.min_deactivated_date}
              onSuccess={() => {
                refetch()
                setState({ activate_pool: false })
                toast({
                  type: "success",
                  message: "Pool activated successfully."
                })
              }}
              onError={() => {
                refetch()
                setState({ activate_pool: false })
                toast({
                  type: "error",
                  message: "Failed to activate pool."
                })
              }}
              onClose={() => setState({ activate_pool: false })}
            />
          )}
          {!!deactivate_pool && (
            <DeactivatePoolModal
              rule_type="pool"
              rule_id={deactivate_pool.id}
              location_id={deactivate_pool.store.id}
              min_deactivated={deactivate_pool.min_deactivated_date}
              allow_deactivate={!!deactivate_pool.active}
              onSuccess={(deactivate_pool = true) => {
                setState({ deactivate_pool: false })
                refetch()
                toast({
                  type: "success",
                  message: `Pool ${
                    deactivate_pool ? "deleted" : "deactivated"
                  } successfully.`
                })
              }}
              onError={(deactivate_pool = true) => {
                setState({ deactivate_pool: false })
                refetch()
                toast({
                  type: "error",
                  message: `Failed to ${
                    deactivate_pool ? "delete" : "deactivate"
                  } pool.`
                })
              }}
              onClose={() => setState({ deactivate_pool: false })}
            />
          )}
        </>
      )}
    </ToastConsumer>
  )
}
