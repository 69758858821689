import { useState } from "react"

import { useMutation } from "@apollo/client"

import CreateRecurring from "./Create"
import { MenuModal } from "../../../../Shared"

import { CUSTOM_SHIFT_SINGLE, CUSTOM_SHIFT_RECURRING } from "./helpers"
import { CREATE_RECURRING_SHIFT } from "../../../../../graphql/mutations"

export default ({ onClose, toast }) => {
  const [custom_shift_type, selectCustomShiftType] = useState(null)

  const [createRecurringShift] = useMutation(CREATE_RECURRING_SHIFT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ createRecurringShift }) => {
      const success = createRecurringShift?.id
      const recurring = custom_shift_type === CUSTOM_SHIFT_RECURRING
      if (success) {
        toast({
          type: "success",
          long: true,
          message: (
            <>
              <p>
                {!!recurring && <>Custom shifts successfully scheduled.</>}
                {!recurring && <>Custom shift successfully created.</>}
              </p>
              <p>
                It may take a few minutes for {!recurring && "your new shift"}
                {!!recurring && "recurring shifts"} to appear in the Worked
                Shifts report.
              </p>
            </>
          )
        })
        onClose()
      } else {
        toast({
          type: "success",
          message: (
            <p>
              An error occurred while {!!recurring && "scheduling"}
              {!recurring && "creating"} your custom shift{!!recurring && "s"}.
            </p>
          )
        })
      }
    }
  })
  return (
    <>
      {!custom_shift_type && (
        <MenuModal
          onClose={onClose}
          close_on_select={false}
          options={[
            {
              name: "Create Single",
              icon: "calendar plus",
              description: (
                <>
                  Create a single custom worked shift and apply it to a
                  specified business day.
                </>
              ),
              onSelect: () => selectCustomShiftType(CUSTOM_SHIFT_SINGLE)
            },
            {
              name: "Schedule Recurring",
              icon: "calendar alternate",
              description: (
                <>
                  Create a recurring shift to be automatically created on
                  specified business days.
                </>
              ),
              onSelect: () => selectCustomShiftType(CUSTOM_SHIFT_RECURRING)
            }
          ]}
        />
      )}
      {!!custom_shift_type && (
        <CreateRecurring
          recurring={custom_shift_type === CUSTOM_SHIFT_RECURRING}
          onSubmit={input =>
            createRecurringShift({
              variables: { input }
            })
          }
          onClose={onClose}
        />
      )}
    </>
  )
}
