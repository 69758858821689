import moment from "moment"
import { p2pEnabled, reportExportsEnabled } from "../../helpers"

export const SALES_TAB = "sales"
export const PEER_TO_PEER_TAB = "peer-to-peer"
export const WORKED_SHIFTS_TAB = "worked-shifts"
export const BREAKDOWN_TAB = "breakdown-snapshots"
export const REPORT_EXPORTS_TAB = "report-exports"
export const REPORTING_TABS = [
  {
    path: SALES_TAB,
    name: "Sales"
  },
  {
    path: WORKED_SHIFTS_TAB,
    name: "Worked Shifts"
  },
  {
    path: BREAKDOWN_TAB,
    name: "Breakdown Snapshots"
  },
  {
    path: REPORT_EXPORTS_TAB,
    name: "Report Exports"
  },
  {
    path: PEER_TO_PEER_TAB,
    name: "Peer-to-Peer"
  }
]

const isTabEnabled = (user, tab) => {
  switch (tab) {
    case PEER_TO_PEER_TAB:
      return p2pEnabled(user.organization.settings)
    case REPORT_EXPORTS_TAB:
      return reportExportsEnabled(user.organization.settings)
    default:
      return true
  }
}

export const visibleTabsForUser = (user, selected_tab = null) => {
  // hide tabs based on settings
  const hidden_tabs = [PEER_TO_PEER_TAB, REPORT_EXPORTS_TAB].filter(
    tab => !isTabEnabled(user, tab)
  )

  let visible_tabs = [...REPORTING_TABS]
    .filter(({ path }) => !hidden_tabs.includes(path))
    .map(tab => ({
      ...tab,
      id: tab.path,
      selected: tab.path === selected_tab
    }))

  try {
    if (
      selected_tab &&
      !visible_tabs.find(({ path }) => path === selected_tab)
    ) {
      const viewing_tab = REPORTING_TABS.find(
        ({ path }) => path === selected_tab
      )
      visible_tabs = [
        ...visible_tabs,
        { ...viewing_tab, id: selected_tab, selected: true }
      ]
    }
  } catch {}

  return visible_tabs
}

export const REPORTING_FILTER_NAMESPACE = "reporting"
export const reportFiltersNamespace = (tab = null) =>
  `${REPORTING_FILTER_NAMESPACE}${!!tab ? `.${tab}` : ""}`

export const DEFUALT_REPORT_FILTERS = {
  page: 1,
  per_page: 50
}

export const prepareReportingFilters = (filters, page) => {
  const { start_time, end_time, ...rest } = filters
  return {
    [REPORTING_FILTER_NAMESPACE]: { start_time, end_time },
    [reportFiltersNamespace(page)]: { ...rest }
  }
}

export const prepareSalesReportFilters = filters =>
  prepareReportingFilters(filters, SALES_TAB)
export const prepareWorkedShiftsReportFilters = filters =>
  prepareReportingFilters(filters, WORKED_SHIFTS_TAB)
export const preparePeerToPeerReportFilters = filters =>
  prepareReportingFilters(filters, PEER_TO_PEER_TAB)

export const generateFilterPresets = ({
  filter_start_date,
  filter_end_date,
  today,
  yesterday,
  current_pay_period,
  last_pay_period,
  applyPreset
}) => {
  // default today/yeterday
  let presets = [
    {
      name: "Today",
      active: filter_start_date === today && filter_end_date === today,
      onClick: () => applyPreset(today, today)
    },
    {
      name: "Yesterday",
      active: filter_start_date === yesterday && filter_end_date === yesterday,
      onClick: () => applyPreset(yesterday, yesterday)
    }
  ]

  if (
    !!current_pay_period &&
    !moment(current_pay_period.start).isAfter(today)
  ) {
    presets.push({
      name: "Current Pay Period",
      active:
        filter_start_date === current_pay_period.start &&
        filter_end_date === today,
      onClick: () => applyPreset(current_pay_period.start, today)
    })
  }

  // add the previous pay period
  if (!!last_pay_period) {
    presets.push({
      name: "Last Pay Period",
      active:
        filter_start_date === last_pay_period.start &&
        filter_end_date === last_pay_period.end,
      onClick: () => applyPreset(last_pay_period.start, last_pay_period.end)
    })
  }

  return presets
}
