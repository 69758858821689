import Styled from "styled-components"

import InfoIcon from "../../Icon/Info"
import { Modal, Popup, Toggle, IconButton, SubmitButton } from "../../Shared"

import { colors } from "../../../constants"
import { useStateObject } from "../../../hooks"

const SettingsRow = Styled.div`
  line-height: 1.67em;
  margin: 0 -0.67rem;
  padding: 0.275rem 0.67rem 0.15rem 0.5rem;
  border-radius: 3px 13px 13px 3px;
  cursor: pointer;
  transition: background-color 0.1s ease-out;
  &:hover {
    background-color: ${colors.light2};
  }
`
const SettingName = Styled.div`
  display: inline-block;
  font-size: 1.2em;
`
const SettingToggle = Styled(Toggle)`
  float: right;
`
const Thin = Styled.div`
  font-weight:300;
  margin-bottom: 1.33rem;
`
const Inline = Styled.div`display: inline-block;`

export default ({
  onSave,
  onClose,
  default_hidden,
  column_definitions,
  column_settings: original_column_settings
}) => {
  const [column_settings, setColumnSettings] = useStateObject({
    ...original_column_settings
  })

  const restoreDefaults = () => {
    const settings = Object.keys(column_settings).reduce((acc, name) => {
      return {
        ...acc,
        [name]: !default_hidden.includes(name)
      }
    }, {})

    setColumnSettings({ ...settings })
  }

  return (
    <Modal dimmer size="tiny" onClose={onClose} closeOnDimmerClick={false}>
      <Modal.Header onClose={onClose}>Customize Display Columns</Modal.Header>
      <Modal.Content scrolling>
        <Thin>
          Your column display settings only apply to your breakdown table.
          Changes won't affect exports or other users.
        </Thin>
        {Object.keys(column_settings).map(column_name => (
          <SettingsRow
            key={column_name}
            onClick={() => {
              setColumnSettings({
                [column_name]: !column_settings[column_name]
              })
            }}
          >
            <SettingName>
              {column_definitions[column_name].display_name}{" "}
              {!!column_definitions[column_name].description && (
                <Popup content={column_definitions[column_name].description}>
                  <Inline>
                    <InfoIcon size="small" />
                  </Inline>
                </Popup>
              )}
            </SettingName>
            <SettingToggle checked={column_settings[column_name]} />
          </SettingsRow>
        ))}
      </Modal.Content>
      <Modal.Actions>
        <IconButton icon="refresh" basic onClick={restoreDefaults}>
          Restore Defaults
        </IconButton>
        <SubmitButton onClick={() => onSave(column_settings)} label="Save" />
      </Modal.Actions>
    </Modal>
  )
}
