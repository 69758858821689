import React from "react"

import { Checkbox } from "semantic-ui-react"

import {
  Meter,
  Header,
  Leader,
  Trophy,
  ShowAll,
  Qualifier,
  LeaderName,
  LeaderRank,
  LeaderTotal,
  NameAndStore,
  LeaderWrapper,
  HiddenEmployees,
  LeaderboardWrapper,
  ToggleSettingsIcon,
  ExportMetricsIcon
} from "./Styled"
import Link from "../../Link"

import { generateMetricExport } from "../helpers"

import { displayNumber } from "../../../helpers/number"

export default ({
  end,
  start,
  show,
  type,
  name,
  mounted,
  leaders,
  show_all,
  edit_mode,
  toggleShowAll,
  toggleEditMode,
  hide_qualifier,
  hidden_result_rows,
  toggleResultSetting
}) => {
  const top_leaders = leaders.slice(0, show)
  const max = top_leaders.reduce(
    (acc, leader) => (leader.total > acc ? leader.total : acc),
    0
  )

  return (
    <LeaderboardWrapper>
      <Header>
        {!edit_mode && `Leaderboard`}
        {!!edit_mode && `Customize Display Settings`}
        <ExportMetricsIcon
          onClick={() => generateMetricExport(leaders, name, start, end)}
        />
        <ToggleSettingsIcon active={!!edit_mode} onClick={toggleEditMode} />
      </Header>
      {!!edit_mode && <HiddenEmployees employees={[]} />}
      {(!!show_all || !!edit_mode ? leaders : top_leaders).map(
        (leader, idx) => (
          <LeaderWrapper
            key={idx}
            active={!hidden_result_rows.includes(leader.result_key)}
            edit_mode={!!edit_mode}
            onClick={() =>
              !!edit_mode && toggleResultSetting(leader.result_key)
            }
          >
            <Meter width={!mounted ? 0 : 40 * (leader.total / max)} idx={idx} />
            <Leader>
              <LeaderRank>
                {!edit_mode && <>{idx + 1}.</>}
                {!!edit_mode && (
                  <Checkbox
                    checked={!hidden_result_rows.includes(leader.result_key)}
                  />
                )}
              </LeaderRank>
              <NameAndStore>
                <LeaderName>{leader.name}</LeaderName>
                {!hide_qualifier &&
                  !!leader.qualifier &&
                  // only show qualifier (store) if the leader is an employee
                  leader.result_key !== leader.store_id && (
                    <Qualifier>{leader.qualifier}</Qualifier>
                  )}
              </NameAndStore>
              <LeaderTotal>
                {idx === 0 && <Trophy name="trophy" />}
                {type === "money" && `$`}
                {displayNumber(
                  leader.total,
                  type === "number" ? 0 : type === "percent" ? 1 : 2
                )}
                {type === "percent" && "%"}
              </LeaderTotal>
            </Leader>
          </LeaderWrapper>
        )
      )}
      {leaders.length > show && !edit_mode && (
        <ShowAll onClick={toggleShowAll}>
          <Link>{!!show_all ? `Show Top ${show}` : `Show All`}</Link>
        </ShowAll>
      )}
    </LeaderboardWrapper>
  )
}
