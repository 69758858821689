import Styled from "styled-components"
import { settings_manage_roles } from "../../../../../../constants/permissions"
import { entity_type_employee } from "../../../../../../constants/role"
import { useStateObject, useUser } from "../../../../../../hooks"
import useAuthorization from "../../../../../../hooks/authorization"
import { Badge, Table } from "../../../../../Shared"
import { userCanModifyEntityType } from "../../../Users/helpers"
import { useRoleModals } from "../../context/roleModals"
import {
  displayEntityType,
  EDITOR_MODE_COPY,
  EDITOR_MODE_VIEW
} from "../../helpers"
import ActionsPopupMenu from "./ActionsPopupMenu"

const RoleRow = ({ role }) => {
  const user = useUser()
  const [state, setState] = useStateObject({ actions_open: false })
  const { showModal } = useRoleModals()
  const { hasPermission } = useAuthorization()
  const closeActions = () => setState({ actions_open: false })
  const { actions_open } = state
  const { id, name, description, entity_type, custom, assigned } = role

  const getActions = () => {
    let actions = []

    actions.push({
      label: "View Summary",
      action: () => {
        showModal("editor_modal", { mode: EDITOR_MODE_VIEW, role })
        closeActions()
      }
    })

    // only custom roles can be edited
    if (
      hasPermission(settings_manage_roles) &&
      userCanModifyEntityType(user, entity_type) &&
      custom
    ) {
      actions.push({
        label: "Edit",
        action: () => {
          showModal("edit_modal", role)
          closeActions()
        }
      })
    }

    if (
      hasPermission(settings_manage_roles) &&
      userCanModifyEntityType(user, entity_type) &&
      role.entity_type !== entity_type_employee
    ) {
      actions.push({
        label: "Use as Template",
        action: () => {
          showModal("editor_modal", {
            mode: EDITOR_MODE_COPY,
            role
          })
          closeActions()
        }
      })
    }

    // only custom roles that have not been user assigned can be deleted
    if (
      hasPermission(settings_manage_roles) &&
      userCanModifyEntityType(user, entity_type) &&
      custom &&
      !assigned
    ) {
      actions.push({
        label: "Delete",
        danger: true,
        action: () => {
          showModal("delete_modal", role)
          closeActions()
        }
      })
    }

    return actions
  }

  const actions = getActions()
  return (
    <Table.Row
      key={id}
      onClick={() =>
        showModal("editor_modal", { mode: EDITOR_MODE_VIEW, role })
      }
    >
      <Table.Cell singleLine>
        {name}
        {custom && <CustomRoleBadge />}
      </Table.Cell>
      <Table.Cell width={6}>{description}</Table.Cell>
      <Table.Cell>{displayEntityType(entity_type)}</Table.Cell>
      <Table.Cell textAlign="right">
        {!!actions.length && (
          <ActionsPopupMenu
            open={actions_open}
            actions={actions}
            onOpen={() => setState({ actions_open: true })}
            onClose={closeActions}
          />
        )}
      </Table.Cell>
    </Table.Row>
  )
}

const CustomRoleBadge = Styled(props => <Badge {...props}>custom</Badge>)`
  &.ui.label {
    font-size: 0.7rem;
    margin-left: 0.5rem;
  }
`

export default RoleRow
