import moment from "moment"
import { rgba } from "polished"
import Styled from "styled-components"

import {
  Table,
  Popup,
  ExclamationIcon,
  MenuDropdown,
  IconButton
} from "../../../Shared"

import {
  statusIsCaution,
  statusIsPositive,
  statusIsNegative,
  employeeTypeFilters,
  REPORT_COLUMN_ETA_STATE,
  REPORT_COLUMN_ETA_OPTED_OUT,
  REPORT_COLUMN_ETA_EFFECTIVE,
  REPORT_COLUMN_ETA_OPTED_OUT_AT,
  ETA_STATE_PROVIDER_APPROVED
} from "../../helpers"
import { colors, month_day_year_format } from "../../../../constants"
import useAuthorization from "../../../../hooks/authorization"
import { settings_manage_employee_app_users } from "../../../../constants/permissions"

export const TableWrapper = Styled.div`
  margin: 0 0;
  position: relative;
  padding-bottom: 0.33rem;

  & .table tr > th {
    min-width: 109px;
  }

  /* status column */
  & .table tr > *:nth-child(2) {
    border-left: 1px solid ${colors.table_border};
  }
`

export const StatusIndicator = Styled(({ className, label = null }) => (
  <>
    <div className={className} />
    {!!label && <span>&nbsp;{label}</span>}
  </>
))`${({ color = colors.light3 }) => `
  display: inline-block;
  vertical-align: baseline;
  margin: 0 0.1em 0 0.1em;
  background-color: ${rgba(color, 0.7)};
  width: 0.7em;
  height: 0.7em;
  border-radius: 50%;
  box-shadow: inset ${color} 0 0 0.33em;
`}`

export const StatusPositive = props => (
  <StatusIndicator {...props} color={colors.green} />
)
export const StatusCaution = props => (
  <StatusIndicator {...props} color={colors.warning} />
)
export const StatusNegative = props => (
  <StatusIndicator {...props} color={colors.red} />
)
export const StatusDisabled = () => <ExclamationIcon fitted name="remove" />

export const StatusCell = Styled(function ({ className, row }) {
  const positive = statusIsPositive(row)
  const caution = statusIsCaution(row)
  const negative = statusIsNegative(row)
  const state = row[REPORT_COLUMN_ETA_STATE]
  const opted_out = row[REPORT_COLUMN_ETA_OPTED_OUT]
  const effective_date = row[REPORT_COLUMN_ETA_EFFECTIVE]
  const opted_out_at = row[REPORT_COLUMN_ETA_OPTED_OUT_AT]
  return (
    <Table.Cell className={className} collapsing textAlign="center">
      <Popup
        delay
        content={
          <>
            {!opted_out_at && (
              <>
                {positive && (
                  <>
                    {state === ETA_STATE_PROVIDER_APPROVED && (
                      <p>
                        Employee is eligible to participate in ETA, pending
                        account verification.
                      </p>
                    )}
                    {state !== ETA_STATE_PROVIDER_APPROVED && (
                      <p>
                        Employee is participating in Earned Tip Access
                        {!!effective_date && (
                          <>
                            {" "}
                            as of{" "}
                            {moment(effective_date).format(
                              month_day_year_format
                            )}
                          </>
                        )}
                        .
                      </p>
                    )}
                  </>
                )}
                {caution && (
                  <p>
                    Employee is in the Sign-Up process for Earned Tip Access.
                  </p>
                )}
                {negative && (
                  <p>Employee is not participating in Earned Tip Access.</p>
                )}
              </>
            )}
            {!!opted_out_at && (
              <p>
                Earned Tip Access {!opted_out && "deactivated"}
                {!!opted_out && "opted out"} as of{" "}
                {moment(opted_out_at).format(month_day_year_format)}.
              </p>
            )}
          </>
        }
      >
        <div>
          {!opted_out_at && (
            <>
              {positive && <StatusPositive />}
              {caution && <StatusCaution />}
              {negative && <StatusNegative />}
            </>
          )}
          {!!opted_out_at && <StatusDisabled />}
        </div>
      </Popup>
    </Table.Cell>
  )
})`
  background-color: ${colors.light2} !important;
`

export const StatusCellDescription = Styled(({ className }) => (
  <div className={className}>
    <div>
      <StatusPositive label="Participating" />
    </div>
    <div>
      <StatusCaution label="In Sign-Up Process" />
    </div>
    <div>
      <StatusNegative label="Not Participating" />
    </div>
  </div>
))`
  & > div {
    line-height: 1.67rem;
  }
`

export const EmployeeTypeDropdown = props => (
  <MenuDropdown prefix="Showing" options={employeeTypeFilters()} {...props} />
)

export const SendInvitesCell = Styled(({ className, onClick }) => {
  const { hasPermission } = useAuthorization()
  return (
    <Table.Cell className={className}>
      <div>
        {hasPermission(settings_manage_employee_app_users) ? (
          <IconButton
            data-testid="send-invites-button"
            fitted
            compact
            circular
            size="tiny"
            icon="user plus"
            popup={{
              content: <>Send employee app invite to register.</>,
              delay: true
            }}
            onClick={onClick}
          />
        ) : (
          "-"
        )}
      </div>
    </Table.Cell>
  )
})`
  background-color: ${colors.light2};
  & > div {
    margin: -0.5em;
    text-align: center;
  }
`
