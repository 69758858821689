import Styled from "styled-components"
import { Dropdown as SemanticDropdown, Form } from "semantic-ui-react"

import MultiSelect from "./MultiSelect"
import MenuDropdown, { ActionsDropdown } from "./Menu"

export { MultiSelect, MenuDropdown, ActionsDropdown }

const Dropdown = Styled(
  ({
    label,
    required,
    min_width_expanded,
    min_width_collapsed,
    selectOnBlur = true,
    ...props
  }) => {
    if (label) {
      return (
        <Form.Field required={required}>
          <label>{label}</label>
          <SemanticDropdown selectOnBlur={selectOnBlur} {...props} />
        </Form.Field>
      )
    }
    return <SemanticDropdown selectOnBlur={selectOnBlur} {...props} />
  }
)`${({
  icon = true,
  inline = false,
  min_width_collapsed = "8rem",
  min_width_expanded = "13rem"
}) => `
  display: inline-block;
  vertical-align: top;

  ${
    !icon
      ? `
    padding: 0.78571429em 1em !important;

    & > input.search {
      padding: 0.67857143em 1em !important;
    }
  `
      : ""
  }

  ${
    !!inline
      ? `
    &&.dropdown > i.dropdown.icon {
      margin: 0 0 0 1em;
    }
      `
      : `
    &&&&.dropdown {
      min-width: ${min_width_collapsed};
    }
    &&&&.dropdown.active {
      min-width: ${min_width_expanded};
    }
  `
  }

  &&&.dropdown {
    &.selection .menu {
      width: calc(100% + 2px); // left + right border width
    }
    & .menu {
      min-width: calc(100% + 2px); // left + right border width
    }
    &.ui.button:hover, &.ui.button:focus {
      color: rgba(0,0,0,.8);
    }
  }
`}
`

export default Dropdown
