import { useQuery } from "@apollo/client"
import { CLIENT_DASH_ROLES } from "../../../../graphql/queries"
import RolesTable from "./components/Table"
import RoleEditor from "./components/Editor"
import { useSelectedLocationId, useStateObject } from "../../../../hooks"
import FilterBar from "./components/FilterBar"
import EditRole from "./components/Modal/EditRole"
import { RoleModalsProvider, useRoleModals } from "./context/roleModals"
import DeleteRoleModal from "./components/Modal/DeleteRole"

export default () => (
  <RoleModalsProvider>
    <RolesTab />
  </RoleModalsProvider>
)

const RolesTab = () => {
  const [filters, setFilters] = useStateObject({
    sort_by: "id",
    sort_dir: "desc",
    entity_types: [],
    search: ""
  })
  const store_id = useSelectedLocationId()
  const { entity_types, search, sort_by, sort_dir } = filters
  const { data, loading, refetch } = useQuery(CLIENT_DASH_ROLES, {
    fetchPolicy: "network-only",
    variables: {
      entity_types,
      search,
      store_id,
      sort_by,
      sort_dir
    }
  })
  const roles = data?.clientDashRoles ?? []
  const { modal, hideModal } = useRoleModals()
  const { editor_modal, edit_modal, delete_modal } = modal
  return (
    <>
      <FilterBar filters={filters} setFilters={setFilters} loading={loading} />
      {!loading && <RolesTable roles={roles} />}
      {editor_modal && (
        <RoleEditor
          role={editor_modal?.role ?? null}
          mode={editor_modal.mode}
          onClose={() => hideModal("editor_modal")}
          refetch={refetch}
        />
      )}
      {edit_modal && (
        <EditRole
          role={edit_modal}
          onClose={() => hideModal("edit_modal")}
          refetch={refetch}
        />
      )}
      {delete_modal && (
        <DeleteRoleModal
          role={delete_modal}
          onClose={() => hideModal("delete_modal")}
          refetch={refetch}
        />
      )}
    </>
  )
}
