import {
  routes_visited_settings_key,
  store_completed_sync_settings_key,
  completed_org_onboarding_settings_key,
  completed_user_onboarding_settings_key
} from "../../constants"
import { apollo_client } from "../api"
import { UPDATE_USER_SETTING } from "../../graphql/mutations"
import { hashify } from "../string"

// User Helpers
export const hasCompletedInitialSync = user => {
  try {
    return (
      user.stores.filter(
        store => store.settings[store_completed_sync_settings_key] === "0"
      ).length === 0
    )
  } catch {}
  return false
}

const routesVisited = ({ settings }) => {
  let routes_visited
  try {
    routes_visited = JSON.parse(settings[routes_visited_settings_key])
    if (routes_visited.client) {
      return routes_visited.client
    }
  } catch {}
  return {}
}

export const hasVisitedRoute = (route_name, user) => {
  const route_visits = routesVisited(user)

  if (user.is_backdoor) {
    return true
  }

  return !!route_visits[route_name]
}

export const updateUserSetting = (setting_key, value) =>
  apollo_client.mutate({
    mutation: UPDATE_USER_SETTING,
    variables: {
      key: setting_key,
      value: value
    }
  })

export const markRouteVisited = (route_name, user) => {
  let routes_visited = {}
  try {
    routes_visited = JSON.parse(user.settings[routes_visited_settings_key])
    if (Array.isArray(routes_visited)) {
      // this is the default case
      routes_visited = {
        client: {
          [route_name]: 1
        }
      }
    } else if (routes_visited.client) {
      routes_visited.client[route_name] = 1
    } else {
      routes_visited.client = { [route_name]: 1 }
    }
  } catch {}

  routes_visited = JSON.stringify(routes_visited)
  updateUserSetting(routes_visited_settings_key, routes_visited)

  return routes_visited
}

export const completedOrgOnboarding = user => {
  if (user.is_backdoor) {
    return true
  }

  try {
    return !!JSON.parse(
      user.organization.settings[completed_org_onboarding_settings_key]
    )
  } catch {}
  return true
}

export const completedUserOnboarding = user => {
  if (user.is_backdoor) {
    return true
  }

  try {
    return !!JSON.parse(user.settings[completed_user_onboarding_settings_key])
  } catch {}
  return true
}

export const nightModeEnabled = user => {
  const key = nightModeKey(user)
  if (key) {
    const value = localStorage.getItem(key)
    if (!!value) {
      try {
        return !!JSON.parse(value)
      } catch {}
    }
  }
  return false
}
export const toggleNightMode = user => {
  const key = nightModeKey(user)
  if (key) {
    const enabled = nightModeEnabled(user)
    localStorage.setItem(key, !enabled)
  }
}

const nightModeKey = user => {
  if (!!user && !!user.name) {
    return `${hashify(user.name)}-night-mode-enabled`
  } else {
    return false
  }
}
