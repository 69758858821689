import React from "react"

import Modal from "../../../Modal"
import Message from "../../../Message"

export default ({ onClose, can_edit }) => (
  <Modal onClose={onClose} size="mini">
    <Modal.Header onClose={onClose}>Invalid Clock Out</Modal.Header>
    <Modal.Content>
      <Message type="info">
        <p>
          A valid clock out should be after the clock in but before the end of
          the business day.
        </p>
        <p>
          Shifts missing a valid clock out are excluded from tip distribution.
        </p>
        <p>
          The best way to resolve an invalid clock out is by updating the shift
          through your point-of-sale provider. Updates will propagate to TipHaus
          shortly after.
        </p>
        {!!can_edit && (
          <p>
            You can also override your PoS and edit shifts directly from the
            worked shifts reporting page.
          </p>
        )}
      </Message>
    </Modal.Content>
  </Modal>
)
