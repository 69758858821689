import { useQuery } from "@apollo/client"

import { useOrganization, useSelectedLocation } from "../"
import {
  EMPLOYEE_ETA_PAYOUTS,
  FETCH_REPORT,
  USERS_IN_ETA_REGISTRATION
} from "../../graphql/queries"

export const queryFetchReport = input => {
  return useQuery(FETCH_REPORT, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      input
    }
  })
}

export const queryUsersInEtaRegistration = () => {
  const organization = useOrganization()
  const selected_location = useSelectedLocation()

  return useQuery(USERS_IN_ETA_REGISTRATION, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      org_id: organization.id,
      store_id: selected_location?.id
    }
  })
}

export const queryEmployeeEtaPayouts = ({ employee_id, pay_period_id }) => {
  return useQuery(EMPLOYEE_ETA_PAYOUTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      employee_id,
      pay_period_id
    }
  })
}
