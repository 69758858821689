import { FilterWrapper } from "../Styled"

import Icon from "../../../Icon"
import { Dropdown, Button } from "../../"

const SORT_DIRECTION_ASC = "asc"
const SORT_DIRECTION_DESC = "desc"

export default ({ field, direction, options, onChange }) => (
  <FilterWrapper label="Sort By">
    <Dropdown
      selection
      value={field}
      options={options}
      min_width_expanded="10rem"
      onChange={(e, { value }) => onChange(value, direction)}
    />
    {!!direction && (
      <>
        &nbsp;
        <SortDirectionButton
          direction={direction}
          onClick={() =>
            onChange(
              field,
              direction === SORT_DIRECTION_ASC
                ? SORT_DIRECTION_DESC
                : SORT_DIRECTION_ASC
            )
          }
        />
      </>
    )}
  </FilterWrapper>
)

export const SortDirectionButton = ({ direction, onClick }) => (
  <Button basic circular size="mini" onClick={onClick}>
    <strong>
      {direction === SORT_DIRECTION_ASC && "ASC"}
      {direction === SORT_DIRECTION_DESC && "DESC"}
    </strong>
    &nbsp;&nbsp;
    <Icon
      name={
        direction === SORT_DIRECTION_ASC ? "sort amount up" : "sort amount down"
      }
    />
  </Button>
)
