import { useMemo } from "react"

import moment from "moment"

import { useOrganizationSetting } from "./"

import {
  date_format,
  datetime_format,
  minutes_per_hour,
  business_start_hour_settings_key
} from "../../constants"

// biz date function definitions
const businessDate = (datetime, format, day_start_hour) => {
  let biz_date = moment(datetime ?? undefined)
  const biz_date_time = biz_date.hour() + biz_date.minute() / minutes_per_hour
  if (biz_date_time < day_start_hour) {
    biz_date.subtract(1, "d")
  }
  if (!format) {
    return moment(biz_date.format(date_format))
  }
  return biz_date.format(date_format)
}

const businessStart = (datetime, format, day_start_hour) => {
  const hour_start = Math.floor(day_start_hour)
  const minute_start = Math.ceil(
    minutes_per_hour * (day_start_hour - hour_start)
  )
  let biz_start = businessDate(datetime, false, day_start_hour)
    .set("h", hour_start)
    .set("m", minute_start)

  if (!format) {
    return biz_start
  }
  return biz_start.format(datetime_format)
}

const businessEnd = (datetime, format, day_start_hour) => {
  let biz_end = businessStart(datetime, false, day_start_hour)
    .add(1, "d")
    .subtract(1, "millisecond")

  if (!format) {
    return biz_end
  }
  return biz_end.format(datetime_format)
}

const businessStartFromDate = (date, format, day_start_hour) =>
  businessStart(moment(date).endOf("day"), format, day_start_hour)

const businessEndFromDate = (date, format, day_start_hour) =>
  businessEnd(moment(date).endOf("day"), format, day_start_hour)

export const useBusinessDates = () => {
  const start_hour = parseFloat(
    useOrganizationSetting(business_start_hour_settings_key)
  )

  // memoize based on start hour to prevent unnecessary rerendering
  return useMemo(
    () => ({
      business_start_hour: start_hour,
      businessDate: (dt = null, format = true) =>
        businessDate(dt, format, start_hour),
      businessStart: (dt = null, format = true) =>
        businessStart(dt, format, start_hour),
      businessEnd: (dt = null, format = true) =>
        businessEnd(dt, format, start_hour),
      businessStartFromDate: (dt = null, format = true) =>
        businessStartFromDate(dt, format, start_hour),
      businessEndFromDate: (dt = null, format = true) =>
        businessEndFromDate(dt, format, start_hour)
    }),
    [start_hour]
  )
}
