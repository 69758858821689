export {
  prepareSalesReportFilters,
  preparePeerToPeerReportFilters,
  prepareWorkedShiftsReportFilters
} from "../Report/helpers"

export const BREAKDOWN_REPORT_NAME = "breakdown"

export const FILTER_ALL_EMPLOYEES = 0
export const FILTER_TIPPED_EMPLOYEES = 1
export const FILTER_EMPLOYEES_WITH_TAKE_HOME = 2

export const BREAKDOWN_FILTER_NAMESPACE = "breakdown"

export const DEFUALT_BREAKDOWN_FILTERS = {
  showing: FILTER_TIPPED_EMPLOYEES,
  page: 1,
  per_page: 50
}
