import React from "react"

import Styled from "styled-components"
import { Search as SemanticSearch } from "semantic-ui-react"

export const DEBOUNCE_MS = 500
export const PAGE = 1
export const PER_PAGE = 20

export default ({
  visible = true,
  onChange = () => {},
  onSelect = () => {},
  onSearchChange = () => {},
  value = undefined,
  fluid = undefined,
  ...props
}) => (
  <SearchWrapper visible={visible} fluid={fluid}>
    <StyledSearch
      spellCheck="false"
      placeholder="Search..."
      resultRenderer={({ title }) => <SearchResult>{title}</SearchResult>}
      {...props}
      onResultSelect={(e, { result }) => onChange({ ...result })}
      onSearchChange={(e, { value }) => onSearchChange(value)}
      value={value}
    />
  </SearchWrapper>
)

export const ResultItem = ({ title, subtitle = null, id_field = null }) => (
  <div>
    <ResultTitle>{title}</ResultTitle>
    {!!subtitle && <ResultSubtitle>{subtitle}</ResultSubtitle>}
    {!!id_field && <ResultSubtitle>{id_field}</ResultSubtitle>}
  </div>
)

const ResultTitle = Styled.div`
  font-weight: 400;
  font-size: 1rem;
`
const ResultSubtitle = Styled.div`
  font-weight: 300;
  font-size: 0.92rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const SearchWrapper = Styled.div`${({ visible, fluid }) => `
  display: ${!!visible ? (!!fluid ? "block" : "inline-block") : "none"};

  & > .search {

    & > .input {
      ${!!fluid ? "width: 100%" : ""};
    }

    & .results {
      overflow-y: auto !important;
      max-height: 300px !important;
    }
  }
`}`
const SearchResult = Styled.div``
const StyledSearch = Styled(SemanticSearch)``
