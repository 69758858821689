import { Component } from "react"

import { Loader } from "semantic-ui-react"
import { Mutation } from "@apollo/client/react/components"

import Modal from ".."
import { Logo, NavBack, NavNext, NavSubmit, InvalidInput } from "./Styled"
// steps
import IntroStep, { STEP_INTRO } from "./Steps/Intro"
import TipFeeStep, { STEP_TIP_FEE } from "./Steps/TipFee"
import PayPeriodStep, { STEP_PAY_PERIOD } from "./Steps/PayPeriod"
import InviteUsersStep, { STEP_INVITE_USERS } from "./Steps/InviteUsers"

import { isNumber } from "../../../helpers/number"
import { ONBOARD_ORGANIZATION } from "../../../graphql/mutations"

const STEPS = [STEP_INTRO, STEP_TIP_FEE, STEP_PAY_PERIOD, STEP_INVITE_USERS]

export default class extends Component {
  state = {
    step: STEP_INTRO,
    loading: false,
    cc_tip_fee: null,
    invite_users: [],
    pay_period_interval: null,
    pay_period_reference_date: null
  }

  goForward = () => {
    const { step } = this.state
    if (!this.isLastStep()) {
      this.setState({ step: STEPS[STEPS.indexOf(step) + 1] })
    }
  }

  goBack = () => {
    const { step } = this.state
    if (!this.isFirstStep()) {
      this.setState({ step: STEPS[STEPS.indexOf(step) - 1] })
    }
  }

  isLastStep = () => {
    const { step } = this.state
    return STEPS.length === STEPS.indexOf(step) + 1
  }

  isFirstStep = () => STEPS.indexOf(this.state.step) === 0

  isValidInput = () => {
    const { cc_tip_fee } = this.state
    let valid = true

    if (cc_tip_fee !== null && cc_tip_fee !== "") {
      valid &= cc_tip_fee.length < 8
      valid &= isNumber(cc_tip_fee)
      valid &= parseFloat(cc_tip_fee) >= 0 && parseFloat(cc_tip_fee) < 10
    }

    return valid
  }

  submitOnboardOrganization = mutation => {
    const {
      cc_tip_fee,
      invite_users,
      pay_period_interval,
      pay_period_reference_date
    } = this.state

    let input = {
      payroll: null,
      user_emails: invite_users,
      cc_tip_fee: parseFloat(cc_tip_fee)
    }
    if (!!pay_period_interval) {
      input.payroll = {
        interval: pay_period_interval,
        reference_date: pay_period_reference_date
      }
    }

    mutation({ variables: { input } })
  }

  stepContent = () => {
    const { user } = this.props
    const {
      step,
      loading,
      cc_tip_fee,
      invite_users,
      pay_period_interval,
      pay_period_reference_date
    } = this.state

    if (loading) {
      return <Loader inline active />
    }

    switch (step) {
      case STEP_INTRO:
        return <IntroStep />
      case STEP_TIP_FEE:
        return (
          <TipFeeStep
            tip_fee={cc_tip_fee}
            org_is_multi_unit={user.stores.length > 1}
            onChange={cc_tip_fee => this.setState({ cc_tip_fee })}
          />
        )
      case STEP_PAY_PERIOD:
        return (
          <PayPeriodStep
            interval={pay_period_interval}
            reference_date={pay_period_reference_date}
            onChangeInterval={pay_period_interval =>
              this.setState({ pay_period_interval })
            }
            onChangeReferenceDate={pay_period_reference_date =>
              this.setState({ pay_period_reference_date })
            }
          />
        )
      case STEP_INVITE_USERS:
        return (
          <InviteUsersStep
            invites={invite_users}
            org_is_multi_unit={user.stores.length > 1}
            onAddInvite={email =>
              this.setState({
                invite_users: [...invite_users, email]
              })
            }
            onRemoveInvite={email =>
              this.setState({
                invite_users: invite_users.filter(e => e !== email)
              })
            }
          />
        )
      default:
        return null
    }
  }

  render() {
    const { onCompleted } = this.props
    const valid_input = this.isValidInput()

    return (
      <Mutation mutation={ONBOARD_ORGANIZATION} onCompleted={onCompleted}>
        {(onboardOrganization, { loading }) => (
          <Modal size="tiny">
            <Modal.Header>
              Getting Started
              <Logo />
            </Modal.Header>
            <Modal.Content>{this.stepContent()}</Modal.Content>
            <Modal.Actions>
              {!valid_input && <InvalidInput />}
              {!this.isFirstStep() && (
                <NavBack
                  disabled={!valid_input || loading}
                  onClick={this.goBack}
                />
              )}
              {!this.isLastStep() && (
                <NavNext
                  disabled={!valid_input || loading}
                  onClick={this.goForward}
                />
              )}
              {this.isLastStep() && (
                <NavSubmit
                  loading={loading}
                  disabled={!valid_input || loading}
                  onClick={() =>
                    this.submitOnboardOrganization(onboardOrganization)
                  }
                />
              )}
            </Modal.Actions>
          </Modal>
        )}
      </Mutation>
    )
  }
}
