import moment from "moment"

import {
  Table,
  Button,
  PopupMenu,
  InactiveLabel,
  FutureDatedLabel
} from "../../../Shared"
import ViewRuleModal from "../Summary"
import CopyRuleModal from "../../Actions/CopyToLocations"
import ActivateRuleModal from "../../Actions/ActivateRule"
import DeactivateRuleModal from "../../Actions/DeactivateRule"
import { TableRow, SourceBadge, EffectiveTimeSpan } from "../../Styled"

import {
  useLocations,
  useStateObject,
  useSelectedLocation
} from "../../../../hooks"
import { ToastConsumer } from "../../../../context"
import { displayMoney, round } from "../../../../helpers"
import { month_day_year_format } from "../../../../constants"
import { RULE_TYPE_FLAT_RATE, ruleIsFutureDated } from "../../helpers"
import useAuthorization from "../../../../hooks/authorization"
import { rules_manage } from "../../../../constants/permissions"

export default ({ rule, refetch, editRule, duplicateRule }) => {
  const { hasPermission } = useAuthorization()
  const can_edit = hasPermission(rules_manage)
  const locations = useLocations()
  const future_dated = ruleIsFutureDated(rule)
  const selected_location = useSelectedLocation()

  const [state, setState] = useStateObject({
    view_rule: false,
    copy_request: false,
    show_actions: false,
    activate_rule: false,
    deactivate_rule: false
  })

  const {
    view_rule,
    copy_request,
    show_actions,
    activate_rule,
    deactivate_rule
  } = state

  return (
    <>
      <TableRow
        className="tip-distribution-rules-table-row"
        onClick={() =>
          setState({
            view_rule: { ...rule }
          })
        }
      >
        <Table.Cell>
          {rule.name}
          {!rule.active && !future_dated && <InactiveLabel />}
          {!!future_dated && <FutureDatedLabel />}
        </Table.Cell>
        {!selected_location?.id && (
          <Table.Cell>{rule.store?.name ?? rule.store_name}</Table.Cell>
        )}
        <Table.Cell>
          {!!rule.sales_category_names.length && (
            <SourceBadge
              key={rule.distributionSources[0].id}
              type={rule.distributionSources[0].source}
            >
              {rule.distributionSources[0].name}
            </SourceBadge>
          )}
          {!rule.sales_category_names.length &&
            rule.distributionSources.map(source => (
              <SourceBadge key={source.id} type={source.source}>
                {source.name}
              </SourceBadge>
            ))}
        </Table.Cell>
        <Table.Cell>
          {rule.type === RULE_TYPE_FLAT_RATE
            ? `${displayMoney(rule.percent_in)} / hr`
            : `${round(rule.percent_in, 2)}%`}
        </Table.Cell>
        <Table.Cell>{rule.distributionMethod.name}</Table.Cell>
        <Table.Cell>
          <EffectiveTimeSpan
            effective_date={rule.effective_date}
            deactivated_date={rule.deactivated_date}
          />
        </Table.Cell>
        <Table.Cell>
          {moment.utc(rule.created_at).local().format(month_day_year_format)}
        </Table.Cell>
        {can_edit && (
          <Table.Cell
            textAlign="right"
            className="distribution-rules-table-action-cell"
          >
            <PopupMenu
              open={!!show_actions}
              onClose={() => setState({ show_actions: false })}
              trigger={
                <Button
                  icon="ellipsis vertical"
                  size="mini"
                  onClick={e => {
                    e.stopPropagation()
                    setState({ show_actions: true })
                  }}
                />
              }
            >
              {(!!rule.active || !!future_dated) && (
                <>
                  <PopupMenu.Item
                    onClick={e => {
                      e.stopPropagation()
                      editRule(rule.id)
                    }}
                  >
                    Edit
                  </PopupMenu.Item>
                  <PopupMenu.Item
                    onClick={e => {
                      e.stopPropagation()
                      duplicateRule(rule)
                    }}
                  >
                    Duplicate
                  </PopupMenu.Item>
                  {locations.length > 1 && (
                    <PopupMenu.Item
                      onClick={e => {
                        e.stopPropagation()
                        setState({
                          copy_request: rule.id,
                          show_actions: false
                        })
                      }}
                    >
                      Copy to Locations
                    </PopupMenu.Item>
                  )}
                </>
              )}
              {!rule.active && !future_dated && (
                <PopupMenu.Item
                  onClick={e => {
                    e.stopPropagation()
                    setState({
                      activate_rule: rule,
                      show_actions: false
                    })
                  }}
                >
                  Activate Rule
                </PopupMenu.Item>
              )}
              <PopupMenu.Item
                danger
                onClick={e => {
                  e.stopPropagation()
                  setState({
                    deactivate_rule: rule,
                    show_actions: false
                  })
                }}
              >
                {!rule.active ? "Delete Rule" : "Deactivate/Delete"}
              </PopupMenu.Item>
            </PopupMenu>
          </Table.Cell>
        )}
      </TableRow>
      {!!view_rule && (
        <ViewRuleModal
          name={view_rule.name}
          type={view_rule.type}
          distribution_method={view_rule.distributionMethod.name}
          effective_date={view_rule.effective_date}
          deactivated_date={view_rule.deactivated_date}
          percent={view_rule.percent_in}
          revenue_centers={
            view_rule.revenueCenters?.map(({ name }) => name) ?? []
          }
          distribution_sources={view_rule.distributionSources.map(
            ({ name }) => name
          )}
          sale_categories={[...view_rule.sales_category_names]}
          collect_from={view_rule.input_job_code_names}
          distribute_to={view_rule.outputJobCodes.map(
            ({ name, distribution_weight, priority }) => ({
              name,
              weight: distribution_weight,
              priority
            })
          )}
          schedules={view_rule.schedules}
          onEdit={can_edit ? () => editRule(view_rule.id) : null}
          onClose={() => setState({ view_rule: false })}
        />
      )}
      <ToastConsumer>
        {toast => (
          <>
            {!!deactivate_rule && (
              <DeactivateRuleModal
                rule_id={deactivate_rule.id}
                location_id={deactivate_rule.store.id}
                min_deactivated={deactivate_rule.min_deactivated_date}
                allow_deactivate={!!deactivate_rule.active}
                onSuccess={(deactivate_rule = true) => {
                  setState({ deactivate_rule: false })
                  refetch()
                  toast({
                    type: "success",
                    message: `Rule ${
                      deactivate_rule ? "deleted" : "deactivated"
                    } successfully.`
                  })
                }}
                onError={(deactivate_rule = true) => {
                  setState({ deactivate_rule: false })
                  refetch()
                  toast({
                    type: "error",
                    message: `Failed to ${
                      deactivate_rule ? "delete" : "deactivate"
                    } rule.`
                  })
                }}
                onClose={() => setState({ deactivate_rule: false })}
              />
            )}
            {!!activate_rule && (
              <ActivateRuleModal
                rule_id={activate_rule.id}
                location_id={activate_rule.store.id}
                min_effective={activate_rule.min_deactivated_date}
                onSuccess={() => {
                  refetch()
                  setState({ activate_rule: false })
                  toast({
                    type: "success",
                    message: "Rule activated successfully."
                  })
                }}
                onError={() => {
                  refetch()
                  setState({ activate_rule: false })
                  toast({
                    type: "error",
                    message: "Failed to activate rule."
                  })
                }}
                onClose={() => setState({ activate_rule: false })}
              />
            )}
            {!!copy_request && (
              <CopyRuleModal
                rule_id={copy_request}
                onSuccess={() => {
                  refetch()
                  setState({ copy_request: false })
                  toast({
                    type: "success",
                    message: "Rule copied successfully."
                  })
                }}
                onClose={() => setState({ copy_request: false })}
              />
            )}
          </>
        )}
      </ToastConsumer>
    </>
  )
}
