import React, { useState } from "react"

import Popup from "../../Popup"
import Button from "../../Button"
import ResyncModal from "../../Modal/Resync"

import { project_info } from "../../../constants"
import { useLocations } from "../../../hooks"

export default ({ toast }) => {
  const [show_modal, showModal] = useState(false)
  const locations = useLocations()
  const is_resync_disabled = locations.every(
    location => location.settings.scheduled_jobs_enabled === "0"
  )

  return (
    <>
      <Popup
        key="control-resync-stores"
        position="left center"
        delay
        content={
          <>
            <div>Re-sync {project_info.name} with your</div>
            <div>point-of-sale provider.</div>
          </>
        }
        trigger={
          <Button
            id="settings-resync-control"
            icon="refresh"
            content="Sync"
            circular
            size="small"
            disabled={is_resync_disabled}
            onClick={() => showModal(true)}
          />
        }
      />
      {!!show_modal && (
        <ResyncModal toast={toast} onClose={() => showModal(false)} />
      )}
    </>
  )
}
