import React from "react"
import { colors } from "./../../constants"

export default ({
  style,
  inverted,
  primary = colors.info,
  secondary = colors.light3
}) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    style={{ ...style }}
    viewBox="0 0 3260 1180"
    preserveAspectRatio="xMidYMid meet"
  >
    <g id="layer101" fill={!!inverted ? secondary : colors.dark3} stroke="none">
      <path d="M1708 773 c-3 -245 -4 -260 -25 -293 -31 -50 -70 -70 -140 -70 -46 0 -66 5 -96 25 l-37 26 0 -201 c0 -110 3 -200 6 -200 3 0 17 9 31 20 34 26 131 56 228 69 48 7 332 11 771 11 l694 0 -1 258 c0 141 -3 250 -6 242 -14 -39 -57 -69 -112 -80 -133 -26 -126 -23 -129 -52 -3 -22 2 -30 23 -38 39 -15 76 -12 117 8 l36 19 26 -33 26 -33 -24 -16 c-80 -53 -214 -45 -276 16 -58 59 -29 162 53 188 18 5 55 12 82 16 63 9 85 20 85 45 0 53 -95 63 -181 19 -23 -11 -42 -19 -43 -17 -1 2 -12 17 -25 35 l-22 32 38 25 c107 71 280 50 319 -38 12 -28 13 -15 14 122 l0 152 -714 0 -715 0 -3 -257z m374 31 c45 -29 48 -29 48 -4 0 17 7 20 45 20 l45 0 0 -205 0 -205 -44 0 c-38 0 -45 3 -50 25 l-6 25 -22 -20 c-37 -34 -92 -45 -155 -32 -115 24 -169 101 -161 228 4 58 11 82 31 113 58 87 181 112 269 55z m487 -7 l41 -27 0 25 c0 23 4 25 45 25 l45 0 0 -205 0 -205 -50 0 -50 0 0 131 0 131 -35 35 c-32 32 -40 35 -76 30 -80 -11 -99 -53 -99 -219 l0 -108 -51 0 -52 0 5 143 c4 119 8 149 26 184 20 38 55 71 92 86 34 15 119 0 159 -26z" />
      <path d="M1914 706 c-29 -29 -34 -41 -34 -82 0 -67 27 -109 80 -125 85 -26 160 27 160 114 0 80 -46 127 -123 127 -42 0 -54 -5 -83 -34z" />
    </g>
    <g id="layer102" fill={primary} stroke="none">
      <path d="M1562 1114 c-23 -18 -71 -43 -105 -55 -62 -24 -64 -24 -692 -29 l-630 -5 -3 -432 -2 -433 590 0 590 0 0 330 0 330 50 0 50 0 0 -110 c0 -121 12 -166 51 -194 45 -31 119 -14 138 33 8 17 10 123 9 312 l-3 286 -43 -33z m-674 -226 l3 -107 36 25 c28 19 49 24 96 24 78 0 132 -26 172 -83 28 -39 30 -49 30 -128 0 -77 -3 -90 -28 -127 -41 -63 -135 -103 -206 -87 -17 3 -46 17 -66 30 l-35 24 -6 -24 c-5 -22 -12 -25 -50 -25 l-44 0 0 296 0 295 48 -3 47 -3 3 -107z m-368 -74 c6 -6 5 -24 -2 -47 -11 -35 -13 -37 -45 -31 -25 5 -39 2 -53 -11 -17 -16 -20 -32 -20 -122 l0 -103 54 0 c59 0 64 -5 58 -56 -4 -34 -4 -34 -58 -34 l-54 0 0 -55 0 -55 -50 0 -50 0 0 55 0 55 -40 0 -40 0 0 45 0 45 40 0 40 0 0 113 c0 130 14 170 71 200 36 19 130 20 149 1z m170 -199 l0 -205 -50 0 -50 0 0 205 0 205 50 0 50 0 0 -205z m-6 -278 c12 -9 17 -23 14 -47 -6 -62 -76 -73 -107 -18 -30 53 41 103 93 65z" />
      <path d="M990 734 c-64 -22 -90 -57 -90 -123 0 -62 40 -102 108 -109 49 -4 54 -2 87 31 28 28 35 42 35 76 0 72 -31 112 -95 124 -17 3 -37 3 -45 1z" />
    </g>
  </svg>
)
