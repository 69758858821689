import { useQuery, useMutation } from "@apollo/client"
import { SEND_EMPLOYEE_REGISTRATIONS } from "../../../../graphql/mutations"
import { EMPLOYEE_SEARCH } from "../../../../graphql/queries"
import { useToast } from "../../../../hooks"
import EmployeeInvitesModal from "../../../EmployeeInvites/Modal"

export default ({ store_id, onEditRequest, onClose }) => {
  const toast = useToast()

  const { data, loading: employees_loading } = useQuery(EMPLOYEE_SEARCH, {
    variables: {
      page: 1,
      first: 10000,
      filters: {
        active: true,
        store_id,
        include_none: false,
        no_user: true
      }
    }
  })

  const [
    sendRegistrationEmails,
    { loading: sending_registration }
  ] = useMutation(SEND_EMPLOYEE_REGISTRATIONS, {
    onCompleted: () => {
      onClose()
      toast({
        type: "success",
        message: <p>Invites sent successfully.</p>
      })
    }
  })

  const handleSendEmployeeInvites = invites => {
    sendRegistrationEmails({
      variables: {
        invites: invites.map(({ id, email }) => ({
          employee_id: id,
          email
        }))
      }
    })
  }

  const employees = data?.employeeSearch?.data ?? []

  return (
    <EmployeeInvitesModal
      header={`Invite Employee${employees.length > 1 ? "s" : ""} to Sign Up`}
      employees={employees}
      loading={employees_loading || sending_registration}
      onClose={onClose}
      onSubmit={handleSendEmployeeInvites}
      onEditRequest={onEditRequest}
    />
  )
}
