import React from "react"
import Styled from "styled-components"

import Modal from "./"
import Logo from "../Logo/"

import { colors, project_info } from "../../constants"

const Content = Styled.div`
  font-size: 1.2em;
  margin-bottom: 2em;
  color: ${colors.light2};
`
const Link = Styled.a`
  color: ${colors.info};
`

export default ({ closeModals }) => (
  <Modal size="tiny" basic onClose={() => closeModals()}>
    <Modal.Header onClose={() => closeModals()}>How can we help?</Modal.Header>
    <Modal.Content>
      <Content>
        <p>Let us know what's on your mind!</p>
        <p>
          Give us a shout at{" "}
          <Link href={`mailto:${project_info.support_email}`}>
            {project_info.support_email}
          </Link>
          .
        </p>
        <p>Our team will respond as quickly as possible.</p>
      </Content>
      <Logo />
    </Modal.Content>
  </Modal>
)
