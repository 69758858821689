import { useState, useEffect } from "react"
import { Form } from "semantic-ui-react"
import { breakdown_view } from "../../../../../../../constants/permissions"
import useAuthorization from "../../../../../../../hooks/authorization"
import { ToggleField } from "../Styled"

export const breakdownStep = ({ permissions, edit_mode }, onChange) => {
  const { [breakdown_view]: can_view } = permissions

  const [visited, setVisited] = useState(false)
  return {
    name: "Breakdown",
    complete: !edit_mode || visited,
    render: () => (
      <Breakdown
        can_view={can_view}
        edit_mode={edit_mode}
        onChange={new_state =>
          onChange({
            permissions: {
              ...permissions,
              ...new_state
            }
          })
        }
        onMount={() => setVisited(true)}
      />
    )
  }
}

const Breakdown = ({ can_view, edit_mode, onChange, onMount }) => {
  const { hasPermission } = useAuthorization()

  useEffect(() => onMount(), [])
  return (
    <>
      <div style={{ marginBottom: "1rem" }}>
        <strong>Note:</strong> The Breakdown page only requires general access
        and manage permissions.
      </div>
      <Form>
        <ToggleField
          checked={can_view}
          disabled={!edit_mode || !hasPermission(breakdown_view)}
          label="Breakdown Tab Visible"
          onChange={checked => onChange({ [breakdown_view]: checked })}
        />
      </Form>
    </>
  )
}
