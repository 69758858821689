import { Form } from "semantic-ui-react"
import Styled from "styled-components"
import { colors } from "../../../../../../constants"
import { Checkbox, Icon, Table, Toggle } from "../../../../../Shared"

export const PermissionsTable = Styled(({ className, permissions = [] }) => (
  <Table basic="very" className={className}>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell />
        <Table.HeaderCell textAlign="right">Access</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {permissions.map(({ display_name, selected }) => (
        <Table.Row key={display_name}>
          <Table.Cell>{display_name}</Table.Cell>
          <Table.Cell textAlign="right">
            {selected ? <Icon name="checkmark" color={colors.green} /> : null}
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
))`
	td:first-child {
		padding-left: 0 !important;
	}
`

export const TogglePermissionsTable = Styled(
  ({ className, rows = [], selected = {}, edit_mode, onChange }) => (
    <Table basic="very" className={className}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell textAlign="right">Access</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {rows.map(({ name, description, disabled }) => {
          const checked = !!selected[name]
          return (
            <Table.Row
              key={name}
              onClick={
                edit_mode && !disabled ? () => onChange(name, !checked) : null
              }
            >
              <Table.Cell>
                <PermissionLabel disabled={disabled}>
                  {description}
                </PermissionLabel>
              </Table.Cell>
              <Table.Cell textAlign="right">
                {edit_mode ? (
                  <Checkbox checked={checked} disabled={disabled} />
                ) : (
                  <Icon
                    name="checkmark"
                    color={checked ? colors.green : colors.transparent}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
)`
	td:first-child {
		padding-left: 0 !important;
	}
`

const PermissionLabel = Styled.div`
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
`

export const ToggleField = Styled(
  ({ className, checked, label, disabled, onChange }) => (
    <Form.Field className={className}>
      <label>{label}</label>
      <div className="toggle-container">
        <Toggle
          checked={checked}
          disabled={disabled}
          onChange={(e, { checked }) => onChange(checked)}
        />
      </div>
    </Form.Field>
  )
)`
  & {
    display: grid;
    grid-template-columns: 75% 25%;
  }
  & > label {
    font-size: 1.1rem !important;
  }
  & > .toggle-container {
    text-align: right;
  }
`
