import { List } from "semantic-ui-react"
import Link from "./../Link"

import { project_info, paths } from "./../../constants"

import { settings_manage_users } from "../../constants/permissions"

const TAB_USERS = "tab-navigation-users"
const TAB_LOCATIONS = "tab-navigation-locations"
const TAB_CC_TIP_FEE = "tab-navigation-tip-fee"
const TAB_PAY_PERIOD = "tab-navigation-pay-period"
const TAB_PEER_TO_PEER = "tab-navigation-peer-to-peer"

const clickTab = id => {
  requestAnimationFrame(() => {
    try {
      document.getElementById(id).click()
    } catch {}
  })
}

export default {
  onClose: () => clickTab(TAB_LOCATIONS),
  steps: [
    {
      target: "body",
      placement: "center",
      title: `${project_info.name} Settings`,
      content: (
        <>
          <p>I promise you, it's even more fun than it sounds.</p>
          <p>Follow along for a closer look under the hood.</p>
        </>
      )
    },
    {
      target: `#${TAB_LOCATIONS}`,
      name: "locations-tab",
      title: "Location Settings",
      content: (
        <>
          <p>You can manage basic settings for individual locations here.</p>
        </>
      ),
      onMount: () => clickTab(TAB_LOCATIONS),
      next: {
        onClick: (step, onNext) => {
          try {
            if (!document.getElementById("location-menu-general")) {
              requestAnimationFrame(() => {
                try {
                  document
                    .getElementsByClassName("location-row-header")[0]
                    .click()
                } catch {}
              })
            }
            requestAnimationFrame(() => {
              try {
                onNext()
              } catch {}
            })
          } catch {}
        }
      }
    },
    {
      target: ".location-row",
      title: "A closer look:",
      content: (
        <>
          <p>General location settings include name, timezone, and tip fee.</p>
        </>
      ),
      onMount: () => {
        requestAnimationFrame(() => {
          try {
            document.getElementById("location-menu-general").click()
          } catch {}
        })
      }
    },
    {
      target: "#location-menu-employees",
      name: "employees-tab",
      title: "Employees Tab",
      content: (
        <>
          <p>Your employees are listed here.</p>
          <p>They're synced with your point-of-sale every morning.</p>
        </>
      ),
      onMount: () => {
        requestAnimationFrame(() => {
          try {
            document.getElementById("location-menu-employees").click()
          } catch {}
        })
      }
    },
    {
      target: "#employee-settings-assigned-job-code",
      title: "Job Code Override",
      content: (
        <>
          <p>
            When applying tip sharing logic, {project_info.name} uses shift
            information to match employees with a job code.
          </p>
          <p>
            You may choose to override shift information by assigning a job code
            to an employee here.
          </p>
        </>
      )
    },
    {
      target: "#location-menu-job-codes",
      title: "Job Codes Tab",
      content: () => (
        <>
          <p>
            Job codes are accessible from this tab. They're also synced daily.
          </p>
        </>
      ),
      onMount: () => {
        requestAnimationFrame(() => {
          try {
            document.getElementById("location-menu-job-codes").click()
          } catch {}
        })
      },
      back: {
        onClick: ({ goToStep }) => goToStep("employees-tab")
      }
    },
    {
      target: "#job-code-settings-status",
      title: "Distribution Enabled",
      content: (
        <>
          <p>
            Disabled job codes won't be visible when creating tip sharing rules
            and tip pools.
          </p>
        </>
      )
    },
    {
      target: `#${TAB_USERS}`,
      name: "users-tab",
      title: `Manage your Users`,
      content: ({ hasPermission }) => {
        if (hasPermission(settings_manage_users)) {
          return (
            <p>
              As an admin, you can invite new users to join the{" "}
              {project_info.name} team.
            </p>
          )
        }
        return (
          <>
            <p>
              From the user tab, you can invite employees to register for the My
              {project_info.name} mobile app.
            </p>
            <p>
              Employees can login and view their location's tip sharing logic
              along with a personalized summary of tips.
            </p>
            <p>
              <i>Pro Tip:</i> Managers are required by law to disclose tip
              distribution processes to employees. My{project_info.name}{" "}
              provides an easy way to reduce legal liability and protect your
              business.
            </p>
          </>
        )
      },
      onMount: () => clickTab(TAB_USERS),
      back: {
        onClick: ({ goToStep }) => goToStep("locations-tab")
      }
    },
    {
      target: `#${TAB_CC_TIP_FEE}`,
      title: "Credit Card Tip Fee",
      content: ({ user }) => (
        <>
          <p>
            CC tip fees are deducted from take home pay after pooling and tip
            sharing rules have applied.
          </p>
          {user.stores.length > 1 && (
            <p>
              You can customize the tip fee for specific locations if necessary.
            </p>
          )}
          <p>Be aware of the following:</p>
          <List style={{ textAlign: "left" }} bulleted>
            <List.Item>
              CA, MA, and ME do not allow for CC tip deductions.
            </List.Item>
            <List.Item>DE, KY, and MT laws can vary by county.</List.Item>
            <List.Item>
              Consult your legal advisor if you're unsure about the laws in your
              area.
            </List.Item>
          </List>
        </>
      ),
      onMount: () => clickTab(TAB_CC_TIP_FEE),
      back: {
        onClick: ({ goToStep }) => goToStep("users-tab")
      }
    },
    {
      target: `#${TAB_PAY_PERIOD}`,
      name: "pay-period-tab",
      title: "Pay Period Setup",
      content: ({ user }) => (
        <>
          <p>
            Your pay period configuration enables enhanced reporting and
            automatic breakdown snapshots.
          </p>
          {!user.organization.payroll_interval && (
            <>
              <p>
                Simply select the appropriate interval and the start date of
                your current pay period.
              </p>
              <p>I'd recommend setting this up after the tour.</p>
            </>
          )}
        </>
      ),
      onMount: () => clickTab(TAB_PAY_PERIOD)
    },
    {
      target: `#${TAB_PEER_TO_PEER}`,
      title: "Peer-to-Peer Tip Sharing",
      content: ({ user }) => (
        <>
          <p>This one's pretty cool - and it's free to use.</p>
          <p>
            Peer-to-Peer allows employees to share tips directly with one
            another through the app.
          </p>
          <p>When enabled, a new column will appear in the Breakdown.</p>
          {!user.organization.payroll_interval && (
            <p>
              <i>Note:</i> Pay period is required to calculate available funds
              for Peer-to-Peer Tip Sharing.
            </p>
          )}
        </>
      ),
      onMount: () => clickTab(TAB_PEER_TO_PEER),
      back: {
        onClick: ({ goToStep }, goBack) => {
          if (document.getElementById(TAB_PAY_PERIOD)) {
            goBack()
          } else {
            goToStep("users-tab")
          }
        }
      }
    },
    {
      target: "#help-button",
      title: "Your turn to drive.",
      content: (
        <>
          <p>That concludes the Settings tour.</p>
          <p>
            <Link href={paths.contact} target="_blank">
              Let our team know
            </Link>{" "}
            if I left any questions unanswered.
          </p>
        </>
      ),
      disable_next: true
    }
  ]
}
