import { useState } from "react"
import Styled from "styled-components"
import { Dropdown, Header } from "semantic-ui-react"

import { Modal, Table, Button, SubmitButton } from "../Shared"

import { useBusinessDates } from "../../hooks"
import { ucwords } from "../../helpers/string"
import { getNextDay } from "../../helpers/datetime"
import {
  colors,
  hours_per_day,
  hour_noon,
  hour_midnight
} from "../../constants"

// default export
const ScheduleTable = ({
  schedules,
  include_header = true,
  onUpdate = false,
  fitted = true,
  row_hover = true,
  ...props
}) => {
  const { business_start_hour } = useBusinessDates()

  let enable_edit = onUpdate !== false
  return (
    <Table fitted={fitted} {...props}>
      {!!include_header && onUpdate !== false && (
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Day</Table.HeaderCell>
            <Table.HeaderCell>Start</Table.HeaderCell>
            <Table.HeaderCell>End</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
      )}
      <Table.Body>
        {schedules
          .filter(schedule => enable_edit || schedule.active)
          .map(schedule => ({
            // apply default values
            start_time: business_start_hour,
            end_time: business_start_hour + hours_per_day,
            active: false,
            // override default values
            ...schedule
          }))
          .map(schedule => (
            <WeekdayRow
              hover={row_hover}
              key={schedule.day}
              active={schedule.active}
            >
              <Table.Cell disabled={!schedule.active}>
                {ucwords(schedule.day)}
              </Table.Cell>
              {!enable_edit && (
                <Table.Cell>
                  <TimeValue
                    day={schedule.day}
                    time={schedule.start_time}
                    start={schedule.start_time}
                    end={schedule.end_time}
                  />
                  &nbsp;
                  <Thin>to</Thin>
                  &nbsp;
                  <TimeValue
                    day={schedule.day}
                    time={schedule.end_time}
                    start={schedule.start_time}
                    end={schedule.end_time}
                  />
                </Table.Cell>
              )}
              {enable_edit && (
                <>
                  <Table.Cell disabled={!schedule.active}>
                    <TimeInput
                      day={schedule.day}
                      time={schedule.start_time}
                      min={business_start_hour}
                      // max - 0.25 is set to ensure minimum 15 min window
                      max={schedule.end_time - 0.25}
                      onChange={val =>
                        onUpdate(
                          updateSchedules(
                            [
                              {
                                ...schedule,
                                start_time: val,
                                enable_copy: true
                              }
                            ],
                            schedules
                          )
                        )
                      }
                    />
                  </Table.Cell>
                  <Table.Cell disabled={!schedule.active}>
                    <TimeInput
                      day={schedule.day}
                      time={schedule.end_time}
                      // min + 0.25 is set to ensure minimum 15 min window
                      min={schedule.start_time + 0.25}
                      max={business_start_hour + hours_per_day}
                      onChange={val =>
                        onUpdate(
                          updateSchedules(
                            [{ ...schedule, end_time: val, enable_copy: true }],
                            schedules
                          )
                        )
                      }
                    />
                  </Table.Cell>
                </>
              )}
              {enable_edit && (
                <Table.Cell>
                  <DayControls
                    schedule={schedule}
                    onApplyToAll={() => {
                      onUpdate(
                        updateSchedules(
                          schedules.map(day => ({
                            ...day,
                            start_time: schedule.start_time,
                            end_time: schedule.end_time,
                            enable_copy: false
                          })),
                          schedules
                        )
                      )
                    }}
                    onToggleActive={() =>
                      onUpdate(
                        updateSchedules(
                          [
                            {
                              ...schedule,
                              active: !schedule.active
                            }
                          ],
                          schedules
                        )
                      )
                    }
                  />
                </Table.Cell>
              )}
            </WeekdayRow>
          ))}
      </Table.Body>
    </Table>
  )
}
const getDisplayHour = input => {
  // resulting hour will be >= 0 and < 24
  let hour = input % hour_midnight

  // resulting hour will be >= 0 and < 12
  hour = hour % hour_noon

  // return display hour accounting for noon
  return hour >= 1 ? hour : hour_noon
}

const updateSchedules = (updated_days, schedules) =>
  schedules.map(schedule => {
    const update = updated_days.find(({ day }) => day === schedule.day)
    if (update) {
      return { ...update }
    }
    return { ...schedule, enable_copy: false }
  })

const Thin = Styled.div`
  display: inline-block;
  font-weight: 300;
`
const Strong = Styled.div`
  display: inline-block;
  font-weight: 600;
`

const bizDayHours = (min, max) => {
  let hours = []
  // for business start hours that are a fraction of an hour, take the base hour
  for (let i = Math.floor(min); i <= Math.floor(max); i++) {
    hours.push(i)
  }
  return hours
}

const hourValToTime = (day, hour, minutes) => (
  <>
    <span>
      {/* calculate hour based on 12 hour increments so am/pm applies */}
      {getDisplayHour(hour)}:{minutes < 10 ? `0${minutes}` : minutes}
      {hour % hour_midnight < hour_noon ? "am" : "pm"}
    </span>
    {hour >= 24 ? (
      <>
        &nbsp;<Thin>({ucwords(getNextDay(day))})</Thin>
      </>
    ) : (
      ""
    )}
  </>
)

const timeOptions = (day, min, max) =>
  bizDayHours(min, max)
    .reduce((acc, hour) => {
      acc.push({
        value: hour,
        text: <Strong>{hourValToTime(day, hour, 0)}</Strong>
      })
      if (hour <= max) {
        // include fractions of an hour based on biz start time
        if (hour <= max - 0.25) {
          acc.push({
            value: hour + 0.25,
            text: hourValToTime(day, hour, 15)
          })
        }
        if (hour <= max - 0.5) {
          acc.push({
            value: hour + 0.5,
            text: hourValToTime(day, hour, 30)
          })
        }
        if (hour <= max - 0.75) {
          acc.push({
            value: hour + 0.75,
            text: hourValToTime(day, hour, 45)
          })
        }
      }
      return acc
    }, [])
    .filter(
      option =>
        (!min || option.value >= min) &&
        (!max || option.value <= max || option.value >= min)
    )

const TimeValue = Styled(({ day, time, start, end, ...props }) => (
  <div {...props}>
    {timeOptions(day, start, end).find(({ value }) => value === time).text}
  </div>
))`
  display: inline-block;
  &&& > div > span {
    font-weight: 400;
  }
`

const TimeInput = Styled(({ day, time, min, max, onChange, ...props }) => (
  <Dropdown
    {...props}
    compact
    scrolling
    value={time}
    options={[...timeOptions(day, min, max)]}
    onChange={(e, { value }) => onChange(value)}
  />
))`
  &&& > .text > :first-child {
    font-weight: 400 !important;
  }
`

const DayControls = Styled(
  ({ schedule, onApplyToAll, onToggleActive, ...props }) => (
    <div {...props}>
      {!!schedule.active && !!schedule.enable_copy && (
        <Button
          size="small"
          compact
          basic
          circular
          popup={{
            content: (
              <>
                <div>Copy {ucwords(schedule.day)}'s schedule</div>
                <div>to all other days.</div>
              </>
            ),
            position: "top right",
            delay: true
          }}
          onClick={() => onApplyToAll()}
        >
          Apply To All
        </Button>
      )}
      <Button
        size="small"
        compact
        basic
        circular
        popup={{
          content: (
            <>
              <div>{!!schedule.active ? "Disable" : "Enable"} this rule</div>
              <div>on {ucwords(schedule.day)}s.</div>
            </>
          ),
          position: "top right",
          delay: true
        }}
        onClick={() => onToggleActive()}
      >
        {!!schedule.active ? "Disable" : "Enable"}
      </Button>
    </div>
  )
)`
  text-align: right;
  &&& > .button {
    background-color: ${colors.white} !important;
  }
`

const WeekdayRow = Styled(({ active, ...props }) => <Table.Row {...props} />)`
  ${({ active, hover = true }) =>
    !!active
      ? ``
      : `
    background-color: ${colors.light2};
    ${
      !hover
        ? ""
        : `
    $:hover {
      background-color: ${colors.light2} !important;
    }
    `
    }
  `}
`

export const Description = Styled(props => <Header.Subheader {...props} />)`
  color: ${colors.dark2} !important;
  font-weight: 300 !important;
  margin-top: 0.15rem !important;
`

export const ScheduleTableModal = ({
  onClose,
  onSubmit = false,
  closeOnDimmerClick = false,
  ...props
}) => {
  const [schedules, setSchedules] = useState([...props.schedules])

  const enable_edit = onSubmit !== false

  return (
    <Modal
      size="small"
      onClose={onClose}
      closeOnDimmerClick={closeOnDimmerClick}
    >
      <Modal.Header onClose={onClose}>
        {enable_edit && "Configure a "}Custom Schedule
      </Modal.Header>
      <Modal.Content>
        <Description>
          Tips are only distributed within the specified start & end time for
          each business day.
        </Description>
        <br />
        <ScheduleTable
          schedules={schedules}
          onUpdate={!enable_edit ? false : schedules => setSchedules(schedules)}
        />
      </Modal.Content>
      {enable_edit && (
        <Modal.Actions>
          <Button onClick={onClose}>Cancel</Button>
          <SubmitButton
            label="Apply"
            disabled={schedules.filter(day => !!day.active).length === 0}
            onClick={() => onSubmit(schedules)}
          />
        </Modal.Actions>
      )}
    </Modal>
  )
}

export default ScheduleTable
