import { FilterRow, FilterValue } from "../Styled"
import LocationSearch from "../../../../Search/Location"

import { formatListForSentence } from "../../../../../helpers"

export default ({
  location,
  available_locations,
  dependent_filters = {},
  onChange
}) => {
  const applied_dependencies = Object.keys(dependent_filters)
    .filter(key => !!dependent_filters[key])
    .map(filter => filter.replace(/_/g, " "))
  let disabled_msg = null
  if (applied_dependencies.length) {
    disabled_msg = (
      <>
        To modify this filter, first clear the{" "}
        {formatListForSentence(applied_dependencies)} filter
        {applied_dependencies.length > 1 && "s"}.
      </>
    )
  }

  return (
    <FilterRow name="Location">
      {!!location && (
        <FilterValue
          value={location.name}
          disabled_message={disabled_msg}
          onRemove={() => onChange(null)}
        />
      )}
      {!location && (
        <>
          <LocationSearch
            aligned="right"
            locations={available_locations}
            onChange={({ location }) => onChange({ ...location })}
          />
        </>
      )}
    </FilterRow>
  )
}
