import moment from "moment"

import {
  useBusinessDates,
  useCurrentPayPeriod,
  useEtaEnabled
} from "../../hooks"
import { weekdays, hour_midnight, hours_per_day } from "../../constants"
import { round, toInt, isNumber, getNextDay, getLastDay } from "../../helpers"

export { OBJECT_NAME as OBJECT_NAME_TIP_SHARE } from "./TipShare/helpers"
export { OBJECT_NAME as OBJECT_NAME_TIP_POOL } from "./TipPool/helpers"

// distro methods
export const DISTRO_METHOD_DAILY = "Daily"
export const DISTRO_METHOD_WEEKLY = "Weekly"
export const DISTRO_METHOD_HOURLY = "Every Hour"
export const DISTRO_METHOD_HALF_HOUR = "Every Half Hour"
export const DISTRO_METHOD_SALE_TIME = "Time of Sale"
export const DISTRO_METHOD_SHIFT_TIME = "Time of Shift"

export const BASE_DISTRIBUTION_METHODS = {
  [DISTRO_METHOD_DAILY]: "Day",
  [DISTRO_METHOD_HOURLY]: "Hour",
  [DISTRO_METHOD_HALF_HOUR]: "Half Hour",
  [DISTRO_METHOD_SALE_TIME]: "Time a Sale is Made"
}
export const WEEKLY_ENABLED_DISTRIBUTION_METHODS = {
  ...BASE_DISTRIBUTION_METHODS,
  [DISTRO_METHOD_WEEKLY]: "Week"
}
// weekly distribution methods for cash rules
export const CASH_WEEKLY_ENABLED_DISTRIBUTION_METHODS = {
  [DISTRO_METHOD_DAILY]: "Day",
  [DISTRO_METHOD_WEEKLY]: "Week"
}
export const DEFAULT_DISTRO_METHOD = DISTRO_METHOD_DAILY
// flat rate distribution methods
export const FLAT_RATE_BASE_DISTRIBUTION_METHODS = {
  [DISTRO_METHOD_DAILY]: "Day",
  [DISTRO_METHOD_HOURLY]: "Hour",
  [DISTRO_METHOD_HALF_HOUR]: "Half Hour",
  [DISTRO_METHOD_SHIFT_TIME]: "Time of Shift"
}
export const FLAT_RATE_WEEKLY_ENABLED_DISTRIBUTION_METHODS = {
  ...FLAT_RATE_BASE_DISTRIBUTION_METHODS,
  [DISTRO_METHOD_WEEKLY]: "Week"
}

// distro source types
export const DISTRO_SOURCE_TYPE_SALES = "sale_amount"
export const DISTRO_SOURCE_TYPE_TIPS = "sale_tip"
export const DISTRO_SOURCE_TYPE_CASH = "shift_tip"

// tip distribution sources
export const DISTRO_SOURCE_SALE = "Sale Item Price"
export const DISTRO_SOURCE_GC_TIP = "Gift Card Tip"
export const DISTRO_SOURCE_CC_TIP = "Credit Card Tip"
export const DISTRO_SOURCE_SERVICE_CHARGE = "Gratuity Service Charge"
export const DISTRO_SOURCE_CASH = "Declared Cash Tip"

export const TIP_DISTRO_SOURCES_ALL = [
  DISTRO_SOURCE_CC_TIP,
  DISTRO_SOURCE_GC_TIP,
  DISTRO_SOURCE_SERVICE_CHARGE
]
export const TIP_DISTRO_SOURCES_DEFAULT = [
  DISTRO_SOURCE_CC_TIP,
  DISTRO_SOURCE_GC_TIP
]

export const RULE_TYPE_SHARE = "tip_share"
export const RULE_TYPE_POOL = "tip_pool"
export const RULE_TYPE_FLAT_RATE = "flat_rate"
export const RULE_TYPE_SALE_ASSIGNED_POOL = "sale_assigned_pool"
export const RULE_SHARING_TYPES = [RULE_TYPE_SHARE, RULE_TYPE_FLAT_RATE]
export const RULE_POOLING_TYPES = [RULE_TYPE_POOL, RULE_TYPE_SALE_ASSIGNED_POOL]

export const DEFAULT_DISTRO_WEIGHT = 1
export const DEFAULT_DISTRO_PRIORITY = 1
export const MAX_DISTRO_WEIGHT = 10000

export const SORT_DIRECTION_ASC = "asc"
export const SORT_DIRECTION_DESC = "desc"
export const DEFUALT_RULE_STATUS = "active"
export const INACTIVE_RULE_STATUS = "inactive"
export const DEFAULT_SORT_FIELD = "name"

export const RULE_FILTER_NAMESPACE = "rules"
export const ruleFiltersNamespace = (tab = null) =>
  `${RULE_FILTER_NAMESPACE}${!!tab ? `.${tab}` : ""}`

export const TIP_POOLING_TAB = "tip-pools"
export const TIP_SHARING_TAB = "tip-sharing"
export const moduleTabs = selected_view => [
  {
    id: TIP_SHARING_TAB,
    name: "Tip Sharing",
    selected: selected_view == TIP_SHARING_TAB
  },
  {
    id: TIP_POOLING_TAB,
    name: "Tip Pools",
    selected: selected_view == TIP_POOLING_TAB
  }
]

export const FILTERS_TEMPLATE = {
  location: null,
  revenue_center: null,
  distribute_from: null,
  distribute_to: null,
  distribution_source: {
    [DISTRO_SOURCE_SALE]: false,
    [DISTRO_SOURCE_CC_TIP]: false,
    [DISTRO_SOURCE_GC_TIP]: false,
    [DISTRO_SOURCE_CASH]: false,
    [DISTRO_SOURCE_SERVICE_CHARGE]: false
  },
  source_names: [],
  status: DEFUALT_RULE_STATUS,
  sort_field: DEFAULT_SORT_FIELD,
  sort_direction: SORT_DIRECTION_ASC
}

/* === rule status helpers === */
export const ruleIsEffective = (
  time,
  { effective_date, deactivated_date = null, deleted = false }
) => {
  const reference = moment(time)
  const effective = moment(effective_date)
  const deactivated = !deactivated_date ? null : moment(deactivated_date)

  if (deleted) {
    // check if rule is deleted
    return false
  }

  if (effective.isAfter(reference)) {
    // rule effective after given date
    return false
  }

  if (!deactivated) {
    // rule not deactivated
    return true
  }

  if (effective.isAfter(deactivated)) {
    // rule will be activated in the future
    return true
  }

  if (deactivated.isAfter(reference) && deactivated.isAfter(effective)) {
    // rule will be deactivated in the future
    return true
  }

  return false
}

// return true if "Status: Active" filter should include this rule
export const ruleIsActive = rule =>
  ruleIsEffective(moment(), rule) || ruleIsFutureDated(rule)

// query filter acive rules
export const ruleSearchActiveFilter = status => {
  if (status === DEFUALT_RULE_STATUS) {
    return true
  } else if (status === INACTIVE_RULE_STATUS) {
    return false
  }
  return null
}

export const ruleIsFutureDated = rule =>
  !rule.deleted &&
  moment(rule.effective_date).isAfter(moment()) &&
  (!rule.deactivated_date ||
    moment(rule.deactivated_date).isAfter(rule.effective_date))

/* === rule schedule helpers === */

export const useDefaultRuleSchedules = () => {
  const { business_start_hour } = useBusinessDates()
  return weekdays.map(day => ({
    day: day,
    start_time: business_start_hour,
    end_time: business_start_hour + hours_per_day,
    active: true
  }))
}

export const useIsCustomRuleSchedule = input_schedules => {
  if (input_schedules.length > 0) {
    const default_schedules = useDefaultRuleSchedules()
    let rule_schedules = [...input_schedules]
    if (!!rule_schedules[0].day_start) {
      // schedules are formatted for submit
      rule_schedules = useFormatSchedulesForDisplay(rule_schedules)
    }

    const schedulesMatch = (schedule_a, schedule_b) => {
      try {
        return (
          schedule_a.day === schedule_b.day &&
          schedule_a.start_time === schedule_b.start_time &&
          schedule_a.end_time === schedule_b.end_time &&
          schedule_a.active === schedule_b.active
        )
      } catch {}

      return false
    }

    return !default_schedules.reduce(
      (acc, default_schedule) =>
        acc &&
        schedulesMatch(
          default_schedule,
          rule_schedules.find(({ day }) => day === default_schedule.day)
        ),
      true
    )
  }
}

export const useFormatSchedulesForDisplay = schedules => {
  const { business_start_hour } = useBusinessDates()
  const schedule_by_day = schedules.reduce((acc, data) => {
    let day_key =
      data.hour_start < business_start_hour
        ? getLastDay(data.day_start)
        : data.day_start
    return {
      ...acc,
      [day_key]: {
        day: day_key,
        start_time:
          data.hour_start < business_start_hour
            ? data.hour_start + hours_per_day
            : data.hour_start,
        end_time:
          data.hour_end <= business_start_hour
            ? data.hour_end + hours_per_day
            : data.hour_end,
        active: true
      }
    }
  }, {})

  return weekdays.map(day => ({
    day: day,
    // default values
    start_time: business_start_hour,
    end_time: business_start_hour + hours_per_day,
    active: false,
    // override with specified values when they exist
    ...schedule_by_day[day]
  }))
}

export const formatSchedulesForSubmit = schedules =>
  schedules
    .filter(({ active }) => !!active)
    .map(({ day, start_time, end_time }) => ({
      day_start: start_time >= hour_midnight ? getNextDay(day) : day,
      day_end: end_time >= hour_midnight ? getNextDay(day) : day,
      hour_start:
        start_time >= hour_midnight ? start_time - hour_midnight : start_time,
      hour_end: end_time >= hour_midnight ? end_time - hour_midnight : end_time
    }))

/* === rule job codes helpers === */

// check to see if a set of job codes have weights configured
export const hasWeightsConfigured = job_codes =>
  job_codes.length > 1 &&
  !job_codes.reduce(
    (acc, { weight, distribution_weight }) =>
      acc && (weight ?? distribution_weight) === DEFAULT_DISTRO_WEIGHT,
    true
  )

// create object grouping distribute-to jobs by priority
export const groupJobsByPriority = (job_codes, max_priority_fill = null) => {
  const max_priority = !!max_priority_fill
    ? max_priority_fill
    : maxDistributionPriority(job_codes)
  let result = {}

  for (let p = DEFAULT_DISTRO_PRIORITY; p <= max_priority; p++) {
    result[p] = []
  }
  result = job_codes.reduce((acc, job_code) => {
    try {
      acc[job_code.priority].push(job_code)
    } catch {}
    return acc
  }, result)

  // filter out priority levels with no jobs set
  if (!max_priority_fill) {
    return [
      ...Object.keys(result).filter(priority => result[priority].length > 0)
    ]
      .sort((a, b) => a > b)
      .map((priority, idx) =>
        result[priority].map(job_code => ({
          ...job_code,
          priority: idx + DEFAULT_DISTRO_PRIORITY
        }))
      )
      .reduce(
        (acc, group, idx) => ({
          ...acc,
          [idx + DEFAULT_DISTRO_PRIORITY]: [...group]
        }),
        {}
      )
  }

  return result
}

export const maxDistributionPriority = job_codes =>
  toInt(
    job_codes.reduce(
      (acc, { priority }) => Math.max(acc, priority),
      DEFAULT_DISTRO_PRIORITY
    )
  )

export const readableRuleType = type => {
  switch (type) {
    case RULE_TYPE_SALE_ASSIGNED_POOL:
      return "Custom Team Adjustment"
    case RULE_TYPE_POOL:
      return "Tip Pool"
    case RULE_TYPE_SHARE:
    default:
      return "Tip Share"
  }
}

export const handleSourceNames = (source_names, name) => {
  let idx = source_names.indexOf(name)
  // if name exists in source_names, remove it
  if (idx > -1) {
    return source_names.filter(function (item) {
      return item !== name
    })
  }
  // default: add name to array of source_names
  return [...source_names, name]
}

export const useShowEtaFinalizeRuleWarning = date => {
  const eta_enabled = useEtaEnabled()
  const { businessStartFromDate } = useBusinessDates()
  const current_pay_period = useCurrentPayPeriod()
  const show_finalize_warning =
    eta_enabled &&
    current_pay_period?.end &&
    businessStartFromDate(current_pay_period.end, false).isSameOrAfter(date)
  return show_finalize_warning
}
