import { useState } from "react"
import { Query } from "@apollo/client/react/components"

import { AlertButton } from "../../Shared"
import MissedClockoutsModal from "./SummaryModal"

import {
  useUser,
  useBusinessDates,
  useSelectedLocation
} from "../../../hooks/selectors"
import { MISSED_CLOCKOUTS } from "../../../graphql/queries"
import { validateRequestTimes, filterAndSortQueryResults } from "./helpers"

// missed clockout link
const MissedClockoutsAlert = ({ min_time = null, max_time = null }) => {
  const user = useUser()
  const selected_location = useSelectedLocation()
  const { businessStart } = useBusinessDates()

  const [show_modal, showModal] = useState(false)

  const missed_clockout_cutoff_time = businessStart()

  let store_ids = [...user.stores.map(({ id }) => id)]
  if (selected_location) {
    store_ids = [selected_location?.id]
  }

  // validate times
  if (!validateRequestTimes(min_time, max_time, missed_clockout_cutoff_time)) {
    return <></>
  }

  return (
    <Query
      fetchPolicy="no-cache"
      query={MISSED_CLOCKOUTS}
      variables={{ store_ids }}
    >
      {({ data }) => {
        const missed_clockouts = filterAndSortQueryResults(
          data?.missedClockOuts ?? [],
          min_time,
          max_time
        )

        return (
          <>
            {missed_clockouts?.length > 0 && (
              <AlertButton
                id="missed-clockouts-notification"
                basic
                danger
                icon="warning sign"
                count={missed_clockouts.length}
                label={`Missed Clockout${
                  missed_clockouts.length > 1 ? "s" : ""
                }`}
                onClick={() => showModal(true)}
              />
            )}
            {missed_clockouts?.length > 0 && !!show_modal && (
              <MissedClockoutsModal
                shifts={missed_clockouts}
                onClose={() => showModal(false)}
              />
            )}
          </>
        )
      }}
    </Query>
  )
}

export default MissedClockoutsAlert
