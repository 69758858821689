import React, { Component } from "react"
import Styled from "styled-components"

import SourceFilters from "./SourceFilters"
import { StepHeader } from "../../../Styled"

const SaleTypeWrapper = Styled.div``

export default class extends Component {
  render() {
    return (
      <SaleTypeWrapper>
        <StepHeader
          content="Amount Shared"
          subheader="Specify the percentage of each sale's tip or menu item price to share."
        />
        <SourceFilters {...this.props} />
      </SaleTypeWrapper>
    )
  }
}
