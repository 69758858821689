import { useSelector, shallowEqual } from "react-redux"

export * from "./user"
export * from "./settings"
export * from "./locations"
export * from "./organization"
export * from "./business-dates"
export * from "./report-lock-threshold"
export * from "./processing-in-progress"
export { useSelector, shallowEqual }

export const useObjectSelector = (selector, comparisonFunction) =>
  useSelector(selector, comparisonFunction ?? shallowEqual)
