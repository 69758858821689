import Message from "./"
import Link from "../Link"

import { paths } from "../../../constants"

export default ({
  title = "An Error Occurred",
  message = (
    <>
      <div>
        Try reloading the page. If the issue persists,{" "}
        <Link target="_blank" href={paths.contact}>
          let us know
        </Link>
        .
      </div>
      <div>Our support team will resolve the issue as quickly as possible.</div>
    </>
  ),
  ...props
}) => (
  <Message strong type="danger" title={title} message={message} {...props} />
)
