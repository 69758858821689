import React from "react"

import Styled from "styled-components"

import Message from "../../../Message"

export default Styled(props => (
  <Message
    inline
    type="info"
    title="No Results Found"
    message={
      <>
        No sales found with the current filters. Try expanding the date range
        for a broader search.
      </>
    }
    {...props}
  />
))`
  margin: 0.33rem 0;
`
