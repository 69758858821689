export const updateSearchParams = params => {
  const searchParams = new URLSearchParams({ ...params })
  history.push({ search: "?" + searchParams.toString() })
}

//search is an object {name: value}
export const createSearchQuery = (url, search) =>
  `${url}?${Object.keys(search)
    .map(name => `${encodeURI(name)}=${encodeURI(search[name])}`)
    .join("&")}`

export const parseSearchQuery = search =>
  search
    .substring(1)
    .split("&")
    .filter(val => val.trim())
    .reduce(
      (accumulator, filter) => ({
        ...accumulator,
        [filter.split("=")[0]]: decodeURIComponent(filter.split("=")[1])
      }),
      {}
    )
