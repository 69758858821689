import React, { Component, createRef } from "react"
import { v4 as uuidv4 } from "uuid"
import {
  Chart,
  PieController,
  DoughnutController,
  ArcElement,
  Legend,
  Title,
  Tooltip
} from "chart.js"
import { rgba } from "polished"

import { style, colors } from "./../../constants"
import { colorByIndex } from "./helpers"

Chart.register(
  PieController,
  DoughnutController,
  ArcElement,
  Legend,
  Title,
  Tooltip
)

const TYPE_DOUGHNUT = "doughnut"
const TYPE_PIE = "pie"
const DEFAULT_ASPECT_RATIO = 2

class PieChart extends Component {
  chart = null
  ref = createRef()

  componentDidMount() {
    this.renderChart()
  }

  componentDidUpdate() {
    if (!!this.chart) {
      if (!!this.props.redraw) {
        this.chart.destroy()
        this.renderChart()
      } else {
        this.chart.update()
      }
    }
  }

  componentWillUnmount() {
    !!this.chart && this.chart.destroy()
  }

  legendOptions = () => {
    const { legend } = this.props

    if (legend === false) {
      return false
    }

    const options = legend === true ? {} : { ...legend }

    return {
      position: "right",
      labels: {
        color: !!this.props.inverted ? colors.light3 : colors.dark3
      },
      ...options
    }
  }

  titleOptions = () => {
    const { title } = this.props

    if (!title) {
      return false
    }

    let options = {}

    if (typeof title === "object") {
      options = { ...title }
    } else {
      options = { text: title }
    }

    return {
      display: true,
      font: {
        size: 16,
        color: !!this.props.inverted ? colors.light2 : colors.dark3,
        family: style.font
      },
      position: "top",
      ...options
    }
  }

  buildOptions = () => {
    const { options = {} } = this.props
    return {
      aspectRatio: DEFAULT_ASPECT_RATIO,
      plugins: {
        legend: this.legendOptions(),
        title: this.titleOptions()
      },
      layout: {
        padding: 5
      },
      ...options
    }
  }

  chartType = () => {
    switch (this.props.type) {
      case TYPE_PIE:
        return TYPE_PIE
      case TYPE_DOUGHNUT:
      default:
        return TYPE_DOUGHNUT
    }
  }

  renderChart = () => {
    this.chart = new Chart(this.ref.current.getContext("2d"), {
      type: this.chartType(),
      data: {
        datasets: [
          {
            borderWidth: 0.5,
            hoverOffset: 5,
            // hoverBorderWidth: 3,
            // borderAlign: "inner",
            borderColor: rgba(colors.white, 0.5),
            backgroundColor: this.props.data.map(({ color }, idx) =>
              !!color ? color : rgba(colorByIndex(idx), 0.7 - 0.025 * (idx + 1))
            ),
            data: [...this.props.data.map(({ value }) => value)]
          }
        ],
        labels: [...this.props.data.map(({ label }) => label)]
      },
      options: this.buildOptions()
    })
  }

  render() {
    return <canvas id={this.props.id || uuidv4()} ref={this.ref} />
  }
}

export default Chart

export const Pie = ({ ...props }) => <PieChart {...props} type={TYPE_PIE} />
export const Doughnut = ({ ...props }) => (
  <PieChart {...props} type={TYPE_DOUGHNUT} />
)
