import { useState } from "react"
import { useQuery } from "@apollo/client"

import Search, { ResultItem, DEBOUNCE_MS, PAGE, PER_PAGE } from "./"

import { toInt } from "../../helpers/number"
import { JOB_CODE_SEARCH } from "./../../graphql/queries"

let debounce_ctr = null

export default ({
  active = true,
  fluid = false,
  displayed = true,
  skip_empty = true,
  store_id = undefined,
  exclude_none = false,
  placeholder = "Search Job Codes",
  ...props
}) => {
  const [{ search, search_request }, setSearch] = useState({
    search: "",
    search_request: null
  })

  const { data, loading } = useQuery(JOB_CODE_SEARCH, {
    skip: !!skip_empty && search.trim() === "",
    variables: {
      page: PAGE,
      first: PER_PAGE,
      filters: {
        active,
        store_id,
        search,
        displayed,
        exclude_none
      }
    }
  })

  const is_loading = loading || !!search_request
  let results = []
  if (!is_loading) {
    try {
      results = data.jobCodeSearch.data
        .map(({ id, ...rest }) => ({ id: toInt(id), ...rest }))
        .map(job_code => ({
          key: job_code.id,
          value: job_code.id,
          title: job_code.name,
          job_code: { ...job_code }
        }))
    } catch (e) {}
  }

  return (
    <Search
      input={{ icon: "search", iconPosition: "left" }}
      placeholder={placeholder}
      loading={is_loading}
      results={results}
      fluid={fluid}
      onSearchChange={search => {
        let now = Date.now()
        debounce_ctr = now
        setSearch({ search: "", search_request: search })
        setTimeout(() => {
          if (debounce_ctr === now) {
            debounce_ctr = null
            setSearch({ search, search_request: null })
          }
        }, DEBOUNCE_MS)
      }}
      noResultsMessage={!!is_loading ? "Searching..." : "No results found."}
      resultRenderer={({ title, job_code }) => (
        <ResultItem
          title={!!title ? title : <i>No Name</i>}
          subtitle={!store_id ? job_code.store.name : null}
        />
      )}
      {...props}
    />
  )
}
