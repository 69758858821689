import { Component } from "react"

import { Form } from "semantic-ui-react"

import Modal from "../../../Modal"
import Button, { SubmitButton } from "../../../Button"

import { timezones } from "../../../../constants"

export default class extends Component {
  state = { timezone: "" }

  componentDidMount() {
    const { timezone } = this.props

    this.setState({ timezone })

    if (!timezones.includes(timezone)) {
      timezones.push(timezone)
    }
  }

  isValidInput = () => {
    const timezone_input = this.state.timezone.trim()
    const timezone = this.props.timezone.trim()
    return timezone_input !== timezone && timezone_input.length > 0
  }

  render() {
    const { onClose, onSubmit, submitted } = this.props
    const { timezone } = this.state

    return (
      <Modal size="tiny" onClose={onClose} closeOnDimmerClick={false}>
        <Modal.Header onClose={onClose}>Edit Timezone</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>Location Timezone</label>
              <Form.Dropdown
                value={timezone}
                placeholder="Select One"
                selection
                search
                onChange={(e, { value }) => this.setState({ timezone: value })}
                options={timezones.map(timezone => ({
                  key: timezone,
                  value: timezone,
                  text: timezone
                }))}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose} disabled={!!submitted}>
            Cancel
          </Button>
          <SubmitButton
            loading={!!submitted}
            disabled={!!submitted || !this.isValidInput()}
            onClick={() => onSubmit(timezone)}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}
