import { Modal, Button } from "../../../../../Shared"
import VerifyUsersTable from "../Table/index"

const VerifyModal = ({ users, onClose, onReviewCompleted }) => {
  return (
    <Modal onClose={onClose} sticky>
      <Modal.Header onClose={onClose}>ETA Sign-Up Review</Modal.Header>
      <Modal.Content scrolling>
        <VerifyUsersTable users={users} onReviewCompleted={onReviewCompleted} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default VerifyModal
