import React, { useState } from "react"

import Styled from "styled-components"
import { Icon, Loader } from "semantic-ui-react"

import { colors } from "../../constants"
import { LOGIN_ENTERPRISE_ADMIN_TO_ORG } from "../../graphql/mutations"
import { useMutation } from "@apollo/client"

const ComponentWrapper = Styled.div`
  position: relative;
`
const StyledLoader = Styled.div`
  padding: 1.33rem;
  text-align: center;
`
const NoResults = Styled.div`
  padding: 1.33rem;
  font-size: 1rem;
  text-align: center;
`
const LoginLoader = Styled(Loader)`
  float: right;
  margin-top: 10px !important;
  margin-right: 10px !important;
`
const SelectOrgWrapper = Styled.div`
  border: 1px solid ${colors.light5};
  border-radius: 3px;
  overflow: hidden;
`
const Organization = Styled.div`
  position: relative;
  font-size: 1.2rem;
  border-bottom: 1px solid ${colors.light4};
  line-height: 1.6rem;
  cursor: pointer;
  &>div {
    display: inline-block;
    padding: 0.67rem;
  }
  &:last-child {
    border-bottom: none;
  }
`
const ChevronWrapper = Styled.div`
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  color: ${colors.success};
`
const OrganizationHeader = Styled(Organization)`
  position: relative;
  background-color: ${colors.light2};
  font-weight: 400;
  cursor: default;
`
const OrgName = Styled.div`
  &&& {
    padding: 0.67rem 0.33rem 0.67rem 1rem;
    color: ${colors.dark};
  }
`
const OrganizationRow = Styled(Organization)`
  color: ${colors.dark2};
  background-color: ${colors.white};
  font-weight: 300;
  ${({ active }) => !active && `&:hover {`}
    & ${ChevronWrapper} {
      opacity: 1;
    }
    &::before {
      content: '';
      position: absolute;
      left: -1px;
      top: 0;
      bottom: 0px;
      background-color: ${colors.success};
      width: 2px;
    }
  ${({ active }) => !active && `}`}
  & > .icon {
    float: right;
    visibility: hidden;
  }
    &:hover {
      background-color: ${colors.light3};
      color: ${colors.dark};
      & > .icon {
        visibility: visible;
      }
    }
`

const SignInIcon = Styled(Icon)`
    padding-top: 0.5rem;
`

export default ({ loginSuccess, navigateTo, syncing_user, user, syncUser }) => {
  const [selectedOrg, setSelectedOrg] = useState()

  const [loginToOrg, { loading: loadingLogin }] = useMutation(
    LOGIN_ENTERPRISE_ADMIN_TO_ORG,
    {
      onCompleted: result => {
        if (result && result.loginEnterpriseAdminToOrg) {
          syncUser(loginSuccess())
          navigateTo(window.location.pathname)
        }
      }
    }
  )

  return (
    <ComponentWrapper>
      <SelectOrgWrapper>
        <OrganizationHeader>
          <OrgName>Name</OrgName>
        </OrganizationHeader>
        {!!syncing_user && !!user?.enterprise?.organizations.length === 0 && (
          <StyledLoader>
            <Loader active inline />
          </StyledLoader>
        )}
        {!syncing_user && !!user?.enterprise?.organizations.length === 0 && (
          <NoResults>No Results Found.</NoResults>
        )}
        {user?.enterprise?.organizations.map(org => (
          <OrganizationRow
            key={org.id}
            onClick={e => {
              e.stopPropagation()
              e.nativeEvent.stopImmediatePropagation()
              setSelectedOrg(org.id)
              loginToOrg({
                variables: {
                  id: org.id
                }
              })
            }}
          >
            <OrgName>{org.name}</OrgName>
            {!loadingLogin ? (
              <SignInIcon name="sign in" size="large" />
            ) : (
              selectedOrg === org.id && (
                <LoginLoader active inline size="small" />
              )
            )}
          </OrganizationRow>
        ))}
      </SelectOrgWrapper>
    </ComponentWrapper>
  )
}
