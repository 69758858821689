import moment from "moment"
import Styled from "styled-components"
import { Dropdown } from "../../../../../Shared"
import {
  colors,
  date_format,
  month_day_format
} from "../../../../../../constants"
import { usePayPeriods } from "../../../../../../hooks"

const EffectiveDateDropdown = ({ disabled, effective_date, onChange }) => {
  const { last_pay_period, current_pay_period } = usePayPeriods()
  const options = [
    {
      value: last_pay_period.start,
      text: "Last Pay Period",
      description: `As of ${moment(last_pay_period.start).format(
        month_day_format
      )}`
    },
    {
      value: current_pay_period.start,
      text: "Current Pay Period",
      description: `As of ${moment(current_pay_period.start).format(
        month_day_format
      )}`
    },
    {
      value: moment(current_pay_period.end).add(1, "d").format(date_format),
      text: "Next Pay Period",
      description: `As of ${moment(current_pay_period.end)
        .add(1, "d")
        .format(month_day_format)}`
    }
  ]
  const description_text = options.find(o => o.value === effective_date)
    ?.description

  return (
    <>
      <PayPeriodDropdown
        disabled={disabled}
        value={effective_date}
        onChange={(e, { value }) => onChange(value)}
        options={options.map(({ text, value, description }) => ({
          key: value,
          value,
          text,
          content: <PayPeriodOption text={text} description={description} />
        }))}
      />
      {!!description_text && (
        <DescriptionText>{description_text}</DescriptionText>
      )}
    </>
  )
}

const PayPeriodDropdown = Styled(props => {
  return <Dropdown inline {...props} />
})`
  &.ui.dropdown.inline > .text {
    font-weight: 400;
  }
`

const DescriptionText = Styled.div`
  color: #858599;
  font-size: 0.9rem;
`

const PayPeriodOption = Styled(({ className, text, description }) => (
  <div className={className}>
    {text}
    <div>{description}</div>
  </div>
))`
  & > div:first-child {
    padding-top: 0.2rem;
    color: ${colors.dark6};
    font-size: 0.9rem;
    font-weight: 400;
  }
`

export default EffectiveDateDropdown
