import { urls } from "./links"

export * from "./links"
export * from "./style"
export * from "./datetime"
export * from "./settings"
export * from "./pay-period"

export const project_info = {
  name: "TipHaus",
  title: "Client Dashboard",
  marketing_site_url: urls().marketing,
  learning_site_url: urls().learning,
  employee_app_name: "MyTipHaus",
  employee_app_url: urls().my,
  employee_app_registration_link: `${urls().setup}auth/employee/request-link`,
  forgot_pw_link: `${urls().setup}auth/password/reset`,
  support_email: "support@tiphaus.com",
  blog_url: urls().blog,
  faq_url: urls().faq,
  webinar_url: urls().webinar
}

export const LAYOUT_CONTAINER_ID = "th-layout-content-container"

export const none_job_code = "None"

export const mobile_app_role = "mobile-app"

export const GOOGLE_ANALYTICS_MEASUREMENT_ID = "G-5NMNZHH2NM"
