import { useEffect } from "react"
import { useIntercom } from "react-use-intercom"

import Content from "./Content"
import AppError from "../AppError"
import { NoActiveLocations } from "./NoAccess"

import {
  bootFullview,
  intercomEnabled,
  intercomBootConfig
} from "../../helpers"
import { useUser, useOrganization } from "../../hooks"

export default ({ onMount, app_error, children, ...props }) => {
  const user = useUser()
  const organization = useOrganization()

  const { boot } = useIntercom()

  useEffect(() => {
    // on mount
    onMount()
  }, [])

  useEffect(() => {
    // boot intercom if the user isn't backdoored
    if (intercomEnabled(user)) {
      // initiate fullview
      bootFullview(user)
      // initiate itercom
      boot({ ...intercomBootConfig(user, organization) })
    }
  }, [user.id])

  if (user.stores.length === 0) {
    // this can happen if the last store was deactivated in settings
    return <NoActiveLocations logout={props.logout} />
  }

  return (
    <Content {...props}>
      {!!app_error && <AppError {...props} {...app_error} />}
      {!app_error && children}
    </Content>
  )
}
