import {
  entity_type_employee,
  entity_type_organization,
  entity_type_store,
  entity_type_enterprise
} from "../constants/role"

export const hasEnterpriseLevelRole = user =>
  !!user.roleAssignments.find(
    ({ entity_type }) => entity_type === entity_type_enterprise
  )

export const hasOrgLevelRole = user =>
  !!user.roleAssignments.find(
    ({ entity_type }) => entity_type === entity_type_organization
  )

export const hasStoreLevelRole = user =>
  !!user.roleAssignments.find(
    ({ entity_type }) => entity_type === entity_type_store
  )

export const hasMobileAppLevelRole = user =>
  !!user.roleAssignments.find(
    ({ entity_type }) => entity_type === entity_type_employee
  )
