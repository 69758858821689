import Styled from "styled-components"

import { FilterRow } from "../Styled"
import Dropdown from "../../../../Dropdown"
import { SortDirectionButton } from "../../../../Shared/FilterBar/Filters/SortBy"

const SORT_FIELD_SALE_CLOSED = "sale_time"
const SORT_FIELD_SALE_OPENED = "opened_time"
const SORT_FIELD_SALE_SUBTOTAL = "total_sales"
const SORT_FIELD_SALE_CC_TIP = "total_cc_tips"
const SORT_DIRECTION_ASC = "asc"
const SORT_DIRECTION_DESC = "desc"

const DEFAULT_SORT_FIELD = SORT_FIELD_SALE_CLOSED
const DEFAULT_SORT_DIRECTION = SORT_DIRECTION_DESC

const SORT_OPTIONS = [
  {
    text: "Sale Closed",
    value: SORT_FIELD_SALE_CLOSED
  },
  {
    text: "Sale Opened",
    value: SORT_FIELD_SALE_OPENED
  },
  {
    text: "Subtotal",
    value: SORT_FIELD_SALE_SUBTOTAL
  },
  {
    text: "Credit Card Tip",
    value: SORT_FIELD_SALE_CC_TIP
  }
]

export default ({ field, direction, onChange }) => (
  <SortWithDefaults
    field={field ?? DEFAULT_SORT_FIELD}
    direction={direction ?? DEFAULT_SORT_DIRECTION}
    onChange={(field, direction) =>
      onChange(field, direction, sortIsDefault(field, direction))
    }
  />
)

const SortWithDefaults = ({ field, direction, onChange }) => (
  <FilterRow name="Sort By">
    <Dropdown
      selection
      value={field}
      options={SORT_OPTIONS}
      onChange={(e, { value }) => onChange(value, direction)}
    />
    &nbsp;
    <StyledDirectionButton
      direction={direction}
      onClick={() =>
        onChange(
          field,
          direction === SORT_DIRECTION_ASC
            ? SORT_DIRECTION_DESC
            : SORT_DIRECTION_ASC
        )
      }
    />
  </FilterRow>
)

const StyledDirectionButton = Styled(({ className, ...props }) => (
  <div className={className}>
    <SortDirectionButton {...props} />
  </div>
))`
  display: inline-block;
  line-height: 2.67rem;
`

const fieldIsDefault = field => !field || field === DEFAULT_SORT_FIELD
const directionIsDefault = direction =>
  !direction || direction === DEFAULT_SORT_DIRECTION

export const sortIsDefault = (field, direction) =>
  fieldIsDefault(field) && directionIsDefault(direction)
export const saleSortOptions = () => [...SORT_OPTIONS]
export const defaultSaleSort = () => ({
  field: DEFAULT_SORT_FIELD,
  direction: DEFAULT_SORT_DIRECTION
})
export const sortFieldLabel = field =>
  SORT_OPTIONS.find(option => option.value === field)?.text
