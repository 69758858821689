import { Table } from "../../../../../Shared"
import UserRow from "./UserRow"

const UsersTable = ({ users, controls, onStatusChangeCompleted }) => (
  <Table unstackable compact controls={controls}>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.HeaderCell>Email</Table.HeaderCell>
        <Table.HeaderCell>Location Access</Table.HeaderCell>
        <Table.HeaderCell>Role</Table.HeaderCell>
        <Table.HeaderCell>Created Date</Table.HeaderCell>
        <Table.HeaderCell textAlign="center">Registered</Table.HeaderCell>
        <Table.HeaderCell textAlign="center">Active</Table.HeaderCell>
        <Table.HeaderCell />
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {users.map(user => (
        <UserRow
          key={user.id}
          user={user}
          onStatusChangeCompleted={onStatusChangeCompleted}
        />
      ))}
    </Table.Body>
  </Table>
)

export default UsersTable
