import Styled from "styled-components"
import { colors } from "../../constants"
import Icon from "../Icon"

const MarketingBanner = ({ onDismiss }) => {
  return (
    <Box>
      <img
        src="images/office-hours-emp-app.png"
        onClick={() =>
          window.open("https://streamyard.com/watch/bsXWHtqbPabW", "_blank")
        }
      />
      <IconBox onClick={onDismiss}>
        <Icon name="close" />
      </IconBox>
    </Box>
  )
}

const Box = Styled.div`
  margin-bottom: 1rem;
  position: relative;
  max-width: 1500px;
  img {
    max-width: 100%;
    cursor: pointer;
  }
`

const IconBox = Styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 1.25rem;
  width: 1.25rem;
  text-align: center;
  background-color: ${colors.white};
  border-radius: 2px;
  cursor: pointer;
  .close.icon {
    margin: 0;
    color: ${colors.dark7};
  }
  &:hover .close.icon {
    color: ${colors.dark5};
  }
`

export default MarketingBanner
