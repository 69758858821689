import { useState } from "react"

import moment from "moment"
import Styled from "styled-components"
import { Mutation } from "@apollo/client/react/components"

import { Icon, Modal, Button, SubmitButton, DatePicker } from "../../Shared"

import { month_day_format } from "../../../constants"
import { useReportLockThreshold } from "../../../hooks"
import { ACTIVATE_RULE_MUTATION } from "../../../graphql/mutations"
import { ucwords, appMinDate, compatibleDateObject } from "../../../helpers"
import { useUser } from "../../../hooks"

const Thin = Styled.p`font-weight: 300;`

const DatePickerWrapper = Styled.div`
  display: inline-block;

  &&& .react-calendar {
    min-width: ${44 * 7}px;
  }

  &&& .react-date-picker__clear-button.react-date-picker__button {
    display: none;
  }
`
const FilterTitle = Styled.div`
  font-size: 1rem;
  display: inline-block;
  margin-right: 0.67rem;
  line-height: 2.33rem;
  cursor: default;
`

export default ({
  rule_id,
  location_id,
  rule_type = "rule",
  min_effective = null,
  onSuccess = () => {},
  onError = () => {},
  onClose = () => {}
}) => {
  const reportLockThreshold = useReportLockThreshold()
  const user = useUser()

  const app_min_date = appMinDate(null, user)
  const min_effective_date = min_effective
    ? moment.max([moment(min_effective), app_min_date]).startOf("day")
    : app_min_date
  const min_is_lock_threshold = reportLockThreshold(location_id)
    .startOf("day")
    .isSame(min_effective_date)

  const [activated_date, setActivatedDate] = useState(moment().startOf("day"))

  return (
    <Mutation
      mutation={ACTIVATE_RULE_MUTATION}
      onCompleted={({ activateRule }) =>
        !!activateRule ? onSuccess() : onError()
      }
      onError={() => onError()}
    >
      {(activateRule, { loading }) => {
        return (
          <Modal size="mini" onClose={onClose}>
            <Modal.Header content={`Activate ${ucwords(rule_type)}`} />
            <Modal.Content>
              <Thin>
                Select the date you'd like this {rule_type} to activate.
              </Thin>
              <FilterTitle>Effective date: </FilterTitle>
              <DatePickerWrapper>
                <DatePicker
                  onChange={e =>
                    setActivatedDate(moment(e).format("YYYY-MM-DD 04:00:00"))
                  }
                  value={compatibleDateObject(activated_date)}
                  minDate={compatibleDateObject(min_effective_date)}
                  description={
                    min_is_lock_threshold ? (
                      <>
                        Days before{" "}
                        {min_effective_date.format(month_day_format)} are
                        locked.
                      </>
                    ) : null
                  }
                />
              </DatePickerWrapper>
            </Modal.Content>
            <Modal.Actions>
              <Button disabled={!!loading} onClick={onClose}>
                Cancel
              </Button>
              <SubmitButton
                disabled={!!loading}
                loading={!!loading}
                onClick={e =>
                  activateRule({
                    variables: {
                      id: Number(rule_id),
                      activated_date: moment(activated_date).format(
                        "YYYY-MM-DD 04:00:00"
                      )
                    }
                  })
                }
              />
            </Modal.Actions>
          </Modal>
        )
      }}
    </Mutation>
  )
}
