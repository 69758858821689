import { useState, useEffect } from "react"

import Styled from "styled-components"

import { Field } from "../../../Styled"
import { Table, Toggle, Statistic } from "../../../../Shared"

import { salesGratuityForSources } from "../../helpers"
import { displayMoney } from "../../../../../helpers/number"
import { formatListForSentence, ucfirst } from "../../../../../helpers/string"

// step entrypoint
export const reviewStep = ({ ...props }, updateRule) => {
  // completed will be set once this step is visited
  const [complete, setCompleted] = useState(false)

  return {
    name: "Review",
    complete,
    render: () => (
      <Review
        {...props}
        updateRule={updateRule}
        onMount={() => setCompleted(true)}
      />
    )
  }
}

const Review = ({ rule, onMount, updateRule }) => {
  // set step completed on mount
  useEffect(() => onMount(), [])

  const { sales } = rule
  const { employees, distribution_sources } = rule
  const amount_shared = salesGratuityForSources(
    sales,
    rule.distribution_sources
  )

  return (
    <>
      <AmountShared
        sales={sales}
        distribution_sources={distribution_sources}
        amount_shared={amount_shared}
      />
      <Recipients employees={employees} amount_shared={amount_shared} />
      <EnableDefaultSharingToggle
        value={
          !rule.sales.reduce(
            (acc, { is_constrained }) => acc && is_constrained,
            true
          )
        }
        onToggle={() =>
          updateRule({
            ...rule,
            sales: rule.sales.map(sale => ({
              ...sale,
              is_constrained: !sale.is_constrained
            }))
          })
        }
      />
    </>
  )
}

const AmountShared = ({ sales, amount_shared, distribution_sources }) => {
  return (
    <Field name="Amount Shared">
      <Statistic value={displayMoney(amount_shared)} />
      <div>
        <strong>Source:</strong>{" "}
        {ucfirst(formatListForSentence(distribution_sources))} from{" "}
        {sales.length} sale
        {sales.length > 1 && "s"}.
      </div>
    </Field>
  )
}

const Recipients = ({ employees, amount_shared }) => {
  const total_weight = employees.reduce(
    (acc, employee) => acc + employee.distribution_weight,
    0
  )

  return (
    <Field name="Recipients">
      <Table>
        <Table.Body>
          {[...employees]
            .sort((a, b) =>
              a.distribution_weight < b.distribution_weight ? 1 : -1
            )
            .map(employee => (
              <Table.Row key={employee.id}>
                <Table.Cell>{employee.name}</Table.Cell>
                <Table.Cell positive textAlign="right">
                  {displayMoney(
                    amount_shared *
                      (employee.distribution_weight / total_weight)
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </Field>
  )
}

const EnableDefaultSharingToggle = Styled(({ value, onToggle, className }) => {
  return (
    <Field name="Additional Options">
      <div className={className}>
        <div>
          <Toggle
            checked={value}
            onChange={onToggle}
            label="Enable standard tip sharing rules on selected sale(s)."
          />
          {!value && (
            <div className="thin">
              When disabled, your custom team adjustment will override tip
              sharing rules.
            </div>
          )}
          {!!value && (
            <div className="thin">
              When enabled, your custom team adjustment will apply in addition
              to tip sharing rules.
            </div>
          )}
        </div>
      </div>
    </Field>
  )
})`
  & span {
    cursor: default;
  }
  & div.thin {
    margin-top: 1rem;
    font-weight: 300;
  }
`
