import { v4 as uuidv4 } from "uuid"

import moment from "moment"
import { routes_visited_settings_key } from "../constants"

import { toInt } from "../helpers/number"

import { toggleNightMode, nightModeEnabled } from "../helpers/settings/user"

import {
  SYNC_USER,
  SET_USER,
  APP_ERROR,
  CLEAR_TOAST,
  MOUNT_TOAST,
  SYNCING_USER,
  CREATE_TOAST,
  UNMOUNT_TOAST,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  LOGOUT_PENDING,
  INIT_NIGHT_MODE,
  TOGGLE_NIGHT_MODE,
  SET_ROUTES_VISITED,
  CHANGE_SELECTED_LOCATION,
  SET_RULE_TEMPLATE_FOR_CREATE
} from "../actions"

export const default_state = {
  user: null,
  syncing_user: false,
  logged_in: false,
  logout_pending: false,
  selected_location_id: null,
  selected_location_name: null,
  app_error: false,
  night_mode: nightModeEnabled(),
  toasts: [],
  create_rule_template: null,
  authenticated: false
}

export default (state = { ...default_state }, action) => {
  const { type, payload } = action
  let { selected_location_id, selected_location_name } = state
  let user

  switch (type) {
    case SET_USER:
      return {
        ...state,
        user: { ...state.user, ...payload }
      }
    case SYNCING_USER:
      return {
        ...state,
        syncing_user: payload
      }
    case SYNC_USER:
      user = getUserState(payload)
      try {
        // select location automatically when only one store exists
        if (user.stores.length === 1) {
          selected_location_id = [...user.stores][0].id
        }
        if (selected_location_id) {
          selected_location_name = user.stores.find(
            ({ id }) => id === selected_location_id
          ).name
        }
      } catch {}
      return {
        ...state,
        syncing_user: false,
        user: { ...state.user, ...user },
        selected_location_id,
        selected_location_name,
        authenticated: true
      }
    case CHANGE_SELECTED_LOCATION:
      selected_location_id = null
      if (payload) {
        selected_location_id = toInt(payload)
      }
      try {
        if (selected_location_id) {
          selected_location_name = state.user.stores.find(
            ({ id }) => id === selected_location_id
          ).name
        } else {
          selected_location_name = null
        }
      } catch {}
      return {
        ...state,
        selected_location_id,
        selected_location_name,
        report_filter_employee: null
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        logged_in: !!state.logged_in ? state.logged_in + 1 : 1
      }
    case APP_ERROR:
      return { ...state, app_error: { error_msg: false, ...payload } }
    case LOGOUT_PENDING:
      return { ...state, logout_pending: true }
    case LOGOUT_SUCCESS:
      return { ...default_state, logged_in: false, authenticated: false }
    case INIT_NIGHT_MODE:
      return { ...state, night_mode: nightModeEnabled(state.user) }
    case TOGGLE_NIGHT_MODE:
      toggleNightMode(state.user)
      return { ...state, night_mode: nightModeEnabled(state.user) }
    case CREATE_TOAST:
      return {
        ...state,
        toasts: [...state.toasts, { ...payload, id: uuidv4(), mounted: false }]
      }
    case MOUNT_TOAST:
      return {
        ...state,
        toasts: state.toasts.map(toast => ({
          ...toast,
          mounted: toast.id === payload ? true : toast.mounted
        }))
      }
    case UNMOUNT_TOAST:
      return {
        ...state,
        toasts: state.toasts.map(toast => ({
          ...toast,
          mounted: toast.id === payload ? false : toast.mounted
        }))
      }
    case CLEAR_TOAST:
      return {
        ...state,
        toasts: state.toasts.filter(({ id }) => id !== payload)
      }
    case SET_ROUTES_VISITED:
      return {
        ...state,
        user: {
          ...state.user,
          settings: {
            ...state.user.settings,
            [routes_visited_settings_key]: payload
          }
        }
      }
    case SET_RULE_TEMPLATE_FOR_CREATE:
      return {
        ...state,
        create_rule_template: payload
      }
    default:
      return { ...state }
  }
}

const mapSettings = settings =>
  settings.reduce(
    (acc, setting) => ({ ...acc, [setting.key]: setting.value }),
    {}
  )

const getOrgType = stores => {
  return stores.length > 1
}

export const getUserState = payload => {
  const stores = [...payload.authorizedStores]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ id, name, timezone, min_processing_time, settings }) => ({
      id: toInt(id),
      name,
      timezone,
      min_processing_time,
      settings: mapSettings(settings)
    }))

  let user = {
    id: toInt(payload.id),
    name: payload.name,
    email: payload.email,
    registered_at: payload.registered_at,
    is_backdoor: payload.is_backdoor,
    admin: payload.is_admin,
    settings: mapSettings(payload.settings),
    roleAssignments: payload.roleAssignments,
    // TODO: refactor this by setting enterprise_admin from the backend
    enterprise_admin: payload.roleAssignments.some(({ role }) => {
      return role.name === "Enterprise Admin"
    }),
    enterprise: payload.enterprise,
    stores,
    store_ids: stores.map(({ id }) => id),
    show_terms_of_service: payload.show_terms_of_service
  }

  if (payload.organization) {
    user = {
      ...user,
      organization: {
        id: toInt(payload.organization.id),
        name: payload.organization.name,
        timezone: payload.organization.timezone,
        settings: mapSettings(payload.organization.settings),
        eta_config: { ...(payload.organization.eta_config ?? {}) },
        eta_employee_transfer_fee:
          payload.organization.eta_employee_transfer_fee,
        eta_employer_transfer_fee:
          payload.organization.eta_employer_transfer_fee,
        eta_min_employee_payout: payload.organization.eta_min_employee_payout,
        integration_sources: payload.organization.integrationSources.map(
          ({ name }) => name
        ),
        payroll_interval: payload.organization.payroll_interval,
        payroll_reference_date: payload.organization.payroll_reference_date,
        current_pay_period: null,
        last_pay_period: null,
        pay_period_before_last: null,
        min_processing_time: payload.organization.min_processing_time,
        stores,
        multi_unit_org: getOrgType(stores),
        available_roles: payload.organization.available_roles
      }
    }

    if (payload.organization.recent_pay_periods) {
      const pay_periods = [...payload.organization.recent_pay_periods]
        .sort((a, b) => (moment(a.start) > moment(b.start) ? -1 : 1))
        .map(({ id, start, end, exported_at }) => ({
          id: toInt(id),
          start,
          end,
          exported_at
        }))
      user.organization.current_pay_period = pay_periods[0]
      user.organization.last_pay_period = pay_periods[1]
      user.organization.pay_period_before_last = pay_periods[2]
    }
  }
  return user
}
