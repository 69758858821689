import { Component } from "react"

import moment from "moment"
import Styled from "styled-components"
import { Calendar } from "react-date-range"

import Input from "./Input"
import Button from "../../Button"
import InfoIcon from "../../Icon/Info"

import "react-date-range/dist/styles.css"
import "react-date-range/dist/theme/default.css"

import { colors, style } from "../../../constants"
import { compatibleDateObject } from "../../../helpers/datetime"

const Wrapper = Styled.div`
  position: relative;
  display: ${({ fluid }) => (!!fluid ? "block" : "inline-block")};
`

const StyledInput = Styled(({ open, ...props }) => <Input {...props} />)`
${({ value, fluid, open }) => `
  ${!!fluid ? "" : `width: calc(${value.length}ch + 1.35em) !important;`}

  & > input {
    cursor: pointer !important;
    transition: none !important;
    padding-top: 0.7em !important;
    caret-color: transparent !important;
    border-color: ${
      open ? colors.input_border_focus : colors.input_border
    } !important;
  }

  &:hover > input {
    border-color: ${
      open ? colors.input_border_focus : colors.input_border_hover
    } !important;
  }
`}`

const StyledCalendar = Styled(
  ({
    className,
    onClick,
    description,
    position,
    date,
    show_month_and_year_pickers = false,
    ...props
  }) => (
    <div className={className} onClick={onClick}>
      <Calendar
        {...props}
        date={date ?? compatibleDateObject(new Date())}
        shownDate={date ?? compatibleDateObject(new Date())}
        showMonthAndYearPickers={show_month_and_year_pickers}
      />
      {!!description && <CalendarDescription content={description} />}
    </div>
  )
)`
  z-index: 10000;
  position: absolute;
  ${({ position = "bottom" }) => {
    switch (position) {
      case "top":
        return "bottom: calc(100% + 3px); left: 0;"
      case "top right":
        return "left: calc(100% + 3px); bottom: 0;"
      case "left":
        return "right: calc(100% + 3px); top: 0;"
      case "top left":
        return "right: calc(100% + 3px); bottom: 0;"
      case "right":
        return "left: calc(100% + 3px); top: 0;"
      case "bottom left":
        return "top: calc(100% + 3px); right: 0;"
      case "bottom":
      default:
        return "top: calc(100% + 3px); left: 0;"
    }
  }}
  border-radius: ${style.border_radius};
  border: 1px solid ${colors.light4};
  & .rdrCalendarWrapper {
    vertical-align: top;
    border-radius: ${style.border_radius};
  }
  & .rdrMonthAndYearWrapper {
    padding-top: 0;
  }
  & .rdrMonth {
    padding-bottom: 0.67em;
  }

  & .rdrDayPassive > .rdrDayNumber * {
    color: transparent !important;
  }
`

const CalendarDescription = Styled(({ content, ...props }) => (
  <div {...props}>
    <InfoIcon />
    <div>{content}</div>
  </div>
))`
  position: relative;
  text-align: left;
  padding: 0.67rem;
  background-color: ${colors.light2};
  border-top: 0.5px solid ${colors.light3};
  & > i:first-child {
    position: absolute;
    top: 0.67rem;
    left: 0.67rem;
  }
  & > div:last-child {
    padding-left: 1.8rem;
  }
`

class DatePickerInput extends Component {
  state = { date: null }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (moment(nextProps.date).diff(moment(prevState.date), "d") !== 0) {
      return { date: nextProps.date }
    }
    return null
  }

  constructor(props) {
    super(props)
    this.state.date = this.props.date
  }

  render() {
    const { onClick, onChange, onClose, ...props } = this.props
    const { date } = this.state
    return (
      <StyledCalendar
        {...props}
        onClick={onClick}
        date={date}
        onChange={e => {
          onChange(e)
          onClose()
        }}
      />
    )
  }
}

class DatePicker extends Component {
  state = { open: false, deactivate_propagation: false }
  componentDidMount() {
    document.addEventListener("click", this.close)
    document.addEventListener("keydown", this.escape)
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.close)
    document.removeEventListener("keydown", this.escape)
  }
  close = () => {
    let state = {
      deactivate_propagation: false
    }
    if (!this.state.deactivate_propagation) {
      state.open = false
    }
    this.setState({ ...state })
  }
  escape = e => e.key.toLowerCase() === "escape" && this.close()
  toggle = () => this.setState({ open: !this.state.open })
  render() {
    const {
      value,
      onChange,
      format = "LL",
      button = false,
      minDate,
      maxDate,
      description,
      fluid,
      position = "bottom",
      force_close = false,
      show_month_and_year_pickers = false,
      ...props
    } = this.props
    const { open } = this.state
    return (
      <Wrapper fluid={fluid}>
        {!button && (
          <StyledInput
            {...props}
            open={open}
            fluid={fluid}
            value={moment(value).format(format)}
            onClick={() => {
              this.toggle()
              this.setState({ deactivate_propagation: true })
            }}
          />
        )}
        {!!button && (
          <Button
            {...props}
            fluid={fluid}
            onClick={() => {
              this.toggle()
              this.setState({ deactivate_propagation: true })
            }}
          >
            {moment(value).format(format)}
          </Button>
        )}
        {!!open && force_close !== true && (
          <DatePickerInput
            // drop time
            date={compatibleDateObject(moment(value).format("YYYY-MM-DD"))}
            onClick={() => {
              this.setState({ deactivate_propagation: true })
            }}
            onChange={e => {
              onChange(e)
              this.close()
            }}
            onClose={this.close}
            minDate={minDate}
            maxDate={maxDate}
            description={description}
            position={position}
            show_month_and_year_pickers={show_month_and_year_pickers}
          />
        )}
      </Wrapper>
    )
  }
}

export default DatePicker
