import { ToastProvider, ToastConsumer } from "./toast"
import { NavigateToProvider, NavigateToConsumer } from "./navigateTo"
import {
  SelectLocationProvider,
  SelectLocationConsumer
} from "./selectLocation"
import {
  SetApplicationFiltersProvider,
  SetApplicationFiltersConsumer
} from "./setApplicationFilters"

export {
  ToastConsumer,
  NavigateToConsumer,
  SelectLocationConsumer,
  SetApplicationFiltersConsumer
}

const ApplicationProvider = ({
  toast,
  navigateTo,
  setApplicationFilters,
  changeSelectedLocation,
  ...props
}) => (
  <ToastProvider toast={toast}>
    <NavigateToProvider navigateTo={navigateTo}>
      <SelectLocationProvider changeSelectedLocation={changeSelectedLocation}>
        <SetApplicationFiltersProvider
          setApplicationFilters={setApplicationFilters}
          {...props}
        />
      </SelectLocationProvider>
    </NavigateToProvider>
  </ToastProvider>
)

export default ApplicationProvider
