import { useState } from "react"
import { useMutation, useQuery } from "@apollo/client"

import Editor from "./Editor"
import PoolsTable from "./Table"
import FilterBar from "../FilterBar"
import { FilterBarControls } from "../Styled"
import { Link, Relative, ErrorMessage } from "../../Shared"

import {
  OBJECT_NAME,
  POOL_EDITOR_MODE_EDIT,
  POOL_EDITOR_MODE_CREATE,
  POOL_EDITOR_MODE_COPY
} from "./helpers"
import { paths } from "../../../constants"
import { ruleSearchActiveFilter, RULE_TYPE_POOL } from "../helpers"
import { DISTRIBUTION_RULE_SEARCH } from "../../../graphql/queries"
import { CREATE_RULE, UPDATE_RULE } from "../../../graphql/mutations"
import { SelectLocationConsumer, ToastConsumer } from "../../../context"
import { useSelectedLocation, useUser } from "../../../hooks"
import useAuthorization from "../../../hooks/authorization"
import { rules_manage } from "../../../constants/permissions"

export default ({
  mock_pool,
  pool_filters,
  setPoolFilters,
  changePage,
  changePerPage
}) => {
  const user = useUser()
  const { hasPermission } = useAuthorization()
  const can_edit = hasPermission(rules_manage)
  const selected_location = useSelectedLocation()

  const [create_pool, createPool] = useState(false)
  const [edit_pool, editPool] = useState(false)

  const { loading, data, error, refetch } = useQuery(DISTRIBUTION_RULE_SEARCH, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    skip: !!mock_pool,
    variables: {
      filters: {
        active: ruleSearchActiveFilter(pool_filters.status),
        types: [RULE_TYPE_POOL],
        store_id: selected_location?.id ?? null,
        revenue_center_id: pool_filters.revenue_center?.id ?? null,
        organization_id: user.organization.id,
        sort_field: pool_filters.sort_field ?? null,
        sort_direction: pool_filters.sort_direction ?? null,
        source_names: pool_filters.source_names ?? [],
        distribute_to: pool_filters.distribute_to?.id ?? null
      },
      page: pool_filters.page,
      first: pool_filters.per_page
    }
  })

  // override api result with mock payload when prop exists
  const pools = mock_pool ? [{ ...mock_pool }] : data?.ruleSearch?.data ?? []

  return (
    <>
      <SelectLocationConsumer>
        {changeSelectedLocation => (
          <Relative>
            {!!error && <ErrorMessage inline />}
            {!error && (
              <>
                <FilterBar
                  loading={!!loading}
                  filters={pool_filters}
                  object_name={OBJECT_NAME}
                  setFilters={({ location = undefined, ...filters }) => {
                    const filter_location_id = location?.id
                      ? Number(location.id)
                      : null
                    if (
                      location !== undefined &&
                      filter_location_id !== selected_location?.id
                    ) {
                      changeSelectedLocation(location?.id)
                    }
                    setPoolFilters({ ...filters, page: 1 })
                  }}
                  controls={
                    can_edit ? (
                      <FilterBarControls
                        popup={{
                          content: "Create a New Tip Pool",
                          position: "top left",
                          delay: true
                        }}
                        onClick={() => createPool(true)}
                      />
                    ) : null
                  }
                />
                {(!loading || mock_pool) && (
                  <PoolsTable
                    pools={pools}
                    total_pools={
                      mock_pool
                        ? 1
                        : data?.ruleSearch?.paginatorInfo?.total ?? 0
                    }
                    editPool={pool => editPool(pool)}
                    copyPool={pool => createPool(pool)}
                    refetch={refetch}
                    page={pool_filters.page}
                    per_page={pool_filters.per_page}
                    changePage={changePage}
                    changePerPage={changePerPage}
                  />
                )}
              </>
            )}
          </Relative>
        )}
      </SelectLocationConsumer>
      {can_edit && (create_pool || edit_pool) && (
        <ToastConsumer>
          {toast => (
            <>
              {!!create_pool && (
                <CreateTipPool
                  onCreated={() => {
                    refetch()
                    createPool(false)
                    successToast(toast)
                  }}
                  onError={() => {
                    createPool(false)
                    errorToast(toast)
                  }}
                  onClose={() => createPool(false)}
                  copy_from={create_pool === true ? false : create_pool}
                />
              )}
              {!!edit_pool && (
                <EditTipPool
                  pool={edit_pool}
                  onEdited={() => {
                    refetch()
                    editPool(false)
                    successToast(toast, true)
                  }}
                  onError={() => {
                    editPool(false)
                    errorToast(toast, true)
                  }}
                  onClose={() => editPool(false)}
                />
              )}
            </>
          )}
        </ToastConsumer>
      )}
    </>
  )
}

const CreateTipPool = ({ copy_from = false, onCreated, onError, onClose }) => {
  const [createRule, { loading }] = useMutation(CREATE_RULE, {
    onCompleted: ({ createRule }) => (!!createRule ? onCreated() : onError()),
    onError: onError
  })

  const mode = copy_from ? POOL_EDITOR_MODE_COPY : POOL_EDITOR_MODE_CREATE

  return (
    <Editor
      mode={mode}
      pool={!copy_from ? null : { ...copy_from }}
      loading={!!loading}
      onClose={onClose}
      submitPool={createRule}
    />
  )
}

const EditTipPool = ({ pool, onEdited, onError, onClose }) => {
  const [updateRule, { loading }] = useMutation(UPDATE_RULE, {
    onCompleted: ({ updateRule }) => (!!updateRule ? onEdited() : onError()),
    onError: onError
  })

  return (
    <Editor
      pool={pool}
      mode={POOL_EDITOR_MODE_EDIT}
      loading={!!loading}
      onClose={onClose}
      submitPool={updateRule}
    />
  )
}

const successToast = (toast, update = false) =>
  toast({
    type: "success",
    message:
      `Tip pool ${update ? "updated" : "created"} successfully. ` +
      "It may take up to 15 minutes for changes to appear in your Breakdown report."
  })

const errorToast = (toast, update = false) =>
  toast({
    ttl_ms: 30000,
    type: "error",
    title: "Oh No!",
    message: (
      <>
        <div>
          An unexpected error ocurred while trying to{" "}
          {!!update ? "update" : "create"} this pool.
        </div>
        <div>
          Try refreshing the page and {!!update ? "updating" : "creating"} the
          pool again.
        </div>
        <div>
          If that doesn't work,{" "}
          <Link href={paths.contact} target="_blank">
            let our support team know
          </Link>
          .
        </div>
      </>
    )
  })
