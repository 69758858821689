import moment from "moment"
import Styled from "styled-components"

import Message from "../../../Message"
import Icon, { SuccessIcon, ExclamationIcon, WarningIcon } from "../../../Icon"

import { payoutLocationsDisplay, payPeriodDateSpan } from "../../helpers"
import { colors, datetime_format_verbose } from "../../../../constants"
import { Popup } from "../../../Shared"
import { displayMoney } from "../../../../helpers"

export const TableWrapper = Styled.div`position: relative;`

export const PayPeriodLabel = Styled(({ className, pay_period }) => (
  <span className={className}>{payPeriodDateSpan(pay_period)}</span>
))``

export const DateTimeLabel = Styled(({ className, value }) => (
  <span className={className}>
    {moment.utc(value).local().format(datetime_format_verbose)}
  </span>
))``

export const NoResultsMessage = Styled(({ className }) => (
  <Message className={className} type="info" inline>
    <Message.Header>No Payouts Found</Message.Header>
    <Message.Content>
      No results found using the current filters. Try expanding the date range
      for a broader search.
    </Message.Content>
  </Message>
))`
  margin: 0.33rem 0;
`

export const StatusSuccessful = () => (
  <SuccessIcon label={<StatusLabel label="Successful" />} />
)
export const StatusPartialSuccess = () => (
  <WarningIcon label={<StatusLabel label="Partial Success" />} />
)
export const StatusFailed = () => (
  <ExclamationIcon label={<StatusLabel label="Failed" />} />
)
export const StatusInProgress = () => (
  <Icon
    color={colors.info}
    name="hourglass half"
    label={<StatusLabel italic label="In Progress..." />}
  />
)

const StatusLabel = Styled(({ className, italic, label = "" }) => (
  <span className={className}>{label}</span>
))`
  line-height: 1rem;
  font-size: 0.95rem;
  ${({ italic = false }) =>
    !!italic ? "font-style: italic;" : "font-weight: bold;"}
`

export const EscrowAmount = Styled(
  ({ className, total_amount_unsuccessful }) => (
    <Popup
      content={`${displayMoney(
        total_amount_unsuccessful
      )} amount remaining in escrow for future payouts.`}
    >
      <ExclamationIcon className={className} />
    </Popup>
  )
)`
  margin-left: 0.25rem !important;
`

export const PayoutLocationsPopup = Styled(
  ({ className, payout_locations }) => (
    <Popup content={`${payout_locations.join(", ")}`}>
      <span className={className}>
        {payoutLocationsDisplay(payout_locations)}
      </span>
    </Popup>
  )
)`
  color: ${colors.link};
`
