import Styled from "styled-components"
import { Query } from "@apollo/client/react/components"

import { Table, Loader, Modal } from "../../../Shared"

import {
  displayMoney,
  displayDistributionAmount
} from "../../../../helpers/number"
import {
  formatListForSentence,
  firstCharToUpper
} from "../../../../helpers/string"
import {
  RULE_TYPE_SHARE,
  readableRuleType,
  RULE_TYPE_SALE_ASSIGNED_POOL,
  RULE_TYPE_FLAT_RATE
} from "../../../Rule/helpers"
import { colors } from "../../../../constants"
import { SALE_DISTRIBUTIONS } from "../../../../graphql/queries"

import { DISTRO_SOURCE_SALE } from "../../../Rule/helpers"

export default ({ sale_id, rule_id = null, onClose }) => {
  return (
    <Modal dimmer basic size="tiny" closeOnEscape onClose={onClose}>
      <Modal.Header onClose={onClose}>Sale Distribution Detail</Modal.Header>
      <Modal.Content>
        <Query
          fetchPolicy="network-only"
          notifyOnNetworkStatusChange
          // todo: optimize this query
          // every distribution row is loading sales categories
          query={SALE_DISTRIBUTIONS}
          variables={{ id: sale_id }}
        >
          {({ loading, data }) => {
            let rules = []
            let distributions = []
            if (!!loading) {
              return (
                <div>
                  <Loader size="large" />
                </div>
              )
            }
            if (!!data && !!data.sale.distributions) {
              distributions = data.sale.distributions.filter(
                ({ deleted }) => !deleted
              )
              rules = Object.values(
                distributions
                  .filter(dist => dist.is_deposit)
                  .reduce((acc, distro) => {
                    if (!rule_id || rule_id === distro.rule.id) {
                      acc[distro.rule.id] = { ...distro.rule }
                    }
                    return acc
                  }, {})
              ).sort((a, b) => b.type.localeCompare(a.type))
            }

            return (
              <RulesWrapper>
                {rules.map((rule, idx) => {
                  // get all distributions for this rule
                  const rule_distros = distributions.filter(
                    distro => distro.rule.id === rule.id
                  )
                  // filter the deposits
                  const deposits = rule_distros.filter(
                    ({ is_deposit }) => is_deposit
                  )
                  const deposit_employees = groupDistrosByEmployee(
                    rule_distros.filter(dist => dist.is_deposit)
                  )
                  // find the withdraw distributions
                  const withdraw_employees = groupDistrosByEmployee(
                    rule_distros.filter(dist => !dist.is_deposit)
                  )

                  // get all deposit distribution source names
                  const deposit_sources = formatListForSentence(
                    Object.keys(
                      deposits
                        .map(
                          dist =>
                            `a ${displayMoney(dist.source_deposit_amount)} ${
                              dist.rule.distribution_source_names.includes(
                                DISTRO_SOURCE_SALE
                              )
                                ? DISTRO_SOURCE_SALE
                                : dist.distributionSource.name
                            }`
                        )
                        .reduce((acc, source) => {
                          acc[source] = true
                          return acc
                        }, {})
                    )
                  )

                  // get all deposit employee names
                  const deposit_employee_names = formatListForSentence(
                    deposit_employees.map(dist => dist.employee.first_name),
                    false
                  )
                  // total deposit amount
                  const total_deposit_amount = deposits.reduce(
                    (sum, dist) => sum + dist.amount,
                    0
                  )
                  // deposit job code names
                  const deposit_job_codes = formatListForSentence(
                    Object.keys(
                      deposit_employees.reduce((acc, dist) => {
                        acc[dist.jobCode.name] = true
                        return acc
                      }, {})
                    )
                  )

                  return (
                    <RuleWrapper key={idx}>
                      <RuleHeader rule={rule} />
                      <RuleBody>
                        <DepositSummary>
                          {rule.type === RULE_TYPE_SHARE ? (
                            <>
                              {firstCharToUpper(deposit_employee_names)} shared{" "}
                              {rule.percent_in}% of {deposit_sources} ($
                              {displayDistributionAmount(total_deposit_amount)})
                              while working as {deposit_job_codes}.
                            </>
                          ) : rule.type === RULE_TYPE_FLAT_RATE ? (
                            <>
                              {firstCharToUpper(deposit_employee_names)} shared{" "}
                              {displayMoney(rule.percent_in)} per hour from{" "}
                              {deposit_sources} ($
                              {displayDistributionAmount(total_deposit_amount)})
                              while working as {deposit_job_codes}.
                            </>
                          ) : (
                            <>
                              {firstCharToUpper(deposit_employee_names)}{" "}
                              deposited $
                              {displayDistributionAmount(
                                deposits.reduce(
                                  (acc, deposit) => acc + deposit.amount,
                                  0
                                )
                              )}{" "}
                              to the pool while working as {deposit_job_codes}.
                            </>
                          )}
                        </DepositSummary>
                        <WithdrawSummary>
                          <Table basic="very" celled compact>
                            <Table.Header>
                              <Table.Row>
                                <TableHeaderCell>Employee</TableHeaderCell>
                                <TableHeaderCell>Job Code</TableHeaderCell>
                                <TableHeaderCell textAlign="right">
                                  Total Shared
                                </TableHeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {withdraw_employees
                                .filter(({ amount }) => amount > 0)
                                .map((withdraw, idx) => (
                                  <Table.Row key={idx}>
                                    <Table.Cell>
                                      {withdraw.employee.first_name}{" "}
                                      {withdraw.employee.last_name}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {withdraw.jobCode.name}
                                    </Table.Cell>
                                    <Table.Cell textAlign="right">
                                      $
                                      {displayDistributionAmount(
                                        withdraw.amount
                                      )}
                                    </Table.Cell>
                                  </Table.Row>
                                ))}
                              <Table.Row>
                                <FooterCell>Total:</FooterCell>
                                <FooterCell />
                                <FooterCell textAlign="right">
                                  $
                                  {displayDistributionAmount(
                                    total_deposit_amount
                                  )}
                                </FooterCell>
                              </Table.Row>
                            </Table.Body>
                          </Table>
                        </WithdrawSummary>
                      </RuleBody>
                    </RuleWrapper>
                  )
                })}
              </RulesWrapper>
            )
          }}
        </Query>
      </Modal.Content>
    </Modal>
  )
}

// helper for grouping distributions
const groupDistrosByEmployee = distros => {
  const by_employee = distros.reduce((acc, distro) => {
    if (!acc[distro.employee.id]) {
      acc[distro.employee.id] = { ...distro }
    } else {
      acc[distro.employee.id].amount += distro.amount
    }
    return acc
  }, {})
  return Object.values(by_employee)
}

const RulesWrapper = Styled.div``
const RuleWrapper = Styled.div`
  background-color: ${colors.light};
  border: 1px solid ${colors.light4};
  color: ${colors.dark};
  border-radius: 1px;
  font-size: 1.2rem;
  font-weight: 300;
  margin-bottom: 1.5rem;
`
const RuleHeader = Styled(({ rule, className }) => (
  <div className={className}>
    <Header>
      <span className="header-rule-type">
        {readableRuleType(rule.type)}
        {rule.type !== RULE_TYPE_SALE_ASSIGNED_POOL && ":"}
      </span>
      {rule.type !== RULE_TYPE_SALE_ASSIGNED_POOL && (
        <span className="header-rule-name">{rule.name}</span>
      )}
    </Header>
    {rule.type !== RULE_TYPE_SALE_ASSIGNED_POOL && (
      <Subheader>Distribution: {rule.distributionMethod.name}</Subheader>
    )}
    {rule.type === RULE_TYPE_SHARE && (
      <Subheader>Percent Shared: {rule.percent_in}%</Subheader>
    )}
  </div>
))`
  background-color: ${colors.light2};
  border-bottom: 1px solid ${colors.light4};
  padding: 1.33rem 2rem;

  & > div:first-child {
    & > span.header-rule-type {
      padding-right: 0.5rem;
    }
    & > span.header-rule-name {
      font-style: italic;
    }
  }
`
const Header = Styled.div`
  font-size: 1.3rem;
  font-weight: 400;
`
const Subheader = Styled.div`
  padding-top: 0.1rem;
  font-size: 1rem;
`
const RuleBody = Styled.div`
  background-color: ${colors.light};
  padding: 1.33rem 2rem;
`
const TableHeaderCell = Styled(Table.HeaderCell)`
  &&&& {
    font-weight: 400;
  }
`
const FooterCell = Styled(Table.Cell)`
  font-weight: 400;
`
const DepositSummary = Styled.div`
  padding: 0.33rem 0 0.5rem;
  margin-bottom: 0.5rem;
`
const WithdrawSummary = Styled.div`
  padding: 0.5rem 0;
`
