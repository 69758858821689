import { MultiSelectList } from "../../../../../Shared"

import { DEFAULT_DISTRO_WEIGHT } from "../../../../helpers"

export default ({ job_codes, weight_by_job_code, onUpdate }) => {
  const available_job_codes =
    job_codes
      ?.sort((a, b) => a.localeCompare(b))
      .filter(name => weight_by_job_code[name] === undefined)
      .map(job_code => ({
        id: job_code,
        name: job_code
      })) ?? []

  return (
    <>
      <MultiSelectList
        fluid
        selection_placeholder="Which job codes will participate in the pool?"
        available={available_job_codes}
        selected={Object.keys(weight_by_job_code)
          .sort((a, b) => a.localeCompare(b))
          .map(job_code => ({
            id: job_code,
            name: job_code
          }))}
        onUpdate={selected =>
          onUpdate(
            selected
              .map(({ name }) => name)
              .sort((a, b) => a.localeCompare(b))
              .reduce(
                (acc, name) => ({
                  ...acc,
                  [name]: weight_by_job_code[name] ?? DEFAULT_DISTRO_WEIGHT
                }),
                {}
              )
          )
        }
      />
    </>
  )
}
