import PoolRow from "./TableRow"
import HeaderRow from "./HeaderRow"
import { Table } from "../../../Shared"
import { TableWrapper } from "../../Styled"
import NoResultsMessage from "./NoResultsMessage"

import { useSelectedLocation } from "../../../../hooks"
import useAuthorization from "../../../../hooks/authorization"
import { rules_manage } from "../../../../constants/permissions"

export default ({
  pools,
  total_pools,
  editPool,
  copyPool,
  refetch,
  page,
  per_page,
  changePage,
  changePerPage
}) => {
  const { hasPermission } = useAuthorization()
  const can_edit = hasPermission(rules_manage)
  const selected_location = useSelectedLocation()

  return (
    <>
      <TableWrapper>
        {pools.length === 0 && <NoResultsMessage />}
        {pools.length > 0 && (
          <Table
            id="tip-pools-table"
            draggable
            singleLine
            unstackable
            overflow="auto"
            controls={{
              position: "bottom",
              custom_controls_position: "top",
              pagination: {
                current_page: page,
                per_page: per_page,
                total: total_pools,
                include_total: true,
                changePage,
                changePerPage,
                options: {
                  per_page: [25, 50, 100]
                }
              }
            }}
          >
            <HeaderRow
              include_location={!selected_location}
              include_actions={can_edit}
            />
            <Table.Body>
              {pools.map(pool => (
                <PoolRow
                  key={pool.id}
                  pool={pool}
                  refetch={refetch}
                  editPool={editPool}
                  copyPool={copyPool}
                />
              ))}
            </Table.Body>
          </Table>
        )}
      </TableWrapper>
    </>
  )
}
