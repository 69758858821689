import { useEffect } from "react"

import moment from "moment"
import { Loader } from "semantic-ui-react"

import Editor from "./Editor"
import { Button } from "../../../Shared"
import RuleSummaryModal from "../Summary"
import RouteContent from "../../../RouteContent"
import SelectStoresModal from "../../../Modal/SelectStores"

import {
  groupJobsByPriority,
  useIsCustomRuleSchedule,
  useDefaultRuleSchedules,
  formatSchedulesForSubmit,
  useFormatSchedulesForDisplay,
  RULE_TYPE_SHARE,
  RULE_TYPE_FLAT_RATE,
  DEFAULT_DISTRO_METHOD,
  DISTRO_SOURCE_TYPE_CASH,
  DISTRO_SOURCE_TYPE_TIPS,
  DISTRO_SOURCE_TYPE_SALES,
  TIP_DISTRO_SOURCES_DEFAULT
} from "../../helpers"
import {
  useLocations,
  useStateObject,
  useBusinessDates,
  useSelectedLocation
} from "../../../../hooks"
import tour from "./tour"
import { formatRuleForDisplay } from "../helpers"
import { colors, paths } from "../../../../constants"
import { round } from "../../../../helpers"

const BASICS = "basics"
const SALE_FILTERS = "sale_filters"
const COLLECT_FROM = "collect_from"
const DISTRIBUTE_TO = "distribute_to"

export default props => {
  const {
    user,
    rule,
    title,
    loading,
    is_edit,
    navigateTo,
    onRuleSubmit,
    setRoutesVisited
  } = props

  const locations = useLocations()
  const selected_location = useSelectedLocation()
  const { businessDate, businessStartFromDate } = useBusinessDates()
  const schedules = rule?.schedules
    ? useFormatSchedulesForDisplay(rule.schedules)
    : useDefaultRuleSchedules()
  const schedule_active = rule?.schedules
    ? useIsCustomRuleSchedule(rule.schedules)
    : false

  const [state, setState] = useStateObject({
    submit_rule_modal: false,
    selected: BASICS,
    confirmed_stores: locations === 1 || !!selected_location,
    store_ids: !!selected_location
      ? [selected_location.id]
      : locations.map(({ id }) => id),
    pages: {
      [BASICS]: {
        name: "",
        schedules,
        revenue_centers: [],
        effective_date: moment(businessDate())
      },
      [SALE_FILTERS]: {
        percent: "",
        type: RULE_TYPE_SHARE,
        source: { sales: false, tips: false },
        distribution_method: DEFAULT_DISTRO_METHOD,
        sale_categories: [],
        distribution_sources: [...TIP_DISTRO_SOURCES_DEFAULT]
      },
      [COLLECT_FROM]: { selected: [] },
      [DISTRIBUTE_TO]: { selected: [] }
    }
  })

  const {
    pages,
    selected,
    store_ids,
    confirmed_stores,
    submit_rule_modal
  } = state

  const selectPage = selected => setState({ selected })

  // populate initial values for rule edit
  useEffect(() => {
    if (rule) {
      // format rule if not already
      const formatted = formatRuleForDisplay(rule)

      setState({
        store_ids: [Number(formatted.store.id)],
        confirmed_stores: true,
        pages: {
          [BASICS]: {
            name: formatted.name,
            schedules,
            effective_date: moment(formatted.effective_date),
            revenue_centers: (
              formatted.revenueCenters?.map(({ name }) => name) ?? []
            ).reduce(
              // unique based on name
              (acc, rc) => (acc.includes(rc) ? acc : [...acc, rc]),
              []
            )
          },
          [SALE_FILTERS]: {
            type: formatted.type,
            percent: formatted.percent_in,
            source: {
              sales: sourcesAreType(
                formatted.distributionSources,
                DISTRO_SOURCE_TYPE_SALES
              ),
              tips: sourcesAreType(
                formatted.distributionSources,
                DISTRO_SOURCE_TYPE_TIPS
              ),
              cash: sourcesAreType(
                formatted.distributionSources,
                DISTRO_SOURCE_TYPE_CASH
              )
            },
            distribution_sources: formatted.distribution_source_names,
            distribution_method: formatted.distributionMethod.name ?? "",
            sale_categories: formatted.sales_category_names
          },
          [COLLECT_FROM]: {
            selected: (formatted.inputJobCodes ?? []).map(job => ({
              id: job.name,
              name: job.name,
              priority: null,
              weight: null
            }))
          },
          [DISTRIBUTE_TO]: {
            selected: (formatted.outputJobCodes ?? []).map(job => ({
              id: job.name,
              name: job.name,
              priority: job.priority,
              weight: job.distribution_weight
            }))
          }
        }
      })
    }
  }, [])

  const formatRuleForSubmit = () => {
    // unpack pages
    const { basics, collect_from, distribute_to, sale_filters } = pages

    return {
      rule: {
        name: basics.name,
        // type: RULE_TYPE_SHARE,
        type: sale_filters.type, // tip_share or flat_rate
        percent_in: parseFloat(sale_filters.percent),
        distribution_method: sale_filters.distribution_method,
        effective_date: businessStartFromDate(basics.effective_date)
      },
      schedules: formatSchedulesForSubmit(basics.schedules),
      // combine input/output job codes and format
      job_codes: collect_from.selected
        .concat(
          Object.values(groupJobsByPriority(distribute_to.selected)).reduce(
            (acc, job_codes) => [...acc, ...job_codes],
            []
          )
        )
        .map(job_code => ({
          name: job_code.name,
          priority: job_code.priority ?? null,
          distribution_weight: job_code.weight ?? null
        })),
      sale_categories: !!sale_filters.source.sales
        ? [...sale_filters.sale_categories]
        : null,
      distribution_sources: !!sale_filters.source.sales
        ? null
        : [...sale_filters.distribution_sources],
      revenue_centers: basics.revenue_centers,
      // add store ids if create
      store_ids: !is_edit ? [...store_ids] : undefined
    }
  }

  const isValid = page => {
    switch (page) {
      case BASICS:
        return isValidBasics(pages)
      case SALE_FILTERS:
        return isValidSaleFilters(pages)
      case COLLECT_FROM:
        return isValidCollectFrom(pages)
      case DISTRIBUTE_TO:
        return isValidDistributeTo(pages)
      default:
        return false
    }
  }

  return (
    <RouteContent
      header={title}
      route_name="rule-editor"
      header_background={colors.light}
      content_background={colors.light}
      setRoutesVisited={setRoutesVisited}
      controls={
        <Button
          basic
          negative
          circular
          size="small"
          icon="remove"
          content={<strong>Cancel</strong>}
          popup={{
            content: "Exit Rule Editor",
            delay: true,
            position: "left center"
          }}
          onClick={() => navigateTo(paths.distributionRules)}
        />
      }
      tour={
        !confirmed_stores
          ? false
          : {
              ...tour,
              onClose: () => {
                // navigate to basics
                requestAnimationFrame(() => {
                  try {
                    document.getElementById("rule-editor-step-basics").click()
                  } catch (e) {}
                })
              }
            }
      }
    >
      {!confirmed_stores && (
        <SelectStoresModal
          prompt="Which locations would you like to create this rule for?"
          onClose={() => navigateTo(paths.distributionRules)}
          default_selected_store_ids={[]}
          user={user}
          confirm_text={"Continue"}
          onSubmit={store_ids =>
            setState({
              store_ids,
              confirmed_stores: true
            })
          }
        />
      )}
      <>
        {!!loading && <Loader active inline />}
        {!loading && store_ids.length > 0 && (
          <Editor
            {...props}
            pages={pages}
            selected={selected}
            selectPage={selectPage}
            getPreviousPage={() => getPreviousPage(selected)}
            getNextPage={() => getNextPage(selected)}
            updatePage={(page, payload) =>
              setState({
                pages: { ...pages, [page]: { ...pages[page], ...payload } }
              })
            }
            allValid={() => allValid(pages)}
            isValid={isValid}
            title={title}
            is_edit={is_edit}
            rule={rule}
            store_ids={store_ids}
            schedule_active={schedule_active}
            onSubmit={() => setState({ submit_rule_modal: true })}
          />
        )}
        {!loading && !!submit_rule_modal && (
          <RuleSummaryModal
            name={pages[BASICS].name}
            type={pages[SALE_FILTERS].type}
            effective_date={businessStartFromDate(pages[BASICS].effective_date)}
            schedules={formatSchedulesForSubmit(pages[BASICS].schedules)}
            distribution_method={pages[SALE_FILTERS].distribution_method}
            percent={pages[SALE_FILTERS].percent}
            revenue_centers={[...pages[BASICS].revenue_centers]}
            distribution_sources={[...pages[SALE_FILTERS].distribution_sources]}
            sale_categories={
              !!pages[SALE_FILTERS].source.sales
                ? [...pages[SALE_FILTERS].sale_categories]
                : []
            }
            collect_from={pages[COLLECT_FROM].selected.map(
              job_code => job_code.name
            )}
            distribute_to={pages[DISTRIBUTE_TO].selected}
            is_edit={is_edit}
            onClose={() => setState({ submit_rule_modal: false })}
            onSubmit={() => {
              setState({ submit_rule_modal: false })
              onRuleSubmit(formatRuleForSubmit(), rule ? rule.id : null)
            }}
          />
        )}
      </>
    </RouteContent>
  )
}

const sourcesAreType = (sources, source_type) => {
  let type = sources[0].source ?? ""
  return type === source_type
}

const isValidBasics = pages => {
  const page = pages[BASICS]
  try {
    return (
      !!page.name.trim().length &&
      Object.values(page.schedules).filter(day => !!day.active).length > 0
    )
  } catch (e) {
    return false
  }
}

const isValidSaleFilters = pages => {
  const {
    type,
    percent,
    source,
    distribution_sources,
    sale_categories
  } = pages[SALE_FILTERS]
  // validate based on rule type (tip share or flat rate)
  const percent_input = round(percent.toString(), 2)
  const valid_percent =
    type === RULE_TYPE_SHARE
      ? !isNaN(percent_input) && percent_input > 0 && percent_input <= 100
      : type === RULE_TYPE_FLAT_RATE
      ? !isNaN(percent_input) && parseFloat(percent_input) > 0
      : false
  const valid_source =
    Object.keys(source).filter(key => !!source[key]).length > 0
  const valid_source_tips = source.tips && distribution_sources.length > 0
  const valid_source_sales = source.sales && sale_categories.length > 0
  const valid_source_cash = source.cash
  return (
    valid_percent &&
    valid_source &&
    (valid_source_tips || valid_source_sales || valid_source_cash)
  )
}

const isValidCollectFrom = pages => pages[COLLECT_FROM].selected.length > 0

const isValidDistributeTo = pages => pages[DISTRIBUTE_TO].selected.length > 0

const allValid = pages =>
  isValidBasics(pages) &&
  isValidSaleFilters(pages) &&
  isValidCollectFrom(pages) &&
  isValidDistributeTo(pages)

const getNextPage = selected => {
  const next_page = {
    [BASICS]: SALE_FILTERS,
    [SALE_FILTERS]: COLLECT_FROM,
    [COLLECT_FROM]: DISTRIBUTE_TO,
    [DISTRIBUTE_TO]: false
  }
  return next_page[selected]
}

const getPreviousPage = selected => {
  const previous_page = {
    [BASICS]: false,
    [SALE_FILTERS]: BASICS,
    [COLLECT_FROM]: SALE_FILTERS,
    [DISTRIBUTE_TO]: COLLECT_FROM
  }
  return previous_page[selected]
}
