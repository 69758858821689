import React, { useState } from "react"

import moment from "moment"
import Styled from "styled-components"

import Icon from "../../Icon"
import Table from "../../Table"
import Popup from "../../Popup"
import Button from "../../Button"
import SaleDistributionsModal from "../../Report/Sales/Modal/SaleDistributions"

import {
  RULE_TYPE_POOL,
  RULE_TYPE_SHARE,
  RULE_TYPE_SALE_ASSIGNED_POOL
} from "../../Rule/helpers"
import {
  displayDistributionAmount,
  displayMoney
} from "../../../helpers/number"
import {
  colors,
  time_format,
  month_day_format,
  weekday_month_day_format
} from "../../../constants"

export const DetailWrapper = Styled.div`
  overflow: hidden;
  margin-bottom: 1px;
  border-radius: 0.28571429rem;
  box-shadow: 0 1px 3px 0 ${colors.table_border}, 0 0 0 1px ${colors.table_border};
`

const SummaryWrapper = Styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(150px, 1fr));
  grid-column-gap: 0.33rem;
  grid-row-gap: 0.67rem;
`

const SummaryItem = Styled(({ title, content, className }) => (
  <div className={className}>
    <div>
      <div>{title}:</div>
      <div>{content}</div>
    </div>
  </div>
))`
  border-right: 0.5px solid ${colors.table_border};

  &:last-child {
    border-right: none;
  }

  & > div {
    font-size: 1.2rem;
    padding: 0 1.33rem;

    & > div:first-child {
      font-weight: bold;
      margin-bottom: 0.25rem;
    }
  }
`

export const DetailsSummary = Styled(
  ({ start_date, end_date, employee, net_amount, className }) => {
    const start_formatted = moment(start_date).format(month_day_format)
    const end_formatted = moment(end_date).format(month_day_format)

    return (
      <div className={className}>
        <SummaryWrapper>
          <SummaryItem
            title="Employee"
            content={`${employee.first_name} ${employee.last_name}`}
          />
          <SummaryItem
            title="Job Codes"
            content={employee.job_code_names.join(", ")}
          />
          <SummaryItem
            title="Dates"
            content={
              start_formatted === end_formatted
                ? start_formatted
                : `${start_formatted} to ${end_formatted}`
            }
          />
          <SummaryItem title="Net Amount" content={displayMoney(net_amount)} />
        </SummaryWrapper>
      </div>
    )
  }
)`
  margin-bottom: 1.67rem;
`

export const DateDetails = Styled(
  ({ detail, active, toggleActive, className }) => {
    return (
      <div className={className}>
        <DateDetailsHeader
          detail={detail}
          active={active}
          toggleActive={toggleActive}
        />
        {!!active && <DateDetailsContent detail={detail} />}
      </div>
    )
  }
)`
  margin-bottom -1px;
  border-top: 1px solid ${colors.table_border};
  &:first-child, &:first-child > div:first-child {
    border-top: none;
    border-top-left-radius: 0.28571429rem;
    border-top-right-radius: 0.28571429rem;
  }
  &:last-child, &:last-child > div:last-child {
    border-bottom-left-radius: 0.28571429rem;
    border-bottom-right-radius: 0.28571429rem;
  }
`

const DateDetailsHeader = Styled(
  ({ detail, active, toggleActive, className }) => (
    <div onClick={toggleActive} className={className}>
      <div>
        <Icon
          name={!!active ? "minus square outline" : "plus square outline"}
        />
        {moment(detail.date).format(weekday_month_day_format)} as{" "}
        {detail.jobCode.name}
      </div>
      <div>{displayMoney(detail.net_amount)}</div>
    </div>
  )
)`
  display:grid;
  grid-template-columns: 1fr auto;
  font-size: 1.05rem;
  background-color: ${colors.light};
  cursor: ${({ toggleActive }) => (!!toggleActive ? "pointer" : "default")};
  padding 1rem 1rem;
  font-weight: bold;
  ${({ active }) =>
    !active
      ? ``
      : `
    background-color: ${colors.light2};
    border-bottom: 0.5px solid ${colors.light3};
  `}

  & > div > i.icon {
    padding-right: 0.5rem !important;
  }
  &:hover {
    background-color: ${colors.light2};
  }
`

const DateDetailsContent = ({ detail }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <div>
      {detail.rules.map(rule => (
        <RuleDistributions
          key={rule.id}
          rule={rule}
          employee={detail.employee}
          expanded={expanded == rule.id}
          setExpanded={setExpanded}
        />
      ))}
    </div>
  )
}

const RuleDistributions = Styled(
  ({ className, rule, employee, expanded, setExpanded }) => {
    return (
      <div className={className}>
        <div
          className="rule-header"
          onClick={() => setExpanded(!!expanded ? false : rule.id)}
        >
          <Icon
            name={!!expanded ? "minus square outline" : "plus square outline"}
          />
          {rule.type === RULE_TYPE_SHARE && "Tip Share"}
          {rule.type === RULE_TYPE_POOL && "Tip Pool"}
          {rule.type === RULE_TYPE_SALE_ASSIGNED_POOL &&
            "Custom Team Adjustment"}
          {rule.type !== RULE_TYPE_SALE_ASSIGNED_POOL && (
            <>
              : <>{rule.name}</>
            </>
          )}
          <div className="total-rule-amount">
            {rule.net_amount < 0 && "-"}$
            {displayDistributionAmount(Math.abs(rule.net_amount))}
          </div>
        </div>
        {!!expanded && (
          <DistributionsTable
            rule={rule}
            employee={employee}
            distributions={rule.distributions}
          />
        )}
      </div>
    )
  }
)`
  overflow: hidden;
  border-bottom: 1px solid ${colors.light3};

  &:last-child {
    border-bottom: none;
  }
  & > div.rule-header {
    cursor: pointer;
    background-color: ${colors.white};
    padding 0.75rem 1rem 0.75rem 1.5rem;

    &:hover {
      background-color: ${colors.light};
    }

    & > .icon {
      padding-right: 0.5rem;
    }

    & .total-rule-amount {
      float: right;
    }
  }
`

const DistributionsTable = Styled(
  ({ className, rule, employee, distributions }) => {
    const [view_sale_detail, viewSaleDetail] = useState(false)
    return (
      <div className={className}>
        <Table fitted basic="very" compact>
          <Table.Body>
            <Table.Row active>
              <Table.Cell>
                <strong>Sale Time</strong>
              </Table.Cell>
              <Table.Cell>
                <strong>Total Tendered</strong>
              </Table.Cell>
              <Table.Cell>
                <strong>Description</strong>
              </Table.Cell>
              <Table.Cell />
              <Table.Cell textAlign="right">
                <strong>Net Amount</strong>
              </Table.Cell>
            </Table.Row>
            {distributions
              .sort((a, b) => {
                if (a.distributable.id === b.distributable.id) {
                  return a.is_deposit ? -1 : 1
                }
                return moment(a.distributable.sale_time).isAfter(
                  b.distributable.sale_time
                )
                  ? 1
                  : -1
              })
              .map(distro => {
                const sale = distro.distributable

                return (
                  <Table.Row hover={false} key={distro.id}>
                    <Table.Cell>
                      {moment(sale.sale_time).format(time_format)}
                    </Table.Cell>
                    <Table.Cell>{displayMoney(sale.total)}</Table.Cell>
                    <Table.Cell>
                      {employee.first_name}{" "}
                      {rule.type === RULE_TYPE_SHARE && (
                        <>
                          <strong>
                            {distro.is_deposit ? "shared" : "received"}
                          </strong>{" "}
                          a percentage of the{" "}
                          {distro.distributionSource.name.toLowerCase()}.
                        </>
                      )}
                      {rule.type !== RULE_TYPE_SHARE && (
                        <>
                          {distro.is_deposit && (
                            <>
                              <strong>deposited</strong> this sale's{" "}
                              {distro.distributionSource.name.toLowerCase()}.
                            </>
                          )}
                          {!distro.is_deposit && (
                            <>
                              <strong>received</strong> a portion of the{" "}
                              {distro.distributionSource.name.toLowerCase()}.
                            </>
                          )}
                        </>
                      )}
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Popup
                        position="top right"
                        content={
                          <>
                            View all distributions applied to this sale by the{" "}
                            <i>{rule.name}</i>{" "}
                            {rule.type === RULE_TYPE_SHARE && "tip share"}
                            {rule.type !== RULE_TYPE_SHARE && "tip pool"}.
                          </>
                        }
                      >
                        <Button
                          basic
                          compact
                          circular
                          size="tiny"
                          icon="file alternate outline"
                          onClick={() => viewSaleDetail(sale.id)}
                        />
                      </Popup>
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      {distro.is_deposit && "-"}$
                      {displayDistributionAmount(distro.amount)}
                    </Table.Cell>
                  </Table.Row>
                )
              })}
          </Table.Body>
        </Table>
        {!!view_sale_detail && (
          <SaleDistributionsModal
            sale_id={view_sale_detail}
            rule_id={rule.id}
            onClose={() => viewSaleDetail(false)}
          />
        )}
      </div>
    )
  }
)`
  border-top: 1px solid ${colors.light3};

  & td:first-child {
    padding-left: 3.2rem !important;
  }
`
