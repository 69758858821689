import React from "react"

import moment from "moment"

import { date_format } from "../../../../constants"

export default ({ start_time, end_time }) => (
  <div>
    Loading Worked Shifts for{" "}
    <strong>{moment(start_time).format("dddd, MMMM Do")}</strong>{" "}
    {moment(start_time).format(date_format) !==
      moment(end_time).format(date_format) && (
      <>
        through <strong>{moment(end_time).format("dddd, MMMM Do")}</strong>
      </>
    )}
  </div>
)
