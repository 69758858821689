import { expandSidebar } from "../../helpers"
import { project_info } from "./../../constants"

export default {
  steps: [
    {
      target: "body",
      title: `Welcome to ${project_info.name}!`,
      content: (
        <>
          <p>
            I’d love to take a few minutes to show you our most important
            features.
          </p>
          <p>Ready to begin the walkthrough?</p>
        </>
      ),
      placement: "center",
      onClose: ({ goToStep }) => goToStep("last")
    },
    {
      target: "#home-route-header",
      title: "Let’s get to it!",
      content: (
        <>
          <p>This is the homepage of your {project_info.name} dash.</p>
          <p>
            Here, you get a quick glimpse of your organization's tip health.
          </p>
        </>
      ),
      onClose: ({ goToStep }) => goToStep("last")
    },
    {
      target: "#landing-metric-filters",
      title: "What's most meaningful to you?",
      content: (
        <>
          <p>
            Select the metrics that are most meaningful for you and your team.
          </p>
          <p>Use the second dropdown to view different date ranges.</p>
        </>
      ),
      onClose: ({ goToStep }) => goToStep("last")
    },
    {
      target: ".landing-metric-panel",
      title: (
        <div>
          <div>The data you need,</div>
          <div>not the data you don't.</div>
        </div>
      ),
      content: (
        <>
          <p>
            Click the gear icon to customize which employees are shown on each
            tile.
          </p>
          <p>Or, click the pie chart icon to see full screen charts.</p>
        </>
      ),
      next: {
        onClick: (step, onNext) => {
          // check if the next step will be displayed or not
          // if not, toggle the sidebar
          if (!document.getElementById("navbar-dropdown-stores")) {
            expandSidebar()
          }
          onNext()
        }
      },
      onClose: ({ goToStep }) => goToStep("last")
    },
    {
      target: "#navbar-dropdown-stores",
      title: "Filter by Location",
      content: () => (
        <>
          <p>By default, data is displayed for all locations.</p>
          <p>Use this dropdown to select a specific location.</p>
        </>
      ),
      next: {
        onClick: (step, onNext) => {
          expandSidebar()
          onNext()
        }
      },
      onClose: ({ goToStep }) => goToStep("last")
    },
    {
      target: "#sidebar-item-tip-distribution",
      title: <div>Take Control</div>,
      content: (
        <>
          <p>
            The Tip Distribution page is where you'll customize your
            organization's tip-sharing logic.
          </p>
          <p>
            Easily set up rules and tip pools to best fit the needs of your
            team.
          </p>
        </>
      ),
      delay_mount_ms: 300,
      onClose: ({ goToStep }) => goToStep("last")
    },
    {
      target: "#sidebar-item-breakdown",
      title: <div>Simplify Payroll</div>,
      content: (
        <>
          <p>The Breakdown makes payroll a breeze.</p>
          <p>
            Here you'll find a summary of tips received and shared along with
            take-home totals for your employees.
          </p>
          <p>Detailed payroll exports are a few clicks away.</p>
        </>
      ),
      onClose: ({ goToStep }) => goToStep("last")
    },
    {
      target: "#sidebar-item-reporting",
      title: <div>Transparency is Everything</div>,
      content: (
        <>
          <p>
            Reporting provides a direct window into your organization's data.
          </p>
          <p>Dive in and review employee sales, worked shifts, and more.</p>
        </>
      ),
      onClose: ({ goToStep }) => goToStep("last")
    },
    {
      target: "#sidebar-item-settings",
      title: <div>Set Up for Success</div>,
      // use word "adjusted".  maybe mention p2p
      content: (
        <>
          <p>
            Finally, the Settings page provides access to some essential levers.
          </p>
          <p>
            Invite {project_info.name} users, customize your features, and
            manage your configuration here.
          </p>
        </>
      ),
      onClose: ({ goToStep }) => goToStep("last"),
      next: {
        title: "Got it!"
      }
    },
    {
      target: "#help-button",
      title: <div>I'll be here!</div>,
      content: (
        <>
          <p>
            Most pages include a tour to help you get the most from your{" "}
            {project_info.name} experience.
          </p>
          <p>Simply click this question mark if you need a hand.</p>
        </>
      ),
      disable_next: true,
      disable_back: true
    }
  ]
}
