import IntroPanel from "./"
import { Message } from "../../Shared"

import { INTRO_REQUESTED_FOLLOWUP } from "../helpers"
import { eta_intro_modal_route_key, project_info } from "../../../constants"
import {
  useRoutesVisited,
  useIsBackdoorUser,
  useSetRouteVisited
} from "../../../hooks"

export default () => {
  const setRouteVisited = useSetRouteVisited()
  const route_visited_val = useRoutesVisited().client[eta_intro_modal_route_key]
  const is_backdoor = useIsBackdoorUser()
  return (
    <>
      <br />
      {route_visited_val === INTRO_REQUESTED_FOLLOWUP && (
        <Message
          centered
          type="success"
          margin_bottom="1rem"
          title="Thanks for expressing interest!"
          message={
            <>
              Our team has been notified. A {project_info.name} representative
              will reach out soon with more information.
            </>
          }
        />
      )}
      <IntroPanel
        onRequestFollowup={
          !is_backdoor && route_visited_val !== INTRO_REQUESTED_FOLLOWUP
            ? () =>
                setRouteVisited(
                  eta_intro_modal_route_key,
                  INTRO_REQUESTED_FOLLOWUP
                )
            : null
        }
      />
    </>
  )
}
