import { useMutation } from "@apollo/client"

import {
  ActionCell,
  TableWrapper,
  SummaryLabel,
  SummaryValue,
  PayPeriodLabel,
  PayPeriodDates,
  PayPeriodStatus,
  NoEmployeesSelected,
  FeeDescriptiveText,
  ProcessingMessage
} from "./Styled"
import EmployeeDetails from "./EmployeeDetails"
import { Table, Button } from "../../../Shared"

import {
  totalEtaSent,
  rowEmployeeId,
  payPeriodLabel,
  rowEtaAvailable,
  rowIsHausdirectPayout,
  totalEtaAvailable,
  totalTakeHomePayable,
  totalTakeHomeReserved,
  totalTakeHomeEligible,
  totalEtaEmployeeFees
} from "../../helpers"
import {
  useOrganization,
  useBusinessDates,
  useIsBackdoorUser,
  useProcessingInProgress
} from "../../../../hooks"
import { INITIATE_PAYOUT } from "../../../../graphql/mutations"
import { round, displayMoney, isProduction } from "../../../../helpers"

export default ({
  filters,
  all_rows,
  pay_period,
  included_rows,
  onPayoutInitiated,
  onIncludeEmployee,
  onExcludeEmployee,
  excluded_employees
}) => (
  <SummaryTable
    filters={filters}
    all_rows={all_rows}
    pay_period={pay_period}
    included_rows={included_rows}
    onPayoutInitiated={onPayoutInitiated}
    onExcludeEmployee={onExcludeEmployee}
    onIncludeEmployee={onIncludeEmployee}
    excluded_employees={excluded_employees}
  />
)

const SummaryTable = ({
  filters,
  all_rows,
  pay_period,
  included_rows,
  onPayoutInitiated,
  onExcludeEmployee,
  onIncludeEmployee,
  excluded_employees
}) => {
  const org = useOrganization()
  const is_backdoor = useIsBackdoorUser()
  const { businessStartFromDate } = useBusinessDates()
  const processing_in_progress = useProcessingInProgress({
    end_time: businessStartFromDate(pay_period.end)
  })
  const hausdirect_rows = included_rows.filter(rowIsHausdirectPayout)
  const total_hausdirect_employees = all_rows.filter(rowIsHausdirectPayout)
    .length
  const transfer_fee = org.eta_config?.transfer_fee ?? 0
  const transfer_fee_owner = org.eta_config?.transfer_fee_owner ?? ""
  const eta_min_employee_payout = org?.eta_min_employee_payout ?? 0
  const total_transfer_fee = hausdirect_rows.length * transfer_fee
  const total_employee_transfer_fee = totalEtaEmployeeFees(included_rows)
  const total_employer_transfer_fee =
    total_transfer_fee - total_employee_transfer_fee

  const total_sent = totalEtaSent(included_rows)
  const eligible_take_home = totalTakeHomeEligible(included_rows)
  const eligible_to_pay = totalEtaAvailable(included_rows)
  const total_reserved = totalTakeHomeReserved(included_rows)
  const total_payable = totalTakeHomePayable(included_rows)
  const payout_buffer = org.eta_config?.payout_buffer ?? 0
  const pct_reserved = round(100 * (1 - payout_buffer), 2)
  // total eta available + total employer fees
  const total_payout = eligible_to_pay + total_employer_transfer_fee

  return (
    <TableWrapper>
      {total_hausdirect_employees > 0 && (
        <FeeDescriptiveText
          transfer_fee={transfer_fee}
          transfer_fee_owner={transfer_fee_owner}
        />
      )}
      <Table unstackable compact fitted basic="very">
        <Table.Body>
          <Table.Row hover={false}>
            <SummaryLabel>Pay Period</SummaryLabel>
            <SummaryValue>
              <PayPeriodLabel
                label={payPeriodLabel(filters.selected_pay_period)}
              />
              <PayPeriodDates start={pay_period.start} end={pay_period.end} />
              <PayPeriodStatus pay_period={pay_period} />
            </SummaryValue>
          </Table.Row>
          <Table.Row secondary>
            <SummaryLabel
              description={
                <p>
                  Take home pay belonging to employees who have completed ETA
                  registration (prior to withholding).
                </p>
              }
            >
              Eligible Take Home
            </SummaryLabel>
            <SummaryValue>{displayMoney(eligible_take_home)}</SummaryValue>
          </Table.Row>
          <Table.Row secondary>
            <SummaryLabel
              inset
              description={
                <>
                  <p>
                    {pct_reserved}% of eligible take home is reserved to adjust
                    for corrections.
                  </p>
                  <p>Withholding only applies for non-finalized pay periods.</p>
                </>
              }
            >
              Reserved {pct_reserved}%
            </SummaryLabel>
            <SummaryValue>
              {total_reserved > 0 && displayMoney(total_reserved)}
              {total_reserved === 0 && "-"}
            </SummaryValue>
          </Table.Row>
          <Table.Row secondary>
            <SummaryLabel
              inset
              description={
                <p>The amount available to pay out after withholdings.</p>
              }
            >
              Payable
            </SummaryLabel>
            <SummaryValue>
              {total_payable > 0 && displayMoney(total_payable)}
              {total_payable === 0 && "-"}
            </SummaryValue>
          </Table.Row>
          <Table.Row secondary>
            <SummaryLabel>Already Paid Out</SummaryLabel>
            <SummaryValue>
              {total_sent > 0 && displayMoney(total_sent)}
              {total_sent === 0 && "-"}
            </SummaryValue>
          </Table.Row>
          <Table.Row secondary>
            <SummaryLabel>Available to Pay Out</SummaryLabel>
            <SummaryValue>
              {eligible_to_pay > 0 && displayMoney(eligible_to_pay)}
              {eligible_to_pay === 0 && "-"}
            </SummaryValue>
          </Table.Row>
          {all_rows.length > 0 && (
            <EmployeeDetails
              detail={all_rows}
              onExcludeEmployee={onExcludeEmployee}
              onIncludeEmployee={onIncludeEmployee}
              excluded_employees={excluded_employees}
              eta_min_employee_payout={eta_min_employee_payout}
            />
          )}
          {/* only show fees if there are hausdirect employees */}
          {total_hausdirect_employees > 0 && (
            <>
              <Table.Row secondary>
                <SummaryLabel>Payout Fees</SummaryLabel>
                <SummaryValue>{displayMoney(total_transfer_fee)}</SummaryValue>
              </Table.Row>
              <Table.Row secondary>
                <SummaryLabel inset>Employee</SummaryLabel>
                <SummaryValue>
                  {displayMoney(total_employee_transfer_fee)}
                </SummaryValue>
              </Table.Row>
              <Table.Row secondary>
                <SummaryLabel inset>Organization/Store</SummaryLabel>
                <SummaryValue>
                  {displayMoney(total_employer_transfer_fee)}
                </SummaryValue>
              </Table.Row>
            </>
          )}
          <Table.Row secondary>
            <SummaryLabel>Total Payout</SummaryLabel>
            <SummaryValue strong={total_payout > 0}>
              {total_payout > 0 && displayMoney(total_payout)}
              {total_payout === 0 && "-"}
            </SummaryValue>
          </Table.Row>
          {processing_in_progress && (
            <Table.Row secondary hover={false}>
              <ActionCell>
                <ProcessingMessage />
              </ActionCell>
            </Table.Row>
          )}
          <Table.Row secondary hover={false}>
            <ActionCell>
              {included_rows.length === 0 && <NoEmployeesSelected />}
              {included_rows.length > 0 && (
                <InitiatePayoutsButton
                  disabled={isProduction() && is_backdoor}
                  pay_period={pay_period}
                  payouts={included_rows.map(row => ({
                    employee_id: rowEmployeeId(row),
                    amount: rowEtaAvailable(row)
                  }))}
                  total_payout={total_payout}
                  onPayoutInitiated={onPayoutInitiated}
                />
              )}
            </ActionCell>
          </Table.Row>
        </Table.Body>
      </Table>
    </TableWrapper>
  )
}

const InitiatePayoutsButton = ({
  disabled = false,
  pay_period,
  payouts,
  total_payout,
  onPayoutInitiated
}) => {
  const [initiatePayout, { loading }] = useMutation(INITIATE_PAYOUT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: result => onPayoutInitiated(result)
  })
  return (
    <Button
      fluid
      fitted
      secondary
      loading={loading}
      disabled={disabled}
      onClick={() =>
        initiatePayout({
          variables: {
            pay_period_id: pay_period.id,
            requested_payouts: [...payouts]
          }
        })
      }
    >
      Initiate Payout for {displayMoney(total_payout)}
    </Button>
  )
}
