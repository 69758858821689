import gql from "graphql-tag"

export const organizations = gql`
  query {
    organizations {
      id
      name
      active
      created_at
      stores {
        id
        name
      }
      users {
        id
        name
        email
        active
      }
    }
  }
`

export const ORGANIZATION_SEARCH = gql`
  query organizationSearch(
    $filters: OrganizationFilters
    $first: Int!
    $page: Int!
  ) {
    organizationSearch(filters: $filters, first: $first, page: $page) {
      data {
        id
        name
        external_id
        active
        updated_at
        created_at
        stores {
          id
          name
        }
      }
      paginatorInfo {
        currentPage
        lastPage
        total
      }
    }
  }
`

const rule_query = `
  id
  name
  type
  effective_date
  deactivated_date
  active
  revenueCenters {
    id
    name
  }
  distributionMethod {
    id
    name
    description
  }
  distributionSources {
    id
    name
    source
    category_id
  }
  store {
    id
    name
  }
  percent_in
  created_at
  schedules {
    hour_end
    hour_start
    day_end
    day_start
  }
  min_deactivated_date
  output_job_code_names
  input_job_code_names
  sales_category_names
  jobCodes {
    id
    name
    deleted
    priority
    distribution_weight
  }
  inputJobCodes {
    id
    name
    deleted
  }
  outputJobCodes {
    id
    name
    deleted
    priority
    distribution_weight
  }
  salesCategories {
    id
    name
    active
  }
`

export const DISTRIBUTION_RULE_SEARCH = gql`
  query ruleSearch($filters: RuleFilters, $first: Int!, $page: Int!) {
    ruleSearch(filters: $filters, first: $first, page: $page) {
      data {
        ${rule_query}
      }
      paginatorInfo {
        currentPage
        lastPage
        total
      }
    }
  }
`

export const RULE_BY_ID = gql`
  query rule($id: ID!) {
    rule(id: $id) {
      ${rule_query}
      store {
        id
        name
      }
      clonable_to_stores {
        id
        name
      }
    }
  }
`

export const SALE_DISTRIBUTIONS = gql`
  query sale($id: ID) {
    sale(id: $id) {
      id
      total_cc_tips
      total_gc_tips
      total_sc_tips
      distributions {
        id
        amount
        deleted
        is_deposit
        business_date
        source_deposit_amount
        distributionSource {
          id
          name
          source
        }
        employee {
          id
          first_name
          last_name
        }
        jobCode {
          id
          name
        }
        rule {
          id
          name
          type
          percent_in
          distribution_source_names
          distributionMethod {
            id
            name
          }
        }
      }
    }
  }
`

export const SALE_CHECKS = gql`
  query sale($id: ID) {
    sale(id: $id) {
      checks {
        id
        external_id
        voided
        deleted
        price
        tax
        cc_tips
        cash_tips
        total_tips
        service_charge
        saleItems {
          id
          name
          price
          tax
          voided
          salesCategory {
            id
            name
          }
        }
      }
    }
  }
`

export const FILTERED_SALES = gql`
  query filteredSales(
    $id_in: [ID!]
    $store_id: ID
    $start_time: DateTime
    $end_time: DateTime
    $revenue_center_id: ID
    $employee_id: ID
    $has_job_code: Boolean
    $job_code_id: ID
    $has_distribution: Boolean
    $distribution_rule_id: ID
    $external_id: String
    $has_refunds: Boolean
    $page: Int
    $page_size: Int
    $sort_by: String
    $sort_dir: String
  ) {
    filteredSales(
      id_in: $id_in
      store_id: $store_id
      start_time: $start_time
      end_time: $end_time
      revenue_center_id: $revenue_center_id
      employee_id: $employee_id
      has_job_code: $has_job_code
      job_code_id: $job_code_id
      has_distribution: $has_distribution
      distribution_rule_id: $distribution_rule_id
      external_id: $external_id
      has_refunds: $has_refunds
      page: $page
      page_size: $page_size
      sort_by: $sort_by
      sort_dir: $sort_dir
    ) {
      id
      total
      sum_price
      sum_cc_tip
      sum_tax
      sum_service_charge
      sum_gc_tip
      sales {
        id
        external_id
        total
        store_id
        sale_time
        opened_time
        total_sales
        total_cc_tips
        total_sales_refund
        total_tips_refund
        total_cc_tips_refund
        total_gc_tips_refund
        total_service_charges
        total_tax
        total_gc_tips
        revenueCenter {
          id
          name
        }
        workedShift {
          id
          jobCode {
            id
            name
          }
        }
        assignedJobCode {
          id
          name
        }
        assignedDistributionRules {
          id
          active
          deleted
          effective_date
          deactivated_date
          distributionSources {
            id
            name
          }
          pivot {
            is_constrained
          }
          created_at
          createdBy {
            name
          }
          employees {
            name
            distribution_weight
          }
        }
        deposit_distributions_count
        employee {
          id
          payroll_id
          first_name
          last_name
          assignedJobCode {
            id
            name
          }
          store {
            id
            name
          }
        }
        checks {
          id
          voided
          deleted
        }
      }
    }
  }
`

export const FILTERED_WORKED_SHIFTS = gql`
  query filteredWorkedShifts(
    $store_id: Int
    $start_time: DateTime
    $end_time: DateTime
    $employee_id: Int
    $job_code_id: Int
    $page: Int
    $page_size: Int
    $sort_by: String
    $sort_dir: String
  ) {
    filteredWorkedShifts(
      store_id: $store_id
      start_time: $start_time
      end_time: $end_time
      employee_id: $employee_id
      job_code_id: $job_code_id
      page: $page
      page_size: $page_size
      sort_by: $sort_by
      sort_dir: $sort_dir
    ) {
      id
      total
      workedShifts {
        id
        is_custom
        start
        end
        cash_tips
        recurring_shift_id
        employee {
          id
          payroll_id
          first_name
          last_name
          assignedJobCode {
            id
            name
          }
          store {
            id
            name
          }
        }
        jobCode {
          id
          name
        }
      }
    }
  }
`

export const RECURRING_SHIFT = gql`
  query recurringShift($id: ID!) {
    recurringShift(id: $id) {
      id
      store {
        id
        name
      }
      employee {
        id
        name
      }
      jobCode {
        id
        name
      }
      schedules {
        day_start
        hour_start
        minute_start
        day_end
        hour_end
        minute_end
      }
      effective_date
      deactivated_date
    }
  }
`

export const STORE_JOB_CODES = gql`
  query storeJobCodes($store_id: Int!) {
    storeJobCodes(store_id: $store_id) {
      id
      name
      is_manager
      displayed
      is_tipped
      store {
        id
        name
      }
    }
  }
`

export const organizationTipPools = id => gql`
  {
    organization(id: ${id}) {
      id
      tipPools {
        id
        name
        active
        effective_date
        distributionMethod {
          id
          name
        }
        jobCodes {
          id
          name
          tip_pool_weight
          deleted
        }
        store {
          id
          name
          active
        }
      }
    }
  }
`
export const storeTipPools = id => gql`
  {
    store(id: ${id}) {
      id
      tipPools {
        id
        name
        active
        effective_date
        distributionMethod {
          id
          name
        }
        jobCodes {
          id
          name
          tip_pool_weight
        }
        store {
          id
          name
        }
      }
    }
  }
`

export const ORG_STORES_RULES_PROCESSING = gql`
  query orgStoresRulesProcessing($org_id: Int!) {
    orgStoresRulesProcessing(org_id: $org_id) {
      id
      name
      is_processing
      lowwater
    }
  }
`

export const backdoor = user_id => gql`
  query {
    backdoor(user_id: ${user_id}) {
      message
      token
      token_type
      expires
    }
  }
`

export const CLIENT_BACKDOOR_LOGIN_USER_SEARCH = gql`
  query clientBackdoorLoginUserSearch(
    $first: Int
    $page: Int
    $org_id: ID!
    $filters: UserSearchFilters
  ) {
    clientBackdoorLoginUserSearch(
      first: $first
      page: $page
      org_id: $org_id
      filters: $filters
    ) {
      data {
        id
        name
        email
        roleAssignments {
          entity_type
          entity_id
        }
      }
      paginatorInfo {
        currentPage
        lastPage
        total
      }
    }
  }
`

export const CLIENT_SETTINGS_USER_SEARCH = gql`
  query clientSettingsUserSearch(
    $store_id: ID
    $role_ids: [ID!]
    $search: String
    $active: Boolean
    $sort_by: String
    $sort_dir: OrderByDirection
    $page: Int!
    $first: Int!
  ) {
    clientSettingsUserSearch(
      store_id: $store_id
      role_ids: $role_ids
      search: $search
      active: $active
      sort_by: $sort_by
      sort_dir: $sort_dir
      page: $page
      first: $first
    ) {
      data {
        id
        name
        email
        active
        registered_at
        created_at
        roleAssignments {
          entity_type
          entity_id
          role {
            id
            name
            permissions {
              id
              name
              description
            }
          }
        }
        employees {
          id
          name
          store {
            id
            name
          }
        }
      }
      paginatorInfo {
        currentPage
        lastPage
        total
      }
    }
  }
`

export const CLIENT_DASH_ROLES = gql`
  query clientDashRoles(
    $entity_types: [String!]
    $search: String
    $store_id: ID
    $sort_by: String
    $sort_dir: OrderByDirection
  ) {
    clientDashRoles(
      entity_types: $entity_types
      search: $search
      store_id: $store_id
      sort_by: $sort_by
      sort_dir: $sort_dir
    ) {
      id
      name
      description
      entity_type
      assigned
      custom
      entities {
        entity_id
        entity_type
      }
      permissions {
        id
        name
        description
      }
    }
  }
`

export const SELF = gql`
  query {
    self {
      id
      is_backdoor
      name
      email
      active
      is_admin
      registered_at
      created_at
      show_terms_of_service
      settings {
        key
        value
      }
      enterprise {
        id
        name
        organizations {
          id
          name
          active
          active_stores {
            id
            name
          }
        }
      }
      organization {
        id
        name
        created_at
        eta_config {
          enabled
          payout_buffer
          verified_at
          transfer_fee
          transfer_fee_owner
          hausdirect_enabled
          payouts_enabled
        }
        eta_employee_transfer_fee
        eta_employer_transfer_fee
        eta_min_employee_payout
        settings {
          key
          value
        }
        integrationSources {
          name
        }
        timezone
        min_processing_time
        payroll_interval
        payroll_reference_date
        current_pay_period {
          id
          start
          end
        }
        recent_pay_periods {
          id
          start
          end
          exported_at
        }
        available_roles {
          id
          name
          description
          entity_type
        }
      }
      roleAssignments {
        entity_type
        entity_id
        role {
          name
          permissions {
            id
            name
            description
          }
        }
      }
      authorizedStores {
        id
        name
        timezone
        min_processing_time
        settings {
          key
          value
        }
      }
    }
  }
`

export const MISSED_CLOCKOUTS = gql`
  query missedClockOuts($store_ids: [Int!]) {
    missedClockOuts(store_ids: $store_ids) {
      id
      start
      end
      employee {
        id
        first_name
        last_name
        store {
          id
          name
        }
      }
      jobCode {
        id
        name
      }
    }
  }
`

export const NONE_JOB_CODE_SALES = gql`
  query noneJobCodeSales($store_ids: [Int!]) {
    noneJobCodeSales(store_ids: $store_ids) {
      id
      external_id
      sale_time
      workedShift {
        id
        jobCode {
          id
          name
        }
      }
      employee {
        id
        first_name
        last_name
        store {
          id
          name
        }
      }
    }
  }
`

export const alerts = gql`
  query {
    alerts {
      id
      name
      description
      preview_url
      settings_key
    }
  }
`

export const TOTALS_BY_EMPLOYEE = gql`
  query totalsByEmployee(
    $start_time: DateTime!
    $end_time: DateTime!
    $store_id: Int
  ) {
    totalsByEmployee(
      start_time: $start_time
      end_time: $end_time
      store_id: $store_id
    ) {
      store_id
      store_name
      employee_id
      first_name
      last_name
      job_code_id
      job_code_name
      total_sales_revenue
      total_tips_revenue
      total_minutes_worked
      total_sales_volume
      total_take_home
      total_cc_tip_fee_revenue
      total_service_charges_revenue
    }
  }
`

export const JOB_CODES_WITH_INVALID_TAKE_HOME = gql`
  query jobCodesWithInvalidTakeHome($store_ids: [Int!]) {
    jobCodesWithInvalidTakeHome(store_ids: $store_ids) {
      take_home
      window_start
      jobCode {
        id
        name
      }
      store {
        id
        name
      }
      employee {
        id
        first_name
        last_name
      }
    }
  }
`

export const SALES_WITH_EXCESSIVE_TIP = gql`
  query salesWithExcessiveTip($store_ids: [Int!]) {
    salesWithExcessiveTip(store_ids: $store_ids) {
      id
      external_id
      sale_time
      total_sales
      total_cc_tips
      total_gc_tips
      total_cash_tips
      store {
        id
        name
      }
      employee {
        id
        first_name
        last_name
      }
      workedShift {
        id
        start
        end
        jobCode {
          id
          name
        }
      }
    }
  }
`

export const EMPLOYEE_APP_USERS = gql`
  query employeeAppUsers {
    employeeAppUsers {
      id
      name
      email
      active
      deleted
    }
  }
`

export const PEER_TO_PEER_TRANSACTIONS = gql`
  query peerToPeerTransactions(
    $page: Int
    $first: Int
    $store_id: ID
    $start_date: Date!
    $end_date: Date!
    $employee_id: ID
  ) {
    peerToPeerTransactions(
      page: $page
      first: $first
      store_id: $store_id
      start_date: $start_date
      end_date: $end_date
      employee_id: $employee_id
    ) {
      data {
        id
        amount
        shared_at
        note
        status
        fromEmployee {
          id
          name
          first_name
          last_name
          store {
            id
            name
          }
        }
        toEmployee {
          id
          name
          first_name
          last_name
          store {
            id
            name
          }
        }
      }
      paginatorInfo {
        currentPage
        lastPage
        total
      }
    }
  }
`

export const breakdownSnapshots = (pay_period_id, store_id) => gql`
  query {
    breakdownSnapshots(
      filters: {
        pay_period_id: ${pay_period_id}
        ${!!store_id ? `store_id: ${store_id}` : ``}
      }
    ) {
      name
      store {
        id
        name
      }
      pay_period {
        id
        start
        end
      }
      last_modified
      size
    }
  }
`

export const payPeriodsWithSnapshots = (page = 1, count = 10) => gql`
  query {
    self {
      organization {
        id
        name
        payPeriodsPaginated(first: ${count}, page: ${page}) {
          data {
            id
            start
            end
            closedPayPeriods {
              store {
                id
                name
              }
              created_at
            }
          }
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }
    }
  }
`

export const RECENT_PAY_PERIODS = gql`
  query recentPayPeriods {
    self {
      organization {
        id
        name
        recent_pay_periods {
          id
          exported_at
          closedPayPeriods {
            id
            exported_at
            created_at
            store {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const SALE_SEARCH = gql`
  query saleSearch($page: Int, $first: Int, $filters: SaleFilters) {
    saleSearch(page: $page, first: $first, filters: $filters) {
      data {
        id
        external_id
        sale_time
        total_sales
        total_cc_tips
        total_cash_tips
        total_sales_refund
        total_tips_refund
        total_service_charges
        voided
        checks {
          id
        }
        distributions {
          id
        }
        employee {
          id
          first_name
          last_name
          store {
            id
            name
            organization {
              id
              name
            }
          }
        }
        workedShift {
          id
          start
          end
          jobCode {
            id
            name
          }
        }
      }
      paginatorInfo {
        currentPage
        lastPage
        total
      }
    }
  }
`

export const JOB_CODE_SEARCH = gql`
  query jobCodeSearch($page: Int, $first: Int, $filters: JobCodeFilters) {
    jobCodeSearch(page: $page, first: $first, filters: $filters) {
      data {
        id
        external_id
        name
        displayed
        deleted
        is_custom
        is_tipped
        take_home_enabled
        employeeAssignments {
          id
          first_name
          last_name
          external_id
          email
          deleted
        }
        tipPoolRules {
          id
          name
          active
          distributionSources {
            id
            name
          }
        }
        tipShareRules {
          id
          name
          active
          distributionSources {
            id
            name
          }
        }
        store {
          id
          name
          active
        }
      }
      paginatorInfo {
        currentPage
        lastPage
        total
      }
    }
  }
`

export const EMPLOYEE_SEARCH = gql`
  query employeeSearch($page: Int, $first: Int, $filters: EmployeeFilters) {
    employeeSearch(page: $page, first: $first, filters: $filters) {
      data {
        id
        first_name
        last_name
        name
        external_id
        deleted
        email
        payroll_id
        is_custom
        store {
          id
          name
          organization {
            id
            name
          }
        }
        user {
          id
          name
          email
        }
        most_recent_worked_shift {
          id
          start
          end
          jobCode {
            id
            name
          }
        }
        assignedJobCode {
          id
          name
        }
      }
      paginatorInfo {
        currentPage
        lastPage
        total
      }
    }
  }
`

export const REVENUE_CENTER_SEARCH = gql`
  query revenueCenterSearch(
    $page: Int
    $first: Int
    $filters: RevenueCenterFilters
  ) {
    revenueCenterSearch(page: $page, first: $first, filters: $filters) {
      data {
        id
        name
        external_id
        deleted
        store {
          id
          name
        }
      }
      paginatorInfo {
        currentPage
        lastPage
        total
      }
    }
  }
`

export const SHARED_JOB_CODE_NAMES = gql`
  query sharedJobCodeNames($store_ids: [ID!]!) {
    sharedJobCodeNames(store_ids: $store_ids)
  }
`

export const SHARED_SALES_CATEGORY_NAMES = gql`
  query sharedJobCodeNames($store_ids: [ID!]!) {
    sharedSalesCategoryNames(store_ids: $store_ids)
  }
`

export const SHARED_REVENUE_CENTERS = gql`
  query sharedRevenueCenterNames($store_ids: [ID!]!) {
    sharedRevenueCenterNames(store_ids: $store_ids)
  }
`

export const ORG_SALE_CATEGORIES = gql`
  query {
    self {
      id
      authorizedStores {
        id
        name
        salesCategories {
          id
          external_id
          name
          active
          created_at
        }
      }
    }
  }
`

export const STORE_SALE_CATEGORIES = gql`
  query store($id: ID!) {
    store(id: $id) {
      id
      name
      salesCategories {
        id
        external_id
        name
        active
        created_at
      }
    }
  }
`

export const STORE_REVENUE_CENTERS = gql`
  query store($id: ID!) {
    store(id: $id) {
      id
      name
      revenueCenters {
        id
        external_id
        name
        is_default
        created_at
      }
    }
  }
`

export const ORGANIZATION_REPORTS = gql`
  query organizationReports($org_id: Int, $active: Boolean) {
    organizationReports(organization_id: $org_id, active: $active) {
      id
      active
      name
      display_name
      description
      organization {
        id
        name
      }
      columns {
        id
        order
        name
        display_name
        description
        default_display_name
        default_description
      }
    }
  }
`

export const fetchReportExports = (store_id = null) => gql`
  query {
    fetchReportExports(store_id: ${store_id}) {
      id
      name
      report {
        id
        active
        name
        display_name
        description
      }
      reportable {
        __typename
        ... on Store {
          id
          name
          organization {
            id
            name
          }
        }
        ... on Organization {
          id
          name
        }
      }
    }
  }
`

export const report_export_runs = gql`
  query($filters: ExportRunFilters!, $first: Int!, $page: Int!) {
    reportExportRuns(filters: $filters, first: $first, page: $page) {
      data {
        id
        output
        arguments
        started_at
        finished_at
        failed_at
        created_at
        reportExport {
          id
          name
          driver
        }
      }
      paginatorInfo {
        currentPage
        lastPage
        total
      }
    }
  }
`

export const FETCH_REPORT = gql`
  query fetchReport($input: ReportQueryInput!) {
    fetchReport(input: $input) {
      id
      name
      columns {
        name
        display_name
        description
        appended
        type
      }
      values
    }
  }
`

export const TERMS_OF_SERVICE_LATEST = gql`
  query {
    termsOfServiceActive {
      id
      document_url
      published_at
    }
  }
`

export const ORGANIZATION_STORES = gql`
  query organizationStores($store_ids: [Int!], $active: Boolean) {
    organizationStores(store_ids: $store_ids, active: $active) {
      id
      name
      active
      timezone
      revenueCenters {
        id
        external_id
        name
        is_default
      }
      settings {
        key
        value
      }
    }
  }
`

export const EMPLOYEE_TAKE_HOME_DETAIL = gql`
  query employeeTakeHomeDetailByDate(
    $id: Int!
    $start_date: Date!
    $end_date: Date!
  ) {
    employeeTakeHomeDetailByDate(
      id: $id
      start_date: $start_date
      end_date: $end_date
    ) {
      id
      window_start
      jobCode {
        id
        name
      }
      employee {
        id
        name
        first_name
      }
      saleDistributions {
        id
        deleted
        is_deposit
        amount
        rule {
          id
          name
          type
        }
        distributionSource {
          id
          name
        }
        distributable {
          ... on Sale {
            id
            total
            sale_time
          }
        }
      }
    }
  }
`

const PAYOUT_GQL = `
  id
  total_amount
  total_fees
  total_employer_fees
  total_amount_unsuccessful
  failed
  failed_message
  created_at
  completed_at
  user {
    id
    name
  }
  payPeriod {
    id
    start
    end
  }
  employeePayouts {
    id
    amount
    net_amount
    employee_fee
    employer_fee
    payout_method
    failed
    failed_message
    created_at
    completed_at
    employee {
      id
      name
      store {
        id
        name
      }
    }
  }
`

export const FETCH_PAYOUTS = gql`
  query getPayouts(
    $store_id: ID
    $employee_id: ID
    $start_date: Date!
    $end_date: Date!
    $page: Int
    $per_page: Int
    $sort_dir: String
  ) {
    getPayouts(
      store_id: $store_id
      employee_id: $employee_id
      start_date: $start_date
      end_date: $end_date
      page: $page
      first: $per_page
      sort_dir: $sort_dir
    ) {
      data {
        ${PAYOUT_GQL}
      }
      paginatorInfo {
        currentPage
        lastPage
        total
      }
      aggregates {
        total_amount_successful
        total_employee_fees
        total_employer_fees
      }
    }
  }
`

export const LATEST_PAYOUT = gql`
  query getLatestPayout($store_id: ID) {
    getLatestPayout(store_id: $store_id) {
      payout {
        completed_at
        failed
        failed_message
        employeePayouts {
          failed
          failed_message
        }
      }
    }
  }
`

export const USERS_IN_ETA_REGISTRATION = gql`
  query usersInEtaRegistration($org_id: ID!, $store_id: ID) {
    usersInEtaRegistration(org_id: $org_id, store_id: $store_id) {
      state
      invite_expires
      payout_method
      user {
        id
        name
        email
        eta_state
        eta_payout_method
        eta_opted_out_at
      }
      person {
        first_name
        last_name
        address_line_1
        address_line_2
        city
        country_code
        postal_code
        state
      }
      transfer {
        from_payout_method
        from_effective_date
      }
    }
  }
`

export const GET_AVAILABLE_ETA_INVITES = gql`
  query getAvailableEtaInvites($org_id: ID!, $store_id: ID) {
    getAvailableEtaInvites(org_id: $org_id, store_id: $store_id) {
      id
      name
      email
      user {
        id
        name
        email
      }
      store {
        id
        name
      }
    }
  }
`

export const EMPLOYEE_ETA_PAYOUTS = gql`
  query($employee_id: ID!, $pay_period_id: ID!) {
    employeeEtaPayouts(
      employee_id: $employee_id
      pay_period_id: $pay_period_id
    ) {
      id
      amount
      net_amount
      take_home
      dates_included
      employee_fee
      employer_fee
      payout_method
      completed_at
      employee {
        name
      }
    }
  }
`
