import { useState } from "react"

import Styled from "styled-components"

import Icon from "../../Icon"
import { Modal, Loader, Button, SubmitButton, ErrorMessage } from "../"

import { style, colors } from "../../../constants"

export default ({
  steps,
  loading = false,
  size = "fullscreen",
  show_step_icon = true,
  onClose,
  onSubmit,
  ...props
}) => {
  const [state, setState] = useState({ selected_step_idx: 0 })

  const { selected_step_idx } = state
  const selected_step = steps[selected_step_idx]
  const selectStep = idx => setState({ ...state, selected_step_idx: idx })
  const goBack = !!steps[selected_step_idx - 1]
    ? () => setState({ ...state, selected_step_idx: selected_step_idx - 1 })
    : null
  const goForward =
    !!steps[selected_step_idx + 1] && selected_step.complete
      ? () => setState({ ...state, selected_step_idx: selected_step_idx + 1 })
      : null

  return (
    <StyledSequenceModal
      size={selected_step.size ?? size}
      loading={loading}
      onClose={onClose}
      goBack={goBack}
      onSubmit={onSubmit}
      goForward={goForward}
      final_step={!steps[selected_step_idx + 1]}
      sequence_complete={steps.filter(step => !step.complete).length === 0}
      {...props}
    >
      <StepNavigation
        steps={steps}
        selectStep={selectStep}
        selected_step_idx={selected_step_idx}
        show_step_icon={show_step_icon}
      />
      <StepContent step={selected_step} />
    </StyledSequenceModal>
  )
}

const StyledSequenceModal = Styled(
  ({
    size,
    header,
    loading,
    final_step,
    sequence_complete,
    children,
    goBack,
    goForward,
    onSubmit,
    onClose,
    ...props
  }) => (
    <Modal
      centered
      size={size}
      onClose={onClose}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      {...props}
    >
      {!!header && <SequenceHeader onClose={onClose} header={header} />}
      <>
        {!!loading && <SequenceLoader />}
        {!loading && (
          <>
            <Modal.Content>
              <div>{children}</div>
            </Modal.Content>
            <Modal.Actions>
              {!!goBack && (
                <Button
                  basic
                  icon
                  labelPosition="left"
                  floated="left"
                  onClick={goBack}
                >
                  Back <Icon name="arrow left" />
                </Button>
              )}
              {!final_step && (
                <Button
                  basic
                  icon
                  labelPosition="right"
                  disabled={!goForward}
                  onClick={goForward}
                >
                  Next <Icon name="arrow right" />
                </Button>
              )}
              {(final_step || sequence_complete) && !!onSubmit && (
                <SubmitButton
                  disabled={!sequence_complete}
                  onClick={onSubmit}
                />
              )}
              {!onSubmit && (
                <Button basic onClick={onClose}>
                  Close
                </Button>
              )}
            </Modal.Actions>
          </>
        )}
      </>
    </Modal>
  )
)`
  &&& > .content {
    position: relative;
    padding: 0 !important;

    & > div {
      display: grid;
      grid-template-columns: auto 1fr;
    }
  }
`

const default_nav_bg = colors.light2
const default_nav_item_bg = colors.light2
const hover_nav_item_bg = colors.light3
const active_nav_item_bg = colors.white
const border_color = colors.light5

const SequenceHeader = Styled(({ header, className, onClose }) => (
  <Modal.Header className={className} onClose={onClose}>
    {header}
  </Modal.Header>
))`
  border-bottom: none !important;
  background-color: ${default_nav_bg} !important;
`

const StepNavigation = Styled(
  ({ steps, selected_step_idx, selectStep, show_step_icon, className }) => (
    <div className={className}>
      {steps.map((step, step_idx) => (
        <NavStep
          step={step}
          key={step_idx}
          step_number={step_idx + 1}
          active={step_idx === selected_step_idx}
          show_step_icon={show_step_icon}
          selectStep={() => selectStep(step_idx)}
          // disabled if one or more steps before this step is not marked complete
          disabled={
            step_idx > selected_step_idx &&
            steps.filter(
              (filter_step, filter_step_idx) =>
                filter_step_idx < step_idx && !filter_step.complete
            ).length > 0
          }
        />
      ))}
    </div>
  )
)`
  position: relative;
  white-space: nowrap;
  border-right: 1px solid ${border_color};
  background-color: ${default_nav_bg};
`

const NavStep = Styled(
  ({
    step,
    active,
    disabled,
    className,
    selectStep,
    step_number,
    show_step_icon
  }) => {
    let icon_name = "circle outline"
    let icon_color = colors.light5
    if (active && !step.complete) {
      icon_name = "arrow right circle"
      icon_color = colors.success
    } else if (!disabled && !step.complete) {
      icon_name = "arrow right circle"
    } else if (!disabled && step.complete) {
      icon_name = "check circle"
      icon_color = colors.green
    }

    return (
      <div className={className}>
        <div onClick={!!disabled ? undefined : () => selectStep()}>
          <span className="nav-step-number">{step_number}.</span>{" "}
          <span className="nav-step-name">{step.name}</span>
          {show_step_icon && (
            <StepStatusIcon name={icon_name} color={icon_color} />
          )}
        </div>
      </div>
    )
  }
)`
  position: relative;
  padding-left: 1rem;
  font-size: 1.1rem;
  & > div {
    transition: all 0.1s ease-out;
    cursor: ${({ disabled }) => (!!disabled ? "not-allowed" : "pointer")};
    border-top-left-radius: ${style.border_radius};
    border-bottom-left-radius: ${style.border_radius};
    margin-right: -1px;
    ${({ active, disabled }) =>
      !!active
        ? `
      padding: 1.2rem 1rem;
      margin-right: -1px;
      background-color: ${active_nav_item_bg};
      border-top: 1px solid ${border_color};
      border-bottom: 1px solid ${border_color};
      border-left: 1px solid ${border_color};
      border-right: 1px solid ${active_nav_item_bg};
      & > .icon {
        opacity: 1;
      }
    `
        : `
      background-color: ${default_nav_item_bg};
      border: 1px solid transparent;
      border-right: 1px solid ${border_color};
      padding: 0.67rem 1rem;
      ${
        !disabled
          ? `&:hover {
          background-color: ${hover_nav_item_bg};
        }`
          : ""
      }
    `}
    & > span {
      &.nav-step-number {
        margin-right: 0.2rem;
      }
      &.nav-step-name {
        font-weight: 300;
      }
    }
  }
`

const StepStatusIcon = Styled(({ name, color, className }) => (
  <div className={className}>
    <Icon fitted name={name} color={color} />
  </div>
))`
  display: inline-block;
  width: 20px;
  vertical-align: top;
  transition: opacity 0.1s ease-out;
  line-height: 1.5rem !important;
  margin-left: 0.6rem !important;
`

const MIN_CONTENT_HEIGHT = "calc(90vh - 10rem)"

const StepContent = Styled(({ step, className }) => (
  <div className={className}>
    {!!step && <>{step.render()}</>}
    {!step && <StepNotFound />}
  </div>
))`
  height: ${MIN_CONTENT_HEIGHT};
  position: relative;
  padding: 1.7rem 2rem;
  overflow: auto;
  border-top: 1px solid ${border_color};
`

const SequenceLoader = Styled(props => (
  <div {...props}>
    <Loader inline active size="large" />
  </div>
))`
  background-color: ${default_nav_bg};
  height: ${MIN_CONTENT_HEIGHT};
  padding: 5rem 2rem;
  text-align: center;
`

const StepNotFound = () => <ErrorMessage />
