import { forwardRef } from "react"

import Icon from "./Icon"

import { colors } from "../../../constants"

export default forwardRef(({ color = colors.success, ...props }, ref) => {
  return (
    <Icon name="question circle outline" color={color} {...props} ref={ref} />
  )
})
