import { useState } from "react"
import { useQuery } from "@apollo/client"

import Search, { ResultItem, DEBOUNCE_MS, PAGE, PER_PAGE } from "."

import { toInt } from "../../helpers/number"
import { readableRuleType } from "../Rule/helpers"
import { useOrganization } from "../../hooks/selectors"
import { DISTRIBUTION_RULE_SEARCH } from "../../graphql/queries"

let debounce_ctr = null

export default ({
  active = true,
  no_user = null,
  store_id = null,
  skip_empty = true,
  placeholder = "Search Tip Share or Pool",
  ...props
}) => {
  const organization = useOrganization()
  const [{ search, search_request }, setSearch] = useState({
    search: "",
    search_request: null
  })

  const { data, loading } = useQuery(DISTRIBUTION_RULE_SEARCH, {
    skip: !!skip_empty && search.trim() === "",
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: {
      filters: {
        search,
        store_id,
        active: true,
        organization_id: organization.id
      },
      page: PAGE,
      first: PER_PAGE
    }
  })

  const is_loading = loading || !!search_request
  const results = (data?.ruleSearch?.data ?? [])
    .map(({ id, ...rest }) => ({ id: toInt(id), ...rest }))
    .map(rule => ({
      key: rule.id,
      value: rule.id,
      title: rule.name,
      rule: { ...rule }
    }))

  return (
    <Search
      input={{ icon: "search", iconPosition: "left" }}
      placeholder={placeholder}
      loading={is_loading}
      results={results}
      onSearchChange={search => {
        let now = Date.now()
        debounce_ctr = now
        setSearch({ search: "", search_request: search })
        setTimeout(() => {
          if (debounce_ctr === now) {
            debounce_ctr = null
            setSearch({ search, search_request: null })
          }
        }, DEBOUNCE_MS)
      }}
      noResultsMessage={!!is_loading ? "Searching..." : "No results found."}
      resultRenderer={({ title, rule }) => (
        <ResultItem
          title={title}
          subtitle={`${readableRuleType(rule.type)}${
            !store_id ? ` at ${rule.store.name}` : ""
          }`}
        />
      )}
      {...props}
    />
  )
}
