import Styled from "styled-components"
import { darken, setLightness } from "polished"
import { Button as SemanticButton } from "semantic-ui-react"

import Popup from "../Popup"

import { colors } from "../../../constants"

export { default as IconButton } from "./Icon"
export { default as AlertButton } from "./Alert"
export { default as SubmitButton } from "./Submit"

const color_primary = setLightness(0.5, colors.success)
const color_secondary = setLightness(0.65, colors.success)
const color_negative = setLightness(0.7, colors.danger)
const color_positive = setLightness(0.4, colors.green)

// todo: the style implementation needs to be cleaned up
const StyledButton = Styled(
  ({ style_type, strong, color, dark, fitted, ...props }) => (
    <SemanticButton {...props} />
  )
)`${({
  color,
  dark = false,
  strong = null,
  fitted = false,
  style_type = null
}) => `
  &&&.button {
    ${strong === true ? "font-weight: 600;" : ""}
    ${strong === false ? "font-weight: 400;" : ""}
    ${!fitted ? "" : "margin-right: 0 !important;"}
    &.basic {
      background-color: ${colors.white} !important;
      box-shadow: none !important;
      &:hover {
        box-shadow: none !important;
      }
    }
  }
  ${
    !style_type
      ? `
  &&&.button.basic {
    ${
      !dark
        ? ""
        : `
      color: ${colors.light} !important;
      border: 0.5px solid rgba(255, 255, 255, 0.03) !important;
      background-color: rgba(255, 255, 255, 0.06) !important;
      &:hover {
        background-color: rgba(255, 255, 255, 0.12) !important;
        color: ${colors.white} !important;
      }

    `
    }
  }`
      : `
  &&&.button.${style_type} {
    background-color: ${color} !important;
    border: 0.5px solid ${color} !important;
    & .icon {
      color: ${!dark ? colors.white : colors.dark}
    }
    &:hover {
      background-color: ${darken(0.125, color)} !important;
      border: 0.5px solid ${darken(0.125, color)} !important;
    }
  }
  &&&.button.basic.${style_type} {
    background-color: ${!dark ? colors.white : colors.transparent} !important;
    color: ${color} !important;
    & .icon {
      color: ${color};
    }
    &:hover {
      background-color: ${color}  !important;
      border: 0.5px solid ${color} !important;
      color: ${!dark ? colors.white : colors.dark} !important;
      & .icon {
        color: ${!dark ? colors.white : colors.dark} !important;
      }
    }
  }`
  }
`}`
const PrimaryButton = props => (
  <StyledButton style_type="primary" color={color_primary} {...props} />
)
const SecondaryButton = props => (
  <StyledButton style_type="secondary" color={color_secondary} {...props} />
)
const PositiveButton = props => (
  <StyledButton style_type="positive" color={color_positive} {...props} />
)
const NegativeButton = props => (
  <StyledButton style_type="negative" color={color_negative} {...props} />
)
const DefaultButton = Styled(props => <StyledButton {...props} />)`
  border: 0.5px solid #e0e1e2 !important;
  &:hover {
    border: 0.5px solid #cacbcd !important;
  }
`

const TypedButton = props => {
  const { positive, negative, primary, secondary } = props
  if (!!primary) {
    return <PrimaryButton {...props} />
  } else if (secondary) {
    return <SecondaryButton {...props} />
  } else if (positive) {
    return <PositiveButton {...props} />
  } else if (negative) {
    return <NegativeButton {...props} />
  }
  return <DefaultButton {...props} />
}

const Button = ({ popup = false, ...props }) => {
  let popup_props = false
  if (popup) {
    popup_props = popup?.content ? { ...popup } : { content: popup }
  }
  return (
    <>
      {!popup_props && <TypedButton {...props} />}
      {!!popup_props && (
        <Popup {...popup_props}>
          <TypedButton {...props} />
        </Popup>
      )}
    </>
  )
}

Button.Group = SemanticButton.Group
Button.Or = SemanticButton.Or

export default Button
