export const p2p_settings_key = "p2p_enabled"
export const p2p_enabled_val = "1"
export const p2p_disabled_val = "0"

export const completed_user_onboarding_settings_key =
  "completed_user_onboarding"
export const routes_visited_settings_key = "routes_visited"
export const eta_intro_modal_route_key = "eta-intro-modal"

export const completed_org_onboarding_settings_key = "completed_org_onboarding"
export const store_completed_sync_settings_key = "completed_initial_sync"
export const setting_scheduled_jobs_enabled = "scheduled_jobs_enabled"

export const cash_tip_distribution_settings_key = "cash_tip_rules_enabled"
export const cash_tip_distribution_enabled_val = "1"

export const cc_tip_fee_settings_key = "cc_tip_fee"

export const pay_period_auto_finalize_settings_key =
  "pay_period_auto_finalize_days"

export const sale_assigned_pools_settings_key = "sale_assigned_pools_enabled"
export const sale_assigned_pools_enabled_val = "1"

export const business_start_hour_settings_key = "business_day_start_hour"

export const recurring_shifts_settings_key = "recurring_shifts_enabled"
export const recurring_shifts_enabled_val = "1"

export const report_exports_settings_key = "report_exports_enabled"
export const report_exports_enabled_val = "1"

export const weekly_distros_settings_key = "weekly_distributions_enabled"
export const weekly_distros_enabled_val = "1"

export const flat_rate_settings_key = "flat_rate_rules"
export const flat_rate_enabled_val = "1"

export const rev_centers_settings_key = "revenue_centers_enabled"
export const rev_centers_enabled_val = "1"

export const can_update_sale_details_key = "can_update_sale_details"
export const can_update_sale_details_val = "1"

export const none_job_code_sales_notifications_settings_key =
  "none_job_code_sales_notifications"
export const none_job_code_sales_notifications_enabled_val = "1"

export const excessive_tip_notifications_settings_key =
  "excessive_tip_threshold"
export const excessive_tip_notifications_enabled_val = "0"

export const apply_rules_to_sale_assigned_pools_settings_key =
  "auto_apply_rules_to_sale_assigned_pools"
export const apply_rules_to_sale_assigned_pools_enabled_val = "1"
