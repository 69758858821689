import { darken } from "polished"
import Styled from "styled-components"

import Popup from "./Popup"

import { colors } from "../../constants"

const style = `
  display: inline-block;
  color: ${colors.link};
  cursor: pointer;
  &:hover {
    color: ${darken(0.1, colors.link)};
  }
`

const LinkAnchor = Styled(({ font_weight, active, ...props }) => (
  <a {...props} />
))`
  ${({ font_weight = false }) =>
    !!font_weight ? `font-weight: ${font_weight};` : ``}
  ${({ active = false }) => (!active ? "" : "text-decoration: underline;")}
  ${style}
`
const LinkSpan = Styled(({ font_weight, active, ...props }) => (
  <span {...props} />
))`
  ${({ font_weight = false }) =>
    !!font_weight ? `font-weight: ${font_weight};` : ``}
  ${({ active = false }) => (!active ? "" : "text-decoration: underline;")}
  ${style}
`

const LinkWrapper = ({ children, ...props }) =>
  !!props.onClick ? (
    <LinkSpan {...props}>{children}</LinkSpan>
  ) : (
    <LinkAnchor {...props}>{children}</LinkAnchor>
  )

const Link = ({ popup = false, ...props }) => {
  let popup_props = false
  if (popup) {
    popup_props = popup?.content ? { ...popup } : { content: popup }
  }
  return (
    <>
      {!popup_props && <LinkWrapper {...props} />}
      {!!popup_props && (
        <Popup {...popup_props}>
          <LinkWrapper {...props} />
        </Popup>
      )}
    </>
  )
}

export default Link
