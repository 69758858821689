import { Checkbox } from "../../../../../Shared"

export default ({ selected, options, onUpdate }) => {
  return (
    <>
      {[...options]
        .sort((a, b) => a.text.localeCompare(b.text))
        .map(({ value, text }) => (
          <div key={value}>
            <Checkbox
              label={text}
              checked={selected.includes(value)}
              onChange={checked =>
                onUpdate(
                  checked
                    ? [...selected, value]
                    : selected.filter(s => s !== value)
                )
              }
            />
          </div>
        ))}
    </>
  )
}
