import axios from "axios"
import { saveAs } from "file-saver"
import { api, paths, urls } from "./../constants"
import { logoutSuccess } from "./../actions/authenticate"
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  createHttpLink
} from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import { setContext } from "@apollo/client/link/context"

const UNAUTHENTICATED_ERROR = "unauthenticated"

export const getFilenameFromHeaders = headers =>
  headers["content-disposition"]
    .split(";")
    .find(n => n.includes("filename="))
    .replace("filename=", "")
    .trim()

const getWithParams = async (url, params) =>
  await axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "client-name": "tiphaus-client"
    },
    params,
    withCredentials: true
  })

export const downloadFile = async (path, params = {}) => {
  const url = urls().api + path
  const response = await getWithParams(url, params)
  const filename = getFilenameFromHeaders(response.headers)

  saveAs(new Blob([response.data]), filename)
}

export const getRequest = async (path, params = {}) => {
  const url = urls().api + path
  return await getWithParams(url, params)
}

const httpLink = createHttpLink({
  uri: api.graphql_origin,
  credentials: "include"
})

const authMiddleware = setContext(() => {
  return {
    headers: {
      "client-name": "tiphaus-client"
    }
  }
})

const isAuthenticationError = ({ message = "" }) =>
  message.toLowerCase().includes(UNAUTHENTICATED_ERROR)

const errorHandler = onError(({ graphQLErrors, networkError, operation }) => {
  if (operation.getContext()?.response?.status === 503) {
    window.location.href = paths.maintenance
  }
  if (graphQLErrors) {
    const unauthenticated = graphQLErrors.some(isAuthenticationError)

    if (unauthenticated) {
      logoutSuccess()
    }
  }
})

export const apollo_client = new ApolloClient({
  link: ApolloLink.from([errorHandler, authMiddleware, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          organization: {
            merge: true
          }
        }
      }
    }
  })
})

// gets a flat list of form validation error messages returned from graphQl
export const getGraphQLValidationErrors = (errors = []) => {
  const result = errors.reduce((acc, obj) => {
    const messsages = Object.values(
      obj.extensions.validation ?? { errors: [obj.debugMessage] }
    ).reduce((arr, fieldErrors) => [...arr, ...fieldErrors], [])
    return [...acc, ...messsages]
  }, [])
  return result
}
