import React from "react"
import Styled from "styled-components"
import moment from "moment"

import Logo from "../../Logo"
import Icon from "../../Icon"
import Link from "../../Link"

import { colors, style } from "../../../constants"

// theme
const primary_background = colors.light2
const primary_font_color = colors.dark
const separator_color = colors.dark5

const logo_primary = colors.success
const logo_secondary = colors.dark5

export const FooterWrapper = Styled.div`
  ${({ night_mode }) => (!night_mode ? `` : `filter: invert(0.8);`)}
  background-color: ${primary_background};
  height: ${style.footer.height};
  text-align: right;
  overflow: hidden;
`

export const FooterLinks = Styled.div`
  line-height: ${style.footer.height};
  display: inline-block;
  font-size: 1.1rem;
`

export const FooterLink = Styled(props => (
  <Link {...props} font_weight={300} />
))`
  padding: 0 0.67em;
  line-height: ${style.footer.height};
`

export const LinkSeparator = Styled.div`
  color: ${separator_color};
  display: inline;
  &::after {
    content: "|";
  }
`

export const FooterLogo = Styled(props => (
  <div {...props}>
    <Logo
      size="mini"
      verticalAlign="top"
      transparent
      primaryColor={logo_primary}
      secondaryColor={logo_secondary}
      style={{ marginRight: "0.5em", marginTop: "1px" }}
    />
    <Icon name="copyright outline" size="small" />
    &nbsp;
    {moment().year()}
  </div>
))`
  display: inline-block;
  color: ${primary_font_color};
  margin: 0 1rem;
  & > div {
    line-height: 0.8rem !important;
  }
`
