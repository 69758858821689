import { Component, Fragment } from "react"

import moment from "moment"
import Styled from "styled-components"
import { Accordion } from "semantic-ui-react"
import { Query } from "@apollo/client/react/components"

import Icon from "../../Icon"
import Message from "../../Message"
import SnapshotsTable from "./Table"
import Paginator from "../../Paginator"
import { LoadingMessage } from "../../Shared/Loader"
import Introduction from "../../Shared/PageIntroduction"

import { payPeriodsWithSnapshots } from "../../../graphql/queries"
import { colors, month_day_year_format } from "../../../constants"

const ContentWrapper = Styled.div``
const PayPeriodsWrapper = Styled.div``
const PaginationWrapper = Styled.div`
  text-align:right;
  margin-bottom:0.67rem;
`

const AccordionTitle = Styled(Accordion.Title)`
  font-size: 1.1rem !important;
  ${({ active }) =>
    !active
      ? `
    font-weight: 400 !important;
    color: ${colors.dark2} !important;
  `
      : `
    font-weight: 600 !important;
    color: ${colors.dark} !important;
  `}
  &:hover {
    color: ${colors.dark} !important;
  }
`
const AccordionContent = Styled(Accordion.Content)``

const NoPayPeriods = props => (
  <Message inline type="info" title="No Pay Periods" {...props}>
    No closed pay periods found. Snapshots are automatically saved after the
    close of each pay period.
  </Message>
)

const formatDateDisplay = (start, end) => {
  return (
    moment(start).format(month_day_year_format) +
    " to " +
    moment(end).format(month_day_year_format)
  )
}

const parsePaginatorInfo = data => {
  let page = 1
  let total_pages = 0
  let total = 0
  try {
    const { paginatorInfo } = data.self.organization.payPeriodsPaginated
    page = paginatorInfo.currentPage
    total_pages = paginatorInfo.lastPage
    total = paginatorInfo.total
  } catch {}

  return { page, total_pages, total }
}

const parsePayPeriods = data => {
  let pay_periods = []
  try {
    pay_periods = [...data.self.organization.payPeriodsPaginated.data]
  } catch {}
  return pay_periods
}

export default class extends Component {
  state = {
    page: 1,
    limit: 10,
    active_index: null
  }

  togglePayPeriod = (e, props) => {
    const { index } = props
    const { active_index } = this.state
    const new_index = active_index === index ? null : index

    this.setState({ active_index: new_index })
  }

  render() {
    const { user, selected_location_name } = this.props
    const { page, limit, active_index } = this.state

    return (
      <ContentWrapper>
        <Introduction right_pad={false}>
          Download and review breakdown snapshots for past pay periods.
        </Introduction>
        <Query
          fetchPolicy="network-only"
          notifyOnNetworkStatusChange
          query={payPeriodsWithSnapshots(page, limit)}
        >
          {({ data, loading }) => {
            const pay_periods = parsePayPeriods(data)
            const { total, total_pages } = parsePaginatorInfo(data)

            if (!!loading) {
              return (
                <LoadingMessage show_loader={false}>
                  Loading pay periods for{" "}
                  {!!selected_location_name
                    ? selected_location_name
                    : user.organization.name}
                </LoadingMessage>
              )
            }

            if (!pay_periods.length) {
              return <NoPayPeriods />
            }

            return (
              <PayPeriodsWrapper>
                {total_pages > 1 && (
                  <PaginationWrapper>
                    <Paginator
                      current_page={page}
                      total={total}
                      per_page={limit}
                      changePage={page => this.setState({ page })}
                    />
                  </PaginationWrapper>
                )}

                <Accordion styled fluid>
                  {pay_periods.map((pp, i) => (
                    <Fragment key={i}>
                      <AccordionTitle
                        active={active_index === i}
                        index={i}
                        onClick={this.togglePayPeriod}
                      >
                        <Icon name="dropdown" />
                        {formatDateDisplay(pp.start, pp.end)}
                      </AccordionTitle>
                      <AccordionContent active={active_index === i}>
                        <SnapshotsTable
                          active={active_index === i}
                          pay_period={pp}
                          {...this.props}
                        />
                      </AccordionContent>
                    </Fragment>
                  ))}
                </Accordion>
              </PayPeriodsWrapper>
            )
          }}
        </Query>
      </ContentWrapper>
    )
  }
}
