import Styled from "styled-components"
import { lighten, darken } from "polished"
import { Statistic, List } from "semantic-ui-react"

import Icon from "../../Icon"
import Popup from "../../Popup"
import Dropdown, { MultiSelect } from "../../Dropdown"

import { colors } from "../../../constants"

// Top Level components
export const MetricsControl = Styled(
  ({
    selected_metrics,
    metrics_options,
    onChangeDisplayed,
    date_range_options,
    selected_date_range,
    onSelectDateRange,
    ...props
  }) => (
    <div>
      <div {...props} id="landing-metric-filters">
        <MetricsControlLabel>Showing</MetricsControlLabel>
        <MetricsControlDropdown
          placeholder="Select Metrics to Display"
          max_labels={3}
          value={selected_metrics}
          options={metrics_options}
          onChange={(e, { value }) => onChangeDisplayed(value)}
        />
        <MetricsControlLabel>from</MetricsControlLabel>
        <MetricsTimeRangeDropdown
          selection
          options={date_range_options}
          value={selected_date_range}
          onChange={(e, { value }) => onSelectDateRange(value)}
        />
      </div>
    </div>
  )
)`
  margin-bottom: 1.33rem;
  font-weight: 300;
  font-size: 1.2rem;
  display: inline-block;

  & > div {
    vertical-align: top;
  }
  &&& .dropdown {
    min-height: 3rem;
    margin-bottom: 0.5rem;
    & > .dropdown.icon {
      top: 0.65rem;
    }
  }
  & > div:first-child {
    margin-left: 0;
  }
`
const MetricsControlLabel = Styled.div`
  display: inline-block;
  margin: 0.75rem 0.5rem;
  font-size: 1.2rem;
`
const MetricsControlDropdown = Styled(MultiSelect)`
  vertical-align: top;
  max-width: 35rem;
  & > .text {
    margin-top: 0.367em !important;
    margin-bottom: 0.367em !important;
  }
`
const MetricsTimeRangeDropdown = Styled(props => <Dropdown {...props} />)`
  padding-top: 0.7em !important;
  padding-bottom: 0.7em !important;
  min-width: 13rem !important;
`

// Metric components
export const MetricHeader = Styled(
  ({ moveBack, remove, description, showChart, name, ...props }) => (
    <div {...props}>
      <LeftIcons>{!!moveBack && <MoveBackIcon onClick={moveBack} />}</LeftIcons>
      <MetricTitle title={name} />
      <RightIcons>
        {!!description && (
          <InfoIcon info={<MetricDescription description={description} />} />
        )}
        {!!showChart && <ToggleChartIcon onClick={showChart} />}
        {!!remove && <RemoveIcon onClick={remove} />}
      </RightIcons>
    </div>
  )
)`
  padding: 0.67rem;
  font-weight: 300;
  font-size: 1.3rem;
  position: relative;
  color: ${colors.dark};
  background-color: ${colors.light2};
  & i.icon {
    font-size: 1.22rem;
  }
`
export const MetricSegment = Styled.div`
  overflow: hidden;
  border-radius: 3px;
  margin-bottom: 1.5rem;
  border: 0.5px solid ${colors.table_border};

  &:hover {
    border: 0.5px solid ${colors.light6};
  }
`
const MetricTitle = Styled(({ title, ...props }) => (
  <div {...props}>{title}</div>
))`
  text-align: center;
  margin: 0 auto;
  clear: both;
  padding: 0 5rem;
`
const LeftIcons = Styled.div`
  position: absolute;
  left: 0.67rem;
  top: 0.75rem;
  height: 100%;
`
const RightIcons = Styled.div`
  position: absolute;
  right: 0.5rem;
  top: 0.75rem;
  height: 100%;
`
const PopupIcon = Styled(
  ({ color, popup, icon, cursor = "pointer", ...props }) => (
    <Popup delay content={popup}>
      <Icon size="small" cursor={cursor} {...props} />
    </Popup>
  )
)`
  float: ${({ align }) => (align === "right" ? "right" : "left")};
  box-sizing: border-box;
  color: ${colors.light6};
  cursor: pointer;
  &:hover {
    color: ${({ color }) => color};
  }
`
const RemoveIcon = props => (
  <PopupIcon
    name="remove circle"
    color={colors.danger}
    popup="Hide metric"
    {...props}
  />
)
const InfoIcon = ({ info, ...props }) => (
  <PopupIcon
    name="info circle"
    popup={info}
    cursor="default"
    color={colors.success}
    {...props}
  />
)
const ToggleChartIcon = ({ ...props }) => (
  <PopupIcon
    name="pie chart"
    popup="Show fullscreen leaderboard chart"
    color={colors.green}
    {...props}
  />
)
const MoveBackIcon = props => (
  <PopupIcon
    name="arrow left"
    color={colors.success}
    popup="Toggle left"
    {...props}
  />
)

export const MetricStatistic = Styled(Statistic)``

export const StatWrapper = Styled.div`
  padding: 3rem 1rem;
  text-align: center;
  background-color: ${colors.white};
`

export const TertiaryStatistic = Styled(props => (
  <Statistic horizontal size="small">
    <Statistic.Value {...props} />
  </Statistic>
))`
  &&&&&& {
    color: ${colors.tween} !important;
    font-weight: 300;
    font-size: 2.67rem !important;
    line-height: 4rem !important;
  }
`

export const MetricDisabled = Styled(({ msg, ...props }) => (
  <div {...props}>{msg}</div>
))`
  text-align: center;
  padding: 2rem 15%;
  font-size: 1.33rem;
  line-height: 2rem;
  font-weight: 300;
  color: ${colors.dark6};
`

const MetricDescription = Styled(({ description, ...props }) => (
  <List bulleted {...props}>
    {description.map((line, idx) => (
      <List.Item key={idx}>{line}</List.Item>
    ))}
  </List>
))``

// Leaderboard Components
export const LeaderboardWrapper = Styled.div``
export const LeaderWrapper = Styled.div`
  height: 2rem;
  position: relative;
  background-color: ${colors.light2};
  opacity: ${({ active = true }) => (!!active ? "1" : "0.5")};
  cursor: ${({ edit_mode = false }) => (!!edit_mode ? "pointer" : "default")};

  &:hover {
    background-color: ${darken(0.03, colors.light)};
    opacity: 1;
  }
`
export const LeaderName = Styled.div`
  display: inline-block;
  font-weight: 300;
`
export const LeaderTotal = Styled.div`
  position: absolute;
  right: 1rem;
  top: 0.33rem;
  font-weight: bold;
  color: ${colors.dark2};
`
export const LeaderRank = Styled.div`
  display: inline;
  font-weight: bold;
  color: ${colors.tween};
  font-style: italic;
  margin-right: 0.5rem;
  font-size: 1.2rem;
  vertical-align: top;
`
export const Qualifier = Styled.div`
  display: inline;
  font-weight: 300;
  margin-left: 0.33rem;
  font-style: italic;
  color: ${colors.dark4};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
export const NameAndStore = Styled.div`
  display: inline-block;
  width: 70%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
export const Leader = Styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0.33rem 0.67rem;
`
export const Meter = Styled.div`
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  border-radius: 0 0 0 3px;
  transition: width 0.3s ease-out;
  width: ${({ width }) => width}%;
  background-color ${({ idx }) => lighten(0.025 + 0.025 * idx, colors.info)};
`
export const Header = Styled.div`
  font-size: 0.7rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  position: relative;
  text-align: center;
  padding: 0.33rem 1rem;
  color: ${colors.dark3};
  background-color: ${colors.light3};
  & > i {
    color: ${colors.tween};
    font-size: 1rem;
  }
`
export const Trophy = Styled(Icon)`
  &&& {
    margin-right: 0.5rem;
    color: ${colors.gold};
  }
`
export const ShowAll = Styled.div`
  cursor: pointer;
  font-weight: 300;
  text-align: center;
  font-size: 0.95rem;
  padding: 0.25rem 1rem;
  background-color: ${colors.light3};
`
export const ToggleSettingsIcon = Styled(({ active, ...props }) => (
  <Popup
    content={
      !active
        ? "Customize which employees appear on this leaderboard."
        : "Looks Good!"
    }
    delay
    trigger={<Icon name={!!active ? "thumbs up" : "cog"} fitted {...props} />}
  />
))`
  position: absolute;
  right: 0.67rem;
  top: 0.33rem;
  color: ${colors.tween};
  cursor: pointer;
  &:hover {
    color: ${colors.success};
  }
`
export const ExportMetricsIcon = Styled(({ ...props }) => (
  <Popup
    content={"Download metric export"}
    delay
    trigger={<Icon name={"cloud download"} fitted {...props} />}
  />
))`
  position: absolute;
  right: 2.20rem;
  top: 0.33rem;
  color: ${colors.tween};
  cursor: pointer;
  &:hover {
    color: ${colors.success};
  }
`

export const HiddenEmployees = Styled(({ employees, ...props }) => (
  <div {...props}>
    {employees.length === 0 && (
      <>
        <Centered>
          <div>Use the checkboxes to control visibility of each employee.</div>
          <div>Customizing this chart won't affect other users or charts.</div>
        </Centered>
      </>
    )}
    {employees.map(e => (
      <HiddenEmployee key={e.id} employee={e} />
    ))}
  </div>
))`
  padding: 0.67rem;
  background-color: ${colors.white};
  color: ${colors.dark3};
  font-weight: 300;
  border-top: 0.5px solid ${colors.light4};
  border-bottom: 0.5px solid ${colors.light4};
`
const HiddenEmployee = Styled(props => <div>{props.first_name}</div>)``
const Centered = Styled.div`
  text-align: center;
  padding: 0.33rem 1rem;
`
