import { push } from "connected-react-router"

import {
  toast,
  logout,
  syncUser,
  setUser,
  showModal,
  clearToast,
  mountToast,
  closeModals,
  loginSuccess,
  unmountToast,
  initNightMode,
  triggerAppError,
  toggleNightMode,
  setRoutesVisited,
  clearReportFilters,
  setApplicationFilters,
  changeSelectedLocation,
  setFiltersForNamespace,
  setRuleTemplateForCreate
} from "../actions"

export default dispatch => ({
  navigateTo: location => dispatch(push(location)),
  changeSelectedLocation: id =>
    dispatch(changeSelectedLocation(id ? Number(id) : null)),
  logout: () => dispatch(logout()),
  loginSuccess: () => dispatch(loginSuccess()),
  showModal: name => dispatch(showModal(name)),
  closeModals: () => dispatch(closeModals()),
  triggerAppError: payload => dispatch(triggerAppError(payload)),
  setApplicationFilters: (filters, merge = true) =>
    dispatch(setApplicationFilters(filters, merge)),
  setFiltersForNamespace: (namespace, filters, merge = true) =>
    dispatch(setFiltersForNamespace(namespace, filters, merge)),
  clearReportFilters: (namespace = null) =>
    dispatch(clearReportFilters(namespace)),
  toggleNightMode: () => dispatch(toggleNightMode()),
  initNightMode: () => dispatch(initNightMode()),
  toast: config => dispatch(toast(config)),
  toastToSuccess: (message, title = undefined) =>
    dispatch(
      toast({
        type: "success",
        title,
        message
      })
    ),
  toastToFailure: (message, title = undefined) =>
    dispatch(
      toast({
        type: "error",
        title,
        message
      })
    ),
  clearToast: toast_id => dispatch(clearToast(toast_id)),
  mountToast: toast_id => dispatch(mountToast(toast_id)),
  unmountToast: toast_id => dispatch(unmountToast(toast_id)),
  syncUser: (onSuccess, onError) => dispatch(syncUser(onSuccess, onError)),
  setUser: (name, email) => dispatch(setUser(name, email)),
  setRoutesVisited: routes_visited =>
    dispatch(setRoutesVisited(routes_visited)),
  setRuleTemplateForCreate: rule => dispatch(setRuleTemplateForCreate(rule))
})
