import { createContext } from "react"

const NavigateToContext = createContext(undefined)

export const NavigateToProvider = ({ navigateTo, ...props }) => {
  return <NavigateToContext.Provider {...props} value={navigateTo} />
}

export const NavigateToConsumer = props => (
  <NavigateToContext.Consumer {...props} />
)
