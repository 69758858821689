import React from "react"

import { SubMenuWrapper, SubMenuItem } from "./Styled"
import { SelectLocationConsumer } from "../../../context"
import { useSelectedLocation } from "../../../hooks"

export default ({ items, open, toggle, visible = true }) => {
  const selected_location_id = useSelectedLocation()?.id
  return (
    <SelectLocationConsumer>
      {changeSelectedLocation => (
        <>
          {visible && (items?.length ?? 0) > 0 && (
            <SubMenuWrapper open={open} length={items.length}>
              {items.map(store => (
                <SubMenuItem
                  selected={selected_location_id === store.id}
                  key={store.id}
                  onClick={() => {
                    if (selected_location_id === store.id) {
                      changeSelectedLocation(null)
                    } else {
                      changeSelectedLocation(store.id)
                    }
                    toggle()
                  }}
                >
                  {store.name}
                </SubMenuItem>
              ))}
            </SubMenuWrapper>
          )}
        </>
      )}
    </SelectLocationConsumer>
  )
}
