import { useQuery } from "@apollo/client"

import RulesTable from "./Table"
import FilterBar from "../FilterBar"
import { FilterBarControls } from "../Styled"
import { Relative, ErrorMessage, PageIntroduction } from "../../Shared"

import {
  RULE_TYPE_SHARE,
  RULE_TYPE_FLAT_RATE,
  ruleSearchActiveFilter
} from "../helpers"
import { useOrganization, useSelectedLocation } from "../../../hooks"
import { OBJECT_NAME } from "./helpers"
import { paths } from "../../../constants"
import { SelectLocationConsumer } from "../../../context"
import { DISTRIBUTION_RULE_SEARCH } from "../../../graphql/queries"
import useAuthorization from "../../../hooks/authorization"
import { rules_manage } from "../../../constants/permissions"

export default ({
  mock_rule,
  rule_filters,
  setRuleFilters,
  changePage,
  changePerPage,
  navigateTo,
  setRuleTemplateForCreate
}) => {
  const { hasPermission } = useAuthorization()
  const can_edit = hasPermission(rules_manage)
  const organization = useOrganization()
  const selected_location = useSelectedLocation()

  const { loading, data, error, refetch } = useQuery(DISTRIBUTION_RULE_SEARCH, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    skip: !!mock_rule,
    variables: {
      filters: {
        active: ruleSearchActiveFilter(rule_filters.status),
        types: [RULE_TYPE_SHARE, RULE_TYPE_FLAT_RATE],
        store_id: selected_location?.id ?? null,
        revenue_center_id: rule_filters.revenue_center?.id ?? null,
        organization_id: organization.id,
        sort_field: rule_filters.sort_field ?? null,
        sort_direction: rule_filters.sort_direction ?? null,
        source_names: rule_filters.source_names ?? [],
        distribute_from: rule_filters.distribute_from?.id ?? null,
        distribute_to: rule_filters.distribute_to?.id ?? null
      },
      page: rule_filters.page,
      first: rule_filters.per_page
    }
  })

  // override api result with mock payload when prop exists
  const rules = mock_rule ? [{ ...mock_rule }] : data?.ruleSearch?.data ?? []

  return (
    <SelectLocationConsumer>
      {changeSelectedLocation => (
        <Relative>
          {!!error && <ErrorMessage inline />}
          {!error && (
            <>
              <FilterBar
                loading={!!loading}
                filters={rule_filters}
                object_name={OBJECT_NAME}
                controls={
                  can_edit ? (
                    <FilterBarControls
                      popup={{
                        content: "Create a New Tip-Sharing Rule",
                        position: "top left",
                        delay: true
                      }}
                      onClick={() => navigateTo(paths.createDistributionRule)}
                    />
                  ) : null
                }
                setFilters={({ location = undefined, ...filters }) => {
                  const filter_location_id = location?.id
                    ? Number(location.id)
                    : null
                  if (
                    location !== undefined &&
                    filter_location_id !== selected_location?.id
                  ) {
                    changeSelectedLocation(filter_location_id)
                  }
                  setRuleFilters({ ...filters, page: 1 })
                }}
              />
              {(!loading || mock_rule) && (
                <RulesTable
                  rules={rules}
                  refetch={refetch}
                  navigateTo={navigateTo}
                  duplicateRule={rule => {
                    setRuleTemplateForCreate(rule)
                    navigateTo(paths.createDistributionRule)
                  }}
                  pagination={{
                    current_page: rule_filters.page,
                    per_page: rule_filters.per_page,
                    total: mock_rule
                      ? 1
                      : data?.ruleSearch?.paginatorInfo?.total ?? 0,
                    include_total: true,
                    changePage,
                    changePerPage
                  }}
                />
              )}
            </>
          )}
        </Relative>
      )}
    </SelectLocationConsumer>
  )
}
