import { useState, useEffect } from "react"

export * from "./queries"
export * from "./actions"
export * from "./selectors"

export const useStateObject = stateObj => {
  const [state, setStateObj] = useState(stateObj)

  const setState = newState => {
    setStateObj(prevState => {
      return { ...prevState, ...newState }
    })
  }

  return [state, setState]
}

// use debounce hook
// derived from this example: https://usehooks.com/useDebounce/
export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

// useState customized to work well with boolean values
export const useBooleanState = (default_value = false) => {
  const [bool, _setBool] = useState(default_value)
  const setOrToggleBool = value =>
    _setBool(value !== undefined ? !!value : !bool)
  return [bool, setOrToggleBool]
}
