import Styled from "styled-components"

import { FutureDatedLabel } from "../../../../Shared"
import { ReviewRow, LabelCell, ValueCell } from "../Styled"

import { ruleIsFutureDated } from "../../../helpers"
import { useBusinessDates } from "../../../../../hooks"
import { weekday_month_day_year_format } from "../../../../../constants"

export default Styled(({ className, effective_date }) => {
  const future_dated = ruleIsFutureDated({ effective_date })
  const { businessStartFromDate } = useBusinessDates()
  return (
    <ReviewRow className={className}>
      <LabelCell label="Effective" />
      <ValueCell>
        {!!future_dated && (
          <div className="pool-review-future-dated-label">
            <FutureDatedLabel fitted />
          </div>
        )}
        {businessStartFromDate(effective_date, false).format(
          weekday_month_day_year_format
        )}
      </ValueCell>
    </ReviewRow>
  )
})`
  & > td > div.pool-review-future-dated-label {
    line-height: 2rem;
  }
`
