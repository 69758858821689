import Styled from "styled-components"
import { Label, Icon } from "semantic-ui-react"

import { colors } from "../../../constants"

const Badge = Styled(
  ({ primary = false, compact, onRemove, children, ...props }) => (
    <Label {...props}>
      {children}
      {!!onRemove && <Icon name="delete" onClick={onRemove} />}
    </Label>
  )
)`${({ compact = false, primary = false }) => `
  &&& {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
    ${!compact ? "" : "padding: 0.4em;"}
    ${
      !primary
        ? ""
        : `
      background-color: ${colors.success};
      color: ${colors.white};
    `
    }
  }
`}`

export default Badge
