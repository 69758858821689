import { Route, Switch } from "react-router"
import { paths } from "../../constants"
import Maintenance from "../Maintenance"

const GlobalRoutes = props => (
  <Switch>
    <Route path={paths.maintenance} render={() => <Maintenance {...props} />} />
  </Switch>
)

export default GlobalRoutes
