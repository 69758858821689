import Styled from "styled-components"
import { rgba } from "polished"

import { List } from "../../Shared"

import { colors, style } from "../../../constants"

export const PanelWrapper = Styled.div`
  padding: 0.5rem;
  background-color: ${colors.primary};
  border-radius: ${style.border_radius};
`
export const PanelHeader = Styled.div`
  padding: 0.67rem;
  font-weight: 600;
  font-size: 1.33rem;
  text-align: center;
  margin-top: -0.5rem;
  /* text-transform: uppercase; */
  background-color: ${colors.primary};
  color: ${colors.white};
  & > i.icon {
    vertical-align: super;
  }
`
export const PanelSubheader = Styled.div`
  font-weight: 600;
  font-size: 1.2rem;
  color: ${colors.info};
`
export const PanelContent = Styled.div`
  padding: 1rem;
  font-size: 1rem;
  color: ${colors.primary};
  background-color: ${colors.white};
`
export const ProductDescription = Styled.div`
  padding: 1rem;
  text-align: center;
  position: relative;
  background-color: ${rgba(colors.info, 0.2)};
`
export const ProductHeadline = Styled.div`
  /* font-weight: 600; */
  font-size: 1.5rem;
  /* font-style: italic; */
  color: ${colors.primary};
`
export const ProductDetails = Styled.div`
  font-size: 0.9rem;
  padding: 0.5rem 4rem;
  color: ${colors.primary};
`
export const ProductServices = Styled(({ className, children }) => (
  <div className={className}>
    {children}
    <div className="eta-services-separator">OR</div>
  </div>
))`
  width: 100%;
  padding: 1rem 5rem 1.5rem;
  color: ${colors.primary};
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: auto auto auto;
  justify-items: center;
  & img {
    max-width: 80%;
    max-height: 35vh;
  }
  & > .eta-services-separator {
    font-size: 1.2rem;
    margin: auto;
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 3;
  }
`
const ProductServiceLogo = Styled(({ className, src }) => (
  <div className={className}>
    <div>
      <img src={src} />
    </div>
  </div>
))`
  grid-row-start: ${({ row }) => row};
  grid-column-start: ${({ column }) => column};
  & > div {
    padding-bottom: 0.5rem;
    & > img {
      max-height: 40px;
    }
  } 
`
export const HausmoneyLogo = props => (
  <ProductServiceLogo {...props} row={1} column={1} />
)
export const HausdirectLogo = props => (
  <ProductServiceLogo {...props} row={1} column={3} />
)
const ProductServiceImage = Styled(({ className, src }) => (
  <div className={className}>
    <img src={src} />
  </div>
))`
  grid-row-start: ${({ row }) => row};
  grid-column-start: ${({ column }) => column};
`
export const HausmoneyImage = props => (
  <ProductServiceImage {...props} row={2} column={1} />
)
export const HausdirectImage = props => (
  <ProductServiceImage {...props} row={2} column={3} />
)
const ProductServiceDescription = Styled(({ className, children }) => (
  <div className={className}>
    <div>{children}</div>
  </div>
))`
  font-size: 0.9rem;
  grid-row-start: ${({ row }) => row};
  grid-column-start: ${({ column }) => column};
  & > div {
    margin: 0 10%;
  }
`
export const HausmoneyDescription = props => (
  <ProductServiceDescription {...props} row={3} column={1} />
)
export const HausdirectDescription = props => (
  <ProductServiceDescription {...props} row={3} column={3} />
)
export const ProductSummary = Styled.div`
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 4rem 0.25rem;
`
export const ProductIncentives = ({ incentives = [], className }) => (
  <List horizontal className={className}>
    {incentives.map((incentive, key) => (
      <List.Item key={key}>
        <List.Content>
          <List.Icon name="plus" color={colors.green} />
          {incentive}
        </List.Content>
      </List.Item>
    ))}
  </List>
)
export const PanelActions = Styled.div`
  text-align: right;
  padding-top: 1rem;
  background-color: ${colors.white};
`
