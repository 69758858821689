import moment from "moment"

export const validateRequestTimes = (
  min_time = null,
  max_time = null,
  time_threshold
) => {
  if (min_time && max_time) {
    return (
      moment(min_time).isBefore(time_threshold) &&
      moment(min_time).isSameOrBefore(moment(max_time))
    )
  } else if (min_time && !max_time) {
    return moment(min_time).isBefore(time_threshold)
  } else if (!min_time && max_time) {
    return false
  }
  return true
}

export const filterAndSortQueryResults = (
  results = [],
  min_time = null,
  max_time = null
) => {
  let sales = []
  try {
    sales = [...results]
      .sort((a, b) =>
        moment(a.sale_time).isAfter(moment(b.sale_time)) ? 1 : -1
      )
      // filter with optional min and max time
      .filter(
        ({ sale_time }) =>
          (!min_time || moment(min_time).isSameOrBefore(moment(sale_time))) &&
          (!max_time || moment(max_time).isSameOrAfter(moment(sale_time)))
      )
  } catch {}

  return sales
}
