import { useState } from "react"

import moment from "moment"
import Styled from "styled-components"
import { Mutation } from "@apollo/client/react/components"

import Icon from "../Icon"
import Button from "../Button"
import Toggle from "../Toggle"
import DatePicker from "../DatePicker"
import { style } from "../../constants"
import { DataLockedModal } from "../Shared/ReportLockedIndicator"
import { Link, Table, Modal, Message, MultiSelect } from "../Shared"

import {
  useLocations,
  useBusinessDates,
  useSelectedLocation,
  useReportLockThreshold
} from "../../hooks/selectors"
import { toInt } from "../../helpers/number"
import { compatibleDateObject } from "../../helpers/datetime"
import { date_format, month_day_format, paths } from "../../constants"
import { scheduledJobsEnabled } from "../../helpers/settings/store"

import {
  REQUEST_REPROCESS_STORES,
  REQUEST_REPROCESS_STORES_WITH_DATES
} from "../../graphql/mutations"

const MAX_SYNC_DAYS_BACK = 21

const LOCATIONS_LIST_POSITION_IDX = 4

const SelectStore = Styled(({ ...props }) => (
  <MultiSelect
    {...props}
    closeOnChange
    placeholder="Select one or more locations to re-sync."
    noResultsMessage="All Locations Selected"
    selectOnBlur={false}
    selectOnNavigation={false}
    fluid
    search
    max_labels={5}
  />
))`
  margin-bottom: 0.2rem !important;
`
const Description = Styled(({ header, ...props }) => <div {...props} />)`
  font-weight: 300;
  ${({ header }) => (!header ? "" : "margin-bottom: 0.67rem;")}
`
const ModalSection = Styled.div`
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
`

const DetailToggle = Styled(({ ...props }) => <Toggle {...props} />)`
  font-family: ${style.font} !important;
  margin-bottom: 1rem !important;
  font-weight: 300;
  font-size: 1rem;
`

const successToast = toast =>
  toast({
    type: "success",
    title: "We're on it!",
    message: (
      <>
        <div>Your re-sync request is underway.</div>
        <div>It may take a few minutes for updates to appear in the dash.</div>
      </>
    )
  })
const errorToast = toast =>
  toast({
    type: "error",
    message: "An error occurred while processing your request."
  })

// export default
export default ({ toast, onClose }) => {
  const { businessDate } = useBusinessDates()
  const selected_location = useSelectedLocation()
  const reportLockThreshold = useReportLockThreshold()
  const locations = useLocations()

  const [selected_location_ids, setSelectedLocations] = useState(
    selected_location ? [selected_location.id] : []
  )
  const [show_threshold_detail, showThresholdDetail] = useState(false)

  // single date resync
  const [sync_date, setSyncDate] = useState(
    compatibleDateObject(businessDate())
  )

  // multi select dates resync
  const [sync_dates, setSyncDates] = useState([])

  // detailed multi date selection toggle
  const [detailed_date_selection, setDetailView] = useState(false)

  const global_min_resync = moment(businessDate()).subtract(
    MAX_SYNC_DAYS_BACK,
    "days"
  )

  // if selecting reprocess dates individually,
  // allow min date from store min process date (detailed view)
  const locationMinResync = id => {
    const location_threshold = reportLockThreshold(id)
    if (location_threshold) {
      return moment.max(
        businessDate(location_threshold, false),
        global_min_resync
      )
    }
    return global_min_resync
  }

  // Store dates helper
  const getStoreDates = sync_dates => {
    let store_dates = []
    selected_location_ids.forEach(id =>
      store_dates.push({
        store_id: toInt(id),
        date:
          moment(
            sync_dates.find(date => toInt(date.store_id) === toInt(id))?.date
          ).format(date_format) ?? moment(businessDate()).format(date_format)
      })
    )
    return store_dates
  }

  // if mass reprocess use most constrained min process date
  const selected_min_resync =
    selected_location_ids.length === 0
      ? sync_date
      : moment.max([...selected_location_ids.map(id => locationMinResync(id))])

  return (
    <Modal sticky size="tiny" onClose={onClose}>
      <Modal.Header onClose={onClose}>Request a Re-Sync</Modal.Header>
      <Modal.Content>
        {locations.length > 1 && (
          <>
            <ModalSection>
              <Description header>
                Select one or more locations to re-sync with your point-of-sale
                provider:
              </Description>
              <SelectStore
                options={locations
                  .filter(location => scheduledJobsEnabled(location))
                  // sort locations in dropdown
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(store => ({
                    value: store.id,
                    text: store.name
                  }))}
                value={selected_location_ids}
                can_select_all={true}
                onChange={(e, d) => {
                  const selected = [...d.value]
                  const updated_min_date =
                    selected.length === 0
                      ? null
                      : compatibleDateObject(
                          moment.max([
                            ...selected.map(id => locationMinResync(id))
                          ])
                        )

                  // set selected locations
                  setSelectedLocations(selected)
                  // update selected date if new min is more constrainted
                  if (
                    updated_min_date &&
                    moment(updated_min_date).isAfter(selected_min_resync)
                  ) {
                    setSyncDate(updated_min_date)
                  }
                }}
              />
            </ModalSection>
          </>
        )}
        {selected_location_ids.length > 0 && (
          <Description header>
            Specify how far back to sync sales and shifts:
          </Description>
        )}
        {selected_location_ids.length > 0 && !detailed_date_selection && (
          <ModalSection>
            <DatePicker
              button
              position="right"
              value={sync_date}
              force_close={!!show_threshold_detail}
              minDate={compatibleDateObject(selected_min_resync)}
              maxDate={compatibleDateObject(businessDate(null, false))}
              description={
                // show an info message if date selection is limited by closed pp.
                selected_min_resync.isAfter(global_min_resync) ? (
                  <>
                    Days preceding{" "}
                    {selected_min_resync.format(month_day_format)} are locked.{" "}
                    <Link onClick={() => showThresholdDetail(true)}>
                      Learn more
                    </Link>
                  </>
                ) : (
                  <>
                    Resync requests are limited to{" "}
                    {moment().startOf("day").diff(global_min_resync, "weeks")}{" "}
                    weeks.{" "}
                    <Link href={paths.contact} target="_blank">
                      Contact Support
                    </Link>{" "}
                    for more information.
                  </>
                )
              }
              onChange={e => setSyncDate(compatibleDateObject(moment(e)))}
            />
          </ModalSection>
        )}
        {selected_location_ids.length > 1 && (
          <DetailToggle
            label="Specify sync dates by location (optional)"
            checked={!!detailed_date_selection}
            onChange={() =>
              setDetailView(detailed_date_selection ? false : true)
            }
          />
        )}

        {selected_location_ids.length > 0 && !!detailed_date_selection && (
          <Table compact basic="very">
            <Table.Body>
              {[...selected_location_ids].map((id, idx) => (
                <Table.Row key={idx}>
                  <Table.Cell>
                    {
                      locations.find(store => toInt(store.id) === toInt(id))
                        .name
                    }
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <DatePicker
                      button
                      fitted
                      compact
                      position={
                        idx > LOCATIONS_LIST_POSITION_IDX
                          ? "top left"
                          : "bottom left"
                      }
                      value={
                        // get the sync date value for the current selected location id
                        sync_dates.find(
                          date => toInt(date.store_id) === toInt(id)
                        )?.date
                      }
                      force_close={!!show_threshold_detail}
                      minDate={compatibleDateObject(locationMinResync(id))}
                      maxDate={compatibleDateObject(businessDate(null, false))}
                      description={
                        // show an info message if date selection is limited by closed pp.
                        locationMinResync(id).isAfter(global_min_resync) ? (
                          <>
                            Days preceding{" "}
                            {locationMinResync(id).format(month_day_format)} are
                            locked.{" "}
                            <Link onClick={() => showThresholdDetail(true)}>
                              Learn more
                            </Link>
                            .
                          </>
                        ) : (
                          <>
                            Resync requests are limited to{" "}
                            {moment()
                              .startOf("day")
                              .diff(global_min_resync, "weeks")}{" "}
                            weeks.{" "}
                            <Link href={paths.contact} target="_blank">
                              Contact Support
                            </Link>{" "}
                            for more information.
                          </>
                        )
                      }
                      onChange={e =>
                        // refresh state with updated sync date object
                        setSyncDates(state => [
                          ...state.filter(
                            item => toInt(item.store_id) !== toInt(id)
                          ),
                          {
                            store_id: id,
                            date: moment(compatibleDateObject(e)).format(
                              date_format
                            )
                          }
                        ])
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </Modal.Content>
      {/* Use single date reprocess mutation when not using detailed date selection */}
      {!detailed_date_selection && (
        <Modal.Actions>
          <Button onClick={onClose}>Cancel</Button>
          <Mutation
            mutation={REQUEST_REPROCESS_STORES}
            onCompleted={({ reprocessStores }) => {
              const { success } = reprocessStores
              if (success) {
                successToast(toast)
              } else {
                errorToast(toast)
              }
              onClose()
            }}
            onError={() => {
              errorToast(toast)
              onClose()
            }}
          >
            {(reprocessStores, { loading }) => (
              <Button
                icon
                labelPosition="right"
                primary
                disabled={selected_location_ids.length < 1 || !!loading}
                loading={!!loading}
                onClick={() => {
                  reprocessStores({
                    variables: {
                      store_ids: [
                        ...selected_location_ids.map(id => toInt(id))
                      ],
                      date: moment(sync_date).format(date_format),
                      sync_static: true,
                      sync_dynamic: true
                    }
                  })
                }}
              >
                Submit <Icon name="check" />
              </Button>
            )}
          </Mutation>
        </Modal.Actions>
      )}
      {/* When using detailed date selection use multi store/date mutation */}
      {!!detailed_date_selection && (
        <Modal.Actions>
          <Button onClick={onClose}>Cancel</Button>
          <Mutation
            mutation={REQUEST_REPROCESS_STORES_WITH_DATES}
            onCompleted={({ reprocessStoresWithDates }) => {
              const { success } = reprocessStoresWithDates
              if (success) {
                successToast(toast)
              } else {
                errorToast(toast)
              }
              onClose()
            }}
            onError={() => {
              errorToast(toast)
              onClose()
            }}
          >
            {(reprocessStoresWithDates, { loading }) => (
              <Button
                icon
                labelPosition="right"
                primary
                disabled={selected_location_ids.length < 1 || !!loading}
                loading={!!loading}
                onClick={() => {
                  reprocessStoresWithDates({
                    variables: {
                      store_dates: getStoreDates(sync_dates),
                      sync_static: true,
                      sync_dynamic: true
                    }
                  })
                }}
              >
                Submit <Icon name="check" />
              </Button>
            )}
          </Mutation>
        </Modal.Actions>
      )}
      {!!show_threshold_detail && (
        <DataLockedModal
          lock_threshold={selected_min_resync}
          onClose={() => showThresholdDetail(false)}
        />
      )}
    </Modal>
  )
}
