import Styled from "styled-components"

import Icon from "../Icon"
import InfoMessage from "./Info"
import ErrorMessage from "./Error"
import ValidationErrors from "./ValidationErrors"

import { colors, style } from "../../../constants"

export {
  InfoMessage,
  ErrorMessage,
  ValidationErrors as ValidationErrorsMessage
}

const { border_radius } = style

const TYPE_INFO = "info"
const TYPE_CLEAN = "clean"
const TYPE_WARN = "warning"
const TYPE_DANGER = "danger"
const TYPE_SUCCESS = "success"

const parseMessageType = type => {
  switch (type) {
    case TYPE_DANGER:
    case "error":
      return TYPE_DANGER
    case TYPE_WARN:
    case "warn":
      return TYPE_WARN
    case TYPE_INFO:
      return TYPE_INFO
    case TYPE_CLEAN:
      return TYPE_CLEAN
    case TYPE_SUCCESS:
    default:
      return TYPE_SUCCESS
  }
}

const MessageWrapper = Styled.div`${({
  big,
  type,
  basic,
  strong,
  raised,
  inline,
  centered,
  dismissable,
  margin_top,
  margin_bottom
}) => {
  let background_color = colors.white
  let border_color = colors.transparent
  switch (type) {
    case TYPE_WARN:
      border_color = colors.warning
      break
    case TYPE_DANGER:
      border_color = colors.danger
      break
    case TYPE_SUCCESS:
      border_color = colors.success
      break
    case TYPE_CLEAN:
      border_color = colors.table_border
      break
    case TYPE_INFO:
    default:
      background_color = colors.light
      border_color = colors.table_border
  }
  return `
    padding: 1rem;
    position: relative;
    color: ${colors.dark};
    border-radius: ${border_radius};
    font-weight: ${!!strong ? 400 : 300};
    background-color: ${background_color};
    font-size: ${!!big ? `1.2rem` : `1rem`};
    ${!centered ? `` : `text-align: center;`}
    ${!!dismissable ? `padding-right: 3rem;` : ``}
    display: ${!!inline ? `inline-block` : `block`};
    ${!basic ? `border: 0.5px solid ${border_color};` : ""}
    ${!!raised ? `box-shadow: 0 1px 2px ${colors.light6};` : ``}
    ${!!margin_top ? `margin-top: ${margin_top};` : ``}
    ${!!margin_bottom ? `margin-bottom: ${margin_bottom};` : ``}
`
}}`

const closeIconColor = type => {
  let parsed_type = parseMessageType(type)
  switch (parsed_type) {
    case TYPE_SUCCESS:
      return colors.success
    case TYPE_DANGER:
      return colors.danger
    default:
      return colors.dark
  }
}

const CloseIcon = Styled(({ type, ...props }) => (
  <Icon
    {...props}
    hover
    fitted
    cursor="pointer"
    color={closeIconColor(type)}
    name="close"
  />
))`
  height: 2rem !important;
  top: 0.6rem !important;
  right: 0.8rem !important;
  padding: 0.33rem !important;
  font-size: 1.1rem !important;
  position: absolute !important;
`
const HeaderWrapper = Styled.div`
  font-weight: 400;
  font-size: 1.15rem;
  margin-bottom: 0.33rem;
  font-size: ${({ big }) => (!!big ? `1.3rem` : `1.15rem`)};
  ${({ dismissable }) => (!!dismissable ? `padding-right: 3rem;` : ``)}
`
const ContentWrapper = Styled.div``

const Message = ({
  title,
  children,
  type,
  big = false,
  basic = false,
  strong = false,
  raised = false,
  message = null,
  inline = false,
  hidden = false,
  onDismiss = null,
  centered = false,
  margin_top = false,
  margin_bottom = false,
  ...props
}) =>
  !!hidden ? (
    <></>
  ) : (
    <MessageWrapper
      {...props}
      big={big}
      basic={basic}
      strong={strong}
      raised={raised}
      inline={inline}
      centered={centered}
      dismissable={!!onDismiss}
      type={parseMessageType(type)}
      margin_top={margin_top}
      margin_bottom={margin_bottom}
    >
      {!!onDismiss && <CloseIcon onClick={onDismiss} type={type} />}
      {!!title && <HeaderWrapper big={big}>{title}</HeaderWrapper>}
      <ContentWrapper>{!!message ? message : children}</ContentWrapper>
    </MessageWrapper>
  )
Message.Header = props => <HeaderWrapper {...props} />
Message.Content = props => <ContentWrapper {...props} />

export default Message
