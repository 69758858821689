import { apollo_client } from "../helpers/api"
import { SELF } from "../graphql/queries"

//centralize action exports

export * from "./modal"
export * from "./authenticate"
export * from "./application-filters"

// index actions

export const CHANGE_SELECTED_LOCATION = "CHANGE_SELECTED_LOCATION"
export const changeSelectedLocation = id => ({
  type: CHANGE_SELECTED_LOCATION,
  payload: id
})

export const APP_ERROR = "APP_ERROR_STATE"
export const triggerAppError = payload => ({
  type: APP_ERROR,
  payload: { ...payload }
})

export const INIT_NIGHT_MODE = "INIT_NIGHT_MODE"
export const initNightMode = () => ({ type: INIT_NIGHT_MODE })

export const TOGGLE_NIGHT_MODE = "TOGGLE_NIGHT_MODE"
export const toggleNightMode = () => ({ type: TOGGLE_NIGHT_MODE })

export const CREATE_TOAST = "CREATE_TOAST"
export const toast = ({ ...config }) => ({
  type: CREATE_TOAST,
  payload: config
})
export const CLEAR_TOAST = "CLEAR_TOAST"
export const clearToast = id => ({
  type: CLEAR_TOAST,
  payload: id
})
export const MOUNT_TOAST = "MOUNT_TOAST"
export const mountToast = id => ({
  type: MOUNT_TOAST,
  payload: id
})
export const UNMOUNT_TOAST = "UNMOUNT_TOAST"
export const unmountToast = id => ({
  type: UNMOUNT_TOAST,
  payload: id
})

export const SET_USER = "SET_USER"
export const setUser = (name, email) => ({
  type: SET_USER,
  payload: { name, email }
})

export const SYNCING_USER = "SYNCING_USER"
export const SYNC_USER = "SYNC_USER"
export const syncUser = (
  onSuccess = null,
  onError = null,
  custom_apollo_client = null
) => dispatch => {
  const client = custom_apollo_client || apollo_client
  dispatch({ type: SYNCING_USER, payload: true })

  client
    .query({
      query: SELF,
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    })
    .then(({ data }) => {
      if (data && data.self && data.self.active) {
        let { self } = data
        // set user state
        dispatch({
          type: SYNC_USER,
          payload: { ...self }
        })

        // Invoke onSuccess
        !!onSuccess && typeof onSuccess === "function" && onSuccess({ ...self })
      } else {
        dispatch({ type: SYNCING_USER, payload: false })
        dispatch({
          type: APP_ERROR,
          payload: { error_msg: "Failed to parse user." }
        })
      }
    })
    .catch(error => {
      // Invoke onError
      dispatch({ type: SYNCING_USER, payload: false })
      !!onError && typeof onError === "function" && onError(error)
    })
}

export const SET_ROUTES_VISITED = "SET_ROUTES_VISITED"
export const setRoutesVisited = payload => ({
  type: SET_ROUTES_VISITED,
  payload
})

export const SET_RULE_TEMPLATE_FOR_CREATE = "SET_RULE_TEMPLATE_FOR_CREATE"
export const setRuleTemplateForCreate = payload => ({
  type: SET_RULE_TEMPLATE_FOR_CREATE,
  payload
})
