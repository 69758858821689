import moment from "moment"
import Styled from "styled-components"

import { LinkToReporting } from "../../Report"
import { Link, Modal, Table } from "../../Shared"

import { useSelectedLocation } from "../../../hooks"
import { paths, project_info } from "../../../constants"
import useAuthorization from "../../../hooks/authorization"
import { reporting_manage_sale } from "../../../constants/permissions"

const SummaryTable = Styled(({ className, ...props }) => (
  <div className={className}>
    <Table {...props} />
  </div>
))`
  max-height: 50vh;
  overflow-y: auto;
  margin: 1.5rem 0;
`

const SummaryModal = props => {
  const selected_location = useSelectedLocation()
  const { hasPermission } = useAuthorization()
  const { sales, onClose } = props
  const first_day = moment(sales[0].sale_time).format("MMMM Do")
  const last_day = moment(sales[sales.length - 1].sale_time).format("MMMM Do")

  return (
    <Modal size="small" onClose={onClose}>
      <Modal.Header onClose={onClose}>Excessive Tips</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {sales.length} sale{sales.length > 1 && `s`}{" "}
          {sales.length === 1 ? "was" : "were"} found with excessive tips{" "}
          {first_day === last_day && <>on {first_day}</>}
          {first_day !== last_day && (
            <>
              between {first_day} and {last_day}
            </>
          )}
          .
        </Modal.Description>
        <SummaryTable compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Employee</Table.HeaderCell>
              <Table.HeaderCell>Job Code</Table.HeaderCell>
              {!selected_location && (
                <Table.HeaderCell>Location</Table.HeaderCell>
              )}
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Tip%</Table.HeaderCell>
              <Table.HeaderCell>Tip Amount</Table.HeaderCell>
              <Table.HeaderCell collapsing />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {[...sales].reverse().map(sale => (
              <Table.Row key={sale.id}>
                <Table.Cell>
                  {sale.employee?.first_name} {sale.employee?.last_name}
                </Table.Cell>
                <Table.Cell>{sale.workedShift?.jobCode?.name}</Table.Cell>
                {!selected_location && (
                  <Table.Cell>{sale.store.name}</Table.Cell>
                )}
                <Table.Cell>
                  {moment(sale.sale_time).format("ddd, M/D/YY [at] h:mma")}
                </Table.Cell>
                <Table.Cell>
                  {(() => {
                    let tips =
                      (sale.total_cc_tips +
                        sale.total_cash_tips +
                        sale.total_gc_tips) /
                      sale.total_sales

                    return tips.toLocaleString("en-US", {
                      style: "percent",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    })
                  })()}
                </Table.Cell>
                <Table.Cell>
                  {(
                    sale.total_cc_tips +
                    sale.total_cash_tips +
                    sale.total_gc_tips
                  ).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD"
                  })}
                </Table.Cell>
                <Table.Cell collapsing>
                  <LinkToReporting excessive_tip={sale} onClick={onClose} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </SummaryTable>
        <Modal.Description>
          Incorrect tip inputs will impact tip distribution calculations. To
          ensure calculations are correct, review sales with excessive tip
          inputs.
        </Modal.Description>
        {hasPermission(reporting_manage_sale) && (
          <Modal.Description>
            You can visit the{" "}
            <Link target="_blank" href={paths.reportSales}>
              Sales Tab
            </Link>{" "}
            in reporting to review tip inputs.
          </Modal.Description>
        )}
      </Modal.Content>
    </Modal>
  )
}

export default SummaryModal
