import React from "react"

import { Route, Switch, Redirect } from "react-router"

import Rule from "../Rule"
import Login from "../Login"
import Report from "../Report"
import Profile from "../Profile"
import Contact from "../Contact"
import Landing from "../Landing"
import Settings from "../Settings"
import Breakdown from "../Breakdown"
import FourOhFour from "../FourOhFour"
import EditRule from "../Rule/TipShare/Edit"
import CreateRule from "../Rule/TipShare/Create"
import EarnedTipAccess from "../EarnedTipAccess"

import { paths } from "../../constants"

export default props => (
  <Switch>
    <Route
      exact
      path={paths.index}
      render={routeProps => <Landing {...props} {...routeProps} />}
    />
    <Route
      exact
      path={paths.login}
      render={routeProps => <Login {...props} {...routeProps} />}
    />
    <Route
      exact
      path={paths.breakdown}
      render={routeProps => <Breakdown {...props} {...routeProps} />}
    />
    <Route
      exact
      path={paths.profile}
      render={routeProps => <Profile {...props} {...routeProps} />}
    />
    <Route
      exact
      path={paths.createDistributionRule}
      render={routeProps => <CreateRule {...props} {...routeProps} />}
    />
    <Route
      exact
      path={paths.editDistributionRule}
      render={routeProps => <EditRule {...props} {...routeProps} />}
    />
    <Route
      path={paths.distributionRules}
      render={routeProps => <Rule {...props} {...routeProps} />}
    />
    <Route
      path={paths.report}
      render={routeProps => <Report {...props} {...routeProps} />}
    />
    <Route
      path={paths.earnedTipAccess}
      render={routeProps => <EarnedTipAccess {...props} {...routeProps} />}
    />
    <Route
      path={paths.settings}
      render={routeProps => <Settings {...props} {...routeProps} />}
    />
    <Route
      path={paths.contact}
      render={routeProps => <Contact {...props} {...routeProps} />}
    />
    <Route
      exact
      path={paths.pageNotFound}
      render={routeProps => <FourOhFour {...props} {...routeProps} />}
    />
    <Redirect to={paths.pageNotFound} />
  </Switch>
)
