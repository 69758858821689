import {
  p2p_enabled_val,
  p2p_settings_key,
  cc_tip_fee_settings_key,
  pay_period_auto_finalize_settings_key,
  report_exports_settings_key,
  report_exports_enabled_val
} from "../../constants"

// Organization Helpers

export const p2pEnabled = org_settings => {
  let p2p_enabled = false
  try {
    p2p_enabled = org_settings[p2p_settings_key] === p2p_enabled_val
  } catch {}
  return p2p_enabled
}

export const ccTipFeeSetting = settings => {
  try {
    if (Array.isArray(settings)) {
      return parseFloat(
        settings.filter(({ key }) => key === cc_tip_fee_settings_key)[0].value
      )
    }
    return parseFloat(settings[cc_tip_fee_settings_key])
  } catch {}
  return 0
}

export const autoFinalizePayPeriodSetting = settings => {
  try {
    if (Array.isArray(settings)) {
      return Number(
        settings.filter(
          ({ key }) => key === pay_period_auto_finalize_settings_key
        )[0].value
      )
    }
    return Number(settings[pay_period_auto_finalize_settings_key])
  } catch {}
  return 0
}

export const reportExportsEnabled = org_settings => {
  let report_exports = false
  try {
    report_exports =
      org_settings[report_exports_settings_key] === report_exports_enabled_val
  } catch {}
  return report_exports
}
