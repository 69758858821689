import { useState } from "react"

import moment from "moment"
import Styled from "styled-components"

import RecurringShiftSummary from "./Summary"
import Icon, { RemoveIcon } from "../../../../Icon"
import JobCodeSearch from "../../../../Search/JobCode"
import EmployeeSearch from "../../../../Search/Employee"
import { SubmitButton, IconButton } from "../../../../Button"
import {
  Label,
  Modal,
  Message,
  Checkbox,
  TimeInput,
  DatePicker,
  cssTextSelectionDisabled
} from "../../../../Shared"

import { MERIDIEM_PM, timeValueToFloat } from "../../../../Shared/Input/Time"
import {
  useBusinessDates,
  useSelectedLocation,
  useReportLockThreshold
} from "../../../../../hooks"
import {
  round,
  toInt,
  ucfirst,
  getNextDay,
  compatibleDateObject
} from "../../../../../helpers"
import {
  style,
  colors,
  weekdays,
  date_format,
  month_day_format
} from "../../../../../constants"

const START_HOUR_DEFAULT = 12
const START_MIN_DEFAULT = 0
const START_MERIDIEM_DEFAULT = MERIDIEM_PM
const END_HOUR_DEFAULT = 1
const END_MIN_DEFAULT = 0
const END_MERIDIEM_DEFAULT = MERIDIEM_PM

const defaultScheduleDays = () =>
  [...weekdays].reduce((acc, day) => ({ ...acc, [day]: true }), {})

export default ({
  recurring = false,
  onClose = () => {},
  onSubmit = () => {}
}) => {
  const selected_location = useSelectedLocation()
  const { business_start_hour, businessDate } = useBusinessDates()

  const [show_shift_review, _setShowShiftReview] = useState(false)
  const onShowReview = () => _setShowShiftReview(true)
  const onShowEditor = () => _setShowShiftReview(false)

  const [shift, _setShift] = useState({
    submitted: false,
    location_id: selected_location?.id,
    job_code: null,
    employee: null,
    effective_date: businessDate(),
    start_time: {
      hour: START_HOUR_DEFAULT,
      minute: START_MIN_DEFAULT,
      meridiem: START_MERIDIEM_DEFAULT
    },
    end_time: {
      hour: END_HOUR_DEFAULT,
      minute: END_MIN_DEFAULT,
      meridiem: END_MERIDIEM_DEFAULT
    },
    schedule_days: defaultScheduleDays()
  })

  return (
    <>
      {!show_shift_review && (
        <RecurringShiftEditor
          shift={shift}
          setShift={modified_shift =>
            _setShift({ ...shift, ...modified_shift })
          }
          recurring={recurring}
          selected_location={selected_location}
          businessDate={businessDate}
          business_start_hour={business_start_hour}
          onClose={onClose}
          onShowReview={onShowReview}
        />
      )}
      {!!show_shift_review && (
        <RecurringShiftSummary
          recurring_shift={{ ...shift, recurring }}
          onClose={onClose}
          header={<ShiftHeader recurring={recurring} />}
          actions={
            <>
              <IconButton
                basic
                icon="arrow left"
                labelPosition="left"
                onClick={onShowEditor}
                disabled={shift.submitted}
              >
                Back
              </IconButton>
              <SubmitButton
                loading={shift.submitted}
                disabled={shift.submitted}
                onClick={() => {
                  _setShift({ ...shift, submitted: true })
                  const input = {
                    effective_date: moment(shift.effective_date).format(
                      date_format
                    ),
                    deactivated_date: !recurring
                      ? moment(shift.effective_date)
                          .add(1, "d")
                          .format(date_format)
                      : null,
                    employee_id: toInt(shift.employee.id),
                    job_code_id: toInt(
                      shift.employee?.assignedJobCode?.id ?? shift.job_code?.id
                    ),
                    schedules: Object.keys(shift.schedule_days)
                      .filter(day => !!shift.schedule_days[day])
                      .map(day => {
                        const start_hour = timeValueToFloat(shift.start_time)
                        const end_hour = timeValueToFloat(shift.end_time)
                        return {
                          day_start:
                            start_hour < business_start_hour
                              ? getNextDay(day)
                              : day,
                          hour_start: Math.floor(start_hour),
                          minute_start: shift.start_time.minute,
                          day_end:
                            end_hour < business_start_hour
                              ? getNextDay(day)
                              : day,
                          hour_end: Math.floor(end_hour),
                          minute_end: shift.end_time.minute
                        }
                      })
                  }
                  return onSubmit(input)
                }}
              />
            </>
          }
        />
      )}
    </>
  )
}

const editorInputErrors = (
  { job_code, employee, effective_date, start_time, end_time, schedule_days },
  recurring,
  lock_threshold,
  business_start_hour
) => {
  let errors = []
  const selected_days = Object.keys(schedule_days).filter(
    day => !!schedule_days[day]
  )
  if (!employee?.id ?? false) {
    errors.push("Missing Employee input.")
  }
  if (employee && !(employee?.assignedJobCode?.id ?? job_code?.id ?? false)) {
    errors.push("Missing Job Code input.")
  }
  if (
    !effective_date ||
    (lock_threshold &&
      moment(effective_date).isBefore(lock_threshold.startOf("day")))
  ) {
    errors.push(`Invalid ${recurring ? "effective" : "business"} date.`)
  }

  // validate start and end time
  if (!isValidShiftTime(start_time, end_time, business_start_hour)) {
    errors.push(`Invalid Start Time and End Time values.`)
  }

  // recurring specific validation
  if (recurring) {
    // make sure at least one day is selected
    if (!selected_days.length) {
      errors.push(`At least one day must be selected for scheduling.`)
    }
  }

  return errors
}

const isValidShiftTime = (start_time, end_time, business_start_hour) => {
  const shift_start_hour = timeValueToFloat(start_time)
  const shift_end_hour = timeValueToFloat(end_time)

  if (shift_start_hour >= business_start_hour) {
    // shift start is before midnight.
    // therefore, shift end must be greater than shift start (same day)
    // OR less than business start (next day).
    return (
      shift_end_hour > shift_start_hour || shift_end_hour < business_start_hour
    )
  } else {
    // shift start is after midnight.
    // therefore, shift end must be greater than shift start
    // AND less than business start.
    return (
      shift_end_hour > shift_start_hour && shift_end_hour < business_start_hour
    )
  }
}

const RecurringShiftEditor = ({
  shift,
  setShift,
  recurring,
  selected_location,
  businessDate,
  business_start_hour,
  onClose,
  onShowReview
}) => {
  const reportLockThreshold = useReportLockThreshold()
  const [show_errors, setShowErrors] = useState(false)

  const {
    location_id = selected_location?.id ?? null,
    job_code,
    employee,
    effective_date,
    start_time,
    end_time,
    schedule_days
  } = shift

  const lock_threshold = reportLockThreshold(location_id)

  const errors = editorInputErrors(
    shift,
    recurring,
    lock_threshold,
    business_start_hour
  )

  return (
    <Modal sticky size="small" onClose={onClose}>
      <EditorModalHeader recurring={recurring} />
      <Modal.Content>
        {!!show_errors && errors.length > 0 && (
          <Message
            basic
            type="danger"
            onDismiss={() => setShowErrors(false)}
            margin_bottom="1rem"
          >
            {errors.map((e, i) => (
              <div key={i}>{e}</div>
            ))}
          </Message>
        )}
        {!!recurring && (
          <>
            <WeekdayInputDescription />
            <InputSection>
              <WeekdayInput
                value={schedule_days}
                onChange={schedule_days => setShift({ schedule_days })}
              />
            </InputSection>
          </>
        )}
        <ShiftInputDescription recurring={recurring} />
        <InputSection
          columns={!!employee ? 2 : 1}
          width={(!!employee ? 2 : 1) / 3}
        >
          <EmployeeInput
            employee={employee}
            location_id={location_id}
            setEmployee={({ employee }) => {
              const changed_location = employee.store.id !== shift.location_id
              return setShift({
                employee,
                location_id: employee.store.id,
                job_code: changed_location ? null : shift.job_code
              })
            }}
            clearEmployee={() =>
              setShift({
                employee: null,
                job_code: null,
                location_id: selected_location?.id ?? null
              })
            }
          />
          {!!employee && (
            <>
              {!employee.assignedJobCode && (
                <JobCodeInput
                  job_code={job_code}
                  location_id={location_id}
                  setJobCode={({ job_code }) =>
                    setShift({
                      job_code
                    })
                  }
                  clearJobCode={() =>
                    setShift({
                      job_code: null
                    })
                  }
                />
              )}
              {!!employee.assignedJobCode && (
                <JobCodeInput
                  job_code={employee.assignedJobCode}
                  location_id={location_id}
                />
              )}
            </>
          )}
        </InputSection>
        <TimeInputDescription recurring={recurring} />
        <InputSection columns={3}>
          <ShiftStartInput
            value={start_time}
            onChange={start_time => setShift({ start_time })}
          />
          <ShiftEndInput
            value={end_time}
            onChange={end_time => setShift({ end_time })}
          />
          <EffectiveDateInput
            label={!!recurring ? "Effective On" : "Business Date"}
            effective_date={effective_date}
            min_date={lock_threshold}
            today_date={businessDate()}
            setEffectiveDate={effective_date => setShift({ effective_date })}
          />
        </InputSection>
      </Modal.Content>
      <Modal.Actions>
        <SubmitButton
          icon="arrow right"
          label="Review and Submit"
          onClick={() => {
            if (errors.length) {
              setShowErrors(true)
            } else {
              onShowReview()
            }
          }}
        />
      </Modal.Actions>
    </Modal>
  )
}

const EditorModalHeader = ({ recurring }) => (
  <Modal.Header>
    <ShiftHeader recurring={recurring} />
  </Modal.Header>
)
const ShiftHeader = ({ recurring }) =>
  !!recurring ? <RecurringShiftHeader /> : <CustomShiftHeader />
const ShiftInputDescription = ({ recurring }) =>
  !!recurring ? <RecurringInputDescription /> : <CustomInputDescription />
const TimeInputDescription = ({ recurring }) =>
  !!recurring ? (
    <RecurringTimeInputDescription />
  ) : (
    <CustomTimeInputDescription />
  )

const RecurringShiftHeader = () => <>Schedule a Recurring Shift</>
const RecurringInputDescription = () => (
  <Modal.Description>
    Select an employee to associate with each shift:
  </Modal.Description>
)
const RecurringTimeInputDescription = () => (
  <Modal.Description>
    Specify the start and end time to be used with each shift:
  </Modal.Description>
)
const WeekdayInputDescription = () => (
  <Modal.Description>
    Select which days a shift should be created:
  </Modal.Description>
)
const CustomShiftHeader = () => <>Create a Custom Shift</>
const CustomInputDescription = () => (
  <Modal.Description>
    Select an employee to associate with this shift:
  </Modal.Description>
)
const CustomTimeInputDescription = () => (
  <Modal.Description>
    Specify the clock-in and clock-out times to be used with each shift:
  </Modal.Description>
)

const InputSection = Styled(({ className, children }) => (
  <div className={className}>
    <div>
      <div>{children}</div>
    </div>
  </div>
))`${({ columns = 1, align = "left", width = 1 }) => `
  display: block;
  position: relative;
  margin-bottom: 2rem;
  &:last-child {
    margin-bottom: 0;
  }

  & > div {
    width: ${round(100 * width, 5)}%;

    & > div {
      text-align: ${align};
      display: grid;
      grid-column-gap: 1px;
      grid-template-columns: repeat(${columns}, minmax(0, 1fr));

      & > div {
        padding: 0 1rem;
        background-color: ${colors.white};

        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
`}`

const FilterValue = Styled(
  ({ value, qualifier = null, onRemove, className }) => (
    <div className={className}>
      <div onClick={onRemove}>
        {value}
        {!!qualifier && <span>{qualifier}</span>}
        {!!onRemove && (
          <>
            &nbsp;&nbsp;
            <RemoveIcon />
          </>
        )}
      </div>
    </div>
  )
)`
  line-height: 2.75rem;
  & > div {
    cursor: ${({ onRemove }) => (!!onRemove ? "pointer" : "default")};
    line-height: 2rem;
    font-weight: bold;
    display: inline-block;
    padding: 0.16rem 0.67rem;
    background-color: ${colors.light};
    border-radius: ${style.border_radius};
    border: 1px solid ${colors.table_border};

    & > span {
      font-weight: 300;
      margin-left: 0.33rem;
    }
  }
`

const JobCodeInput = ({ job_code, location_id, setJobCode, clearJobCode }) => (
  <div>
    <Label>Job Code</Label>
    {!job_code && (
      <JobCodeSearch fluid store_id={location_id} onChange={setJobCode} />
    )}
    {!!job_code && (
      <FilterValue
        value={job_code.name}
        qualifier={!clearJobCode ? "Assigned" : null}
        onRemove={clearJobCode}
      />
    )}
  </div>
)

const EmployeeInput = ({
  employee,
  location_id,
  setEmployee,
  clearEmployee
}) => (
  <div>
    <Label>Employee</Label>
    {!employee && (
      <EmployeeSearch fluid store_id={location_id} onChange={setEmployee} />
    )}
    {!!employee && (
      <FilterValue value={employee.name} onRemove={clearEmployee} />
    )}
  </div>
)

const EffectiveDateInput = ({
  label,
  effective_date,
  min_date,
  today_date,
  setEffectiveDate
}) => (
  <div>
    <Label>{label}</Label>
    <DatePicker
      button
      position="bottom left"
      value={compatibleDateObject(effective_date)}
      minDate={compatibleDateObject(min_date)}
      maxDate={compatibleDateObject(today_date)}
      description={
        <>Days before {min_date.format(month_day_format)} are locked.</>
      }
      onChange={e => setEffectiveDate(moment(e))}
    />
  </div>
)

const ShiftStartInput = ({ value, label = "Start Time", onChange }) => (
  <div>
    <Label>{label}</Label>
    <TimeInput value={value} onChange={onChange} />
  </div>
)

const ShiftEndInput = ({ value, label = "End Time", onChange }) => (
  <div>
    <Label>{label}</Label>
    <TimeInput value={value} onChange={onChange} />
  </div>
)

const WeekdayInput = Styled(
  ({ className, value, label = "Schedule Days", onChange }) => {
    const [select_all, setSelectAll] = useState(
      Object.keys(value).filter(day => !!value[day]).length === weekdays.length
    )
    return (
      <div className={className}>
        <Label>{label}</Label>
        <Checkbox
          checked={select_all}
          onChange={checked => {
            setSelectAll(checked)
            onChange(defaultScheduleDays())
          }}
          label="Create a shift every day of the week."
        />
        {!select_all && <WeekdaySelect value={value} onChange={onChange} />}
      </div>
    )
  }
)`
  & > div.checkbox {
    margin-bottom: 0.67rem;
  }
`

const WeekdaySelect = cssTextSelectionDisabled(Styled(
  ({ className, value, onChange }) => (
    <div className={className}>
      {[...weekdays].map(day => {
        const selected = value[day] ?? false
        return (
          <div
            key={day}
            className={`recurring-shift-select-day${
              !selected ? " weekday-disabled" : ""
            }`}
            onClick={() => onChange({ ...value, [day]: !selected })}
          >
            <div>{ucfirst(day)}</div>
            <div>
              {!!selected && (
                <Icon
                  size="big"
                  color={colors.green}
                  name="check circle outline"
                />
              )}
              {!selected && (
                <Icon
                  color={colors.danger}
                  size="big"
                  name="times circle outline"
                />
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
)`
  display: grid;
  grid-column-gap: 1px;
  grid-template-columns: repeat(7, 1fr);

  padding: 1px;
  border-radius: ${style.border_radius};
  background-color: ${colors.table_border};

  & > div.recurring-shift-select-day {
    display: block;
    cursor: pointer;
    overflow: hidden;
    text-align: center;
    background-color: ${colors.white};

    &.weekday-disabled {
      background-color: ${colors.light2};
    }

    &:first-child {
      border-radius: ${style.border_radius} 0 0 ${style.border_radius};
    }
    &:last-child {
      border-radius: 0 ${style.border_radius} ${style.border_radius} 0;
    }

    & > div:first-child {
      font-weight: bold;
      font-size: 0.95rem;
      padding: 0.5em 0.1em;
    }

    & > div:last-child {
      padding: 0.33em 0 1em;
    }
  }
`)
