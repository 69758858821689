import { useState } from "react"
import { SubTabs } from "../../Tabs"
import Users from "./Users/"
import Roles from "./Roles/"
import { parseSearchQuery } from "../../../helpers"

const TAB_USERS = "users"
const TAB_ROLES = "roles"

export default props => {
  const { view = null } = parseSearchQuery(window.location.search)
  const [selected_tab, setSelectedTab] = useState(
    view === TAB_USERS ? TAB_USERS : view === TAB_ROLES ? TAB_ROLES : TAB_USERS
  )
  const show_sub_tabs = true
  return (
    <>
      {show_sub_tabs && (
        <SubTabs
          tabs={[
            {
              id: TAB_USERS,
              name: "Users",
              selected: selected_tab === TAB_USERS
            },
            {
              id: TAB_ROLES,
              name: "Roles",
              selected: selected_tab === TAB_ROLES
            }
          ]}
          onChange={tab => {
            setSelectedTab(tab.id)
            // clear out get params when changing tabs
            props.history.replace({
              search: ""
            })
          }}
        />
      )}

      {selected_tab === TAB_USERS && <Users {...props} />}
      {selected_tab === TAB_ROLES && <Roles {...props} />}
    </>
  )
}
