import moment from "moment"
import Styled from "styled-components"

import { Table, Modal } from "../../../../Shared"

import { useUser } from "../../../../../hooks"
import { isSingleCustomShift } from "./helpers"
import { momentToTimeValue } from "../../../../Shared/Input/Time"
import { weekdays, weekday_month_day_format } from "../../../../../constants"
import {
  toInt,
  ucfirst,
  displayHour,
  displayMinute
} from "../../../../../helpers"

const parseShiftForSummary = (worked_shift, recurring_shift) => {
  if (recurring_shift.schedule_days) {
    // the recurring_shift data structure is already parsed
    return recurring_shift
  }

  return {
    job_code: recurring_shift.jobCode,
    employee: recurring_shift.employee,
    end_time: momentToTimeValue(moment(worked_shift.end)),
    start_time: momentToTimeValue(moment(worked_shift.start)),
    location_id: recurring_shift.store.id,
    recurring: !isSingleCustomShift(recurring_shift),
    effective_date: recurring_shift.effective_date,
    schedule_days: recurring_shift.schedules.reduce(
      (acc, { day_start }) => ({
        ...acc,
        [day_start]: true
      }),
      {}
    )
  }
}

export const RecurringShiftSummaryFromQuery = ({
  worked_shift,
  recurring_shift,
  ...props
}) => (
  <RecurringShiftSummary
    sticky={false}
    recurring_shift={parseShiftForSummary(worked_shift, recurring_shift)}
    header={
      isSingleCustomShift(recurring_shift)
        ? "Custom Shift Summary"
        : "Recurring Shift Summary"
    }
    {...props}
  />
)

const RecurringShiftSummary = ({
  header,
  actions,
  onClose,
  sticky = true,
  recurring_shift
}) => {
  return (
    <Modal sticky={sticky} size="tiny" onClose={onClose}>
      <Modal.Header>
        {!!header && header}
        {!header && "Custom Shift Detail"}
      </Modal.Header>
      <Modal.Content fitted>
        <ShiftSummary shift={recurring_shift} />
      </Modal.Content>
      {!!actions && <Modal.Actions>{actions}</Modal.Actions>}
    </Modal>
  )
}

const ShiftSummary = Styled(({ className, shift }) => {
  const user = useUser()
  const {
    job_code,
    employee,
    end_time,
    recurring,
    start_time,
    location_id,
    schedule_days,
    effective_date
  } = shift

  const location = user.stores.find(({ id }) => id === toInt(location_id))

  return (
    <div className={className}>
      <Table basic="very" fitted>
        <Table.Body>
          {user.stores.length > 1 && (
            <ReviewShiftRow label="Location" value={location?.name ?? "-"} />
          )}
          <ReviewShiftRow label="Employee" value={employee?.name ?? "-"} />
          <ReviewShiftRow
            label="Job Code"
            value={employee?.assignedJobCode?.name ?? job_code?.name ?? "-"}
          />
          <ReviewShiftRow
            label={recurring ? "Effective On" : "Business Date"}
            value={moment(effective_date).format(weekday_month_day_format)}
          />
          <ReviewShiftRow
            label="Time of Shift"
            value={`${displayHour(start_time.hour)}:${displayMinute(
              start_time.minute
            )}${start_time.meridiem.toLowerCase()} to ${displayHour(
              end_time.hour
            )}:${displayMinute(
              end_time.minute
            )}${end_time.meridiem.toLowerCase()}`}
          />
          {!!recurring && (
            <ReviewShiftRow
              label="Recurring On"
              value={
                Object.keys(schedule_days).filter(day => !!schedule_days[day])
                  .length === weekdays.length ? (
                  "Every day of the week"
                ) : (
                  <>
                    {Object.keys(schedule_days)
                      .filter(day => !!schedule_days[day])
                      .map(day => ucfirst(day))
                      .join(", ")}
                  </>
                )
              }
            />
          )}
        </Table.Body>
      </Table>
    </div>
  )
})``

const ReviewShiftRow = ({ label, value }) => (
  <Table.Row>
    <ReviewLabelCell label={label} />
    <Table.Cell>{value}</Table.Cell>
  </Table.Row>
)
const ReviewLabelCell = Styled(({ className, label }) => (
  <Table.Cell collapsing className={className}>
    <span>{label}:</span>
  </Table.Cell>
))`
  & > span {
    font-size: 0.96rem;
    font-weight: bold;
  }
`

export default RecurringShiftSummary
