import Styled from "styled-components"

import RouteContent from "../RouteContent"
import PageIntro from "./../Shared/PageIntroduction"

import { colors } from "../../constants"
import Link from "../Link"
import { useIntercom } from "react-use-intercom"

const ContactWrapper = Styled.div`
  position: relative;
  width: 100%;
`

const IntercomLink = Styled(({ children, ...props }) => (
  <div {...props}>
    <div>
      &nbsp;
      {children}
    </div>
  </div>
))`
    font-size: 1rem;
    line-height: 1rem;
    display: inline-block;
    color: ${colors.dark3};
`

export default () => {
  // Show intercom hook
  const { show } = useIntercom()
  return (
    <RouteContent header="Contact Us">
      <ContactWrapper>
        <div>
          <>
            <PageIntro>
              We rely on your feedback to continuously improve our product. We
              genuinely appreciate any and all feedback.
            </PageIntro>
            <IntercomLink>
              You can contact our support team directly by clicking{" "}
              <Link onClick={show}>here.</Link>
            </IntercomLink>
          </>
        </div>
      </ContactWrapper>
    </RouteContent>
  )
}
