import { apollo_client } from "./api"
import { TERMS_OF_SERVICE_LATEST } from "./../graphql/queries"

const getLatestTermsOfService = () => {
  return apollo_client.query({
    query: TERMS_OF_SERVICE_LATEST
  })
}

export const checkToShowTermsOfService = user => {
  if (!!user && !!user.show_terms_of_service) {
    // Get the current terms of service
    getLatestTermsOfService()
      .then(({ data }) => {
        if (data && data.termsOfServiceActive) {
          // Display ToS modal
          showTerms(data.termsOfServiceActive)
        }
      })
      .catch(error => console.log("getLatestTermsOfService", error))
  }
}
