import { useState } from "react"

import { Query } from "@apollo/client/react/components"

import SummaryModal from "./SummaryModal"
import { AlertButton } from "../../Shared"
import { filterAndSortNotificationQueryResults } from "./helpers"

import { useUser, useSelectedLocation } from "../../../hooks/selectors"
import { JOB_CODES_WITH_INVALID_TAKE_HOME } from "../../../graphql/queries"

const InvalidTakeHomeAlert = ({ min_time = null, max_time = null }) => {
  const user = useUser()
  const selected_location = useSelectedLocation()

  const [show_modal, showModal] = useState(false)

  let store_ids = [...user.stores.map(({ id }) => id)]
  if (selected_location) {
    store_ids = [selected_location?.id]
  }

  return (
    <Query
      fetchPolicy="no-cache"
      query={JOB_CODES_WITH_INVALID_TAKE_HOME}
      variables={{ store_ids }}
    >
      {({ data }) => {
        // job codes with invalid take home
        const jc_invalid_take_home = filterAndSortNotificationQueryResults(
          data?.jobCodesWithInvalidTakeHome ?? [],
          min_time,
          max_time
        )

        return (
          <>
            {jc_invalid_take_home?.length > 0 && (
              <AlertButton
                id="job-code-take-home-notification"
                basic
                caution
                icon="flag"
                label="Invalid Take Home"
                count={jc_invalid_take_home.length}
                onClick={() => showModal(true)}
              />
            )}
            {jc_invalid_take_home?.length > 0 && !!show_modal && (
              <SummaryModal
                job_codes={jc_invalid_take_home}
                onClose={() => showModal(false)}
              />
            )}
          </>
        )
      }}
    </Query>
  )
}

export default InvalidTakeHomeAlert
