import Table from "../../Table"

import { displayNumber } from "../../../helpers/number"
import { TAKE_HOME_PER_HOUR_IDX, calculateAvgTakeHomePerHour } from "./helpers"

export default ({ columns, table_rows, search_fields, search_callbacks }) => (
  <Table.TotalsRow>
    {columns.map(column => {
      if (column.name === TAKE_HOME_PER_HOUR_IDX) {
        return (
          <Table.TotalCell
            key={column.name}
            textAlign="right"
            value={
              isFinite(calculateAvgTakeHomePerHour(table_rows))
                ? displayNumber(calculateAvgTakeHomePerHour(table_rows), 2)
                : "-"
            }
          />
        )
      }
      if (search_fields[column.name] !== undefined) {
        return (
          <Table.InputCell
            key={column.name}
            value={search_fields[column.name]}
            onChange={value => search_callbacks[column.name](value)}
          />
        )
      }
      if (column.type === "number") {
        return (
          <Table.TotalCell
            key={column.name}
            textAlign="right"
            value={displayNumber(
              table_rows.reduce(
                (acc, { rows }) =>
                  acc +
                  rows.reduce((row_acc, row) => row_acc + row[column.name], 0),
                0
              ),
              2
            )}
          />
        )
      }
      return <Table.TotalCell value="-" textAlign="left" key={column.name} />
    })}
  </Table.TotalsRow>
)
