import { useState } from "react"

import moment from "moment"
import Styled from "styled-components"
import { Form } from "semantic-ui-react"
import { Mutation } from "@apollo/client/react/components"

import {
  Modal,
  Button,
  Message,
  TimeInput,
  IconButton,
  SubmitButton,
  SelectedLabel
} from "../../../Shared"
import JobCodeSearch from "../../../Search/JobCode"
import { timeValueToDateTime } from "../../../Shared/Input/Time"

import { useBusinessDates } from "../../../../hooks"
import { datetime_format, none_job_code } from "../../../../constants"
import { toInt, round, isNumber } from "../../../../helpers/number"
import { UPDATE_WORKED_SHIFT } from "../../../../graphql/mutations"

export default ({ worked_shift, onClose, onSuccess }) => {
  const { businessDate } = useBusinessDates()

  const [state, _setState] = useState(() => ({
    error: null,
    dirty: false,
    job_code_input: null,
    cash_tips_input: !!worked_shift.cash_tips
      ? worked_shift.cash_tips.toFixed(2)
      : "",
    shift_start_input: moment(worked_shift.start).startOf("minute"),
    shift_end_input: worked_shift.end
      ? moment(worked_shift.end).startOf("minute")
      : null
  }))
  const setState = new_state => _setState({ ...state, ...new_state })

  const {
    error,
    job_code_input,
    cash_tips_input,
    shift_end_input,
    shift_start_input
  } = state

  const job_code_name =
    worked_shift.employee?.assignedJobCode?.name ??
    job_code_input?.name ??
    worked_shift.jobCode?.name ??
    none_job_code

  const can_edit_job_code = !worked_shift.employee?.assignedJobCode?.name

  return (
    <Modal sticky size="tiny" onClose={onClose}>
      <Modal.Header onClose={onClose}>Edit Shift</Modal.Header>
      <Modal.Content>
        {!error && (
          <Message
            type="info"
            margin_bottom="1rem"
            message={
              <>
                <strong>Note:</strong> Changes made here will override data
                reported by your point-of-sale provider.
              </>
            }
          />
        )}
        {!!error && (
          <Message
            type="error"
            message={error}
            margin_bottom="1rem"
            onDismiss={() => setState({ error: null })}
          />
        )}
        <StyledForm>
          <Form.Field>
            <label>Employee Name</label>
            <span>
              {worked_shift.employee.first_name ?? ""}{" "}
              {worked_shift.employee.last_name ?? ""}
            </span>
          </Form.Field>
          <Form.Field>
            <label>Job Code</label>
            <span>
              {can_edit_job_code && job_code_input === false ? (
                <JobCodeSearch
                  autoFocus={true}
                  exclude_none={true}
                  name="edit-shift-job-code-search"
                  store_id={worked_shift?.employee?.store?.id}
                  onChange={({ job_code }) =>
                    setState({ dirty: true, job_code_input: { ...job_code } })
                  }
                />
              ) : (
                <SelectedLabel
                  value={job_code_name}
                  onRemove={
                    can_edit_job_code
                      ? () =>
                          setState({
                            dirty: true,
                            job_code_input: false
                          })
                      : null
                  }
                />
              )}
            </span>
          </Form.Field>
          <Form.Field>
            <label>Clock In</label>
            <TimeInput
              value={shift_start_input}
              onChange={time => {
                const shift_start = timeValueToDateTime(
                  time,
                  worked_shift.start,
                  businessDate
                )
                setState({
                  dirty: true,
                  shift_start_input: shift_start,
                  error:
                    shift_end_input && shift_start.isAfter(shift_end_input)
                      ? "Clock in can't be after clock out."
                      : null
                })
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Clock Out</label>
            {!shift_end_input && (
              <NoClockoutSet
                onSetClockout={() =>
                  setState({
                    dirty: true,
                    shift_end_input: moment(shift_start_input).add(1, "minutes")
                  })
                }
              />
            )}
            {!!shift_end_input && (
              <TimeInput
                value={shift_end_input}
                onChange={time => {
                  const shift_end = timeValueToDateTime(
                    time,
                    worked_shift.start,
                    businessDate
                  )
                  setState({
                    dirty: true,
                    shift_end_input: shift_end,
                    error: shift_end.isBefore(shift_start_input)
                      ? "Clock out can't be before clock in."
                      : null
                  })
                }}
              />
            )}
          </Form.Field>
          <Form.Field>
            <label>Cash Tips</label>
            <CashTipsInput
              cash_tips_input={cash_tips_input}
              onChange={value => {
                setState({
                  dirty: true,
                  cash_tips_input: value,
                  error:
                    value.length > 0 && !validMoneyValue(value)
                      ? "Invalid cash tip value."
                      : null
                })
              }}
            />
          </Form.Field>
        </StyledForm>
      </Modal.Content>
      <Modal.Actions>
        <Mutation mutation={UPDATE_WORKED_SHIFT} onCompleted={onSuccess}>
          {(updateWorkedShift, { loading }) => (
            <>
              <Button disabled={loading} onClick={onClose}>
                Cancel
              </Button>
              <SubmitButton
                loading={loading}
                disabled={
                  loading || !formIsValid(state, worked_shift, businessDate)
                }
                onClick={() =>
                  updateWorkedShift({
                    variables: {
                      input: {
                        id: toInt(worked_shift.id),
                        job_code_id: job_code_input?.id,
                        cash_tips: parseFloat(
                          !!cash_tips_input ? cash_tips_input : 0
                        ),
                        start: shift_start_input.format(datetime_format),
                        end: !!shift_end_input
                          ? shift_end_input.format(datetime_format)
                          : null
                      }
                    }
                  })
                }
              />
            </>
          )}
        </Mutation>
      </Modal.Actions>
    </Modal>
  )
}

// helper functions
const validMoneyValue = value =>
  isNumber(value) &&
  parseFloat(value) >= 0 &&
  round(value, 2) === parseFloat(value)

const validCashTips = cash_tips_input => {
  if (cash_tips_input === "") {
    // no input is valid input
    return true
  }
  return validMoneyValue(cash_tips_input)
}

const validStartTime = (state, worked_shift, businessDate) => {
  const { start } = worked_shift
  const { shift_start_input, shift_end_input } = state

  return (
    businessDate(shift_start_input) === businessDate(start) &&
    (shift_end_input === null ||
      shift_start_input.isSameOrBefore(shift_end_input))
  )
}

const validEndTime = (state, worked_shift, businessDate) => {
  const { end } = worked_shift
  const { shift_start_input, shift_end_input } = state

  return (
    (end === null && shift_end_input === null) ||
    (shift_end_input !== null &&
      end === shift_end_input.format(datetime_format)) ||
    (businessDate(shift_end_input) === businessDate(shift_start_input) &&
      shift_end_input.isSameOrAfter(shift_start_input))
  )
}

const formIsValid = (state, worked_shift, businessDate) =>
  state.dirty &&
  state.job_code_input !== false &&
  validCashTips(state.cash_tips_input) &&
  validStartTime(state, worked_shift, businessDate) &&
  validEndTime(state, worked_shift, businessDate)

// styled components
const StyledForm = Styled(Form)`
  & > div {
    margin-bottom: 1rem;
  }
`

const CashTipsInput = Styled(({ className, cash_tips_input, onChange }) => (
  <div className={className}>
    <div>
      <Form.Input
        placeholder="0.00"
        icon="dollar"
        value={cash_tips_input}
        onChange={(e, { value }) => onChange(value)}
      />
    </div>
  </div>
))`
  & > div {
    display: inline-block;
  }
`

const NoClockoutSet = Styled(({ className, onSetClockout }) => (
  <div className={className}>
    <span>No clock out set.</span>&nbsp;&nbsp;
    <IconButton
      circular
      compact
      size="tiny"
      icon="edit"
      onClick={onSetClockout}
    />
  </div>
))`
  line-height: 2rem;
`
