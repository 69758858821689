import Review from "./"
import { Icon, Modal, Button } from "../../../Shared"

export default ({ pool, onEdit, onClose, ...props }) => (
  <Modal onClose={onClose} {...props}>
    <Modal.Header>
      <span id="pool-summary-header">Tip Pool Summary</span>
    </Modal.Header>
    <Modal.Content scrolling>
      <Review pool={pool} />
    </Modal.Content>
    <Modal.Actions>
      <Button default onClick={onClose}>
        Close
      </Button>
      {!!onEdit && (
        <Button primary onClick={onEdit} icon labelPosition="right">
          Edit
          <Icon name="edit" />
        </Button>
      )}
    </Modal.Actions>
  </Modal>
)
