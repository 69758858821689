import React from "react"

import Modal from "./"
import ErrorMessage from "../Message/Error"

export default ({
  size = "tiny",
  closeOneEscape = true,
  onClose = () => {},
  ...props
}) => (
  <Modal
    closeOnEscape={closeOneEscape}
    open={open}
    size={size}
    centered={centered}
    dimmer={dimmer}
    onClose={onClose}
    {...props}
  >
    <Modal.Content>
      <ErrorMessage />
    </Modal.Content>
  </Modal>
)
