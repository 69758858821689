import Styled from "styled-components"
import {
  Icon,
  ExclamationIcon,
  SuccessIcon,
  Link,
  Table
} from "../../../Shared"

export default ({ failed, completed_at, has_detail, handleShowDetail }) => {
  return (
    <Table.Cell className="payout-status-cell">
      {!!failed && (
        <>
          <ExclamationIcon label={<StatusLabel label="Payout Failed" />} />
          {has_detail && (
            <>
              &nbsp;&nbsp;&nbsp;
              <Link onClick={handleShowDetail}>show detail</Link>
            </>
          )}
        </>
      )}
      {!failed && (
        <>
          {!completed_at && (
            <Icon
              color={colors.info}
              name="hourglass half"
              label={<StatusLabel italic label="In Progress..." />}
            />
          )}
          {!!completed_at && (
            <SuccessIcon label={<StatusLabel label="Complete" />} />
          )}
        </>
      )}
    </Table.Cell>
  )
}

const StatusLabel = Styled(({ className, label = "" }) => (
  <span className={className}>{label}</span>
))`
  line-height: 1rem;
  font-size: 0.95rem;
  ${({ italic = false }) =>
    !!italic ? "font-style: italic;" : "font-weight: bold;"}
`
