import { useState } from "react"

import moment from "moment"
import { useQuery } from "@apollo/client"

import Modal from "../../Modal"
import { Loader } from "../../Shared"
import Button, { SubmitButton } from "../../Button"
import { DetailWrapper, DetailsSummary, DateDetails } from "./Styled"

import { toInt } from "../../../helpers/number"
import { generateDistrosExport } from "./helpers"
import { date_format } from "../../../constants"
import { RULE_TYPE_POOL, RULE_TYPE_SHARE } from "../../Rule/helpers"
import { EMPLOYEE_TAKE_HOME_DETAIL } from "../../../graphql/queries"

export default ({
  onClose,
  values,
  rule_types,
  employee,
  start_date,
  end_date
}) => {
  const [active_row, setActiveRow] = useState(null)
  const { data } = useQuery(EMPLOYEE_TAKE_HOME_DETAIL, {
    variables: {
      id: toInt(employee.id),
      start_date,
      end_date
    }
  })
  // get the specific breakdown for this employee
  let breakdown_values = values.filter(
    ({ employee: { id } }) => id === employee.id
  )[0].rows

  let details = null
  try {
    details = data.employeeTakeHomeDetailByDate
      .map(({ saleDistributions, ...breakdown }) => ({
        ...breakdown,
        date: moment(breakdown.window_start).format(date_format),
        distributions: saleDistributions.filter(({ rule }) =>
          rule_types.includes(rule.type)
        )
      }))
      .filter(breakdown => breakdown.distributions.length > 0)
      .map(breakdown => ({
        ...breakdown,
        net_amount: breakdown.distributions.reduce((acc, distro) => {
          if (distro.is_deposit) {
            return acc - distro.amount
          }
          return acc + distro.amount
        }, 0),
        rules: Object.values(
          breakdown.distributions.reduce((acc, { rule, ...distro }) => {
            if (!acc[rule.id]) {
              acc[rule.id] = { ...rule, distributions: [], net_amount: 0 }
            }
            acc[rule.id].distributions.push(distro)
            if (distro.is_deposit) {
              acc[rule.id].net_amount -= distro.amount
            } else {
              acc[rule.id].net_amount += distro.amount
            }
            return acc
          }, {})
        )
      }))
  } catch {}
  // Get the net amount for the summary, sum field on each row
  let net_amount = 0
  if (rule_types.includes(RULE_TYPE_POOL)) {
    net_amount = breakdown_values.reduce(
      (sum, row) => sum + row.tip_pool_net,
      0
    )
  } else if (rule_types.includes(RULE_TYPE_SHARE)) {
    net_amount = breakdown_values.reduce((sum, row) => sum + row.net_distro, 0)
  }
  return (
    <Modal onClose={onClose}>
      <Modal.Header onClose={onClose}>
        {rule_types.includes(RULE_TYPE_POOL) && "Tip Pool"}
        {rule_types.includes(RULE_TYPE_SHARE) && "Tip Share"} Distribution
        Detail
      </Modal.Header>
      <Modal.Content scrolling>
        {!details && <Loader />}
        {!!details && (
          <>
            <DetailsSummary
              start_date={start_date}
              end_date={end_date}
              employee={employee}
              net_amount={net_amount}
            />
            <DetailWrapper>
              {[...details]
                .sort((a, b) =>
                  moment(a.date).isBefore(moment(b.date)) ? 1 : -1
                )
                .map((breakdown, idx) => (
                  <DateDetails
                    key={idx}
                    detail={breakdown}
                    active={breakdown.id === active_row || details.length === 1}
                    toggleActive={
                      details.length === 1
                        ? undefined
                        : () =>
                            setActiveRow(
                              breakdown.id === active_row ? null : breakdown.id
                            )
                    }
                  />
                ))}
            </DetailWrapper>
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Close</Button>
        <SubmitButton
          secondary
          label="Export"
          icon="download"
          disabled={!details}
          onClick={() => generateDistrosExport(details)}
        />
      </Modal.Actions>
    </Modal>
  )
}
