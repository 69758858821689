import { useMutation } from "@apollo/client"
import { DELETE_ROLE } from "../../../../../../graphql/mutations"
import { project_info } from "../../../../../../constants"
import { AreYouSure } from "../../../../../Shared"
import { useToast } from "../../../../../../hooks"

const DeleteRoleModal = ({ role, onClose, refetch }) => {
  const [deleteRole, { loading }] = useMutation(DELETE_ROLE)
  const toast = useToast()

  const handleDeleteRole = () => {
    const { id } = role
    deleteRole({
      variables: { id },
      onCompleted: () => {
        onClose()
        toast({
          type: "success",
          message: "Role deleted successfully."
        })
        refetch()
      }
    })
  }

  return (
    <AreYouSure
      header="Are you sure?"
      submitted={loading}
      body={
        <>
          <p>Are you sure you want to delete {role.name}?</p>
          <p>This role will no longer have access to {project_info.name}.</p>
        </>
      }
      onConfirm={handleDeleteRole}
      onClose={onClose}
    />
  )
}

export default DeleteRoleModal
