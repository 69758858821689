import { Component, useEffect } from "react"
import Joyride from "react-joyride"

import {
  NextButton,
  CloseButton,
  TooltipBody,
  HiddenBeacon,
  TooltipTitle,
  TooltipFooter,
  TooltipContent
} from "./Styled"
import { Button } from "../Shared"

import { useStateObject, useUser } from "../../hooks"
import useAuthorization from "../../hooks/authorization"

const default_locale = {
  back: "Back",
  close: "Close",
  last: "Close",
  next: "Next",
  skip: "Skip"
}

export default ({
  steps,
  locale = {},
  onOpen = () => {},
  onClose = () => {}
}) => {
  const user = useUser()
  const { hasPermission } = useAuthorization()

  const [{ pause_tour, step_index }, setState] = useStateObject({
    pause_tour: false,
    step_index: null
  })

  useEffect(() => onOpen(), [])

  return (
    <Joyride
      stepIndex={step_index}
      steps={steps.map((step, idx) => ({
        ...step,
        disableBeacon: true,
        hide_hint: steps.length === 1,
        user: user,
        hasPermission,
        goToStep: to_step => {
          let step_idx
          if (to_step === "last") {
            step_idx = steps.length - 1
          } else if (to_step === "first") {
            step_idx = 0
          } else if (steps.map(({ name }) => name).indexOf(to_step) > -1) {
            step_idx = steps.map(({ name }) => name).indexOf(to_step)
          } else {
            step_idx = to_step
          }
          setState({ step_index: step_idx })
        },
        onMount: () => {
          setState({ step_index: idx })
          if (step.onMount) {
            try {
              step.onMount()
            } catch {}
          }
        }
      }))}
      run={!pause_tour}
      callback={data => {
        if (
          (data.action === "next" ||
            data.action === "prev" ||
            data.action === "update") &&
          data.lifecycle === "ready" &&
          data.step.delay_mount_ms
        ) {
          setState({ pause_tour: true })
          setTimeout(
            () => setState({ pause_tour: false }),
            data.step.delay_mount_ms
          )
        }
        if (data.action === "close" || data.action === "reset") {
          // close the tour
          onClose()
        }
      }}
      locale={{ ...default_locale, ...locale }}
      continuous
      beaconComponent={HiddenBeacon}
      disableScrollParentFix={true}
      tooltipComponent={Tooltip}
      styles={{
        options: {
          zIndex: 100000
        }
      }}
      floaterProps={{
        styles: {
          floater: {
            filter: "none"
          }
        }
      }}
      disableOverlayClose={true}
    />
  )
}

class Tooltip extends Component {
  state = {
    closeTour: null,
    nextStep: null,
    previousStep: null
  }

  componentDidMount() {
    const { step, closeProps, primaryProps, backProps } = this.props
    const closeTour = !!step.onClose
      ? e => step.onClose(step, () => closeProps.onClick(e))
      : closeProps.onClick
    const nextStep =
      step.next && step.next.onClick
        ? e =>
            step.next.onClick(
              step,
              () => primaryProps.onClick(e),
              () => closeProps.onClick(e)
            )
        : primaryProps.onClick
    const previousStep =
      step.back && step.back.onClick
        ? e =>
            step.back.onClick(
              step,
              () => backProps.onClick(e),
              () => closeProps.onClick(e)
            )
        : backProps.onClick

    this.setState({ closeTour, nextStep, previousStep })

    if (step.onMount) {
      step.onMount()
    }

    document.addEventListener("keydown", this.handleKeyboardNav)
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyboardNav)
  }

  handleKeyboardNav = ({ key }) => {
    let btn
    switch (key) {
      case "ArrowRight":
        btn = document.getElementById("tour-continue")
        if (btn) btn.click()
        return
      case "ArrowLeft":
        btn = document.getElementById("tour-back")
        if (btn) btn.click()
        return
      default:
        return
    }
  }

  // attributes can be specified as functions to be evaluated
  // at render time.
  renderAttribute = attr => {
    if (typeof attr === "function") {
      return attr(this.props.step)
    }
    return attr
  }

  render() {
    const {
      continuous,
      index,
      step,
      backProps,
      primaryProps,
      tooltipProps
    } = this.props
    const { closeTour, nextStep, previousStep } = this.state
    return (
      <TooltipBody {...tooltipProps}>
        <CloseButton id="tour-close" onClick={closeTour} />
        {!!step.title && (
          <TooltipTitle>{this.renderAttribute(step.title)}</TooltipTitle>
        )}
        <TooltipContent>
          {this.renderAttribute(step.content)}
          {index === 0 && step.hide_hint !== true && (
            <p>
              <i>Hint:</i> Try using the left & right arrow keys to navigate the
              tour.
            </p>
          )}
        </TooltipContent>
        <TooltipFooter>
          {index > 0 && !step.disable_back && (
            <Button id="tour-back" onClick={previousStep}>
              {backProps.title}
            </Button>
          )}
          {!!step.actions &&
            step.actions.map(({ title, onClick }, idx) => (
              <Button key={idx} onClick={() => onClick(step)}>
                {title}
              </Button>
            ))}
          {continuous && !step.disable_next && (
            <NextButton
              id="tour-continue"
              onClick={nextStep}
              title={
                step.next && step.next.title
                  ? step.next.title
                  : primaryProps.title
              }
            />
          )}
          {!!step.disable_next && (
            <Button secondary onClick={closeTour}>
              Close
            </Button>
          )}
        </TooltipFooter>
      </TooltipBody>
    )
  }
}
