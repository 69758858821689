import { createContext, useContext } from "react"
import { useStateObject } from "../../../../../hooks"

const RoleModalsContext = createContext({})

export const RoleModalsProvider = ({ children }) => {
  const [modal, setModal] = useStateObject({
    edit_modal: null,
    delete_modal: null,
    editor_modal: null
  })

  const showModal = (name, data = true) => setModal({ [name]: data })

  const hideModal = name => setModal({ [name]: null })

  return (
    <RoleModalsContext.Provider
      value={{ modal, setModal, showModal, hideModal }}
    >
      {children}
    </RoleModalsContext.Provider>
  )
}

export const useRoleModals = () => useContext(RoleModalsContext)
