import { Popup as SemanticPopup } from "semantic-ui-react"

import { style } from "../../../constants"

export { default as PopupMenu } from "./PopupMenu"

const DEFAULT_DELAY_MS = 50
const POPOVER_DELAY_MS = 500

const Popup = ({
  children,
  content = "",
  delay = null,
  trigger = null,
  pointing = false,
  disabled = false,
  hideOnScroll = true,
  ...props
}) => (
  <SemanticPopup
    inverted
    content={content}
    basic={!pointing}
    disabled={disabled}
    hideOnScroll={hideOnScroll}
    style={{ fontFamily: style.font }}
    trigger={!!trigger ? trigger : children}
    mouseEnterDelay={
      typeof delay === "number"
        ? delay
        : !!delay
        ? POPOVER_DELAY_MS
        : DEFAULT_DELAY_MS
    }
    {...props}
  />
)

export default Popup
