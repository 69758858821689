import Dropdown from "../../../../../Dropdown"
import {
  DISTRO_SOURCE_CASH,
  DISTRO_SOURCE_CC_TIP,
  DISTRO_SOURCE_TYPE_CASH,
  DISTRO_SOURCE_TYPE_TIPS,
  DISTRO_METHOD_DAILY,
  TIP_DISTRO_SOURCES_DEFAULT
} from "../../../../helpers"

export default ({
  revenue_centers,
  distribution_source_type,
  distribution_method,
  onUpdate
}) => {
  const handleChange = (e, { value }) => {
    onUpdate({
      distribution_source_type: value,
      distribution_sources:
        value === DISTRO_SOURCE_TYPE_CASH
          ? [DISTRO_SOURCE_CASH]
          : [...TIP_DISTRO_SOURCES_DEFAULT],
      // force distribution method to daily when cash tips selected
      distribution_method:
        value === DISTRO_SOURCE_TYPE_CASH
          ? DISTRO_METHOD_DAILY
          : distribution_method
    })
  }

  return (
    <Dropdown
      selection
      fluid
      value={distribution_source_type}
      placeholder="Select a distribution source"
      options={[
        {
          value: DISTRO_SOURCE_TYPE_TIPS,
          text: DISTRO_SOURCE_CC_TIP + "s"
        },
        {
          value: DISTRO_SOURCE_TYPE_CASH,
          text:
            DISTRO_SOURCE_CASH +
            (revenue_centers.length > 0
              ? "s (not compatible with Revenue Centers)"
              : "s"),
          disabled: revenue_centers.length > 0
        }
      ]}
      onChange={handleChange}
    />
  )
}
