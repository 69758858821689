import moment from "moment"
import Styled from "styled-components"

import WeightedDistribution from "../../WeightedDistribution"
import { Icon, List, Modal, Table, Badge, Button } from "../../../Shared"
import { EffectiveTimeSpan, EtaFinalizeRuleWarning } from "../../Styled"

import {
  DISTRO_SOURCE_CASH,
  RULE_TYPE_FLAT_RATE,
  DEFAULT_DISTRO_PRIORITY,
  groupJobsByPriority,
  hasWeightsConfigured,
  useIsCustomRuleSchedule,
  maxDistributionPriority,
  useShowEtaFinalizeRuleWarning
} from "../../helpers"
import {
  useReportLockThreshold,
  useRevenueCentersEnabled
} from "../../../../hooks"
import {
  ucwords,
  formatListForSentence,
  round,
  displayMoney
} from "../../../../helpers"
import { colors, hour_midnight, month_day_format } from "../../../../constants"

const ModalHeader = Styled(({ children, ...props }) => (
  <Modal.Header {...props}>
    <span id="rule-summary-header">{children}</span>
  </Modal.Header>
))``
const ModalContent = Styled(props => <Modal.Content scrolling {...props} />)`
  & > div:last-child {
    margin-bottom: 0;
  }
`
const Field = Styled.div`
  margin-bottom: 1rem;
`
const FieldName = Styled(({ name, ...props }) => <div {...props}>{name}:</div>)`
  font-size: 0.9rem;
  font-weight: bold;
  color: ${colors.dark2};
  border: 1px solid ${colors.light4};
  background-color: ${colors.light2};
  padding: 0.5rem 1rem;
`
const FieldValue = Styled.div`
  font-size: 1.3rem;
  font-weight: 300;
  ${({ compact = false }) =>
    !!compact ? `` : `padding: 0.67rem 1rem 0.67rem 1rem;`}
  border-left: 1px solid ${colors.light4};
  border-right: 1px solid ${colors.light4};
  border-bottom: 1px solid ${colors.light4};
`
const ItemLabel = props => <Badge {...props} />

const ListWrapper = Styled.div`
  margin: 0.5rem 1rem 0.33rem 1rem;
`
const CategoriesList = Styled(List)`
  &&& {
    font-size: 0.9rem;
    font-weight: normal;
  }
`
const ScheduleTable = Styled(props => (
  <Table
    {...props}
    basic="very"
    compact="very"
    padded={false}
    size="small"
    celled={false}
    fitted
  />
))``
const DistributeToJobs = ({ distribute_to_by_priority, max_priority }) => (
  <>
    {[...Object.keys(distribute_to_by_priority)]
      .sort((a, b) => a > b)
      .map(priority => {
        const distribute_to = distribute_to_by_priority[priority]
        const has_weights_configured = hasWeightsConfigured(distribute_to)
        return (
          <DistributeToPriorityGroup
            key={priority}
            priority={Number(priority)}
            max_priority={max_priority}
          >
            {!has_weights_configured &&
              distribute_to.map((job_code, idx) => (
                <ItemLabel primary key={idx}>
                  {job_code.name}
                </ItemLabel>
              ))}
            {!!has_weights_configured && (
              <WeightedDistribution
                allow_input={false}
                show_header={false}
                selected_job_codes={[...distribute_to].sort((a, b) =>
                  b.weight === a.weight
                    ? a.name.localeCompare(b.name)
                    : b.weight - a.weight
                )}
              />
            )}
          </DistributeToPriorityGroup>
        )
      })}
  </>
)

const DistributeToPriorityGroup = Styled(
  ({ max_priority, priority, children, ...props }) => {
    if (max_priority === DEFAULT_DISTRO_PRIORITY) {
      return <div style={{ fontSize: "0.9rem" }}>{children}</div>
    }
    return (
      <div {...props}>
        <div>
          {priority === DEFAULT_DISTRO_PRIORITY && "Primary Distribution Group"}
          {priority > DEFAULT_DISTRO_PRIORITY && (
            <>
              Fallback Distribution Group
              {max_priority > DEFAULT_DISTRO_PRIORITY + 1 && (
                <> {priority - 1}</>
              )}
            </>
          )}
          :
        </div>
        <div>{children}</div>
      </div>
    )
  }
)`
  font-size: 0.9rem;
  font-weight: bold;
  color: ${colors.dark2};
  margin-top: 0.67rem;
  &:first-child {
    margin-top: 0;
  }
  & > div:first-child {
    margin: 0.17rem 0 0.33rem;
  }
`

const displayHour = hour => {
  if (hour === 0) return "12am"
  if (hour === hour_midnight / 2) return "12pm"
  if (hour > hour_midnight / 2) {
    return `${hour - hour_midnight / 2}pm`
      .replace(".25", ":15")
      .replace(".5", ":30")
      .replace(".75", ":45")
  }
  return `${hour}am`
    .replace(".25", ":15")
    .replace(".5", ":30")
    .replace(".75", ":45")
}

export default ({
  name,
  type,
  onEdit,
  percent,
  onClose,
  onSubmit,
  schedules,
  collect_from,
  distribute_to,
  effective_date,
  revenue_centers,
  sale_categories,
  deactivated_date,
  distribution_method,
  distribution_sources,
  is_edit = false
}) => {
  const reportLockThreshold = useReportLockThreshold()
  const lock_threshold = reportLockThreshold()
  const custom_schedule = useIsCustomRuleSchedule(schedules)
  const show_finalize_warning = useShowEtaFinalizeRuleWarning(effective_date)
  const rev_centers_enabled =
    useRevenueCentersEnabled() &&
    !distribution_sources.includes(DISTRO_SOURCE_CASH)
  return (
    <Modal size="small" onClose={onClose}>
      <ModalHeader onClose={onClose}>Rule Summary</ModalHeader>
      <ModalContent>
        {show_finalize_warning && !!onSubmit && (
          <EtaFinalizeRuleWarning is_edit={is_edit} />
        )}
        <Field>
          <FieldName name="Rule Name" />
          <FieldValue>{name}</FieldValue>
        </Field>
        {rev_centers_enabled && (
          <Field>
            <FieldName name="Revenue Centers" />
            <FieldValue>
              {revenue_centers.length === 0 && "No Filter Applied"}
              {revenue_centers.length > 0 &&
                revenue_centers.map((revenue_center, idx) => (
                  <ItemLabel key={idx}>{revenue_center}</ItemLabel>
                ))}
            </FieldValue>
          </Field>
        )}
        <div id="rule-summary-percent-from-to">
          <Field>
            <FieldName name="Distribute" />
            <FieldValue>
              {type === RULE_TYPE_FLAT_RATE
                ? `${displayMoney(percent)} per hour worked from `
                : `${round(percent, 2)}% of `}
              {sale_categories.length === 0 &&
                formatListForSentence(
                  distribution_sources.sort((a, b) => a.localeCompare(b))
                ) + "."}
              {sale_categories.length > 0 && (
                <>
                  sale price for items in the following categories:
                  <ListWrapper>
                    <CategoriesList bulleted>
                      {sale_categories.map((category, idx) => (
                        <List.Item key={idx}>{category}</List.Item>
                      ))}
                    </CategoriesList>
                  </ListWrapper>
                </>
              )}
            </FieldValue>
          </Field>
          <Field>
            <FieldName name="From" />
            <FieldValue>
              {collect_from.map((job_code_name, idx) => (
                <ItemLabel primary key={idx}>
                  {job_code_name}
                </ItemLabel>
              ))}
            </FieldValue>
          </Field>
          <Field>
            <FieldName name="To" />
            <FieldValue>
              <DistributeToJobs
                distribute_to_by_priority={groupJobsByPriority(distribute_to)}
                max_priority={maxDistributionPriority(distribute_to)}
              />
            </FieldValue>
          </Field>
        </div>
        <Field id="rule-summary-distro-frequency">
          <FieldName name="Distribution Frequency" />
          <FieldValue>{distribution_method}</FieldValue>
        </Field>
        {!!custom_schedule && (
          <Field>
            <FieldName name="Schedule" />
            <FieldValue compact>
              <ScheduleTable>
                <Table.Body>
                  {schedules.map((schedule, idx) => (
                    <Table.Row key={idx}>
                      <Table.Cell>{ucwords(schedule.day_start)}</Table.Cell>
                      <Table.Cell>
                        {displayHour(schedule.hour_start)} -{" "}
                        {displayHour(schedule.hour_end)}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </ScheduleTable>
            </FieldValue>
          </Field>
        )}
        <Field>
          <FieldName name="Effective" />
          <FieldValue>
            {(!onSubmit || !lock_threshold.isAfter(effective_date)) && (
              <EffectiveTimeSpan
                effective_date={effective_date}
                deactivated_date={deactivated_date}
              />
            )}
            {!!onSubmit && lock_threshold.isAfter(effective_date) && (
              <>
                Updates will take effect starting{" "}
                {lock_threshold.format(month_day_format)}.
              </>
            )}
          </FieldValue>
        </Field>
      </ModalContent>
      <Modal.Actions>
        {!onSubmit && (
          <>
            <Button default onClick={e => onClose()}>
              Close
            </Button>
            {!!onEdit && (
              <Button primary onClick={onEdit} icon labelPosition="right">
                Edit
                <Icon name="edit" />
              </Button>
            )}
          </>
        )}
        {!!onSubmit && (
          <>
            <Button default onClick={e => onClose()}>
              Cancel
            </Button>
            <Button
              primary
              disabled={
                name.trim().length === 0 || isNaN(moment(effective_date).unix())
              }
              onClick={onSubmit}
              icon
              labelPosition="right"
            >
              Submit
              <Icon name="check" />
            </Button>
          </>
        )}
      </Modal.Actions>
    </Modal>
  )
}
