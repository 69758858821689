import React from "react"

import { Form } from "semantic-ui-react"

import { Thin, Paragraph, PercentInput } from "../Styled"

export const STEP_TIP_FEE = "tip-fee"

export default ({ tip_fee, org_is_multi_unit = false, onChange }) => (
  <>
    <Paragraph>
      Let's start with your organization's credit card tip fee.
    </Paragraph>
    <Paragraph>
      CC tip fees are witheld when calculating total take home pay for each
      employee.
    </Paragraph>
    {org_is_multi_unit && (
      <Paragraph>
        <Thin>
          <i>Note:</i> You can customize percentages for specific locations from
          the Settings page.
        </Thin>
      </Paragraph>
    )}
    <Form>
      <Form.Field>
        <label>Credit Card Tip Fee</label>
        <PercentInput
          icon="percent"
          value={tip_fee === null ? "" : tip_fee}
          placeholder="0"
          onChange={(e, { value }) => onChange(value)}
        />
      </Form.Field>
    </Form>
  </>
)
