import React, { useState } from "react"

import { Form } from "semantic-ui-react"

import Button from "../../../Button"
import { Thin, Paragraph, EmailList, EmailItem } from "../Styled"

import { isValidEmail } from "../../../../helpers/string"

export const STEP_INVITE_USERS = "invite-users"

export default ({
  invites = [],
  org_is_multi_unit = false,
  onAddInvite,
  onRemoveInvite
}) => {
  const [request_add_invites, requestAddInvites] = useState(invites.length > 0)
  const [invite_email, setInviteEmail] = useState("")

  return (
    <>
      <Paragraph>One last thing...</Paragraph>
      {!request_add_invites && (
        <>
          <Paragraph>
            Would you like us to send invites to other Admin users?
          </Paragraph>
          <Button basic onClick={() => requestAddInvites(true)}>
            Invite Users
          </Button>
        </>
      )}
      {!!request_add_invites && (
        <>
          <Paragraph>Would you like to invite other admin users?</Paragraph>
          <Paragraph>
            <Thin>
              Admin users can edit distribution logic and settings{" "}
              {org_is_multi_unit && "for all locations"}.
            </Thin>
          </Paragraph>
          <Paragraph>
            <Thin>
              You'll be able to invite managers and/or employees separately from
              the Settings page.
            </Thin>
          </Paragraph>
          <Form>
            <Form.Field>
              <label>Email Address</label>
              <Form.Input
                autoFocus={true}
                spellCheck="false"
                placeholder="email@example.com"
                value={invite_email}
                onChange={(e, { value }) => setInviteEmail(value)}
                action={{
                  labelPosition: "right",
                  disabled:
                    !isValidEmail(invite_email.trim()) ||
                    invites
                      .map(email => email.toLowerCase())
                      .includes(invite_email.trim().toLowerCase()),
                  icon: "plus",
                  content: "Add Invite",
                  onClick: () => {
                    onAddInvite(invite_email.trim())
                    setInviteEmail("")
                  }
                }}
              />
            </Form.Field>
          </Form>
          {invites.length > 0 && (
            <EmailList>
              {invites.map((email, idx) => (
                <EmailItem
                  email={email}
                  key={idx}
                  onRemove={() => onRemoveInvite(email)}
                />
              ))}
            </EmailList>
          )}
        </>
      )}
    </>
  )
}
