import Styled from "styled-components"
import { useQuery, useMutation } from "@apollo/client"

import {
  Modal,
  Loader,
  Button,
  MultiSelect,
  SubmitButton,
  ErrorMessage
} from "../../Shared"

import { RULE_BY_ID } from "../../../graphql/queries"
import { CLONE_RULE } from "../../../graphql/mutations"
import { useLocations, useStateObject } from "../../../hooks"

const SelectStore = Styled(({ ...props }) => (
  <MultiSelect
    {...props}
    placeholder="Add a Location"
    noResultsMessage="All available locations selected"
    selectOnBlur={false}
    selectOnNavigation={false}
    can_select_all={true}
    fluid
    search
    max_labels={5}
  />
))`
  margin-bottom: 0.2rem !important;
`
const ModalSection = Styled.div`
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
`
const Description = Styled.div`font-weight:300;`

export default ({ rule_id, rule_type = "rule", onSuccess, onClose }) => {
  const locations = useLocations()
  const [state, setState] = useStateObject({
    selected_location_ids: [],
    error: false
  })
  const { selected_location_ids, error } = state

  const { loading, data } = useQuery(RULE_BY_ID, {
    variables: {
      id: rule_id
    }
  })

  const rule = data?.rule ?? null
  const available_stores = (rule?.clonable_to_stores ?? [])
    .map(({ id, name }) => ({ id: Number(id), name }))
    // filter down to only available locations for this user
    .filter(({ id }) => locations.find(location => id === location.id))
    .sort((a, b) => a.name.localeCompare(b.name))

  return (
    <Modal onClose={onClose} size="tiny">
      <Modal.Header>Copy to Different Locations</Modal.Header>

      <Modal.Content>
        {!!error && (
          <ModalSection>
            <ErrorMessage />
          </ModalSection>
        )}
        {!!loading && <Loader />}
        {!!rule && (
          <>
            {available_stores.length > 0 && (
              <>
                <ModalSection>
                  <Description>
                    Select the locations you'd like to copy this {rule_type} to.
                  </Description>
                </ModalSection>
                <ModalSection>
                  <SelectStore
                    options={available_stores.map(store => ({
                      value: store.id,
                      text: store.name
                    }))}
                    onChange={(e, d) =>
                      setState({
                        selected_location_ids: [...d.value]
                      })
                    }
                    value={selected_location_ids}
                  />
                </ModalSection>
              </>
            )}
            {available_stores.length === 0 && (
              <ModalSection>
                <Description>
                  This rule is not eligible to copy because it references job
                  codes{" "}
                  {rule.salesCategories.length > 0 && <>or sale categories </>}
                  {rule.revenueCenters.length > 0 && <>or revenue centers </>}
                  that were not found at other locations.
                </Description>
              </ModalSection>
            )}
          </>
        )}
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={onClose}>Cancel</Button>
        {selected_location_ids.length > 0 && (
          <SubmitCloneRule
            rule_id={Number(rule_id)}
            location_ids={selected_location_ids}
            onSuccess={onSuccess}
            onError={() => setState({ error: true })}
          />
        )}
      </Modal.Actions>
    </Modal>
  )
}

const SubmitCloneRule = ({ rule_id, location_ids, onSuccess, onError }) => {
  const [cloneRule, { loading }] = useMutation(CLONE_RULE, {
    onCompleted: onSuccess,
    onError
  })

  return (
    <SubmitButton
      disabled={!!loading}
      loading={!!loading}
      onClick={() =>
        cloneRule({
          variables: {
            id: Number(rule_id),
            store_ids: location_ids
          }
        })
      }
    />
  )
}
