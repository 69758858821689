import { useState, useEffect } from "react"

import Styled from "styled-components"
import { useQuery } from "@apollo/client"
import { colors } from "../../../../../constants"
import { darken } from "polished"

import PayoutSummary from "../../Summary"
import { Modal, Loader, IconButton } from "../../../../Shared"

import { paths } from "../../../../../constants"
import { useEtaConfigSetting, useOrganization } from "../../../../../hooks"
import { LATEST_PAYOUT } from "../../../../../graphql/queries"
import {
  rowEtaAvailable,
  rowEmployeeId,
  rowHausdirectPayoutIneligible,
  getPayoutStatusFromPayout
} from "../../../helpers"
import { ExclamationIcon, WarningIcon } from "../../../../Icon"
import PAYOUT_STATUS from "../../../../../constants/payout-status"
import { useRef } from "react"
import { ToastConsumer } from "../../../../../context"
import useAuthorization from "../../../../../hooks/authorization"
import { eta_manage_payout } from "../../../../../constants/permissions"

const PAYMENT_IN_PROGRESS_POLL_FREQ = 2500
const PAYMENT_NOT_IN_PROGRESS_POLL_FREQ = 10000

export default ({
  filters,
  values,
  pay_period,
  selected_location,
  onPayoutCompleted,
  requestFailedPayoutDetails
}) => {
  const { hasPermission } = useAuthorization()
  const [payment_status, setPaymentStatus] = useState(PAYOUT_STATUS.NONE)
  const [show_modal, setShowModal] = useState(false)

  const { data, refetch } = useQuery(LATEST_PAYOUT, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    pollInterval:
      payment_status === PAYOUT_STATUS.IN_PROGRESS
        ? PAYMENT_IN_PROGRESS_POLL_FREQ
        : PAYMENT_NOT_IN_PROGRESS_POLL_FREQ,
    variables: {
      store_id: selected_location?.id
    },
    onCompleted: ({ getLatestPayout }) => {
      const payout = getLatestPayout?.payout ?? false
      setPaymentStatus(getPayoutStatusFromPayout(payout))
    }
  })

  const latest_payout = data?.getLatestPayout
  const payable =
    values
      ?.filter(row => rowEtaAvailable(row) > 0)
      ?.sort((a, b) => (rowEtaAvailable(a) < rowEtaAvailable(b) ? 1 : -1)) ?? []

  const old_payment_status = useRef(payment_status)
  const payouts_enabled = useEtaConfigSetting("payouts_enabled", false)
  useEffect(() => {
    if (
      old_payment_status.current === PAYOUT_STATUS.IN_PROGRESS &&
      window.location.pathname === paths.earnedTipAccessOverview
    ) {
      onPayoutCompleted(payment_status)
    }
    old_payment_status.current = payment_status
  }, [payment_status])

  return (
    <>
      {hasPermission(eta_manage_payout) && (
        <IconButton
          secondary
          icon="dollar"
          loading={!latest_payout}
          disabled={
            !payouts_enabled ||
            !latest_payout ||
            payment_status === PAYOUT_STATUS.IN_PROGRESS ||
            payable.length === 0
          }
          onClick={() => setShowModal(true)}
        >
          Initiate Payout
        </IconButton>
      )}
      {payment_status === PAYOUT_STATUS.FAILURE && (
        <PaymentFailed
          onPayoutCompleted={onPayoutCompleted}
          requestFailedPayoutDetails={requestFailedPayoutDetails}
        />
      )}
      {payment_status === PAYOUT_STATUS.PARTIAL_FAILURE && (
        <PaymentPartiallyFailed
          onPayoutCompleted={onPayoutCompleted}
          requestFailedPayoutDetails={requestFailedPayoutDetails}
        />
      )}
      {payment_status === PAYOUT_STATUS.IN_PROGRESS && (
        <PaymentInProgress onPayoutCompleted={onPayoutCompleted} />
      )}
      {show_modal && (
        <ToastConsumer>
          {toast => (
            <SendFundsModal
              filters={filters}
              payable={payable}
              pay_period={pay_period}
              onClose={() => setShowModal(false)}
              onPayoutInitiated={({ initiatePayout: { success, message } }) => {
                if (success) {
                  setPaymentStatus(PAYOUT_STATUS.IN_PROGRESS)
                }
                setShowModal(false)
                toast({
                  type: success ? "success" : "error",
                  message: <p>{message}</p>
                })
              }}
            />
          )}
        </ToastConsumer>
      )}
    </>
  )
}

const SendFundsModal = ({
  payable,
  filters,
  onClose,
  className,
  pay_period,
  onPayoutInitiated
}) => {
  const org = useOrganization()
  const [excluded_employees, _setExcluded] = useState([])
  const excludeEmployee = id =>
    _setExcluded([...new Set([...excluded_employees, Number(id)])])
  const includeEmployee = id =>
    _setExcluded(excluded_employees.filter(ex => ex !== Number(id)))

  const included = payable.filter(
    row => !excluded_employees.includes(rowEmployeeId(row))
  )

  useEffect(() => {
    const eta_min_employee_payout = org?.eta_min_employee_payout ?? 0
    const ineligible_employees = payable
      .filter(row =>
        rowHausdirectPayoutIneligible(row, eta_min_employee_payout)
      )
      .map(rowEmployeeId)

    if (ineligible_employees.length > 0) {
      _setExcluded(ineligible_employees)
    }
  }, [])

  return (
    <Modal className={className} onClose={onClose} size="small">
      <Modal.Header onClose={onClose}>Send Available Funds</Modal.Header>
      <SendFundsModalContent>
        <PayoutSummary
          filters={filters}
          all_rows={payable}
          included_rows={included}
          pay_period={pay_period}
          onExcludeEmployee={excludeEmployee}
          onIncludeEmployee={includeEmployee}
          onPayoutInitiated={onPayoutInitiated}
          excluded_employees={excluded_employees}
        />
      </SendFundsModalContent>
    </Modal>
  )
}

const SendFundsModalContent = Styled(props => (
  <Modal.Content fitted {...props} />
))`
  &&&& {
    font-size: 1.15rem;
    & .table tr {
      & > td:first-child {
        padding-left: 1.5rem;
      }
      & > td:last-child {
        padding-right: 1.5rem;
      }
    }
  }
`

const PaymentInProgress = Styled(({ onPayoutCompleted, ...props }) => {
  return (
    <div {...props}>
      <Loader indeterminate size="mini" />
      <span>Payout in Progress</span>
    </div>
  )
})`
  display: inline-block;
  line-height: 2.73rem;
  margin-left: 0.5rem;
  & > span {
    padding: 0.33rem 0.5rem;
    font-weight: 300;
    font-size: 0.95rem;
  }
`

const PaymentFailed = Styled(
  ({ onPayoutCompleted, requestFailedPayoutDetails, ...props }) => {
    return (
      <div {...props} onClick={requestFailedPayoutDetails}>
        <ExclamationIcon label="Last Payout Failed" />
      </div>
    )
  }
)`
  display: inline-block;
  line-height: 2.73rem;
  margin-left: 0.5rem;
    color: ${colors.link};
  &:hover {
    color: ${darken(0.1, colors.link)};
  }
    cursor: pointer;
`

const PaymentPartiallyFailed = Styled(
  ({ onPayoutCompleted, requestFailedPayoutDetails, ...props }) => {
    return (
      <div {...props} onClick={requestFailedPayoutDetails}>
        <WarningIcon label="Last Payout Partially Failed" />
      </div>
    )
  }
)`
  display: inline-block;
  line-height: 2.73rem;
  margin-left: 0.5rem;
  color: ${colors.link};
  &:hover {
    color: ${darken(0.1, colors.link)};
  }
  cursor: pointer;
`
