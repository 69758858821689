import EditShiftButton from "./EditShift"
import ViewCustomShiftButton from "./ViewCustomShift"
import DeleteCustomShiftButton from "./DeleteCustomShift"

import { useRecurringShiftsEnabled } from "../../../../../hooks"
import useAuthorization from "../../../../../hooks/authorization"
import { reporting_manage_custom_shifts } from "../../../../../constants/permissions"

export { EditShiftButton, ViewCustomShiftButton, DeleteCustomShiftButton }

export const ACTION_CUSTOM_SHIFT = "Manage Custom Shifts"

export const useWorkedShiftActions = () => {
  const { hasPermission } = useAuthorization()
  let actions = []

  // add recurring shifts action if access is enabled
  if (
    hasPermission(reporting_manage_custom_shifts) &&
    useRecurringShiftsEnabled()
  ) {
    actions.push({
      text: ACTION_CUSTOM_SHIFT,
      value: ACTION_CUSTOM_SHIFT
    })
  }

  return actions
}
