import { useState, useEffect } from "react"

import moment from "moment"
import Styled from "styled-components"

import { Field } from "../../../Styled"
import {
  Table,
  Popup,
  Checkbox,
  Statistic,
  ExclamationIcon
} from "../../../../Shared"

import {
  TIP_DISTRO_SOURCES_ALL,
  DISTRO_SOURCE_CC_TIP,
  DISTRO_SOURCE_GC_TIP,
  DISTRO_SOURCE_SERVICE_CHARGE
} from "../../../helpers"
import { salesGratuityForSources } from "../../helpers"
import { datetime_format_verbose } from "../../../../../constants"

// step entrypoint
export const distributionSourcesStep = ({ rule, ...props }, updateRule) => {
  const [visited, setVisited] = useState(false)

  return {
    name: "Select Tip Types",
    complete: visited && !!rule.sales,
    render: () => (
      <DistributionSources
        rule={rule}
        onMount={() => setVisited(true)}
        {...props}
        updateRule={updateRule}
      />
    )
  }
}

const DistributionSources = ({ rule, updateRule, onMount }) => {
  useEffect(() => onMount(), [])
  const { sales } = rule
  return (
    <>
      <Field name="Select Tip Types">
        <AvailableSources
          selected={[...rule.distribution_sources]}
          available={TIP_DISTRO_SOURCES_ALL}
          onToggleSource={source =>
            updateRule({
              ...rule,
              distribution_sources: rule.distribution_sources.includes(source)
                ? rule.distribution_sources.filter(s => s !== source)
                : [...rule.distribution_sources, source]
            })
          }
        />
      </Field>
      <Field name="Selected Sales">
        <SalesTable
          sales={sales}
          selected_sources={[...rule.distribution_sources]}
        />
      </Field>
      <Field name="Amount Shared">
        <AmountShared
          sales={sales}
          selected_sources={[...rule.distribution_sources]}
        />
      </Field>
    </>
  )
}

const AvailableSources = Styled(
  ({ selected, available, onToggleSource, className }) => (
    <div className={className}>
      {available.map(available_source => (
        <div key={available_source}>
          <Checkbox
            label={available_source}
            checked={selected.includes(available_source)}
            onChange={() => onToggleSource(available_source)}
          />
        </div>
      ))}
    </div>
  )
)`
  & > div {
    margin: 0.33rem 0
  }
`

const SalesTable = Styled(({ sales, selected_sources, className }) => (
  <div className={className}>
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Sale Closed</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Total Tender</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Subtotal</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Tax</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">CC Tips</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">GC Tips</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">
            Gratuity Service Charge
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {sales.map(sale => (
          <Table.Row key={sale.id}>
            <Table.Cell>
              {moment(sale.sale_time).format(datetime_format_verbose)}
            </Table.Cell>
            <Table.Cell textAlign="right">
              {sale.total === 0 ? "-" : sale.total.toFixed(2)}
            </Table.Cell>
            <Table.Cell textAlign="right">
              {sale.total_sales === 0 ? (
                "-"
              ) : (
                <div>
                  {sale.total_sales.toFixed(2)}{" "}
                  {sale.total_sales_refund > 0 && (
                    <Popup
                      flowing
                      content={`Refund Applied: $${sale.total_sales_refund.toFixed(
                        2
                      )}`}
                    >
                      <ExclamationIcon />
                    </Popup>
                  )}
                </div>
              )}
            </Table.Cell>
            <Table.Cell textAlign="right">
              {sale.total_tax > 0 ? sale.total_tax.toFixed(2) : "-"}
            </Table.Cell>
            <Table.Cell
              textAlign="right"
              positive={selected_sources.includes(DISTRO_SOURCE_CC_TIP)}
            >
              {sale.total_cc_tips === 0 ? (
                "-"
              ) : (
                <div>
                  {sale.total_cc_tips.toFixed(2)}{" "}
                  {sale.total_tips_refund > 0 && (
                    <Popup
                      flowing
                      content={`Refund Applied: $${sale.total_tips_refund.toFixed(
                        2
                      )}`}
                    >
                      <ExclamationIcon />
                    </Popup>
                  )}
                </div>
              )}
            </Table.Cell>
            <Table.Cell
              textAlign="right"
              positive={selected_sources.includes(DISTRO_SOURCE_GC_TIP)}
            >
              {sale.total_gc_tips === 0 ? "-" : sale.total_gc_tips.toFixed(2)}
            </Table.Cell>
            <Table.Cell
              textAlign="right"
              positive={selected_sources.includes(DISTRO_SOURCE_SERVICE_CHARGE)}
            >
              {sale.total_service_charges === 0 ? (
                "-"
              ) : (
                <div>{sale.total_service_charges.toFixed(2)}</div>
              )}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  </div>
))`

`

const AmountShared = Styled(({ sales, selected_sources, className }) => {
  const total_shared = salesGratuityForSources(sales, selected_sources)
  return (
    <div className={className}>
      <Statistic value={`$${total_shared.toFixed(2)}`} />
    </div>
  )
})`

`
