import ScheduleTable from "../../../ScheduleTable"
import { ReviewRow, LabelCell, ValueCell } from "../Styled"

import { useIsCustomRuleSchedule } from "../../../helpers"

export default ({ schedules }) => {
  const has_custom_schedule = useIsCustomRuleSchedule(schedules)
  return (
    <ReviewRow>
      <LabelCell label="Schedule" />
      <ValueCell>
        {!has_custom_schedule && <span>Always Active</span>}
        {!!has_custom_schedule && (
          <ScheduleTable
            compact
            basic="very"
            fitted={true}
            row_hover={false}
            include_header={false}
            schedules={schedules}
          />
        )}
      </ValueCell>
    </ReviewRow>
  )
}
