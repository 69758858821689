import React, { useState } from "react"

import { EditSaleButton } from "../Styled"
import EditSaleModal from "../../Modal/EditSale"
import useAuthorization from "../../../../../hooks/authorization"
import { reporting_manage_sale } from "../../../../../constants/permissions"

export default ({ sale, job_code, min_sale_date, locked, onSaleEdited }) => {
  const [show_modal, setShowModal] = useState(false)
  const { hasPermission } = useAuthorization()
  return (
    <>
      {hasPermission(reporting_manage_sale) && (
        <EditSaleButton locked={locked} onClick={() => setShowModal(true)} />
      )}
      {show_modal && (
        <EditSaleModal
          sale={sale}
          job_code={job_code}
          min_date={min_sale_date}
          onSuccess={payload => {
            onSaleEdited(payload)
            setShowModal(false)
          }}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  )
}
