import { FilterRow, FilterValue } from "../Styled"
import RevenueCenterSearch from "../../../Search/RevenueCenter"

export default ({ location, revenue_center, onSelect, onRemove }) => {
  return (
    <FilterRow name="Revenue Center">
      {!!revenue_center && (
        <FilterValue value={revenue_center.name} onRemove={onRemove} />
      )}
      {!revenue_center && (
        <>
          <RevenueCenterSearch
            aligned="right"
            store_id={location?.id}
            onChange={({ revenue_center }) => onSelect({ ...revenue_center })}
          />
        </>
      )}
    </FilterRow>
  )
}
