import { Component } from "react"

import Styled from "styled-components"
import { Pagination } from "semantic-ui-react"

import Dropdown from "./Dropdown"

import { displayNumber } from "../helpers/number"
import { colors } from "../constants"

const PaginationWrapper = Styled(({ padded, ...props }) => <div {...props} />)`
  ${({ padded }) => (!!padded ? `padding: 1rem 0 1rem 0;` : ``)}
  text-align: right;
  line-height: 3rem;
  &&& > div {
    font-size: 1rem;
    font-weight: 300;
    margin-left: 0.5rem;
    &:first-child {
      margin-left: 0;
    }
  }
  &&& .menu {
    box-shadow: none;
  }
  &&&& .dropdown, &&& .pagination {
    vertical-align: top;
    height: 2.9rem;
    line-height: 1.3rem;
  }
`
const TotalWrapper = Styled.div`
  display: inline-block;
`
const TotalResults = Styled.div`
  font-size: 1rem;
  color: ${colors.dark};
  font-weight: 300;
`
const PerPageDropdown = props => (
  <Dropdown min_width_expanded="8rem" {...props} />
)
const StyledPagination = Styled(props => <Pagination {...props} />)``

export default class extends Component {
  render() {
    const {
      current_page,
      total,
      include_total = true,
      padded = false,
      changePage,
      changePerPage = null,
      per_page = null,
      options = null,
      custom_controls
    } = this.props

    let last_page = current_page
    try {
      last_page = Math.ceil(total / per_page)
    } catch (e) {}

    return (
      <PaginationWrapper padded={padded}>
        {!!include_total && total !== null && (
          <TotalWrapper>
            <TotalResults>
              {total === 0 && `No Results`}
              {total === 1 && `Showing ${total} result.`}
              {total > 1 && (
                <>
                  Showing{" "}
                  <strong>
                    {displayNumber((current_page - 1) * per_page + 1)}-
                    {displayNumber(
                      current_page < last_page ? current_page * per_page : total
                    )}
                  </strong>{" "}
                  of {displayNumber(total)} results.
                </>
              )}
            </TotalResults>
          </TotalWrapper>
        )}

        {!!per_page && total > per_page && (
          <StyledPagination
            boundaryRange={0}
            siblingRange={3}
            totalPages={last_page}
            activePage={current_page}
            onPageChange={(e, { activePage }) => changePage(activePage)}
          />
        )}
        {!!custom_controls && <>{custom_controls}</>}
        {!!options &&
          !!per_page &&
          !!changePerPage &&
          !!options.per_page &&
          total > Math.min.apply(Math, options.per_page) && (
            <PerPageDropdown
              selection
              value={per_page}
              options={options.per_page.map(value => ({
                value,
                text: `${value} results per page`,
                selected: value === per_page
              }))}
              onChange={(e, { value }) => changePerPage(value)}
            />
          )}
      </PaginationWrapper>
    )
  }
}
