import { Component } from "react"
import Styled from "styled-components"
import { Query } from "@apollo/client/react/components"

import { Loader } from "../../../../Shared"
import { StepHeader } from "../../../Styled"
import MultiSelectList from "../../../../MultiSelectList"

import { SHARED_JOB_CODE_NAMES } from "../../../../../graphql/queries"

const CollectFromWrapper = Styled.div``
const CollectFromInput = Styled(({ className, ...props }) => (
  <div className={className} id="rule-editor-collect-from-input">
    <MultiSelectList icon="user" {...props} />
  </div>
))`display: inline-block;`

export default class extends Component {
  state = {
    job_name_search: ""
  }

  render() {
    const { onUpdate, selected, exclude, store_ids } = this.props
    return (
      <CollectFromWrapper>
        <StepHeader
          content="Collect From"
          subheader="Select the job codes that will share tips."
        />
        <Query query={SHARED_JOB_CODE_NAMES} variables={{ store_ids }}>
          {({ loading, data }) => {
            let job_codes = []
            if (!!loading || !data) {
              return <Loader inline active />
            } else {
              job_codes = data.sharedJobCodeNames.map(name => ({
                id: name,
                name: name
              }))
              return (
                <CollectFromInput
                  available={job_codes
                    .filter(
                      job_code => !exclude.find(({ id }) => id === job_code.id)
                    )
                    .sort((a, b) => {
                      if (a.name < b.name) {
                        return -1
                      }
                      if (a.name > b.name) {
                        return 1
                      }
                      return 0
                    })}
                  selected={selected}
                  onUpdate={selected => onUpdate({ selected })}
                />
              )
            }
          }}
        </Query>
      </CollectFromWrapper>
    )
  }
}
