import Styled from "styled-components"
import { Loader } from "semantic-ui-react"

import Link from "../../Link"

import {
  CONTENT_PADDING_LEFT,
  CONTENT_PADDING_RIGHT
} from "../../RouteContent/Styled"
import { colors } from "../../../constants"

const content_background_color = colors.light

export const FilterBarWrapper = Styled(
  ({ className, for_route = true, ...props }) => (
    <div
      className={`${
        for_route ? "route-content-full-width " : " "
      }filter-bar ${className}`}
    >
      <div {...props} />
    </div>
  )
)`${({ for_route = true }) => `
  position: relative;
  margin-top: 0rem;
  margin-bottom: 0.33rem;
  display: block;

  & > div {
    & ${FiltersContent} {
      padding:
        calc(0.5rem - 0.5px)
        calc(${for_route ? CONTENT_PADDING_RIGHT : "0px"})
        calc(0.5rem - 0.5px)
        ${for_route ? `calc(2px + ${CONTENT_PADDING_LEFT})` : "1rem"};
    }
    & ${PresetsWrapper} {
      padding:
        0.17rem
        ${for_route ? CONTENT_PADDING_RIGHT : "0px"}
        0.33rem
        calc(2px + ${for_route ? CONTENT_PADDING_LEFT : "0px"});
    }
    & ${FilterActions} {
      padding:
        0
        ${for_route ? CONTENT_PADDING_RIGHT : "0px"}
        0
        calc(2px + ${for_route ? CONTENT_PADDING_LEFT : "0px"});
    }
    & ${FiltersLoader} {
      right: calc(1.5rem + ${for_route ? CONTENT_PADDING_RIGHT : "0px"});
    }
  }
`}`

export const FiltersContent = Styled(({ className, ...props }) => (
  <div {...props} className={`filter-bar-content ${className}`} />
))`
  position: relative;
  color: ${colors.dark};
  background-color: ${content_background_color};
  border-top: 0.5px solid ${colors.light4};
  border-bottom: 0.5px solid ${colors.light3};
  display: grid;
  grid-template-columns: 1fr auto auto;
`
export const FiltersWrapper = Styled.div`
  grid-column: 1;
  padding-right: 5rem;
`
export const FiltersLoader = Styled(({ className }) => (
  <div className={className}>
    <Loader active />
  </div>
))`
  grid-column: 2;
  position: relative;
`
export const ControlsWrapper = Styled.div`
  grid-column: 3;
  line-height: 3.5rem
`
export const FilterActions = Styled(({ actions, ...props }) => (
  <div {...props}>{actions}</div>
))`
  text-align: left;
  position: relative;
  margin-top: 0.5rem;
`
export const DatePickerWrapper = Styled.div`
  display: inline-block;
`

const FilterTitle = Styled.div`
  font-weight: 600;
  font-size: 0.97rem;
  color: ${colors.dark2};
  display: inline-block;
  margin-right: 1rem;
  line-height: 2.6rem;
  cursor: default;
`
export const FilterWrapper = Styled(
  ({ label, children, styled, className, ...props }) => (
    <div className={className} {...props}>
      {!!label && <FilterTitle className="filter-label">{label}</FilterTitle>}
      <div>{children}</div>
    </div>
  )
)`
  padding: 0.33rem 1.33rem 0.33rem 0;
  line-height: 2.67rem;
  display: inline-block;
  ${({ styled = true }) =>
    !styled
      ? ""
      : `
    border-radius: 1px;
    & .selected-label {
      color: ${colors.dark2};
    }
    &:hover {
      & .selected-label {
        color: ${colors.dark};
        box-shadow: 0 1px 2px ${colors.light6};
      }
    }
    & > div:last-child {
      display: inline-block;
      vertical-align: top;
    }
  `}
`
export const SelectedLabel = Styled(
  ({ className, onRemove = null, ...props }) => (
    <div
      className={`${className} selected-label`}
      onClick={onRemove ?? undefined}
      {...props}
    />
  )
)`${({ onRemove = null }) => `
  cursor: ${!!onRemove ? "pointer" : "default"};
  line-height: 1.67rem;
  display: inline-block;
  font-weight: bold;
  background-color: ${colors.light3};
  border-radius: 4px;
  fonts-size: 1.2rem;
  padding: 0.2rem ${!!onRemove ? "0" : "0.67rem"} 0.2rem 0.67rem;
  & > .icon {
    padding: 0.5rem 0.67rem;
    color: ${colors.dark6};
  }
  &:hover > .icon {
    color: ${colors.red};
  }
`}`
export const PresetsWrapper = Styled(({ className, ...props }) => (
  <div {...props} className={className} />
))`
  font-weight: 300;
  background-color: ${colors.transparent};
`
export const PresetsLabel = Styled.div`
  display: inline-block;
  color: ${colors.dark};
`
export const PresetLink = Styled(({ className, ...props }) => (
  <div className={className}>
    <Link {...props} />
  </div>
))`
  font-weight: 400;
  display: inline-block;
  padding: 0 calc(0.67rem + 0.5px) 0 0.67rem;
  border-right: 0.5px solid ${colors.light6};
  &:last-child {
    border-right: none;
  }
`
