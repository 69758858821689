import { Component, useState } from "react"

import moment from "moment"
import Styled from "styled-components"
import { useMutation } from "@apollo/client"
import { Form, Loader } from "semantic-ui-react"

import Link from "../../Link"
import Modal from "../../Modal"
import Message from "../../Message"
import HelpIcon from "../../Icon/Help"
import RecentPayPeriods from "./Recent"
import DatePicker from "../../DatePicker"
import AreYouSure from "../../Modal/AreYouSure"
import Button, { SubmitButton } from "../../Button"
import Dropdown, { MenuDropdown } from "../../Dropdown"

import {
  UPSERT_ORGANIZATION_PAYROLL,
  UPDATE_PAY_PERIOD_AUTO_FINALIZE_SETTING
} from "../../../graphql/mutations"
import { paths, date_format, project_info } from "../../../constants"
import { ucwords } from "../../../helpers/string"
import { compatibleDateObject } from "../../../helpers/datetime"
import { autoFinalizePayPeriodSetting } from "../../../helpers/settings/organization"
import Icon from "../../Icon"
import { Popup } from "../../Shared"
import { etaEnabled } from "../../EarnedTipAccess/helpers"
import { hasEnterpriseLevelRole, hasOrgLevelRole } from "../../../helpers/user"

const PAYROLL_INTERVAL_WEEKLY = "weekly"
const PAYROLL_INTERVAL_BI_WEEKLY = "bi-weekly"
const PAYROLL_INTERVAL_SEMI_MONTHLY = "semi-monthly"

const PAYROLL_INTERVALS = [
  PAYROLL_INTERVAL_WEEKLY,
  PAYROLL_INTERVAL_BI_WEEKLY,
  PAYROLL_INTERVAL_SEMI_MONTHLY
]

const AUTOFINALIZE_OPTIONS = [0, 1, 2, 3, 4, 5]

const SEMI_MONTHLY_DEFAULT_OFFSET = 1
const SEMI_MONTHLY_INTERVAL_DAYS = 15

const EMPTY_VALUE = ""

const Description = Styled.div`
  font-weight: 300;
  font-size: 1rem;
  margin: 1rem 0;
`
const FormWrapper = Styled.div`
  font-size: 1rem;
  margin-top: 0.67rem;
  & .field {
    width: 15rem;
  }
`

export default class extends Component {
  state = {
    initialized: false,
    reference_date: null,
    settings_updated: false,
    pay_period_updated: false,
    auto_finalize_modal: false,
    selected_interval: EMPTY_VALUE,
    autofinalize_after: EMPTY_VALUE,
    reference_offset: SEMI_MONTHLY_DEFAULT_OFFSET
  }

  componentDidMount() {
    this.initializeSettings()
  }

  initializeSettings() {
    const {
      settings,
      payroll_interval,
      current_pay_period,
      payroll_reference_date
    } = this.props.user.organization

    let reference_date = payroll_reference_date
    let reference_offset = SEMI_MONTHLY_DEFAULT_OFFSET

    if (current_pay_period) {
      reference_date = current_pay_period.start
    }

    if (
      payroll_interval === PAYROLL_INTERVAL_SEMI_MONTHLY &&
      payroll_reference_date
    ) {
      // day of month
      reference_offset = moment(payroll_reference_date).date()
    }

    this.setState({
      reference_offset,
      initialized: true,
      settings_updated: false,
      pay_period_updated: false,
      selected_interval: payroll_interval ?? EMPTY_VALUE,
      autofinalize_after: autoFinalizePayPeriodSetting(settings),
      reference_date: reference_date ?? this.props.businessDate()
    })
  }

  reset = () => {
    this.props.syncUser()
    this.setState({
      settings_updated: false,
      pay_period_updated: false
    })
  }

  cancel = () => this.initializeSettings()

  requiresReferenceDate = () =>
    [PAYROLL_INTERVAL_WEEKLY, PAYROLL_INTERVAL_BI_WEEKLY].includes(
      this.state.selected_interval
    )

  requiresReferenceOffset = () =>
    [PAYROLL_INTERVAL_SEMI_MONTHLY].includes(this.state.selected_interval)

  isValid = () =>
    this.state.selected_interval !== EMPTY_VALUE &&
    this.state.autofinalize_after !== EMPTY_VALUE

  sortedPayPeriods = pay_periods =>
    pay_periods.sort((a, b) => (moment(a.start).isAfter(b.start) ? -1 : 1))

  canEditPayPeriodSettings = () => {
    const { user, can_edit_pay_period } = this.props
    if (!!user.is_backdoor) {
      return true
    }
    // only allow org & enterprise level users with the settings:manage-pay-period permission edit access
    // dont allow eta orgs to edit pay period settings
    return (
      can_edit_pay_period &&
      (hasEnterpriseLevelRole(user) || hasOrgLevelRole(user)) &&
      !etaEnabled(user)
    )
  }

  canEditAutoFinalizeSetting = () => {
    const { user, can_edit_pay_period } = this.props
    // only allow org & enterprise level users with the settings:manage-pay-period permission edit access
    return (
      can_edit_pay_period &&
      (hasEnterpriseLevelRole(user) || hasOrgLevelRole(user))
    )
  }

  render() {
    const {
      initialized,
      reference_date,
      reference_offset,
      settings_updated,
      selected_interval,
      autofinalize_after,
      pay_period_updated,
      auto_finalize_modal
    } = this.state

    const { user, syncing_user, businessDate, showIntercom } = this.props

    const {
      last_pay_period,
      current_pay_period,
      pay_period_before_last
    } = user.organization
    const can_edit = this.canEditPayPeriodSettings()

    if (!initialized || syncing_user) {
      return <Loader inline active />
    }

    return (
      <>
        <FormWrapper>
          <Form>
            <LockedSettingsPopup
              field_name="Pay Period Interval"
              disabled={can_edit}
              showIntercom={showIntercom}
            >
              <PayPeriodInterval>
                <MenuDropdown
                  disabled={!can_edit}
                  label={
                    <span>Pay Period Interval {!can_edit && <LockIcon />}</span>
                  }
                  selection
                  value={selected_interval}
                  options={[
                    { text: "Select One", value: EMPTY_VALUE },
                    ...PAYROLL_INTERVALS.map(interval => ({
                      text: ucwords(interval),
                      value: interval
                    }))
                  ]}
                  onChange={value =>
                    this.setState({
                      pay_period_updated: true,
                      selected_interval: value
                    })
                  }
                />
              </PayPeriodInterval>
            </LockedSettingsPopup>

            {selected_interval !== EMPTY_VALUE && (
              <>
                {this.requiresReferenceDate() && (
                  <Form.Field
                    label={
                      <label>
                        Pay Period Start Date {!can_edit && <LockIcon />}
                      </label>
                    }
                    control={() => (
                      <LockedSettingsPopup
                        field_name="Pay Period Start Date"
                        disabled={can_edit}
                        showIntercom={showIntercom}
                      >
                        <div>
                          <DatePicker
                            disabled={!can_edit}
                            fluid
                            position="right"
                            onChange={e =>
                              this.setState({
                                reference_date: moment(e).format(date_format),
                                pay_period_updated: true
                              })
                            }
                            value={compatibleDateObject(
                              `${reference_date} 00:00:00`
                            )}
                            minDate={compatibleDateObject(
                              `${businessDate(null, false)
                                .subtract(
                                  selected_interval === PAYROLL_INTERVAL_WEEKLY
                                    ? 6
                                    : 13,
                                  "d"
                                )
                                .format(date_format)} 00:00:00`
                            )}
                            maxDate={compatibleDateObject(
                              `${businessDate()} 00:00:00`
                            )}
                          />
                        </div>
                      </LockedSettingsPopup>
                    )}
                  />
                )}
                {this.requiresReferenceOffset() && (
                  <Description>
                    Semi-monthly pay periods start on the{" "}
                    <Dropdown
                      inline
                      scrolling
                      value={reference_offset}
                      options={[...Array(13).keys()].map(idx => ({
                        value: idx + 1,
                        text: moment.localeData().ordinal(idx + 1)
                      }))}
                      onChange={(e, { value }) =>
                        this.setState({
                          reference_offset: value,
                          pay_period_updated: true
                        })
                      }
                    />{" "}
                    and{" "}
                    {moment
                      .localeData()
                      .ordinal(
                        reference_offset + SEMI_MONTHLY_INTERVAL_DAYS
                      )}{" "}
                    of each month.
                  </Description>
                )}
                <MenuDropdown
                  disabled={!this.canEditAutoFinalizeSetting()}
                  label={
                    <>
                      Auto-Finalize After&nbsp;
                      <HelpIcon
                        popup={
                          <>
                            Closed pay periods will lock automatically after a
                            specified number of days.
                          </>
                        }
                      />
                    </>
                  }
                  selection
                  value={autofinalize_after}
                  options={[
                    { text: "Select One", value: EMPTY_VALUE },
                    ...AUTOFINALIZE_OPTIONS.map(day => ({
                      text:
                        day === 0
                          ? "Immediately"
                          : `${day} Day${day > 1 ? "s" : ""}`,
                      value: day
                    }))
                  ]}
                  onChange={value =>
                    this.setState({
                      settings_updated: true,
                      autofinalize_after: value
                    })
                  }
                />
                <FinalizeLearnMore
                  requestLearnMore={() =>
                    this.setState({ auto_finalize_modal: true })
                  }
                />
              </>
            )}
          </Form>
        </FormWrapper>
        {(pay_period_updated || settings_updated) && (
          <UpdateSettingsControls
            user={user}
            selected_interval={selected_interval}
            reference_date={
              selected_interval === PAYROLL_INTERVAL_SEMI_MONTHLY
                ? moment().date(reference_offset).format(date_format)
                : reference_date
            }
            autofinalize_after={autofinalize_after}
            update_pay_period={pay_period_updated}
            update_settings={settings_updated}
            is_valid={this.isValid()}
            onCancel={() => this.cancel()}
            onUpdateSuccess={() => {
              this.props.toast({
                type: "success",
                message: `Pay period settings updated successfully.`
              })
              this.reset()
            }}
            onUpdateError={() => {
              this.props.toast({
                type: "danger",
                message: `Failed to update pay period settings.`
              })
              this.reset()
            }}
          />
        )}
        {!!current_pay_period && !pay_period_updated && (
          <RecentPayPeriods
            {...this.props}
            refresh={this.reset}
            last_pay_period={last_pay_period}
            current_pay_period={current_pay_period}
            pay_period_before_last={pay_period_before_last}
          />
        )}
        {!!auto_finalize_modal && (
          <AutoFinalizeModal
            onClose={() => this.setState({ auto_finalize_modal: false })}
          />
        )}
      </>
    )
  }
}

const UpdateSettingsControls = Styled(
  ({
    user,
    is_valid,
    onCancel,
    className,
    onUpdateError,
    reference_date,
    onUpdateSuccess,
    update_settings,
    selected_interval,
    update_pay_period,
    autofinalize_after
  }) => {
    const [request_submit, setRequestSubmit] = useState(false)
    const onCompleted = e => {
      // hack to prevent multiple onsuccess
      if (e.updatePayPeriodAutoFinalizeSetting && update_pay_period) {
        return
      }
      onUpdateSuccess()
      setRequestSubmit(false)
    }
    const onError = e => {
      // hack to prevent multiple onsuccess
      if (e.updatePayPeriodAutoFinalizeSetting && update_pay_period) {
        return
      }
      onUpdateError()
      setRequestSubmit(false)
    }
    const [
      upsertOrganizationPayroll,
      { loading: pay_period_loading }
    ] = useMutation(UPSERT_ORGANIZATION_PAYROLL, {
      notifyOnNetworkStatusChange: true,
      onCompleted,
      onError
    })
    const [
      updatePayPeriodAutoFinalizeSetting,
      { loading: settings_loading }
    ] = useMutation(UPDATE_PAY_PERIOD_AUTO_FINALIZE_SETTING, {
      notifyOnNetworkStatusChange: true,
      onCompleted,
      onError
    })
    const loading = pay_period_loading || settings_loading

    return (
      <div className={className}>
        <Button onClick={onCancel}>Cancel</Button>
        <SubmitButton
          label="Update Pay Period Settings"
          secondary
          disabled={!is_valid}
          onClick={() => setRequestSubmit(true)}
        />
        {!!request_submit && (
          <AreYouSure
            header="Are you sure?"
            body={
              <>You can always update pay period settings if they change.</>
            }
            submitted={!!loading}
            onClose={() => setRequestSubmit(false)}
            onConfirm={() => {
              if (!loading) {
                if (update_pay_period) {
                  upsertOrganizationPayroll({
                    variables: {
                      payroll_interval: selected_interval,
                      payroll_reference_date: reference_date
                    }
                  })
                }
                if (update_settings) {
                  updatePayPeriodAutoFinalizeSetting({
                    variables: {
                      value: autofinalize_after.toString()
                    }
                  })
                }
              }
            }}
          />
        )}
      </div>
    )
  }
)`
  font-size: 1rem;
  margin-top: 1.5rem;
`

const FinalizeLearnMore = Styled(({ requestLearnMore, className }) => (
  <div className={className}>
    <Link onClick={requestLearnMore}>Learn more</Link> about finalized pay
    periods.
  </div>
))``

const AutoFinalizeModal = ({ onClose }) => (
  <Modal onClose={onClose} size="tiny">
    <Modal.Header onClose={onClose}>Finalized Pay Periods</Modal.Header>
    <Modal.Content>
      <Message type="info">
        <p>
          {project_info.name} automatically finalizes pay periods a set number
          of days after they close. You can configure the auto-finalize delay
          from pay period settings.
        </p>
        <p>
          When a pay period is finalized, payroll information is locked and a
          snapshot of your breakdown report is created. You can download
          snapshots from the{" "}
          <Link href={paths.reportBreakdown} target="_blank">
            Reporting tab
          </Link>
          .
        </p>
        <p>
          <strong>If you need to reprocess</strong> data after a pay period has
          finalized, you can unlock, adjust and finalize the pay period. We'll
          resync data from your point of sale, recalculate tip distribution, and
          generate a new breakdown snapshot.
        </p>
      </Message>
    </Modal.Content>
  </Modal>
)

const PayPeriodInterval = Styled.div`
  display: inline-block;
  margin-bottom: 1rem;
`

const LockIcon = Styled(({ className }) => (
  <Icon name="lock" className={className} />
))`
  &.icon {
    margin-left: 0.15rem;
  }
`

const LockedSettingsPopup = ({
  field_name,
  disabled,
  children,
  showIntercom
}) => (
  <Popup
    hoverable
    content={
      <>
        {field_name} is locked to ensure ETA distribution accuracy. Please{" "}
        <Link onClick={showIntercom}>contact</Link> support to make any changes.
      </>
    }
    position="right center"
    disabled={disabled}
  >
    {children}
  </Popup>
)
