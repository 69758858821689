import React from "react"

import withSizes from "react-sizes"
import { Provider, connect } from "react-redux"
import { ConnectedRouter } from "connected-react-router"

import Layout from "../components/Layout"

import history from "../store/history"
import mapStateToApp from "./state-map"
import mapActionsToApp from "./actions-map"
import { sizesToProps } from "../constants"

import "typeface-roboto"
import "semantic-ui-css/semantic.min.css"

const Index = withSizes(sizesToProps)(({ store, ...props }) => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Layout {...props} />
    </ConnectedRouter>
  </Provider>
))

// connect redux store & actions to our app
export default connect(mapStateToApp, mapActionsToApp)(Index)
